import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import PreviewIcon from '@mui/icons-material/Preview';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import SidebarLink from "./SidebarLink";
import {useDispatch, useSelector} from "react-redux"
import "./Sidebar.css";
import {  typeEdit } from "../../../redux/dependenciesReducer";
import PeopleIcon from '@mui/icons-material/People';
import { ListAltOutlined, QrCodeScanner, ViewModule} from "@mui/icons-material";
import authService from "../../../services/auth-services";
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import MuseumOutlinedIcon from '@mui/icons-material/MuseumOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

const Sidebar = () => {
  const {active,auction_id, type, auction_title} = useSelector(state => state.dependencies)
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isStorePermission, setIsStorePermission] = useState(false)
  useEffect(() => {
    if(type === null && location.pathname !== "/admin") {
      navigate("/admin", {replace: true})
    }
  }, [])
  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("data"))
    let status = null
    if(savedData) {
      if(savedData.auction_status) {
        status = savedData.auction_status
      }
      dispatch(typeEdit({
        auction_id: savedData.auction_id,
        auction_title: savedData.auction_title,
        start_date: savedData.start_date,
        end_date: savedData.end_date,
        auction_status: status,
        // active:'event',
        active: location.pathname.includes('/market-place') ? 'market' : location.pathname.includes('/store') ? 'store' : 'event',
        has_auction: savedData.has_auction,
        has_buy_now: savedData.has_buy_now
      }))
    }

  }, [])

  return (
    <>
      <nav className="sidebar">
      <div>
        <SidebarLink header={true} title="Causes" icon={<EventNoteOutlinedIcon sx={{ fontSize: 18, marginRight: "3px" }} />}/>
        <div className="sidebar-sub-menu">
          <SidebarLink title="Manage Causes" icon={<ManageSearchIcon sx={{ fontSize: 18, marginRight: "3px" }} />} path="/admin/cause"/>
          {/* <SidebarLink title="Create/Edit Cause" icon={<ManageSearchIcon sx={{ fontSize: 18, marginRight: "3px" }} />} path="/admin/cause/add" activePaths={['/admin/cause/edit']}/> */}
        </div>
      </div>
      <div>
        <SidebarLink header={true} title="Events" icon={<EventNoteOutlinedIcon sx={{ fontSize: 18, marginRight: "3px" }} />}/>
        <div className="sidebar-sub-menu">
          <SidebarLink title="Dashboard" icon={<ManageSearchIcon sx={{ fontSize: 18, marginRight: "3px" }} />} path="/admin/my-events"  />
          {/* <SidebarLink title={((type === 'add' || type === null) && active !== 'event') ? "Create Event" : `Edit Event - ${auction_title}`} icon={<ControlPointIcon sx={{ fontSize: 18, marginRight: "3px" }} />} path={auction_id && active === 'event' ? `/admin/event/edit/${auction_id}` : "/admin/event"} /> */}
          {/* <SidebarLink title={auction_id && active === 'event' ? `Edit Event - ${auction_title}`  :"Create Event"} icon={<ControlPointIcon sx={{ fontSize: 18, marginRight: "3px" }} />} path={auction_id && active === 'event' ? `/admin/event/edit/${auction_id}` : "/admin/event"} activePaths={["/admin/terms/edit", "/admin/terms"]} /> */}
          {/* <SidebarLink disabled={(type === 'add' && auction_id === null) || type === null} title="Add/Edit Event Terms" icon={<DescriptionOutlinedIcon sx={{ fontSize: 18, marginRight: "3px" }} />} path={auction_id ? `/admin/terms/edit/${auction_id}` : "/admin/terms"} /> */}
          <SidebarLink disabled={!auction_id || active !== 'event'} title="Add/Edit Event Items" icon={<ViewInArOutlinedIcon sx={{ fontSize: 18, marginRight: "3px" }} />} path="/admin/items" activePaths={["/admin/items/add", "/admin/items/edit"]} />
          <SidebarLink disabled={!auction_id || active !== 'event'} title="Preview Event" icon={<PreviewIcon sx={{ fontSize: 18, marginRight: "3px" }}/>} path={auction_id ? `/admin/event-preview/${auction_id}` : ""}/>
          <SidebarLink disabled={!auction_id || active !== 'event'} title="Bid Management" icon={<LocalAtmIcon sx={{ fontSize: 18, marginRight: "3px" }}/>} path={auction_id ? `/admin/event/management/bids/${auction_id}` : ""}/>
          {/* <SidebarLink title="Event Dashboard" icon={<DashboardOutlinedIcon sx={{ fontSize: 19, marginRight: "3px" }} />} path="/admin/event-dashboard" /> */}
        </div>
      </div>

      <div>
        <SidebarLink header={true} title="Store" icon={<StoreOutlinedIcon sx={{ fontSize: 18, marginRight: "3px" }} />}/>
        <div className="sidebar-sub-menu">
          <SidebarLink title="Dashboard" icon={<ManageSearchIcon sx={{ fontSize: 18, marginRight: "3px" }} />} path="/admin/store" activePaths={["/admin/store/add", `/admin/store/edit`, "/admin/store/terms", "/admin/store/terms/edit"]} />        
          <SidebarLink disabled={active !== 'store'}  title="Add/Edit Store Items" icon={<ViewInArOutlinedIcon sx={{ fontSize: 18, marginRight: "3px" }} />} path="/admin/store/items" activePaths={["/admin/store/items/add", "/admin/store/items/edit"]} />          
          <SidebarLink disabled={active !== 'store'} title="Preview Store" icon={<PreviewIcon sx={{ fontSize: 18, marginRight: "3px" }}/>} path={auction_id ? `/admin/store-preview/${auction_id}` : ""}/>          
          {/* <SidebarLink disabled={active !== 'store'} title="Bid Management" icon={<LocalAtmIcon sx={{ fontSize: 18, marginRight: "3px" }}/>} path={auction_id ? `/admin/store/management/bids/${auction_id}` : ""}/>           */}
        </div>
      </div>

        <div>
        <SidebarLink header={true} title="Marketplace" icon={<MuseumOutlinedIcon sx={{ fontSize: 18, marginRight: "3px" }} />}/>
        <div className="sidebar-sub-menu">
          <SidebarLink title="Dashboard" icon={<ManageSearchIcon sx={{ fontSize: 18, marginRight: "3px" }} />} path="/admin/market-place" activePaths={["/admin/market-place/add", `/admin/market-place/edit`, "/admin/market-place/terms", "/admin/market-place/terms/edit"]} />         
          <SidebarLink disabled={active !== 'market'} title="Add/Edit Market Place Items" icon={<ViewInArOutlinedIcon sx={{ fontSize: 18, marginRight: "3px" }} />} path="/admin/market-place/items" activePaths={["/admin/market-place/items/add", "/admin/market-place/items/edit"]} />          
          <SidebarLink disabled={active !== 'market'} title="Preview Marketplace" icon={<PreviewIcon sx={{ fontSize: 18, marginRight: "3px" }}/>} path={auction_id  ? `/admin/market-place-preview/${auction_id }` : ""}/>          
          <SidebarLink disabled={active !== 'market'} title="Bid Management" icon={<LocalAtmIcon sx={{ fontSize: 18, marginRight: "3px" }}/>} path={auction_id  ? `/admin/market-place/management/bids/${auction_id }` : ""}/>          
        </div>
      </div>
      
        <div>
        <SidebarLink header={true} title="Orders And Reports" icon={<SummarizeOutlinedIcon sx={{ fontSize: 19, marginRight: "3px" }} />} />
          <div className="sidebar-sub-menu">
            {/* <SidebarLink title="Event Status" icon={<FlagOutlinedIcon sx={{ fontSize: 19, marginRight: "3px" }} />} path="" />
            <SidebarLink title="Manage Users" icon={<PeopleAltOutlinedIcon sx={{ fontSize: 19, marginRight: "3px" }} />} path="" />
            <SidebarLink title="Sales" icon={<StyleOutlinedIcon sx={{ fontSize: 19, marginRight: "3px" }} />} path="" /> */}
            {/* <SidebarLink title="Orders" icon={<LocalShippingOutlinedIcon sx={{ fontSize: 19, marginRight: "3px" }} />} path="/admin/order" /> */}
            <SidebarLink title="Events" icon={<ListAltOutlined sx={{ fontSize: 19, marginRight: "3px" }} />} path="/admin/orders-and-reports/event" />
            <SidebarLink title="Marketplace" icon={<MuseumOutlinedIcon sx={{ fontSize: 19, marginRight: "3px" }} />} path="/admin/orders-and-reports/market-place" />
            <SidebarLink title="Store" icon={<StoreOutlinedIcon sx={{ fontSize: 19, marginRight: "3px" }} />} path="/admin/orders-and-reports/store" />
          </div>
        </div>
     


      <div>
      <SidebarLink header={true} title="Tools" icon={<SettingsOutlinedIcon sx={{ fontSize: 19, marginRight: "3px" }} />} />
        <div className="sidebar-sub-menu">
          <SidebarLink title="Manage Donors" icon={<VolunteerActivismOutlinedIcon sx={{ fontSize: 19, marginRight: "3px" }} />} path="/admin/manage-donor" activePaths={["/admin/add-donorPromo", "/admin/add-donor","/admin/add-donor-events", "/admin/edit-donor"]} />
          <SidebarLink title="User Management" icon={<PeopleIcon sx={{ fontSize: 19, marginRight: "3px" }} />} path="/admin/user-management" />
          <SidebarLink title="Advatix Onboarding" icon={<InsertChartOutlinedIcon sx={{ fontSize: 19, marginRight: "3px" }} />} path="/admin/advatix-onboarding" />
          <SidebarLink title="ASN" icon={<InsertChartOutlinedIcon sx={{ fontSize: 19, marginRight: "3px" }} />} path="/admin/asn" />
          <SidebarLink title="Print Item QR Codes" icon={<QrCodeScanner sx={{ fontSize: 19, marginRight: "3px" }} />} path="/admin/item-qr-code"  activePaths={["/admin/item-qr-code/generate"]}/>
          <SidebarLink title="Items Display Order" icon={<ViewModule sx={{ fontSize: 19, marginRight: "3px" }} />} path="/admin/item-display-order" />
          <SidebarLink title="Blog" icon={<AssignmentIcon sx={{ fontSize: 19, marginRight: "3px" }} />} path="/admin/blog" activePaths={["/admin/blog/edit-blog", "/admin/blog/create-blog"]} />
        </div>
      </div>
    </nav>
    <div className="sidebar-place"></div>
    </>
  );
};

export default Sidebar;
