import React, { useEffect, useState } from 'react'
import Container from "../../components/HOC/Container/Container"
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DropDown from '../../components/DropDown/DropDown';
import "./AdvatixOnboarding.css"
import { Checkbox, FormControlLabel, Paper, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import auctionServices from '../../../services/auction-services';
import { Table } from 'react-bootstrap';
import styled from '@emotion/styled';
import ItemRow from './components/index';
import AccessDenied from "../../components/AccessDenied/AccessDenied"
import authService from '../../../services/auth-services';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import { COLORS_OBJECT } from '../../../constants/en';
import Alert from '../../components/Alert/Alert';
import UploadFile from '../../components/UploadFile/UploadFile';
import { getEventType } from '../../../utils/util-functions';

const redColor = COLORS_OBJECT.color[0]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

const AdvatixOnboarding = () => {
    const [filter, setFilter] = useState("unknown")
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState([])
    const [selectedKnown, setSelectedKnown] = useState([])
    const [events, setEvents] = useState([{label: "All events", value: "allEvents"}])
    const [noEvents, setNoEvents] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [selectedSend, setSelectedSend] = useState([])
    const [permission, setPermission] = useState(false);
    const [error,setError] = useState(null);
    const [fileUploadStatus,setFileUplaodStatus]=useState('ready_to_upload');
    const [selectAllClicked,setSelectAllClicked]=useState(false);

    const verifyPermission = async () => {
        setLoading(true)
        try {
          const permission = await authService.checkLimitedPermissions(13)
          if(permission.access) {
            setPermission(true)
          }
          setLoading(false)
        } catch (error) {
          if(error?.response?.status === 403 || !error?.response?.data?.access) {
            setPermission(false)
          }
          setLoading(false)
        }
      }
    
      useEffect(() => {
        verifyPermission()
      }, [])
  
    const handleSelectedItem = (type, obj) => {
        if(type === 'markKnown') {
            const isThere = selectedKnown.find(item => item.sku === obj.sku)
            if(isThere) {
                setSelectedKnown(prev => prev.filter(item => item.sku !== obj.sku))
            } else {
                setSelectedKnown([...selectedKnown, obj])
            }
        } else if (type === 'send') {
            const isThere = selectedSend.find(item => item.sku === obj.sku)
            if(isThere) {
                setSelectedSend(prev => prev.filter(item => item.sku !== obj.sku))
            } else {
              setSelectedSend([...selectedSend, obj])
          }
      }
    }

    const getUpdateType = (item) => {
        // item can be a varaint or anything else
        if(item?.advatix_status === 'unknown') {
            return {
                type: 'send'
            }
        }
        if(item?.advatix_status === 'sent') {
            return {
                type: 'markKnown'
            }
        }
    }

    const handleSelectAll = () => {
        let sendItems = []
        let markKnownItems = []
        setSelectAllClicked(!selectAllClicked)
        if(selectAllClicked) {
            setSelectedKnown([])
            setSelectedSend([])
            return;
        }
        for(let obj of products) {
            if(obj?.variants?.length) {
                for(let variant of obj?.variants) {
                    const item = {
                        id:obj?.id,
                        sku:variant?.sku,
                        storeItemId:obj?.store_item?.id,
                        isVariant:1
                    }
                    // handleSelectedItem(getUpdateType(variant)?.type, item)
                    if(getUpdateType(variant)?.type === 'markKnown') {
                        markKnownItems.push(item)
                    }
                    else if(getUpdateType(variant)?.type === 'send') {
                        sendItems.push(item)
                    }
                }
            }
            else {
                const item = {
                    id:obj?.id,
                    sku: obj?.store_item ? obj?.store_item.sku : obj?.sku,
                    storeItemId: obj?.store_item ? obj?.store_item.id : null,
                    isVariant: 0
                }
                // handleSelectedItem(getUpdateType(obj?.store_item ? obj?.store_item : obj)?.type, item)
                if(getUpdateType(obj?.store_item ? obj?.store_item : obj)?.type === 'markKnown') {
                    markKnownItems.push(item)
                }
                else if(getUpdateType(obj?.store_item ? obj?.store_item : obj)?.type === 'send') {
                    sendItems.push(item)
                }
            }
        }
        setSelectedKnown(markKnownItems)
        setSelectedSend(sendItems)
    }
  
    const handleFilterChange = (e) => {
      setSelectedKnown([])
      setSelectedSend([])
      setSelectAllClicked(false)
      setFilter(e.target.value)
    }
  
    const handleSubmit = async () => {
      if(!selectedKnown && !selectedSend.length) return
      if(!selectedEvent.value) return
      setLoading(true)
      try{

          if(selectedKnown.length > 0 && selectedSend.length > 0) {
            if(selectedEvent?.value === 'allEvents') {
                await auctionServices.markAdvatixStatusAllEvents('known', selectedKnown)
                await auctionServices.markAdvatixStatusAllEvents('ready_to_send', selectedSend)
            }else {
                await auctionServices.markAdvatixStatus('known', selectedKnown, selectedEvent.value)
                await auctionServices.markAdvatixStatus('ready_to_send', selectedSend, selectedEvent.value)
            }
          } else if(selectedKnown.length > 0) {
            if(selectedEvent?.value === 'allEvents') {
                await auctionServices.markAdvatixStatusAllEvents('known', selectedKnown)
            }else {
                await auctionServices.markAdvatixStatus('known', selectedKnown, selectedEvent.value)
            }
          } else if (selectedSend.length > 0) {
            if(selectedEvent?.value === 'allEvents') {
                await auctionServices.markAdvatixStatusAllEvents('ready_to_send', selectedSend)
            }
            else {
                await auctionServices.markAdvatixStatus('ready_to_send', selectedSend, selectedEvent.value)
            }
          }
          setSelectedKnown([])
          setSelectedSend([])
          setSelectAllClicked(false)
          await fetchProducts()
      } catch(error){
        setError('Somthing went wrong')
      }finally{
          setLoading(false)
      }
    }

    const fetchProductsWithoutEvent = async(status) => {
        return await auctionServices.getAllItemsByAdvatixStatus(1, 50000, status)
        // return data
    }
  
    const fetchProducts = async () => {
        try {
            if(!selectedEvent) {
                setProducts([]);
                return;
            }
            let data;
            if(filter === 'all') {
                if(selectedEvent?.value === "allEvents") {
                    data = await fetchProductsWithoutEvent(filter)
                    setProducts(data?.result);
                }
                else {
                    data = await auctionServices.getItemsForAdvatixTag(selectedEvent.value, 'all')
                    setProducts(data);
                }
            } else {
                // if(!selectedEvent) {
                //     setProducts([]);
                //     return;
                // }
                if(selectedEvent?.value === "allEvents") {
                    data = await fetchProductsWithoutEvent(filter)
                    setProducts(data?.result);
                }
                else {
                    data = await auctionServices.getItemsForAdvatixTag(selectedEvent.value, filter)
                    setProducts(data);
                }
            }
        } catch (error) {
            if(error.response && error.response.status === 403) {
                setPermission(false)
            }
        }
    }

    useEffect(() => {
        if(permission) {
            (async () => {
                setLoading(true)
                const data = await auctionServices.getAllEventsForAdmin();
                const mapped = data?.data?.map(evt => ({label: `${evt?.title} ( ${getEventType(evt)} )`, value: evt?.id}))
                setEvents([...events, ...mapped])
                if(data.data.length === 0) {
                    setNoEvents(true)
                }
                setLoading(false)
            })()
        }
    }, [permission])
  
    useEffect(() => {
        if(permission) {
            (async () => {
                setLoading(true)
                await fetchProducts()
                setLoading(false)
            })()
        }
    }, [filter, selectedEvent])

    const UpdateKnown = async(file)=>{
        if(!file) return setError('Please choose a file')
        setFileUplaodStatus('loading')

        try{
            const formData = new FormData();
            formData.append("advatixBatchFile",file)
         await auctionServices.updateKnown(formData)
         setFileUplaodStatus('success')
         setError('File uploaded and advatix status updated successfully')
        }catch(error){
            setError('Something went wrong')
         setFileUplaodStatus('failed')
        }

    }

    const handleEventChange = (e) => {
        setSelectAllClicked(false)
        setSelectedKnown([])
        setSelectedSend([])
        setSelectedEvent(e)
    }

  return (
   <>
    {loading ? 
    <Container style={{position: "relative"}} title='ADVATIX Onboarding'>
        <LoadingModal isOpen={loading} />
    </Container> : (
         <Container title='ADVATIX Onboarding'>
         {!permission ? <AccessDenied /> : (
             <>
                 <div style={{display: "flex", alignItems: "center", gap: "6px"}}> 
                 <CalendarMonthOutlinedIcon sx={{fontSize: "18px", color: "lightblue"}} />
                 <div style={{fontSize: "14px"}}>Select An Event</div>
                 </div>
                 <hr />
                 <div className='adv-event-container'>
                     <DropDown placeholder="Choose an Event" value={selectedEvent} onChange={(e) => handleEventChange(e)} isSearchable label="Select Event" options={events} />
                     <FormControlLabel onChange={handleFilterChange} value="all" sx={{ '& .MuiFormControlLabel-label': {fontSize: 14}}} 
                          control={<Checkbox checked={filter === 'all'} 
                          sx={{"&.Mui-checked": {color: redColor,}}}
                          size="small" />} label="Show All"
                     />
                     <FormControlLabel onChange={handleFilterChange} value="unknown" sx={{ '& .MuiFormControlLabel-label': {fontSize: 14}}} 
                          control={<Checkbox checked={filter === 'unknown'} 
                          sx={{"&.Mui-checked": {color: redColor,}}} 
                          size="small" />} label="Show Unknown"
                     />
                     <FormControlLabel onChange={handleFilterChange} value="known" sx={{ '& .MuiFormControlLabel-label': {fontSize: 14}}} 
                          control={<Checkbox checked={filter === 'known'}
                          sx={{"&.Mui-checked": {color: redColor,}}}
                          size="small" />} label="Show Known"/>
                     <FormControlLabel onChange={handleFilterChange} value="sent" sx={{ '& .MuiFormControlLabel-label': {fontSize: 14}}} 
                          control={<Checkbox checked={filter === 'sent'}
                          sx={{"&.Mui-checked": {color: redColor,}}}
                          size="small" />} label="Show Sent"/>
                 </div>
                 <br />
                <div className='adv-heading-container'>
                <div style={{display: "flex", alignItems: "center", gap: "6px"}}> 
                 <CalendarMonthOutlinedIcon sx={{fontSize: "18px", color: "lightblue"}} />
                 <div style={{fontSize: "14px"}}>Items</div>
                 </div>
                 <div className="actions_container">
                   {
                    (products?.length && !['known']?.includes(filter)) ? 
                      <button className="aitem-modal-btn aitem-white-button" onClick={handleSelectAll} type="submit">{selectAllClicked ? 'Remove All' : 'Mark All'}</button>
                    : null
                   }
                   <UploadFile uploadStatus={fileUploadStatus} buttonText='Batch Update Known' onUploadFile={UpdateKnown} />
                 </div>
                </div>
                 <hr />
                 <Paper elevation={0}>
                     {loading ? <div className='adv-no-data'>Loading</div> : noEvents ? <div className='adv-no-data'>No Events Found with the user</div> : (
                         <>
                         {!selectedEvent ? <div className='adv-no-data'>Please select an Event</div> : products?.length === 0 ? <div className='adv-no-data'>No Data</div> : (
                                 <>
                                     <TableContainer>
                                         <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                         <TableHead>
                                             <TableRow>
                                                 <StyledTableCell>Product</StyledTableCell>
                                                 <StyledTableCell>SKU</StyledTableCell>
                                                 <StyledTableCell>Status</StyledTableCell>
                                                 <StyledTableCell>Send Date</StyledTableCell>
                                                 <StyledTableCell>Send?</StyledTableCell>
                                                 <StyledTableCell>Mark As Known</StyledTableCell>
                                             </TableRow>
                                         </TableHead>
                                         <TableBody>
                                             {products?.length ? products?.map((row) => {
                                                 return <ItemRow handleSelectedItem={handleSelectedItem} rowData={row} setSelectAllClicked={setSelectAllClicked} selectedSend={selectedSend} selectedKnown={selectedKnown} />
                                             }) : null}
                                         </TableBody>
                                         </Table>
                                     </TableContainer>
                                     {/* <TablePagination
                                         rowsPerPageOptions={[10, 20]}
                                         component="div"
                                         count={totalEvents}
                                         rowsPerPage={limit}
                                         page={page - 1}
                                         onPageChange={(e, newPage) => setPage(newPage + 1)}
                                         onRowsPerPageChange={(e) => {setLimit(+e.target.value);setPage(1)}}
                                     /> */}
                                 </>
                             )}
                         </>
                     )}
                 </Paper>
                 <div className='aitem-submit-container'>
                     <button className='aitem-modal-btn' disabled={!selectedEvent || (!selectedKnown.length && !selectedSend.length)} onClick={handleSubmit} type='button'>Finish and Send</button>
                 </div>
             </>
         )}
         <Alert isOpen={!!error} message={error} onClose={()=>setError(null)}/>
     </Container>
    )}
   </>
  )
}

export default AdvatixOnboarding