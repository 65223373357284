import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import auctionServices from "../services/auction-services";


export const getItemsByEventId = createAsyncThunk(
    `items/getItemsByEventId`,
    async({eventId, limit,page}) => {
        const response = await auctionServices.getAllItemsForEvent(eventId, limit,page)
        return {
            data: response.data,
            totalItems:response.totalCount,
            totalPage:response.totalPage
        }
    }
)

const initialState = {
    items:[],
    totalItems:0,
    isLoading:false,
    totalPage:0,
    limit:20,
    page:1,
    error:''
}

const itemsByEventIdSlice = createSlice({
    name:'itemSlice',
    initialState,
    reducers: {
        setItemsPage: (state, action) => {
            state.page = action.payload.page
        },
        setItemsLimit: (state, action) => {
            state.limit = action.payload.limit
        },
        setItemsLoading: (state, action) => {
            state.isLoading = action.payload.loading
        },
        clearItemsState: (state, action) => {
            state.limit = 20
            state.page = 1
            state.items = []
            state.totalItems = 0
            state.totalPage = 0
        }
    },
    extraReducers: (builder)=> {
        builder.addCase(getItemsByEventId.pending, (state, action) => {
            state.isLoading=true
        })
        builder.addCase(getItemsByEventId.fulfilled, (state, action) => {
            state.items = action.payload.data
            state.totalItems = action.payload.totalItems
            state.totalPage = action.payload.totalPage
            state.isLoading = false
            state.error = ''
        })
        builder.addCase(getItemsByEventId.rejected, (state, action) => {
            state.isLoading=false
            state.error=action.error.message
        })

    }
})

export default itemsByEventIdSlice.reducer
export const {setItemsLimit, setItemsPage, setItemsLoading, clearItemsState} = itemsByEventIdSlice.actions