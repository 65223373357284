import React from 'react'
import { SortableContainer, SortableElement } from "@rexxars/react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import auctionServices from '../../../services/auction-services'
import { useParams } from 'react-router-dom';
import { IconButton, Menu, MenuItem, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';


const SortableItem = SortableElement(({ item, forCustomCategory }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleCustomSorting = (id, heading) => {
    forCustomCategory(id, heading)
    setAnchorEl(null);
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

    return (
      <>
        <div style={{zIndex:9999, alignItems:'center'}} className='items-drag-card'>
            { item?.image_key ? <div className='items-drag-card-img'>
                <img src={item?.image_key} alt="" />
            </div> : null}
            <div className='items-drag-card-content'>
                <div className='items-drag-card-header'>{item?.name ? item?.name : item?.heading }</div>
            </div>
                 {(item?.heading || item?.heading === "") && 
                  <div style={{flexGrow:1}}>
                        <IconButton
                          sx={{float:"right"}}
                          style={{border:'none', background:'white', float:"right"}}
                          type='button'
                          id="demo-positioned-button"
                          aria-controls={open ? 'demo-positioned-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                        >
                          <MoreVertIcon sx={{width:'100%', pointerEvents:'none'}} />
                        </IconButton>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <MenuItem onClick={() => {handleCustomSorting(item?.id, item?.heading)}}>Update Custom Category Display Order</MenuItem>
                        </Menu>
                    </div>}
        </div>
      </>
    )
});


const SortableList = SortableContainer(({ items, forCustomCategory }) => {
    return (
      <div>
        {items.map((item, index) => (
          <SortableItem key={`item-${item.id}`} index={index} item={item} forCustomCategory={forCustomCategory} />
        ))}
      </div>
    );
  });


const SortableComponent = ({items, setItems, forCustomCategory}) => {
    const onSortEnd = ({ oldIndex, newIndex }) => {
      setItems(items => arrayMoveImmutable(items, oldIndex, newIndex));
    };
    return (
      <>
        <SortableList items={items} onSortEnd={onSortEnd} lockAxis="y" forCustomCategory={forCustomCategory} />
      </>
    );
  };


const CustomCategoryPriorityModalComponent = ({onDone, category, setPriorityCategory, setIsLoading, customCategoryHeadingId, setAlertMsg, setPriorityOrderModal}) => {
    const {auction_id} = useParams()
    const updateOrder = async(navigateAction) => {
        if(navigateAction ==='navigateBack') {
            setPriorityOrderModal(false)
            return;
        }
        setIsLoading(true)
        const constructArray = category?.map((item, index) => {
            return {
              id: item.id,
              display_order: index + 1
            }
          });
          try{
          const response =  await auctionServices.updateCustomCategoryDisplayOrder(customCategoryHeadingId?.customCatId, constructArray)
          if(response){
            setAlertMsg(response?.message)
          }
          onDone()
          setIsLoading(false)
          setPriorityOrderModal(false)
          }catch (error) {
            setIsLoading(false)
            setAlertMsg(error.response.data.message)
          }
    }

  return (
    <div style={{ position:'relative'}}>
    <div style={{backgroundColor:'white', fontSize:'20px', justifyContent:'center', padding:"0px"}} className='bg-modal-title-container'>
        <div style={{textAlign:'center'}} >{customCategoryHeadingId?.heading}</div>
    </div>
     <SortableComponent items={category} setItems={setPriorityCategory} />
     <div style={{ textAlign: "right", display:'flex', justifyContent:'space-between' }}>
                    
                  <button
                      type="button"
                      className="aitem-modal-btn"
                      onClick={() => {updateOrder('navigateBack')}}
                    >
                      Go Back
                   </button>

                    <button
                      type="button"
                      className="aitem-modal-btn"
                      onClick={updateOrder}
                    >
                      Update Display Order
                    </button>
                  </div>
    </div>
  )
}

export default CustomCategoryPriorityModalComponent


export const CustomCategoryHeadingPriorityModalComponent = ({
  onHeadingDone,
  category,
  setPriorityCategory,
  setIsLoading,
  setAlertMsg,
  setHeadingPriorityOrderModal,
  forCustomCategory
}) => {
  const {auction_id} = useParams()
    const updateHeadingOrder = async(e,navigateAction) => {
        if(navigateAction ==='navigateBack') {
          setHeadingPriorityOrderModal(false)
            return;
        }
        setIsLoading(true)
        const constructArray = category?.map((item, index) => {
            return {
              id: item.id,
              display_order: index + 1
            }
          });
          try{
          let heading = true
          const response =  await auctionServices.updateCustomCategoryDisplayOrder(auction_id, constructArray, heading)
          if(response){
            setAlertMsg(response?.message)
          }
          onHeadingDone()
          setIsLoading(false)
          // setPriorityOrderModal(false)
          }catch (error) {
            setIsLoading(false)
            setAlertMsg(error.response.data.message)
          }
    }
   return (
    <div style={{ position:'relative'}}>
     <SortableComponent items={category} setItems={setPriorityCategory} forCustomCategory={forCustomCategory} />
     <div style={{ textAlign: "right", display:'flex', justifyContent:'space-between' }}>
                    
                  <button
                      type="button"
                      className="aitem-modal-btn"
                      onClick={(e) => {updateHeadingOrder(e,'navigateBack')}}
                    >
                      Go Back
                   </button>

                    <button
                      type="button"
                      className="aitem-modal-btn"
                      onClick={updateHeadingOrder}
                    >
                      Update Display Order
                    </button>
                  </div>
    </div>
   )
}