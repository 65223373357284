
const localStorageClearStates = [
    'eventData',
    'extraEventState',
    'storeData',
    'extraStoreState',
    'marketplaceData',
    'extraMarketplaceState',
    
    'eventStoreItem',
    'eventExtraStoreItem',
    'storeStoreItem',
    'storeExtraStoreItem',
    'marketplaceStoreItem',
    'marketplaceExtraStoreItem',
    
    'eventAuctionItem',
    'eventExtraAuctionItem',
    'storeAuctionItem',
    'storeExtraAuctionItem',
    'marketplaceAuctionItem',
    'marketplaceExtraAuctionItem',
  ]

const localStorageSpace = () => {
    let allStrings = '';
    for (const key of Object.keys(localStorage)) {
      allStrings += localStorage[key];
    }
    // return allStrings ? 3 + ((allStrings.length * 16) / (8 * 1024)) + ' KB' : 'Empty (0 KB)';
    // checking localstorage space in kb
    return allStrings ? 3 + ((allStrings.length * 16) / (8 * 1024)): 0
  }

  const checkAndClearLocalStorage = (localStorageCurrentSize) => {
    // 2560 means 2.5mb in kb
    // 3072 means 3mb in kb
    // console.log(localStorageCurrentSize >= 3072, localStorageCurrentSize, 3072,"localStorageCurrentSize >= 3072")
    if(localStorageCurrentSize >= 3072) {
      console.log(`LocalStorage exceeds 3 MB (${localStorageCurrentSize}). Clearing...`);
      for(let item of localStorageClearStates) {
            localStorage.removeItem(item);
          }
    }
  }


 export const setItemToLocalStorage = (key='', value=null) => {
    try {
        if(!value) return ;
        const localStorageCurrentSize = localStorageSpace()
        checkAndClearLocalStorage(localStorageCurrentSize)
        localStorage.setItem(key, JSON.stringify(value))
    }catch (error) {
        // console.log(error)
    }
  }