import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Modal from '../Modal/Modal'
import ImageInput from '../ImageInput/ImageInput'
import classes from './CustomImagesModal.module.css'
import checkImageBeforeUpload from '../../../utils/checkImageBeforeUpload'
import constants from '../../../constants/en'
import auctionServices from '../../../services/auction-services'
import Alert from '../Alert/Alert'
import { Tooltip } from '@mui/material'

const activeStyle = {
    border: '2px solid #9e55c2'
}

const userContainerStyle = {
    margin:0
}

const CustomImagesModal = ({stringLowerName, isOpen, onDone, onClose, images, permission = {}, setIsLoading, handleImageState, setAlertMsg, handleCustomImageSelect }) => {
    const [imageUrl, setImageUrl] = useState({})
    const [activeState, setActiveState] = useState(false)
    const [activeExtraState, setActiveExtraState] = useState(false)
    const [imageState, setImageState] = useState('')
    const [uploadingImages, setUploadingImages] = useState([])
    const [imageLoading, setImageLoading] = useState({ normalImage: false, godImage: false })
    const [formDetails, setFormDetails] = useState({new_images:[], image_location: images?.flag})
    const [sideLabelText, setSideLabelText] = useState('');
    const [errorMessage,setErrorMessage]= useState('');
    const [bgWhiteBorderAlert,setBgWhiteBorderAlert] = useState(false);
    const [whiteBorderAlert,setWhiteBorderAlert] = useState(null);
    const [file,setFile] =useState(null);


    const handleSelectedImage = (imageKey) => {
        setActiveState(true)
        setActiveExtraState(false)
        setImageUrl({ imageKey: imageKey, flag: images?.flag })
        setErrorMessage('')
    }
    useEffect(() => {
        setImageState("")
        setImageUrl({})
        setUploadingImages([])
        if(images?.flag === 'portrait') setSideLabelText(constants.ADMIN_COPY_CHARITY_PORTRAIT_IMAGE_SIDELABEL?.slice(7));
        if(images?.flag === 'landscape') {
            if(stringLowerName === 'marketplace') {
                setSideLabelText(constants.ADMIN_COPY_MARKETPLACE_LANDSCAPE_IMAGE_SIDELABEL?.slice(7));
            }
            else {
                setSideLabelText(constants.ADMIN_COPY_CHARITY_LANDSCAPE_IMAGE_SIDELABEL?.slice(7));
            }
        }
        if(images?.flag === 'auction_item' || images?.flag === 'buy_now_item') setSideLabelText(constants.ADMIN_COPY_CHARITY_BROWSE_AUCTION_IMAGE_SIDELABEL?.slice(7));
    }, [isOpen])

    const deleteSelectedImage = () => {
        setImageState("")
    }

    const checkLandcapeImageProperties = () => {
                    let preprocessedWidthLandscape = 1908;
                    let preprocessedHeightLandscape = 954;
                    let widthByHeight = 1.95;
                    let sizeAlert = constants.ADMIN_COPY_EVENT_LANDSCAPE_IMG_SIZE;
                    let imgRatio = '2:1';
                    if(stringLowerName === 'marketplace'){
                        preprocessedWidthLandscape = 1908;
                        preprocessedHeightLandscape = 532;
                        widthByHeight = 3;
                        sizeAlert = constants.ADMIN_COPY_MARKETPLACE_LANDSCAPE_IMG_SIZE;
                        imgRatio = '25:7';
                    }
                    return {
                        preprocessedWidthLandscape,
                        preprocessedHeightLandscape,
                        widthByHeight,
                        sizeAlert,
                        imgRatio,
                    }
    }

    const checkInvalidImageSize =async (image) =>{
            let invalid = false
          let preprocessedWidth;
          let preprocessedHeight;
          const imageUrl = URL.createObjectURL(image)
          const { width, height } = await checkImageBeforeUpload(imageUrl)
        if (images?.flag === "portrait") {
            preprocessedWidth = 1125;
            preprocessedHeight = 1350;
            if (width < 1125 || height < 1350) {
                setAlertMsg(constants.ADMIN_COPY_EVENT_IMG_SIZE)
                setImageLoading({ normalImage: false });
                invalid = true
                return invalid
            }
            return invalid
        }
        if (images?.flag === "landscape") {
            const landscapeProperties = checkLandcapeImageProperties()
            preprocessedWidth = landscapeProperties.preprocessedWidthLandscape;
            preprocessedHeight = landscapeProperties.preprocessedHeightLandscape;
            if (width < preprocessedWidth || height < preprocessedHeight) {
                setAlertMsg(landscapeProperties?.sizeAlert)
                setImageLoading({ normalImage: false });
                invalid = true
                return invalid
            }
            return invalid
        }
        if (images?.flag === "auction_item" || images?.flag === "buy_now_item") {
            preprocessedWidth = 300;
            preprocessedHeight = 200;
            if (width < 300 || height < 200) {
                setAlertMsg(constants.ADMIN_COPY_EVENT_BROWSE_IMG_SIZE)
                setImageLoading({ normalImage: false });
                invalid = true
                return invalid
            }
            return invalid
        }
       
    }
    const checkInvalidAspectRatio = async (image) =>{
        let preprocessedWidth;
        let preprocessedHeight;
        let invalid = false
        const imageUrl = URL.createObjectURL(image)
        const { width, height } = await checkImageBeforeUpload(imageUrl)
      if (images?.flag === "portrait") {
          preprocessedWidth = 1125;
          preprocessedHeight = 1350;
          if ((width/height < 0.8167) || (width/height > preprocessedWidth/preprocessedHeight)) {
              setWhiteBorderAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','5:6'))
              setImageLoading({ normalImage: false });
              invalid = true
              return invalid
          }
          return invalid
      }
      if (images?.flag === "landscape") {
          const landscapeProperties = checkLandcapeImageProperties()
          preprocessedWidth = landscapeProperties.preprocessedWidthLandscape;
          preprocessedHeight = landscapeProperties.preprocessedHeightLandscape;
          if ((width/height < landscapeProperties?.widthByHeight) || (width/height > preprocessedWidth/preprocessedHeight)) {
              setWhiteBorderAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()',landscapeProperties?.imgRatio))
              setImageLoading({ normalImage: false });
              invalid = true
              return invalid
          }
          return invalid
      }
      if (images?.flag === "auction_item" || images?.flag === "buy_now_item") {
          preprocessedWidth = 300;
          preprocessedHeight = 200;
          if ((width/height < 1.47) || (width/height > preprocessedWidth/preprocessedHeight)) {
              setWhiteBorderAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','3:2'))
              setImageLoading({ normalImage: false });
              invalid = true
              return invalid
          }
          return invalid
      }

     
    }
    const onFileChange = async (e,confirm) => {
        if (!e.target.files[0]) return
        try{
            const newFile = e.target.files[0]
            setImageLoading({ normalImage: true });
            if (newFile.size > 5000000) {
                setAlertMsg(constants.ADMIN_COPY_EVENT_IMG_FILE_SIZE)
                setImageLoading({ normalImage: false });
                return 
              }
            setActiveState(false)
            setActiveExtraState(true)

            if(await checkInvalidImageSize(newFile)){
                return
            }
            if(!confirm){
                if(await checkInvalidAspectRatio(newFile) ){
                    setFile(e)
                    return
                }
            }
                if(confirm){
                    setFile(null)
                }
                const returnValue = await handleImageState(newFile, images?.flag)
                setImageLoading({ normalImage: false });
                setImageState(returnValue)
                setImageUrl({ imageKey: returnValue, flag: images?.flag })
                setErrorMessage('');
            

        }catch (error) {
            setImageLoading({ normalImage: false });
        }
    }

    const handleExtraImageClick = () => {
        setActiveExtraState(true);
        setActiveState(false);
        setImageUrl({ imageKey: imageState, flag: images?.flag })
    }


    const handleMultipleImages = async (e,confirm) => {
        if (e.target.files.length === 0) return
        setImageLoading({ godImage: true })
        const filesUrl = []
        const selectedFiles = []
        const targetFilesObj = [...e.target.files]
        const preProcessedFiles = []
        if (targetFilesObj.every((currentValue) => currentValue.size <= 5000000)) {
            targetFilesObj.map((file) => {
                return filesUrl.push({ name: URL.createObjectURL(file), type: 'img', local: true });
            });
            let preprocessedWidth;
            let preprocessedHeight;
            for (const url of filesUrl) {
                const { width, height } = await checkImageBeforeUpload(url.name)
                if (images?.flag === "portrait") {
                    preprocessedWidth = 1125;
                    preprocessedHeight = 1350;
                    if (width < 1125 || height < 1350) {
                        setAlertMsg(constants.ADMIN_COPY_EVENT_IMG_SIZE)
                        setImageLoading({ godImage: false })
                        return
                    }
                    if(!confirm){
                        if ((width/height < 0.8167) || (width/height > preprocessedWidth/preprocessedHeight)) {
                            setFile(e)
                            setBgWhiteBorderAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','5:6'))
                            setImageLoading({ godImage: false });
                            return 
                        }
                    }
                }
                if (images?.flag === "landscape") {
                    const landscapeProperties = checkLandcapeImageProperties()
                    preprocessedWidth = landscapeProperties.preprocessedWidthLandscape;
                    preprocessedHeight = landscapeProperties.preprocessedHeightLandscape;
                    if (width < preprocessedWidth || height < preprocessedHeight) {
                        setAlertMsg(landscapeProperties?.sizeAlert)
                        setImageLoading({ godImage: false })
                        return
                    }
                    if(!confirm){
                        if ((width/height < landscapeProperties?.widthByHeight) || (width/height > preprocessedWidth/preprocessedHeight)) {
                            setFile(e)
                            setBgWhiteBorderAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()',landscapeProperties?.imgRatio))
                            setImageLoading({ godImage: false });
                            return 
                        }
                    }
                }
                if (images?.flag === "auction_item" || images?.flag === "buy_now_item") {
                    preprocessedWidth = 300;
                    preprocessedHeight = 200;
                    if (width < 300 || height < 200) {
                        setAlertMsg(constants.ADMIN_COPY_EVENT_BROWSE_IMG_SIZE)
                        setImageLoading({ godImage: false })
                        return
                    }
                    if(!confirm){
                        if ((width/height < 1.47) || (width/height > preprocessedWidth/preprocessedHeight)) {
                            setFile(e)
                            setBgWhiteBorderAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','3:2'))
                            setImageLoading({ godImage: false });
                            return 
                        }
                    }
                }
            }

            try {
                if(confirm){ 
                    setFile(null)
                }
                for (const image of targetFilesObj) {
                    const formData = new FormData()
                    formData.append("image", image)
                    formData.append("width", preprocessedWidth)
                    formData.append("height", preprocessedHeight)

                    const preProcessedImage = await auctionServices.imagePreProcess(formData);
                    selectedFiles.push({ name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img', local: true });
                    const uint8 = new Uint8Array(preProcessedImage.buffer.data);
                    const newBlob = new Blob([uint8], { type: "image/jpeg" })
                    preProcessedFiles.push(newBlob)
                }
            } catch (error) { setImageLoading({ godImage: false }) }

            setUploadingImages([...uploadingImages, ...selectedFiles]);
            setFormDetails({...formDetails, new_images: preProcessedFiles});
            setImageLoading({ godImage: false });
        }
        else {
            setAlertMsg(constants.ADMIN_COPY_EVENT_IMG_FILE_SIZE);
            setImageLoading({ godImage: false }) 
            return
        }
    }

    const deleteUploadingImages = (url, imageIdx) => {
        setUploadingImages(uploadingImages?.filter((item, idx) => url !== item.name))
    }

    const handleNewBgImages = async() => {
         try{
            if(!formDetails.new_images?.length) {
                alert('Please upload images first')
                return;
            }
            const formData = new FormData()
            formData.append("image_location", `${stringLowerName.slice(0,6)}_${images?.flag}`)
            formDetails.new_images && 
                formDetails.new_images?.map(image => formData.append('new_images', image))
            setIsLoading(true)
            const data = await auctionServices.uploadBgImages(formData)
            setIsLoading(false)
            setUploadingImages([])
            setFormDetails({image_location:'', new_images:[]})
            setAlertMsg(data.message)
            handleCustomImageSelect(images?.flag)
         } catch(error) {
            setIsLoading(false)
            setAlertMsg(error.response.data.message)
         }
    }

    const saveAndContinue=(imageUrl, activeExtraState)=>{
        if(!imageUrl?.imageKey || imageUrl?.imageKey === '' ) return setErrorMessage('Please choose an image')
        onDone(imageUrl, activeExtraState)
    }

    return (
        <>
            <Modal isOpen={isOpen} onDone={() => { saveAndContinue(imageUrl, activeExtraState) }} onClose={onClose} title={'Choose an image'} extraButtonRequired={permission?.godPermission && uploadingImages?.length} extraButtonText='Upload Default Images' onExtraButtonDone={handleNewBgImages} >
                <section>
                    {images?.images && images?.images?.length ? <p style={{ textAlign: 'center', marginBottom: '10px', textTransform: 'capitalize' }}><b>{images?.flag?.replace(/_/g, ' ')}</b></p> : null}
                    {errorMessage && <p style={{color:'red',textAlign: 'center'}}>{errorMessage}</p>}
                    <div className={classes.container} >
                        <div className={classes.section}>
                            {images?.images && images?.images?.length ?
                                images?.images?.map((item, i) => (
                                    <div key={i} onClick={() => { handleSelectedImage({image:item?.image_key?.image+'_small',db_key:item?.image_key?.db_key}) }} className={classes.image_wrapper} >
                                        <ImageInput
                                            images={item.image_key?.image ? [{ name: item.image_key?.image+'_small' }] : []}
                                            // _small is a variant of the image
                                            activeStyle={(activeState && imageUrl?.imageKey?.db_key === item?.image_key.db_key) ? activeStyle : {}}
                                        />
                                    </div>
                                ))
                                : <div style={{width:"100%", textAlign:'center'}} >No default images available</div>
                            }
                            {permission?.newImagePermission && 
                            <Tooltip placement='top' title={sideLabelText} >
                                <div style={images?.images && images?.images?.length ? userContainerStyle : {}} className={classes.userImage_container} onClick={handleExtraImageClick}>
                                <ImageInput
                                    edit
                                    onDelete={deleteSelectedImage}
                                    handleChange={(e)=>onFileChange(e,false)}
                                    images={(imageState) ? [{ name: imageState }] : []}
                                    activeStyle={(activeExtraState && imageState) ? activeStyle : {}}
                                    loading={imageLoading.normalImage}
                                />
                            </div>
                            </Tooltip>
                            }
                        </div>

                        {permission?.godPermission && <div style={{width:"80%"}}>
                            <ImageInput
                                label={`Upload default ${images?.flag?.replace(/_/g, ' ')} images for ${stringLowerName}`}
                                multiple
                                edit
                                onDelete={deleteUploadingImages}
                                images={uploadingImages}
                                handleChange={(e)=>handleMultipleImages(e,false)}
                                loading={imageLoading.godImage}
                                iButton={true}
                                infoBubbleText={`Please upload additional ${images?.flag?.replace(/_/g, ' ')} images here. Once uploaded, the images will be displayed in the gallery, allowing users to choose them for their ${stringLowerName}.`}
                                sidelabel={sideLabelText}
                                max='20'
                            />
                        </div>}

                    </div>
                </section>
            </Modal>
            <Alert isOpen={!!whiteBorderAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                onFileChange(file,true);
                setWhiteBorderAlert(null)
                }} 
                onClose={()=>setWhiteBorderAlert(null)} 
                message={whiteBorderAlert}/>
           <Alert isOpen={!!bgWhiteBorderAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handleMultipleImages(file,true);
                setBgWhiteBorderAlert(null)
                }} 
                onClose={()=>setBgWhiteBorderAlert(null)} 
                message={bgWhiteBorderAlert}/>
        </>
    )
}

export default CustomImagesModal