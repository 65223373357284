import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import auctionServices from '../services/auction-services'

export const fetchCategorySalesReportRedux = createAsyncThunk(
    'auction/fetchCategorySalesReportRedux',
    async ({ auctionId, page, limit, pageType = "event", orderSearchValue, orderSearchBy }) => {
        const response = await auctionServices.getCategorySalesReport(auctionId, page, limit, pageType, orderSearchValue, orderSearchBy)
        return {
            data: response?.data,
            categorySalesTotalCount: response?.count,
            categorySalesTotalPage: response?.total_page
        }
    }
)

const initialState = {
    cateogrySalesData: [],
    categorySalesTotalCount: 0,
    categorySalesReportLoading: false,
    categorySalesTotalPage: 0,
    categorySalesHeading: ['Category Name', 'Number Of Items Sold', 'Total Sales Amount (excl. tax and charges)'],
    error: ''
}

const categorySalesReportSlice = createSlice({
    name: 'auction/item-sales',
    initialState,
    reducers: {
        clearCategorySalesReport: (state, action) => {
                state.cateogrySalesData = []
                state.categorySalesTotalCount = 0
                state.categorySalesReportLoading = false
                state.categorySalesTotalPage = 0
                state.categorySalesHeading = ['Category Name', 'Number Of Items Sold', 'Total Sales Amount (excl. tax and charges)']
                state.error = ''
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCategorySalesReportRedux.pending, (state, action) => {
            state.categorySalesReportLoading = true
        })
        builder.addCase(fetchCategorySalesReportRedux.fulfilled, (state, action) => {
            state.cateogrySalesData = action.payload?.data
            state.categorySalesTotalCount = action.payload?.categorySalesTotalCount
            state.categorySalesReportLoading = false
            state.categorySalesTotalPage = action.payload.categorySalesTotalPage
            state.error = ""
        })
        builder.addCase(fetchCategorySalesReportRedux.rejected, (state, action) => {
            state.categorySalesReportLoading = false
            state.error = action.payload?.error?.message
        })
    }
})

export default categorySalesReportSlice.reducer
export const { clearCategorySalesReport } = categorySalesReportSlice.actions