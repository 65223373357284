import { useDispatch } from "react-redux";
import { setItemToLocalStorage } from "../../utils/autoSave";

const usePopState = (localStorageKey, stateData, showPrompt=true) => {
  const dispatch = useDispatch()
    window.onpopstate = () => {
        if(showPrompt) {
          // localStorage.setItem(localStorageKey, JSON.stringify(stateData))
          setItemToLocalStorage(localStorageKey, stateData)
        }
        else {
        //   console.log('Not saving')
        }
      }
}

export default usePopState;//// for saving the form data in localstorage while changing the page by clicking on browser controller