import React,{useEffect, useRef, useState} from 'react';
import "./Input.css";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';


const Input = ({value, onChange, label=null, name, width = "100%", required=false, type="text",iButton=false, iButtonText="", infoSize='16px' ,style={}, labelStyle={}, inputStyle={}, infoBubbleText="",disabled=false, placeholder="" ,autoComplete='off',maxLength=null, needCharacterCount=false, ...props }) => {
  const myInput=useRef()
  const [charLeft, setCharLeft] = useState(maxLength)
  const preventScroll=(e)=>{
    if(type==='number' && myInput.current){
      myInput.current.blur()
    }
  }

    useEffect(() => {
        if(!maxLength) return
        setCharLeft(maxLength - value?.length)
    }, [value, maxLength])

  return (
    <div className='input-container' style={{width, ...style}}>
        {label && <label className='input-label' style={{...labelStyle}} htmlFor={name}>{label} {required && <span style={{color: "red"}}>*</span> } {" "} {iButton&& <Tooltip title={<Typography sx={{fontSize:'0.85rem'}}>{infoBubbleText}</Typography>}><InfoOutlinedIcon sx={{color:'#495057c4',fontSize:infoSize, marginLeft: "4px"}} /></Tooltip>}</label>}
        <input maxLength={maxLength} onWheel={preventScroll} ref={myInput} min={0} required={required} name={name} type={type} value={value} onChange={onChange} style={{...inputStyle}} {...props} disabled={disabled} placeholder={placeholder} autoComplete={autoComplete} />
        {(maxLength && needCharacterCount) && <div className='formtextarea-max-chars'>Characters Left: {charLeft}</div>}
    </div>
  )
}

export default Input