import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    blogPage:1,
    blogLimit:10
}

const managePageSlice = createSlice({
    name: "managePage",
    initialState,
    reducers: {
        
        setBlogPage: (state, action) => {
            state.blogPage = action.payload
        },
        setBlogLimit: (state, action) => {
            state.blogLimit = action.payload
            state.blogPage = 1
        },
       

    }
})

export default managePageSlice.reducer
export const {setBlogPage,setBlogLimit} = managePageSlice.actions