import Container from "../../components/HOC/Container/Container";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import auctionServices from "../../../services/auction-services";
import "./AddDonorPromo.css";
import Input from "../../components/Input/Input";
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import constants from "../../../constants/en";
const AddDonorPromo = () => {
  const location = useLocation()
  const { donor } = location.state;
  const navigate=useNavigate();
  const [promoDetailes, setPromoDetails] = useState({
    donor_id: donor.id,
    promo_link: "",
    promo_text: "",
    promo_code: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPromoDetails({ ...promoDetailes, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      if (
        promoDetailes.donor_id !== "" &&
        promoDetailes.promo_code !== "" &&
        promoDetailes.promo_link !== "" &&
        promoDetailes.promo_text !== ""
      ) {
        await auctionServices
          .addDonorPromo(promoDetailes)
          .then((res) => {
            // console.log(res);
            alert(res.data.msg);
            navigate("/admin/manage-donor")
          })
          .catch((err) => {
            throw err;
          });
      } else {
        alert(constants.ADMIN_COPY_DONOR_PROMO_FIELD_ERROR);
      }
    } catch (err) {
      throw err;
    }
  };
  return (
    <>
      <Container title="Donor Lead Gen">
      <div className="manage-donor-title"><VolunteerActivismOutlinedIcon sx={{ fontSize: 18, marginRight: "3px", color: "skyblue" }}/> Manage Donor</div>
        <div className="donor-promo-container">
          <div className="donorPromo-form-container">
            <Input label="Donor Name" value={donor.contact_firstname+" "+donor.contact_lastname} disabled />
       
         
            <Input
            required
           
             label="Promo Text"
              name="promo_text"
              value={promoDetailes.promo_text}
              onChange={handleChange}
            />

        
            <Input
            required
           
              label="Promo Link"
              name="promo_link"
              value={promoDetailes.promo_link}
              onChange={handleChange}
            />
    
     
            <Input
            required
           
            label="Promo Code"
              type="text"
              name="promo_code"
              value={promoDetailes.promo_code}
              onChange={handleChange}
            />
      </div>
          <div className="donor-promo-btn" style={{textAlign:"center"}}>
                 <button onClick={handleSubmit}>Add</button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AddDonorPromo;
