import "./ForgetPassword.css";
import { useEffect, useState } from "react";
import { TextField } from '@mui/material';
import { useNavigate,useLocation } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import parseQuery from "query-string";
import CircularProgress from '@mui/material/CircularProgress';
import AlertComp from '@mui/material/Alert';
import InputPassword from "../../components/InputPassword";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import authService from "../../../services/auth-services";
import Container from "../../components/HOC/Container/Container";

function ChangedPassword() {//Success Password Changed
    return (
      <div className="comp_wrapper">
        <Player
          autoplay
          speed={1}
          loop
          src="/success_gif.json"
          style={{ height: "200px", width: "200px" }}
        />
        <h3>Success</h3>
        <p>Please click the button below to sign in.</p>
      </div>
    )
}
function ResetPassword({password='',confirmPassword='',setPass,setConPass}) {
    return (
      <div className="comp_wrapper">
        <div className="wrapper_top">
          <h3>Create New Password</h3>
          <p>Your new password must be different from previous used passwords</p>
        </div>
        <div className="comp_reset_password_input">
          <InputPassword
            className='comp_forgot_input'
            pass={password}
            onChange={setPass}
          />
          <InputPassword
            className='comp_forgot_input'
            placeholder="Confirm Password"
            pass={confirmPassword}
            onChange={setConPass}
          />
        </div>
      </div>
    )
}
function SendResetLink({email='',setEmail}) {
  return (
    <div className="comp_wrapper">
      <div className="wrapper_top">
        <h3>Forgot your password? </h3>
        <p>Enter your email below to receive reset instructions.</p>
      </div>
      <TextField
        label='Email Address'
        id="outlined-start-adornment"
        sx={{ m: 1, width: "25ch" }}
        value={email}
        onChange={setEmail}
        className='comp_forgot_input'
      />
    </div>
  )
}
export default function ForgotPassword() {
  // 0=SendResetLink / 1=ResetPassword / 2=Success{or}ChangedPassword
  const [step,setStep] = useState(0);
  const [reqBody,setReqBody] = useState({});
  const [alert,setAlert] = useState({type: 'error',msg: '',open: false,loading:false});
  const goTo = useNavigate();
  const searchQuery = useLocation();

  useEffect(()=>{
    const {ticket} = parseQuery.parse(searchQuery.search);
    if(ticket) {
      setStep(1);
      setReqBody({...reqBody,resetToken:ticket});
    } else {
      setStep(0);
    }
  },[searchQuery]);
  useEffect(()=>{
    if(alert.open) {
      const interval = setInterval(() => {
        setAlert({...alert,open:false});
      },5000);
      return () => clearInterval(interval);
    }
  },[alert]);

  async function handleBtn() {
    if(step === 2) {
      goTo('/admin/sign-in');
      return '';
    }
    // Loading Starts Here
    setAlert({...alert,loading:true});
    // Loading Starts Here
    await authService.forgotPwdApi(step,reqBody,setStep,setAlert);
    return '';
  }

  return (
    <Container style={{width:'80%'}}>
    <div className="forgot_password">
      {step === 0?
        <SendResetLink
          email={reqBody.email}
          setEmail={(e)=>setReqBody({...reqBody,email:e.target.value})}
        />:
        step === 1?
        <ResetPassword
          password={reqBody.newPassword}
          confirmPassword={reqBody.confirmPassword}
          setPass={(e)=>setReqBody({...reqBody,newPassword:e.target.value})}
          setConPass={(e)=>setReqBody({...reqBody,confirmPassword:e.target.value})}
        />:
        <ChangedPassword />
      }
      {/* Main Content */}
      {alert.open && (
        <AlertComp style={{width:'50%'}} severity={alert.type}>{alert.msg}</AlertComp>
      )}
      <button disabled={alert.loading || alert.open} onClick={alert.loading?null:()=>handleBtn()}>
        { alert.loading ? <CircularProgress style={{color:'#fff'}} size='1.5rem' /> :
          step===2?'Sign In':step===1?'Reset Password':'Recovery Link'
        }
      </button>
      {step === 0 && (
        <div onClick={()=>goTo('/admin/sign-in')} className="back_wrapper">
          <button className="back">
            <KeyboardBackspaceIcon />
            <span>Back to Login</span>
          </button>
        </div>
      )}
    </div>
    </Container>
  )
}
