import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    event: null,
    portraitImage: null,
    landscapeImage: [],
    browseBuyNowImage:null,
    browseAuctionImage:null,
    videos: [],
    featuredImage:null
}

const savedEventReducer = createSlice({
    name: "event/data",
    initialState,
    reducers: {
        saveEvent: (state, action) => {
            return {
                event: action.payload.event,
                portraitImage: action.payload.portraitImage,
                landscapeImage: action.payload.landscapeImage,
                browseBuyNowImage: action.payload.browseBuyNowImage,
                browseAuctionImage: action.payload.browseAuctionImage,
                videos: action.payload.videos,
                featuredImage:action.payload.featuredImage
            }
        },
        resetSavedEvent: (state, action) => {
            return initialState
        },
        updateProperties: (state, action) => {
            state.event[action.payload.key] = action.payload.value
        }
    }
})

export default savedEventReducer.reducer
export const {saveEvent, resetSavedEvent, updateProperties} = savedEventReducer.actions