import { styled } from '@mui/material/styles';
import { Checkbox, TableCell, tableCellClasses, TableRow } from '@mui/material';
import React from 'react'
import { COLORS_OBJECT } from '../../../../constants/en';
const redColor = COLORS_OBJECT.color[0]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const checkRenderCheckBox = (purpose, status) => {
  if(purpose === 'send' && status === 'unknown') return true
  else if (purpose === 'markKnown' && status === 'sent') return true
  return false
}

const ItemRow = ({rowData, handleSelectedItem, setSelectAllClicked=()=>null, selectedKnown=[], selectedSend=[]}) => {
  const formatDate = (dt) => {
    if(dt){
      return new Date(dt).toDateString();
    }
    return "None"
  }

  const handleCheck = (type, id, sku, storeItemId, isVariant) => {
    setSelectAllClicked(false)
    const item = {
      id,
      sku,
      storeItemId,
      isVariant
    }
    handleSelectedItem(type, item)
  }

  const markCheckBox = (sku, type) => {
    let flag = false
    if(type === 'send') {
      for(let item of selectedSend) {
        if(item?.sku === sku) {
          flag = true;
          break;
        }
      }
    }
    else if(type === 'markKnown') {
      for(let item of selectedKnown) {
        if(item?.sku === sku) {
          flag = true;
          break;
        }
      }
    }
    return flag;
    }
  

  if(!rowData.variants) {
    const storeItemId = rowData.store_item ? rowData.store_item.id : null
    const sendDate = rowData.store_item ? formatDate(rowData.store_item.send_date) : formatDate(rowData.send_date)
    const status = rowData.store_item ? rowData.store_item.advatix_status : rowData.advatix_status
    const sku = rowData.store_item ? rowData.store_item.sku : rowData.sku
    return (
      <StyledTableRow
      key={rowData.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
          <StyledTableCell>{rowData.name}</StyledTableCell>
          <StyledTableCell>{sku}</StyledTableCell>
          <StyledTableCell>{status}</StyledTableCell>
          <StyledTableCell>{sendDate ? sendDate : "None"}</StyledTableCell>
          <StyledTableCell>
            {checkRenderCheckBox('send', status) && 
            <Checkbox 
                checked={markCheckBox(sku, 'send')}
                onClick={() => handleCheck('send', rowData.id, sku, storeItemId, 0)} 
                sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, "&.Mui-checked": {color: redColor} }}
            />}
          </StyledTableCell>
          <StyledTableCell>
            {checkRenderCheckBox('markKnown', status) && 
            <Checkbox 
                checked={markCheckBox(sku, 'markKnown')}
                onClick={() => handleCheck('markKnown', rowData.id, sku, storeItemId, 0)} 
                sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, "&.Mui-checked": {color: redColor} }}
            />}
          </StyledTableCell>
      </StyledTableRow> 
    )
  } else if (rowData.variants) {
    return (
      <>
        {rowData.variants.map(variant => (
          <TableRow
          key={variant.sku}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
              <StyledTableCell>{rowData.name}</StyledTableCell>
              <StyledTableCell>{variant.sku}</StyledTableCell>
              <StyledTableCell>{variant.advatix_status}</StyledTableCell>
              <StyledTableCell>{variant.send_date ? formatDate(variant.send_date) : "None"}</StyledTableCell>
              <StyledTableCell>
                {checkRenderCheckBox('send', variant.advatix_status) && 
                  <Checkbox
                      checked={markCheckBox(variant.sku, 'send')}
                      onClick={() => handleCheck('send', rowData.id, variant.sku, rowData.store_item.id, 1)} 
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, "&.Mui-checked": {color: redColor} }}
                  />
                }
              </StyledTableCell>
              <StyledTableCell>
                {checkRenderCheckBox('markKnown', variant.advatix_status) && 
                  <Checkbox 
                      checked={markCheckBox(variant.sku, 'markKnown')}
                      onClick={() => handleCheck('markKnown', rowData.id, variant.sku, rowData.store_item.id, 1)} 
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, "&.Mui-checked": {color: redColor} }}
                  />
                }
              </StyledTableCell>
          </TableRow>
        ))}
      </>
    )
  }
}

export default ItemRow;