import React from 'react'
import Container from '../../components/HOC/Container/Container'
import DFaq from "../../../images/d_faq.png"
import classes from './Faq.module.css'
import AccordionMui from '../../components/AccordionMui/AccordionMui'

const Faq = () => {
  return (
    <Container>
        <div className={classes.faq_wraper}>
        <div className={classes.faq_main}>
        <h1 className={classes.faq_heading} >FAQ's</h1>
        <img src={DFaq} alt="faq" />
        <AccordionMui 
                titleStyle={{fontWeight: 700, color: "#1a385c", fontSize: "17px"}}
                title={'How Does Bidding Work?'} 
                description={
                <ol>
                    <li>To bid in this auction, find your desired lot and choose “Place Bid”.</li>
                    <li>Select your bid amount. You may either place a bid at the “Next Bid'' option or choose a Maximum Bid amount that the Boundless Giving system will execute on your behalf when competing bids are placed.</li>
                    <li>You will then be asked to enter your payment information. Your card will not be charged unless you win the item at the end of the auction.</li>
                    <li>You will receive a confirmation of your bid onscreen and via email.</li>
                </ol>
                }
                />
                <AccordionMui 
                titleStyle={{fontWeight: 700, color: "#1a385c", fontSize: "17px"}}
                title={'Returns and Refunds'} 
                description={
                <>
                    <div className={classes.faq_mini_wraper}>
                        <h5 style={{fontWeight: 600}}>Auction Items</h5>
                        <p>All sales on auction items are final and non-refundable. If for any reason you decide you do not want an item you've won, please contact help@boundlessgiving.com within two days upon the close of the auction and we will attempt to give it to the next highest bidder.</p>
                    </div>
                    <div style={{marginTop: "20px"}} className={classes.faq_mini_wraper}>
                        <h5 style={{fontWeight: 600}}>Pop-Up Store / Buy Now Items</h5>
                        <p>Given that the vast majority of the money from your purchase goes to a good cause, it’s important that you’re completely satisfied with your purchase. If you are dissatisfied with a purchase that you bought directly from our store for any reason and want a refund, please contact us at help@boundlessgiving.com and we will take care of it. In exchange, we ask that you donate the refunded item to a cause you care about.</p>
                        <p>For "Buy Now" items, we offer a full money back guarantee for thirty days after delivery for up to two purchased items. You don't have to send the item back, but please consider donating it to charity if you're unsatisfied for any reason.</p>
                        <p>Please note that all refund requests must be made within 45 days of the close of the event.</p>
                    </div>
                </>
                }
                />
                <AccordionMui
                    titleStyle={{fontWeight: 700, color: "#1a385c", fontSize: "17px"}}
                    title='Shipping and Delivery of Your Purchase or Auction Item.'
                    description={<p>Please note that items purchased in one of the Boundless Giving events will not ship until the conclusion of the event itself. You will receive an email letting you know that your item has shipped and you will be able to track its progress to delivery from that point forward. Any shipping or delivery inquiries should be directed to help@boundlessgiving.com.</p>}
                />
                <AccordionMui
                    titleStyle={{fontWeight: 700, color: "#1a385c", fontSize: "17px"}}
                    title='How Do I Contact Customer Service?'
                    description={<p>If you need help with any part of our platform, please contact us at help@boundlessgiving.com and someone will be in touch.</p>}
                />
                <AccordionMui
                    titleStyle={{fontWeight: 700, color: "#1a385c", fontSize: "17px"}}
                    title='Can I donate to a cause directly?'
                    description={<p>As of now, Boundless Giving is not set up to receive direct donations on behalf of any organizations. However, if you'd like to donate to a cause you support, please visit the cause's web page or contact us at help@boundlessgiving.com and we will point you in the right direction.</p>}
                />
                <AccordionMui
                    titleStyle={{fontWeight: 700, color: "#1a385c", fontSize: "17px"}}
                    title='When will I receive my item?'
                    description={<>
                        <p>If your item is a digital item or experience, you will receive it within 48 hours of the conclusion of the event.</p>
                        <p>If your item is a physical good, it will ship at the conclusion of the event. Once your item has shipped, you will receive an email that will allow you to track your item's delivery.</p>
                    </>}
                />
                <AccordionMui
                    titleStyle={{fontWeight: 700, color: "#1a385c", fontSize: "17px"}}
                    title='How much of my purchase goes to charity?'
                    description={<p>A minimum of 80% of your purchase price (minus minimal labor fulfillment costs) goes directly to the causes you've supported. Boundless Giving is determined to make this platform the most effective tool for fundraising it can possibly be, with as much of the sales as possible going to causes you want to support.</p>}
                />
        </div>
        </div>
    </Container>
  )
}

export default Faq