import styles from './VariantCombination.module.css'

const VariantCombination = ({ combiantionKeys = [], combiantionValues = [] }) => {
    return (
        <>
            {
                <div className={styles.variant_section}>
                    <div>
                        {
                            combiantionKeys.map((item) => (
                                <div key={item}><b>{item}:</b></div>
                            ))
                        }
                    </div>
                    <div>
                        {
                            combiantionValues.map((item) => (
                                <div key={item}>{item}</div>
                            ))
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default VariantCombination;