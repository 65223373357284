import React, { useState, useEffect } from "react";
import causeServices from "../services/cause-services";
import logger from "../utils/logger";

const CauseContext = React.createContext({
  causeCategoryList: [],
});

export const CauseContextProvider = (props) => {
  const [causeCategoryList, setCauseCategoryList] = useState([]);

  useEffect(() => {
    //get cause api
    const getCategory = async () => {
      try {
        await causeServices.getCauseCategoryList().then(
          (response) => {
            setCauseCategoryList(response.data);
          },
          (error) => {
            logger('./store/cause-context.jsx','getCategory(): catch1',error);
            setCauseCategoryList([]);
          }
        );
      } catch (err) {
        logger('./store/cause-context.jsx','getCategory(): catch2',err);
        setCauseCategoryList([]);
      }
    };
    getCategory();
  }, []);

  return (
    <CauseContext.Provider
      value={{
        causeCategoryList: causeCategoryList,
      }}
    >
      {props.children}
    </CauseContext.Provider>
  );
};

export default CauseContext;
