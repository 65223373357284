import { Player } from "@lottiefiles/react-lottie-player";
import CircularProgress from '@mui/material/CircularProgress';
import ReportGmailerrorredRoundedIcon from '@mui/icons-material/ReportGmailerrorredRounded';
import parseQuery from "query-string";
import { useEffect, useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import authService from '../../../services/auth-services';
import Container from "../../components/HOC/Container/Container";
import './verifyChangeEmail.css';

function Success({toGo}) {
    return (
        <div className="verify-success">
            <Player
                autoplay
                speed={1}
                loop
                src="/success_gif.json"
                style={{ height: "300px", width: "300px" }}
            />
            <h3>Your New Email Verified</h3>
            <button className="verify-login-btn" onClick={()=>toGo('/admin/sign-in', {replace: true})}>Sign In</button>
        </div>
    )
}
export default function VerifyChangeEmail() {
    const toGo = useNavigate();
    const url = useLocation();
    const [state,setState] = useState({msg:'Verifying Email...',success:false});
    useEffect(()=>{
        const {token,email} = parseQuery.parse(url.search);
        if(token && email && !state.success) {
            setTimeout(async ()=>{
                await authService.verifyChangeEmail(token,email,state,setState,toGo);
            },2000);
        } else {
            setTimeout(()=>{
                setState({...state,msg:'Invalid Url!'});
                setTimeout(()=>{
                    setState({...state,msg:'Redirecting...'});
                    setTimeout(()=>{
                        toGo('/admin/sign-up', {replace: true});
                    },2000);
                },2000);
            },2000);
        }
    },[url]);

    return (
        <Container style={{height: "100%"}}>
            <div className="verify-container">
            {!state.success?
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {state.msg==='Invalid Url!'?
                        <ReportGmailerrorredRoundedIcon style={{color:'#173B83',fontSize:'60px'}} />:
                        <CircularProgress sx={{color:'#173B83', marginBottom: "20px"}} thickness={5} />
                    }
                    <h3 style={{fontWeight: "600", fontSize: "2.1rem"}}>{state.msg}</h3>
                </div>:
                <Success toGo={toGo} />
            }
            </div>
        </Container>
    )
}