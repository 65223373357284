import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import auctionServices from '../services/auction-services'


export const fetchAvailableItemsOfDonors = createAsyncThunk(
    'donors/fetchAvailableItemsOfDonors',
    async ({donors,auction_id, page, limit}) => {
        const response = await auctionServices.getAvailableItemsOfDonors(donors, auction_id, page, limit);
        return {
            data: response.data,
            totalAvailableItems: response.total_count,
        }
    }
)

const initialState = {
    availableItems: [],
    totalAvailableItems: 0,
    page:1,
    limit:20,
    loading:true,
    error:''
}

const availableItemsByDonorsSlice = createSlice({
    name: 'donors/availableItems',
    initialState,
    reducers: {
        setAvailableItemsPage: (state, action) => {
            state.page = action.payload
        },
        setAvailableItemsLimit: (state, action) => {
            state.limit = action.payload
        },
        toggleAvailableItemsLoading: (state, action) => {
            state.loading = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAvailableItemsOfDonors.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchAvailableItemsOfDonors.fulfilled, (state, action) => {
            state.availableItems = action.payload?.data
            state.loading = false
            state.totalAvailableItems = action.payload?.totalAvailableItems
            state.error = ''
        })
        builder.addCase(fetchAvailableItemsOfDonors.rejected, (state, action) => {
            state.loading = false
            state.error = action.error?.message
        })
    }
})

export default availableItemsByDonorsSlice.reducer
export const {setAvailableItemsLimit, setAvailableItemsPage, toggleAvailableItemsLoading} = availableItemsByDonorsSlice.actions