import React, { useEffect, useState } from 'react'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import './CauseAddEdit.css';
import Container from '../../components/HOC/Container/Container';
import LoadingModal from "../../components/LoadingModal/LoadingModal"
import causeServices from '../../../services/cause-services';
import Input from '../../components/Input/Input';
import TextArea from '../../components/FormTextarea/FormTextarea.jsx'
import ImageInput from '../../components/ImageInput/ImageInput';
import checkImageBeforeUpload from '../../../utils/checkImageBeforeUpload';
import auctionServices from '../../../services/auction-services';
import DropDown from '../../components/DropDown/DropDown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {useDispatch} from "react-redux"
import { updateCause } from '../../../redux/dependenciesReducer';
import statesOptions from '../../../utils/states-us.js';
import Alert from "../../components/Alert/Alert"
import authService from '../../../services/auth-services';
import AccessDenied from '../../components/AccessDenied/AccessDenied';
import constants from '../../../constants/en';
import RadioGroupComponent from '../../components/RadioGroup/RadioGroup.jsx';

function CauseAddEdit() {
  const {cause_id} = useParams()
  const [causeCatagory, setCauseCatagory] = useState([]);
  const [images, setImages] = useState([]);
  const [logoImage, setLogoImage] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [form, setForm] = useState({
    cause_category_id: "",
    contact_firstname: "",
    contact_lastname: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    image: [],
    video: [],
    about: "",
    org_name: "BG",
    legal_name: "",
    tax_id: "123",
    cause_logo: null,
    featured: false
  });
  const [selectedCateogry, setSelectedCategory] = useState(null)
  const [formError, setFormError] = useState("");
  const [videoPreview, setVideoPreview] = useState([]);

  const [landscapeImageLoading, setLandscapeImageLoading] = useState(false)
  const [selectedState, setSelectedState] = useState(null)
  const [editAccess, setEditAccess] = useState(true);
  const [logoLoading,setLogoLoading] = useState(false);
  const [whiteBorderAlert,setWhiteBorderAlert] = useState(null);
  const [imageEvent,setImageEvent] = useState(null)
  // const statesOptions = useMemo(() => {
  //   return states.map(c => ({value: c.abbreviation, label: c.name}))
  // }, [])

  const fetchCause = async () => {
     try{
      setIsLoading(true)
      const permission = await authService.checkCausePermissions(3, cause_id)
    if(!permission.access) {
      setEditAccess(false)
      setIsLoading(false)
      return
    } else {
      setEditAccess(true)
    }
    setIsLoading(true)
    const data = await causeServices.getCauseById(cause_id)
    setForm({
      ...form,
      ...data.data,
    })
    if(data.data.images) {
      setImages(data.data.images.map((i,index)=> ({name: i ,imageType:`image${index+1}`})))
    }
    if(data.data.video) {
      setVideoPreview([{name: data.data.video}])
    }
    if(data.data?.cause_logo) {
      setLogoImage([{name:data.data?.cause_logo, type:'img'}])
    }
    setIsLoading(false)
  }catch(error){
       setIsLoading(false)
     }
  }

  useEffect(() => {
    if(cause_id) {
        fetchCause()
    }
  }, [])

  useEffect(() => {
     try{
      setIsLoading(true)
      causeServices.getCauseCategoryList().then(response => {
        const causeCategory= response.data?.map((catagory)=>({label:catagory.name,value:catagory.id}))
         setCauseCatagory(causeCategory)
         setIsLoading(false)
       })
     }
     catch(error){
      setIsLoading(false)
     }
  }, [])

  useEffect(() => {
    if(!selectedCateogry && cause_id) {
      causeCatagory.map(cat => {
        if(cat.value === form.cause_category_id) {
          setSelectedCategory(cat)
        }
      })
    }
  }, [form.cause_category_id, causeCatagory])

  useEffect(() => {
    if(!selectedState && cause_id) {
      statesOptions.map(s => {
        if(s.value === form.state) {
          setSelectedState(s)
        }
      })
    }
  }, [form.state, statesOptions])

  useEffect(() => {
    localStorage.removeItem("data")
  }, [])

  const handleConfirmBeforeUpload =async (e) =>{
    if (e.target.files.length === 0) return
    setLandscapeImageLoading(true)
    const filesUrl = []
    const selectedFiles = []
    const targetFilesObj = [...e.target.files]
    const preProcessedFiles = []
    if (targetFilesObj.length > 3 || images.length === 3 || (images.length + targetFilesObj.length) > 3) {
      setFormError(constants.ADMIN_COPY_CAUSE_LAND_SCAPE_IMG_LIMIT);
      setLandscapeImageLoading(false)
      return
    }
    if (targetFilesObj.every((currentValue) => currentValue.size > 5000000)){
      setFormError(constants.ADMIN_COPY_CAUSE_LAND_SCAPE_IMG_FILE_SIZE);
      setLandscapeImageLoading(false)
      return
    }
    targetFilesObj.map((file) => {
      return filesUrl.push({ name: URL.createObjectURL(file), type: 'img', local: true });
    });

    let invalidImage = false;
    let invalidAspectRatio = false
    for (const url of filesUrl) {
      const { width, height } = await checkImageBeforeUpload(url.name)
      if (width < 636 || height < 318) {
        invalidImage = true
      }
      if(((width/height) < 1.96) || ((width/height) > 2)){ //correct aspect ratio is 2:1 but taking 98% of 2:1 as break point for checking.
        invalidAspectRatio = true
      }
    }
    if (invalidImage) {
      setFormError(constants.ADMIN_COPY_CAUSE_LAND_SCAPE_IMG_SIZE)
      setLandscapeImageLoading(false)
      return
    }else if(invalidAspectRatio){
      setImageEvent(e)
      setWhiteBorderAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','2:1'))
      setLandscapeImageLoading(false)
      return
    }
    else{
      handleMultipleImages(e)
    }
  }
  const handleMultipleImages = async (e) => {
    if (e.target.files.length === 0) return
    setLandscapeImageLoading(true)
    const filesUrl = []
    const selectedFiles = []
    const targetFilesObj = [...e.target.files]
    const preProcessedFiles = []
    // if (targetFilesObj.length > 3 || images.length === 3 || (images.length + targetFilesObj.length) > 3) {
    //   alert(constants.ADMIN_COPY_CAUSE_LAND_SCAPE_IMG_LIMIT);
    //   setLandscapeImageLoading(false)
    //   return
    // }
    if (targetFilesObj.every((currentValue) => currentValue.size <= 5000000)) {
      targetFilesObj.map((file) => {
        return filesUrl.push({ name: URL.createObjectURL(file), type: 'img', local: true });
      });

      // let invalidImage = false;
      // for (const url of filesUrl) {
      //   const { width, height } = await checkImageBeforeUpload(url.name)
      //   if (width < 636 || height < 318) {
      //     invalidImage = true
      //   }
      // }
      // if (invalidImage) {
      //   setWhiteBorderAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG)
      //   setLandscapeImageLoading(false)
      //   return
      // }

      try {
        for (const image of targetFilesObj) {
          const formData = new FormData()
          formData.append("image", image)
          formData.append("width", 636)
          formData.append("height", 318)

          const preProcessedImage = await auctionServices.imagePreProcess(formData)

          selectedFiles.push({ name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img', local: true });
          const uint8 = new Uint8Array(preProcessedImage.buffer.data)
          const newBlob = new Blob([uint8], { type: "image/jpeg" })
          preProcessedFiles.push(newBlob)
        }
      } catch (err) { setLandscapeImageLoading(false) }

      setImages([...images, ...selectedFiles])
      setForm({
        ...form,
        image: [...form.image, ...preProcessedFiles]
      })
      setLandscapeImageLoading(false)
    } else {
      alert(constants.ADMIN_COPY_CAUSE_LAND_SCAPE_IMG_FILE_SIZE);
      setLandscapeImageLoading(false)
      return
    }
  }

  const handleMultipleVideos = (evnt) => {
    const selectedFIles = [];
    const targetFiles = evnt.target.files;
    const targetFilesObject = [...targetFiles];
    if (targetFilesObject.length < 2) {
      targetFilesObject.map((file) => {
        return selectedFIles.push({ name: URL.createObjectURL(file) });
      });
      setVideoPreview(selectedFIles);
      setForm({
        ...form,
        video: evnt.target.files[0],
      });
    } else {
      alert(constants.ADMIN_COPY_CAUSE_VIDEO_LIMIT);
    }
  };

  const handleValidation = () => {
    if(!form.state) {
      setFormError(constants.ADIMIN_COPY_CAUSE_STATE)
      return true
    }
    if(images.length === 0) {
      setFormError(constants.ADMIN_COPY_CAUSE_COVER_IMG)
      return true
    }
    if(!form.cause_category_id) {
      setFormError(constants.ADMIN_COPY_CAUSE_CATEGORY)
      return true
    }
    // if(form.contact_firstname.indexOf(' ') >= 0){
    //   setFormError(constants.ADMIN_COPY_CAUSE_FIRST_NAME)
    //   return true      
    // }
    // if(form.contact_lastname.indexOf(' ') >= 0){
    //   setFormError(constants.ADMIN_COPY_CAUSE_LAST_NAME)
    //   return true      
    // }
    const USZipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    if(!form.zip.match(USZipCodeRegex)){
      setFormError(constants.ADMIN_COPY_CAUSE_ZIP_CODE)
      return true
    }
    if(!form.cause_logo) {
      setFormError(constants.ADMIN_COPY_CAUSE_LOGO_REQURIED)
      return true
    }
    return false
  }

  const handleCauseLogo = async(e) => {
    try {
      if(!e.target.files[0]) return;
      setLogoLoading(true);
      const imageFile = e.target.files[0];
      if(imageFile.size > 5000000 ) {
        setFormError(constants.ADMIN_COPY_EVENT_IMG_FILE_SIZE)
        setLogoLoading(false)
        return;
      }
      const imageUrl = URL.createObjectURL(imageFile);
      const { width, height } = await checkImageBeforeUpload(imageUrl)
      if (width < 80 || height < 80) {
      setFormError(constants.ADMIN_COPY_CAUSE_LOGO_SIZE)
      setLogoLoading(false)
      return
     }
     setLogoImage([{ name: imageUrl, type: 'img' }])
     setForm({
      ...form,
      cause_logo: imageFile
     })
     setLogoLoading(false)
    } catch (error) {
      setLogoLoading(false)
    }
  }


  const handleForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if(handleValidation()) return
    setIsLoading(true)
    let bodyFormData = new FormData();
    form.about && bodyFormData.append("about", form.about);
    bodyFormData.append("org_name", "BG");
    form.legal_name && bodyFormData.append("legal_name", form.legal_name);
    bodyFormData.append("tax_id", "123");
    form.cause_category_id &&
      bodyFormData.append("cause_category_id", form.cause_category_id);
    form.contact_firstname &&
      bodyFormData.append("contact_firstname", form.contact_firstname.trim());
    form.contact_lastname &&
      bodyFormData.append("contact_lastname", form.contact_lastname.trim());
    form.address1 && bodyFormData.append("address1", form.address1);
    form.address2 && bodyFormData.append("address2", form.address2);
    form.city && bodyFormData.append("city", form.city);
    form.state && bodyFormData.append("state", form.state);
    form.zip && bodyFormData.append("zip", form.zip);
    form.email && bodyFormData.append("email", form.email);

    form.video && bodyFormData.append("video", form.video);
    form.cause_logo && bodyFormData.append('cause_logo', form.cause_logo)
    bodyFormData.append('featured', form.featured ? 1 : 0)

    // form.image.map(image => bodyFormData.append("image", image))
    // images.map((img,i)=>{
    //  if( img.name.split(':')[0]!='data') return null
    //  bodyFormData.append("image", form.image[i])
    //  return null
    // })
  

    // The below set of code help us to identify which image is changed (updated) and which are not and
    // append to bodyFormDate(formData) to send image to backend as image1,image2 and image3.
    // 
    let j=0
    let temp={}
    const imageLimit=3
    for(let i=0;i<images.length;i++){
       if(images[i].imageType){
        temp[images[i].imageType]=images[i].imageType;
        continue;
       }
        let imageName=''
         for(let k=1;k<=imageLimit;k++){
          if(!temp[`image${k}`]){
            imageName=`image${k}`
            temp[imageName]=imageName
            break;
          }
         }
         bodyFormData.append(imageName, form.image[j++])
    }


    if(cause_id) {
      try {
        const data = await causeServices.updateCause(cause_id, bodyFormData)
        setIsLoading(false)
        setFormError(null);
        navigate("/admin/cause")
      } catch(error){
        setIsLoading(false)
          if(error.response.data.error) {
            setFormError(error.response.data.message || constants.ADMIN_COPY_CAUSE_CREATE_FAILURE);
          } else if(error?.response?.data?.data?.length) {
            setFormError(error.response.data.data[0]?.msg || constants.ADMIN_COPY_CAUSE_CREATE_FAILURE)
          } else {
            setFormError(error.response.data.message || constants.ADMIN_COPY_CAUSE_CREATE_FAILURE);
          }
      }
    } else {
      try {
        await causeServices.createCause(bodyFormData).then(
          (response) => {
            dispatch(updateCause({cause_id: response.data.id, cause_category_id: response.data.cause_category_id, cause_legal_name: response.data.legal_name}))
            setIsLoading(false)
            setFormError(null);
            navigate("/admin/event")
          },
          (error) => {
            setIsLoading(false)
            if(error.response.data.error) {
              setFormError(error.response.data.message || constants.ADMIN_COPY_CAUSE_CREATE_FAILURE);
            } else if(error?.response?.data?.data?.length) {
              setFormError(error.response.data.data[0]?.msg || constants.ADMIN_COPY_CAUSE_CREATE_FAILURE)
            }else {
              setFormError(error.response.data.message || constants.ADMIN_COPY_CAUSE_CREATE_FAILURE);
            }
          }
        );
      } catch (err) {
        setIsLoading(false)
        setFormError(constants.ADMIN_COPY_CAUSE_CREATE_FAILURE);
      }
    }
  }

  const handleStateChange = (e) => {
    setSelectedState(e)
    setForm({
      ...form,
      state: e.value
    })
  }

  const deleteImage = async (url, imageIdx) => {
    setForm({
      ...form,
      image: form.image.filter((i,idx)=> idx !== imageIdx)
    })
    setImages(images.filter((o, idx) => url !== o.name))
  }

  const deleteVideo = async (url, imageIdx) => {
    setForm({
      ...form,
      video: null
    })
    setVideoPreview([])
  }


  const deleteCauseImage = () => {
    setLogoImage([]);
    setForm({
      ...form,
      cause_logo: null
    })
  }

  const handleFeaturedChange = (e) => {
    setForm({...form, featured:e})
  }

  return (
    <Container title='Step One: Tell Us About Your Cause' style={{position: "relative"}}>
        {(cause_id && !editAccess) ? <AccessDenied /> : (
           <form onSubmit={handleSubmit}>
           <span className='calender_icon_container'>
             <CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#34b5e5', marginRight: "5px" }} />
             <p style={{fontSize: "0.9rem"}}>Create A Cause</p>
           </span>
           <hr style={{margin: 0, marginTop: "5px"}} />
           <div className='cause_form'>
             <div>
               <Input infoBubbleText='Enter the full name of your nonprofit as you want it to appear on the Boundless Giving Platform' name='legal_name' value={form.legal_name} onChange={handleForm} label='Cause Name' iButton required />
               <DropDown value={selectedCateogry} infoBubbleText='Select a category that best defines your nonprofit.' onChange={(e) => {setForm({...form, cause_category_id: e.value}); setSelectedCategory(e)}} required options={causeCatagory} label='Category' isSearchable iButton/>
               <TextArea infoBubbleText="Describe your cause in a few sentences. For example, who does it serve? How long has it been around? What is its mission etc." name='about' value={form.about} onChange={handleForm} required iButton label='About Your Cause' />
               <div className='aitem-section-head'>
                 Cause Address
                 <hr />
               </div>
               <Input name='address1' value={form.address1} onChange={handleForm} required label='Cause Address' />
               <Input name='city' value={form.city} onChange={handleForm} required label='City' />
               <DropDown placeholder="Choose a State" menuPlacement='top' value={selectedState} isSearchable style={{margin: "10px 0 20px 0"}} onChange={handleStateChange} options={statesOptions} required label='State' />
               <Input name='zip' value={form.zip} onChange={handleForm} required label='Zip Code' />
               <RadioGroupComponent
                  checked={form.featured}
                  onChange={handleFeaturedChange}
                  label={constants.ADMIN_COPY_CAUSE_FEATURED_LABEL} 
                />
             </div>
             <div>
               <div className='aitem-section-head mt-2'>
                 Contact Information
                 <hr />
               </div>
               <div className='contact_name'>
                 <div>
                   <Input name='contact_firstname' value={form.contact_firstname} onChange={handleForm} required label='Contact First Name' />
                 </div>
                 <div>
                   <Input name='contact_lastname' value={form.contact_lastname} onChange={handleForm} required label='Contact Last Name' />
                 </div>
               </div>
               <Input name='email' value={form.email} onChange={handleForm} required label='Contact Email' type="email" />
               <Input name='phone' value={form.phone} onChange={handleForm} label='Phone' type="phone" />
               <div className='aitem-section-head'>
                 Cause Images
                 <hr />
               </div>
               <ImageInput loading={landscapeImageLoading} edit onDelete={deleteImage} required label='Add A Cause Cover Photo' images={images} multiple accept="image/*" sidelabel='Max 3, recommended size 1908x954px' max={3} handleChange={handleConfirmBeforeUpload} iButton={true} infoBubbleText={constants.ADIMIN_COPY_CAUSE_COVER_PHOTO} />
               <ImageInput loading={logoLoading} edit onDelete={deleteCauseImage} label='Add A Cause Logo' images={logoImage} accept="image/*" sidelabel='Max 1, recommended size 80x80px' required handleChange={handleCauseLogo} />
               <ImageInput max={1} edit onDelete={deleteVideo} label='Add A Video About Your Cause' sidelabel='max size 20MB' accept="video/*" images={videoPreview} handleChange={handleMultipleVideos} />
             </div>
           </div>
             <div style={{fontSize: "0.9rem", marginTop: "20px", fontWeight: "600"}}><span style={{color: "red"}}>*</span> - required fields</div>
               <div >
                 <div className='event-needhelp'><a target='_blank' href="mailto:help@boundlessgiving.com">Need Help?</a></div>
                 <button style={{width:'100%'}} className='event-submit-btn' type='submit'>{cause_id ? "Save Cause Changes" : "Save Cause and Continue"}</button>
               </div>
           </form>
        )}
        <LoadingModal isOpen={isLoading} />
        <Alert isOpen={formError} onClose={() => setFormError(null)} message={formError}/>
        <Alert isOpen={!!whiteBorderAlert} type='confirm' okBtnText='Upload Anyway' onConfirm={()=>{handleMultipleImages(imageEvent);setWhiteBorderAlert(null)}} onClose={()=>setWhiteBorderAlert(null)} message={whiteBorderAlert}/>
    </Container>
  )
}

export default CauseAddEdit