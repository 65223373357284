import React, { useContext, useEffect, useState } from 'react';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Container from '../../components/HOC/Container/Container';
import { Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { fetchEvents } from '../../../redux/eventReducer';
import AuthContext from '../../../store/auth-context';
import authService from '../../../services/auth-services';
import Alert from '../../components/Alert/Alert';
import DropDown from '../../components/DropDown/DropDown';
import { chooseEvent } from '../../../redux/orderReducer';
import Input from '../../components/Input/Input';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import constants from '../../../constants/en';


const UserManagement = () => {
    const dispatch = useDispatch()
    const [roleUser, setRoleUser] = useState({ first_name: '', id: '' })
    const [permissions, setPermissions] = useState([])
    const [selectedPermission, setSelectedPermission] = useState([])
    const [alertMessage, setAlertMsg] = useState(null)
    const [options, setOptions] = useState([{ label: '', value: '', type:'' }])
    const reduxSelectedEvent = useSelector(state => state.orderDetails.selectedEvent)
    const [isChecked, setIsChecked] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const [inviteEmail, setInviteEmail] = useState({ email: '', confirmEmail: '' })

    const { events, loading } = useSelector(state => state.userEvents)
    const ctx = useContext(AuthContext)
    const getType = (eventData) => {
        if(eventData?.market_place == '1') {
            return 'marketplace'
        }
        else if(eventData?.is_store == '1') {
            return 'store'
        }
        else {
            return 'event'
        }
    } 
    useEffect(() => {
        const data = events?.map((event) => ({ label: event.title, value: event.id, type:getType(event) }))
        setOptions(data)
    }, [events])



    useEffect(() => {
        dispatch(fetchEvents({ user_id: ctx.user.id, page:null, limit:null, needMarketAndStore:true }))
    }, [])


    const fetchPermissions = () => {
        setIsLoading(true)
        try {
            authService.fetchPermissions().then((response) => {
                setPermissions(response.data.data.filter((item) =>
                    item.name !== 'create_event' && item.name !== 'create_cause' &&
                    item.name !== 'god_user' && item.name !== 'has_store' && item.name !== 'has_marketplace'
                ))
            })
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        fetchPermissions()
    }, [])


    const handlePermissionChange = (e, user_id, i) => {
        if (e.target.checked) {
            setSelectedPermission(prevState => [...prevState, { permission_id: e.target.value, user_id: user_id }])
            setIsChecked({ ...isChecked, [i]: true })
        }
        else {
            setSelectedPermission(prevState => prevState.filter(value => value.permission_id !== e.target.value))
            setIsChecked({ ...isChecked, [i]: false })

        }
    }

    const handleAllPermissionsChange = (event) => {
        const updatedIsChecked = {};
        permissions?.map((data, index) => {
            updatedIsChecked[index] = event.target.checked;
            let dataEvent = {}
                dataEvent = {
                    target: {
                        value:data?.id,
                        checked:event?.target?.checked
                    }
                }
                handlePermissionChange(dataEvent, "", index)
        })
        setIsChecked(updatedIsChecked);
    }

    const handleInviteSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            // const permission = await authService.checkPermissions(9, reduxSelectedEvent)
            // if (!permission.access) {
            //     setIsLoading(false)
            //     setInviteEmail({ email: '', confirmEmail: '' })
            //     setIsChecked({})
            //     setAlertMsg('You dont have access to give permissions')
            // }
            // else {
                setIsLoading(false)
                const { confirmEmail, email } = inviteEmail
                if (!confirmEmail || !email) {
                    setAlertMsg(constants.ADMIN_COPY_USER_MANAGEMENT_MISSING_CREDENTIAL)
                }
                else if (!confirmEmail.match(email)) {
                    setAlertMsg(constants.ADMIN_COPY_USER_MANAGEMENT_CONFIRM_EMAIL)
                }
                else if (selectedPermission.length < 1) {
                    setAlertMsg(constants.ADMIN_COPY_USER_MANAGEMENT_SELECT_PERMISSION)
                }
                else {
                    setIsLoading(true)
                  const response = await  authService.makePermissionInviteEmail(reduxSelectedEvent?.value, selectedPermission, email, setAlertMsg)
                        setIsLoading(false)
                        if (response.data.message === 'Invitation mail sent to the email addresss') {
                            setIsLoading(false)
                            setInviteEmail({ email: '', confirmEmail: '' })
                            setSelectedPermission([])
                            setIsChecked({})
                            setAlertMsg(response.data.message)
                        }
                        else if (response.data.message === 'Permissions assigned') {
                            setIsLoading(false)
                            setInviteEmail({ email: '', confirmEmail: '' })
                            setSelectedPermission([])
                            setIsChecked({})
                            setAlertMsg(constants.ADMIN_COPY_USER_MANAGEMENT_PERMISSION_NOTIFICATION)
                        }
                        else {
                            setIsLoading(false)
                        }

                    
                }
            // }
        } catch (error) {
            setIsLoading(false)
            setInviteEmail({ email: '', confirmEmail: '' })
            dispatch(chooseEvent(''))
            setAlertMsg(constants.ADMIN_COPY_USER_MANAGEMENT_NO_PERMISSION)
        }
    }


    return (

        <Container title='User Management' style={{position: "relative"}} >
            <div className='event_header_container'>
                <span className='calender_icon_container'><CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#34b5e5' }} /><p>Assign Permissions</p></span>
            </div>
            <hr />
            {events?.length > 0 ? <DropDown isSearchable={true} onChange={(e) => dispatch(chooseEvent({label:e?.label, value:e?.value, type:e?.type}))} options={options} /> : 'No events found'}
            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', flexDirection: 'column', marginTop: "20px" }}>
                <div >
                    {reduxSelectedEvent?.value !== null && 
                            <div style={{ margin: '20px', gap: '20px', display: 'flex', justifyContent:'flex-end' }}  >
                                <input onChange={handleAllPermissionsChange} type="checkbox" />
                                <label htmlFor="" style={{ textTransform: 'capitalize' }} >Select All</label>
                            </div>
                    }
                    <Grid container justify='center'  >
                        {reduxSelectedEvent?.value !== null && permissions?.map((permission, i) => {
                            return (
                                <Grid alignItems='center' display={'flex'} justifyContent='center' key={permission?.id} item xs={12} md={6} lg={4} sx={{ width: '40%' }}>
                                    <div style={{ margin: '20px', gap: '20px', display: 'flex', width: '60%' }}  >
                                        <input type="checkbox" checked={isChecked[i]} value={permission?.id} onChange={(event) => { handlePermissionChange(event, roleUser?.id, i) }} />
                                        <label htmlFor="" style={{ textTransform: 'capitalize' }} >{permission.name.replace(/_/g, ' ').replace('event', reduxSelectedEvent?.type)}</label>
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
                {reduxSelectedEvent?.value !== null &&
                    <form onSubmit={handleInviteSubmit} style={{ width: '100%' }} >
                        <div style={{ padding: '10px', width: '100%', display: 'flex', gap: '20px' }}>
                            <Input label="Enter email address" value={inviteEmail.email} name="email" onChange={(event) => {
                                setInviteEmail({ ...inviteEmail, [event.target.name]: event.target.value })
                            }} required />

                            <Input label="Confirm Email address" value={inviteEmail.confirmEmail} name="confirmEmail" onChange={(event) => {
                                setInviteEmail({ ...inviteEmail, [event.target.name]: event.target.value })
                            }} required />


                            <button type='submit'
                                style={{
                                    marginTop: '29px',
                                    marginBottom: '10px'
                                }}
                                className="aitem-modal-btn"
                            >
                                Invite</button>
                        </div>
                    </form>
                }
            </div>
            {reduxSelectedEvent?.value === null ? <Paper sx={{ height: '300px', textAlign: 'center', paddingY: '140px' }}>
                <p>Please choose an event</p>
            </Paper> : ''}
            <Alert isOpen={!!alertMessage} message={alertMessage} onClose={() => setAlertMsg(null)} />
            {/* <LoadingModal isOpen={loading} /> */}
            <LoadingModal isOpen={isLoading || loading} />
        </Container>

    )
}

export default UserManagement
