import { configureStore } from '@reduxjs/toolkit'
import dependenciesReducer from './dependenciesReducer'
import eventReducer from './eventReducer'
import savedEventReducer from './savedEventReducer'
import userActionsReducer from './userActionsReducer'
import orderReducer from './orderReducer'
import itemsListReducer from './itemsListReducer'
import userReducer from './usersReducer'
import reportReducer from './reportReducer'
import managePageReducer from './managePageReducer'
import donorReportReducer from './donorReportReducer'
import savedMarketPlaceReducer from './savedMarketPlaceReducer'
import marketPlaceReducer from './marketPlaceReducer'
import savedStoreReducer from './savedStoreReducer'
import orderReportModalTableReducer from './orderReportModalTableReducer'
import availableDonorItemsReducer from './availableDonorItemsReducer'
import archievedEvents from './archievedEvents'
import persistReducer from './persistReducer'
import auctionWinner from './auctionWinner'
import biddersReport from './biddersReport'
import categoriesHaveItem from './categoriesHaveItem'
import itemBuyersReducer from './itemBuyersReducer'
import itemSalesReport from './itemSalesReport'
import categorySalesReport from './categorySalesReport'
import itemsByEventReducer from './itemsByEventReducer'
import qrCodeItemsReducer from './qrCodeItemsReducer'
import allProductsReducer from './allProductsReducer'


export const store = configureStore({
  reducer: {
    userEvents: eventReducer,
    userAction: userActionsReducer,
    savedEvent: savedEventReducer,
    dependencies: dependenciesReducer,
    orderDetails:orderReducer,
    itemsList: itemsListReducer,
    users:userReducer,
    report:reportReducer,
    managePage:managePageReducer,
    donorReport:donorReportReducer,
    savedMarketPlace: savedMarketPlaceReducer,
    marketPlace:marketPlaceReducer,
    savedStore:savedStoreReducer,
    orderModalTable:orderReportModalTableReducer,
    availableDonorItems: availableDonorItemsReducer,
    archievedEvents: archievedEvents,
    auctionWinners:auctionWinner,
    biddersData: biddersReport,
    persistedData: persistReducer,
    categoriesHaveItem: categoriesHaveItem,
    itemBuyer: itemBuyersReducer,
    itemSales: itemSalesReport,
    categorySales: categorySalesReport,
    itemsByEventId: itemsByEventReducer,
    qrCodeItems: qrCodeItemsReducer,
    allProducts: allProductsReducer
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: true,
    },
  }),
})