import React from 'react';
import { useRef } from 'react';
import ReactQuill from 'react-quill';
import './QuillEditor.css'

const QuillEditor = ({ onChange = () => null, modules = {}, value = "", className = "", placeholder = "", editorStyle={}, label='' }) => {
    const editorRef = useRef()
    // console.log(label,"label")
    return (
        <>
            <div className={'quill_container'} >
                <label className='formtextarea-label' htmlFor="">{label}</label>
                <ReactQuill
                    theme="snow"
                    value={value}
                    modules={modules}
                    onChange={onChange}
                    className={'editor_style'}
                    style={{...editorStyle}}
                    ref={editorRef}
                    placeholder={placeholder}
                />
            </div>
        </>
    )
}

export default QuillEditor;