import { AddTask, BorderColor, ContactMail, ContentPasteGo, DeleteOutline, MoreVert, Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import TableButton from "../TableButton/TableButton";
import constants, { COLORS_OBJECT } from "../../../constants/en";
import { useSelector } from "react-redux";
const redColor = COLORS_OBJECT.color[0]

const ItemListMenu = ({
    itemType = "",
    row,
    handleOpenDeleteConfirm = () => null,
    handleEdit = () => null,
    checkStoreItemStatus = () => null,
    handleMakeAcive = () => null,
    getStoreItemArchived = () => null,
    handleArhiveItemAlert = () => null,
    handleBuyerModal = () => null,
    handleCloneItemAlert = () => null,
    getPageType = () => null,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { auction_status } = useSelector(state => state.dependencies)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVert />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >

                {auction_status && auction_status === 'live' && itemType === 'auction' ? null
                    :
                    <MenuItem onClick={handleClose}>
                        <TableButton
                            onClick={() => handleOpenDeleteConfirm(row?.id, row?.auction_auction_item_xrefs?.length > 0)}
                            Icon={DeleteOutline} color='white' backgroundColor={redColor} text="Delete"
                            extraClassName="itemListMenuButtons"
                        />
                    </MenuItem>}

                {!row?.auction_auction_item_xrefs?.length ? <MenuItem onClick={handleClose}>
                    <TableButton extraClassName="itemListMenuButtons" onClick={() => handleEdit(row, itemType)} Icon={BorderColor} text='Edit' />
                </MenuItem> : null}

                {checkStoreItemStatus(row) === 'pending' &&
                    <MenuItem onClick={handleClose}>
                        <TableButton extraClassName="itemListMenuButtons" onClick={() => handleMakeAcive(row.id, row)} Icon={AddTask} color={"#96c440"} text="Activate" borderColor='#96c440' />
                    </MenuItem>}

                {checkStoreItemStatus(row) === 'active' &&
                    <MenuItem onClick={handleClose}>
                        <Tooltip title={
                            <p style={{ fontSize: '0.85rem' }}>
                                {getStoreItemArchived(row)?.archived == '1' ? constants.ADMIN_COPY_ITEM_UNARCHIVED_ALERT : constants.ADMIN_COPY_ITEM_ARCHIVED_ALERT}
                            </p>
                        }>
                            <div style={{ width: '100%' }}>
                                <TableButton
                                    onClick={() => handleArhiveItemAlert(row)}
                                    Icon={getStoreItemArchived(row)?.archived == '1' ? VisibilityOff : Visibility}
                                    color={"#96c440"}
                                    text={getStoreItemArchived(row)?.archived == '1' ? "Unarchive" : "Archive"}
                                    borderColor='#96c440'
                                    extraClassName="itemListMenuButtons"
                                />
                            </div>
                        </Tooltip>
                    </MenuItem>
                }

                {!row?.auction_auction_item_xrefs?.length && row?.status !== 'pending' ?
                    <MenuItem onClick={handleClose}>
                        <Tooltip title={
                            <p style={{ fontSize: '0.85rem' }}>
                                {constants.ADMIN_COPY_ITEM_BUYER_ALERT}
                            </p>
                        }>
                            <div style={{ width: '100%' }}>
                                <TableButton
                                    onClick={() => handleBuyerModal(row)}
                                    Icon={ContactMail}
                                    color={COLORS_OBJECT.color[1]}
                                    text="Buyers"
                                    borderColor={COLORS_OBJECT.color[1]}
                                    extraClassName="itemListMenuButtons"
                                />
                            </div>
                        </Tooltip>
                    </MenuItem>
                    : null}

                {!row?.auction_auction_item_xrefs?.length ?
                    <MenuItem onClick={handleClose}>
                        <Tooltip title={
                            <p style={{ fontSize: '0.85rem' }}>
                                {constants.ADMIN_COPY_CLONE_ITEM_ALERT.replace('event', getPageType())}
                            </p>
                        }>
                            <div style={{ width: '100%' }}>
                                <TableButton
                                    onClick={() => handleCloneItemAlert(row, itemType)}
                                    Icon={ContentPasteGo}
                                    color={redColor}
                                    text="Clone Product"
                                    extraClassName="itemListMenuButtons"
                                />
                            </div>
                        </Tooltip>
                    </MenuItem>
                    : null}
            </Menu>
        </div>
    )
}

export default ItemListMenu;