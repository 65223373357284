import React, { useEffect } from 'react'
import MarketPlaceAndStoreTable from './MarketPlaceAndStoreTable'
import { resetItemsList } from '../../../../redux/itemsListReducer'
import { useDispatch, useSelector } from 'react-redux'
import { resetDependencies } from '../../../../redux/dependenciesReducer'
import { resetSavedEvent } from '../../../../redux/savedEventReducer'

const MyMarketPlace = () => {
    const dispatch = useDispatch()
    const {marketPlaces, loading, totalMarketPlace} = useSelector(state => state.marketPlace)

    useEffect(() => {
        localStorage.removeItem("data")
        dispatch(resetDependencies())
        dispatch(resetSavedEvent())
        dispatch(resetItemsList())
    }, [])
  return (
    <>
    <MarketPlaceAndStoreTable marketPlacesOrStores={marketPlaces} loading={loading} totalMarketPlaceOrStore={totalMarketPlace} StringLowerName={'marketplace'} stringCapitalName={'MarketPlace'}  />
    </>
  ) 
}

export default MyMarketPlace