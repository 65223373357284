import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    // orders:[],
    totalData:0,
    paginationPage:1,
    paginationLimit:10,
    tableHeading:[],
    tableBody:[],
    tableType:''
}

const orderModalPaginationSlice = createSlice({
    name: "orderModalPaginationSlice",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.totalData = action.payload.totalData
            state.paginationPage = action.payload.paginationPage
            state.paginationLimit = action.payload.paginationLimit
        },
        setPaginationPage: (state, action) => {
            state.paginationPage = action.payload.paginationPage
        },
        setPaginationLimit: (state, action) => {
            state.paginationLimit = action.payload.paginationLimit
        },
        setDetails: (state, action) => {
            state.tableType = action.payload.tableType
            state.tableHeading = action.payload.tableHeading
            state.tableBody = action.payload.tableBody
        }
    }
})

export default orderModalPaginationSlice.reducer
export const {setPagination, setPaginationPage, setPaginationLimit, setDetails} = orderModalPaginationSlice.actions