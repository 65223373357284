import React from 'react'
import { useLocation, useNavigate} from "react-router-dom"
import "./SidebarLink.css"
import { useDispatch } from 'react-redux'
import { resetSelectedEvent } from '../../../redux/orderReducer'
import { COLORS_OBJECT } from '../../../constants/en'
import { clearQRItemState, clearSelectAll } from '../../../redux/qrCodeItemsReducer'
import { clearItemsState } from '../../../redux/itemsByEventReducer'
import { setOrderSearchValue } from '../../../redux/reportReducer'

const matchPath = (currPath, path="", activePaths) => {
    if(path === '/admin/items' && currPath.startsWith("/admin/items")) return true
    // if(path.startsWith('/admin/event/edit') && currPath.startsWith("/admin/terms")) return true
    if(path === '/admin/my-events' && (currPath.startsWith('/admin/event') || currPath.startsWith('/admin/terms') || currPath.startsWith('/admin/restore-events')) && (!currPath.includes('/event-preview') && !currPath.includes("/management")) ) return true 
    if(path === '/admin/cause' && currPath.startsWith("/admin/cause")) return true
    if(path === '/admin/manage-donor' && currPath.startsWith("/admin/edit-donor")) return true
    if(path === '/admin/market-place' && (currPath.startsWith("/admin/market-place/add") || currPath.startsWith("/admin/market-place/edit")|| currPath.startsWith("/admin/market-place/terms") || currPath.startsWith('/admin/restore-market_place'))) return true
    if(path === '/admin/market-place/items' && currPath.startsWith("/admin/market-place/items")) return true
    if(path === '/admin/store' && (currPath.startsWith("/admin/store/add") || currPath.startsWith("/admin/store/edit")|| currPath.startsWith("/admin/store/terms")|| currPath.startsWith('/admin/restore-store'))) return true
    if(path === '/admin/store/items' && currPath.startsWith("/admin/store/items")) return true
    if(path === '/admin/blog' && currPath.startsWith("/admin/blog")) return true
    return (currPath === path || currPath === `${path}/`) || (activePaths.indexOf(currPath) !== -1)
}

const commonStyles = {
    backgroundColor:COLORS_OBJECT.backgroundColor[0]
}

const SidebarLink = ({title, icon, path, header=false, activePaths=[], disabled=false}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const clearQrStates = () => {
        dispatch(clearQRItemState())
        dispatch(clearSelectAll())
        dispatch(clearItemsState())
    }
    const handleClick = () => {
        dispatch(resetSelectedEvent())
        dispatch(setOrderSearchValue({orderSearchValue: ""}))
        clearQrStates()
        navigate(path);
    }
    return (
        <div onClick={!disabled ? () => { handleClick() } : null} className={`${header ? "sidebarlink-header" : "sidebarlink-container"} ${disabled && 'sidebarlink-disabled'}`} style={matchPath(location.pathname, path, activePaths) ? {color: "white"} : {}}>
            {icon}
            <span style={{fontSize: `${header ? 'inherit' : ''}`}}>{title}</span>
            {matchPath(location.pathname, path, activePaths) && (
                <div style={commonStyles} className="sidebarlink-selected">
                    <div style={commonStyles} className='t'></div>
                    <div style={commonStyles} className='b'></div>
                </div>
            )}
            {header && !matchPath(location.pathname, path, activePaths) && <span className="sidebarlink-header-line"></span>}
        </div>
  )
}

export default SidebarLink