import React, { useEffect } from 'react'
import { useState } from 'react'
import Container from "../../components/HOC/Container/Container"
import AuctionItem from "./AuctionItem/AuctionItem"
import AuctionItemEdit from "./AuctionItem/AuctionItemEdit"
import StoreItemEdit from "./StoreItem/StoreItemEdit"
import StoreItem from "./StoreItem/StoreItem"
import "./Item.css"
import auctionServices from '../../../services/auction-services'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import AccessDenied from '../../components/AccessDenied/AccessDenied'
import authService from '../../../services/auth-services'
import LoadingModal from '../../components/LoadingModal/LoadingModal'
import { COLORS_OBJECT } from '../../../constants/en'
import AvailableItems from './AvailableItems/AvailableItems'
import { setTabValue, toggleBooleanState } from '../../../redux/persistReducer'


const redColor = COLORS_OBJECT.color[0]

const Items = () => {
  const {auction_id, has_auction, has_buy_now,active} = useSelector(state => state.dependencies)
  // const stateData = useSelector(state => state.dependencies)
  const params = useParams()
  const [categories, setCategories] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = useState( active !== 'store'? state ? state.type : "auction":"store")
  const [editAccess, setEditAccess] = useState(true)
  const [addAccess, setAddAccess] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [path, setPath] = useState('')
  const [lowerName, setLowerName] = useState('')
  const [upperName, setUpperName] = useState('')
  const [showPrompt, setShowPrompt] = useState(false)
  const [donorsList, setDonorsList] = useState([])
  const [currentComponentToSave, setCurrentComponentToSave] = useState('')
  const [godAccess, setGodAccess] = useState(false);

    useEffect(() => {
      if(location.pathname.includes('/admin/items')){
        setLowerName('event')
        setUpperName('Event')
        if(!auction_id) {
          alert("No event found!")
          navigate('/admin/my-events')
          return
      }
      }
      else if(location.pathname.includes('/admin/market-place')){
        setLowerName('marketplace')
        setUpperName('Marketplace')
        if(!auction_id) {
          alert("No event found!")
          navigate('/admin/market-place')
          return
      }
      }
      else {
        setLowerName('store')
        setUpperName('Store')
        if(!auction_id) {
          alert("No event found!")
          navigate('/admin/store')
          return
      }
      }
    
    },[])

    useEffect(() => {
      //  if(has_auction == '1' && has_buy_now == '0') setCurrentTab("auction");
      //  if((has_buy_now == '1' && has_auction == '0')|| active === 'store') setCurrentTab('store');
       if(active === 'store') setCurrentTab('store');
       if(active === 'market' && location.pathname.includes('add')) {
        setCurrentTab('store')
       }
    },[active])
    
  const checkPermissionsEdit = async () => {
    if(!auction_id) return
    try {
      setIsLoading(true)
      const permission = await authService.checkPermissions(5, params.auction_id)
      if(!permission.access) {
        setEditAccess(false)
        setIsLoading(false)
        return
      }
      setIsLoading(false)
    }
    catch(error) {
      setEditAccess(false)
      setIsLoading(false)
    }
  }

  const checkPermissionsAdd = async () => {
    if(!auction_id) return
    try {
      setIsLoading(true)
      const permission = await authService.checkPermissions(6, auction_id)
      if(!permission?.access) {
        setAddAccess(false)
        setIsLoading(false)
        return
      }
      setIsLoading(false)
    }
    catch(error) {
      setAddAccess(false)
      setIsLoading(false)
    }
  }

  const checkGodPermission = async() => {
    try{
        setIsLoading(true)
        const permission = await authService.checkLimitedPermissions(15);
        setGodAccess(permission?.access);
        setIsLoading(false)
    }catch(error) {
        setGodAccess(false);
        setIsLoading(false)
    }
  }

  useEffect(() => {
    // const type = location.pathname.split("/")[3] === 'edit' ? 'edit' : 'add'
    let type;
    if(location.pathname.includes('/admin/items')){
      type = location.pathname.split("/")[3] === 'edit' ? 'edit' : 'add'
    }
    else {
      type = location.pathname.split("/")[4] === 'edit' ? 'edit' : 'add'
    }
    if(type === 'edit' && params && params.auction_id) {
      checkPermissionsEdit()
    } else {
      checkPermissionsAdd()
    }
    checkGodPermission()
  }, [])
  const reload = () => {
    if(active === 'store') {
      setCurrentTab('store')
    }
    else if(active === 'market' && location.pathname.includes('add')) {
      setCurrentTab('store')
    }
    else{
      setCurrentTab("auction")
    }
  }

  const handleTabChange= (e) => {
    let confirm=false
    if(showPrompt) {
      confirm = window.confirm('Do you want to leave?')
      if(confirm) {
        /// here we update state in redux for saving item details in localstorage 
             dispatch(toggleBooleanState({key:currentComponentToSave, value: true}))
             dispatch(setTabValue({tabValue: e.target.value}))
        //  setCurrentTab(e.target.value)
      }
    }
    else {
      setCurrentTab(e.target.value)
    }
  }

  const fetchCategories = async () => {
    try {
      const custom = await auctionServices.fetchCustomCategories(auction_id)

    // const newCustomList = custom?.data?.map(c => ({label: `${c.custom_category_heading} - ${c.category_name}`, value: {id: c.id, type: "custom"}}))
    const newCustomList = custom?.data?.map(c => ({key: (c.custom_category_heading?.name !== "")?`${c.custom_category_heading?.name} -  ${c.category_name}` : `${c.category_name}`, value: c.id, type: "custom"}))

    const global = await auctionServices.getAuctionCategoryList()
    // const newGlobalList = global?.data?.map((c)=>({label:c.name, value: {id: c.id, type: "global"}}))
    const newGlobalList = global?.data?.map((c)=>({key:c.name, value: c.id, type: "global"}))
    const newCategories = [...newGlobalList, ...newCustomList]
    setCategories(newCategories?.sort((a,b) =>{
      let aLabel = a.key?.toLowerCase()
      let bLabel = b.key?.toLowerCase();
   
       if (aLabel < bLabel) {
          return -1;
       }
       if (aLabel > bLabel) {
          return 1;
       }
         return 0;
    }))
  }catch(error){}
  }

  async function addDonors() {
    try {
      const data = await auctionServices.getdonorsListbyAuctionId(auction_id)
      // setDonorsList(data.donors.map((i) => {
      //   return {
      //     label: i.org_name, value: i.id
      //   }
      // }));
      setDonorsList(data.donors.map((i) => {
          return i?.id
        }))
    } catch (err) {
      console.log(err)
    }
  }
  
  useEffect(() => {
    fetchCategories()
    addDonors()
  }, [])


  // const type = location.pathname.split("/")[3] === 'edit' ? 'edit' : 'add'
  let type;
  if(location.pathname.includes('/admin/items')){
    type = location.pathname.split("/")[3] === 'edit' ? 'edit' : 'add'
  }
  else {
    type = location.pathname.split("/")[4] === 'edit' ? 'edit' : 'add'
  }
  
  return (
    <Container title={`${type==='edit'?'Edit':'Add'} Your ${upperName} Items`} style={{position: "relative", padding: "0"}}>
      <LoadingModal isOpen={isLoading} />
      {
       ((type === "edit") || (type === 'add' && !addAccess)) ? null :
        <div className='items-navtab-radio'>
        <div style={{fontWeight: "600"}}>Select the type of item you want to {type} - {" "}</div>
        <RadioGroup sx={{flexDirection:lowerName === 'marketplace' ? 'row-reverse' : 'row'}} row value={currentTab} onChange={handleTabChange}>
          {/* { ((has_auction == '1' || active!=='event')&& active!=='store') ?  <FormControlLabel
              sx={{".MuiFormControlLabel-label": {fontSize: "16px", fontWeight: 600, fontFamily: "AvenirNext"}}}
              value="auction"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="Auction Item"
            /> : ''}
            { (has_buy_now == '1' || active!=='event') ? <FormControlLabel
              sx={{".MuiFormControlLabel-label": {fontSize: "16px", fontWeight: 600, fontFamily: "AvenirNext"}}}
              value="store"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="Store Item"
            /> : ''}
            { (type === 'add' && active!=='market') ? <FormControlLabel
              sx={{".MuiFormControlLabel-label": {fontSize: "16px", fontWeight: 600, fontFamily: "AvenirNext"}}}
              value="availableItems"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="Browse Available Items"
            /> : ''} */}

            { (active!=='store') ?  <FormControlLabel
              sx={{".MuiFormControlLabel-label": {fontSize: "16px", fontWeight: 600, fontFamily: "AvenirNext"}}}
              value="auction"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="Auction Item"
            /> : ''}
            <FormControlLabel
              sx={{".MuiFormControlLabel-label": {fontSize: "16px", fontWeight: 600, fontFamily: "AvenirNext"}}}
              value="store"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="Store Item"
            />
            { (type === 'add' && active!=='market') ? <FormControlLabel
              sx={{".MuiFormControlLabel-label": {fontSize: "16px", fontWeight: 600, fontFamily: "AvenirNext"}}}
              value="availableItems"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="Browse Available Items"
            /> : ''}
        </RadioGroup>
        </div>
      }
      {((type==='edit' && !editAccess) || (type === 'add' && !addAccess)) ? <AccessDenied margin />
        :  currentTab === 'auction' ? 
          (type === 'edit' ? <AuctionItemEdit godAccess={godAccess} categories={categories} reload={reload} /> : <AuctionItem setCurrentComponentToSave={setCurrentComponentToSave}  setCurrentTab={setCurrentTab} categories={categories} reload={reload} showPrompt={showPrompt} setShowPrompt={setShowPrompt} godAccess={godAccess} />)
        : currentTab === 'store' ?
          (type === 'edit' ? <StoreItemEdit godAccess={godAccess} categories={categories} reload={reload} /> : <StoreItem setCurrentComponentToSave={setCurrentComponentToSave}  setCurrentTab={setCurrentTab} categories={categories} reload={reload} showPrompt={showPrompt} setShowPrompt={setShowPrompt} godAccess={godAccess} />)
        : <AvailableItems setShowPrompt={setShowPrompt} availableDonors={donorsList} auction_id={auction_id}/>
      }
    </Container>
  )
}

export default Items