import Container from '../../components/HOC/Container/Container'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAllProducts, setProducts, setAllItemsLoading } from '../../../redux/allProductsReducer'
import { SortableContainer, SortableElement, SortableHandle } from "@rexxars/react-sortable-hoc";
import classes from './AllProductsPriority.module.css'
import { arrayMoveImmutable } from "array-move";
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import constants from '../../../constants/en';
import auctionServices from '../../../services/auction-services';
import Alert from '../../components/Alert/Alert';
import authService from '../../../services/auth-services';
import AccessDenied from '../../components/AccessDenied/AccessDenied';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

// const SortableItem = SortableElement(({ item }) => {
//     return (
//         <div className={`items-drag-card ${classes.itemCard}`}>
//             <div className='items-drag-card-img'>
//                 <img src={item.assets && item.assets.length > 0 && item.assets.find(i => i.context_type === 'portrait')?.name} alt="" />
//             </div>
//             <div className='items-drag-card-content'>
//                 <div className='items-drag-card-header'>{item.name}</div>
//                 <div style={{ marginTop: "10px", fontWeight: 600, fontSize: "0.9rem", display: "flex", gap: "30px" }}>
//                     <div>Price: ${item.store_item_id ? item.store_item.price : item.price}</div>
//                     {/* {
//                         !item.store_item_id && (
//                             <>
//                                 <div>Start Date: {moment(item.start_date).format('MMMM Do YYYY, h:mm a')}</div>
//                                 <div>End Date: {moment(item.end_date).format('MMMM Do YYYY, h:mm a')}</div>
//                             </>
//                         )
//                     } */}
//                 </div>
//             </div>
//         </div>
//     )
// })

// const SortableList = SortableContainer(({ items }) => {
//     return (
//         <div className={classes.mainContainer}>
//             {items?.map((item, index) => (
//                 <SortableItem key={`item-${item.id}`} index={index} item={item} />
//             ))}
//         </div>
//     );
// });

// const SortableComponent = ({ items, setItems }) => {
//     const onSortEnd = ({ oldIndex, newIndex }) => {
//         items = arrayMoveImmutable(items, oldIndex, newIndex)
//         setItems(items);
//     };
//     return (
//         <>
//             <SortableList items={items} onSortEnd={onSortEnd} axis="xy" />
//         </>
//     );
// };

// const AllProductsPriority = () => {
//     const { products, loading } = useSelector(state => state.allProducts)
//     const [alert, setAlert] = useState({ isOpen: false, message: '' })
//     const [godAccess, setGodAccess] = useState(false)
//     const dispatch = useDispatch()
//     useEffect(() => {
//         dispatch(getAllProducts())
//         checkGodPermission()
//     }, [])
//     const checkGodPermission = async () => {
//         try {
//             const permission = await authService.checkLimitedPermissions(15);
//             setGodAccess(permission?.access)
//         } catch (error) {
//             setGodAccess(false)
//         }
//     }
//     const setAllProducts = (items) => {
//         dispatch(setProducts({ products: items }))
//     }
//     const handleOrderItems = async () => {
//         try {
//             dispatch(setAllItemsLoading({ loading: true }))
//             const constructArray = products.map((item, index) => {
//                 return {
//                     id: item.id,
//                     shop_now_display_order: index + 1
//                 }
//             });
//             const response = await auctionServices.updateItemShopNowDisplayOrder(constructArray)
//             dispatch(setAllItemsLoading({ loading: false }))
//             setAlert({ isOpen: true, message: response.message || constants.ADMIN_COPY_ITEM_PRIORITY_UPDATED })
//         } catch (error) {
//             dispatch(setAllItemsLoading({ loading: false }))
//             setAlert({ isOpen: true, message: error.response.data.message || constants.ADMIN_COPY_SOMETHING_WENT_WRONG })
//         }
//     }
//     return (
//         <Container title='Update All Products Priority' style={{ position: "relative", padding: "10px" }}>
//             {
//                 !godAccess ? <AccessDenied /> :
//                     <div>
//                         <div className='event_header_container'>
//                             <span className='calender_icon_container'><CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#34b5e5' }} /><p>Drag And Drop Items according to the order you want to show.</p></span>
//                             <button onClick={handleOrderItems} className="aitem-modal-btn" >Update</button>
//                         </div>
//                         <hr />
//                         <div className={classes.mainSection} >
//                             <SortableComponent items={products} setItems={setAllProducts} />
//                         </div>
//                     </div>
//             }
//             <LoadingModal isOpen={loading} />
//             <Alert isOpen={alert.isOpen} message={alert.message} onClose={() => setAlert({ isOpen: false, message: "" })} />
//         </Container>
//     )
// }

// export default AllProductsPriority;


const DragHandle = SortableHandle(() => <span className={classes.grabber}><DragIndicatorIcon className={classes.grabIcon}/></span>);

const SortableItem = SortableElement(({ item, isSelected, onClick }) => {
    return (
        <div 
            className={`items-drag-card ${classes.itemCard} ${isSelected ? classes.selected : ''}`} 
            onClick={onClick}
        >
            <DragHandle />
            <div className='items-drag-card-img'>
                <img src={item.assets && item.assets.length > 0 && item.assets.find(i => i.context_type === 'portrait')?.name} alt="" />
            </div>
            <div className='items-drag-card-content'>
                <div className='items-drag-card-header'>
                    {item.name}
                </div>
                <div style={{ marginTop: "10px", fontWeight: 600, fontSize: "0.9rem", display: "flex", gap: "30px" }}>
                    <div>Price: ${item.store_item_id ? item.store_item.price : item.price}</div>
                </div>
            </div>
        </div>
    );
});

const SortableList = SortableContainer(({ items, selectedItems, onItemClick }) => {
    return (
        <div className={classes.mainContainer}>
            {items?.map((item, index) => (
                <SortableItem 
                    key={`item-${item.id}`} 
                    index={index} 
                    item={item} 
                    isSelected={selectedItems.includes(item.id)}
                    onClick={() => onItemClick(item.id)}
                />
            ))}
        </div>
    );
});

const SortableComponent = ({ items, setItems, selectedItems, setSelectedItems }) => {
    const onSortEnd = ({ oldIndex, newIndex }) => {
        
        if(!selectedItems?.length) {
            items = arrayMoveImmutable(items, oldIndex, newIndex)
            setItems(items);
            return;
        }

       // Get the selected items
       const selected = items.filter(item => selectedItems.includes(item.id));

       // Remove the selected items from the original array
       const remainingItems = items.filter(item => !selectedItems.includes(item.id));

       // Insert the selected items back into the new position
       const updatedItems = [
           ...remainingItems.slice(0, newIndex),
           ...selected,
           ...remainingItems.slice(newIndex)
       ];

       setItems(updatedItems);
       setSelectedItems([]);
    };

    const onItemClick = (id) => {
        setSelectedItems((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter(itemId => itemId !== id);
            } else {
                return [...prevSelected, id];
            }
        });
    };

    return (
        <>
            <SortableList 
                items={items} 
                onSortEnd={onSortEnd} 
                axis="xy"
                selectedItems={selectedItems}
                onItemClick={onItemClick}
                useDragHandle
            />
        </>
    );
};

const AllProductsPriority = () => {
    const { products, loading } = useSelector(state => state.allProducts)
    const [alert, setAlert] = useState({ isOpen: false, message: '' })
    const [godAccess, setGodAccess] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllProducts())
        checkGodPermission()
    }, [])
    const checkGodPermission = async () => {
        try {
            const permission = await authService.checkLimitedPermissions(15);
            setGodAccess(permission?.access)
        } catch (error) {
            setGodAccess(false)
        }
    }
    const setAllProducts = (items) => {
        dispatch(setProducts({ products: items }))
    }
    const handleOrderItems = async () => {
        try {
            dispatch(setAllItemsLoading({ loading: true }))
            const constructArray = products.map((item, index) => {
                return {
                    id: item.id,
                    shop_now_display_order: index + 1
                }
            });
            const response = await auctionServices.updateItemShopNowDisplayOrder(constructArray)
            dispatch(setAllItemsLoading({ loading: false }))
            setAlert({ isOpen: true, message: response.message || constants.ADMIN_COPY_ITEM_PRIORITY_UPDATED })
        } catch (error) {
            dispatch(setAllItemsLoading({ loading: false }))
            setAlert({ isOpen: true, message: error.response.data.message || constants.ADMIN_COPY_SOMETHING_WENT_WRONG })
        }
    }

    return (
        <Container title='Update All Products Priority' style={{ position: "relative", padding: "10px" }}>
            {!godAccess ? <AccessDenied /> :
                <div>
                    <div className='event_header_container'>
                        <span className='calender_icon_container'><CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#34b5e5' }} /><p>Drag And Drop Items according to the order you want to show.</p></span>
                        <button onClick={handleOrderItems} className="aitem-modal-btn">Update</button>
                    </div>
                    <hr />
                    <div className={classes.mainSection}>
                        <SortableComponent 
                            items={products} 
                            setItems={setAllProducts} 
                            selectedItems={selectedItems} 
                            setSelectedItems={setSelectedItems}
                        />
                    </div>
                </div>
            }
            <LoadingModal isOpen={loading} />
            <Alert isOpen={alert.isOpen} message={alert.message} onClose={() => setAlert({ isOpen: false, message: "" })} />
        </Container>
    )
}

export default AllProductsPriority;