import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function AccordionMui({title,description,titleStyle={},descriptionStyle={},titleVarient='',descriptionVarient=''}) {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={titleStyle} variant={titleVarient}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={descriptionStyle} variant={descriptionVarient}>
            {description}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}