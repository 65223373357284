import React, { useEffect } from 'react'
import { useState } from 'react'
import "./FormTextarea.css";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip, Typography } from '@mui/material';



const Input = ({value, onChange, label, name, width = "100%", required=false, style={}, cols=3, rows=3, maxsize=null,iButton=false,infoSize='16px', placeholder="", infoBubbleText="",disabled=false}) => {

    const [charLeft, setCharLeft] = useState(maxsize)
    useEffect(() => {
        if(!maxsize) return
        setCharLeft(maxsize - value?.length)
    }, [value, maxsize])
  return (
    <div className='formtextarea-container' style={{width, ...style}}>
        <label className='formtextarea-label' htmlFor={name}>{label} {required && <span style={{color: "red"}}>*</span> }{iButton&&<Tooltip title={<Typography sx={{fontSize:'0.85rem'}}>{infoBubbleText}</Typography>}><InfoOutlinedIcon sx={{color:'#495057c4',fontSize:infoSize, marginLeft: "4px"}} /></Tooltip>} </label>
        <div className='formtextarea-textarea-container'>
          <textarea placeholder={placeholder} required={required} maxLength={maxsize} name={name} cols={cols} rows={rows} onChange={onChange} value={value} disabled={disabled}></textarea>
        </div>
        {maxsize && <div className='formtextarea-max-chars'>Characters Left: {charLeft}</div>}
    </div>
  )
}

export default Input