import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import auctionServices from '../services/auction-services'
import constants from '../constants/en';


export const fetchItemBuyersData = createAsyncThunk(
    'item/fetchItemBuyersData',
    async ({auction_id, itemId, page, limit}) => {
       try {
        const response = await auctionServices.getBuyersOfItem(auction_id, itemId, page, limit);
        return {
            data: response.data,
            totalBuyers: response.total_count,
        }
       }catch(error) {
        throw error
       }
    }
)

const initialState = {
    buyers: [],
    totalBuyers: 0,
    page:1,
    limit:10,
    loading:false,
    error: null
}

const itemBuyersSlice = createSlice({
    name: 'item/itemBuyers',
    initialState,
    reducers: {
        changePage: (state, action) => {
            state.page = action.payload
        },
        changeLimit: (state, action) => {
            state.limit = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchItemBuyersData.pending, (state, action) => {
            state.loading = true
            state.error = null
            state.buyers = []
        })
        builder.addCase(fetchItemBuyersData.fulfilled, (state, action) => {
            state.buyers = action.payload?.data
            state.loading = false
            state.totalBuyers = action.payload?.totalBuyers
            state.error = null
        })
        builder.addCase(fetchItemBuyersData.rejected, (state, action) => {
            state.buyers = []
            state.loading = false
            state.error = action.error?.message?.includes('401') ? constants.ADMIN_COPY_UNAUTHORIZED_USER : constants?.ADMIN_COPY_SOMETHING_WENT_WRONG
        })
    }
})

export default itemBuyersSlice.reducer
export const {changePage, changeLimit} = itemBuyersSlice.actions
