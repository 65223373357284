import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import auctionServices from '../services/auction-services'

export const fetchBiddersData = createAsyncThunk(
    'auction/fetchBiddersData',
    async ({auctionId, page, limit, startDate, endDate, orderSearchValue, orderSearchBy}) => {
        const response = await auctionServices.getBiddersReport(auctionId, page, limit, startDate, endDate, orderSearchValue, orderSearchBy)
        return {
            data: response?.data,
            totalBidders: response?.count?.length,
            totalBiddersPage: response?.total_page
        }
    }
)

const initialState = {
    biddersData: [],
    totalBidders: 0,
    biddersReportLoading:false,
    totalBiddersPage: 0,
    biddersHeading: ['First Name', 'Last Name', 'Email', 'Bids'],
    error:''
}

const biddersReportSlice = createSlice({
    name: 'auction/bidders',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchBiddersData.pending, (state, action) => {
            state.biddersReportLoading=true
        })
        builder.addCase(fetchBiddersData.fulfilled, (state, action) => {
            state.biddersData = action.payload?.data
            state.totalBidders = action.payload?.totalBidders
            state.biddersReportLoading = false
            state.totalBiddersPage = action.payload.totalBiddersPage
            state.error = ""
        })
        builder.addCase(fetchBiddersData.rejected, (state, action) => {
            state.biddersReportLoading = false
            state.error = action.payload?.error?.message
        })
    }
})

export default biddersReportSlice.reducer