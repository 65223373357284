import React from 'react'
import Container from "../components/HOC/Container/Container"
import preval from 'preval.macro'
import moment from "moment-timezone"
import { useNavigate } from 'react-router-dom'

const AppStatus = () => {
  const deployedTimestamp = preval`module.exports = new Date();`
  const navigate = useNavigate()
  return (
    <Container style={{minHeight: "fit-content"}} title="App Status">
        <div style={{textAlign: "center", fontWeight: "600"}}>
            Last Deployed: {moment(deployedTimestamp).tz('America/Los_Angeles').format('MMMM Do YYYY, h:mm:ss a z')}
            <div onClick={() => navigate(-1)} className='event-submit-btn' style={{marginTop: "40px", cursor: "pointer"}}>Go Back</div>
        </div>
    </Container>
  )
}

export default AppStatus