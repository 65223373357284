import React, { useState, useEffect } from "react";
import auctionServices from "../services/auction-services";
import logger from "../utils/logger";

const AuctionContext = React.createContext({
  auctionCategoryList: [],
});

export const AuctionContextProvider = (props) => {
  const [auctionCategoryList, setAuctionCategoryList] = useState([]);
  const [donorsList,setDonorsList]=useState();
  const [donor,setDonor]=useState({});
  useEffect( () => {
    auctionServices
       .getdonorsList()
       .then((res) => {
         setDonorsList((prev)=>{
             return res?.data || []
         });
       })
       .catch((err) => {
         throw err;
       });
      
   },[]);

  useEffect(() => {
    const getCategory = async () => {
      try {
        await auctionServices.getAuctionCategoryList().then(
          (response) => {
            setAuctionCategoryList(response?.data || []);
          },
          (error) => {
            logger('./store/auction-context.jsx','getCategory(): catch1',error);
            setAuctionCategoryList([]);
          }
        );
      } catch (err) {
        logger('./store/auction-context.jsx','getCategory(): catch2',err);
        setAuctionCategoryList([]);
      }
    };
    getCategory();
  }, []);

  return (
    <AuctionContext.Provider
      value={{
        auctionCategoryList: auctionCategoryList,
        donors:donorsList,
        donor:donor,
        setDonor
      }}
    >
      {props.children}
    </AuctionContext.Provider>
  );
};

export default AuctionContext;
