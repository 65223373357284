import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    orders:[],
    selectedEvent:{label:'Choose an event',value:null, type:'event'},
    totalOrders:0,
    page:1,
    limit:10,
    lastPage:''
}

const orderSlice = createSlice({
    name: "orderManagement",
    initialState,
    reducers: {
        addOrders: (state, action) => {
            state.orders = action.payload?.orders;
            state.totalOrders=action.payload?.totalOrders
        },
        chooseEvent: (state, action) => {
            state.selectedEvent=action.payload
        },
        changeLabel: (state,action) =>{
            state.selectedEvent ={label:`Choose ${action.payload}`,value:null}
        },
        setPageDetails: (state, action) => {
            state.page = action.payload.page?action.payload.page:state.page;
            state.limit = action.payload.limit ? action.payload.limit:state.limit;
        },
        resetSelectedEvent: (state, action) => {
            state.selectedEvent = {label:'Choose an event',value:null}
            state.orders = []
            state.totalOrders = 0
            state.page = 1
            state.limit = 10
        },
        setLastPage: (state, action) => {
            state.lastPage = action.payload.lastPage
        }
    }
})

export default orderSlice.reducer
export const {chooseEvent, addOrders,setPageDetails, resetSelectedEvent,changeLabel, setLastPage} = orderSlice.actions