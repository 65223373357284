import { BorderColor, ContentPasteGo, DeleteOutlineOutlined, MoreVert } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import TableButton from "../TableButton/TableButton";
import constants, { COLORS_OBJECT } from "../../../constants/en";
const redColor = COLORS_OBJECT.color[0]

const EventActionsMenu = ({
    eventType = "event",
    row,
    handleEdit = () => null,
    godMod,
    updateStatus = () => null,
    getButtonText = () => null,
    handleDeleteEvent = () => null,
    getFeaturedDetails = () => null,
    handleMakeFeatured=()=>null,
    handleCloneEvent=()=>null,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVert />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {['inreview', 'complete'].indexOf(row.status) === -1 &&
                    <MenuItem onClick={handleClose}>
                        <TableButton onClick={() => handleEdit(row)} Icon={BorderColor} extraClassName="itemListMenuButtons" />
                    </MenuItem>
                }
                {(['complete'].indexOf(row.status) === 0 && godMod) &&
                    <MenuItem onClick={handleClose}>
                        <TableButton onClick={() => handleEdit(row)} Icon={BorderColor} extraClassName="itemListMenuButtons"/>
                    </MenuItem>
                }
                {godMod && row.status === 'inreview' &&
                    <MenuItem onClick={handleClose}>
                        <TableButton onClick={() => updateStatus(row, 'approve')} color={redColor} text='Approve' extraClassName="itemListMenuButtons"/>
                    </MenuItem>
                }
                {godMod && row.status === 'inreview' &&
                    <MenuItem onClick={handleClose}>
                        <TableButton onClick={() => updateStatus(row, 'reject')} backgroundColor={redColor} color={'white'} style={{ border: 'none' }} text='Reject' extraClassName="itemListMenuButtons"/>
                    </MenuItem>
                }
                {getButtonText(row.status) &&
                    <MenuItem onClick={handleClose}>
                        <TableButton onClick={() => updateStatus(row)} backgroundColor={redColor} color={'white'} style={{ border: 'none' }} text={getButtonText(row.status)} extraClassName="itemListMenuButtons"/>
                    </MenuItem>
                }
                {(godMod && ['live', 'preview'].indexOf(row.status) === -1) &&
                    <MenuItem onClick={handleClose}>
                        <TableButton text='Delete' backgroundColor={redColor} color={'white'} onClick={() => handleDeleteEvent(row)} Icon={DeleteOutlineOutlined} extraClassName="itemListMenuButtons"/>
                    </MenuItem>
                }
                {(godMod && ['live'].includes(row.status)) &&
                    <MenuItem onClick={handleClose}>
                        <TableButton text={getFeaturedDetails(row).text} backgroundColor={'white'} color={redColor} onClick={() => handleMakeFeatured(row)} Icon={getFeaturedDetails(row).icon} extraClassName="itemListMenuButtons"/>
                    </MenuItem>
                }
                <MenuItem onClick={handleClose}>
                    <TableButton text={"Clone Event"} backgroundColor={'white'} color={redColor} onClick={() => handleCloneEvent(row)} Icon={ContentPasteGo} extraClassName="itemListMenuButtons"/>
                </MenuItem>
            </Menu>
        </div>
    )
}

export default EventActionsMenu;