import React from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { COLORS_OBJECT } from '../../../constants/en';
import classes from './TableButton.module.css'

const TableButton = ({type="button", onClick = () => null, text = 'Edit', color = COLORS_OBJECT.color[0], Icon = null, backgroundColor = 'white', borderColor=COLORS_OBJECT.color[0], style={}, needText=true, disabled=false, extraClassName='' }) => {
    return (
        <>
            <button
                onClick={onClick}
                className={`${classes.table_btn} ${classes[extraClassName]}`}
                style={{
                    backgroundColor:backgroundColor,
                    border:`1px solid ${borderColor}`,
                    ...style
                }}
                disabled={disabled}
                type={type}
            >
                {Icon && <Icon sx={{fontSize: "16px", color: color}}/>}
                {needText && <span style={{ color: color, fontWeight: "600" }}>{text}</span>}
            </button>
        </>
    )
}

export default TableButton;