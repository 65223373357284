import React from 'react'
import Container from '../../components/HOC/Container/Container'
import classes from '../TermsOfUse/TermsOfUse.module.css'
import { Link } from 'react-router-dom'


const Privacy = () => {
  return (
    <Container>
        <div className={classes.t_p_main}>
                <h1 className={classes.terms_heading} >Privacy Policy</h1>
                <p className={classes.text}>
                    By accessing or using the Services, or by accepting our <Link to='/admin/tos'>Terms and Conditions</Link>, 
                    or any other terms that incorporate this Privacy Policy by reference, 
                    you agree on behalf of yourself and any organization that you represent 
                    (together, “you”) that you have read and understand this Privacy Policy and 
                    that you consent to the collection, use, and sharing of information as discussed 
                    below. If you do not agree with this Privacy Policy, do not access or use the 
                    Services. This Policy is incorporated into and made a part of our <Link to='/admin/tos'>Terms and Conditions</Link>.
                </p>
                <div className={classes.section}>
                    <h1>1. Overview</h1>
                    <p className={classes.text}>
                        Boundless Giving, Inc. has developed this Privacy Policy to inform you how 
                        we collect data and use the data we collect. Boundless Giving provides a platform, 
                        applications, and our website (collectively, “Services”) specifically for 
                        fundraising activities by nonprofit and corporate customers.
                    </p>
                    <p className={classes.text}>
                        In this Privacy Policy, “you” or “your” refers to a user of our Services who may 
                        use the Services directly through us or through one of our corporate customers 
                        (e.g., a nonprofit organization or other corporate entity) (“Customer”). For example, 
                        you may be an individual seeking information from a Customer or you may be providing 
                        a donation to a Customer, and you use our platform or otherwise access the Services 
                        that we provide to the Customer. Please note that this Privacy Policy only covers 
                        the data we collect, and it does not apply to websites or services we do not control, 
                        including websites or services of our Customers.
                    </p>
                    <p className={classes.text}>
                        If you have questions, please contact us. Additional contact details are at the 
                        bottom of this Privacy Policy.
                    </p>
                </div>
                <div className={classes.section}>
                    <h1>2. Data We Collect</h1>
                    <p className={classes.text}>
                        When you use the Site, we collect and process the following types of information:
                    </p>
                    <div className={classes.sub_section}>
                        <h3>a. Information You Provide</h3>
                        <p className={classes.text}>
                            You may register to use our Services, set up an account,, or respond to 
                            communications (e.g., surveys, polls, requests for feedback), we will 
                            collect the information you provide to us. This may include your IP address, 
                            first and last name(s), demographic information, mailing address, e-mail 
                            address, phone number, and credit card number. In the use of Services, you 
                            may also choose to disclose or provide your communication preferences, your 
                            physical location, and your demographic information (e.g., your age, marital 
                            status, ethnicity/race, and gender). All of this is “Personal Information,” 
                            because it can be used to identify you.
                        </p>
                    </div>
                    <div className={classes.sub_section}>
                        <h3>b. Information We Collect about You</h3>
                        <p className={classes.text}>
                            We collect information about your use of our Services, including but not limited 
                            to: your Internet connections, computer equipment, web browsers, websites visited 
                            before using or accessing the Services, websites visited after leaving the Services, 
                            and other similar information about traffic and usage, as you navigate to, through, 
                            and away from our website. This is “Non-Personal Information,” because it does not 
                            identify you, but provides insights to us regarding the uses of our Services. 
                            For example, we use this information to generate statistical information, monitor 
                            and analyze traffic and usage patterns in connection with our Services, monitor and 
                            prevent fraud, investigate complaints and potential violations of our policies, and 
                            to improve the our content and the products, services, materials, and other content 
                            that we describe or make available through the Services.
                        </p>
                        <p className={classes.text}>
                            In some jurisdictions, such as the United States, an IP address may be considered 
                            Non-Personal Information. In the European Economic Area an IP address is considered 
                            Personal Information under applicable data protection laws. If this is the case, 
                            we process Non-Personal Information for the same purposes as Personal Information 
                            under this Privacy Policy.
                        </p>
                    </div>
                    <div className={classes.sub_section}>
                        <h3>c. Geolocation Information</h3>
                        <p className={classes.text}>
                            You may choose to allow us to access your location by granting the Services access to 
                            your location when prompted or through your device’s location services settings. 
                            You may change these settings on your device.
                        </p>
                    </div>
                </div>
                <div className={classes.section}>
                    <h1>3. How We Use Data</h1>
                    <p className={classes.text}>
                        Boundless Giving uses Personal information and
                        Non-Personal Information for the following purposes:
                    </p>
                    <ul className={classes.lists}>
                        <li>
                            As necessary to perform the Services on your behalf or on behalf of our Customers with 
                            whom you may have an account and for other legitimate and lawful business purposes. This may include:
                        </li>
                        <ul className={classes.lists}>
                            <li><b>Establishing accounts to use the Services</b></li>
                            <li><b>Communicating with you in connection with Services or as a result of a request</b></li>
                            <li><b>Communicating promotional materials, such as surveys, event notifications, newsletters, and other information</b></li>
                            <li>Notifying you of changes made to the Services</li>
                            <li>Maintaining a record of the donations and related activities in connection with your use of the Services.</li>
                        </ul>
                        <li>Sharing with the recipient non-profit organization when making a bid or purchase;</li>
                        <li>Sharing with the donor (brand or individual) when making a bid or purchase on a donated item;</li>
                        <li>
                            Sharing with our service providers that perform certain business functions or services on our behalf and with 
                            whom are bound by contractual obligations consistent with this Privacy Policy;
                        </li>
                        <li>Evaluating and improving the Services;</li>
                        <li>To comply with a legal obligation, a court order, or in order to exercise our legal claims, or to defend against legal claims;</li>
                        <li>To prevent or investigate fraud (or for risk management purposes);</li>
                        <li>To describe our Services to current and prospective business partners and to other third parties for other lawful purposes; and</li>
                        <li>
                            To conduct aggregate analysis and develop business intelligence that helps us to enhance; operate, protect, make informed 
                            decisions, and report on the performance of our Services. Boundless Giving may share this aggregate data with its customers, 
                            affiliates, agents and business partners. This aggregated data will not identify an end user as an individual. Boundless 
                            Giving may also disclose aggregated statistics prepared using Collected Information in order to describe our Services to 
                            current and prospective business partners and to other third parties for other lawful purposes.
                        </li>
                    </ul>
                </div>
                <div className={classes.section}>
                    <h1>4. How We Disclose Data</h1>
                    <p className={classes.text}>
                        We do not sell or rent Personal Information to marketers or unaffiliated third parties. We do have trusted third parties and 
                        we may share your Personal and Non-Personal Information (“Collected Information”) with the following entities:
                    </p>
                    <ul className={classes.lists}>
                        <li>
                            Service Providers that perform certain functions or services on our behalf, such as to host or assist with the Services, 
                            manage databases, process payments or donations, host a store or other e-commerce platform, perform analyses, or send 
                            communications for us. We require these service providers to comply with all applicable data privacy laws and regulations;
                        </li>
                        <li>
                            Business Partners that assist in the delivery of the Services (e.g., merchant service providers, organizations holding 
                            fundraising events, vendors who have provided bidding items);
                        </li>
                        <li>Customers, as necessary to provide the Services;</li>
                        <li>
                            Authorized third parties, who are parties directly authorized by you to receive the applicable Collected Information, 
                            such as when a you authorize a third-party application provider to access your account. The use of your Collected 
                            Information by an authorized third party is subject to the third party’s privacy policy; and
                        </li>
                        <li>
                            Third parties in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other 
                            disposition of all or any portion of our business, assets or stock (including in connection with any bankruptcy or similar proceedings).
                        </li>
                        <li>
                            We may use and disclose Collected Information as we believe necessary: (i) under applicable law; (ii) to enforce applicable terms and 
                            conditions; (iii) to protect our rights, privacy, safety or property, and/or that of our affiliates, you, or others; and (iv) to 
                            respond to requests from courts, law enforcement agencies, regulatory agencies, and other public and government authorities, 
                            which may include authorities outside your country of residence.
                        </li>
                    </ul>
                </div>
                <div className={classes.section}>
                    <h1>5. Other Services and Platforms</h1>
                    <p className={classes.text}>
                        We work with third parties with whom we have a contractual relationship. For example, we use other third parties for technical, payment, 
                        and delivery services, analytics providers, and search information providers. For example, when you submit credit card or debit card 
                        information, that Personal Information is provided directly to our third-party payment processor. Please refer to the privacy policy on any third party website.
                    </p>
                    <p className={classes.text}>
                        As part of our fundraising platform, you may choose to link our Services to a third party account or platform. For purposes of this 
                        Privacy Policy, if you authorize or otherwise enable an account or platform operated by a third party, then you will control that 
                        connection (“Connection”). For example, you may create a Connection to a social media account, in which case, we will receive 
                        Personal Information from the social media provider through the Connection. All information we receive through a Connection will 
                        be subject to this Privacy Policy.
                    </p>
                    <p className={classes.text}>
                        Please review the Privacy Policy of any and all third parties. We have no control over, and assume no responsibility for, the content, 
                        privacy policies, or practices of any third party websites or services.
                    </p>
                </div>
                <div className={classes.section}>
                    <h1>6. Security</h1>
                    <p className={classes.text}>
                        Although we use security measures designed to protect your Personal Information, no data security measures are guaranteed to be completely 
                        effective. Transmission of information via the internet is not wholly secure, and we cannot guarantee the security of your Personal 
                        Information transmitted to or through the website or any of our Services. For example, please do not include credit card or other 
                        sensitive data (e.g., racial or ethnic origin, health, etc.) in your e-mails to us. Any transmission of such Personal Information is 
                        at your own risk. By using the Services, you acknowledge and accept these risks.
                    </p>
                    <p className={classes.text}>
                        Please notify us immediately at help@Boundlessgiving.com if you become aware of any unauthorized use of your password or account or any 
                        other breach of Service security or of this Policy. If our security system is breached, we will notify you of the breach as required by applicable law.
                    </p>
                </div>
                <div className={classes.section}>
                    <h1>7. Advertising</h1>
                    <p className={classes.text}>
                        We use other third-party services to obtain, track, and store user information. For example, we use a customer relations management (CRM) 
                        software to manage data and information. We also use a third-party software to collect information from your browser and computer device 
                        in order to capture and analyze user behavior on our website. From time to time, we will use additional content or applications in the 
                        Services that is served by third parties, including advertisers, ad networks and servers, social media websites, content providers, 
                        and application providers. These third parties may use cookies alone or in conjunction with other Internet tracking technologies to 
                        collect information about you when you use our Services. We do not control these third parties’ tracking technologies or how they may 
                        be used. Note that if any of these third parties are members of the Network Advertising Initiative, you can visit the website to opt-out 
                        of unwanted online advertisements by clicking here.    
                    </p>
                </div>
                <div className={classes.section}>
                    <h1>8. Access and Options. You have choices regarding our use and disclosure of Your Personal Data:</h1>
                    <p className={classes.text}>
                        If you change your mind after opting to receive electronic communications, you may opt out of receiving such communications from us by 
                        communicating your preferences to us by e-mailing <a href="mailto:help@boundlessgiving.com">help@BoundlessGiving.com</a>, or by following 
                        the unsubscribe link contained in the applicable e-mail. We will comply with your request as soon as reasonably practicable. Please note 
                        that if you opt-out of receiving marketing-related emails from us, we may still send you important administrative messages that are required 
                        to provide you with our Services.
                    </p>
                    <p className={classes.text}>
                        In certain circumstances, you may also request that we delete your Personal Information, for example if our retention of your Personal 
                        Information is no longer necessary as part of the Services.
                    </p>
                    <p className={classes.text}>
                        If you would like to review, correct, or update Personal Information that you have previously disclosed, you may do so by signing into 
                        your account or by contacting us. For your protection, we may only implement requests with respect to the Personal Information associated 
                        with the particular email address that you use to send us your request, and we may need to verify your identity before implementing your 
                        request. We will try to comply with your request as soon as reasonably practicable. Unless otherwise prohibited by law, we may charge 
                        you a fee for providing you with a copy of your data.
                    </p>
                    <ul className={classes.lists}>
                        <li>
                            <b>Retention.</b> We will retain Personal Data for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a 
                            longer retention period is required or permitted by law.
                        </li>
                        <li>
                            <b>Use of Services and Privacy of Minors.</b> We do not provide the Services to anyone under the age of 13. In accordance with the Children’s Online Privacy 
                            Protection Act (COPPA), we will never knowingly request or solicit Personal Information from anyone under the age of 13. Please 
                            notify us if we receive such Personal Information of anyone under the age of 13, and we will delete that information from our 
                            database as soon as is reasonably practical.
                        </li>
                        <li>
                            <b>Access from Outside the United States.</b> If you are visiting the Services from outside the United States, please be aware that Collected Information may 
                            be transferred to, stored in, and processed in the United States, which is our suppliers’ servers and databases are located and operated.
                        </li>
                    </ul>
                </div>
                <div className={classes.section}>
                    <h1>9. Boundless Giving as a Data Processor</h1>
                    <p className={classes.text}>
                        We may collect, use, and disclose certain Personal Information about you when acting as service provider to a Customer. Customers are 
                        responsible for ensuring that your privacy rights are respected, and should include information to help you understand how third 
                        parties collect and use your data. To the extent that we are acting as a Customer’s data processor, we will process your Personal 
                        Information according to the terms of our agreement with our Customer and its lawful instructions.
                    </p>
                    <ul className={classes.lists}>
                        <li>
                            <b>Filing a Complaint.</b> If you are not satisfied with how we manage your Personal Information, you have the right to make a 
                            complaint to a data protection regulator.
                        </li>
                        <li>
                            <b>Updates to Privacy Policy.</b> We may change this Privacy Policy from time to time. Any changes are effective when we post 
                            the revised Privacy Policy on or through the Services. Disclosures and notices in relation to this Privacy Policy or your Personal 
                            Information shall be considered to be received by you within twenty-four (24) hours after the time they are posted to our website 
                            or otherwise through the Services.
                        </li>
                    </ul>
                </div>
                <div className={classes.section}>
                    <h1>10. Your California Privacy Rights</h1>
                    <p className={classes.text}>
                        California Civil Code Section 1798.83 permits users of the Services who are California residents to request and obtain a list of what 
                        Personal Information (if any) we disclosed to third parties for direct marketing purposes in the preceding calendar year and the names 
                        and addresses of those third parties. Requests may be made only once a year and are free of charge. Under Section 1798.83, California 
                        residents are entitled to request and obtain such information, by e-mailing a request to <a href="mailto:help@boundlessgiving.com">help@BoundlessGiving.com</a>.
                    </p>
                </div>
                <div className={classes.section}>
                    <h1>11. Contact Us</h1>
                    <p className={classes.text}>A Boundless Giving representative can be reached by email at <a href="mailto:help@boundlessgiving.com">help@BoundlessGiving.com</a>.</p>
                    <p className={classes.text}>If you have any questions or concerns about this Policy, please contact us by email at:</p>
                    <p className={classes.text}><a href="mailto:help@boundlessgiving.com">help@BoundlessGiving.com</a>, or in writing to:</p>
                    <p className={classes.text}>Boundless Giving, Inc.</p>
                    <p className={classes.text}>Attn: Privacy</p>
                    <p className={classes.text}>22287 Mulholland Hwy. PMB #570 Calabasas, CA 91302</p>
                </div>
            </div>
    </Container>
  )
}

export default Privacy