import React, { useState } from 'react'
import Input from '../../../components/Input/Input';

const VaraintsModalContent = ({localVariants, setLocalVariants, setShowPrompt}) => {

    const handleQuantityChange = (e, key, field) => {
        setShowPrompt(true)
        const newVariants = localVariants.map((v) => {
            if (v.key === key) {
            return {
                ...v,
                [field]:
                e.target.value === "" ? e.target.value : Number(e.target.value),
            };
            }
            return v;
        });
        setLocalVariants(newVariants)
    };

        const handleListPriceChange = (e, key, field) => {
            setShowPrompt(true)
            const newVariants = localVariants.map((v) => {
                if (v.key === key) {
                return {
                    ...v,
                    [field]:
                    e.target.value === "" ? e.target.value : Number(e.target.value),
                };
                }
                return v;
            });
            setLocalVariants(newVariants)
        };

        const handleUPCChange = (e, key) => {
            setShowPrompt(true)
            const newVariants = localVariants.map(v => {
                if (v.key === key) {
                return {
                    ...v,
                    upc: e.target.value
                }
                }
                return v
            })
            setLocalVariants(newVariants)
        } 

        const handleVendorSKUChange = (e, key) => {
            setShowPrompt(true)
            const newVariants = localVariants.map(v => {
                if (v.key === key) {
                return {
                    ...v,
                    vendor_sku_id: e.target.value
                }
                }
                return v
            })
            setLocalVariants(newVariants)
        } 
        
    return (
    <>
        {!localVariants || localVariants.length === 0 ? null : (
            <div>
                <div
                className="rounded varaints-table-container"
                style={{ maxHeight: "350px", overflow: "auto", padding: "5px" }}
                >
                <table style={{width: "100%"}}>
                    <thead>
                    <tr>
                        <th>Variant</th>
                        <th>Quantity</th>
                        <th>UPC</th>
                        <th>Vendor SKU Id</th>
                    </tr>
                    </thead>
                    <tbody>
                    {localVariants &&
                        localVariants.map((v) => (
                        <tr key={v.key}>
                            <td style={{fontSize: "0.85rem", fontWeight: "600"}}>{v.key}</td>
                            <td>
                            <Input
                                name="qty"
                                value={v.quantity_in_stock}
                                onChange={(e) =>
                                    handleQuantityChange(e, v.key, "quantity_in_stock")
                                }
                                type="number"
                                inputStyle={{ fontSize: "0.8rem" }}
                                placeholder="0"
                                onWheel={(e) => e.target.blur()}
                            />
                            </td>
                            <td>
                            <Input
                                name="upc"
                                value={v.upc}
                                onChange={(e) =>
                                handleUPCChange(e, v.key, "upc")
                                }
                                type="text"
                                inputStyle={{fontSize: "0.8rem" }}
                                placeholder="UPC"
                            />
                            </td>
                            <td>
                            <Input
                                name="vendor_sku_id"
                                value={v.vendor_sku_id}
                                onChange={(e) =>
                                    handleVendorSKUChange(e, v.key, "vendor_sku_id")
                                }
                                type="text"
                                inputStyle={{fontSize: "0.8rem" }}
                                placeholder="Vendor SKU Id"
                            />
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            </div>
        )}
    </>
    )
}

export default VaraintsModalContent