import React, { useState, useEffect } from 'react';
import { useParams, useNavigate,useLocation } from 'react-router-dom';
import moment from 'moment';

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import Container from '../../components/HOC/Container/Container';
import Input from '../../components/Input/Input';
import DropDown from '../../components/DropDown/DropDown';
import TextArea from '../../components/FormTextarea/FormTextarea'
import './editOrder.css';
import orderService from '../../../services/order-services';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import Alert from '../../components/Alert/Alert';
import constants from '../../../constants/en';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Modal from '../../components/Modal/Modal';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { setLastPage } from '../../../redux/orderReducer';
import { useDispatch } from 'react-redux';
import { checkNetworkError } from '../../../utils/util-functions';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function EditOrder() {
    const { xrefId } = useParams()
    const { state } = useLocation();
    let bidders;
    if(state && state?.bidders?.length){
         bidders = (state.bidders.reverse().filter((obj, index) => {
            return index === state.bidders.findIndex(o => obj.user_id === o.user_id);
          }));
    }

    const navigate = useNavigate()
    const [form, setForm] = useState({
        orderNumber:'',
        product: '',
        sku: '',
        status: '',
        purchaseDate: '',
        price: 0,
        email: '',
        tracking_url: '',
        fullfilment:'',
        shippingAddress:'',
        quantity:0,
        paymentStatus:"success",
        user_id:""
    });
    const [defaultStatus, setDefaultStatus] = useState({ value: '', label: '' })
    const [isLoading, setIsLoading] = useState(false)
    const [alert,setAlert] = useState({isOpen:false,message:'',})
    const [modal,setModal]= useState(false);
    let [order_id,setOrderId]= useState("");
    const [alertMsg,setAlertMsg] = useState(null)
    const dispatch = useDispatch()

    const handleForm = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        fetchOrders()
        dispatch(setLastPage({lastPage:'edit-order'}))
    }, [])

    const handleRetryPaymnet =async (data) =>{
        setModal(false);
        setIsLoading(true)
        const response = await orderService.retryPayment(order_id,data?.user_id,data?.auction_item_id,data?.bid_amount);
        console.log(response)
        if(response.status == 200){
            setIsLoading(false)
            setAlertMsg(response?.data?.message)
        }else{
            setIsLoading(false)
            setAlertMsg(response?.response?.data?.message)
        }
    }

    const fetchOrders = async () => {
        setIsLoading(true)
      try {
        let data = await orderService.getOrder(xrefId);
        let userShipping = data.orderdata?.orderinfo?.user?.user_shippings[0];
        let items = data.orderdata?.items;
        let orderInfo = data.orderdata?.orderinfo;
        setOrderId(orderInfo?.id)
        setForm({
            orderNumber: `BG_${orderInfo?.id}`,
            product: items?.auction_item?.name,
            sku: items?.product_sku_id,
            orderStatus: orderInfo?.status,
            purchaseDate: orderInfo?.created,
            price: items?.auction_item?.price==0? items?.auction_item?.store_item?.price:orderInfo?.subtotal,
            quantity:items.quantity_bought,
            fullfilment:items?.auction_item?.fulfilment?.name,
            email: orderInfo?.user?.email,
            paymentStatus: orderInfo?.payment_status,
            user_id:orderInfo?.user?.id,
            shippingAddress: `${userShipping.name}, `+
            `${userShipping.address1}, `+
            `${userShipping.address2}, `+
            `${userShipping.city}, `+
            `${userShipping.state}, `+
            `${userShipping.country},`+
            `${userShipping.zip}, `+
            `${userShipping.phone}`

        })
        setIsLoading(false)
    }
    catch(error) {
        setIsLoading(false)
        if(!checkNetworkError(error)) {
            setAlertMsg(constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
        }
    }
    }
    const options = [
        { value: "1", label: "Order Placed" },
        { value: "6", label: "Shipped" },
        { value: "7", label: "Out For Delivery" },
        { value: "8", label: "Delivered" },
        { value: "9", label: "Packed" },
        { value: "12", label: "In Transit" },
        { value: "14", label: "Returned" },
    ]
    const DefaultStatus = (value) => {
        let label = ''
        switch (value) {
            case '0':
                label = 'Order Placed'
                break;
            case '1':
                label = 'Order Placed'
                break;
            case '3':
                label = 'Shipped'
                break;
            case '6':
                label = 'Shipped'
                break;
            case '7':
                label = 'Out For Delivery'
                break;
            case '8':
                label = 'Delivered'
                break;
            case '9':
                label = 'Packed'
                break;
            case '12':
                label = 'In Transit'
                break;
            case '14':
                label = 'Returned'
                break;
            case '15':
                label = 'Address Check Failed'
                break;
            // case null:
            //     value = 'Payment Failed'
            //     break;
            default:
                label = ''
                break;
        }
        setDefaultStatus({ value, label })
        return
    }
    useEffect(() => {
        DefaultStatus(form?.orderStatus)
    }, [form?.orderStatus])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setAlert({isOpen:true,message:constants.ADMIN_COPY_EDIT_ORDER_COMMON_MSG})
    }
    const confirmSubmit = async () => {
        try {
            setIsLoading(true)
            setAlert({isOpen:false,message:''})
            let data = await orderService.updateOrderStatus(form)
            setIsLoading(false)
            // navigate('/admin/order')
            setAlertMsg('Order Updated Successfully!')
        }
        catch(error) {
            setIsLoading(false)
            setAlertMsg(constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
        }
    }
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    

    return (
        <Container title='Edit Orders'>
            <div className='event_header_container'>
                <span className='calender_icon_container'><CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#34b5e5' }} /><p>Events</p></span>
            </div>
            <hr />

            <div className='edit-order' >
                <div>
                    <Input name='orderNumber' value={form?.orderNumber} label='Order No.' disabled />
                    <Input name='product' value={form?.product} onChange={handleForm} label='Product' disabled />
                    <Input name='skus' value={form?.sku} onChange={handleForm} label='SKUs' disabled />
                    <Input name='email' value={form?.email} onChange={handleForm} label='Email' disabled />
                    <TextArea name='about' value={form?.shippingAddress} label='Shipping Address' disabled/>
                </div>
                <div>
                    <Input name='purchase_date' value={moment(form?.purchaseDate).format('DD/MM/YYYY')} onChange={handleForm} label='Purchase date' disabled />
                    {state?.bid?
                    <Input name='bids' value={state?.bid}  label='Bids' disabled />:
                    <Input name='quantity' value={form?.quantity}  label='Quantity' disabled />}
                    <Input name='total_price' value={form?.price ? USDollar.format(+form?.quantity * parseFloat(form?.price)):'Not available'}  label={state?.bid? parseFloat(form?.price) > parseFloat(state?.winningBid)? 'Buy Now Price':'Winning Bid':'Total price (excl. tax and charges)'} disabled  />
                    {/* <Input name='tracking_url' value={form?.url} onChange={handleForm} label='Tracking url' required /> */}
                    {defaultStatus?.label !== '' && form.paymentStatus === "success" && <DropDown onChange={(e) => setForm({ ...form, orderStatus: e.value })} required options={options}
                        label='Status' menuPlacement='top' defaultValue={defaultStatus}
                        //  isDisabled={form?.fullfilment==='self'?false:true} 
                         />}
                    <div>
                        {
                           form.paymentStatus === "failed"  && <button className='order-update-btn' onClick={()=>{ setModal(true)}} >Manage order</button>
                        }
                        {
                         defaultStatus?.label !== '' && form.paymentStatus === "success" && <>  <div className='needhelp'><span>Need Help?</span></div>
                            <button style={{width:'100%'}} className='aitem-modal-btn' type='submit' onClick={handleSubmit}>Update</button></> 
                        }
                        
                    </div>
                </div>
            </div>
            <LoadingModal isOpen={isLoading} />
            <Alert onConfirm={confirmSubmit} isOpen={alert.isOpen} type='prompt' message={alert.message} onClose={()=>setAlert({isOpen:false,message:''})} />
            <Alert isOpen={!!alertMsg} message={alertMsg} onClose={()=>setAlertMsg(null)} />
            <Modal title='Bids' isOpen={modal} onClose={() => setModal(false)} onDone={() => setModal(false)} buttonText='Close' modalStyle={{ marginTop: '60px' }} contentStyle={{ width: '90%', overflowY: 'auto' }} >
                                <TableContainer>
                                    {(state && bidders?.length) ? <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Name</StyledTableCell>
                                                <StyledTableCell>Email</StyledTableCell>
                                                <StyledTableCell>Bid Date</StyledTableCell>
                                                <StyledTableCell>Bid Amount</StyledTableCell>
                                                <StyledTableCell>Manage</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {bidders?.map((row, i) => {
                                                return <StyledTableRow key={row.id}>
                                                    <StyledTableCell>{row.user.first_name} {row.user?.last_name}</StyledTableCell>
                                                    <StyledTableCell>{row.user.email}</StyledTableCell>
                                                    <StyledTableCell>{moment(row.bid_date).format('DD/MM/YYYY hh:mm')}</StyledTableCell>
                                                    <StyledTableCell>{USDollar.format(row.bid_amount)}</StyledTableCell>
                                                    <StyledTableCell><div onClick={() =>handleRetryPaymnet(row) } className='table-btn'> <AccountBalanceOutlinedIcon sx={{ fontSize: "16px", color: "#5EABD6" }} />
                                                          <span style={{ color: "#5EABD6", fontWeight: "600" }}>{(form?.user_id === row?.user_id)?"Retry Payment":"Place Order"}</span>
                                                       </div></StyledTableCell>
                                                </StyledTableRow>
                                            })}
                                        </TableBody>
                                    </Table> : <Paper sx={{ height: '100px', textAlign: 'center', paddingY: '140px' }}>
                                        <p>No Bids to show</p></Paper>}
                                </TableContainer>
            </Modal>

        </Container>
    )
}

export default EditOrder