
import "./Alert.css"
const DateAlert = ({isOpen, type='alert', message, onClose, onConfirm, style={}}) => {
    if(!isOpen) return null
  return (
    <div className='bg-alert' style={{...style}}>
        <div className='bg-alert-box'>
            <div className='bg-alert-content'>{message}</div>
            <div className='bg-alert-btn-container'>
                {type !== 'alert' && <button onClick={onClose} type='button' className='bg-alert-cancel'>No</button>}
                <button onClick={type === 'alert' ? onClose : onConfirm} type='button' className='bg-alert-ok'>Yes</button>
            </div>
        </div>
    </div>
  )
}

export default DateAlert