import { useLocation, useNavigate, useParams } from "react-router-dom";
import Container from "../../components/HOC/Container/Container";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { useDispatch, useSelector } from "react-redux"
import { fetchAvailableCategoriesOfEvent, setCategoriesHaveItem, toggleCategoryLoading } from "../../../redux/categoriesHaveItem";
import { useEffect, useState } from "react";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "@rexxars/react-sortable-hoc";
import './CategoryPriorityOrder.css'
import auctionServices from "../../../services/auction-services";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import constants, { COLORS_OBJECT } from "../../../constants/en";
import Alert from "../../components/Alert/Alert";

const redColor = COLORS_OBJECT.color[0];

const SortableItem = SortableElement(({ item }) => {
  return (
    <div className='items-drag-card drag_card'>
      <div className='items-drag-card-content'>
        <div className='items-drag-card-header'>{item?.category?.name || item?.custom_category?.category_name}</div>
      </div>
    </div>
  )
});


const SortableList = SortableContainer(({ items }) => {
  return (
    <div className="sortable_container">
      {items?.map((item, index) => (
        <SortableItem key={`item-${item.id}`} index={index} item={item} />
      ))}
    </div>
  );
});


const SortableComponent = ({ items, setItems }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMoveImmutable(items, oldIndex, newIndex));
  };
  return (
    <>
      <SortableList items={items} onSortEnd={onSortEnd} lockAxis="y" />
    </>
  );
};

const CategoryPirorityOrder = () => {
  const { availableCategories, loading } = useSelector(state => state.categoriesHaveItem);
  const dispatch = useDispatch()
  const { auction_id } = useParams()
  const search = useLocation().search;
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState({isOpen:false, message: ''})
  let itemType = new URLSearchParams(search).get('item-type');
  let event = false;
  if (['auction_item', 'store_item'].includes(itemType)) {
    event = true;
  }
  const setCategories = (cats) => {
    dispatch(setCategoriesHaveItem({ categories: cats }))
  }
  const fetchCategoriesHaveItem = async () => {
    try {
      dispatch(fetchAvailableCategoriesOfEvent({ auction_id: auction_id, itemType: itemType }))
    } catch (error) {
      setAlertMessage({isOpen:true, message: constants.ADMIN_COPY_SOMETHING_WENT_WRONG})
    }
  }
  useEffect(() => {
    if (!auction_id) return;
    fetchCategoriesHaveItem()
  }, [itemType])

  const handleNavigation = (value) => {
    navigate(`/admin/category-priority-order/${auction_id}?item-type=${value}`)
  }

  const onSaveHandler = async (navAction) => {
    const categoryArray = [];
    const customCategoryArray = [];
    try {
      dispatch(toggleCategoryLoading({ loading: true }))
      for (let i = 0; i < availableCategories?.length; i++) {
        if (availableCategories[i]?.category) {
          categoryArray?.push({ category_id: availableCategories[i]?.category?.id, display_order: i + 1 })
        }
        else {
          customCategoryArray?.push({ custom_category_id: availableCategories[i]?.custom_category?.id, display_order: i + 1 })
        }
      }
      const response = await auctionServices.updateEventCategoriesDisplayOrder(auction_id, categoryArray, customCategoryArray)
      dispatch(toggleCategoryLoading({ loading: false }))
      if(navAction === 'back') {
        if(event) {
          navigate('/admin/items');
        }
        else {
          navigate(-1);
        }
      }
      else {
        dispatch(fetchAvailableCategoriesOfEvent({ auction_id: auction_id, itemType: itemType }))
        setAlertMessage({isOpen:true, message: response?.message || constants.ADMIN_COPY_CATEGORY_ORDER_UPDATED})
      }
    }
    catch (error) {
      dispatch(toggleCategoryLoading({ loading: false }))
      setAlertMessage({isOpen:true, message: constants.ADMIN_COPY_SOMETHING_WENT_WRONG})
    }
  };

  return (
    <Container title='Category Order' style={{ position: "relative", padding: "10px" }}>

      {event &&
        <div className='items-navtab-radio'>
          <div style={{ fontWeight: "600" }}>Select the type of catgories you want to sort.</div>
          <RadioGroup row value={itemType} onChange={(e) => handleNavigation(e.target.value)}>
            <FormControlLabel
              sx={{ ".MuiFormControlLabel-label": { fontSize: "16px", fontWeight: 600, fontFamily: "AvenirNext" } }}
              value={'auction_item'}
              control={<Radio size='small' sx={{ "&.Mui-checked": { color: redColor, }, }} />}
              label="Auction Item Categories"
            />
            <FormControlLabel
              sx={{ ".MuiFormControlLabel-label": { fontSize: "16px", fontWeight: 600, fontFamily: "AvenirNext" } }}
              value="store_item"
              // value={itemType}
              control={<Radio size='small' sx={{ "&.Mui-checked": { color: redColor, }, }} />}
              label="Store Item Categories"
            />
          </RadioGroup>
        </div>}

      {
        availableCategories?.length ?
          <>
            <SortableComponent items={availableCategories} setItems={setCategories} />
            <div className='event-two-inputs'>
              <button className='aitem-modal-btn aitem-white-button' onClick={() => onSaveHandler('back')} style={{ width: "100%", fontSize: "1.1rem" }}>Save and Go Back</button>
              <button className='aitem-modal-btn' onClick={() => onSaveHandler('continue')} style={{ width: "100%", fontSize: "1.1rem" }}>Save and Continue Editing</button>
            </div>
          </>
          :
          <h5 className='no_items'>No categories to show.</h5>
      }
      <LoadingModal isOpen={loading} />
      <Alert isOpen={alertMessage.isOpen} message={alertMessage.message} onClose={() => setAlertMessage({isOpen:false, message:''})}/>
    </Container>
  )
}

export default CategoryPirorityOrder