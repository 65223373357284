import React from 'react'
import "./Container.css";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom'
import { COLORS_OBJECT } from '../../../../constants/en';

const redColor = COLORS_OBJECT.backgroundColor[0]
const shadyWhite = COLORS_OBJECT.backgroundColor[2]

const Container = ({ children, showButton = false,to='', buttonText = '', title = "", style = {}, className = "", onClickButton=null, containerStyle={} }) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(to)
  }
  return (
    <div style={{backgroundColor:shadyWhite}} className='main-container'>
      {title && <div className='main-container-title'>{title}
        {showButton && <div style={{borderColor: redColor, color:redColor}} className='add_events' onClick={onClickButton !== null ? onClickButton : handleClick}><span ><AddCircleIcon sx={{ color: redColor, fontSize: '30px', marginRight: '4px' }} /></span>{buttonText}</div>}
      </div>}
      <div className={`main-box ${className}`} style={{ ...style }}>
        {children}
      </div>
    </div>
  )
}

export default Container