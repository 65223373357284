import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import auctionServices from '../services/auction-services'
import constants from '../constants/en';


export const getAllProducts = createAsyncThunk(
    'item/getAllProducts',
    async () => {
        try {
            const response = await auctionServices.getAllProducts();
            return {
                data: response.data,
                totalProducts: response.totalCount,
            }
        } catch (error) {
            throw error
        }
    }
)

const initialState = {
    products: [],
    totalProducts: 0,
    loading: false,
    error: null
}

const allProductsSlice = createSlice({
    name: 'item/allProducts',
    initialState,
    reducers: {
        setProducts: (state, action) => {/// using for changing the display order from AllProductsPriority
            state.products = action.payload.products
        },
        setAllItemsLoading: (state, action) => {
            state.loading = action.payload.loading
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllProducts.pending, (state, action) => {
            state.loading = true
            state.error = null
            state.products = []
        })
        builder.addCase(getAllProducts.fulfilled, (state, action) => {
            state.products = action.payload?.data
            state.loading = false
            state.totalProducts = action.payload?.totalProducts
            state.error = null
        })
        builder.addCase(getAllProducts.rejected, (state, action) => {
            state.products = []
            state.loading = false
            state.error = action.error?.message
        })
    }
})

export default allProductsSlice.reducer
export const { setProducts, setAllItemsLoading } = allProductsSlice.actions