import { useEffect, useState } from "react";
import { Link ,useNavigate} from "react-router-dom";
import {useSelector,useDispatch} from 'react-redux'
import auctionServices from "../../../services/auction-services";
import "./ManageDonor.css";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import Container from "../../components/HOC/Container/Container";
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AccessDenied from "../../components/AccessDenied/AccessDenied";
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import EventRepeatSharpIcon from '@mui/icons-material/EventRepeatSharp';
import { InputAdornment, TablePagination, TableSortLabel, TextField } from "@mui/material";
import Modal from "../../components/Modal/Modal";
import ImageInput from "../../components/ImageInput/ImageInput";
import Alert from "../../components/Alert/Alert";
import checkImageBeforeUpload from '../../../utils/checkImageBeforeUpload.js';
import { BorderColorOutlined, Search } from "@mui/icons-material";
import { setPage,setSorting } from "../../../redux/userActionsReducer";
import constants, { COLORS_OBJECT } from "../../../constants/en";
import TableButton from "../../components/TableButton/TableButton";

const redColor = COLORS_OBJECT.color[0]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    borderBottom: "1px solid black"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const ManageDonor = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {donorPageNumber:page,donorSorting:sorting} = useSelector(state=>state.userAction);
  const [donors, setDonors] = useState([])
  const [access, setAccess] = useState(false)
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [limit, setLimit] = useState(10)
  const [totalCount,setTotalCount] = useState(0);
  const [portraitImageLoading, setPortraitImageLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [featuredImage,setFeaturedImage] = useState([{}]);
  const [splashImage,setSplashImage] = useState([{}]);
  const [featureIcon,setFeaturedIcon] = useState(null);
  const [splashIcon,setSplashIcon] = useState(null);
  const [featuredModal,setFeaturedModal] = useState({isOpen:false,featured:'',rowId:'',icon:''});
  const [searchText,setSearchText] = useState('');
  const [whiteborderPortraitAlert,setWhiteborderPortraitAlert] = useState(null);
  const [portraitEvent,setPortraitEvent] = useState(null);
  const [featuredLogoWhiteBorderAlert,setFeaturedLogoWhiteBorderAlert] = useState(null);
  const [featuredLogoEvent,setFeaturedLogoEvent] = useState(null);

  const verifyPermission = async () => {
    try {
      setLoading(true)
      const data = await auctionServices.checkDonorsPermissions()
      if(data.access) {
        setAccess(true)
      }
      setLoading(false)
    } catch (error) {
      if(error?.response?.status === 403 || !error?.response?.data?.access) {
        setAccess(false)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    verifyPermission()
  }, [])

  const fetchDonors = async (clear=false) => {
    setLoading2(true)
    let search = clear ? null : searchText.trim()!=='' ? searchText : null;
    const data = await auctionServices.getdonorsList(page,limit,sorting.order,sorting.orderBy,search)
    setDonors(data?.data)
    setTotalCount(data?.total_count)
    setLoading2(false)
  }

  useEffect(() => {
    if(access) {
      fetchDonors()
    }
  }, [access,page,limit,sorting])


  const handleFeatured = async (id, featured) => {
    try {
      setLoading(true)
      await auctionServices.makeDonorFeatured(id, featured)
      setLoading(false)
      fetchDonors()
    } catch (error) {
      console.log(error)
    }
  }

  const openModal = (donorId,icon,splashImage)=>{
    setFeaturedImage([
      {
        name: icon,
        type: "img",
        updated:false
      },
    ]);
    setSplashImage([
      {
        name: splashImage,
        type: "img",
        updated:false
      },
    ])
    setFeaturedModal({isOpen:true,featured:true,rowId:donorId,icon:icon})
  }
  const onDone=async()=>{
    if(!splashImage[0].name){
      setAlertMsg(constants.ADMIN_COPY_MANAGE_DONOR_LOGO);
      return
    }
    if(!featuredImage[0].name){
      setAlertMsg(constants.ADMIN_COPY_MANAGE_DONOR_FEATURED_IMAGE);
      return
    }
    setLoading(true)
    try{
      if(featuredImage[0].updated || splashImage[0].updated){
      let formData = new FormData();
      featuredImage[0].updated && formData.append("featured_icon", featureIcon)
      splashImage[0].updated && formData.append("splashimage", splashIcon)
      await auctionServices.updateDonorIcon(featuredModal.rowId,formData)
      }
      handleFeatured(featuredModal.rowId,true)
      setFeaturedModal({isOpen:false,featured:'',rowId:''})

    }catch(error){
      setLoading(false)
      setAlertMsg('something went wrong')
    }
  }

  const handleFeaturedImage = async (e,logo,confirm) => {
    if (!e.target.files[0]) return;
    setPortraitImageLoading(true);
    const image = e.target.files[0];
    if (image.size > 5000000) {
      setAlertMsg(constants.ADMIN_COPY_MANAGE_DONOR_IMAGE_FILE_SIZE);
      setPortraitImageLoading(false);
      return;
    }
    const imageUrl = URL.createObjectURL(image);
    const { width, height } = await checkImageBeforeUpload(imageUrl);
    if (width < 300 || height < 300) {
      setAlertMsg(constants.ADMIN_COPY_MANAGE_DONOR_IMAGE_SIZE);
      setPortraitImageLoading(false);
      return;
    }
    if(logo){
            
      if(!confirm){
        if(width/height < 0.98 || width/height > 1){
          setPortraitEvent(e);
          setPortraitImageLoading(false)
          setWhiteborderPortraitAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','1:1'));
          return
        }
      }else{
        setPortraitEvent(null)
      }
}else{
    if(!confirm){
        if(width/height < 0.98 || width/height > 1){
          setFeaturedLogoEvent(e);
          setPortraitImageLoading(false)
          setFeaturedLogoWhiteBorderAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','1:1'));
          return
        }
      }else{
        setFeaturedLogoEvent(null)
      }
}
    const formData = new FormData();
    formData.append("image", image);
    formData.append("width", 300);
    formData.append("height", 300);
    let alpha
    if(logo){
      alpha = 1
    }else{
      alpha = 0
    }
    const preProcessedImage = await auctionServices.imagePreProcess(formData,alpha);
    if(logo){
      setSplashImage([
        {
          name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`,
          type: "img",
          updated:true
        },
      ]);
    const uint8 = new Uint8Array(preProcessedImage.buffer.data);
    const newBlob = new Blob([uint8], { type: "image/jpeg" });
    setSplashIcon(newBlob);
    }else{
      setFeaturedImage([
        {
          name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`,
          type: "img",
          updated:true
        },
      ]);
      const uint8 = new Uint8Array(preProcessedImage.buffer.data);
      const newBlob = new Blob([uint8], { type: "image/jpeg" });
      setFeaturedIcon(newBlob);
    }
   
    setPortraitImageLoading(false);
  };

  const handleFeaturedImageDelete = ()=>{
    setFeaturedIcon(null);
    setFeaturedImage([{}]);
  }
  const handleSplashImageDelete = ()=>{
    setSplashIcon(null);
    setSplashImage([{}]);
  }
  const handleSort = (orderBy)=>{
    if(orderBy===sorting.orderBy){
      let order = sorting.order === 'ASC' ? 'DESC' : 'ASC';
      dispatch(setSorting({order,orderBy}))
    }
    else{
      dispatch(setSorting({order:'ASC',orderBy}))
    }
  }

  const handleSearch = (e)=>{
    setSearchText(e.target.value)
  }


  const handleKeyPress = (e,click)=>{
    if(e.key==='Enter' || click){
      fetchDonors();
      dispatch(setPage(1))
    }
  }

  const clearSearch = () => {
    setSearchText("")
    fetchDonors(true);
    dispatch(setPage(1))
  }

  const handleSearchClick = () => {
    fetchDonors();
    dispatch(setPage(1))
  }

  const handleEdit = (donorId) => {
    navigate(`/admin/edit-donor/${donorId}`)
}
// pagination
// const [pageNo, setPageNo] = useState(1);
//     let limit = 5;
//     let pages = Math.ceil(donors.length / limit);
//     let pagesArray = new Array(pages).fill(0);
//     const start = (pageNo - 1) * limit;
//     const end = pageNo * limit;
//     const donorsPerPage = donors.slice(start, end);
//     const left = "<"
//     const right = ">"
//   const handlepageClick = (e) => {
//       console.log((e.target.value));
//       setPageNo(parseInt(e.target.value))
//   }
  return (
    <>
      { (
        <Container title="Manage Donor" style={{paddingTop: "10px"}}>
          {
          loading ? <LoadingModal isOpen={loading} />:
         (!access ? <AccessDenied title="Manage Donors" /> : (
        <>
          <LoadingModal isOpen={loading2}/>
          {<>
            <div className="search-box-container">
             <div style={{display: "flex", alignItems: "baseline", gap: "20px"}}>
             <TextField
               id="input-with-icon-textfield"
               label="Search"
               value={searchText}
               onChange={handleSearch}
               onKeyDown={handleKeyPress}
               InputProps={{
               endAdornment: (
                  <InputAdornment position="end" >
                           <Search onClick={(e)=>handleKeyPress(e,'click')} />
                  </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    />

              <div style={{display: "flex", alignItems: "baseline"}}>
                <div className="addDonor-button">
                    <button  className="aitem-modal-btn" onClick={handleSearchClick}>Search</button>
                </div>
                <div className="addDonor-button">
                    <button className="aitem-modal-btn aitem-white-button" onClick={clearSearch}>Clear</button>
                </div>
              </div>
                
             </div>
              <div className="addDonor-button">
                <Link to="/admin/add-donor">
                  <button className="aitem-modal-btn">Add Donor</button>
                </Link>       
              </div>
            </div>
                <Paper>
                {donors.length? <TableContainer>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{width: "50%"}}>
                                  <TableSortLabel active={sorting.orderBy==='org_name'} direction={sorting.order==='ASC'?'asc':'desc'} onClick={()=>handleSort('org_name')} >Donor Name</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>Add Promo</StyledTableCell>
                                <StyledTableCell>
                                  <TableSortLabel active={sorting.orderBy==='featured'} direction={sorting.order==='ASC'?'asc':'desc'} onClick={()=>handleSort('featured')} >Make Featured</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>Edit</StyledTableCell>
                                <StyledTableCell>Add Events</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                          
                            {donors.map((row, i) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell>{`${row.org_name}`}</StyledTableCell>
                                <StyledTableCell>
                                  <ControlPointIcon style={{ cursor: "pointer" , fontWeight:"500" }} 
                                  onClick={()=>{
                                      navigate("/admin/add-donorPromo", {state: {donor: row}})
                                    }}/>
                                </StyledTableCell>
                                <StyledTableCell>
                                {row.featured == 1 ? (
                                      <ArrowCircleDownOutlinedIcon
                                      onClick={() => handleFeatured(row.id, false)}
                                      style={{ cursor: "pointer", color: "red" }}
                                    />
                                    ) : (
                                      <ArrowCircleUpOutlinedIcon
                                        onClick={() =>openModal(row.id,row.featured_icon,row.splashimage)}
                                        style={{ cursor: "pointer", color: "green" }}
                                      />
                                    )}
                                </StyledTableCell>
                                <StyledTableCell style={{color:'#5EABD6' }}>
                                <TableButton onClick={() => handleEdit(row.id)} Icon={BorderColorOutlined}/>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableButton onClick={()=>{
                                           navigate("/admin/add-donor-events", {state: {donor: row}})
                                           }}
                                           needText={false}
                                           Icon={EventRepeatSharpIcon}
                                           color={redColor}
                                           style={{border:"none"}}
                                     />
                                </StyledTableCell>
                            </StyledTableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>:<p style={{textAlign:'center',height:'300px',paddingTop:'150px'}}>No Result</p>}
                    <TablePagination
                        hidden={!donors.length}
                        rowsPerPageOptions={[10, 20,50,100]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={limit}
                        page={page - 1}
                        onPageChange={(e, newPage) => dispatch(setPage(newPage + 1))}
                        onRowsPerPageChange={(e) => {setLimit(+e.target.value);dispatch(setPage(1))}}
                    />
                </Paper>
            </>}
            {/* <div className='page-no'>
                      {(pageNo > pages) ? null : <button onClick={() => 
                      {
                          if(pageNo>1){
                              setPageNo(pageNo - 1)
                          }
                      }
                          }> {left} </button>}

                      {
                          pagesArray.map((item, i) => {
                              return (<button value={i + 1} onClick={handlepageClick}>{i + 1}</button>)
                          })
                      }
                      {(pageNo > pages ) ? null : <button onClick={() => {
                          if (pageNo !== pages) {
                              setPageNo(pageNo + 1)
                          }
                      }
                      }> {right} </button>}
                  </div> */}
          <Modal isOpen={featuredModal.isOpen} onClose={()=>setFeaturedModal({isOpen:false,featured:'',rowId:''})}
           onDone={onDone} buttonText="Make Featured" >
            <ImageInput
              label="Featured Logo"
              sidelabel="Recommended size 300*300"
              handleChange={(e)=>handleFeaturedImage(e,"splashImage",false)}
              images={splashImage[0].name? splashImage :[]}
              edit
              onDelete={handleSplashImageDelete}
              infoBubbleText={constants.ADMIN_COPY_DONOR_SPLASH_IMAGE_INFO_TEXT}
              iButton
            />
            <ImageInput
              label="Featured Icon"
              sidelabel="Recommended size 300*300"
              handleChange={(e)=>handleFeaturedImage(e,null,false)}
              images={featuredImage[0].name? featuredImage :[]}
              edit
              onDelete={handleFeaturedImageDelete}
              infoBubbleText={constants.ADMIN_COPY_DONOR_FEATURED_ICON_INFO_TEXT}
              iButton
            />
          </Modal> 
          <Alert style={{zIndex:40}} isOpen={!!alertMsg} message={alertMsg} onClose={() => setAlertMsg(null)} />  
          <Alert isOpen={!!whiteborderPortraitAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handleFeaturedImage(portraitEvent,'splashImage',true);
                setWhiteborderPortraitAlert(null)
                }} 
                onClose={()=>setWhiteborderPortraitAlert(null)} 
                message={whiteborderPortraitAlert}/>
           <Alert isOpen={!!featuredLogoWhiteBorderAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handleFeaturedImage(featuredLogoEvent,null,true);
                setFeaturedLogoWhiteBorderAlert(null)
                }} 
                onClose={()=>setFeaturedLogoWhiteBorderAlert(null)} 
                message={featuredLogoWhiteBorderAlert}/>   
        </>)
        )}
      </Container>
      )}
    </>
  );
};

export default ManageDonor;