import React, { useEffect, useRef, useState } from 'react'
import Container from '../../components/HOC/Container/Container';
import "./BiddingManagement.css"
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Input from '../../components/Input/Input';
import { useNavigate, useParams } from 'react-router-dom';
import authService from '../../../services/auth-services';
import auctionServices from '../../../services/auction-services';
import Alert from '../../components/Alert/Alert';
import AccessDenied from '../../components/AccessDenied/AccessDenied';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import constants, { COLORS_OBJECT } from '../../../constants/en';

const redColor = COLORS_OBJECT.color[0]

const BiddingManagement = () => {
  const [bidOption, setBidOption] = useState("default")
  const currentType = useRef(null)
  const [constantIncrement, setContantIncrement] = useState("")
  const {auction_id} = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [editAccess, setEditAccess] = useState(true)
  const [alertMsg, setAlertMsg] = useState(null)
  const [defaultCaseIncrement, setDefaultCaseIncrement] = useState("")
  const navigate = useNavigate()

  const [customIncrements, setCustomIncrements] = useState([{
    band_index: 1,
    lower_bound: 0,
    upper_bound: "",
    increment: ""
  }])

  const changeUpperBound = (e, band_index) => {
    const newBands = customIncrements.map((i) => {
        if(i.band_index === band_index) {
            return {
                ...i,
                upper_bound: (+e.target.value >= 0 ) ? e.target.value : ""
            }
        }
        return i
    })
    setCustomIncrements(newBands)
  }

  const changeIncrement = (e, band_index) => {
    const newBands = customIncrements.map((i) => {
        if(i.band_index === band_index) {
            return {
                ...i,
                increment: (+e.target.value >= 0 ) ? e.target.value : ""
            }
        }
        return i
    })
    setCustomIncrements(newBands)
  }

  const fetchAuctionBidDetails = async () => {
    try {
        setIsLoading(true)
        const permission = await authService.checkPermissions(15, auction_id)
        if (!permission.access) {
          setEditAccess(false)
          setIsLoading(false)
          return
        } else {
          setEditAccess(true)
        }
        const response = await auctionServices.getAuctionDetailsById(auction_id);

        currentType.current = response.data.bid_increment_type;

        if(response.data.bid_increment_type === 'default') {
            setBidOption("default")
        } else if (response.data.bid_increment_type === 'custom') {
            setBidOption("constant")
            setContantIncrement(response.data.bid_increment_val || "")
        } else if (response.data.bid_increment_type === 'custom_bands') {
            setBidOption("custom")
            const bands = await auctionServices.getCustomBandsForEvent(auction_id)
            const lastBand = bands?.data.pop()
            setDefaultCaseIncrement(lastBand.increment)
            setCustomIncrements(bands?.data)
        } else {
            setBidOption("default")
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        navigate("/admin/my-events")
      }
  }

  useEffect(() => {
    if(!auction_id) return
    fetchAuctionBidDetails()
  }, [auction_id])

  const onChangeOption = (e) => {
    setBidOption(e.target.value)
    if(!(currentType.current && currentType.current === 'custom')) {
        setContantIncrement("")
    }
    if(!(currentType.current && currentType.current === 'custom_bands')){
        setCustomIncrements([{
            band_index: 1,
            lower_bound: 0,
            upper_bound: "",
            increment: ""
        }])
        setDefaultCaseIncrement("")
    }
  }

  const addMoreBands = () => {
    const lastBand = customIncrements[customIncrements.length - 1]
    if(!lastBand.upper_bound){
        setAlertMsg(constants.ADMIN_COPY_BID_UPPER_BAND)
        return
    } else if (!lastBand.increment) {
        setAlertMsg(constants.ADMIN_COPY_BID_INCREMENT)
        return
    }
    else if (+lastBand.upper_bound <= +lastBand.lower_bound) {
        setAlertMsg(constants.ADMIN_COPY_BID_UPPER_LOWER_ERROR)
        return
    }
    const newBands = [
        ...customIncrements,
        {
            band_index: lastBand.band_index + 1,
            lower_bound: +lastBand.upper_bound + 0.01,
            upper_bound: "",
            increment: ""
        }
    ]
    setCustomIncrements(newBands)
  }

  const deleteBand = (band_index) => {
    setCustomIncrements(prev => prev.filter((i) => i.band_index !== band_index))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
        await auctionServices.createCustomBidBands(auction_id, customIncrements, defaultCaseIncrement)
        await auctionServices.updateEventBidType(auction_id, "custom_bands")
        setIsLoading(false)
        setAlertMsg(constants.ADMIN_COPY_CUSTOM_BID_SAVE)
    } catch (error) {
        setAlertMsg(error?.response?.data?.message || "Something went wrong")
        setIsLoading(false)
    }
  }

  const handleConstantSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    if(+constantIncrement <= 0) return
    try {
        await auctionServices.updateEventBidType(auction_id, "custom", +constantIncrement)
        setIsLoading(false)
        setAlertMsg(constants.ADMIN_COPY_CUSTOM_BID_SAVE)
    } catch (error) {
        setAlertMsg(error?.response?.data?.message || "Something went wrong")
        setIsLoading(false)
    }
  }

  const handleDefaultSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
        await auctionServices.updateEventBidType(auction_id, "default")
        setIsLoading(false)
        setAlertMsg(constants.ADMIN_COPY_DEFAULT_BID_SAVE)
    } catch (error) {
        setAlertMsg(error?.response?.data?.message || "Something went wrong")
        setIsLoading(false)
    }
  }

  return (
    <Container title='Manage Bid Increments' style={{position: "relative"}}>
        {!editAccess ? <AccessDenied /> : (
            isLoading ? <LoadingModal isOpen={true} /> :
            <>
                <RadioGroup
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={bidOption}
                    onChange={onChangeOption}
                >
                <div style={{width: "100%"}}>
                    <div>
                    <FormControlLabel sx={{".MuiFormControlLabel-label": {fontSize: "0.95rem"}}} value="default" control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />} label="Default" />
                    { bidOption === 'default' && 
                        (
                            <form onSubmit={handleDefaultSubmit}>
                                <div style={{width: "20%", marginTop: "5px", marginLeft: "20px", marginBottom: "15px"}} className='bid_manage_inputcover'>
                                    <button className='aitem-modal-btn' >Save and Submit</button>
                                </div>
                            </form>
                        )
                    }
                    </div>    
                    <div>
                    <FormControlLabel sx={{".MuiFormControlLabel-label": {fontSize: "0.95rem"}}} value="constant" control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />} label="Constant Increment" />
                    { bidOption === 'constant' && 
                        (
                            <form onSubmit={handleConstantSubmit}>
                                <div style={{width: "20%"}} className='bid_manage_inputcover'>
                                    <div style={{fontWeight: 600, fontSize: "1.2rem"}}>
                                        <p>$</p>
                                    </div>
                                    <Input required style={{margin: 0}} type="number" placeholder='Increment Value' value={constantIncrement} onChange={(e) => setContantIncrement(e.target.value)} />
                                    <button className='aitem-modal-btn' >Submit</button>
                                </div>
                            </form>
                        )
                    }
                    </div>     
                    <div>
                    <FormControlLabel sx={{".MuiFormControlLabel-label": {fontSize: "0.95rem"}}} value="custom" control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />} label="Custom Increment Bands" />
                    { bidOption === 'custom' && 
                        (
                            <form onSubmit={handleSubmit}>
                                <div className='bid_manage_custom_container'>
                                <div style={{fontWeight: 600, fontSize: "0.9rem"}} className='bid_manage_custom_row'>
                                    <div className='bid_manage_inputcover'>
                                        Lower Bound
                                    </div>
                                    <div style={{height: "2px", width: "7px"}}></div>
                                    <div className='bid_manage_inputcover'>
                                        Upper Bound
                                    </div>
                                    <div style={{height: "2px", width: "7px"}}></div>
                                    <div className='bid_manage_inputcover'>
                                        Increment
                                    </div>
                                </div>
                                {customIncrements.map((inc, idx) => {
                                    return (
                                        <div key={inc.band_index} className='bid_manage_custom_row'>
                                            <div className='bid_manage_inputcover'>
                                                <div style={{fontWeight: 600, fontSize: "1.2rem"}}>
                                                    <p>$</p>
                                                </div>
                                                <Input required disabled style={{margin: 0}} type="number" placeholder='Lower Value' value={idx === 0 ? 0 : inc.lower_bound} onChange={(e) => setContantIncrement(e.target.value)} />
                                            </div>
                                            <div style={{backgroundColor: "black", height: "2px", width: "7px"}}></div>
                                            <div className='bid_manage_inputcover'>
                                                <div style={{fontWeight: 600, fontSize: "1.2rem"}}>
                                                    <p>$</p>
                                                </div>
                                                <Input required style={{margin: 0}} type="number" placeholder='Upper Value' value={inc.upper_bound} onChange={(e) => changeUpperBound(e, inc.band_index)} />
                                            </div>
                                            <div style={{backgroundColor: "black", height: "2px", width: "7px"}}></div>
                                            <div className='bid_manage_inputcover'>
                                                <div style={{fontWeight: 600, fontSize: "1.2rem"}}>
                                                    <p>$</p>
                                                </div>
                                                <Input required style={{margin: 0}} type="number" placeholder='Increment Value' value={inc.increment} onChange={(e) => changeIncrement(e, inc.band_index)} />
                                            </div>
                                            {idx === customIncrements.length - 1 && customIncrements.length < 10 ? (
                                                <div style={{width: "25%", display: "flex", gap: "5px", justifyContent: "center"}}>
                                                    <button type='button' style={{fontSize: "0.8rem"}} className='aitem-modal-btn aitem-white-button' onClick={addMoreBands}>Add Band</button>
                                                    {customIncrements.length > 1 && <button type='button' style={{fontSize: "0.8rem"}} onClick={() => deleteBand(inc.band_index)} className='aitem-modal-btn'>Delete Band</button>}
                                                </div>
                                            ) : <div style={{width: "25%"}}></div>}
                                        </div>
                                    )
                                })}
                                {
                                    customIncrements[customIncrements.length - 1].upper_bound >= 0 && (
                                        <div className='bid_manage_custom_row'>
                                            <div className='bid_manage_inputcover'>
                                                <div style={{fontWeight: 600, fontSize: "1.2rem"}}>
                                                    <p>$</p>
                                                </div>
                                                <Input disabled style={{margin: 0}} type="number" placeholder='Lower Value' value={customIncrements[customIncrements.length - 1].upper_bound >= 0 ? +customIncrements[customIncrements.length - 1].upper_bound + 0.01 : ""} />
                                            </div>
                                            <div style={{backgroundColor: "black", height: "2px", width: "7px"}}></div>
                                            <div className='bid_manage_inputcover'>
                                                <div style={{fontWeight: 600, fontSize: "0.9rem"}}>
                                                    And Above
                                                </div>
                                            </div>
                                            <div style={{backgroundColor: "black", height: "2px", width: "7px"}}></div>
                                            <div className='bid_manage_inputcover'>
                                                <div style={{fontWeight: 600, fontSize: "1.2rem"}}>
                                                    <p>$</p>
                                                </div>
                                                <Input required style={{margin: 0}} type="number" placeholder='Increment Value' value={defaultCaseIncrement} onChange={(e) => setDefaultCaseIncrement(e.target.value)} />
                                            </div>
                                            <button style={{width: "25%", textAlign: "center", cursor: "pointer"}} className='aitem-modal-btn' type="submit">Done</button>
                                        </div>
                                    )
                                }
                            </div>
                            </form>
                        )
                        }
                    </div>
                </div>
                </RadioGroup>
                <Alert isOpen={!!alertMsg} message={alertMsg} onClose={() => setAlertMsg(null)} />
            </>
        )}
    </Container>
  )
}

export default BiddingManagement