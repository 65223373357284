import { Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import "./TagInput.css";
import constants from "../../../constants/en";

const TagInput = ({ tags, handleChange, label = "Label", fixedTags=[],clearTags=false, deleteTags, forCategories=false }) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [inp, setInp] = useState("");

  useEffect(() => {
    setSelectedTags(tags)
  }, [tags])

  useEffect(()=>{
    clearTags && setSelectedTags([])
  },[clearTags])

  const removeTags = (indexToRemove) => {
    const newTags = [
      ...selectedTags.filter((_, index) => index !== indexToRemove),
    ];
    setSelectedTags(newTags);
    handleChange(newTags);
  };
  const addTags = () => {
    if (inp.trim()  !== "") {
      setSelectedTags([...selectedTags,{ name:inp,tempId:tags.tempId ?? `${Math.random()}${new Date().getTime()}`, show_everywhere: 0}]);// show every where is used for creating custom categories
      handleChange([...selectedTags, {name:inp,tempId:tags.tempId ?? `${Math.random()}${new Date().getTime()}`, show_everywhere: 0}]); // show every where is used for creating custom categories
      setInp("");
    }
  };
  const handleInputChange = (e) => {
    if(e.target.value.length > 95) return alert(constants.ADMIN_COPY_MAX_CHAR_CUSTOM_HEADING_CATEGORY)
    if (e.target.value === ",") return;
    setInp(e.target.value);
  };

  return (
    <>
      <label className='input-label'>
        {label}
        <Tooltip title={<Typography fontSize="0.85rem">Enter  sub-category names here. Seperate categories with comma.</Typography>} placement="top">
            <InfoOutlinedIcon sx={{fontSize: "16px", marginLeft: "4px", color: "#495057c4"}} />
        </Tooltip>
      </label>
      <div className="taginput-input">
        {fixedTags?.map((tag, index) => (
          <li key={index} className="taginput-tag">
            <span className="taginput-tag-title">{tag?.name}</span>
            <span
              className="taginput-tag-close-icon"
              onClick={() => {
                deleteTags(tag?.id)
              }}
            >
              x
            </span>
          </li>
        ))}
        {selectedTags?.map((tag, index) => (
          <li key={index} className="taginput-tag">
            <span className="taginput-tag-title">{tag?.name}</span>
            <span
              className="taginput-tag-close-icon"
              onClick={() => removeTags(index)}
            >
              x
            </span>
          </li>
        ))}
        <input
          className="taginput-input-elem"
          type="text"
          value={inp}
          onChange={handleInputChange}
          onBlur={addTags}
          onKeyDown={(event) => (event.key === ",")  ? addTags() : null}
          placeholder={`Enter comma seperated values`}
        />
      </div>
    </>
  );
};
export default TagInput;
