import React from 'react'
import "./NotFound.css"

const NotFound = () => {
  return (
    <div className='notfound-container'>
        <div className='notfound-content'>
            <div>404</div>
            <div></div>
            <div>This page could not be found.</div>
        </div>
    </div>
  )
}

export default NotFound