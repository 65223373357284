import React from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { useLocation } from 'react-router-dom';
import "./Modal.css"

const Modal = ({children, title, onClose, onDone, isOpen,buttonText='Save & Continue',modalStyle={},contentStyle={}, buttonRequired=true, extraButtonRequired=false, extraButtonText='Go Back', onExtraButtonDone=()=>null, childrenContainerStyle={}}) => {
  
    if(!isOpen) return null
  return (
    <div className='bg-modal' style={modalStyle} >
        <div className='bg-modal-content' style={contentStyle}>
            <div className='bg-modal-title-container'>
                <div>{title}</div>
                <div onClick={onClose}><CancelIcon sx={{color: "#F16460"}} /></div>
            </div>
            <div className='bg-modal-child' style={{...childrenContainerStyle}}>{children}</div>
            <div style={{display:'flex', justifyContent:'space-between'}} className='bg-modal-submit-container'>
                 { extraButtonRequired ? <button onClick={onExtraButtonDone} type='button' className='aitem-modal-btn aitem-white-button'>{extraButtonText}</button> : null}
                 { buttonRequired && <button onClick={onDone} type='button' className='aitem-modal-btn'>{buttonText}</button>}
            </div>
        </div>
    </div>
  )
}

export default Modal