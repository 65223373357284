import React from 'react'
import {useSelector} from 'react-redux'
import MarketTerms from './Terms'

const StoreTerms = () => {
    const savedStore = useSelector(event => event.savedStore)
  return (
    <>
    <MarketTerms reduxState={savedStore.store} capitalName={'Store'} lowerName={'store'} />
    </>
  )
}

export default StoreTerms