import { useEffect } from "react"
import { fetchArchievedEvents,toggleLoading } from "../../../redux/archievedEvents"
import Container from "../../components/HOC/Container/Container"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import LoadingModal from "../../components/LoadingModal/LoadingModal"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from "@mui/material"
import TableButton from "../../components/TableButton/TableButton"
import authService from "../../../services/auth-services"
import AccessDenied from "../../components/AccessDenied/AccessDenied"
import auctionServices from "../../../services/auction-services"
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import { useLocation } from "react-router-dom"
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const ArchievedEvents = ()=>{
    const [refresh,setRefresh] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()
    const {state} =location
    const [hasPermission,setHasPermission] = useState(false)
    const { events, total_count, loading } = useSelector(state => state.archievedEvents)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        dispatch(fetchArchievedEvents({page:page,limit:limit,  event_type:state?.event_type}))
    }, [refresh,page,limit])

    useEffect(()=>{
        checkPermission()
    },[])

    const checkPermission =async () =>{
        const permission = await authService.checkLimitedPermissions(15)
        permission.access && setHasPermission(true)
    }

    const updateStatus =async (row) =>{
        dispatch(toggleLoading())

        let status = 'building'
        // console.log(row?.end_date)
        if(new Date() > new Date(row?.end_date)){
            status = 'complete'
        }else{
            status = 'building'
        }
       
        try {
            await auctionServices.updateAuctionStatus(row.id, { status })
        } catch (error) {
            
            dispatch(toggleLoading())
            return
        }
        setRefresh(!refresh)
    }
    const getTitle = (type) =>{
        if(type == 'event'){
            return 'Restore Event'
        }
        else if(type == 'store'){
            return 'Restore Store'
        }
        else if(type == 'market_place'){
            return 'Restore MarketPlace'
        }else{
            return 'Restore Event'
        }
    }
    return(
        <Container title={getTitle(state?.event_type)}>
        {(!hasPermission) && <AccessDenied/>}
        {loading ? <LoadingModal isOpen={true} />:
        <div>
            <div className='event_header_container'>
                <span className='calender_icon_container'><CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#34b5e5' }} /><p>{getTitle(state?.event_type).split(" ")[1]}</p></span>
            </div>
            <hr />
            {events?.length > 0 ? (
                <Paper>
                    <TableContainer>
                        <Table sx={{ minWidth: 400 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ width: "50%" }}>Event Title</StyledTableCell>
                                
                                    <StyledTableCell sx={{ width: "50%" }}>Manage</StyledTableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {events?.map((row, i) => (
                                    <StyledTableRow key={row.id}>
                                        <StyledTableCell>{row.title}</StyledTableCell>
                                        <StyledTableCell sx={{fontWeight: "600"}}><TableButton text="Restore" Icon={RestoreOutlinedIcon} onClick={()=>updateStatus(row)}/></StyledTableCell>
                                    </StyledTableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20]}
                        component="div"
                        count={total_count}
                        rowsPerPage={limit}
                        page={page - 1}
                        onPageChange={(e, newPage) => setPage(newPage + 1)}
                        onRowsPerPageChange={(e) => { setLimit(+e.target.value); setPage(1) }}
                    />
                </Paper>
            ) : <p>No <span>{getTitle(state?.event_type).split(" ")[1]}</span> to show</p>}
        </div>
        }
        </Container>
    )
}

export default ArchievedEvents