import React from 'react'
import Container from '../../components/HOC/Container/Container'
import { Link } from 'react-router-dom'
import classes from './TermsOfUse.module.css'

const TermsOfUse = () => {
  return (
    <Container>
      <div className={classes.t_p_main}>
      <h1 className={classes.terms_heading} >Terms Of Use</h1>
      <p className={classes.date}>Effective date: <i>December 26, 2022</i></p>
                <p className={classes.text}>
                    Welcome to Boundless Giving. Please read on to learn the rules and restrictions that govern your use of our website(s), 
                    products, services and applications (the “Services”), including, without limitation, any request to receive information about, 
                    or to purchase any of the products made available through our website(s) (each, a “Product”). 
                    Your purchase of any Products will also be governed by any other terms made available by us to you during the sales process. 
                    If you have any questions, comments, or concerns regarding these terms or the Services, please contact us at:
                </p>
                <p className={classes.text}>Email: <a href="mailto:info@boundlessgiving.com">info@boundlessgiving.com</a></p>
                <p className={classes.text}>Phone: 323-790-6585</p>
                <p className={classes.text}>Address: 22287 Mulholland Hwy #570, Calabasas, CA 91302</p>
                <p className={classes.text}>
                    These Terms of Service (the “Terms”) are a binding contract between you and <b>BOUNDLESS GIVING, INC. </b> 
                    (“Boundless Giving,” “we” and “us”). Your use of the Services in any way means that you agree to all of these Terms, 
                    and these Terms will remain in effect while you use the Services. These Terms include the provisions in this document as well as those in the <Link to='/admin/privacy'>Privacy Policy</Link>, 
                    and any other accompanying terms and conditions of sale entered into between you and us for the sale of any Products. 
                    <b>Your use of or participation in certain Services may also be subject to additional policies, 
                    rules and/or conditions (“Additional Terms”), which are incorporated herein by reference, and you understand and agree that by using or participating in any such Services, 
                    you agree to also comply with these Additional Terms.</b>
                </p>
                <p className={classes.text}>
                    <b>Please read these Terms carefully.</b> They cover important information about Services provided to you and any charges, 
                    taxes, and fees we bill you. <b>These Terms include information about future changes to these Terms, limitations of liability, 
                    a class action waiver and resolution of disputes by arbitration instead of in court. 
                    PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; 
                    IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.</b>
                </p>
                <p className={classes.text}>
                    <b>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</b> EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION AGREEMENT SECTION BELOW, 
                    YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, 
                    INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
                </p>
                <div className={classes.section}>
                    <h1>Will these terms ever change?</h1>
                    <p className={classes.text}>
                        We are constantly trying to improve our Products and Services, 
                        so these Terms may need to change along with our Products and Services. We reserve the right to change the Terms at any time, 
                        but if we do, we will place a notice on our site, send you an email, 
                        and/or notify you by some other means.
                    </p>
                    <p className={classes.text}>
                        If you don’t agree with the new Terms, you are free to reject them; unfortunately, 
                        that means you will no longer be able to use the Services. 
                        If you use the Services in any way after a change to the Terms is effective, 
                        that means you agree to all of the changes.
                    </p>
                    <p className={classes.text}>
                        Except for changes by us as described here, 
                        no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.
                    </p>
                </div>
                <div className={classes.section}>
                    <h1>What about my privacy?</h1>
                    <p className={classes.text}>
                        Boundless Giving takes the privacy of its users very seriously. 
                        For the current Boundless Giving Privacy Policy, 
                        please <Link to='/admin/privacy'>click here</Link>.
                    </p>
                    <p className={classes.text_sub}>
                        Children’s Online Privacy Protection Act
                    </p>
                    <p className={classes.text}>
                        The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental consent before they 
                        knowingly collect personally identifiable information online from children who are under 13 years of age. We do not knowingly collect 
                        or solicit personally identifiable information from children under 13 years of age; if you are a child under 13 years of age, please 
                        do not attempt to register for or otherwise use the Services or send us any personal information. If we learn we have collected 
                        personal information from a child under 13 years of age, we will delete that information as quickly as possible. If you believe 
                        that a child under 13 years of age may have provided us personal information, please contact us at <a href="mailto:info@boundlessgiving.com">info@boundlessgiving.com</a>.
                    </p>
                </div>
                <div className={classes.section}>
                    <h1>What are the basics of using Boundless Giving?</h1>
                    <p className={classes.text}>
                        You may be required to sign up for an account, select a password and user name (“Boundless Giving User ID”), and provide us with 
                        certain information or data, such as your contact information. You promise to provide us with accurate, complete, and updated 
                        registration information about yourself. You may not select as your Boundless Giving User ID a name that you do not have the 
                        right to use, or another person’s name with the intent to impersonate that person. You may not transfer your account to anyone 
                        else without our prior written permission.
                    </p>
                    <p className={classes.text}>
                        You represent and warrant that you are an individual of legal age to form a binding contract (or if not, you’ve received your 
                        parent’s or guardian’s permission to use the Services and have gotten your parent or guardian to agree to these Terms on your 
                        behalf). If you’re agreeing to these Terms on behalf of an organization or entity, you represent and warrant that you are 
                        authorized to agree to these Terms on that organization’s or entity’s behalf and bind them to these Terms (in which case, 
                        the references to “you” and “your” in these Terms, except for in this sentence, refer to that organization or entity).
                    </p>
                    <p className={classes.text}>
                        You will only use the Services and Products you obtain for your own internal, personal use, and not on behalf of or for the 
                        benefit of any third party, and only in a manner that complies with all laws that apply to you. If your use of the Services or 
                        Products is prohibited by applicable laws, then you aren’t authorized to use the Services. We can’t and won’t be responsible 
                        for your using the Services or Products in a way that breaks the law.
                    </p>
                    <p className={classes.text}>
                        You will not share your Boundless Giving User ID, account or password with anyone, and you must protect the security of your 
                        Boundless Giving User ID, account, password and any other access tools or credentials. You’re responsible for any activity 
                        associated with your Boundless Giving User ID and account.
                    </p>
                </div>
                <div className={classes.section}>
                    <h1>What about messaging?</h1>
                    <p className={classes.text}>
                        As part of the Services, you may receive communications through the Services, including messages that Boundless Giving sends 
                        you (for example, via email or SMS). When signing up for the Services, you will receive a welcome message and instructions on 
                        how to stop receiving messages. <b>By signing up for the Services and providing us with your wireless number, you confirm that 
                        you want Boundless Giving to send you information regarding your account or transactions with us, which may include Boundless 
                        Giving using automated dialing technology to text you at the wireless number you provided, and you agree to receive communications 
                        from Boundless Giving</b>, and you represent and warrant that each person you register for the Services or for whom you provide a 
                        wireless phone number has consented to receive communications from Boundless Giving. You agree to indemnify and hold Boundless 
                        Giving harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses 
                        (including attorneys’ fees) arising from or in any way related to your breach of the foregoing.
                    </p>
                </div>
                <div className={classes.section}>
                    <h1>Are there restrictions in how I can use the Services?</h1>
                    <p className={classes.text}>
                        You represent, warrant, and agree that you will not provide or contribute anything, including any Content (as that term is defined below), 
                        to the Services, or otherwise use or interact with the Services, in a manner that:
                    </p>
                    <ol>
                        <li>infringes or violates the intellectual property rights or any other rights of anyone else (including Boundless Giving);</li>
                        <li>violates any law or regulation, including, without limitation, any applicable export control laws, privacy laws or any other purpose not reasonably intended by Boundless Giving;</li>
                        <li>is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</li>
                        <li>jeopardizes the security of your Boundless Giving User ID, account or anyone else’s (such as allowing someone else to log in to the Services as you);</li>
                        <li>attempts, in any manner, to obtain the password, account, or other security information from any other user;</li>
                        <li>violates the security of any computer network, or cracks any passwords or security encryption codes;</li>
                        <li>runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services’ infrastructure);</li>
                        <li>“crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content (through use of manual or automated means);</li>
                        <li>copies or stores any significant portion of the Content; or</li>
                        <li>decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Products or Services.</li>
                    </ol>
                    <p className={classes.text}>A violation of any of the foregoing is grounds for termination of your right to use or access the Services.</p>
                </div>
                <div className={classes.section}>
                    <h1>What are my rights in the Services?</h1>
                    <p className={classes.text}>
                        The materials displayed or performed or available on or through the Services, including, but not limited to, text, 
                        graphics, data, articles, photos, images, illustrations and so forth (all of the foregoing, the “Content”) are protected 
                        by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, 
                        information, and restrictions contained in any Content you access through the Services, and you won’t use, copy, 
                        reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell, 
                        commercialize or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent 
                        of the owner of that Content or (ii) in a way that violates someone else’s (including Boundless Giving's) rights.
                    </p>
                    <p className={classes.text}>
                        Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and non-transferable 
                        license to use (i.e., to download and display locally) Content solely for purposes of using the Services. Use, reproduction, 
                        modification, distribution or storage of any Content for any purpose other than using the Services is expressly prohibited 
                        without prior written permission from us. You understand that Boundless Giving owns the Services. You won’t modify, publish, 
                        transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative 
                        works based on, or otherwise exploit any of the Services. The Services may allow you to copy or download certain Content, 
                        but please remember that even where these functionalities exist, all the restrictions in this section still apply.
                    </p>
                </div>
                <div className={classes.section}>
                    <h1>Who is responsible for what I see and do on the Services?</h1>
                    <p className={classes.text}>
                        Any information or Content publicly posted or privately transmitted through the Services is the sole responsibility of 
                        the person from whom such Content originated, and you access all such information and Content at your own risk, and 
                        we aren’t liable for any errors or omissions in that information or Content or for any damages or loss you might 
                        suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret 
                        and use the Content or what actions you may take as a result of having been exposed to the Content, and you hereby 
                        release us from all liability for you having acquired or not acquired Content through the Services. We can’t guarantee 
                        the identity of any users with whom you interact in using the Services and are not responsible for which users gain 
                        access to the Services.
                    </p>
                    <p className={classes.text}>
                        You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you 
                        have all rights necessary to do so, in the manner in which you contribute it.
                    </p>
                    <p className={classes.text}>
                        The Services may contain links or connections to third-party websites or services that are not owned or controlled by 
                        Boundless Giving. When you access third-party websites or use third-party services, you accept that there are risks 
                        in doing so, and that Boundless Giving is not responsible for such risks.
                    </p>
                    <p className={classes.text}>
                        Boundless Giving has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or 
                        practices of or opinions expressed in any third-party websites or by any third party that you interact with through 
                        the Services. In addition, Boundless Giving will not and cannot monitor, verify, censor or edit the content of any 
                        third-party site or service. We encourage you to be aware when you leave the Services and to read the terms and 
                        conditions and privacy policy of each third-party website or service that you visit or utilize. By using the Services, 
                        you release and hold us harmless from any and all liability arising from your use of any third-party website or service.
                    </p>
                    <p className={classes.text}>
                        Your interactions with organizations and/or individuals found on or through the Services, including payment and delivery 
                        of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are 
                        solely between you and such organizations and/or individuals. You should make whatever investigation you feel necessary 
                        or appropriate before proceeding with any online or offline transaction with any of these third parties. You agree that 
                        Boundless Giving shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.
                    </p>
                    <p className={classes.text}>
                        If there is a dispute between participants on this site or Services, or between users and any third party, you agree that 
                        Boundless Giving is under no obligation to become involved. In the event that you have a dispute with one or more other 
                        users, you release Boundless Giving, its directors, officers, employees, agents, and successors from claims, demands, 
                        and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out 
                        of or in any way related to such disputes and/or our Services. You shall and hereby do waive California Civil Code 
                        Section 1542 or any similar law of any jurisdiction, which says in substance: “A general release does not extend to 
                        claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of 
                        executing the release and that, if known by him or her, would have materially affected his or her settlement with 
                        the debtor or released party.”
                    </p>
                </div>
                <div className={classes.section}>
                    <h1>Will Boundless Giving ever change the Services?</h1>
                    <p className={classes.text}>
                        We’re always trying to improve our Products and Services, so they may change over time. We may suspend or discontinue any 
                        part of the Services, or we may introduce new features or impose limits on certain features or restrict access to parts 
                        or all of the Services. We’ll try to give you notice when we make a material change to the Services that would adversely affect 
                        you, but this isn’t always practical. We reserve the right to remove any Content from the Services at any time, for any 
                        reason (including, but not limited to, if someone alleges you contributed that Content in violation of these Terms), in 
                        our sole discretion, and without notice.
                    </p>
                </div>
                <div className={classes.section}>
                    <h1>Do the Services cost anything?</h1>
                    <p className={classes.text}>
                        Products are offered at the prices set forth on the Services, and you may choose to purchase “Buy Now” Products or bid on 
                        auction items through the Boundless Giving  website. Boundless Giving may limit or cancel quantities of Products purchased, 
                        and it reserves the right to refuse any order. In the event Boundless Giving needs to make a change to an order, it will 
                        attempt to notify you by contacting the email address, billing address, and/or phone number provided at the time the order 
                        was made. The prices displayed are quoted in U.S. currency and are valid only in the United States. Prices are subject to 
                        change at any time. Sales tax will be determined by the shipping address of the order and will automatically be added to 
                        the order. Boundless Giving is required by law to apply sales tax to orders to certain states. Purchased Products will 
                        be shipped to the shipping address of the order. Information about our shipping rates and return policy can all be found 
                        on our <Link to='/admin/faq'>Frequently Asked Questions page</Link>.  Any payment terms presented to you in the process of 
                        purchasing Products are deemed part of these Terms.
                    </p>
                    <p className={classes.text}>
                        Note that if you elect to receive text messages through the Services, data and message rates may apply. Any and all such 
                        charges, fees or costs are your sole responsibility. You should consult with your wireless carrier to determine what rates, 
                        charges, fees or costs may apply to your use of the Services.
                    </p>
                    <ol>
                        <li>
                            <u>Billing.</u> We use a third-party payment processor (the “Payment Processor”) to bill you through a payment account linked 
                            to your account on the Services (your “Billing Account”). The processing of payments will be subject to the terms, 
                            conditions and privacy policies of the Payment Processor in addition to these Terms. Currently, we use Stripe, Inc. 
                            as our Payment Processor. You can access Stripe’s Terms of Service at Link Account Terms  and their Privacy Policy 
                            at Privacy Policy . We are not responsible for any error by, or other acts or omissions of, the Payment Processor. 
                            By choosing to purchase Products, you agree to pay us, through the Payment Processor, all charges at the prices then 
                            in effect for any such Products in accordance with the applicable payment terms, and you authorize us, through the 
                            Payment Processor, to charge your chosen payment provider (your “Payment Method”). You agree to make payment using 
                            that selected Payment Method. We reserve the right to correct any errors or mistakes that the Payment Processor 
                            makes even if it has already requested or received payment.
                        </li>
                        <li>
                            <u>Payment Method.</u> The terms of your payment will be based on your Payment Method and may be determined by agreements 
                            between you and the financial institution, credit card issuer or other provider of your chosen Payment Method. 
                            If we, through the Payment Processor, do not receive payment from you, you agree to pay all amounts due on your Billing Account upon demand.
                        </li>
                        <li>
                            <u>Current Information Required.</u> YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT. 
                            YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A 
                            CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US 
                            OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A 
                            POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD.
                        </li>
                        <li>
                            <u>Change in Amount Authorized.</u> If the amount to be charged to your Billing Account varies from the amount you 
                            preauthorized (other than due to the imposition or change in the amount of state sales taxes), you have the right to 
                            receive, and we shall provide, notice of the amount to be charged and the date of the charge before the scheduled 
                            date of the transaction. Any agreement you have with your payment provider will govern your use of your Payment Method. 
                            You agree that we may accumulate charges incurred and submit them as one or more aggregate charges.
                        </li>
                    </ol>
                </div>
                <div className={classes.section}>
                    <h1>What if I want to stop using the Services?</h1>
                    <p className={classes.text}>
                        You’re free to do that at any time by contacting us at info@boundlessgiving.com; please refer to our <Link to='/admin/privacy'>Privacy Policy</Link>, 
                        as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our Services.
                    </p>
                    <p className={classes.text}>
                        Boundless Giving is also free to terminate (or suspend access to) your use of the Services or your account for any reason in our discretion, 
                        including your breach of these Terms. Boundless Giving has the sole right to decide whether you are in violation of any of the restrictions 
                        set forth in these Terms.
                    </p>
                    <p className={classes.text}>
                        Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account.
                    </p>
                    <p className={classes.text}>
                        If you have deleted your account by mistake, contact us immediately at <a href="mailto:info@boundlessgiving.com">info@boundlessgiving.com</a> – we will try to 
                        help, but unfortunately, we can’t promise that we can recover or restore anything.
                    </p>
                    <p className={classes.text}>
                        Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following will survive 
                        termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, 
                        and terms regarding disputes between us, including without limitation the arbitration agreement.
                    </p>
                </div>
                <div className={classes.section}>
                    <h1>What else do I need to know?</h1>
                    <p className={classes.text}>
                        <u><i>Warranty Disclaimer.</i></u> Boundless Giving and its licensors, suppliers, partners, parent, subsidiaries or affiliated entities, and each of their respective officers, 
                        directors, members, employees, consultants, contract employees, representatives and agents, and each of their respective successors and assigns (Boundless Giving 
                        and all such parties together, the “Boundless Giving Parties”) make no representations or warranties concerning the Services, including without limitation regarding 
                        any Content contained in or accessed through the Services or any Products, and the Boundless Giving Parties will not be responsible or liable for the accuracy, 
                        copyright compliance, legality, or decency of material contained in or accessed through the Services or any claims, actions, suits procedures, costs, expenses, 
                        damages or liabilities arising out of use of, or in any way related to your participation in, the Services. The Boundless Giving Parties make no representations or 
                        warranties regarding suggestions or recommendations of services or products offered or purchased through or in connection with the Services including, without 
                        limitation, any Products. Products and services purchased or offered (whether or not following such recommendations and suggestions) through the Services are provided 
                        “AS-IS” and without any warranty of any kind from the Boundless Giving Parties or others (unless, with respect to such others only, provided expressly and 
                        unambiguously in writing by a designated third party for a specific product). THE SERVICES AND CONTENT ARE PROVIDED BY BOUNDLESS GIVING (AND ITS LICENSORS AND 
                        SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, 
                        FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW 
                        LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                    </p>
                    <p className={classes.text}>
                        <u><i>Limitation of Liability.</i></u> TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, 
                        CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE BOUNDLESS GIVING PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, 
                        PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY 
                        OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF 
                        (I) ONE-HUNDRED ($100) DOLLARS OR (II) THE AMOUNTS PAID AND/OR PAYABLE BY YOU TO BOUNDLESS GIVING IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD 
                        PRECEDING THIS APPLICABLE CLAIM OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL 
                        OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
                    </p>
                    <p className={classes.text}>
                        <u><i>Indemnity.</i></u> You agree to indemnify and hold the Boundless Giving Parties harmless from and against any and all claims, liabilities, damages (actual and consequential), 
                        losses and expenses (including attorneys’ fees) arising from or in any way related to any claims relating to (a) your use of the Services (including any actions taken 
                        by a third party using your account) and any Products, and (b) your violation of these Terms.
                    </p>
                    <p className={classes.text}>
                        <u><i>Assignment.</i></u> You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or 
                        otherwise) without Boundless Giving's prior written consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.
                    </p>
                    <p className={classes.text}>
                        <u><i>Choice of Law.</i></u> These Terms are governed by and will be construed under the Federal Arbitration Act, applicable federal law, and the laws of the State of California, 
                        without regard to the conflicts of laws provisions thereof.
                    </p>
                    <p className={classes.text}>
                        <u><i>Arbitration Agreement.</i></u> Please read the following ARBITRATION AGREEMENT carefully because it requires you to arbitrate certain disputes and claims with Boundless 
                        Giving and limits the manner in which you can seek relief from Boundless Giving. Both you and Boundless Giving acknowledge and agree that for the purposes of 
                        any dispute arising out of or relating to the subject matter of these Terms, Boundless Giving's officers, directors, employees and independent contractors (“Personnel”) 
                        are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Personnel will have the right (and will be deemed to have accepted the 
                        right) to enforce these Terms against you as the third-party beneficiary hereof.
                    </p>
                    <ol>
                        <li>
                            Arbitration Rules; Applicability of Arbitration Agreement. The parties shall use their best efforts to settle any dispute, claim, question, or disagreement arising 
                            out of or relating to the subject matter of these Terms directly through good-faith negotiations, which shall be a precondition to either party initiating arbitration. 
                            If such negotiations do not resolve the dispute, it shall be finally settled by binding arbitration in Los Angeles County, California. The arbitration will proceed 
                            in the English language, in accordance with the JAMS Streamlined Arbitration Rules and Procedures (the “Rules”) then in effect, by one commercial arbitrator with 
                            substantial experience in resolving intellectual property and commercial contract disputes. The arbitrator shall be selected from the appropriate list of JAMS 
                            arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction.
                        </li>
                        <li>
                            Costs of Arbitration. The Rules will govern payment of all arbitration fees. Boundless Giving will pay all arbitration fees for claims less than seventy-five thousand 
                            ($75,000) dollars. Boundless Giving will not seek its attorneys’ fees and costs in arbitration unless the arbitrator determines that your claim is frivolous.
                        </li>
                        <li>
                            Small Claims Court; Infringement. Either you or Boundless Giving may assert claims, if they qualify, in small claims court in Los Angeles County, California or any 
                            United States county where you live or work. Furthermore, notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue 
                            injunctive or other equitable relief at any time, from any court of competent jurisdiction, to prevent the actual or threatened infringement, misappropriation or 
                            violation of a party's copyrights, trademarks, trade secrets, patents or other intellectual property rights.
                        </li>
                        <li>
                            Waiver of Jury Trial. YOU AND BOUNDLESS GIVING WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and 
                            Boundless Giving are instead choosing to have claims and disputes resolved by arbitration. Arbitration procedures are typically more limited, more efficient, and 
                            less costly than rules applicable in court and are subject to very limited review by a court. In any litigation between you and Boundless Giving over whether to 
                            vacate or enforce an arbitration award, YOU AND BOUNDLESS GIVING WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge.
                        </li>
                        <li>
                            Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL 
                            BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER 
                            OR USER. If however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor Boundless Giving is entitled to arbitration; 
                            instead all claims and disputes will be resolved in a court as set forth in (g) below.
                        </li>
                        <li>
                            Opt-out. You have the right to opt out of the provisions of this Section by sending written notice of your decision to opt out to the following address: 22287 
                            Mulholland Hwy #570, Calabasas, CA 91302 postmarked within thirty (30) days of first accepting these Terms. You must include (i) your name and residence address, 
                            (ii) the email address and/or telephone number associated with your account, and (iii) a clear statement that you want to opt out of these Terms’ arbitration agreement.
                        </li>
                        <li>
                            Exclusive Venue. If you send the opt-out notice in (f), and/or in any circumstances where the foregoing arbitration agreement permits either you or Boundless Giving 
                            to litigate any dispute arising out of or relating to the subject matter of these Terms in court, then the foregoing arbitration agreement will not apply to either 
                            party, and both you and Boundless Giving agree that any judicial proceeding (other than small claims actions) will be brought in the state or federal courts located 
                            in, respectively, Los Angeles County, California, or the federal district in which that county falls.
                        </li>
                        <li>
                            Severability. If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to be unenforceable, then all of 
                            the preceding language in this Arbitration Agreement section will be null and void. This arbitration agreement will survive the termination of your relationship with Boundless Giving.
                        </li>
                    </ol>
                    <p className={classes.text}>
                        <u><i>Miscellaneous.</i></u> You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other governmental assessments associated with your activity 
                        in connection with the Services, provided that the Boundless Giving may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit. 
                        The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. If any provision of these Terms 
                        are found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in 
                        full force and effect and enforceable. You and Boundless Giving agree that these Terms are the complete and exclusive statement of the mutual understanding between you 
                        and Boundless Giving, and that these Terms supersede and cancel all previous written and oral agreements, communications and other understandings relating to the subject 
                        matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of Boundless Giving, and you do not have any 
                        authority of any kind to bind Boundless Giving in any respect whatsoever.
                    </p>
                    <p className={classes.text}>
                        Except as expressly set forth in the sections above regarding the arbitration agreement, you and Boundless Giving agree there are no third-party beneficiaries intended under these Terms.
                    </p>
                </div>
                <div className={classes.section}>
                    <hr />
                    <p className={classes.text}>A few important notes as you review this document:</p>
                    <p className={classes.text}>
                        Reviewing this document: Please review this document to make sure it accurately reflects your business model and practices, both current and planned. If anything seems 
                        incorrect, please let us know. As you review, you’ll see several yellow highlights; these should be replaced with links or relevant information.
                    </p>
                    <p className={classes.text}>
                        Posting this policy: This policy should be clearly accessible from your website and mobile app (if applicable). Best practice is to include a link to these terms and 
                        your privacy policy in the footer on every page of your website.
                    </p>
                    <p className={classes.text}>
                        Acceptance by users: In addition to publicly posting this policy, it’s very important to be able to clearly demonstrate that each and every user of your services accepted 
                        these terms. The first question in almost every class action suit brought by users against a company is whether users were properly notified of, and accepted, the 
                        company’s Terms of Service. To maximize enforceability of these terms, there are two important steps: affirmative acceptance and record keeping:
                    </p>
                    <p className={classes.text}>
                        Affirmative acceptance. In addition to posting these terms, you should ask your users to affirmatively accept these terms in some manner. For example, a user could be 
                        required to check a box next to a statement reading, “I have read, understand, and agree to be bound by the Terms of Service and Privacy Policy” (with the terms “Terms of 
                        Use” and “Privacy Policy” linked to those documents) before they can access your site or use your app.
                    </p>
                    <p className={classes.text}>
                        Record keeping. You should keep careful records of 1) when and how each user accepted these terms, including  a date/time stamp and record of method (web vs. mobile etc.)
                        , and 2) of the signup process and terms in place at any given date and time.
                    </p>
                    <p className={classes.text}>
                        Notifying users of updates: When you post these documents to your site (and mobile app if applicable, and any time you modify them in the future, you should notify your 
                        users that the terms have changed. To maximize enforceability for significant changes, consider: (a) giving users clear advance notice (of at least several days, preferably 
                        more) by email, thus providing users a meaningful chance to read and consider the new terms prior to their becoming effective, and (b) forcing log out of all users, and 
                        requiring them to accept the new terms in the same manner in which they accepted the original version (i.e., requiring an affirmative step before logging in again).  
                        When users accept the new terms, we encourage clients to provide users with a bullet summary of the major changes to the terms above the affirmative consent button, 
                        so that it is clear that users understand that they are agreeing to new terms.
                    </p>
                    <p className={classes.text}>
                        @Boundless: you may want to consider a separate Terms of Sale to cover Product-related policies such as warranties, returns, shipping, etc.  It can be useful to include 
                        these provisions in a separate document that can be updated independently of (and potentially more frequently than) the Terms of Service.  If taking this approach, consider 
                        whether certain of the Product-related terms found below should be moved to that separate document.
                    </p>
                    <p className={classes.text}>
                        @Boundless: I understand that at this stage, Boundless is managing all the auctions. If users are able to auction products for charity themselves (e.g., eBay but for charity) 
                        then we need additional marketplace and Copyright Act language.
                    </p>
                    <p className={classes.text}>
                        @Boundless: Similar to the previous comment about pricing, we recommend that you set out shipping options/rates, return and exchange policies, and other purchasing details 
                        on a separate page. If you would like to include a limited warranty, we can discuss whether it’s best to include that language in these Terms or on your FAQ page.
                    </p>
                </div>
      </div>
    </Container>
  )
}

export default TermsOfUse