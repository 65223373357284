import constants from "../constants/en";
import auctionServices from "../services/auction-services";
import checkImageBeforeUpload from "./checkImageBeforeUpload";

export const handleImageChange = async (setLoading = () => null, event = {}, neededWidth, neededHeight, setAlertMsg = () => null, widthByHeight = [], exactRatio = []) => {
    try {
        if (!event.target.files[0]) return {imgError: true};
        setLoading(true)
        const image = event.target.files[0]
        if (image.size > 5000000) {
            setAlertMsg(constants.ADMIN_COPY_MANAGE_DONOR_IMAGE_FILE_SIZE);
            setLoading(false);
            return {
                imgError: true
            }
          }
        const imageUrl = URL.createObjectURL(image)
        const { width, height } = await checkImageBeforeUpload(imageUrl)
        if (width < neededWidth || height < neededHeight) {
            setAlertMsg(constants.ADMIN_COPY_IMG_SIZE.replace('()',`${neededWidth}x${neededHeight}PX`))
            setLoading(false)
            return {
                imgError: true
            }
        }
        if (widthByHeight?.length) {
            if (width / height < widthByHeight[0] || width / height > widthByHeight[1]) {
                setLoading(false)
                setAlertMsg(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()', `${exactRatio[0]} :${exactRatio[1]}`));
                return {
                    imgError: true
                }
            }
        }
        const formData = new FormData()
        formData.append("image", image)
        formData.append("width", neededWidth)
        formData.append("height", neededHeight)
        const preProcessedImage = await auctionServices.imagePreProcess(formData)
        const uint8 = new Uint8Array(preProcessedImage.buffer.data)
        const newBlob = new Blob([uint8], { type: "image/jpeg" })
        setLoading(false)
        return {
            preProcessedImage: preProcessedImage || '',
            newBlob: newBlob || '',
            imgError: false
        }
    } catch (error) {
        setLoading(false)
        return {
            imgError: true
        }
    }
}