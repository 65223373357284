import { CalendarMonthOutlined } from '@mui/icons-material';
import Container from '../../components/HOC/Container/Container';
import { useSelector, useDispatch } from 'react-redux'
import { chooseEvent } from '../../../redux/orderReducer';
import DropDown from '../../components/DropDown/DropDown';
import { fetchEvents } from '../../../redux/eventReducer';
import { useContext, useState, useEffect, useRef } from 'react';
import AuthContext from '../../../store/auth-context';
import { getEventType } from '../../../utils/util-functions';
import classes from './ItemsQRCode.module.css'
import constants from '../../../constants/en';
import CheckBoxComponent from '../../components/CheckBoxComponent/CheckBoxComponent';
import { getItemsByEventId, setItemsLimit, setItemsPage, setItemsLoading } from '../../../redux/itemsByEventReducer';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { setQRItems, clearQRItemState, SetQRCodeArrayToPrint, setSelectAll, clearSelectAll, clearSelectedItem } from '../../../redux/qrCodeItemsReducer';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
        chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
};

const ItemsQRCode = () => {
    const { events, loading } = useSelector(state => state.userEvents)
    const { selectedEvent } = useSelector(state => state.orderDetails)
    const { items, page, limit, totalPage, isLoading, totalItems } = useSelector(state => state.itemsByEventId)
    const { QRSelectedItemsSKU, QRSelectedItems, selectAll } = useSelector(state => state.qrCodeItems)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useContext(AuthContext)
    const [options, setOptions] = useState([{ label: '', value: '', type: '' }])
    const [removingSku, setRemovingSku] = useState([])// this state is to check if all items in the current page are removed from the redux. This will be cleared when page changes
    const isInitialRender = useRef(true);

    const handlePage = (e, newPage = 1) => {
        dispatch(setItemsPage({ page: newPage }))
        setRemovingSku([])
    }

    const handleLimit = (newLimit = 20) => {
        dispatch(setItemsLimit({ limit: newLimit }))
        dispatch(setItemsPage({ page: 1 }))
        dispatch(clearQRItemState())
        // setSelectAll({})
        dispatch(clearSelectAll())
        setRemovingSku([])
    }

    const handleSelectAllState = (checked) => {
        // setSelectAll(prevState => ({
        //     ...prevState,
        //     [page]: checked
        //   }));
        dispatch(setSelectAll({ page: page, value: checked }))
    }

    useEffect(() => {
        const data = events?.map((event) => ({ label: `${event.title} (${getEventType(event)})`, value: event.id, type: getEventType(event) }))
        setOptions(data)
    }, [events])

    useEffect(() => {
        dispatch(fetchEvents({ user_id: user.id, page: null, limit: null, needMarketAndStore: true }))
    }, [])


    useEffect(() => {
        if (!selectedEvent.value) return;
        dispatch(getItemsByEventId({ eventId: selectedEvent.value, limit: limit, page: page }))
    }, [selectedEvent, limit, page])

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }
        dispatch(SetQRCodeArrayToPrint({ itemsToPrint: [] }))
    }, [selectedEvent])

    useEffect(() => {
        if (removingSku?.length === items?.length) {
            handleSelectAllState(false)
        }
    }, [removingSku])

    useEffect(() => {
        if(!QRSelectedItemsSKU?.length) return;
        //for checking the select all if manually select all items in a page.
        const allItemSelectedCheck = items?.filter((obj) => QRSelectedItemsSKU?.includes(obj?.sku))
        if (allItemSelectedCheck?.length) {
            if (allItemSelectedCheck?.length === items?.length) {
                handleSelectAllState(true)
            }
        }
    }, [QRSelectedItemsSKU])

    const handleEventChange = (e) => {
        dispatch(chooseEvent({ label: e?.label, value: e?.value, type: e?.type }))
        handleLimit()
    }

    const handleSelectItemChange = (e, item) => {
        if (!e.target.checked) {
            setRemovingSku([...removingSku, item?.sku])
        }
        dispatch(setQRItems({ item: item }))
    }

    const handleSelectAll = (event) => {
        const checked = event.target?.checked
        if(checked) {
            for (let obj of items) {
                dispatch(setQRItems({ item: obj, fromSelectAll:true }))
            }
        }else {
            for (let obj of items) {
                dispatch(clearSelectedItem({ item: obj}))
            }
        }
        
        handleSelectAllState(checked)
    }

    const checkPageSelected = () => {
        if (selectAll[page] == true) {
            return true
        }
        else return false
    }

    const handleGenerateQRClicked = () => {
        dispatch(setItemsLoading({ loading: true }))
        const chunkedData = chunkArray(QRSelectedItems, 4);
        dispatch(SetQRCodeArrayToPrint({ itemsToPrint: chunkedData }))
        dispatch(setItemsLoading({ loading: false }))
        navigate('/admin/item-qr-code/generate')
    }

    return (
        <Container title='Item QR Codes' style={{ position: "relative" }} >
            <div className='event_header_container'>
                <span className='calender_icon_container'><CalendarMonthOutlined sx={{ fontSize: '20px', color: '#34b5e5' }} /><p>Generate QR Codes</p></span>
            </div>
            <hr />

            <div className={classes.qrMain}>
                <div className={classes.eventsDropDownContainer}>
                    {events?.length > 0 ? <DropDown value={selectedEvent} isSearchable={true} onChange={(e) => handleEventChange(e)} options={options} /> : 'No events found'}
                </div>

                {
                    items?.length > 0 ?
                        <div className={classes.actionsContainer} >
                            <button onClick={handleGenerateQRClicked} className="aitem-modal-btn">Generate QR Code</button>
                            <CheckBoxComponent label={constants.ADMIN_COPY_QRCODE_SELECT_ALL_LABEL} onChange={handleSelectAll} checked={checkPageSelected()} value={selectAll} />
                        </div>
                        : null
                }

                {
                    items?.length ?
                        <Paper>
                            <TableContainer>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Product Name</StyledTableCell>
                                            <StyledTableCell>Product SKU</StyledTableCell>
                                            <StyledTableCell>Select</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items?.map((row, i) => (
                                            <StyledTableRow key={row.id}>
                                                <StyledTableCell>{row.name}</StyledTableCell>
                                                <StyledTableCell>{row.sku}</StyledTableCell>
                                                <StyledTableCell>
                                                    <div>
                                                        <CheckBoxComponent checked={QRSelectedItemsSKU?.includes(row.sku)} onChange={(e) => handleSelectItemChange(e, row)} value={row.id} />
                                                    </div>
                                                </StyledTableCell>
                                            </StyledTableRow>

                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                component="div"
                                count={totalItems}
                                rowsPerPage={limit}
                                page={page - 1}
                                onPageChange={(e, newPage) => handlePage(e, newPage + 1)}
                                onRowsPerPageChange={(e) => handleLimit(+e.target.value)}
                            />
                        </Paper>
                        : <p className={classes.noDataText}>{!selectedEvent?.value ? 'Please Select An Event/Store/Marketplace' : 'No items to Show'}</p>
                }

            </div>
            <LoadingModal isOpen={isLoading || loading} />
        </Container>
    )
}

export default ItemsQRCode;