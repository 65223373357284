import React, { useContext } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthContext from "./store/auth-context";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Event from "./pages/main/Event/Event";
import SignIn from "./pages/main/SignIn/SignIn";
import SignUp from "./pages/main/SignUp/SignUp";
import Terms from "./pages/main/EventTerms/Terms";
import NotFound from "./pages/main/NotFound/NotFound";
import MyEvents from "./pages/main/MyEvents/MyEvents.jsx";
import CauseAddEdit from "./pages/main/CauseAddEdit/CauseAddEdit";
import ManageDonor from "./pages/main/ManageDonor/ManageDonor";
import AddDonor from "./pages/main/AddDonor/AddDonor";
import AddDonorPromo from "./pages/main/AddDonorPromo/AddDonorPromo";
import Items from "./pages/main/Items/Items";
import ItemList from "./pages/main/Items/ItemList/ItemList";
import VerifyEmail from "./pages/main/VerifyEmail/VerifyEmail";
import EditTerms from "./pages/main/EventTerms/EditTerms";
import MyCauses from "./pages/main/MyCauses/MyCauses";
import PreviewEvent from "./pages/main/PreviewEvent/PreviewEvent";
import Order from "./pages/main/order/order";
import EditOrder from "./pages/main/editOrder/editOrder";
import ForgotPassword from "./pages/main/ForgetPassword/ForgetPassword";
import ChangeEmail from "./pages/main/changeEmail/ChangeEmail";
import VerifyChangeEmail from "./pages/main/verifyChangeEmail/verifyChangeEmail";
import AdvatixOnboarding from "./pages/main/AdvatixOnboarding/AdvatixOnboarding";
import Asn from "./pages/main/Asn/asn";
import UserManagement from "./pages/main/UserManagement/UserManagement";
import ItemsOrderManagement from "./pages/main/ItemsOrderManagement/ItemsOrderManagement";
import EventDashboard from "./pages/main/EventDashboard/EventDashboard";
import AppStatus from "./pages/main/AppStatus";
import AddEvents from "./pages/main/AddEventsToDonor/AddEvents";
import CreateBlog from "./pages/main/Blog/CreateBlog";
import BiddingManagement from "./pages/main/BiddingManagement/BiddingManagement";
import OrderAndReports from "./pages/main/OrdersAndReport/OrderAndReports";
import EditDonor from "./pages/main/EditDonor/EditDonor";
import BlogList from "./pages/main/Blog/BlogList";
import MyMarketPlace from "./pages/main/MarketPlaceAndStore/MyMarketPlace/MyMarketPlace";
import CreateMarketPlace from "./pages/main/MarketPlaceAndStore/CreateForm/CreateMarketPlace";
import MarketPlaceTerms from "./pages/main/MarketPlaceAndStore/Terms/MarketPlaceTerms";
import MyStore from "./pages/main/MarketPlaceAndStore/MyMarketPlace/MyStore";
import CreateStore from "./pages/main/MarketPlaceAndStore/CreateForm/CreateStore";
import StoreTerms from "./pages/main/MarketPlaceAndStore/Terms/StoreTerms";
import TermsOfUse from "./pages/main/TermsOfUse/TermsOfUse";
import Privacy from "./pages/main/Privacy/Privacy";
import Faq from "./pages/main/Faq/Faq";
import StaticImageUpload from "./pages/main/uploadStaticImage/uploadStaticImage";
import ArchievedEvents from "./pages/main/MyEvents/ArchievedEvents";
import RestoreCause from "./pages/main/MyCauses/RestoreCause";
import OrderAndReportsMarketStore from "./pages/main/OrdersAndReport/OrderAndReportMarketStore";
import CategoryPirorityOrder from "./pages/main/CategoryPirorityOrder/CategoryPirorityOrder.jsx";
import ItemsQRCode from "./pages/main/ItemsQRCode/ItemsQRCode.jsx";
import GenerateQRCode from "./pages/main/ItemsQRCode/GenerateQRCode/GenerateQRCode.jsx";
import AllProductsPriority from "./pages/main/AllProductsPriority/AllProductsPriority.jsx";


export default function Router() {
    const ctx = useContext(AuthContext);
    const loader = (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={ctx.isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
    const routes = useRoutes([
        {
            path: "/",
            element: <Navigate to="admin" />
        },
        {
            path: "admin/",
            children: [
                { path: "", element: <Navigate  to="/admin/cause" /> },
                { path: "sign-in", element: ctx.isLoggedIn ? <Navigate to="/admin" /> : <SignIn />},
                { path: "sign-up", element: ctx.isLoggedIn ? <Navigate to="/admin" /> : <SignUp />},
                { path: "auth/verify-email", element: <VerifyEmail />},
                { path: "reset-pwd" , element: <ForgotPassword />},
                { path: "change-email" , element: <ChangeEmail />},
                { path: "auth/verify-change-email", element: <VerifyChangeEmail />},
                { path: "tos", element: <TermsOfUse />},
                { path: "privacy", element: <Privacy />},
                { path: "faq", element: <Faq/>},
                {path: "category-priority-order/:auction_id", element:<CategoryPirorityOrder/> },
                { path: "blog",
                    children:[
                         { path: "", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <BlogList/>},
                         { path: "edit-blog/:pretty_url", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <CreateBlog/>},
                         { path: "create-blog", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <CreateBlog/> }
                        ] },

                {path: "market-place",
                    children:[
                        {path: "", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <MyMarketPlace/>},
                        {path: "add", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <CreateMarketPlace/>},
                        {path: "edit/:auction_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <CreateMarketPlace/>},
                        {path: "terms", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <MarketPlaceTerms/>},
                        {path: "terms/edit/:auction_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <EditTerms/>},
                        {path: "items", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <ItemList/>},
                        {path: "items/add", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <Items />},
                        {path: "items/edit/:auction_id/:item_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <Items />},
                        { path: "management/bids/:auction_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <BiddingManagement /> },
                        { path: "items/order", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <ItemsOrderManagement /> },
                    ]        
                },
                { path: "market-place-preview/:auction_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <PreviewEvent />},

                {path: "store",
                children:[
                    {path: "", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <MyStore/>},
                    {path: "add", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <CreateStore/>},
                    {path: "edit/:auction_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <CreateStore/>},
                    {path: "terms", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <StoreTerms/>},
                    {path: "terms/edit/:auction_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <EditTerms/>},
                    {path: "items", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <ItemList/>},
                    {path: "items/add", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <Items />},
                    {path: "items/edit/:auction_id/:item_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <Items />},
                    { path: "management/bids/:auction_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <BiddingManagement /> },
                    { path: "items/order", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <ItemsOrderManagement /> },
                ]        
            },
            { path: "store-preview/:auction_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <PreviewEvent />},

                {path: "user-management", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <UserManagement/> },

                { path: "event", 
                    children: [
                        { path: "", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <Event />},
                        { path: "my-events", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <MyEvents />},
                        { path: "edit/:auction_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <Event />},
                        { path: "management/bids/:auction_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <BiddingManagement /> }
                    ]},
                { path:"terms",
                    children: [
                        { path: '', element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <Terms />},
                        { path: 'edit/:auction_id', element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <EditTerms />}
                    ]},
                { path:"manage-donor", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <ManageDonor />},
                { path:"add-donor", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <AddDonor />},
                { path:"edit-donor/:donor_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <EditDonor />},
                { path:"add-donorPromo", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <AddDonorPromo />},
                { path: "my-events", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <MyEvents />},
                { path: "restore-events", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <ArchievedEvents/>},
                { path: "restore-market_place", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <ArchievedEvents/>},
                { path: "restore-store", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <ArchievedEvents/>},
                { path: "cause", 
                    children: [
                        { path: "", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <MyCauses />},
                        { path: "add", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <CauseAddEdit />},
                        { path: "edit/:cause_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <CauseAddEdit />},
                        { path: "restore-cause", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <RestoreCause />},

                    ]
                },
                { 
                    path: "items",
                    children: [
                        { path: "", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <ItemList /> },
                        { path: "add", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <Items /> },
                        { path: "order", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <ItemsOrderManagement /> },
                        { path: "edit/:auction_id/:item_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <Items />  }
                    ]
                },
                { path: "event-preview/:auction_id", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <PreviewEvent />},
                // { path: "event-dashboard", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <EventDashboard />},
                { path: "order", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <Order />},
                { path: "orders-and-reports/event", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <OrderAndReports />},
                { path: "orders-and-reports/store", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <OrderAndReportsMarketStore />},
                { path: "orders-and-reports/market-place", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <OrderAndReportsMarketStore />},
                { path: "advatix-onboarding", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <AdvatixOnboarding />},
                { path: "edit-order/:xrefId", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <EditOrder />},
                { path: "asn", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <Asn />},
                { path: "add-donor-events", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <AddEvents />},
                {path: "item-qr-code", 
                    children: [
                        {path: "", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <ItemsQRCode/> },
                        {path: "generate", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <GenerateQRCode/> },
                    ]
                },
                { path: "item-display-order", element: <AllProductsPriority />},
                { path: "deployment-status", element: <AppStatus />},
                { path: "upload-image", element: !ctx.isLoggedIn ? <Navigate to="/admin/sign-in" /> : <StaticImageUpload />},
                { path: "not-found", element: <NotFound /> },
            ],
        },
        { path: "*", element: <Navigate to="admin/not-found" replace /> },
    ]);
    return ctx.isLoading ? loader : routes;
}