import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from '../../components/HOC/Container/Container';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePagination } from '@mui/material';
import AuthContext from '../../../store/auth-context';
import { useEffect } from 'react';
import { fetchEvents, toggleLoading } from '../../../redux/eventReducer';
import auctionServices from '../../../services/auction-services';
import authService from '../../../services/auth-services';
import style from './EventDashboard.module.css';
import Alert from '../../components/Alert/Alert';
import LoadingModal from "../../components/LoadingModal/LoadingModal"


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function EventDashboard() {
    const dispatch = useDispatch()
    const ctx = useContext(AuthContext)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [refresh, setRefresh] = useState(false)
    const [actionDenied, setActionDenied] = useState(false)
    const [godMod,setGOdMod] = useState(false);
    const { events, totalEvents, loading } = useSelector(state => state.userEvents)

    useEffect(() => {
        checkPermission(true,15)
    }, [])


    useEffect(() => {
        dispatch(fetchEvents({ user_id: ctx.user.id, page: page, limit: limit }))
    }, [page, limit, refresh])

    const getButtonText = (status) => {
        let text = ''
        switch (status) {
            case 'building':
                text = 'Submit For Approval'
                break;
            case 'live':
                text = 'Pause'
                break;
            case 'paused':
                text = 'Resume'
                break;
            default:
                text = null;
        }
        return text
    }
    const getCurrentStatus = (status) => {
        let currentStatus = ''
        switch (status) {
            case 'building':
                currentStatus = 'Building'
                break;
            case 'live':
                currentStatus = 'Live'
                break;
            case 'inreview':
                currentStatus = 'Pending Approval'
                break;
            case 'ready':
                currentStatus = 'Ready To Launch'
                break;
            case 'complete':
                currentStatus = 'Complete'
                break;
            case 'paused':
                currentStatus = 'Paused'
                break;
            case 'preview':
                currentStatus = 'Preview'
                break
            default:
                currentStatus = '';
        }
        return currentStatus
    }

    const checkPermission = async (checkGodMod, permissionId, auctionId) => {
        if (!checkGodMod) {
            const permission = await authService.checkPermissions(permissionId, auctionId)
            return permission
        }
        else {
             const permission = await authService.checkLimitedPermissions(15)
             permission.access && setGOdMod(true)
        }
    }
    const updateStatus = async (row, review) => {
        dispatch(toggleLoading())

        let status = ''
        if (review === 'approve') {
            status = 'ready'
        } else if (review === 'reject') {
            status = 'building'
        } else {
            switch (row.status) {
                case 'building':
                    status = 'inreview'
                    break;
                case 'live':
                    status = 'paused'
                    break;
                case 'paused':
                    status = 'live'
                    break;
                default:
                    status = '';
            }
        }
        if (status === '') {
            dispatch(toggleLoading())
            return
        }
        try {
            await auctionServices.updateAuctionStatus(row.id, { status })
        } catch (error) {
            setActionDenied(true)
            dispatch(toggleLoading())
            return
        }
        setRefresh(!refresh)
    }

    return (
        <Container title='Event Dashboard' style={{position: "relative"}}>

            {events?.length > 0 ? (
                <Paper>
                    <TableContainer>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ width: "50%" }}>Event Title</StyledTableCell>
                                    <StyledTableCell>Current status</StyledTableCell>
                                    <StyledTableCell>Manage</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {events?.map((row, i) => (
                                    <StyledTableRow key={i}>
                                        <StyledTableCell sx={{ width: "50%" }}>{row.title}</StyledTableCell>
                                        <StyledTableCell sx={{fontWeight: "600"}}>{getCurrentStatus(row.status)}</StyledTableCell>
                                        <StyledTableCell>
                                            <div style={{ display: "flex", alignItems: "center", gap: '14px' }}>
                                            {godMod && row.status === 'inreview' ? <>
                                                <div onClick={() => updateStatus(row, 'approve')} className={style.table_button}><span style={{ color: "green", fontWeight: "600" }}>Approve</span></div>
                                                <div onClick={() => updateStatus(row, 'reject')} className={style.table_button}><span style={{ color: "red", fontWeight: "600" }}>Reject</span></div>
                                            </> : null}
                                            {getButtonText(row.status) && <div onClick={() => updateStatus(row)} className={style.table_button}><span style={{ color: "#5EABD6", fontWeight: "600" }}>{getButtonText(row.status)}</span></div>}
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20]}
                        component="div"
                        count={totalEvents}
                        rowsPerPage={limit}
                        page={page - 1}
                        onPageChange={(e, newPage) => setPage(newPage + 1)}
                        onRowsPerPageChange={(e) => { setLimit(+e.target.value); setPage(1) }}
                    />
                </Paper>
            ) : <p>No events to show</p>}

            <Alert isOpen={actionDenied} message='You are not autharized to perform this action'
                onClose={() => setActionDenied(false)} />
            <LoadingModal isOpen={loading} />

        </Container>
    )
}

export default EventDashboard