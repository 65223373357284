import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../services/auth-services";


export const fetchUsers = createAsyncThunk(
    `users/fetchUsers`,
    async({limit,page}) => {
        const response = await authService.fetchAllUsers(limit,page)
        return {
            data: response.data.data.rows,
            totalUsers:response.data.total_count
        }
    }
)

const initialState = {
    users:[],
    totalUsers:0,
    loading:false,
    error:''
}

const userSlice = createSlice({
    name:'user',
    initialState,
    extraReducers: (builder)=> {
        builder.addCase(fetchUsers.pending, (state, action) => {
            state.loading=true
        })
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            state.users = action.payload.data
            state.totalUsers = action.payload.totalUsers
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchUsers.rejected, (state, action) => {
            state.loading=false
            state.error=action.error.message
        })

    }
})

export default userSlice.reducer