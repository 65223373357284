import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import auctionServices from '../../../services/auction-services'
import Container from "../../components/HOC/Container/Container"
import LoadingModal from '../../components/LoadingModal/LoadingModal'
import moment from 'moment';
import "./PreviewEvent.css"
import Alert from '../../components/Alert/Alert'
import ItemPreview from "./ItemPreview/ItemPreview"
import { Alert as MUIAlert, AlertTitle, Card, CardContent, CardMedia, CircularProgress, Pagination, Typography } from '@mui/material'
import authService from '../../../services/auth-services'
import EditIcon from '@mui/icons-material/Edit';
import Modal from '../../components/Modal/Modal'
import VariantsModalComponent from '../../components/VariantsModalComponent/VariantsModalComponent'
import { config } from '../../../config/config';
const REACT_USER_APP_BASE_URL = config.REACT_USER_APP_BASE_URL

const Image=({src,alt})=>{
    const [source,setSource] = useState(src+'_large');
    const handleImageLoadingError = ()=>{
      const lastIndex = src.lastIndexOf('_large');
      setSource(lastIndex !== -1 ? src.substring(0, lastIndex) : src)
    }
    return (
      <img src={source} alt={alt} onError={handleImageLoadingError} />
    )
  }

const PreviewEvent = () => {
    const [loading, setLoading] = useState(true)
    const [auctionItemsLoading, setAuctionItemsLoading] = useState(false)
    const [storeItemsLoading, setStoreItemsLoading] = useState(false)
    const [storeItems, setStoreItems] = useState(null)
    const [auctionItems, setAuctionItems] = useState(null)
    const [storePage, setStorePage] = useState(1)
    const [auctionPage, setAuctionPage]= useState(1)
    const [approvalLoading, setApprovalLoading] = useState(false)
    const navigate = useNavigate()
    const [submittedRequest, setSubmittedRequest] = useState(false)
    const [permission, setPermission] = useState(true)
    const [data, setData] = useState({
        event: null
    })
    const {auction_id} = useParams()
    const [previewItem, setPreviewItem] = useState(null)
    const [errorMessage,setErrorMessage] = useState(null)
    const [errorAlert, setErrorAlert] = useState(false)
    const [variantsModal, setVariantsModal] = useState(false)
    const [variants, setVariants] = useState([])
    const [options, setOptions] = useState({})
    const [eventType, setEventType] = useState('event')
    const location = useLocation()

    const getEventType=(path)=>{
        switch (path) {
          case `/admin/event-preview/${auction_id}`:
            return 'event'
          case `/admin/market-place-preview/${auction_id}`:
            return 'marketplace'
          case `/admin/store-preview/${auction_id}`:
            return 'store'
          default:
            return 'event'
        }
   }

    useEffect(()=>{
    if(location.pathname.startsWith('/admin/event-preview')){
        setEventType('event')
    } 
    else if(location.pathname.startsWith('/admin/store-preview')) {
        setEventType('store')
    }
    else if(location.pathname.startsWith('/admin/market-place-preview')){
        setEventType('marketplace')
    }
    },[])

    const checkApprovalPermission = async () => {
        try {
            const permission = await authService.checkPermissions(8, auction_id)
            if(!permission?.access) {
              setPermission(false)
              return
            }
        }
        catch(error) {
            setPermission(false)
        }
    }

    useEffect(() => {
        checkApprovalPermission()
    }, [])

    const getAuctionItemsById = async () => {
        try {
          setAuctionItemsLoading(true)
          const auction_items = await auctionServices.getAuctionItemsByAuctionId(auction_id, auctionPage, 12);
          setAuctionItems(auction_items)
          setAuctionItemsLoading(false)
        } catch (err) {
          setAuctionItemsLoading(false)
        }
    };

    const getStoreItemsById = async () => {
      try {
        setStoreItemsLoading(true)
        
        const store_items = await auctionServices.getStoreItemsByAuctionId(auction_id, storePage, 12, null, getEventType(location.pathname))
        setStoreItems(store_items)
        setStoreItemsLoading(false)
      } catch (err) {
        setStoreItemsLoading(false)
      }
    }

    useEffect(() => {
        getAuctionItemsById()
    }, [auctionPage, auction_id])

    useEffect(() => {
      getStoreItemsById()
    }, [storePage, auction_id])


    const fetchAuctionData = async () => {
        try {
            setLoading(true)
            const auction_data = await auctionServices.getAuctionDetailsById(auction_id);
            setData({
                ...data,
                event: auction_data.data
            })
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchAuctionData()
    }, [auction_id])

    const submitForApproval = async () => {
        try {
            setApprovalLoading(true)
            const data = await auctionServices.submitForApproval(auction_id)
            setApprovalLoading(false) 
            setSubmittedRequest(true)
        } catch (error) {
            setApprovalLoading(false)
        }
    }
    const redirectOnClose = () => {
        setSubmittedRequest(false)
        // navigate("/admin/my-events")
        if(location.pathname.startsWith('/admin/event-preview')) navigate(`/admin/my-events`)
        if(location.pathname.startsWith('/admin/market-place-preview')) navigate(`/admin/market-place`)
        if(location.pathname.startsWith('/admin/store-preview')) navigate(`/admin/store`)
    }
    const handleEdit = (item, type) => {
        if((moment(item.end_date).format() < moment().format()) &&( type === "auction")){
            setErrorMessage("You are not allowed to edit completed auction item");
            setErrorAlert(true)
            return
          }else{
            // navigate(`/admin/items/edit/${item.auction_id}/${item.id}`, {state: {type: type}})
            if(location.pathname.startsWith('/admin/event-preview')) navigate(`/admin/items/edit/${item.auction_id}/${item.id}`, {state: {type: type}})
            if(location.pathname.startsWith('/admin/market-place-preview')) navigate(`/admin/market-place/items/edit/${item.auction_id}/${item.id}`, {state: {type: type}})
            if(location.pathname.startsWith('/admin/store-preview')) navigate(`/admin/store/items/edit/${item.auction_id}/${item.id}`, {state: {type: type}})
          }
    }

    const fetchVariants = async(storeItemId) => {
        try {
            const response = await auctionServices.getVariantInformation(auction_id,storeItemId)
            // const newVariants = Object.values(response.data.variants).map(obj => {
            //     const newVar = {}
            //     for(let key of Object.keys(obj)) {
            //       newVar[key.trim()] = typeof(obj[key]) === 'string' ? obj[key].trim() : obj[key]
            //     }
            //     return newVar
            //   })
              setVariants(response?.data?.variants)
              setOptions(response?.data?.options)
              setVariantsModal(true)
        }catch(error) {}
      }

      const handleNavigateItems = () => {
        if(location.pathname.startsWith('/admin/event-preview')) navigate(`/admin/items`)
        if(location.pathname.startsWith('/admin/market-place-preview')) navigate(`/admin/market-place/items`)
        if(location.pathname.startsWith('/admin/store-preview')) navigate(`/admin/store/items`)
      }

  return (
    <>
        {
            !previewItem ? (
                <Container style={{padding: "0", position: "relative", height: submittedRequest ? "100%": "auto"}}>
                {!loading && !submittedRequest && (
                    <>
                    <div className='preview-container'>
                    <div className='preview-hero'>
                        <div className='preview-cover-image'>
                            <Image src={data.event.image2} alt="" />
                        </div>
                        <div className='preview-info'>
                                <div className='preview-event-info'>
                                    <div>
                                        <span style={{fontWeight: "600"}}>Status: </span>{" "}
                                        <div className='preview-status'>{data.event.status}</div>
                                    </div>
                                    <h1>{data.event.title}</h1>
                                    {data.event.causeDetails.legal_name ? <div>Benefitting: <span style={{fontWeight: "600"}}>{data.event.causeDetails.legal_name}</span></div> : null}
                                    {location.pathname.startsWith('/admin/event-preview') && <div style={{display: "flex", gap: "20px"}}>
                                        <div style={{fontSize: "0.8rem"}}>Start Date: {moment(data.event.start_date).format('MM/DD/YYYY')}</div>
                                        <div style={{fontSize: "0.8rem"}}>End Date: {moment(data.event.end_date).format('MM/DD/YYYY')}</div>
                                    </div>}
                                </div>
                                <div style={{display:"flex",columnGap:"20px"}}>
                                <button  className='aitem-white-button' style={{borderRadius:"25px",padding:"8px 18px"}}><a href={`${REACT_USER_APP_BASE_URL}/${eventType}/${data.event.id}${(data.event.status === "building" || data.event.status === "paused") ? '?admin_preview=true':''}`} target='_blank' rel='noreferrer' style={{color:'red'}}>Preview {eventType.replace(/^./, eventType[0].toUpperCase())}</a></button>
                                <button onClick={handleNavigateItems} className='aitem-modal-btn'>Manage Items</button>
                                </div>

                            </div>
                        </div>
                        {
                            data.event.status === "building" && <MUIAlert severity="info">Once you are happy with your setup please submit your event for approval to launch.</MUIAlert>
                        }
                        <div className='preview-lot-head'>All Lots</div>
                       {!location.pathname.startsWith('/admin/store-preview') && <div className="preview-items-container">
                            <h4 style={{fontWeight: "600"}}>Auction Items</h4>
                            {(auctionItemsLoading) ? <div className='preview-items-loading'><CircularProgress sx={{color: "black"}} /></div> : (auctionItems.data.length <= 0) ? <div>No Items added</div> : (
                                <>
                                    <div className='items-cards'>
                                        {auctionItems.data.map(item => (
                                            <Card  key={item.id} sx={{ width: 280, cursor: "pointer", position:'relative' }}>
                                                <CardMedia
                                                    sx={{ height: 140 }}
                                                    image={item.assets[0]?.name}
                                                    title={item.name}
                                                    onClick={() => setPreviewItem(item)}
                                                />
                                                <div style={{ display:'flex', flexDirection:"column",justifyContent:'space-between', height:'calc(100% - 140px)'}}>
                                                <CardContent onClick={() => setPreviewItem(item)}>
                                                    <Typography sx={{fontSize:'1rem !important',fontWeight:600 ,fontFamily:"AvenirNext', sans-serif !important"}} gutterBottom variant="h6" component="div">
                                                    {item.name}
                                                    </Typography>
                                                   <div style={{display:'flex', justifyContent:'space-between'}}>
                                                   <Typography sx={{ fontSize:'.9rem' , fontFamily:"inherit"}} gutterBottom component="div">
                                                    ${item.price}
                                                    </Typography>
                                                    <Typography sx={{ fontSize:'.9rem' , fontFamily:"inherit"}} gutterBottom component="div">
                                                    SKU: {item.sku}
                                                    </Typography>
                                                   </div>
                                                    <Typography sx={{ fontSize:'.8rem', fontFamily:"inherit"}} variant="body2" color="text.secondary">
                                                    {item.short_description}
                                                    </Typography>
                                                </CardContent>
                                                <button onClick={() => {handleEdit(item, "auction")}}
                                                  className='aitem-modal-btn'
                                                  style={{fontSize:"0.8rem",  margin:'6px auto', display:'flex', gap:'10px', justifyContent:"center", width: "fit-content"}}>
                                                  <EditIcon sx={{fontSize: 16}}/> <span>Edit</span> </button>
                                                  </div>
                                            </Card>
                                        ))}
                                    </div>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <Pagination
                                            count={auctionItems.total_page}
                                            page={auctionPage}
                                            onChange={(e, newPage) => setAuctionPage(newPage)}
                                        />
                                    </div>
                                </>
                            )}
                        </div>}
                        <hr />
                        <div className="preview-items-container">
                            <h4 style={{fontWeight: "600"}}>Store Items</h4>
                            {(storeItemsLoading) ? <div className='preview-items-loading'><CircularProgress sx={{color: "black"}} /></div> : (storeItems?.data?.length <= 0) ? <div>No Items added</div> : (
                                <>
                                    <div className='items-cards'>
                                        {storeItems?.data?.map(item => (
                                            <Card  key={item.id} sx={{ width:280, cursor: "pointer"}}>
                                                <CardMedia
                                                    sx={{ height: 140 }}
                                                    image={item?.assets[0]?.name}
                                                    title={item?.name}
                                                    onClick={() => setPreviewItem(item)}
                                                />
                                                <div style={{display:'flex', flexDirection:"column",justifyContent:'space-between', height:'calc(100% - 140px)'}}>
                                                <CardContent >
                                                    <Typography onClick={() => setPreviewItem(item)} sx={{fontSize:'1rem !important', fontWeight:600, fontFamily:"AvenirNext', sans-serif !important"}} gutterBottom variant="h6" component="div">
                                                    {item?.name}
                                                    </Typography>
                                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                                    <Typography onClick={() => setPreviewItem(item)} sx={{ fontSize:'.9rem' , fontFamily:"inherit"}} gutterBottom component="div">
                                                    ${item?.store_item.price}
                                                    </Typography>
                                                    { item?.store_item?.store_item_actual_inventories?.length > 0 ?  
                                                        <Typography onClick={() => fetchVariants(item?.store_item.id)} sx={{ fontSize:'.9rem' , fontFamily:"inherit", cursor:"pointer", color:"blue"}} gutterBottom component="div">
                                                        Show variants.
                                                        </Typography> :
                                                    <Typography onClick={() => setPreviewItem(item)} sx={{ fontSize:'.9rem' , fontFamily:"inherit"}} gutterBottom component="div">
                                                    SKU: {item?.store_item.sku}
                                                    </Typography>
                                                   }
                                                    </div>
                                                    {/* { item?.store_item?.store_item_actual_inventories?.length > 0 ?  
                                                        <Typography onClick={() => fetchVariants(item.store_item.id)} sx={{ fontSize:'.9rem' , fontFamily:"inherit", cursor:"pointer", color:"blue"}} gutterBottom component="div">
                                                        Show variants.
                                                        </Typography> :
                                                        null
                                                    } */}
                                                    <Typography onClick={() => setPreviewItem(item)} sx={{ fontSize:'.8rem', fontFamily:"inherit"}} variant="body2" color="text.secondary">
                                                    {item.short_description}
                                                    </Typography>
                                                </CardContent>
                                                {!item?.auction_auction_item_xrefs?.length ? <button onClick={() => {handleEdit(item, "store")}}
                                                  className='aitem-modal-btn'
                                                  style={{fontSize:"0.8rem",  margin:'6px auto', display:'flex', gap:'10px', justifyContent:"center", width: "fit-content"}}>
                                                  <EditIcon sx={{fontSize: 16}}/> <span>Edit</span> 
                                                </button> : null}
                                                </div>
                                            </Card>
                                        ))}
                                    </div>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <Pagination
                                            count={storeItems.total_page}
                                            page={storePage}
                                            onChange={(e, newPage) => setStorePage(newPage)}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {!permission && <MUIAlert severity='error'>
                        <AlertTitle><strong>You don't have the permission to perform this action.</strong></AlertTitle>
                        Contact BG administrator if you need help.
                    </MUIAlert>}

                    {data.event.status === 'live' ? null : <div className='aitem-submit-container'>
                        <button onClick={submitForApproval} className='aitem-modal-btn' disabled={data.event.status !== "building" || !permission} type='button'>{approvalLoading ? <CircularProgress size={20} sx={{color: "white"}}/> : "Submit to Boundless Giving for Approval"}</button>
                    </div>}
                        </>
                    )}
                    <Alert message="Approval Request Submitted" isOpen={submittedRequest} onClose={redirectOnClose} />
                    <LoadingModal isOpen={loading} />
                </Container>
            ) : <ItemPreview item={previewItem} goBack={() => setPreviewItem(null)} />
        }
        <Alert message={errorMessage} isOpen={errorAlert} onClose={() => setErrorAlert(false)} type="alert" />
    
        <Modal isOpen={variantsModal} onClose={() => setVariantsModal(false)} onDone={() => setVariantsModal(false)} title={'Variants Details'} buttonText='Close' >
       <VariantsModalComponent variants={variants} options={options}/>
       </Modal>

    </>
  )
}

export default PreviewEvent