import React, { useRef } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography, Tooltip } from '@mui/material';
import classes from './SearchInput.module.css'
import {Clear, Search} from '@mui/icons-material/';

const SearchInput = ({ value, onChange, label = null, name, width = "100%", required = false, type = "text", iButton = false, iButtonText = "", infoSize = '16px', style = {}, labelStyle = {}, inputStyle = {}, infoBubbleText = "", disabled = false, placeholder = "", autoComplete = 'off', maxLength = null, handleSearchClick=()=>null, handleClearClick=()=>null, needSearchIcon=true, ...props }) => {
    const myInput = useRef()
    const preventScroll = (e) => {
        if (type === 'number' && myInput.current) {
            myInput.current.blur()
        }
    }
    return (
        <div className={`${classes.inputContainer}`} style={{ width, ...style }}>
            <div className={`input-container ${classes.searchInputBox}`}>
                {label && <label className='input-label' style={{ ...labelStyle }} htmlFor={name}>{label} {required && <span style={{ color: "red" }}>*</span>} {" "} {iButton && <Tooltip title={<Typography sx={{ fontSize: '0.85rem' }}>{infoBubbleText}</Typography>}><InfoOutlinedIcon sx={{ color: '#495057c4', fontSize: infoSize, marginLeft: "4px" }} /></Tooltip>}</label>}
                <input maxLength={maxLength} onWheel={preventScroll} ref={myInput} min={0} required={required} name={name} type={type} value={value} onChange={onChange} style={{ ...inputStyle }} {...props} disabled={disabled} placeholder={placeholder} autoComplete={autoComplete} />
            </div>
            <div className={classes.searchActions} >
                {needSearchIcon &&<Search className={classes.icons} onClick={handleSearchClick}/>}
                <Clear className={classes.icons} onClick={handleClearClick}/>
            </div>
        </div>
    )
}

export default SearchInput;