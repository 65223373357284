import React from 'react'
import ReactSelect from "react-select";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip, Typography } from '@mui/material';
import { COLORS_OBJECT } from '../../../constants/en';

const redColor = COLORS_OBJECT.color[0]

const DropDown = ({isSearchable=false, options, label, labelStyle={}, menuPlacement="bottom", required,iButton=false,infoSize='16px', onChange=() => {}, defaultValue, iButtonText="", style={},isDisabled=false, infoBubbleText="",...props}) => {

  return (
    <div style={{flex: 1, ...style}}>
        <label className='input-label' style={{...labelStyle}}>{label} {required && <span className='required-asterisk'>*</span> } {" "} {iButton&&<Tooltip title={<Typography sx={{fontSize:'0.85rem'}}>{infoBubbleText}</Typography>}><InfoOutlinedIcon sx={{color:'#495057c4',fontSize:infoSize, position:"relative",left:10}} /></Tooltip>}</label>
        <ReactSelect
            {...props}
            defaultValue={defaultValue}
            onChange={onChange}
            menuPlacement={menuPlacement}
            isSearchable={isSearchable}
            isDisabled={isDisabled}
            styles={{
            control: (baseStyle, state) => ({
                ...baseStyle,
                padding: "0 7px",
                borderRadius: "20px",
                border: "1px solid #d3d3d3",
                fontSize: "0.9rem",
            }),
            option: (baseStyle, state) => ({
                ...baseStyle,
                fontSize: "0.8rem",
                fontWeight: "600",
                padding: "10px",
                backgroundColor: state.isSelected ? redColor : state.isFocused ? "#eeeeee" : state.isDisabled ? "transparent" : "white",
                color: state.isSelected ? "white": state.isDisabled ? "#d3d3d3" : "black"
            })
            }}
            options={options}
            theme={(theme) => ({
            ...theme,
            colors: {
                ...theme.colors,
                primary: "#22a8f6",
            },
            })}
        />
    </div>
  )
}

export default DropDown