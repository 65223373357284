import Container from "../../components/HOC/Container/Container";
import "./AddDonor.css";
import { useEffect, useState } from "react";
import checkImageBeforeUpload from "../../../utils/checkImageRatio";
import auctionServices from "../../../services/auction-services";
import { onlyNumber, validEmail, validName } from "../../../utils/reg-ex";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input/Input";
import TextArea from "../../components/FormTextarea/FormTextarea";
import ImageInput from "../../components/ImageInput/ImageInput";
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import Multiselect from 'multiselect-react-dropdown';
import Alert from "../../components/Alert/Alert";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import constants from "../../../constants/en";
import usePrompt from "../../hooks/usePrompt";
import RadioGroupComponent from "../../components/RadioGroup/RadioGroup";

const AddDonor = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [about, setAbout] = useState("");
  const [legalName, setlegalName] = useState("");
  const [taxid, setTaxid] = useState("");
  const [orgName, setOrgname] = useState("");
  const [logo, setLogo] = useState(null);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [portraitImageLoading, setPortraitImageLoading] = useState(false);
  const [featuredImageLoading, setFeaturedImageLoading] = useState(false)
  const [portraitImage, setPortraitImage] = useState([]);
  const [events,setEvents] = useState([]);
  const [selectedEvents,setSelectedEvents] = useState([]);
  const navigate = useNavigate();
  const [alertMsg, setAlertMsg] = useState(null);
  const [featureIcon,setFeaturedIcon] = useState(null);
  const [featuredImage,setFeaturedImage] = useState([]);
  const [loading,setLoading] = useState(false)
  const [showPrompt, setShowPrompt] = useState(false)
  const [donorFeatured, setDonorFeatured] = useState(false)
  const [whiteborderPortraitAlert,setWhiteborderPortraitAlert] = useState(null);
  const [portraitEvent,setPortraitEvent] = useState(null);
  const [featuredLogoWhiteBorderAlert,setFeaturedLogoWhiteBorderAlert] = useState(null);
  const [featuredLogoEvent,setFeaturedLogoEvent] = useState(null);
  const [ediPartnerId, setEdiPartnerId] = useState({isPartner: false, value:''})



  usePrompt("Do you want to leave? You will lose your changes!", showPrompt)



  const getEventList = async ()=>{
    const data = await auctionServices.getAllEventsForAdmin(null, null, false); // false is for not showing completed events
    setEvents(data?.data.map((obj,i)=>{
      return {key:obj.title,value:obj.id}
    }))
  }
  useEffect(()=>{
    getEventList()
  },[])


  // handle first name
  const handleFirstName = (e) => {
    setShowPrompt(true)
    setFirstName(e.target.value);
    if (validName.test(e.target.value)) {
      setFirstNameErr(false);
    } else {
      setFirstNameErr(true);
    }
  };

  // handle last name
  const handleLastName = (e) => {
    setShowPrompt(true)
    setLastname(e.target.value);
    if (validName.test(e.target.value)) {
      setLastNameErr(false);
    } else {
      setLastNameErr(true);
    }
  };
  // handle email
  const handleEmail = (e) => {
    setShowPrompt(true)
    setEmail(e.target.value);
    if (validEmail.test(e.target.value)) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
  };
  // handle image
  const handlePortraitImage = async (e,confirm) => {
     try{
      if (!e.target.files[0]) return;
      setShowPrompt(true)
      setPortraitImageLoading(true);
      const image = e.target.files[0];
      if (image.size > 5000000) {
        setShowPrompt(false)
        setAlertMsg(constants.ADMIN_COPY_ITEM_IMG_FILE_SIZE);
        setPortraitImageLoading(false);
        return;
      }
      const imageUrl = URL.createObjectURL(image);
      const { width, height } = await checkImageBeforeUpload(imageUrl);
      if (width < 300 || height < 300) {
        setShowPrompt(false)
        setAlertMsg(constants.ADMIN_COPY_ITEM_IMG_POTRAIT_SIZE);
        setPortraitImageLoading(false);
        return;
      }
      if(!confirm){
        if(width/height < 0.98 || width/height > 1){
          setPortraitEvent(e);
          setPortraitImageLoading(false)
          setWhiteborderPortraitAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','1:1'));
          return
        }
      }else{
        setPortraitEvent(null)
      }
      const formData = new FormData();
      formData.append("image", image);
      formData.append("width", 300);
      formData.append("height", 300);
      const preProcessedImage = await auctionServices.imagePreProcess(formData);
      setPortraitImage([
        {
          name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`,
          type: "img",
        },
      ]);
      const uint8 = new Uint8Array(preProcessedImage.buffer.data);
      const newBlob = new Blob([uint8], { type: "image/jpeg" });
      setLogo(newBlob);
      setPortraitImageLoading(false);
      setShowPrompt(false)
     }
     catch(error){
      setPortraitImageLoading(false);
      setShowPrompt(false)
     }
  };

   // handle image
   const handleFeaturedImage = async (e,confirm) => {
      try{
        if (!e.target.files[0]) return;
        setFeaturedImageLoading(true);
        setShowPrompt(true)
        const image = e.target.files[0];
        if (image.size > 5000000) {
          setShowPrompt(false)
          setAlertMsg(constants.ADMIN_COPY_ITEM_IMG_FILE_SIZE);
          setFeaturedImageLoading(false);
          return;
        }
        const imageUrl = URL.createObjectURL(image);
        const { width, height } = await checkImageBeforeUpload(imageUrl);
        if (width < 300 || height < 300) {
          setShowPrompt(false)
          setAlertMsg(constants.ADMIN_COPY_ITEM_IMG_POTRAIT_SIZE);
          setFeaturedImageLoading(false);
          return;
        }
        if(!confirm){
          if(width/height < 0.98 || width/height > 1){
            setFeaturedLogoEvent(e);
            setFeaturedImageLoading(false)
            setFeaturedLogoWhiteBorderAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','1:1'));
            return
          }
        }else{
          setFeaturedLogoEvent(null)
        }
        const formData = new FormData();
        formData.append("image", image);
        formData.append("width", 300);
        formData.append("height", 200);
        const preProcessedImage = await auctionServices.imagePreProcess(formData,0);
        setFeaturedImage([
          {
            name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`,
            type: "img",
          },
        ]);
        const uint8 = new Uint8Array(preProcessedImage.buffer.data);
        const newBlob = new Blob([uint8], { type: "image/jpeg" });
        setFeaturedIcon(newBlob);
        setFeaturedImageLoading(false);
        setShowPrompt(false)
      }
      catch(error){
        setFeaturedImageLoading(false);
        setShowPrompt(false)
      }
  };

      const handleFeaturedImageDelete = ()=>{
        setFeaturedIcon(null);
        setFeaturedImage([]);
      }

      const handleDeletelogo = () =>{
        setLogo(null);
        setPortraitImage([])
      }

  // handle submit
  const handleSubmit = async () => {
    setLoading(true)
    setShowPrompt(false)
     if (orgName === "" || orgName?.trim()?.length < 2) {
      setAlertMsg(constants.ADMIN_COPY_DONOR_ORG_NAME);
      setLoading(false)
      return true
    }
    // else if(ediPartnerId.isPartner && !onlyNumber.test(ediPartnerId.value)) {
    //   setAlertMsg(constants.ADMIN_COPY_DONOR_EDI_PARTNER_ALERT);
    //   setLoading(false)
    //   return true 
    // }
    else if(ediPartnerId.isPartner && !ediPartnerId.value?.trim()) {
      setAlertMsg(constants.ADMIN_COPY_DONOR_EDI_PARTNER_REQUIRED_ALERT);
      setLoading(false)
      return true 
    }
    else if (address1 === "") {
      setAlertMsg(constants.ADMIN_COPY_DONOR_ADDRESS);
      setLoading(false)
      return true
  } else if (city === "") {
      setAlertMsg(constants.ADMIN_COPY_DONOR_CITY);
      setLoading(false)
      return true
  } else if (state === "") {
      setAlertMsg(constants.ADMIN_COPY_DONOR_STATE);
      setLoading(false)
      return true
  }else if (zip === "") {
      setAlertMsg(constants.ADMIN_COPY_DONOR_ZIP);
      setLoading(false)
      return true
  }
    else if (firstname === "") {
        setAlertMsg(constants.ADMIN_COPY_DONOR_FIRST_NAME);
        setLoading(false)
        return true
    }
    else if (!validName.test(firstname)) {
        setAlertMsg(constants.ADMIN_COPY_DONOR_INVALID_FIRST_NAME);
        setLoading(false)
        return true
    }
    else if (lastname === "") {
        setAlertMsg(constants.ADMIN_COPY_DONOR_LAST_NAME);
        setLoading(false)
        return true
    }
    else if (!validName.test(lastname)) {
        setAlertMsg(constants.ADMIN_COPY_DONOR_INVALID_LAST_NAME);
        setLoading(false)
        return true
    }
    else if (email === "") {
      setAlertMsg(constants.ADMIN_COPY_DONOR_EMAIL);
      setLoading(false)
      return true
    }
    else if (!validEmail.test(email)) {
        setAlertMsg(constants.ADMIN_COPY_DONOR_EMAIL_INVALID);
        setLoading(false)
        return true
    } 
    else if (about === "") {
        setAlertMsg(constants.ADMIN_COPY_DONOR_ABOUT);
        setLoading(false)
        return true
    }
    else if (taxid !== '' && isNaN(Number(taxid))) {
        setAlertMsg(constants.ADMIN_COPY_DONOR_TAX_ID_NUMERIC);
        setLoading(false)
        return true;
    }
    else if (selectedEvents.length <= 0) {
      setAlertMsg(constants.ADMIN_COPY_DONOR_SELECT_EVENT);
      setLoading(false)
      return true
    }
    else if (donorFeatured && (!featureIcon || !logo)) {
      setAlertMsg(constants.ADMIN_COPY_DONOR_FEATURED_IMAGE);  
      setLoading(false)
      return true      
    }

    if (
      firstname !== "" &&
      lastname !== "" &&
      address1 !== "" &&
      city !== "" &&
      state !== "" &&
      zip !== "" &&
      email !== "" &&
      about !== "" &&
      orgName !== "" &&
      about.length > 2 &&
      selectedEvents.length > 0
    ) {
      try {
        let formData = new FormData();
        formData.append("contact_firstname", firstname);
        formData.append("contact_lastname", lastname);
        formData.append("address1", address1);
        formData.append("address2", address2);
        formData.append("city", city);
        formData.append("state", state);
        formData.append("zip", zip);
        formData.append("email", email);
        logo && formData.append("splashimage", logo);
        formData.append("about", about);
        formData.append("org_name", orgName);
        formData.append("legal_name", legalName);
        taxid!=='' && formData.append("tax_id", taxid);
        formData.append("assignedEvents",JSON.stringify(selectedEvents));
        featureIcon && formData.append("featured_icon", featureIcon)
        donorFeatured && formData.append("featured", 1);
        ediPartnerId.isPartner && ediPartnerId.value && formData.append("sps_edi_trading_partner_id", ediPartnerId.value);

        await auctionServices
          .addDonor(formData)
          .then((res) => {
            setAlertMsg(res.data.msg);
            setLoading(false)
            navigate("/admin/manage-donor");
          })
          .catch((err) => {
            if(err?.response?.data?.data?.length) {
              setAlertMsg(err?.response?.data?.data[0].msg || constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
            }
            else {
              setAlertMsg(err.response.data.message || constants.ADMIN_COPY_SOMETHING_WENT_WRONG);
            }
            setLoading(false)
          });
      } catch (err) {
        setLoading(false)
        throw err;
      }
    } else {
      setLoading(false)
    }
  };

  const handleFeaturedRadioChange = (e) => {
      setDonorFeatured(e)
  }

  const handleEDIRadioChange = (e) => {
    setEdiPartnerId({isPartner:e, value:''})
  }


  return (
    <>
      <Container title="Add Donor" style={{position: "relative"}}>
      <div className="manage-donor-title"><VolunteerActivismOutlinedIcon sx={{ fontSize: 18, marginRight: "3px", color: "skyblue" }}/> Manage Donor</div>
        <div className="addDonor-container">
          <div className="addDonor-container-left">
            
          <Input
              label="Donor Name (Company Name or Individual That Will Be Displayed in Marketplace)"
              required
              value={orgName}
              onChange={(e) => {
                setShowPrompt(true)
                setOrgname(e.target.value);
              }}
            />
          <Input
              value={address1}
              required
              label="Organization Address1"
              onChange={(e) => {
                setShowPrompt(true)
                setAddress1(e.target.value);
              }}
            />

            <Input
              type="text"
              label="Organization Address2"
              value={address2}
              onChange={(e) => {
                setShowPrompt(true)
                setAddress2(e.target.value);
              }}
            />
            <Input
              required
              value={city}
              label="City"
              onChange={(e) => {
                setShowPrompt(true)
                setCity(e.target.value);
              }}
            />

            <Input
              required
              value={state}
              label="State"
              onChange={(e) => {
                setShowPrompt(true)
                setState(e.target.value);
              }}
            />
          <Input
              label="Zip"
              required
              value={zip}
              onChange={(e) => {
                setShowPrompt(true)
                setZip(e.target.value);
              }}
            />
          
            <Input
              required
              value={firstname}
              label="Contact First Name"
              onChange={(e) => handleFirstName(e)}
              style={{ marginBottom: "0" }}
            />
            {firstNameErr && (
              <label
                style={{
                  textAlign: "left",
                  color: "red",
                  fontWeight: "400",
                  marginBottom: "10px",
                }}
              >
                Enter a valid first name!
              </label>
            )}

            <Input
              required
              value={lastname}
              label="Contact Last Name"
              onChange={(e) => handleLastName(e)}
              style={{ marginBottom: "0" }}
            />
            {lastNameErr && (
              <label
                style={{
                  textAlign: "left",
                  color: "red",
                  fontWeight: "400",
                  marginBottom: "10px",
                }}
              >
                Enter a valid last name!
              </label>
            )}
           <Input
              value={email}
              label="Contact Email Address"
              required
              onChange={(e) => handleEmail(e)}
              style={{ marginBottom: "0" }}
            />
            {emailErr && (
              <label
                style={{
                  textAlign: "left",
                  color: "red",
                  fontWeight: "400",
                  marginBottom: "0",
                }}
              >
                Enter a valid email id!
              </label>
            )}
                  
          <RadioGroupComponent 
           label={'Make this donor a featured donor'}
           firstLabel="Yes"
           secondLabel= "No"
           onChange={handleFeaturedRadioChange}
           checked={donorFeatured}
          />

          </div>
          <div className="addDonor-container-right">

          <RadioGroupComponent 
           label={'Is the donor a trading partner of EDI/SPS?'}
           firstLabel="Yes"
           secondLabel= "No"
           onChange={handleEDIRadioChange}
           checked={ediPartnerId.isPartner}
          />

            {
              ediPartnerId.isPartner && 
              <Input
                 label="Enter ID"
                 value={ediPartnerId.value}
                 onChange={(e) => {
                   setEdiPartnerId({isPartner:true, value:e.target.value});
                 }}
               />
            }

           <TextArea
              value={about}
              onChange={(e) => {
                setAbout(e.target.value);
              }}
              required
              label="Tell us about your organization (max 1000 characters)"
              maxsize="1000"
            />
            <Input
              label="Organization Legal Name (For Tax Purposes)"
              value={legalName}
              onChange={(e) => {
                setlegalName(e.target.value);
              }}
            />
          <Input
              label="Organization Tax ID"
              value={taxid}
              onChange={(e) => {
                setTaxid(e.target.value);
              }}
            />
          

            <label className="input-label" >Select Events</label>
            <Multiselect 
              displayValue="key"
              onRemove={(e)=>setSelectedEvents(e)}
              onKeyPressFn={function noRefCheck(){}}
              onSelect={(e)=>setSelectedEvents(e)}
              options={events}
              showArrow
              avoidHighlightFirstOption
            />

            <ImageInput
              loading={portraitImageLoading}
              required={donorFeatured}
              label="Featured Logo"
              sidelabel="Recommended size 300*300"
              handleChange={(e)=>handlePortraitImage(e,false)}
              images={portraitImage ? portraitImage : []}
              edit
              onDelete={handleDeletelogo}
              infoBubbleText={constants.ADMIN_COPY_DONOR_SPLASH_IMAGE_INFO_TEXT}
              iButton
            />
            <ImageInput
              loading={featuredImageLoading}
              required={donorFeatured}
              label="Featured Icon"
              sidelabel="Recommended size 300*300"
              handleChange={(e)=>handleFeaturedImage(e,false)}
              images={featuredImage ? featuredImage : []}
              edit
              onDelete={handleFeaturedImageDelete}
              infoBubbleText={constants.ADMIN_COPY_DONOR_FEATURED_ICON_INFO_TEXT}
              iButton
            />
           
          </div>

        </div>
            <div className="add-donor-btn" style={{textAlign:"center"}}>
              <button className="aitem-modal-btn" onClick={handleSubmit}>Add</button>
            </div>
            <div style={{fontSize: "0.9rem", marginTop: "20px", fontWeight: "600"}}><span style={{color: "red"}}>*</span> - required fields</div>
            <Alert isOpen={!!alertMsg} message={alertMsg} onClose={() => setAlertMsg(null)} />
            <LoadingModal isOpen={loading}/>
            <Alert isOpen={!!whiteborderPortraitAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handlePortraitImage(portraitEvent,true);
                setWhiteborderPortraitAlert(null)
                }} 
                onClose={()=>setWhiteborderPortraitAlert(null)} 
                message={whiteborderPortraitAlert}/>
           <Alert isOpen={!!featuredLogoWhiteBorderAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handleFeaturedImage(featuredLogoEvent,true);
                setFeaturedLogoWhiteBorderAlert(null)
                }} 
                onClose={()=>setFeaturedLogoWhiteBorderAlert(null)} 
                message={featuredLogoWhiteBorderAlert}/>
      </Container>
    </>
  );
};

export default AddDonor;
