import { useState } from "react"
import Container from "../../components/HOC/Container/Container"
import causeServices from "../../../services/cause-services"
import { useEffect } from "react"
import authService from "../../../services/auth-services"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePagination, styled } from "@mui/material"
import TableButton from "../../components/TableButton/TableButton"
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessDenied from "../../components/AccessDenied/AccessDenied"
import Alert from "../../components/Alert/Alert"
import constants from "../../../constants/en"


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const RestoreCause = ()=>{
    const [causes, setCauses] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [godMod,setGodMod] = useState(false)
    const [openConfirmAlert,setOpenConfirmAlert] = useState(false);
    const [restoreCauseId,setrestoreCauseId] = useState(null);
    const [alert,setAlert] = useState(null)
    const [refresh,setRefresh] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [totalCount,setTotalCount] =useState(0)

    useEffect(() => {
      (async () => {
            try {
              setIsLoading(true)
              const data = await causeServices.getAllArchivedCause(page,limit)
              // console.log(data)
              setCauses(data?.data)
              setTotalCount(data?.total_count)
              setIsLoading(false)
            } catch (error) {
              setIsLoading(false)              
            }
      })()
    }, [refresh,page,limit])
    useEffect(()=>{
      checkPermission()
  },[])


  const checkPermission =async () =>{
    const permission = await authService.checkLimitedPermissions(15)
    permission.access && setGodMod(true)
}


const handleDeleteCauseConfirm =async () =>{
  setOpenConfirmAlert(false)
  if(!restoreCauseId) return 
  setIsLoading(true)
  await causeServices.restoreCause(restoreCauseId).then((res)=>{
      // console.log(res);
      setAlert(res?.data?.message)
      setIsLoading(false)
      setrestoreCauseId(null);
      setRefresh(!refresh)
  }).catch((err)=>{
      // console.log(err);
      setAlert(err?.response?.data?.message)
      setrestoreCauseId(null);
      setIsLoading(false)
  })
  

}

const handleCauseRestore = (cause) =>{
  setrestoreCauseId(cause?.id);
  setOpenConfirmAlert(true)

}

const handleRestoreAlertClose = () =>{
  setrestoreCauseId(null);
  setOpenConfirmAlert(false)
}
    return(
        <Container title="Restore Cause">
     {!godMod && <AccessDenied/>}
{  isLoading ? <LoadingModal isOpen={true} /> : 
     <>
    <div className='event_header_container'>
        <span className='calender_icon_container'><CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#34b5e5' }} /><p>Causes</p></span>
    </div>
    <hr />
    {causes?.length > 0 ? (
        <Paper>
            <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell sx={{width: "40%"}}>Legal Name</StyledTableCell>
                        <StyledTableCell>Organization Name</StyledTableCell>
                        <StyledTableCell>Email</StyledTableCell>
                        <StyledTableCell>Manage</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {causes?.map((row, i) => (
                    <StyledTableRow key={row.id}>
                        <StyledTableCell>{row.legal_name}</StyledTableCell>
                        <StyledTableCell>{row.org_name}</StyledTableCell>
                        <StyledTableCell>{row.email}</StyledTableCell>
                        <StyledTableCell sx={{display: "flex", alignItems: "center", gap: '14px'}}>
                            <TableButton onClick={() =>{handleCauseRestore(row)}} Icon={RestoreOutlinedIcon} text="Restore"/>
                        </StyledTableCell>
                    </StyledTableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20]}
                component="div"
                count={totalCount}
                rowsPerPage={limit}
                page={page - 1}
                onPageChange={(e, newPage) => setPage(newPage + 1)}
                onRowsPerPageChange={(e) => {setLimit(+e.target.value);setPage(1)}}
            />
        </Paper>
    ) : <p>No causes to show</p>}
    </>}
    <Alert message={constants.ADMIN_COPY_CAUSE_RESTORE_CONFIRM} isOpen={openConfirmAlert} type="confirm" onClose={handleRestoreAlertClose} onConfirm={handleDeleteCauseConfirm} />
       <Alert isOpen={! !alert} message={alert}
                onClose={() => setAlert(null)} />
        </Container>
    )
}

export default RestoreCause