import React from 'react'
import "./LoadingButton.css"

const LoadingButton = ({children, isLoading, ...props}) => {
  return (
    <button {...props} className="loading-btn">
    {isLoading ? 
        <div class="spinner-border spinner-border-sm" size role="status">
        </div> : children}
    </button>
  )
}

export default LoadingButton