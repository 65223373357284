import React from 'react'
import { FormControlLabel } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import constants from '../../../constants/en';
import { COLORS_OBJECT } from '../../../constants/en';


const ClearButton = ({onClick=()=>null, labelPlacement='start', style={}}) => {
    return (
        <>
        <div style={style} className='clear_button_container' >
          <FormControlLabel labelPlacement={labelPlacement} onClick={onClick} control={<DeleteOutlineOutlinedIcon sx={{color:COLORS_OBJECT.color[0], marginLeft:'10px'}} />} label={constants.ADMIN_COPY_CLEAR_UNSAVED_CHANGES} />
        </div>
        </>
    )
}

export default ClearButton;