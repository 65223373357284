import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    type: "add",
    auction_status: null,
    cause_id: null,
    cause_category_id: null,
    cause_legal_name: null,
    auction_id: null,
    auction_title: null,
    start_date: null,
    end_date: null,
    active:null,
    has_auction: null,
    has_buy_now: null,
}

const dependenciesReducer = createSlice({
    name: "dependencies",
    initialState,
    reducers: {
        typeEdit: (state, action) => {
            state.type = "edit"
            state.auction_status = action.payload.auction_status
            state.auction_id = action.payload.auction_id
            state.cause_id = action.payload.cause_id
            state.cause_category_id = action.payload.cause_category_id
            state.auction_title = action.payload.auction_title
            state.start_date = action.payload.start_date
            state.end_date = action.payload.end_date
            state.active = action.payload.active
            state.has_buy_now = action.payload.has_buy_now
            state.has_auction = action.payload.has_auction
        },
        resetDependencies: (state, action) => {
            return initialState
        },
        updateProperties: (state, action) => {
            state[action.payload.key] = action.payload.value
        },
        updateCause: (state, action) => {
            state.cause_id = action.payload.cause_id
            state.cause_category_id = action.payload.cause_category_id
            state.cause_legal_name = action.payload.cause_legal_name
        },
        updateEvent: (state, action) => {
            state.type = action.payload.type
            state.auction_id = action.payload.auction_id
            state.auction_title = action.payload.auction_title
            state.start_date = action.payload.start_date
            state.end_date = action.payload.end_date
            state.active = action.payload?.active
            state.has_buy_now = action.payload?.has_buy_now
            state.has_auction = action.payload?.has_auction
        },
    }
})

export default dependenciesReducer.reducer
export const {  resetDependencies, updateProperties, updateCause, updateEvent, typeEdit} = dependenciesReducer.actions