import axios from "axios";
import { headerKey, headerKey_ } from "./api-header";
import { config } from '../config/config.js';

const API_BASE_URL = config.REACT_APP_BASE_URL;


export const createBlog = async (formData, setAlertMsg) => {
    try {
        const data = await axios.post(`${API_BASE_URL}/api/v1/users/create-blog`, formData, {
            headers: { ...headerKey_ },
        })
        return data
    } catch (error) {
        setAlertMsg(error?.response?.data?.message)
    }
}
export const getAllBlogs = async (page,limit,setAlertMsg) => {
    try {
        const data = await axios.get(`${API_BASE_URL}/api/v1/users/blogs/get-blogs`, {
            params:{
                page,
                limit,
                admin:true,
            },
            headers: { ...headerKey_ },
        })
        return data.data
    } catch (error) {
        setAlertMsg(error?.response?.data?.message)
    }
}
export const getBlog = async (pretty_url,setAlertMsg) => {
    try {
        const data = await axios.get(`${API_BASE_URL}/api/v1/users/blog/${pretty_url}`, {
            headers: { ...headerKey_ },
        })
        return data.data
    } catch (error) {
        setAlertMsg(error?.response?.data?.message)
    }
}
export const updateBlog = async (pretty_url,formData,setAlertMsg) => {
    try {
        const data = await axios.put(`${API_BASE_URL}/api/v1/users/update-blog/${pretty_url}`,formData, {
            headers: { ...headerKey_ },
        })
        return data
    } catch (error) {
        setAlertMsg(error?.response?.data?.message)
    }
}
export const updateBlogStatus = async (id,status,setAlertMsg) => {
    try {
        console.log('update')
        const data = await axios.put(`${API_BASE_URL}/api/v1/users/blog/update-status/${id}`,{status}, {
            headers: { ...headerKey_ },
        })
        return data
    } catch (error) {
        setAlertMsg(error?.response?.data?.message)
    }
}



const blogServices = {
    createBlog,
    getAllBlogs,
    getBlog,
    updateBlog,
    updateBlogStatus
};

export default blogServices;
