import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Input from "../../../components/Input/Input"
import FormTextarea from "../../../components/FormTextarea/FormTextarea"
import DropDown from '../../../components/DropDown/DropDown'
import ImageInput from '../../../components/ImageInput/ImageInput'
import auctionServices, { deleteVirtualAsset, reqEditVirtualItem } from '../../../../services/auction-services'
import checkImageBeforeUpload from "../../../../utils/checkImageBeforeUpload"

import "./AuctionItem.css"
import Modal from '../../../components/Modal/Modal'
import Alert from '../../../components/Alert/Alert'
import { setDefaultEndDate, setDefaultStartDate } from '../../../../utils/util-functions'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Checkbox, FormControlLabel, Radio, Tooltip, Typography } from '@mui/material'
import LoadingModal from '../../../components/LoadingModal/LoadingModal'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import usePrompt from '../../../hooks/usePrompt'
import { useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import DatePickerComponent from '../../../components/DatePicker/DatePicker';
import causeServices from '../../../../services/cause-services'
import Multiselect from 'multiselect-react-dropdown';
import constants, { COLORS_OBJECT } from '../../../../constants/en';
import RadioGroupComponent from '../../../components/RadioGroup/RadioGroup';
import AIGeneratedData from '../../../components/AIGeneratedData/AIGeneratedData'
import logger from '../../../../utils/logger';

const fileName = 'Items/AuctionItemEdit.jsx'

const redColor = COLORS_OBJECT.color[0]

const getExtention = (file_name) => file_name ? file_name?.split('.')[file_name.split('.')?.length - 1] : undefined;
const toLocalDate = (iso) => {
  const date = new Date(iso)
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  let dh = date.getHours();
  let dm = date.getMinutes();

  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }
  if (dh < 10) {
    dh = "0" + dh
  }
  if (dm < 10) {
    dm = "0" + dm
  }
  return year + '-' + month + '-' + dt + " " + dh + ":" + dm;
}
const AuctionItem = ({ categories, reload, godAccess }) => {
  const { start_date, end_date } = useSelector(state => state.dependencies)
  const { auction_id, item_id } = useParams()
  const [showAmountDialog, setShowAmountDialog] = useState(true)
  const [auctionItem, setAuctionItem] = useState({
    name: '',
    description: '',
    // condition: '',
    short_description: '',
    price: '',
    reserve_price: null,
    buy_now_price: null,
    auction_id: null,
    auction_item_category_id: null,
    custom_category_id: null,
    category_ids: [],
    custom_category_ids: [],
    // auction_item_category_xrefs:[],
    weight: "",
    height: "",
    width: "",
    length: "",
    upc: "",
    donor_id: null,
    start_date: null,
    end_date: null,
    auction_item_amount_given_dialog:null,
    cash_on_delivery: false,
    vendor_sku_id: ''
  });

  const navigate = useNavigate()

  const [itemType, setItemType] = useState("physical")
  const [openShipping, setOpenShipping] = useState(false)
  const [openDonor, setOpenDonor] = useState(false)
  const [formError, setFormError] = useState(null)
  const [virtualForm, setVirtualForm] = useState(null)
  const [isFlatRate, setIsFlatRate] = useState(false)

  const [hasUPC, setHasUPC] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // const [imageObjects1, setImageObjects1] = useState("")
  const [imageObjects2, setImageObjects2] = useState([])
  // const [portraitImage, setPortraitImage] = useState(null);
  const [landscapeImages, setLandscapeImages] = useState([]);
  // const [portraitImageLoading, setPortraitImageLoading] = useState(false)
  const [landscapeImageLoading, setLandscapeImageLoading] = useState(false)
  const [showPrompt, setShowPrompt] = useState(false)
  const [donorsList, setDonorsList] = useState([])
  const [updateDonors, setUpdateDonors] = useState(true)
  const [localDonor, setLocalDonor] = useState({
    "org_name": "",
    "about": ""
  })
  const [selectedDonor, setSelectedDonor] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState([])
  const [reservedSelected, setReservedSelected] = useState(null)
  const [buyNowSelected, setBuyNowSelected] = useState(null)
  const [selectedItemType, setSelectedItemType] = useState(null)
  const [navigateAction, setNavigateAction] = useState('another')


  const [shipmentType, setShipmentType] = useState(false)
  const [ediShipment, setEdiShipment] = useState(false);
  const [pickupType, setPickupType] = useState(false)
  const [allowMultiple, setAllowMultiple] = useState(true)

  usePrompt("Do you want to leave? You will lose your changes!", showPrompt)

  const location = useLocation()
  const [virtualFile, setVirtualFile] = useState(false);
  const [virtualText, setVirtualText] = useState("");
  const [virtualFiles, setVirtualFiles] = useState([]);
  const [virtualTextUpdated, setVirtualTextUpdated] = useState(false);
  const [newVirtualFiles, setNewVirtualFiles] = useState([]);
  const [virtualTextDocID, setVirtualTextDocID] = useState(null);
  const [canChooseCause,setCanChooseCause] = useState(false);
  const [assignedcauses,setAssignedCauses] = useState([]);
  let [selectedCauses,setSelectedCauses] = useState([]);
  const [unAssignedCauses,setUnassignedCauses] = useState([]);
  const [removeCauseModal,setRemoveCauseModal] = useState(false)
  const [preSelectedCauses,setPreSelectedCauses]= useState([])
  const [defaultCauses,setDefaultCauses] = useState(null)
  const [selectedDefaultCauseID,setSelectedDefaultCauseId] = useState(null)
  const [causeId,setCauseId] = useState(null)
  let can_choose_cause = useRef();
  const [whiteborderPortraitAlert,setWhiteborderPortraitAlert] = useState(null);
  const [whiteborderLandScapeAlert,setWhiteborderLandScapeAlert] = useState(null);
  const [portraitEvent,setPortraitEvent] = useState(null);
  const [landScapeEvents,setLandScapeEvents] =useState(null) 
  const [categoryXrefData, setCategoryXrefData] = useState([])
  const [categorySelectDisable, setCategorySelectDisable] = useState(false)
  const [aiAnalysis, setAiAnalysis] = useState({
    modalIsOpen:false,
    aiData:null,
    isUsed:false
  })

  useEffect(() => {
    if(!landscapeImages?.length && !imageObjects2?.length) {
      setAiAnalysis({...aiAnalysis, isUsed:false})
    }
  },[landscapeImages, imageObjects2])

  let forCharity=false
  if(location.pathname.startsWith('/admin/market-place/items')){
    forCharity=true
  }

  const fetchAuctionItem = async () => {
    try {
      const data = await auctionServices.getEventItemById(auction_id, item_id)
    const item = data.data
    setAuctionItem({
      ...auctionItem,
      ...item,
      name: item.name,
      description: item.description,
      short_description: item.short_description,
      price: item.price,
      reserve_price: item.reserve_price,
      buy_now_price: item.buy_now_price,
      auction_id: item.auction_id,
      auction_item_category_id: item.auction_item_category_id,
      custom_category_id: item.custom_category_id,
      weight: item.weight,
      height: item.height,
      length: item.length,
      width: item.width,
      upc: item.upc,
      donor_id: item.donor_id,
      auction_status: item.auction.status,
      start_date: moment(item.start_date).format("YYYY-MM-DDTHH:mm"),
      end_date: moment(item.end_date).format("YYYY-MM-DDTHH:mm"),
      auction_item_amount_given_dialog:item?.auction_item_amount_given_dialog,
      // auction_item_category_xrefs: item?.auction_item_category_xrefs,
      cash_on_delivery: item?.cash_on_delivery === 1
    })
    if(item?.auction?.cause && item?.auction?.cause?.id) {
      setSelectedDefaultCauseId([{label:item?.auction?.cause?.legal_name, value:item?.auction?.cause?.id}])
      setCauseId(item?.auction?.cause?.id)
    }
    if(item?.auction_item_category_xrefs?.length) {
      setCategoryXrefData(item?.auction_item_category_xrefs)
    }
    if(item?.auction_item_amount_given_dialog === 1){
      setShowAmountDialog(true)
    }
    else{
      setShowAmountDialog(false)
    }
    if(item.can_choose_cause === 1){
      setCanChooseCause(true)
      can_choose_cause.current = true
    }else{
      can_choose_cause.current = false
    }
    if (item.reserve_price) {
      setReservedSelected({ value: true, label: "Yes" })
    } else {
      setReservedSelected({ value: false, label: "No" })
    }
    if (item.buy_now_price) {
      setBuyNowSelected({ value: true, label: "Yes" })
    } else {
      setBuyNowSelected({ value: false, label: "No" })
    }
    if (item.is_virtual == 0) {
      setItemType("physical")
      setSelectedItemType({ value: "physical", label: "Physical Product" })
      // item?.fulfilment?.name === 'advatix' ? setShipmentType(false) : setShipmentType(true)
      if(item?.fulfilment?.name === 'advatix') {
        setShipmentType(false);
        setEdiShipment(false);
      }
      else {
        if(item?.fulfilment?.name === 'edi') {
          setEdiShipment(true);
          setShipmentType(false);
        }
        else {
          setShipmentType(true);
          setEdiShipment(false);
        }
      }
      if (item?.fulfilment?.name === 'self' && item?.fulfilment?.pickup_type === 'pickup') {
        setPickupType(true)
      }
      item?.fulfilment?.allow_multiple == 0 ? setAllowMultiple(false) : setAllowMultiple(true)
    } else {
      setItemType("virtual")
      setSelectedItemType({ value: "virtual", label: "Virtual Product" });
      let files = item.virtual_doc?.filter((item, i) => item.asset_type !== "instructions");
      if(files?.length) {
        setVirtualFiles(files)
      }
      let textData = item.virtual_doc?.filter((item, i) => item.asset_type === "instructions");
      if(textData?.length) {
        setVirtualText(textData[0].name)
        setVirtualTextDocID(textData[0].id)
      }
    }
    if (item.is_flat_rate_shipping === 1) {
      setIsFlatRate(true)
    }
    if (item.upc) {
      setHasUPC(true)
    }
    setLandscapeImages(item?.assets.filter(img => img.context_type === 'portrait'))
    // setPortraitImage(item?.assets.filter(img => img.context_type === 'portrait'))
    }
    catch(error) {
      logger(fileName,'Error found on Editing Auction Item', error)
    }
  }

  const getAssignedCauses= async () =>{
    try {
      const data= await causeServices.getCausesRelatedtoItem(item_id)
      const causes = data?.data.map((obj,i)=>{
       return {
         key:obj.cause.legal_name
         ,value:obj.cause_id}
     })
     setPreSelectedCauses(causes)
     setAssignedCauses(data.data)
     if( !can_choose_cause.current && data?.data.length === 1){
         setSelectedDefaultCauseId([{label:data?.data[0].cause.legal_name,value:data?.data[0].cause_id}])
         setCauseId(data?.data[0].cause_id)
     }
    }catch(error) {}
  }

  const getUnAssignedCauses = async () =>{
    try{
      const data= await causeServices.getCausesNotRelatedtoItem(item_id)
      let newCauses = data.data.map((obj,i)=>{
        return {
          key:obj.cause.legal_name
          ,value:obj.cause_id}
      })
    
      setUnassignedCauses(newCauses)
    }catch(error) {}
  }

  useEffect(() => {
    fetchAuctionItem()
    getAssignedCauses()
  }, [])

  useEffect(()=>{
    (async () => {
      const data = await causeServices.getUserCauses();
      setDefaultCauses(data?.data.map((obj,i)=>{
        return {
          label: obj.cause.legal_name,
          value: obj.cause_id
        }
      }))
    })()
    getUnAssignedCauses()
  },[assignedcauses])

  // useEffect(() => {
  //   if (!selectedCategory) {
  //     if (auctionItem.auction_item_category_id) {
  //       categories.forEach(c => {
  //         if (c.value.id === auctionItem.auction_item_category_id && c.value.type === 'global') {
  //           setSelectedCategory(c)
  //         }
  //       })
  //     }
  //     else if (auctionItem.custom_category_id) {
  //       categories.forEach(c => {
  //         if (c.value.id === auctionItem.custom_category_id && c.value.type === 'custom') {
  //           setSelectedCategory(c)
  //         }
  //       })
  //     }
  //   }
  // }, [categories, auctionItem.auction_item_category_id, auctionItem.custom_category_id])

  useEffect(() => {
    if(categoryXrefData?.length) {
      console.log('if one')
    categoryXrefData?.map((category) => {
      if((category?.category_id && category?.category_id === 16) || auctionItem.category_ids?.includes(16) ) {
        setAuctionItem((prevAuctionItem) => ({
          ...prevAuctionItem,
          category_ids: [16],
          custom_category_ids: []
        }))
       setSelectedCategory(categories.filter((cat)=> cat.value === 16)) 
       setCategorySelectDisable(true)
      }
      else {
      setCategorySelectDisable(false)
      if(category?.category_id) {
        categories.forEach(cat => {
          if (cat?.value === category?.category_id && cat?.type === 'global' && !auctionItem.category_ids.includes(cat.value)) {
            setSelectedCategory((prevSelectedCategory) => [
              ...prevSelectedCategory,
              cat,
            ])
            setAuctionItem((prevAuctionItem) => ({
              ...prevAuctionItem,
              category_ids: [...prevAuctionItem.category_ids, cat.value],
            }))
          }
        })
      }
      if(category?.custom_category_id) {
        categories.forEach(cat => {
          if (cat.value === category?.custom_category_id && cat?.type === 'custom' && !auctionItem.custom_category_ids.includes(cat.value)) {
            setSelectedCategory((prevSelectedCategory) => [
              ...prevSelectedCategory,
              cat,
            ])
            setAuctionItem((prevAuctionItem) => ({
              ...prevAuctionItem,
              custom_category_ids: [...prevAuctionItem.custom_category_ids, cat.value],
            }))
          }
        })
      }
    }
     })
     }
     else {
      if(auctionItem.auction_item_category_id) {
         const newCategories = categories?.filter((cat) => cat.value === auctionItem.auction_item_category_id)
         setSelectedCategory(newCategories)
          setAuctionItem({
            ...auctionItem,
            category_ids: [auctionItem.auction_item_category_id]
          })
          if(auctionItem.auction_item_category_id === 16) setCategorySelectDisable(true)
          else setCategorySelectDisable(false)
      }
      if(auctionItem.custom_category_id) {
        const newCategories = categories?.filter((cat) => cat.value === auctionItem.custom_category_id)
        setSelectedCategory(newCategories)
        setAuctionItem({
          ...auctionItem,
          custom_category_ids: [auctionItem.custom_category_id]
        })
      }
     }
  }, [categories, categoryXrefData])

  const handleDataChange = (e) => {
    // if(e.target.name === 'start_date' || e.target.name === 'end_date') {
    //   if(!e.target.valueAsDate) {
    //     return
    //   }
    // }
    setShowPrompt(true)
    setAuctionItem({
      ...auctionItem,
      [e.target.name]: e.target.value
    })
  }

  const handleDate = (e) => {

    if (!e.target.valueAsDate) {
      return
    }
    setShowPrompt(true)
    setAuctionItem({
      ...auctionItem,
      [e.target.name]: e.target.value
    })
  }

  const handleDonorChange = (e) => {
    setShowPrompt(true)
    setAuctionItem({ ...auctionItem, donor_id: e.value })
    setSelectedDonor(e)
    if(e.sps_edi_trading_partner_id === null) {
      setEdiShipment(false)
    }
  }

  const handlefileChange = (e) => {
    if ((e.target.files.length + virtualFiles.length) > 5) {
      alert(constants.ADMIN_COPY_ITEM_VIRTUAL_FILE_MAX)
      return
    }
    else {
      setNewVirtualFiles((prev) => {
        return [...prev, ...e.target.files]
      })
    }
  }



  async function addDonors() {
    try {
      const data = await auctionServices.getdonorsListbyAuctionId(auction_id)
      setDonorsList(data.donors.map((i) => {
        if (i.email) {
          return {
            label: `${i.contact_firstname} ${i.contact_lastname} - ${i.org_name}`,
            value: i.id,
            sps_edi_trading_partner_id: i?.sps_edi_trading_partner_id
          }
        }
        return {
          label: i.org_name, value: i.id, sps_edi_trading_partner_id: i?.sps_edi_trading_partner_id
        }
      }));
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    donorsList.forEach(i => {
      if (i.value === auctionItem.donor_id)
        setSelectedDonor(i)
    })
  }, [auctionItem, donorsList])

  useEffect(() => {
    addDonors()
  }, [updateDonors])

const handelRemoveCause = async (cause_id) =>{
  const data = await causeServices.removeCauseFromItem(item_id,cause_id);
  setAssignedCauses(assignedcauses.filter((cause,i)=> cause.cause_id !== cause_id));
  setPreSelectedCauses(preSelectedCauses.filter((obj,i)=>obj.value !== cause_id))
}

//   const handlePortraitImage = async (e,confirm) => {
//     try{
//       if (!e.target.files[0]) return
//     setShowPrompt(true)
//     setPortraitImageLoading(true)
//     const image = e.target.files[0]
//     if (image.size > 5000000) {
//       setPortraitImageLoading(false)
//       setFormError(constants.ADMIN_COPY_ITEM_IMG_FILE_SIZE);
//       return
//     }
//     const imageUrl = URL.createObjectURL(image)
//     const { width, height } = await checkImageBeforeUpload(imageUrl)
//     if (width < 300 || height < 300) {
//       setPortraitImageLoading(false)
//       setFormError(constants.ADMIN_COPY_ITEM_IMG_POTRAIT_SIZE)
//       return
//     }
//     if(!confirm){
//       if(width/height < 0.98 || width/height > 1){
//         setPortraitEvent(e);
//         setPortraitImageLoading(false)
//         setWhiteborderPortraitAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','1:1'));
//         return
//       }
//     }else{
//       setPortraitEvent(null)
//     }
//     const formData = new FormData()
//     formData.append("image", image)
//     formData.append("width", 300)
//     formData.append("height", 300)
//     const preProcessedImage = await auctionServices.imagePreProcess(formData)
//     setPortraitImage([{ name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img' }])
//     const uint8 = new Uint8Array(preProcessedImage.buffer.data)
//     const newBlob = new Blob([uint8], { type: "image/jpeg" })
//     setImageObjects1(newBlob)
//     setPortraitImageLoading(false)
//     }
//     catch(error){
//     setPortraitImageLoading(false)
//     }
//   }


  const handleLandscapeImage = async (e,confirm) => {
    try{
      if (e.target.files.length === 0) return
    setShowPrompt(true)
    setLandscapeImageLoading(true)
    const filesUrl = []
    const selectedFiles = []
    const targetFilesObj = [...e.target.files]
    const preProcessedFiles = []
    if (targetFilesObj.length > 4 || landscapeImages.length === 4 || (landscapeImages.length + targetFilesObj.length) > 4) {
      setLandscapeImageLoading(false)
      setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE_MAX);
      return
    }
    if (targetFilesObj.every((currentValue) => currentValue.size <= 5000000)) {
      targetFilesObj.map((file) => {
        return filesUrl.push({ name: URL.createObjectURL(file), type: 'img', local: true });
      });
      let invalidAspectRatio = false
      let invalidImage = false;
      for (const url of filesUrl) {
        const { width, height } = await checkImageBeforeUpload(url.name)
        if (width < 600 || height < 600) {
          invalidImage = true
        }
        if(width/height < 0.98 || width/height > 1){
          invalidAspectRatio = true
        }
      }
      if (invalidImage) {
        setLandscapeImageLoading(false)
        setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE_MAX_SIZE)
        return
      }
      if(!confirm){
        if(invalidAspectRatio){
          setLandScapeEvents(e);
          setLandscapeImageLoading(false);
          setWhiteborderLandScapeAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','1:1'));
          return
        }
      }else{
        setLandScapeEvents(null);
      }

      for (const image of targetFilesObj) {
        const formData = new FormData()
        formData.append("image", image)
        formData.append("width", 1024)
        formData.append("height", 1024)
        const preProcessedImage = await auctionServices.imagePreProcess(formData)
        selectedFiles.push({ name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img', local: true });
        const uint8 = new Uint8Array(preProcessedImage.buffer.data)
        const newBlob = new Blob([uint8], { type: "image/jpeg" })
        preProcessedFiles.push({ blob: newBlob, url: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}` })
      }

      setLandscapeImages([...landscapeImages, ...selectedFiles])
      setImageObjects2([...imageObjects2, ...preProcessedFiles])
      setLandscapeImageLoading(false)
    } else {
      setFormError(constants.ADMIN_COPY_ITEM_IMG_FILE_SIZE);
      setLandscapeImageLoading(false)
      return
    }
    }
    catch(error){
      setLandscapeImageLoading(false)
    }
  }

  const handlePrepareAsset = () => {
    const form = new FormData()
    // form.append("imageData0", imageObjects1)
    const type = ['img']

    for (let [i, l] of imageObjects2.entries()) {
      form.append(`landscapeImg`, l.blob)
      type.push('img')
    }
    form.append('type', JSON.stringify(type))
    form.append("auction_item_id", item_id)
    return form
  }

  const handleSubmitError = (error) => {
    if (error.response && error.response.status === 400 && error.response.data) {
      const data = error.response.data;
      if (data.data?.length > 0) {
        setFormError(`${data.data[0].param} - ${data.data[0].msg}`)
      }
      return;
    }
  }

  const checkValidity = () => {
    // if (!portraitImage) {
    //   setFormError(constants.ADMIN_COPY_ITEM_IMG_POTRAIT)
    //   return true
    // }
    if(ediShipment) {
      if(!auctionItem?.vendor_sku_id || auctionItem?.vendor_sku_id?.trim() === '') {
        setFormError(constants.ADMIN_COPY_ITEM_VENDOR_SKU_ID_ALERT)
        return true
      }
    }
    if(auctionItem?.vendor_sku_id?.length && auctionItem?.vendor_sku_id?.includes(" ")) {
      setFormError(constants.ADMIN_COPY_ITEM_VENDOR_SKU_ID_EMPTY_ALERT)
        return true
    }
    if (landscapeImages.length === 0) {
      setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE)
      return true
    }
    if(!auctionItem.category_ids.length && !auctionItem.custom_category_ids.length) {
      setFormError(constants.ADMIN_COPY_ITEM_CATEGORY)
      return true
    }
    if (itemType === 'physical') {
      if ((!auctionItem?.weight || auctionItem?.weight == 0) && !pickupType) {
        setFormError(constants.ADMIN_COPY_ITEM_PHYSICAL_PROPERTY)
        return true
      }
      return false
    }
    return false
  }
  const createVirtualAsset = (files) => {
    const form = new FormData();
    let type = [];
    let filename = [];
    files.length > 0 && files.forEach(obj => {
      const fln = obj.name;
      const ext = getExtention(fln)
      form.append("asset", obj)
      type.push(ext);
      filename.push(obj.name)
    });
    form.append("type", JSON.stringify(type))
    form.append("file_name", JSON.stringify(filename))
    return form;
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (checkValidity()) return
    if(!location?.pathname.startsWith('/admin/store/')) {
    if(canChooseCause && assignedcauses.length === 0 && selectedCauses.length == 0){
      setFormError(constants.ADMIN_COPY_ITEM_SELECT_CAUSE)
      return
    }

    if(!canChooseCause  && !causeId && !location.pathname.startsWith('/admin/market-place/items')){
      setFormError(constants.ADMIN_COPY_ITEM_SELECT_CAUSE)
      return
    }
  }
    if( !can_choose_cause.current && assignedcauses.length > 0 && canChooseCause && causeId){
       selectedCauses = (selectedCauses.filter((obj,i)=> obj.value !== causeId))
    }
    if( can_choose_cause.current && assignedcauses.length > 0 && !canChooseCause){
        for(let cause of assignedcauses){
          await causeServices.removeCauseFromItem(item_id,cause?.cause_id)
        }
    }
    let payload = {
      ...auctionItem,
      name:auctionItem.name?.replace(/\s+/g, " ").trim(),
      auction_id: +auction_id,
      start_date: setDefaultStartDate(auctionItem.start_date),
      end_date: setDefaultEndDate(auctionItem.end_date),
      created: Date.now(),
      is_virtual: itemType === 'physical' ? 0 : 1,
      height: 0,
      width: 0,
      length: 0,
      weight: (itemType === 'physical' && !pickupType) ? +auctionItem.weight : 0,
      causes: JSON.stringify(selectedCauses),
      can_choose_cause: (canChooseCause) ? 1 : 0,
      cause_id: causeId,
      auction_item_amount_given_dialog:showAmountDialog ? 1 : 0,
      cash_on_delivery: auctionItem?.cash_on_delivery ? 1 : 0
    };

    if(auctionItem.donor_id === null && !location.pathname.startsWith('/admin/market-place/items')){
     setFormError(constants.ADMIN_COPY_ITEM_DONOR)
      return
    }
    // if(auctionItem.auction_item_category_id === null && auctionItem.custom_category_id === null){
    //   setFormError(constants.ADMIN_COPY_ITEM_CATEGORY)
    //   return 
    // }
    if (!hasUPC || payload.upc.length < 2) {
      delete payload.upc
    }
    if (!reservedSelected.value) {
      payload.reserve_price = null
    }
    if (!buyNowSelected.value) {
      payload.buy_now_price = null
    }
    if (itemType === 'physical') {
      payload['is_flat_rate_shipping'] = isFlatRate ? 1 : 0
      payload.fulfilment_name = shipmentType ? 'self' : ediShipment ? 'edi' : 'advatix';
      payload.pickup_type = pickupType ? 'pickup' : 'ship';
      payload.allow_multiple = allowMultiple ? 1 : 0;
    } else {
      payload['is_flat_rate_shipping'] = 0
      payload.fulfilment_name = 'virtual';
      payload.pickup_type = 'virtual';
      payload.allow_multiple = 1;
    }
    if (payload.reserve_price && +payload.reserve_price < +payload.price) {
      setFormError(constants.ADMIN_COPY_ITEM_RESERVE_PRICE_ERROR)
      return
    }
    if (payload.buy_now_price &&
      (+payload.buy_now_price < +payload.price ||
        +payload.buy_now_price < +payload.reserve_price)) {
      setFormError(constants.ADMIN_COPY_ITEM_BUY_NOW_PRICE)
      return
    }
    if (itemType === "virtual") {
      if (virtualText === "" && virtualFiles.length === 0) {
        if (newVirtualFiles.length === 0) {
          setFormError(constants.ADMIN_COPY_VIRTUAL_ITEM)
          return
        }
      }
    }
    if (payload) {
      setIsLoading(true);
      setShowPrompt(false)
      try {
        await auctionServices.reqEditItem(auction_id, item_id, payload, "update-auction-item", forCharity)
        const assetData = handlePrepareAsset()
        if (assetData) {
          await auctionServices.updateItemAsset(assetData)
        }
        if (itemType === 'virtual') {
          if (virtualTextUpdated) {
            const body = {
              name: virtualText,
              virtualDocId: virtualTextDocID
            }
            await reqEditVirtualItem(auction_id, item_id, body);
          }
          if (newVirtualFiles.length > 0) {
            const formData = createVirtualAsset(newVirtualFiles);
            await auctionServices.uploadVirItem(formData, auction_id, item_id);
          }
        }
        setIsLoading(false)
        if (navigateAction === 'another') {
          // navigate("/admin/items")
          if(location.pathname.startsWith('/admin/items')) navigate('/admin/items')
          else if(location.pathname.startsWith('/admin/market-place')) navigate('/admin/market-place/items')
          else navigate('/admin/store/items')
        }
        else if (navigateAction === "anotherItem"){
          if(location.pathname.startsWith('/admin/items')) navigate("/admin/items/add")
          else if(location.pathname.startsWith('/admin/market-place')) navigate('/admin/market-place/items/add')
          else navigate(`/admin/store/items/add`)
        }
        else {
          // navigate(`/admin/event-preview/${auction_id}`)
          if(location.pathname.startsWith('/admin/items')) navigate(`/admin/event-preview/${auction_id}`)
          else if(location.pathname.startsWith('/admin/market-place')) navigate(`/admin/market-place-preview/${auction_id}`)
          else navigate(`/admin/store-preview/${auction_id}`)
        }
      } catch (error) {
        setIsLoading(false);
        setShowPrompt(true);
        handleSubmitError(error)
        setFormError(error.response.data.message)
        // console.log(error,"form error to checkvalidation")
      }
    }
  }

  const handleCategoryChange = (e) => {
    // if (e.value.type === 'global') {
    //   setAuctionItem({
    //     ...auctionItem,
    //     auction_item_category_id: e.value.id,
    //     custom_category_id: null
    //   })
    // } else if (e.value.type === 'custom') {
    //   setAuctionItem({
    //     ...auctionItem,
    //     custom_category_id: e.value.id,
    //     auction_item_category_id: null
    //   })
    // }
    e?.map((item) => {
      if(item.value == 16 || auctionItem.category_ids?.includes(16)) {
        setAuctionItem((prevAuctionItem) => ({
          ...prevAuctionItem,
          category_ids: [16],
          custom_category_ids: []
        }))
       setSelectedCategory(e.filter((cat)=> cat.value === 16)) 
       setCategorySelectDisable(true)
     }
     else {
      setCategorySelectDisable(false)
      if (item.type === 'global' && !auctionItem.category_ids?.includes(item.value) && !auctionItem.category_ids?.includes(16)  ) {
        setAuctionItem((prevAuctionItem) => ({
          ...prevAuctionItem,
          category_ids: [...prevAuctionItem.category_ids, item.value],
        }))
      } else if (item.type === 'custom' && !auctionItem.custom_category_ids?.includes(item.value) && !auctionItem.category_ids?.includes(16) ) {
        setAuctionItem((prevAuctionItem) => ({
          ...prevAuctionItem,
          custom_category_ids: [...prevAuctionItem.custom_category_ids, item.value],
        }))
      }
      setSelectedCategory(e)
    }
    })
    setShowPrompt(true)
  }

  const handleCategoryRemove = async(e, cat) => {
        let deleteCatPayload = {
          auction_item_id: item_id,
          category_id: cat?.value
        }
        if(cat?.type === 'custom') {
          deleteCatPayload = {
            auction_item_id: item_id,
            custom_category_id: cat?.value
          }
        }
        try {
          setIsLoading(true)
          await auctionServices.deleteAuctionItemCategoryXref(deleteCatPayload, auction_id)
          setIsLoading(false)
        }catch(error) {
          setIsLoading(false)
        }
        const globalCategory = e.filter((catItem, index) => (e.indexOf(catItem) === index) && catItem.type === 'global').map((item) => item.value);
        setAuctionItem((prevAuctionItem) => ({
          ...prevAuctionItem,
          category_ids: globalCategory
        }))
        const customCategory = e.filter((catItem,index) => (e.indexOf(catItem) === index) && catItem.type === 'custom').map((item) => item.value);
        setAuctionItem((prevAuctionItem) => ({
          ...prevAuctionItem,
          custom_category_ids: customCategory
        }))
    setSelectedCategory(e)
  }

  const handleLocalDonor = async () => {
    setShowPrompt(true)
    try {
      setIsLoading(true)
      if(localDonor.org_name?.length < 1 || localDonor.about?.length < 1){
        setIsLoading(false)
        // setFormError("Please fill all fields")
        alert(constants.ADMIN_COPY_ITEM_LOCAL_DONOR_FIELD)
        return
      }
      if(localDonor.org_name?.trim()?.length < 2) {
        setIsLoading(false)
        alert(constants.ADMIN_COPY_ITEM_LOCAL_DONOR_ORG_NAME )
        return
      }
      if(localDonor.about?.trim()?.length < 2) {
        setIsLoading(false)
        alert(constants.ADMIN_COPY_ITEM_LOCAL_DONOR_ABOUT)
        return
      }
      const data = await auctionServices.createLocalDonor({ org_name: localDonor.org_name, about: localDonor.about,auction_id:auction_id })
      setUpdateDonors(!updateDonors)
      setLocalDonor({
        org_name: "",
        about: ""
      })
      setSelectedDonor({
        label: data.org_name,
        id: data.id
      })
      setAuctionItem({
        ...auctionItem,
        donor_id: data.id
      })
      setIsLoading(false)
      setOpenDonor(false)
    } catch (error) {
      if(error?.response?.data?.data?.length) {
        setFormError(error?.response?.data?.data[0].msg || constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
      }
      else {
        setFormError(error.response.data.message)
      }
      setOpenDonor(false)
      setLocalDonor({
        org_name: "",
        about: ""
      })
      setIsLoading(false)
    }
  }

  const deleteImage = async (url, imageIdx) => {
    await auctionServices.deleteItemAsset(item_id, url)
    setLandscapeImages(landscapeImages.filter((i, idx) => idx !== imageIdx))
    setImageObjects2(imageObjects2.filter((o, idx) => o.url !== url))
  }

  // const deletePortrait = async (url, imageIdx) => {
  //   setPortraitImage(null)
  //   setImageObjects1("")
  // }

  const deleteFile = async (file, index) => {
    await deleteVirtualAsset(file.auction_item_id, file.name);
    setVirtualFiles(virtualFiles.filter((i, idx) => idx != index))
  }

  const handleEmailText = (e) => {
    setVirtualTextUpdated(true)
    setVirtualText(e.target.value)
  }


  const handleStartDateChange = (value) => {
    const date = moment(value).format("YYYY-MM-DDTHH:mm")
    setAuctionItem({
      ...auctionItem,
      start_date: date
    })
  }

  const handleEndDateChange = (value) => {
    const date = moment(value).format("YYYY-MM-DDTHH:mm")
    setAuctionItem({
      ...auctionItem,
      end_date: date
    })
  }

  const handleReserveDropdownChange = (e) => {
    if(!reservedSelected.value) {
      setAuctionItem({
        ...auctionItem,
        reserve_price:null
      })
    }
    setReservedSelected(e)
  }

  const handleBuyNowDropDownChange = (e) => {
    if(!buyNowSelected.value){
        setAuctionItem({
          ...auctionItem,
          buy_now_price:null
     })
    }
    setBuyNowSelected(e)
  }

  const handleCauseChange = (e) =>{
    setSelectedDefaultCauseId(e)
    setCauseId(e?.value)
  }

  const onDialogChange = (e) => {
    setShowAmountDialog(e)
}

const handelShipment = (shipType) => {
  switch (shipType) {
    case 'shipment':
      if(shipmentType && !godAccess) {
        // if not god user then we are not allowing them to make it advatix
        setFormError(constants.ADMIN_COPY_NORMAL_USER_SHIPPING_OPTION_ALERT)
      }
      else {
        setShipmentType(!shipmentType)
        setEdiShipment(false);
      }
      break;
    case 'ediShipment':
      if(!forCharity || !auctionItem?.donor?.sps_edi_trading_partner_id) {
        if(!selectedDonor) {
          setFormError(constants.ADMIN_COPY_EDI_SHIPMENT_ALERT)
          return
        }
        if(!selectedDonor?.sps_edi_trading_partner_id || selectedDonor?.sps_edi_trading_partner_id === null || selectedDonor?.sps_edi_trading_partner_id === undefined) {
          setFormError(constants.ADMIN_COPY_EDI_SHIPMENT_ALERT)
          return
        }
      }
      setEdiShipment(!ediShipment);
      setShipmentType(false)
      break;
    default:
      break;
  }
}

const handleAiAnalysisClicked = async() => {
  if (imageObjects2.length === 0 && landscapeImages?.length === 0) {
    setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE)
    return true
  }
  try {
    setIsLoading(true)
    const formData = new FormData()
    let newBlob;
      if(landscapeImages[0]?.name?.startsWith('data:image/')) {
        // newBlob = new Blob([landscapeImages[0]], { type: "image/jpeg" })
           newBlob = imageObjects2[0]?.blob
      }
      else {
        newBlob = JSON.stringify(landscapeImages[0])
      }
    formData.append('image', newBlob)
    const response = await auctionServices.generateImageDetails(formData)
    // if(response?.data?.status === 'success') {
    if(response?.data?.description?.length) {
        setAiAnalysis({modalIsOpen:true, aiData:response?.data, isUsed:true})
    }
    // setAiAnalysis({...aiAnalysis, modalIsOpen:true, isUsed:true})
    setIsLoading(false)
  }catch(error) {
    setIsLoading(false)
    setAiAnalysis({modalIsOpen:false, aiData:null, isUsed:false})
    setFormError(error?.response?.data?.message || constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
  }
}

const handleGeneratedData = (data=null) => {
  if(!data) return;
  setShowPrompt(true)
  setAuctionItem({...auctionItem, description: data?.description})
  setAiAnalysis({...aiAnalysis, modalIsOpen:false, isUsed:true})
}

const showAiGeneratedData = () => {
  setAiAnalysis({...aiAnalysis, modalIsOpen:true})
}

const onCashOnDeliveryChange = (e) => {
  setAuctionItem({...auctionItem, cash_on_delivery:e})
}

  return (
    <form onSubmit={handleSubmit} className="aitem-wrapper">
      <div className='form-top-actions'>
      {
        !aiAnalysis.isUsed ?
        <button onClick={handleAiAnalysisClicked} type='button' className='aitem-modal-btn'>AI Analysis</button>
        :
        <button onClick={showAiGeneratedData} type='button' className='aitem-modal-btn'>Show AI Generated Data</button>
      }
      {/* <button onClick={handleAiAnalysisClicked} type='button' className='aitem-modal-btn'>AI Analysis</button> */}
      </div>
      <div className='aitem-container'>
        <div>
          <div className='aitem-section-head'>
            General
            <hr />
          </div>
          <Input label="Auction Item Title" value={auctionItem.name} name="name" onChange={handleDataChange} required />
            <div>
              {/* <DropDown value={selectedCategory} isSearchable onChange={handleCategoryChange} options={categories} label="Choose Category" required /> */}
              <label className='input-label' htmlFor="">Choose Category</label>
              <Multiselect 
                displayValue="key"
                onRemove={(e, item)=>handleCategoryRemove(e, item)}
                onKeyPressFn={function noRefCheck(){}}
                onSelect={(e)=>handleCategoryChange(e)}
                options={categories}
                showArrow
                avoidHighlightFirstOption
                placeholder="Choose Category"
                selectionLimit={categorySelectDisable ? 1 : null}
                selectedValues={selectedCategory}
              /> 
            </div>
          <div className='aitem-two-inputs' style={{ gap: '10px' }}>
            { !location.pathname.startsWith('/admin/market-place')
             ? 
            <div style={{ flex: 3, display: "flex", alignItems: "flex-end", gap: "10px" }}>
              <DropDown value={selectedDonor} isSearchable onChange={handleDonorChange} options={donorsList} label="Donor" required />
              <button onClick={() => setOpenDonor(true)} type='button' className='aitem-modal-btn'>Add Donor</button>
            </div>
            : null 
          }
          </div>
        {location.pathname.includes('/admin/store') ? null : 
          <div style={{marginTop:"15px"}}>
          <label style={{ fontWeight:"600",fontSize:"0.85rem"}} >{constants.ADIMIN_COPY_CAN_CHOOSE_CAUSE}</label>
          <div style={{ display: "flex", gap: "20px" }}>        
            <FormControlLabel
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="Yes"
              checked={canChooseCause === true}
              onChange={()=>setCanChooseCause(true)}
              labelPlacement="end"
            />
            <FormControlLabel  
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="No"
              checked={canChooseCause === false}
              onChange={()=>setCanChooseCause(false)}
              labelPlacement="end"
            />
          </div>
         {
          (canChooseCause) ?  <Multiselect 
          disablePreSelectedValues
          displayValue="key"
          onRemove={(e)=>setSelectedCauses(e)}
          onKeyPressFn={function noRefCheck(){}}
          onSelect={(e)=>{setSelectedCauses(e)}}
          options={unAssignedCauses}
          showArrow
          avoidHighlightFirstOption
          placeholder="Select Causes"
          selectedValues={preSelectedCauses}
          selectionLimit={4}
        /> : <DropDown 
               value={selectedDefaultCauseID} 
               isSearchable 
               onChange={handleCauseChange} 
               options={defaultCauses} 
               label="Select Cause" 
              //  required={!location.pathname.includes('/market-place')? true : false} 
               required={true}
               isDisabled={location.pathname.includes('/market-place')} 
             />
         }
         {assignedcauses.length > 0 && canChooseCause && <button type="button" onClick={() => setRemoveCauseModal(true)} className='aitem-modal-btn' style={{ width: "100%",marginTop:"10px" }}>Manage assigned causes</button>}
          </div>}
          <div>

          </div>
          <FormTextarea iButton infoBubbleText='Add a short but clear description about your item' label="Item Short Description" value={auctionItem.short_description} name="short_description" onChange={handleDataChange} required rows={1} maxsize={75} />
          <FormTextarea iButton infoBubbleText='Describe your item in greater detail' label="Item Long Description" value={auctionItem.description} name="description" onChange={handleDataChange} required maxsize={1000} />
          <Input label="How much do you want to list this item for?" value={auctionItem.price} name="price" onChange={handleDataChange} required type="number"  step='any' />
          <div className='aitem-two-inputs'>
            <div>
              <DropDown menuPlacement='top' value={reservedSelected} onChange={(e) => handleReserveDropdownChange(e)} options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]} label="Does this item come with a Reserve?" labelStyle={{ fontSize: "0.75rem" }} required />
              {reservedSelected?.value && <Input label="Reserved Price" type="number" step='any'  value={auctionItem.reserve_price} name="reserve_price" onChange={handleDataChange} required />}
            </div>
            <div>
              <DropDown menuPlacement='top' value={buyNowSelected} onChange={(e) => handleBuyNowDropDownChange(e)} options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]} label="Do you want to add Buy Now option?" labelStyle={{ fontSize: "0.75rem" }} required />
              {buyNowSelected?.value && <Input label="Buy Now Price" type="number" step='any'  value={auctionItem.buy_now_price} name="buy_now_price" onChange={handleDataChange} required />}
            </div>
          </div>
           {/* }  */}
          <RadioGroupComponent 
           checked={showAmountDialog}
           onChange={onDialogChange}
           label={'Would you like to display a dialog that tells the buyer how much will go to cause?'}
         />
        </div>
        <div>
          <div className='aitem-section-head'>
            Auction period
            <hr />
          </div>
          {/* {auctionItem.start_date && */}
          <div className='item-dates'>
            {/* <Input type="datetime-local" min={moment(start_date).format("YYYY-MM-DDTHH:mm")} max={moment(end_date).format("YYYY-MM-DDTHH:mm")} required label="Start Date" value={auctionItem.start_date} onChange={handleDataChange} name="start_date"/>
              <Input  type="datetime-local" min={moment(auctionItem.start_date).format("YYYY-MM-DDTHH:mm")} max={moment(end_date).format("YYYY-MM-DDTHH:mm")} required label="End Date" value={auctionItem.end_date} onChange={handleDataChange} name="end_date" /> */}

            <DatePickerComponent disabled={moment(auctionItem.end_date) < moment() } date={auctionItem.start_date} minDateTime={moment(start_date).format("YYYY-MM-DDTHH:mm")} maxDateTime={moment(auctionItem.end_date ? auctionItem.end_date : end_date ).format("YYYY-MM-DDTHH:mm")}  onChange={handleStartDateChange} label={'Start Date'} />
            <DatePickerComponent disabled={moment(auctionItem.end_date) < moment() } date={auctionItem.end_date} minDateTime={moment(auctionItem.start_date).format("YYYY-MM-DDTHH:mm")} maxDateTime={moment(end_date).format("YYYY-MM-DDTHH:mm")}  onChange={handleEndDateChange} label={'End Date'} />
          </div>

          <Input
            iButton infoBubbleText={constants.ADMIN_COPY_ITEM_EDI_INFO}
            label="Vendor SKU ID" value={auctionItem.vendor_sku_id} name="vendor_sku_id" onChange={handleDataChange}
          />

          <RadioGroupComponent 
            checked={auctionItem.cash_on_delivery}
            onChange={onCashOnDeliveryChange}
            label={constants.ADMIN_COPY_ITEM_CASH_ON_DELIVERY_LABEL}
          />
          <div className='aitem-section-head'>
            Images
            <hr />
          </div>

          {/* <ImageInput loading={portraitImageLoading} required edit onDelete={deletePortrait} handleChange={(e)=>handlePortraitImage(e,false)} images={portraitImage ? portraitImage : []} label={constants.ADMIN_COPY_ITEM_THUMPNAIL_IMG} sidelabel="Max 1, Recomended size 300x300" max={1} /> */}
          <ImageInput loading={landscapeImageLoading} required onDelete={deleteImage} edit multiple handleChange={(e)=>handleLandscapeImage(e,false)} images={landscapeImages} label={constants.ADMIN_COPY_ITEM_DETAILS_IMG} sidelabel="Max 4, recommended size 1024x1024" max={4} />

          <div className='aitem-section-head' style={{ marginTop: "15px" }}>
            Shipping
            <hr />
          </div>
          <>
            {auctionItem.auction_status === 'live' ?
              <div style={{ display: "flex", gap: "20px" }}>
                {itemType === 'physical' ?
                  <FormControlLabel
                    value="physical"
                    control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
                    label="Physical Product"
                    checked={itemType === "physical"}
                    onChange={() => setItemType("physical")}
                    labelPlacement="end"
                  /> :
                  <FormControlLabel
                    value="Virtual"
                    control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
                    label="Virtual Product"
                    checked={itemType === "virtual"}
                    onChange={() => setItemType("virtual")}
                    labelPlacement="end"
                  />
                }
              </div> :
              <div style={{ display: "flex", gap: "20px" }}>
                <FormControlLabel
                  value="physical"
                  control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
                  label="Physical Product"
                  checked={itemType === "physical"}
                  onChange={() => setItemType("physical")}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Virtual"
                  control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
                  label="Virtual Product"
                  checked={itemType === "virtual"}
                  onChange={() => setItemType("virtual")}
                  labelPlacement="end"
                />
              </div>
            }
          </>
          <div>
          </div>
          {(itemType === 'virtual') && <>
            <FormTextarea label="Enter email body for how to receive or claim item" value={virtualText} name="description" maxsize={500} onChange={handleEmailText} iButton infoBubbleText='Please supply email instructions explaining how item winner/purchaser may receive or claim their purchase.' />
          </>}

          {(itemType === 'virtual') && <>
            <label className='input-label' style={{ marginBottom: 0, marginTop: "10px" }}>Upload attachments with additional instructions (if required)<Tooltip title={<Typography fontSize="0.85rem">Please upload a .pdf, .jpg or .png to send to the auction winner that includes the digital item or instructions for redemption</Typography>}><InfoOutlinedIcon sx={{ color: '#495057c4', fontSize: "16px", marginLeft: "3px" }} /></Tooltip></label>
            <input type='file' className='aitem-file-btn' style={{ marginTop: 0, marginBottom: "15px" }} multiple={true} onChange={handlefileChange} />
            <div>
              {
                (virtualFiles.map((file, i) => {
                  return (
                    <div style={{ textAlign: "left" }}>{file.original_file_name} <span onClick={() => deleteFile(file, i)}><ClearIcon style={{ color: "red", cursor: "pointer" }} /></span></div>
                  )
                }))
              }
            </div>
          </>
          }
          {itemType === 'physical' ? <button type="button" onClick={() => setOpenShipping(true)} className='aitem-modal-btn aitem-white-button' style={{ width: "100%" }}>Add Shipping/Pickup Details</button> : null}
          <hr />
          <div className='aitem-submit-container'>
            <button onClick={() => setNavigateAction("another")} type='submit'>Update</button>
            <button onClick={() => setNavigateAction("anotherItem")} type='submit'>Update & Add Another Item</button>
            <button onClick={() => setNavigateAction("finish")} type='submit'>Finished Updating Items? Update and Preview</button>
          </div>
        </div>
      </div>
      <div style={{ fontSize: "0.9rem", fontWeight: "600", padding: "0 10px" }}><span style={{ color: "red" }}>*</span> - required fields</div>
      <Modal isOpen={openShipping} onClose={() => setOpenShipping(false)} onDone={() => setOpenShipping(false)} title="Shipping Details">
        <div className='input-label' style={{ fontSize: "1.2rem", fontWeight: "600", borderBottom: "1px solid #d3d3d3" }}>Check all that applies</div>
        <FormControlLabel onChange={() => setIsFlatRate(!isFlatRate)} control={<Checkbox size="small" checked={isFlatRate} sx={{"&.Mui-checked": {color: redColor,}}} />} label="Flat Rate Shipping" />
        <br />
        <FormControlLabel onChange={() => handelShipment('ediShipment')} control={<Checkbox size="small" checked={ediShipment} sx={{"&.Mui-checked": {color: redColor,}}}  />} label={constants.ADMIN_COPY_EDI_SHIPMENT_LABEL}/>
        <FormControlLabel onChange={() => handelShipment('shipment')} control={<Checkbox size="small" checked={shipmentType} sx={{"&.Mui-checked": {color: redColor,}}} />} label={ location.pathname.includes('/market-place') ? 'I Will Provide Shipping & Handling (Drop Ship)' : `Shipping & Handling Managed By Event Creator`} />
        <br />
        { !location.pathname.includes('/market-place') &&
       <>
        <FormControlLabel onChange={() => setHasUPC(!hasUPC)} control={<Checkbox size="small" checked={hasUPC} sx={{"&.Mui-checked": {color: redColor,}}} />} label="This product has UPC" />
        {hasUPC && <Input label="Enter UPC" required value={auctionItem.upc} onChange={handleDataChange} name="upc" />}
        </>}
        {shipmentType && <FormControlLabel onChange={() => setPickupType(!pickupType)} control={<Checkbox size="small" checked={pickupType} sx={{"&.Mui-checked": {color: redColor,}}} />} label="No shipping. Customer must pick up from designated location." />}
        {!pickupType && <Input type="number" value={auctionItem.weight} onChange={(e) => setAuctionItem({ ...auctionItem, weight: e.target.value })} label="Product Weight (lb)" required={!pickupType} iButton infoBubbleText='If your item is one pound or less, enter the number 1.' />}
      </Modal>
      <Modal isOpen={openDonor} onClose={() => setOpenDonor(false)} onDone={handleLocalDonor} title="New Donor">
        <Input value={localDonor.org_name} onChange={(e) => setLocalDonor({ ...localDonor, org_name: e.target.value })} label="Donor Name or Organization" required />
        <FormTextarea value={localDonor.about} onChange={(e) => setLocalDonor({ ...localDonor, about: e.target.value })} label="Long Description" required iButton infoBubbleText='Tell us about your item’s donor. Who are they? What do they do? Do you want to share a link to their web site?' />
      </Modal>
      <Modal isOpen={removeCauseModal} onClose={()=>setRemoveCauseModal(false)} buttonText='Continue' onDone={()=>setRemoveCauseModal(false)}>
    {
  (assignedcauses.length > 0 ) ? assignedcauses.map((cause,i)=>{
    return (
    <div key={cause?.id} className='remove-cause-conatiner'>
      <span>{cause?.cause?.legal_name}</span>
      <button onClick={(e)=>{
        e.preventDefault()
        handelRemoveCause(cause?.cause_id)}}
        >Remove</button>
    </div>
    )
  }) : <div>No causes found</div>
   }
      </Modal>

      <Modal isOpen={aiAnalysis.modalIsOpen} onClose={() => setAiAnalysis({...aiAnalysis, modalIsOpen:false})} buttonRequired={false} title={constants.ADMIN_COPY_AI_MODAL_TITLE}>
        <AIGeneratedData data={aiAnalysis.aiData} handleGeneratedData={handleGeneratedData}/>
      </Modal>

      <Alert isOpen={formError} message={formError} onClose={() => setFormError(null)} />
      <LoadingModal isOpen={isLoading} />
      {/* <Alert isOpen={!!whiteborderPortraitAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handlePortraitImage(portraitEvent,true);
                setWhiteborderPortraitAlert(null)
                }} 
                onClose={()=>setWhiteborderPortraitAlert(null)} 
                message={whiteborderPortraitAlert}/> */}
      <Alert isOpen={!!whiteborderLandScapeAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handleLandscapeImage(landScapeEvents,true);
                setWhiteborderLandScapeAlert(null)
                }} 
                onClose={()=>setWhiteborderLandScapeAlert(null)} 
                message={whiteborderLandScapeAlert}/>
    </form>
  )
}

export default AuctionItem