import React from 'react'
import { useState, useEffect } from 'react'
import CreateForm from './CreateForm'
import moment from "moment"
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Container from '../../../components/HOC/Container/Container'
import AccessDenied from '../../../components/AccessDenied/AccessDenied'
import authService from '../../../../services/auth-services'
import LoadingModal from '../../../components/LoadingModal/LoadingModal'

const CreateMarketPlace = () => {
    const today = new Date()
    const startDate = moment(today).format("YYYY-MM-DDTHH:mm");
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    const date = new Date(year + 100, month, day);
    const endDate = moment(date).format("YYYY-MM-DDTHH:mm");
    const {auction_id} = useParams();
    let stringLowerName = 'marketplace'
    let stringCapitalName = 'Marketplace'
    const localStorageData = JSON.parse(localStorage.getItem(`${stringLowerName}Data`));
    const persistedData = useSelector(state => state?.persistedData)
    let initialData = {
      title: '',
      start_date: startDate,
      end_date: endDate,
      currency: 'USD',
      shipping_info: '',
      terms_info: '',
      about: '',
      custom_categories: [],
      image1: null,
      image2: null,
      custom_url: '',
      auction_image_key:null,
      buy_now_image_key:null,
      status:'',
      donor_id:null,
      need_box: '0',
      box_color: null,
      text_color: null,
      show_details_over_banner:true,
    }

    const initialStateCopy = {...initialData}

    if(localStorageData) {
      if(auction_id) {
        localStorage.removeItem(`${stringLowerName}Data`)
        localStorage.removeItem(`extra${stringCapitalName}State`)
      }
      initialData = localStorageData
      if(persistedData?.marketplacePortraitImage) {
        initialData = {...initialData, image1:persistedData?.marketplacePortraitImage}
      }
      if(persistedData?.marketplaceLandscapeImage) {
        initialData = {...initialData, image2:persistedData?.marketplaceLandscapeImage}
      }
    }

    const [marketPlaceData, setMarketPlaceData] = useState(initialData)
    const [marketPlacePermission, setMarketPlacePermission] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
 
    const checkHasStoreMarketPlacePermission = async() => {
      try {
          if(auction_id) {
            setMarketPlacePermission(true)
            return;
          }
          setIsLoading(true)
          const permission = await authService.checkLimitedPermissions(17)
          setIsLoading(false)
          if(permission?.access){
            setMarketPlacePermission(true)
            return;
          }
          else {
            setMarketPlacePermission(false)
          }
      } catch (error) {
        setMarketPlacePermission(false)
        setIsLoading(false)
      }
    }

    useEffect(() => {
      checkHasStoreMarketPlacePermission()
    },[])

  return (
    <>
    {
      !marketPlacePermission ? 
      <Container><AccessDenied title={`Marketplace`} /></Container>
      :
      <CreateForm stateData={marketPlaceData} setStateData={setMarketPlaceData} stringLowerName={stringLowerName} stringCapitalName={stringCapitalName} forMarket={true} initialStateCopy={initialStateCopy}/>
    }
    <LoadingModal isOpen={isLoading} style={{zIndex:'41'}} />
    </>
  )
}

export default CreateMarketPlace