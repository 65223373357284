import React, { useEffect, useState } from 'react';
import Input from '../../components/Input/Input';
import constants from '../../../constants/en';
import DropDown from '../../components/DropDown/DropDown';
import auctionServices from '../../../services/auction-services';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Alert from '../../components/Alert/Alert';

const SaleIntegration = ({godAccess=false, setLoading=()=>null, paramsDonorId=''}) => {

    const [saleIntegrationMethods, setSaleIntegrationMethods] = useState([])
    const [alertMsg, setAlertMsg] = useState(null)
    const [revenueSplit, setRevenueSplit] = useState({
        id:null,
        donor_id: null,
        cause_rev_share_percent:20,
        donor_rev_share_percent:0,
        bg_rev_share_percent:80,
        drop_ship_sale_integration_method:{label:'', value: ''},
    })

    const getLabelName = (id) => {
        switch(id) {
            case 1:
              return constants.ADMIN_COPY_SALE_INTEGRATION_METHOD_ADMIN_ONLY_LABEL1;
            case 2:
              return constants.ADMIN_COPY_SALE_INTEGRATION_METHOD_API_PUSH_LABEL2;
            case 3:
              return constants.ADMIN_COPY_SALE_INTEGRATION_METHOD_API_PULL_LABEL3;
            case 4:
              return constants.ADMIN_COPY_SALE_INTEGRATION_METHOD_EMAIL_LABEL4;
            case 5:
                return 'Proxy';
            default:
                return ''
        }
    }

    const fetchSaleIntegrationMethod = async() => {
        try{
            setLoading(true)
            const data = await auctionServices.getSaleIntegrationMethods()
            const formatedData = data?.data?.map((item) => {
                return {
                    label: getLabelName(item?.id),
                    value: item?.id
                }
            })
            setSaleIntegrationMethods(formatedData)
            setLoading(false)
        }catch(error) {
            setLoading(false)
        }
      }

      const fetchDonorRevenueSplit = async() => {
        try {
            setLoading(true)
            const response = await auctionServices.getDonorRevenueSplit(paramsDonorId)
            let data = response?.data
            if(data) {
                let dataCopy = {...data}
                delete data?.drop_ship_sale_integration_method_drop_ship_integration
                setRevenueSplit({...data, drop_ship_sale_integration_method:{
                    label: getLabelName(data?.drop_ship_sale_integration_method),
                    value: data?.drop_ship_sale_integration_method
                }})
            }
            setLoading(false)
        }catch (error) {
            setLoading(false)
        }
      }
    
      useEffect(()=> {
        if(!godAccess)return
        fetchSaleIntegrationMethod()
        if(!paramsDonorId) return
        fetchDonorRevenueSplit()
      },[godAccess])


    const handleRevenueSplitChange = (event) => {
        if(!event.target?.name) {
            setRevenueSplit({...revenueSplit, drop_ship_sale_integration_method:event})
        }
        else {
            setRevenueSplit({...revenueSplit, [event.target.name]:event.target.value})
        }
    }

    const checkValidation = () => {
       const totalPercentage = Number(revenueSplit?.cause_rev_share_percent) + Number(revenueSplit?.donor_rev_share_percent) + Number(revenueSplit?.bg_rev_share_percent);
      if(totalPercentage !== 100) {
        setAlertMsg(constants.ADMIN_COPY_TOTAL_SHARE_VALIDATION);
        setLoading(false)
        return false;
       }
       else if(revenueSplit?.drop_ship_sale_integration_method?.value === '' || !revenueSplit?.drop_ship_sale_integration_method?.value) {
        setAlertMsg(constants.ADMIN_COPY_INTEGRATION_TYPE_VALIDATION);
        setLoading(false)
        return false;
       }
       else return true;
    }

    const handleSubmit = async() => {
        try{
            setLoading(true)
            if(!checkValidation()) return
            const payload = {
                cause_rev_share_percent: revenueSplit?.cause_rev_share_percent,
                donor_rev_share_percent: revenueSplit?.donor_rev_share_percent,
                bg_rev_share_percent: revenueSplit?.bg_rev_share_percent,
                drop_ship_sale_integration_method: revenueSplit?.drop_ship_sale_integration_method?.value,
                donor_id: paramsDonorId,
                id: revenueSplit?.id && revenueSplit?.id
            }
            if(revenueSplit?.id) {
                /// here we will update the data
                const updateRevenueSplit = await auctionServices.updateRevenueSplitById(payload, revenueSplit?.id);
                setAlertMsg(updateRevenueSplit?.message);
            }
            else {
                /// here we will create the data
                const createRevenueSplit = await auctionServices.createRevenueSplitData(payload)
                setAlertMsg(createRevenueSplit?.message);
            }
            setLoading(false)
            fetchDonorRevenueSplit()
        }catch {
            setLoading(false)
            setAlertMsg(constants.ADMIN_COPY_SOMETHING_WENT_WRONG);
        }
    }

    return (
        <>
            <div style={{ marginTop: '30px' }} className="manage-donor-title"><AdminPanelSettingsIcon sx={{ color: "skyblue" }} />Admin Settings</div>
            <div className='addDonor-container'>
                <div className="addDonor-container-left">
                    <Input
                        type='number'
                        name='donor_rev_share_percent'
                        label={constants.ADMIN_COPY_DONOR_SHARE_PERCENTAGE_LABEL}
                        value={revenueSplit?.donor_rev_share_percent}
                        onChange={handleRevenueSplitChange}
                    />
                    <Input
                        type='number'
                        name='cause_rev_share_percent'
                        label={constants.ADMIN_COPY_CAUSE_SHARE_PERCENTAGE_LABEL}
                        value={revenueSplit?.cause_rev_share_percent}
                        onChange={handleRevenueSplitChange}
                    />
                </div>
                <div className="addDonor-container-right">
                    <Input
                        type='number'
                        name='bg_rev_share_percent'
                        label={constants.ADMIN_COPY_BG_SHARE_PERCENTAGE_LABEL}
                        value={revenueSplit?.bg_rev_share_percent}
                        onChange={handleRevenueSplitChange}
                    />
                    <DropDown
                        label={constants.ADMIN_COPY_DONOR_DROPSHIP_SALE_INTEGRATION_LABEL}
                        options={saleIntegrationMethods}
                        onChange={handleRevenueSplitChange}
                        value={revenueSplit?.drop_ship_sale_integration_method}
                        menuPlacement={'top'}
                        isSearchable
                    />
                </div>
            </div>
            <div className="add-donor-btn" style={{ textAlign: "center" }}>
                    <button className="aitem-modal-btn aitem-white-button" onClick={handleSubmit} type="submit">
                        {
                            revenueSplit?.donor_id ? 'Update Revenue Split' : 'Create Revenue Split'
                        }
                    </button>
            </div>
            <Alert isOpen={!!alertMsg} message={alertMsg} onClose={() => setAlertMsg(null)} />
        </>
    )
}

export default SaleIntegration;