import React, { useState } from 'react';
import './MyEvents.css';
import { useDispatch, useSelector } from 'react-redux';

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Container from '../../components/HOC/Container/Container';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePagination } from '@mui/material';
import { useEffect } from 'react';
import { fetchEvents, setSliceLoading, toggleLoading  } from '../../../redux/eventReducer';
import { useContext } from 'react';
import AuthContext from '../../../store/auth-context';
import { resetDependencies, typeEdit, updateEvent } from '../../../redux/dependenciesReducer';
import { resetSavedEvent } from '../../../redux/savedEventReducer';
import { setDefaultEndDate, setDefaultStartDate } from '../../../utils/util-functions';
import { resetItemsList } from '../../../redux/itemsListReducer';
import ShareIcon from '@mui/icons-material/Share';
import Modal from '../../components/Modal/Modal';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import {QRCodeCanvas} from 'qrcode.react'

import { config } from '../../../config/config';
import authService from '../../../services/auth-services';
import auctionServices from '../../../services/auction-services';
import style from '../EventDashboard/EventDashboard.module.css';
import Alert from '../../components/Alert/Alert';
import TableButton from '../../components/TableButton/TableButton';
import constants, { COLORS_OBJECT } from '../../../constants/en';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {BookmarkAdded, BookmarkRemoveOutlined, ContentPasteGo} from '@mui/icons-material';
import EventActionsMenu from '../../components/EventActionsMenu/EventActionsMenu';

const REACT_USER_APP_BASE_URL = config.REACT_USER_APP_BASE_URL

const redColor = COLORS_OBJECT.color[0]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function MyEvents() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const ctx = useContext(AuthContext)
    const { events, totalEvents, loading } = useSelector(state => state.userEvents)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [shareModal, setShareModal] = useState(false)
    const [activeId, setActiveId] = useState(null)
    const [actionDenied, setActionDenied] = useState(false)
    const [godMod,setGOdMod] = useState(false);
    const [refresh, setRefresh] = useState(false);
   const [openConfirmAlert,setOpenConfirmAlert] = useState(false);
   const [deleteEventId,setDeleteEventId] = useState(null);
   const [confirmDialogue, setConfirmDialogue] = useState({isOpen:false, message:'', confirmFunction:()=>null, confirmParam:null})
    const [alert,setAlert] = useState(null)
    useEffect(() => {
        dispatch(fetchEvents({ user_id: ctx.user.id, page: page ? page : 1, limit: limit ? limit : 10 }))
    }, [page, limit, refresh])
    useEffect(() => {
        localStorage.removeItem("data")
        dispatch(resetDependencies())
        dispatch(resetSavedEvent())
        dispatch(resetItemsList())
    }, [])

    useEffect(() => {
        checkPermission(true,15)
    }, [])

    const handleAddEvent = () => {
        navigate("/admin/event")
    }

    const checkPermission = async (checkGodMod, permissionId, auctionId) => {
        try {
            dispatch(setSliceLoading({sliceLoading:true}));
            if (!checkGodMod) {
                const permission = await authService.checkPermissions(permissionId, auctionId)
                dispatch(setSliceLoading({sliceLoading:false}));
                return permission
            }
            else {
                 const permission = await authService.checkLimitedPermissions(15)
                 dispatch(setSliceLoading({sliceLoading:false}));
                //  permission.access && setGOdMod(true)
                if(permission?.access) {
                    setGOdMod(true)
                }
                else {
                    setGOdMod(false)
                }
            }
        }
        catch (error) {
            dispatch(setSliceLoading({sliceLoading:false}));
        }
    }

    const getButtonText = (status) => {
        let text = ''
        switch (status) {
            case 'building':
                text = 'Submit For Approval'
                break;
            case 'live':
                text = 'Pause'
                break;
            case 'paused':
                text = 'Resume'
                break;
            default:
                text = null;
        }
        return text
    }
    const getCurrentStatus = (status) => {
        let currentStatus = ''
        switch (status) {
            case 'building':
                currentStatus = 'Building'
                break;
            case 'live':
                currentStatus = 'Live'
                break;
            case 'inreview':
                currentStatus = 'Pending Approval'
                break;
            case 'ready':
                currentStatus = 'Ready To Launch'
                break;
            case 'complete':
                currentStatus = 'Complete'
                break;
            case 'paused':
                currentStatus = 'Paused'
                break;
            case 'preview':
                currentStatus = 'Preview'
                break
            default:
                currentStatus = '';
        }
        return currentStatus
    }

    const updateStatus = async (row, review) => {
        dispatch(toggleLoading())

        let status = ''
        if (review === 'approve') {
            status = 'ready'
        } else if (review === 'reject') {
            status = 'building'
        } else {
            switch (row.status) {
                case 'building':
                    status = 'inreview'
                    break;
                case 'live':
                    status = 'paused'
                    break;
                case 'paused':
                    status = 'live'
                    break;
                default:
                    status = '';
            }
        }
        if (status === '') {
            dispatch(toggleLoading())
            return
        }
        try {
            await auctionServices.updateAuctionStatus(row.id, { status })
        } catch (error) {
            setActionDenied(true)
            dispatch(toggleLoading())
            return
        }
        setRefresh(!refresh)
    }

    const handleEdit = (event) => {
        dispatch(typeEdit({
            auction_id: event.id,
            cause_id: event.cause_id,
            cause_category_id: event.cause_category_id,
            auction_title: event.title,
            auction_status: null,
            start_date: event.start_date && setDefaultStartDate(event.start_date),
            end_date: event.end_date && setDefaultEndDate(event.end_date),
            active:'event',
            has_auction: event.has_auction,
            has_buy_now: event.has_buy_now
        }))
        localStorage.setItem("data", JSON.stringify({
            auction_id: event.id,
            auction_title: event.title,
            start_date: event.start_date && setDefaultStartDate(event.start_date),
            end_date: event.end_date && setDefaultEndDate(event.end_date),
            has_auction: event.has_auction,
            has_buy_now: event.has_buy_now
        }))
        navigate(`/admin/event/edit/${event.id}`)
    }

    const handleLiveAddItem = (event) => {
        dispatch(typeEdit({
            auction_status: 'live',
            auction_id: event.id,
            cause_id: event.cause_id,
            cause_category_id: event.cause_category_id,
            auction_title: event.title,
            start_date: event.start_date,
            end_date: event.end_date
        }))
        localStorage.setItem("data", JSON.stringify({
            auction_status: 'live',
            auction_id: event.id,
            auction_title: event.title,
            start_date: event.start_date && setDefaultStartDate(event.start_date),
            end_date: event.end_date && setDefaultEndDate(event.end_date)
        }))
        navigate(`/admin/items`)
    }

    const handleDeleteEventConfirm =async () =>{
        setOpenConfirmAlert(false)
        if(!deleteEventId) return 
        dispatch(toggleLoading());
         
        await auctionServices.deleteEvent(deleteEventId).then((res)=>{
            // console.log(res);
            dispatch(toggleLoading());
            setDeleteEventId(null);
            setRefresh(!refresh)
        }).catch((err)=>{
            // console.log(err);
            setAlert(err?.response?.data?.message)
            setDeleteEventId(null);
            dispatch(toggleLoading());
        })
        

    }

    const handleDeleteEvent = (event) =>{
        setDeleteEventId(event?.id);
        setOpenConfirmAlert(true)
    }

    const handleDeleteAlertClose = () =>{
        setDeleteEventId(null);
        setOpenConfirmAlert(false)
    }
    const downloadQR = (activeId) => {
        const canvas = document.querySelector("#qrcode-canvas")       
        if (!canvas) throw new Error("<canvas> not found in the DOM")    
        const pngUrl = canvas
          .toDataURL("image/png",3)
          .replace("image/png", "image/octet-stream")
        const downloadLink = document.createElement("a")
        downloadLink.href = pngUrl
        downloadLink.download = `Event${activeId}QR code.png`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      
    }

    const getFeaturedDetails = (row) => {
        switch (row?.featured) {
            case 1:
                return {
                    text:'Unfeature',
                    icon: BookmarkRemoveOutlined
                }
            case 0:
                return {
                    text: 'Make Featured',
                    icon: BookmarkAdded
                }
            default:
                return {
                    text: 'Make Featured',
                    icon: BookmarkAdded
                }
        }
    }

    const handleMakeFeatured = async(row) => {
        let featured = 1;
        let alertMessage = constants.ADMIN_COPY_FEATURED_ALERT;
        if(row?.featured === 1) {
            featured = 0
            alertMessage = constants.ADMIN_COPY_UNFEATURED_ALERT
        }
        try {
            dispatch(toggleLoading())
            const updated = await auctionServices.makeEventFeatured(row?.id, featured)
            dispatch(toggleLoading())
            setRefresh(!refresh)
            setAlert(alertMessage)
        }
        catch(error) {
            dispatch(toggleLoading())
            setAlert(error.response?.data?.message || constants?.ADMIN_COPY_SOMETHING_WENT_WRONG)
        }
    }

    const handleCloneEvent = (row) => {
        setConfirmDialogue({isOpen:true, message: constants.ADMIN_COPY_EVENT_CLONE_CONFIRM, confirmFunction:handleCloneConfirm, confirmParam:row})
    }

    const handleCloneConfirm = async(eventDetails) => {
        setConfirmDialogue({isOpen:false, message: ''})
        try {
            dispatch(toggleLoading());
            const response = await auctionServices.cloneEvent(eventDetails.id)
            setAlert(response.message || constants.ADMIN_COPY_EVENT_CLONE_SUCCESS)
            dispatch(fetchEvents({ user_id: ctx.user.id, page: page ? page : 1, limit: limit ? limit : 10 }))
        }catch(error) {
            setAlert(error.response?.data?.message || constants?.ADMIN_COPY_SOMETHING_WENT_WRONG)
            dispatch(toggleLoading());
        }
    }

    return (
        <Container title='My Events' showButton onClickButton={handleAddEvent} buttonText='Add new event' style={{position: "relative"}}>
           {loading ? <LoadingModal isOpen={true} /> :
            <>
            <div className='event_header_container'>
                <span className='calender_icon_container'><CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#34b5e5' }} /><p>Events</p></span>
                { godMod && <div><button className='event-submit-btn' style={{width:'150px'}} onClick={()=>navigate('/admin/restore-events',{state:{event_type:"event"}})}>Restore events</button></div>}
            </div>
            <hr />

            {events?.length > 0 ? (
                <Paper>
                    <TableContainer>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ width: "40%" }}>Event Title</StyledTableCell>
                                    <StyledTableCell sx={{ width: "20%" }}>Status</StyledTableCell>
                                    <StyledTableCell>Manage</StyledTableCell>

                                    <StyledTableCell>Share</StyledTableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {events?.map((row, i) => (
                                    <StyledTableRow key={row.id}>
                                        <StyledTableCell>{row.title}</StyledTableCell>
                                        {/* <StyledTableCell sx={(row.status === "inreview") ? null : { textTransform: "uppercase" }}><strong>{(row.status === "inreview" ? "In Review" : row.status)}</strong></StyledTableCell> */}
                                        <StyledTableCell sx={{fontWeight: "600"}}>{getCurrentStatus(row.status)}</StyledTableCell>
         
                                        <StyledTableCell >
                                       {/* <div style={{display:'flex',gap:'14px'}}>
                                       {['inreview','complete'].indexOf(row.status) === -1 &&
                                            <TableButton onClick={() => handleEdit(row)} Icon={BorderColorIcon} />
                                            }
                                        {(['complete'].indexOf(row.status) === 0 && godMod) &&
                                        <>
                                       <TableButton onClick={() => handleEdit(row)} Icon={BorderColorIcon} />
                                        </>
                                    
                                        }   

                                         <div style={{ display: "flex",  gap: '14px' }}>
                                            {godMod && row.status === 'inreview' ? <>
                                                <TableButton onClick={() => updateStatus(row, 'approve')} color={redColor} text='Approve' />
                                                <TableButton onClick={() => updateStatus(row, 'reject')} backgroundColor={redColor} color={'white'} style={{border:'none'}} text='Reject' />
                                            </> : null}
                                            {getButtonText(row.status) && 
                                               <TableButton onClick={() => updateStatus(row)} backgroundColor={redColor} color={'white'} style={{border:'none'}} text={getButtonText(row.status)} />
                                              }
                                        </div>
                                        {(godMod && ['live','preview'].indexOf(row.status) === -1 ) ? <TableButton text='Delete' backgroundColor={redColor} color={'white'} onClick={() => handleDeleteEvent(row)} Icon={DeleteOutlineOutlinedIcon} /> : null}
                                        {(godMod && ['live'].includes(row.status) ) ? 
                                            <TableButton text={getFeaturedDetails(row).text} backgroundColor={'white'} color={redColor} onClick={() => handleMakeFeatured(row)} Icon={getFeaturedDetails(row).icon} /> 
                                        : null}
                                        <TableButton text={"Clone Event"} backgroundColor={'white'} color={redColor} onClick={() => handleCloneEvent(row)} Icon={ContentPasteGo} />
                                       </div> */}
                                       <EventActionsMenu
                                        eventType="event"
                                        row={row}
                                        handleEdit={handleEdit}
                                        godMod={godMod}
                                        updateStatus={updateStatus}
                                        getButtonText={getButtonText}
                                        handleDeleteEvent={handleDeleteEvent}
                                        getFeaturedDetails={getFeaturedDetails}
                                        handleMakeFeatured={handleMakeFeatured}
                                        handleCloneEvent={handleCloneEvent}
                                       />
                                       </StyledTableCell> 

                                        <StyledTableCell>
                                            {(row.status === 'live' || row.status === 'preview') && <div onClick={() => {
                                                setActiveId( row?.custom_url ? row.custom_url : row.id)
                                                setShareModal(true)}}><ShareIcon sx={{cursor:"pointer"}} /></div>}
                                        </StyledTableCell>
                                    </StyledTableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20]}
                        component="div"
                        count={totalEvents}
                        rowsPerPage={limit}
                        page={page - 1}
                        onPageChange={(e, newPage) => setPage(newPage + 1)}
                        onRowsPerPageChange={(e) => { setLimit(+e.target.value); setPage(1) }}
                    />
                </Paper>
            ) : <p>No events to show</p>}

            <Modal buttonRequired={false} isOpen={shareModal} onClose={() => setShareModal(false)} title={` Scan Qr Code to get the event link`} >
                <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                    {/* <QRCode size={256}
                        id={activeId}
                        style={{ height: "auto", maxWidth: "100%", width: "30%" }}
                        value={`${REACT_USER_APP_BASE_URL}/event/${activeId}`} 
                        viewBox={`0 0 256 256`} /> */}

                        <QRCodeCanvas
                          id="qrcode-canvas" 
                          level="H"
                          imageSettings={{
                            src: "",
                            x: undefined,
                            y: undefined,
                            height: 80,
                            width: 80,
                            excavate: true,
                          }}
                         size={300}
                         style={{ height: "auto", maxWidth: "100%", width: "35%" }}
                         value={`${REACT_USER_APP_BASE_URL}/event/${activeId}`}  />
                </div>
                <div className="aitem-submit-container">
                <button onClick={() => {downloadQR(activeId)}} >Download QR Code</button>
                </div>
            </Modal>
            </>}
            <Alert isOpen={actionDenied} message='You are not authorized to perform this action'
                onClose={() => setActionDenied(false)} />
           <Alert message={constants.ADMIN_COPY_EVENT_DELETE_CONFIRM} isOpen={openConfirmAlert} type="confirm" onClose={handleDeleteAlertClose} onConfirm={handleDeleteEventConfirm} />
           <Alert isOpen={! !alert} message={alert}
                onClose={() => setAlert(null)} />
            <Alert type='confirm' isOpen={confirmDialogue.isOpen} message={confirmDialogue?.message}
                 onConfirm={() => confirmDialogue.confirmFunction(confirmDialogue.confirmParam)} onClose={() => setConfirmDialogue({isOpen:false, message: ''})} />
        </Container>


    )
}

export default MyEvents
