import { FormControlLabel, Radio } from '@mui/material'
import React from 'react'
import { COLORS_OBJECT } from '../../../constants/en'
const redColor = COLORS_OBJECT.color[0]

const RadioGroupComponent = ({onChange ,checked=false, label, firstLabel="Yes", secondLabel="No"}) => {
  const handleChange = (e) => {
    onChange(e.target.name === 'true')
  }  
  return (
    <div style={{marginTop:"15px"}}>
          <label style={{ fontWeight:"600",fontSize:"0.85rem"}} >{label}</label>
          <div style={{ display: "flex", gap: "20px" }}>        
            <FormControlLabel
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}}/>}
              label={firstLabel}
              checked={checked}
              onChange={handleChange}
              labelPlacement="end"
              name="true"
            />
            <FormControlLabel  
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}}/>}
              label={secondLabel}
              checked={!checked}
              onChange={handleChange}
              labelPlacement="end"
              name="false"
            />
          </div>
          </div>
  )
}

export default RadioGroupComponent