import React, { useState } from 'react'
import Input from '../Input/Input'
import constants, { COLORS_OBJECT } from '../../../constants/en';
import { Accordion } from 'react-bootstrap';
import auctionServices from '../../../services/auction-services';
import TableButton from '../TableButton/TableButton';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import Alert from '../Alert/Alert';
import { CircularProgress } from '@mui/material';
import classes from './EditVariants.module.css'

const EditVariants = ({ options = {}, variants = [], setIsLoading = () => null, fetchData = () => null, setEditVariantsModal = () => null, setFormError = () => null, variantDetails={} }) => {

    const [showForm, setShowForm] = useState(false)
    const [editOptions, setEditOptions] = useState([])
    const [deleteVaraintLoading, setDeleteVariantLoading] = useState({})
    const [oldState, setOldState] = useState([])
    const [confirmAlert, setConfirmAlert] = useState({isOpen:false, message:'', value:null, onConfirm:()=>null})
    const [keyName, setKeyName] = useState({ oldKey: '', newKey: '' })

    const handleEditOption = (key, values) => {
        // setEditOptions({ [key]: values })
        const value = values?.map((item, index) => {
            return {
                key: key + -+(index + 1),
                value: item.value,
                id: item.id
            }
        })
        setKeyName({ oldKey: key, newKey: key })
        setEditOptions(value)
        setOldState(value)
        setShowForm(true)
    }

    const handleAddNewVariant = (key, values) => {
        const lastIndex = editOptions?.length
        setEditOptions([...editOptions, {
            newVariantOption: key,
            key:key+'-'+(lastIndex + 1),
            value: values
        }])
    }

    const handleChange = (e, key) => {
        const newOptions = editOptions.map((opt, index) => {
            if (opt.key === key) {
                if(opt?.newVariantOption) {
                    return {
                        ...opt,
                        value:e.target.value,
                    }
                }
                else {
                    return {
                        ...opt,
                        oldVariantValue: oldState[index].value,
                        value: e.target.value,
                    };
                }
            }
            return opt;
        });
        setEditOptions(newOptions);
    }
    const handleKeyChange = (e) => {
        setKeyName({ ...keyName, newKey: e.target?.value })
    }
    
    const handleUpdate = async () => {
        try {
            const groupsId = variants.map((item) => {
                return item?.groupsId
            })
            if (!groupsId?.length) return
            const uniqueValueSet = new Set()
            let duplicateFlag = false;
            let optionDuplicateFlag = false;
            const newEditOptions = editOptions.filter((item) => {
                ///Filtering varaints with same value
                if(!uniqueValueSet.has(item.value)) {
                    uniqueValueSet.add(item.value)
                    return true
                }
                else {
                    duplicateFlag = true;
                    return false;
                }
            })
            if(duplicateFlag) {
                setFormError(constants.ADMIN_COPY_ITEM_VARIANT_DUPLICATE)
                return;
            }
            if(keyName?.oldKey !== keyName?.newKey) {
                for (let option of Object.keys(options)) {
                    if(option.toLowerCase() === keyName?.newKey?.toLowerCase()) {
                        optionDuplicateFlag = true
                    }
                }
            }
            if(optionDuplicateFlag) {
                setFormError(constants.ADMIN_COPY_ITEM_OPTION_DUPLICATE)
                return;
            }
            const updatePayload = {
                editOptions: newEditOptions,/// this is the variants array
                groupsId: groupsId,
                keyName: keyName/// this is the object having option names
            }
            setIsLoading(true)
            const updatedData = await auctionServices.updateVariantsData(updatePayload)
            setEditVariantsModal(false)
            fetchData()
            setFormError(updatedData?.msg ? updatedData?.msg : constants.ADMIN_COPY_UPDATED_SUCCESS_MSG)
        }
        catch (error) {
            setFormError(constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleDeleteVariant = async(variantId) => {
        try {
            setConfirmAlert({isOpen:false, message:'', value:null, onConfirm:()=>null })
            setDeleteVariantLoading({[variantId]:true})
            await auctionServices.deleteVariant(variantId)
            setDeleteVariantLoading({})
            fetchData()
            setShowForm(false)
            setFormError(constants.ADMIN_COPY_DELETED_VARIANT_SUCCESS_MSG)
        }catch(error) {
            setDeleteVariantLoading({})
            console.log(error)
            setFormError(error.response.data.message ||constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
        }
    }

    const handleDeleteConfirm = (variantId) => {
        if(variants?.length === 1) {
            setFormError(constants.ADMIN_COPY_ITEM_DELETE_LAST_VARIANT_ALERT)
        }
        else {
            setConfirmAlert({isOpen:true, message:constants.ADMIN_COPY_ITEM_VARIANT_DELETING_ALERT, value:variantId, onConfirm:handleDeleteVariant })
        }
    }
    return (

        <div className='variants-container'>
            <div className='variants-options-container'>
                <Accordion>
                    {Object.keys(variantDetails).map((key, idx) => (
                        <Accordion.Item key={key} eventKey={idx + ""}>
                            <Accordion.Header>{key}</Accordion.Header>
                            <Accordion.Body>
                                <ul className={classes.variantUl}>
                                    {variantDetails[key].map((val, idx) => (
                                        <li className={`input-label ${classes.variantList}`} key={idx}>
                                            <span>{val.value}</span>
                                            {variantDetails[key]?.length > 1 && (// if we allow all variants in an option to delete then it will delete all inventories of the item.
                                                deleteVaraintLoading[val.id] ? <CircularProgress sx={{width:'20px', height:'20px', color:'red'}}/> :
                                                <TableButton onClick={() => handleDeleteConfirm(val.id)} Icon={DeleteOutlineOutlined} color='white' backgroundColor={'red'} needText={false}/>
                                            )
                                            }
                                        </li>
                                    ))}
                                </ul>
                                <button
                                    className='accordion-edit-btn'
                                    type="button"
                                    onClick={() => handleEditOption(key, variantDetails[key])}
                                >
                                    Edit
                                </button>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
                {
                    showForm &&
                    <div>
                        <Input value={keyName.newKey} onChange={(e) => handleKeyChange(e)} />
                        {
                            editOptions?.map((opt) => (
                                <div key={opt.key} style={{ display: "flex", alignItems: "flex-end" }}>
                                    <Input key={opt.key} value={opt.value} onChange={(e) => handleChange(e, opt.key)} />
                                </div>
                            ))
                        }
                        <button
                            type="button"
                            onClick={() => handleAddNewVariant(keyName.oldKey, '')}
                            className="variants-option-add-btn"
                        >
                            + Add Another {keyName.oldKey}
                        </button>
                        <button
                            type="button"
                            onClick={() => handleUpdate()}
                            className="aitem-modal-btn"
                            style={{
                                margin: 'auto',
                                marginTop: '20px',
                                marginInline: 'auto',
                                display: 'flex',
                            }}
                        >
                            Update and Continue
                        </button>
                    </div>
                }
            </div>
            <Alert 
                isOpen={confirmAlert.isOpen} 
                message={confirmAlert.message} 
                onClose={() => setConfirmAlert({isOpen:false, value:null, message:'', type:''})}
                type='confirm'
                onConfirm={() => confirmAlert.onConfirm(confirmAlert.value)}
                okBtnText='Continue'
            />
        </div >
    )
}

export default EditVariants