import React from 'react'
import {useSelector} from 'react-redux'
import MarketTerms from './Terms'

const MarketPlaceTerms = () => {
    const savedMarketPlace = useSelector(event => event.savedMarketPlace)
  return (
    <>
    <MarketTerms reduxState={savedMarketPlace.marketPlace} capitalName={'Marketplace'} lowerName={'marketplace'} />
    </>
  )
}

export default MarketPlaceTerms