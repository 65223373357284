import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '../../components/HOC/Container/Container'
import Input from '../../components/Input/Input'
import styles from './CreateBlog.module.css'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageInput from '../../components/ImageInput/ImageInput';
import checkImageBeforeUpload from '../../../utils/checkImageBeforeUpload';
import authService from '../../../services/auth-services';
import Alert from '../../components/Alert/Alert';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import auctionServices from '../../../services/auction-services';
import blogServices from '../../../services/blog-service';
import AccessDenied from '../../components/AccessDenied/AccessDenied';
import constants from '../../../constants/en';


const CreateBlog = () => {
  const editorRef = useRef();
  const navigate = useNavigate();
  const { pretty_url } = useParams();
  const [value, setValue] = useState('');
  const [alertMsg, setAlertMsg] = useState(null)
  const [blogImage, setBlogImage] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [blogImageLoading, setBlogImageLoading] = useState(false)
  const [access, setAccess] = useState(true)
  const [success,setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    image: '',
    content: '',
    oldImageKey:''
  })

  const [whiteBorderAlert,setWhiteBorderAlert] = useState(null);
  const [imageEvent,setImageEvent] = useState(null)

  useEffect(() => {
    pretty_url && fetchBlog(pretty_url)
  }, [pretty_url])



  const fetchBlog = async (pretty_url) => {
    const data = await blogServices.getBlog(pretty_url, setAlertMsg);
    let image = data.data.image.split('/').splice(3,3).join('/')
    setFormData({
      title:data.data.title,
      subtitle:data.data.subtitle,
      content:data.data.content,
      oldImageKey:image,
      image: '',
    })
    setValue(data.data.content)
    setBlogImage([{name:data.data.image,type: 'img'}])
  }

  const verifyPermission = async () => {
    try {
      setIsLoading(true)
      const { data } = await authService.checkBlogPermissions()
      if (data.access) {
        setAccess(true)
      }
      setIsLoading(false)
    } catch (error) {
      if (error?.response?.status === 403 || !error?.response?.data?.access) {
        setAccess(false)
      }
      setIsLoading(false)
    }
  }

  useEffect(() => {
    verifyPermission()
  }, [])

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ 'header': 1 }, { 'header': 2 }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["link"],
      ["clean"],
    ]
  }

  const handleDataChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      formData.content = value
      if (formData.title === '' || formData.subtitle === '' ||(!pretty_url && formData.image === '') || formData.content === '') {
        setIsLoading(false)
        setAlertMsg(constants.ADMIN_COPY_BLOG_FILED_ERROR)
        return
      }
      if (formData?.title?.length < 3 || formData?.title?.length > 100) {
        setIsLoading(false)
        setAlertMsg(constants.ADMIN_COPY_BLOG_TITLE_LENGTH)
        return
      }
      if (formData?.subtitle?.length < 5 || formData?.subtitle?.length > 200) {
        setIsLoading(false)
        setAlertMsg(constants.ADMIN_COPY_BLOG_SUB_TITLE_LENGTH)
        return
      }
      if (formData?.content?.length < 200) {
        setIsLoading(false)
        setAlertMsg(constants.ADMIN_COPY_BLOG_CONTENT_LENGTH)
        return
      }
      let blogFormData = new FormData()
      formData.title &&
        blogFormData.append('title', formData.title)
      formData.subtitle &&
        blogFormData.append('subtitle', formData.subtitle)
      formData.content &&
        blogFormData.append('content', formData.content)
      formData.image &&
        blogFormData.append('blogImage', formData.image)
        let data
        console.log(pretty_url)
        if(pretty_url){
          blogFormData.append('oldImageKey', formData.oldImageKey)
          data = await blogServices.updateBlog(pretty_url,blogFormData, setAlertMsg)
        }else{
           data = await blogServices.createBlog(blogFormData, setAlertMsg)
        }
      setIsLoading(false)
      if (data?.data?.message === "Blog created" || data?.data?.message === 'Blog updated' ) {
        setSuccess(true)
        setAlertMsg(`${data?.data?.message} successfully`)
        setFormData({
          image: "",
          title: "",
          content: "",
          subtitle: ""
        })
        setBlogImage([])
        setValue('')
      }
      if (data?.response?.data?.message === "No permissions to perform this action.") {
        setIsLoading(false)
        setAlertMsg(constants.ADMIN_COPY_BLOG_PERMISSION)
        setFormData({
          image: "",
          title: "",
          content: "",
          subtitle: ""
        })
        setBlogImage([])
        setValue('')
      }
    } catch (error) {
      setIsLoading(false)
      setAlertMsg(constants.ADMIN_COPY_BLOG_VALIDATION)
    }
  }


  const handleBlogImage = async (e,confirm) => {
    try{
      if (!e.target.files[0]) return
    setBlogImageLoading(true)
    const blogImage = e.target.files[0]
    if (blogImage.size > 10000000) {
      setBlogImageLoading(false)
      setAlertMsg(constants.ADMIN_COPY_BLOG_IMG_FILE_SIZE)
    }
    const imageUrl = URL.createObjectURL(blogImage)
    const { width, height } = await checkImageBeforeUpload(imageUrl)
    if (width < 1000 || height < 500) {
      setBlogImageLoading(false)
      setAlertMsg(constants.ADMIN_COPY_BLOG_IMAGE_SIZE)
      return
    }
    if(!confirm){
      if((width/height < 1.96 || width/height > 2)){
        setImageEvent(e);
        setWhiteBorderAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','2:1'));
        setBlogImageLoading(false);
        return
      }
    }else{
      setImageEvent(null)
    }
    const imageFormData = new FormData()
    imageFormData.append("image", blogImage)
    imageFormData.append("width", 1000)
    imageFormData.append("height", 500)
    const preProcessedImage = await auctionServices.imagePreProcess(imageFormData)
    //  selectedFiles.push({ name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img', local: true });
    const uint8 = new Uint8Array(preProcessedImage.buffer.data)
    const newBlob = new Blob([uint8], { type: "image/jpeg" })
    //  setBlogImage.push(newBlob)
    setBlogImage([{ name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img' }])
    setFormData({
      ...formData,
      "image": newBlob
    })
    setBlogImageLoading(false)
    }
    catch{
    setBlogImageLoading(false)
    }
  }

  const deleteBlogImage = () => {
    setBlogImage([])
    setFormData({
      ...formData,
      "image": ""
    })
  }
  const closeAlert =()=>{
    setAlertMsg(null)
    success && navigate('/admin/blog')
  }

  return (
    <Container title={`${pretty_url?'Update':'Create'} Blog`} style={{ position: "relative" }} >
      {!access ? <AccessDenied title="Blogs" /> : (
        <form onSubmit={handleSubmit}>
          <div className={styles.main}>
            <Input name={'title'} width='60%' inputStyle={{ textAlign: "center", fontWeight: 600, border: 'none', fontSize: '38px', focus: 'outline none' }} placeholder={'Blog Title...'} value={formData.title} onChange={handleDataChange} />
            <div className={styles.subtitle_div} style={{ background: "" }}>
              <div style={{ width: '50%' }} >
                <Input maxLength={200} name={'subtitle'} width='100%' inputStyle={{ fontSize: '20px', fontWeight: 600, padding: "8px 15px 5px 15px" }} placeholder='Subtitle..' value={formData.subtitle} onChange={handleDataChange} />
                <div className='formtextarea-max-chars'>Characters Left: {200 - formData?.subtitle?.length >= 0 ? 200 - formData?.subtitle?.length : 0}</div>
              </div>
              <div style={{ width: '200px' }}>
                <ImageInput
                  loading={blogImageLoading}
                  required
                  edit
                  onDelete={deleteBlogImage}
                  label="Add an image for your blog."
                  sidelabel="Max 1, Recommended size 1000x500px"
                  handleChange={(e)=>handleBlogImage(e,false)}
                  images={blogImage ? blogImage : []}
                  max={1}
                />
              </div>
            </div>
            <div className={styles.editor_wrapper}>
              <ReactQuill
                theme="snow"
                value={value}
                modules={modules}
                onChange={(e) => setValue(e)}
                className={styles.editor_input}
                ref={editorRef}
                placeholder='Enter your content here...'
              />
            </div>
          </div>
          <button type="submit"
            className='event-submit-btn' style={{ marginTop: "25px", fontWeight: "600" }}>{pretty_url ? 'Update Blog':'Post Blog'  }</button>
        </form>
      )}
      <LoadingModal isOpen={isLoading} />
      <Alert isOpen={!!alertMsg} message={alertMsg} onClose={closeAlert} />
      <Alert isOpen={!!whiteBorderAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handleBlogImage(imageEvent,true);
                setWhiteBorderAlert(null)
                }} 
                onClose={()=>setWhiteBorderAlert(null)} 
                message={whiteBorderAlert}/> 
    </Container>
  )
}

export default CreateBlog
