import axios from "axios";
import { headerKey, headerKey_ } from "./api-header";
import { config } from '../config/config.js';

const API_BASE_URL = config.REACT_APP_BASE_URL;

const createCause = async (payload) => {
  // const response = await axios.post(
  //   `${API_BASE_URL}/api/v1/cause`,
  //   {
  //     ...payload,
  //   },
  //   {
  //     headers: { ...headerKey },
  //   },
  // );
  const response = await axios({
    method: "post",
    url: `${API_BASE_URL}/api/v1/cause`,
    data: payload,
    headers: { ...headerKey },
  })
  return response.data;
};

const getCauseCategoryList = async () => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/cause/category`, {
    headers: { ...headerKey },
  });
  return response.data;
};

const getUserCauses = async (checkCause=false) => {
  /// if we are showing cause in create store we need to show only unassigned causes.
  const {data} = await axios.get(`${API_BASE_URL}/api/v1/cause/user-causes?checkCause=${checkCause}`, {
    headers: { ...headerKey_ }
  })
  return data
}

const getCauseById = async (cause_id) => {
  const {data} = await axios.get(`${API_BASE_URL}/api/v1/cause/forAdmin/${cause_id}`, {
    headers: {...headerKey_}
  })
  return data
}

const updateCause = async (cause_id, form) => {
  const {data} = await axios.put(`${API_BASE_URL}/api/v1/cause/update-cause/${cause_id}`, form,{
    headers: {...headerKey}
  })
  return data
}

const getCausesRelatedtoItem = async (item_id) =>{
  const {data} = await axios.get(`${API_BASE_URL}/api/v1/cause/get-cause-related-to-item/${item_id}`,{
    headers: {...headerKey_}
  })
  return data
}

const getCausesNotRelatedtoItem =  async (item_id) =>{
  const {data} = await axios.get(`${API_BASE_URL}/api/v1/cause/get-cause-not-related-to-item/${item_id}`,{
    headers: {...headerKey_}
  })
  return data
}

const removeCauseFromItem = async (item_id,cause_id) =>{
  const data = await axios.delete(`${API_BASE_URL}/api/v1/cause/remove-cause/${item_id}/${cause_id}`,{
    headers: {...headerKey_}
  })
  return data
}

const archiveCause = async (cause_id) => {
  const data = await axios.patch(`${API_BASE_URL}/api/v1/cause/archive-cause/${cause_id}`, {},
  {
    headers: {...headerKey_}
  }
  )
  return data
}

const restoreCause = async (cause_id) => {
  const data = await axios.patch(`${API_BASE_URL}/api/v1/cause/restore-cause/${cause_id}`, {},
  {
    headers: {...headerKey_}
  }
  )
  return data
}

const getAllArchivedCause = async (page,limit) => {
  const {data} = await axios.get(`${API_BASE_URL}/api/v1/cause/get-all/archived-cause?page=${page}&&limit=${limit}`, {
    headers: {...headerKey_}
  })

  return data
}

const causeServices = {
  createCause,
  getCauseCategoryList,
  getUserCauses,
  getCauseById,
  updateCause,
  getCausesNotRelatedtoItem,
  getCausesRelatedtoItem,
  removeCauseFromItem,
  archiveCause,
  restoreCause,
  getAllArchivedCause
};

export default causeServices;
