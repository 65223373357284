import React, { useEffect } from 'react'
import Container from "../../components/HOC/Container/Container"
import Input from "../../components/Input/Input"
import SidebarImage from "../../../images/sidebar_image.png"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from 'react'
import "./SignUp.css"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { validEmail, validPassword, validName } from "../../../utils/reg-ex";
import logger from "../../../utils/logger";
import authService from "../../../services/auth-services";
import parseQuery from "query-string";
import constants from '../../../constants/en';

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameErr, setLastNameErr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [password, setPassword] = useState("");
  const [pwdErr, setPwdErr] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [privacy, setPrivacy] = useState(false)
  const [invalid, setInvalid] = useState(true)
  const [loading, setLoading] = useState(false)

  const [digitsValid, setDigitsValid] = useState(false)
  const [specialValid, setSpecialValid] = useState(false)
  const [lengthValid, setLengthValid] = useState(false)
  const [subscribeEmail,setSubscribeEmail]=useState(true);

  const navigate = useNavigate()

  const url = useLocation()
  const { token, auction_id } = parseQuery.parse(url.search);

  useEffect(() => {
    setInvalid(!(!emailErr && !pwdErr && !firstNameErr && !lastNameErr && privacy))
  }, [emailErr, pwdErr, firstNameErr, lastNameErr, privacy])

  // handle first name
  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    if (validName.test(e.target.value)) {
      setFirstNameErr(false);
    } else {
      setFirstNameErr(true);
    }
  }

  // handle last name
  const handleLastName = (e) => {
    setLastName(e.target.value);
    if (validName.test(e.target.value)) {
      setLastNameErr(false);
    } else {
      setLastNameErr(true);
    }
  }

  const handleEmail = (e) => {
    setEmail(e.target.value);
    if (validEmail.test(e.target.value)) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
  }

  const handlePassword = (e) => {
    setPassword(e.target.value);
    const hasDigits = /(?=.*[0-9])/.test(e.target.value)
    const hasSpecial = /(?=.*[!@#$&*])/.test(e.target.value)
    const hasLength = /^.{8,}$/.test(e.target.value)
    setDigitsValid(hasDigits)
    setSpecialValid(hasSpecial)
    setLengthValid(hasLength)
    if (validPassword.test(e.target.value)) {
      setPwdErr(false);
    } else {
      setPwdErr(true);
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!emailErr && !pwdErr && !firstNameErr && !lastNameErr && privacy) {
      setLoading(true)
      try {
        await authService.signup(firstName, lastName, null, email, password,subscribeEmail,).then(
          (response) => {
            setSuccessMsg({ type: 'success', body: constants.ADMIN_COPY_SIGNUP_SUCCESS_MSG });
            setLoading(false)
            setInvalid(true)
            setPrivacy(false)
            setFirstName("")
            setLastName("")
            setEmail("")
            setPassword("")
            setSubscribeEmail(true)
            if (token) {
              setUserPermission(response?.data?.id)
            }
          },
          (error) => {
            logger('./pages/SignUp/SignUp.jsx', 'handleSubmit(): catch1', error);
            setSuccessMsg({ type: 'error', body: error.response.data.message || 'Registration failed!' });
            setTimeout(() => {
              setSuccessMsg("")
            }, 5000)
            setLoading(false)
          }
        );
      } catch (err) {
        logger('./pages/SignUp/SignUp.jsx', 'handleSubmit(): catch2', err);
        setSuccessMsg({ type: 'error', body: err.response.data.message || 'Registration failed!' });
        setTimeout(() => {
          setSuccessMsg("")
        }, 5000)
        setLoading(false)
      }

    }
    else {
      alert(constants.ADMIN_COPY_SIGNUP_AGREE_TERMS)
      setLoading(false)
    }
  }

  ///user permission assigning function if page came from mail
  const setUserPermission = async (id) => {
    try {
      await authService.assignPermissions(auction_id, token, id).then((response) => {
        console.log(response, 'response for permission assigned')
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container style={{ marginTop:"20px",padding: "0px", width: "75%" }}>
      <div className='signup-container'>
        <div className='signup-image'>
          <img src={SidebarImage} alt="" />
        </div>
        <div className='signup-right'>
          <div className='signup-form-container'>
            {successMsg?.type === 'success' ? <p style={{ color: 'green', fontSize: "23px", textAlign: "center" }}> Registration Successful! <br/> Check your email for a verification link to get started. </p> :
            <>
            <div className='signup-heading'>Get Started Now. <br /> Register Your Nonprofit</div>
            <form className='signup-form' onSubmit={handleSubmit}>
              <div className='signup-two-inputs'>
                <Input required label="First Name" value={firstName} onChange={handleFirstName} />
                <Input required label="Last Name" value={lastName} onChange={handleLastName} />
              </div>
              <Input required label="Business Email Address" type='email' value={email} onChange={handleEmail} />
              <div className='signin-password-container'>
                <Input required label="Password" type={showPassword ? 'text' : 'password'} value={password} onChange={handlePassword} style={{ marginBottom: "2px" }} />
                <div className='signin-showicon' onClick={() => setShowPassword(!showPassword)}>{showPassword ? <VisibilityOffIcon sx={{ fontSize: "17px" }} /> : <Visibility sx={{ fontSize: "17px" }} />}</div>
              </div>
              {successMsg?.type === 'success' ? <p style={{ color: 'green', fontSize: "25px", textAlign: "center" }}> {successMsg?.body} </p>
                : <div className='signup-help'>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p style={{ textAlign: "left", margin: "5px 0 0 0", fontSize: "10px" }}>{lengthValid ? <CheckCircleIcon sx={{ fontSize: "16px", marginRight: "5px", color: "#448945" }} /> : <CancelIcon sx={{ fontSize: "16px", marginRight: "5px", color: "#D84644" }} />}8-20 characters.</p>
                    {/* {pwdErr && <span style={{color: "red", fontSize: "12px", fontWeight: 'bold'}} >Weak password!</span>} */}
                  </div>
                  <p style={{ textAlign: "left", marginTop: "2px", fontSize: "10px" }}>{digitsValid ? <CheckCircleIcon sx={{ fontSize: "16px", marginRight: "5px", color: "#448945" }} /> : <CancelIcon sx={{ fontSize: "16px", marginRight: "5px", color: "#D84644" }} />}At least 1 number.</p>
                  <p style={{ textAlign: "left", marginTop: "2px", fontSize: "10px" }}>{specialValid ? <CheckCircleIcon sx={{ fontSize: "16px", marginRight: "5px", color: "#448945" }} /> : <CancelIcon sx={{ fontSize: "16px", marginRight: "5px", color: "#D84644" }} />}At least 1 special character(!@#$%^&*)</p>
                </div>}
              <div className='signup-submit-container'>
                <div>
                <div className='check-container'>
                  <input checked={privacy} type="checkbox" onChange={() => setPrivacy(!privacy)}  /><span style={{ fontSize: "12px" }}>By registering,I acknowledge that I agree to <a><Link to={'/admin/tos'} >Terms of Use</Link></a> and <a href="/"><Link to={'/admin/privacy'} >Privacy Policy</Link></a><span style={{color: "red"}}>*</span></span>
                </div>
                <div className='check-container' >
                  <input checked={subscribeEmail} type="checkbox" onChange={() => setSubscribeEmail(!subscribeEmail)}  /><span style={{ fontSize: "12px" }}>Please send me email updates about events from Boundless Giving</span>
                </div>
                </div>
                
                <div className='signup-submit-button'>
                  <button disabled={invalid} type='submit'>
                    {loading ?
                      <div class="spinner-border spinner-border-sm" size role="status">
                      </div> : "Sign Up"
                    }
                  </button>
                  <div style={{ fontSize: "15px", marginTop: "5px" }}>Already have a verified account? <Link to={"/admin/sign-in"} style={{ color: "#FC3300", cursor: "pointer" }}>Sign In</Link></div>
                </div>
              </div>
            </form>
            </>
            }
          </div>
        </div>
      </div>
    </Container>
  )
}

export default SignUp