import logger from "../utils/logger";

const common = {
    // Add common config values here
    PORT: 8082
};


const dev = {
    REACT_APP_MODE: 'dev',
    REACT_APP_BASE_URL: "http://localhost:8080",
    AWS_BUCKET_NAME: "bg-assets-bucket-dev",
    AWS_IMAGE_DOMAIN: "https://bg-assets-bucket-dev.s3.us-east-1.amazonaws.com/",
    LOGO_URL: "https://bg-assets-bucket-dev.s3.amazonaws.com/bg_logo/BG-LOGO-NEW.png",
    REACT_USER_APP_BASE_URL: 'http://localhost:8081',
    ...common
};

const stage = {
    REACT_APP_MODE: 'qa',
    REACT_APP_BASE_URL: "https://stage.api.boundlessgiving.com",
    AWS_BUCKET_NAME: "bg-assets-bucket-stage",
    AWS_IMAGE_DOMAIN: "https://bg-assets-bucket-stage.s3.us-east-1.amazonaws.com/",
    LOGO_URL: "https://bg-assets-bucket-stage.s3.amazonaws.com/bg_logo/BG-LOGO-NEW.png",
    REACT_USER_APP_BASE_URL: 'https://stage.boundlessgiving.com',
    ...common
};


const prod = {
    REACT_APP_MODE: 'prod',
    REACT_APP_BASE_URL: "https://api.boundlessgiving.com",
    AWS_BUCKET_NAME: "bg-assets-bucket",
    AWS_IMAGE_DOMAIN: "https://images.boundlessgiving.com/",
    LOGO_URL: "https://images.boundlessgiving.com/bg_logo/BG-LOGO-NEW.png",
    REACT_USER_APP_BASE_URL: 'https://boundlessgiving.com',
    ...common
};
  
export const config = process.env.REACT_APP_MODE === 'prod' ?  prod : (process.env.REACT_APP_MODE === "qa" ? stage : dev);

logger('./config/config.js','Mode',config.mode);

export default { config } 