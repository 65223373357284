import {createSlice} from "@reduxjs/toolkit"


const initialState = {
    ///event item images///
    eventAuctionItemImages: [],
    eventAuctionItemVirtualAsset:[],
    eventStoreItemImages: [],
    eventStoreItemVirtualAsset:[],
    ///////////////////
    ///store images///
    storeAuctionItemImages:[],
    storeStoreAuctionItemVirtualAsset:[],
    storeStoreItemImages:[],
    storeStoreItemVirtualAsset:[],
    /////////////////
    ///Marketplace images///
    marketplaceAuctionItemImages:[],
    marketplaceStoreAuctionItemVirtualAsset:[],
    marketplaceStoreItemImages:[],
    marketplaceStoreItemVirtualAsset:[],
    ///////////////////
    //// event images///
    eventPortraitImage:'',
    eventLandscapeImage:'',
    eventLogoImage:null,
    ///////////////////
    /// store images///
    storePortraitImage:null,
    storeLandscapeImage:null,
    ///////////////////
    /// marketplace images///
    marketplacePortraitImage:'',
    marketplaceLandscapeImage:'',
    ///////////////////
    auctionItemSave:false,
    storeItemSave:false,
    tabValue:null
}

const persistSlice = createSlice({
    name: 'persistDatas',
    initialState,
    reducers: {
        setAuctionItemImages: (state, action) => {
            const images = action.payload?.auctionItemImages
            state[action.payload.key] = [...images]
        },
        setAuctionItemVirtualAssets: (state, action) => {
            const images = action.payload?.auctionItemVirtualAsset
            state[action.payload.key]= [...images]
        },
        setStoreItemImages: (state, action) => {
            const images = action.payload?.storeItemImages
            state[action.payload.key] = [...images]
        },
        setStoreItemVirtualAssets: (state, action) => {
            const images = action.payload?.storeItemVirtualAsset
            state[action.payload.key] = [...images]
        },
        clearAttribute: (state, action) => {
            state[action.payload.key] = initialState[action.payload.key]
        },
        toggleBooleanState: (state, action) => {
            state[action.payload.key] = action.payload.value
        },
        setTabValue: (state, action) => {
            state.tabValue = action.payload.tabValue
        },
        setAttribute: (state, action) => {
            state[action.payload?.key] = action.payload.value
        }
    }
})

export default persistSlice.reducer
export const {setAuctionItemImages, setStoreItemImages, clearAttribute, setAuctionItemVirtualAssets, setStoreItemVirtualAssets, toggleBooleanState, setTabValue, setAttribute} = persistSlice.actions