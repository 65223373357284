import classes from './visibilityContainer.module.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { Fragment } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: "1px solid black",
        textAlign:'center'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        textAlign:'center'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const VisibilityModal = ({ customCategories = [], setCustomCategories = () => null }) => {
    const handleVisibilityChange = (event, cat, catType='category') => {
        // catType is the array which can be category which is not saved in DB another one is fixedCategory which is saved in DB.
        const { checked } = event.target;
        if(catType === 'category') {
            setCustomCategories((prevCategories) =>
                prevCategories.map((catHead) => ({
                    ...catHead,
                    category: catHead.category.map((catObj) =>
                        catObj.tempId === cat.tempId
                            ? { ...catObj, show_everywhere: checked ? 1 : 0 }
                            : catObj
                    ),
                }))
            );
        }
        else {
            setCustomCategories((prevCategories) =>
                prevCategories.map((catHead) => ({
                    ...catHead,
                    fixedCategory: catHead.fixedCategory.map((catObj) =>
                        catObj.tempId === cat.tempId
                            ? { ...catObj, show_everywhere: checked ? 1 : 0 }
                            : catObj
                    ),
                }))
            );
        }
    }
    return (
        <div className={classes.visibilityContainer}>
            {
                !customCategories?.length ? <p className={classes.noCategories}>No Categories Where Added</p> :
                    <TableContainer>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Category</StyledTableCell>
                                    <StyledTableCell>Update Visibility In App</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    customCategories?.map((catHead) => {
                                        return (
                                            <Fragment key={catHead?.id}>
                                                {
                                                    catHead?.category?.map((cat) => {
                                                        return (
                                                            <StyledTableRow key={cat?.tempId} >
                                                                <StyledTableCell>{cat?.name}</StyledTableCell>
                                                                <StyledTableCell>
                                                                    <input checked={cat?.show_everywhere === 1} type="checkbox" onChange={(event) => handleVisibilityChange(event, cat)} />
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        )
                                                    })
                                                }
                                                {
                                                    catHead?.fixedCategory?.map((cat) => {
                                                        return (
                                                            <StyledTableRow key={cat?.tempId} >
                                                                <StyledTableCell>{cat?.name}</StyledTableCell>
                                                                <StyledTableCell>
                                                                    <input checked={cat?.show_everywhere === 1} type="checkbox" onChange={(event) => handleVisibilityChange(event, cat, 'fixedCategory')} />
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        )
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </div>
    )
}

export default VisibilityModal