import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import React from 'react';
import { COLORS_OBJECT } from '../../../constants/en';
const redColor = COLORS_OBJECT.color[0]

const CheckBoxComponent = ({ label = '', checked = false, value = '', onChange = () => null, size='small' }) => {
    return (
        <>
            <FormControlLabel
                onChange={onChange}
                control={
                    <Checkbox size={size}
                        value={value}
                        checked={checked}
                        sx={{ "&.Mui-checked": { color: redColor, } }} />
                }
                label={<Typography sx={{ fontSize: '0.85rem', fontWeight: '600', fontFamily: 'AvenirNext, sans-serif' }}>{label}</Typography>}
            />
        </>
    )
}

export default CheckBoxComponent