import React, { useState, useEffect } from 'react';
import auctionServices from '../../../../services/auction-services';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAvailableItemsOfDonors, setAvailableItemsLimit, setAvailableItemsPage, toggleAvailableItemsLoading } from '../../../../redux/availableDonorItemsReducer';
import LoadingModal from '../../../components/LoadingModal/LoadingModal';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material';
import TableButton from '../../../components/TableButton/TableButton';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import constants, { COLORS_OBJECT } from '../../../../constants/en';
import Modal from '../../../components/Modal/Modal';
import ItemPreview from '../../PreviewEvent/ItemPreview/ItemPreview';
import Alert from '../../../components/Alert/Alert';

const redColor = COLORS_OBJECT.color[0]
const shadyWhite = COLORS_OBJECT.backgroundColor[2]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const AvailableItems = ({ availableDonors, auction_id, setShowPrompt }) => {
    const { page, limit, loading, availableItems, totalAvailableItems } = useSelector(state => state.availableDonorItems)
    const [itemData, setItemData] = useState({})
    const [itemModal, setItemModal] = useState(false)
    const [alert, setAlert] = useState({message:'', showAlert:false})
    const [confirmAddAll, setConfirmAddAll] = useState({message:'', showAlert:false})
    const [selectedItems, setSelectedItems] = useState([])
    const [checkItem, setCheckItem] = useState([])
    const [selectAllClicked, setSelectAllClicked] = useState(false)
    const [filterdFromAllSelected, setFilterdFromAllSelected] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchAvailableItemsOfDonors({ donors: availableDonors, auction_id: auction_id, page: page, limit: limit }))
    }, [page, limit])

    useEffect(() => {
        setShowPrompt(false)
    },[])

    useEffect(() => {
        if(totalAvailableItems === checkItem?.length) {
            setSelectAllClicked(true)
        }
        if(totalAvailableItems === filterdFromAllSelected?.length) {
            setSelectAllClicked(false)
        }
    },[checkItem])

    const viewItem = async(item) => {
        setItemData(item)
        setItemModal(true)
    }
    const addItem = async(itemId) => {
        try{
            dispatch(toggleAvailableItemsLoading(true))
            const data = await auctionServices.createAuctionAuctionItemXref(itemId, auction_id)
            dispatch(fetchAvailableItemsOfDonors({ donors: availableDonors, auction_id: auction_id, page: page, limit: limit }))
            setAlert({showAlert:true, message: data?.msg || 'Item added successfully'})
            dispatch(toggleAvailableItemsLoading(false))
        }catch(error) {
            setAlert({showAlert:true, message:"Something went wrong!"})
            dispatch(toggleAvailableItemsLoading(false))
        }
    }

    const findXrefStatus = (item) => {
        let xrefStatus = 'pending'
        if(item?.auction?.market_place == '1') {
            /// if its marketplace we keep the status as pending
            xrefStatus = 'pending'
        }
        else {
            if(item?.status === 'active') {
                // if item status is active we keep xref status as active
                xrefStatus = 'active'
            }
            else {
                xrefStatus = 'pending'
            }
        }
        return xrefStatus;
    }

    const handleSelectChange = (event, item) => {
        if(event.target?.checked) {
            const xrefStatus = findXrefStatus(item)
            setSelectedItems([...selectedItems,{
                auction_id:auction_id,
                auction_item_id: item?.id,
                status: xrefStatus,
            }])
            setCheckItem([...checkItem, item?.id])
            /// if selected again after clearing from all selected
            setFilterdFromAllSelected(prevState => prevState?.filter((obj) => obj !== item?.id))
        }
        else {
                setSelectedItems(prevState => prevState?.filter((obj)=> obj?.auction_item_id !== item?.id ))
                setCheckItem(prevState => prevState?.filter((obj) => obj !== item?.id))
                /// To filter from all selected
                setFilterdFromAllSelected([...filterdFromAllSelected, item?.id])
        }
    }

    const handleSelectAllClick = async() => {
            setFilterdFromAllSelected([])
            // if(checkItem?.length === availableItems?.length) {
            if(selectAllClicked) {
                setSelectedItems([])
                setCheckItem([])
                setSelectAllClicked(false)
            }
            else {
                setSelectAllClicked(true)
                const availableItemsId = availableItems?.map((item)=> item?.id)
                setCheckItem(availableItemsId)
            }
    }

    const handleAddAllClick = () => {
            setConfirmAddAll({message:constants.ADMIN_COPY_ADD_ALL_ALERT, showAlert:true})
    }

    const handleConfirmAddAll = async() => {
        try {
            setConfirmAddAll({message:'', showAlert:false})
            dispatch(toggleAvailableItemsLoading(true))
            ///selectAllClicked, this means all items are selected in this case from backend we don't need to consider about selectedItems
            // we just need to find availabe items from backend and create data
            /// filterdFromAllSelected is used to remove from while adding if it is cleared after selecting all
            const data = await auctionServices.bulkCreateAuctionAuctionItemXref(selectedItems, auction_id,selectAllClicked, availableDonors, filterdFromAllSelected)
            if(page === 1) {
                dispatch(fetchAvailableItemsOfDonors({ donors: availableDonors, auction_id: auction_id, page: page, limit: limit }))
            }
            else {
                dispatch(setAvailableItemsPage(1))
            }
            dispatch(toggleAvailableItemsLoading(false))
            setAlert({showAlert:true, message: data?.msg || 'Items added successfully'})
            setSelectedItems([])
            setCheckItem([])
            setSelectAllClicked(false)
            setFilterdFromAllSelected([])
        } catch (error) {
            dispatch(toggleAvailableItemsLoading(false))
            setConfirmAddAll({message:'', showAlert:false})
        }
    }

    return (
        <>
            {
                loading ? <LoadingModal isOpen={true} /> :
                    availableItems && availableItems?.length ?
                        (
                            <Paper>
                                <button onClick={handleSelectAllClick} className='aitem-modal-btn add-all-button'>
                                    {selectAllClicked  ? 'Remove All' : 'Select All'}
                                </button>
                                {selectAllClicked || checkItem?.length ?
                                    <button onClick={handleAddAllClick} className='aitem-modal-btn aitem-white-button add-all-button'>Add All</button>
                                : null}
                                <TableContainer>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Name</StyledTableCell>
                                                <StyledTableCell>Price</StyledTableCell>
                                                <StyledTableCell>Quantity</StyledTableCell>
                                                <StyledTableCell>Supplier</StyledTableCell>
                                                <StyledTableCell>Actions</StyledTableCell>
                                                <StyledTableCell>Select</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                availableItems?.length ? availableItems?.map((item, index) => {
                                                    return (
                                                        <StyledTableRow key={index}>
                                                            <StyledTableCell>{item?.name}</StyledTableCell>
                                                            <StyledTableCell>{item?.store_item?.price}</StyledTableCell>
                                                            <StyledTableCell>{item?.store_item?.quantity_in_stock}</StyledTableCell>
                                                            <StyledTableCell>{item?.donor?.org_name}</StyledTableCell>
                                                            <StyledTableCell>
                                                               <div style={{display:'flex', alignItems:"center", gap:'10px'}} >
                                                                  <TableButton onClick={()=>viewItem(item)} Icon={RemoveRedEyeOutlinedIcon} needText={false} />
                                                                  <TableButton onClick={()=>addItem(item?.id)} color='white' backgroundColor={redColor} text='Add' />
                                                               </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <input 
                                                                   checked={(selectAllClicked && !filterdFromAllSelected?.includes(item?.id)) || checkItem.includes(item?.id) } 
                                                                   type="checkbox" 
                                                                   value={item?.id} 
                                                                   onChange={(event)=>handleSelectChange(event,item)} 
                                                                />
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    )
                                                })
                                                    : null
                                            }
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 25]}
                                        component="div"
                                        count={totalAvailableItems}
                                        rowsPerPage={limit}
                                        page={page - 1}
                                        onPageChange={(e, newPage) => dispatch(setAvailableItemsPage(newPage + 1))}
                                        onRowsPerPageChange={(e) => dispatch(setAvailableItemsLimit(+e.target.value))}
                                    />
                                </TableContainer>
                            </Paper>
                        )
                        : <div style={{padding: '20px 0', textAlign: "center"}}>No items Found</div>
            }
            <Modal 
               buttonText='Close' 
               onDone={() =>setItemModal(false)} 
               isOpen={itemModal} 
               onClose={() => setItemModal(false)} 
               title='Item Details' 
               contentStyle={{width:'90%', backgroundColor:shadyWhite}}
               childrenContainerStyle={{padding:0}}
            >
                <ItemPreview item={itemData} goBack={() => null} needEditButton={false} needGoBackButton={false} showShare={true} />
            </Modal>
            <Alert message={alert.message} isOpen={alert.showAlert} onClose={() => {setAlert({...alert, showAlert:false})}} />
            <Alert type='confirm' message={confirmAddAll.message} isOpen={confirmAddAll.showAlert} onConfirm={handleConfirmAddAll} onClose={() => {setConfirmAddAll({message:'', showAlert:false})}} />
        </>
    )
}

export default AvailableItems;