const states = [
    {
      label: 'Alabama',
      value: 'AL',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Alaska',
      value: 'AK',
      territory: false,
      contiguous: false,
    },
    {
      label: 'American Samoa',
      value: 'AS',
      territory: true,
      contiguous: false,
    },
    {
      label: 'Arizona',
      value: 'AZ',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Arkansas',
      value: 'AR',
      territory: false,
      contiguous: true,
    },
    {
      label: 'California',
      value: 'CA',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Colorado',
      value: 'CO',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Connecticut',
      value: 'CT',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Delaware',
      value: 'DE',
      territory: false,
      contiguous: true,
    },
    {
      label: 'District Of Columbia',
      value: 'DC',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Florida',
      value: 'FL',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Georgia',
      value: 'GA',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Guam',
      value: 'GU',
      territory: true,
      contiguous: false,
    },
    {
      label: 'Hawaii',
      value: 'HI',
      territory: false,
      contiguous: false,
    },
    {
      label: 'Idaho',
      value: 'ID',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Illinois',
      value: 'IL',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Indiana',
      value: 'IN',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Iowa',
      value: 'IA',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Kansas',
      value: 'KS',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Kentucky',
      value: 'KY',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Louisiana',
      value: 'LA',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Maine',
      value: 'ME',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Maryland',
      value: 'MD',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Massachusetts',
      value: 'MA',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Michigan',
      value: 'MI',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Minnesota',
      value: 'MN',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Mississippi',
      value: 'MS',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Missouri',
      value: 'MO',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Montana',
      value: 'MT',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Nebraska',
      value: 'NE',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Nevada',
      value: 'NV',
      territory: false,
      contiguous: true,
    },
    {
      label: 'New Hampshire',
      value: 'NH',
      territory: false,
      contiguous: true,
    },
    {
      label: 'New Jersey',
      value: 'NJ',
      territory: false,
      contiguous: true,
    },
    {
      label: 'New Mexico',
      value: 'NM',
      territory: false,
      contiguous: true,
    },
    {
      label: 'New York',
      value: 'NY',
      territory: false,
      contiguous: true,
    },
    {
      label: 'North Carolina',
      value: 'NC',
      territory: false,
      contiguous: true,
    },
    {
      label: 'North Dakota',
      value: 'ND',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Northern Mariana Islands',
      value: 'MP',
      territory: true,
      contiguous: false,
    },
    {
      label: 'Ohio',
      value: 'OH',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Oklahoma',
      value: 'OK',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Oregon',
      value: 'OR',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Palau',
      value: 'PW',
      territory: true,
      contiguous: false,
    },
    {
      label: 'Pennsylvania',
      value: 'PA',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Puerto Rico',
      value: 'PR',
      territory: true,
      contiguous: false,
    },
    {
      label: 'Rhode Island',
      value: 'RI',
      territory: false,
      contiguous: true,
    },
    {
      label: 'South Carolina',
      value: 'SC',
      territory: false,
      contiguous: true,
    },
    {
      label: 'South Dakota',
      value: 'SD',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Tennessee',
      value: 'TN',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Texas',
      value: 'TX',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Utah',
      value: 'UT',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Vermont',
      value: 'VT',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Virgin Islands',
      value: 'VI',
      territory: true,
      contiguous: false,
    },
    {
      label: 'Virginia',
      value: 'VA',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Washington',
      value: 'WA',
      territory: false,
      contiguous: true,
    },
    {
      label: 'West Virginia',
      value: 'WV',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Wisconsin',
      value: 'WI',
      territory: false,
      contiguous: true,
    },
    {
      label: 'Wyoming',
      value: 'WY',
      territory: false,
      contiguous: true,
    },
  ];
  
  export default states;