import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import auctionServices from '../services/auction-services';
import logger from '../utils/logger';


export const getEventSummery = createAsyncThunk(
    'redux/getEventSummery',
    async ({auctionId, marketplace, store}) => {
        const response = await auctionServices.getEventSummery(auctionId, marketplace, store)
        return response
    }
)
export const getAuctionReport = createAsyncThunk(
    'redux/getAuctionReport',
    async (auctionId, { getState }) => {
        const state = getState();
        const response = await auctionServices.getAuctionReport(auctionId, state.report.auctionPage, state.report.auctionLimit, state.report.customDateRange, state.report.soldStatusFilter, null, state.report.orderSearchValue, state.report.orderSearchBy)
        return response.data
    }
)

const initialState = {
    selectedReport: { label: 'Order Report', value: 'order' },
    selectedDateRange: { label: 'Last 7 Days', value: '7d' },
    customDateRange: { start: '', end: '' },
    soldStatusFilter: { showSold: true, showUnsold: true, showDelivered: true,showRunning:true,showFailed:true },
    auctionReport: [],
    eventSummery: {
        openAuctions: { totalOpenBidCount: 0, totalWinningBid: 0 ,openAuctionCount :0},
        sales: { amount: 0, orderCount: 0, proceeds: 0, totalProceedsAmount: 0 }
    },
    totalAuctions: 0,
    auctionPage: 1,
    auctionLimit: 10,
    summeryLoading: false,
    auctionLoading:false,
    error: null,
    orderSearchValue:'',
    orderSearchBy:'item'
}


const ReportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        setAuctionPage: (state, action) => {
            state.auctionPage = action.payload.page
            state.auctionLimit = action.payload.limit ?? state.auctionLimit
        },
        setSelectedReport: (state, action) => {
            state.selectedReport = action.payload
        },
        setSelectedDateRange: (state, action) => {
            state.selectedDateRange = action.payload
        },
        setCustomDateRange: (state, action) => {
            state.customDateRange.start = action.payload.start ?? state.customDateRange.start
            state.customDateRange.end = action.payload.end ?? state.customDateRange.end
        },
        setSoldStatusFilter: (state, action) => {
            state.soldStatusFilter.showDelivered = action.payload.showDelivered ?? state.soldStatusFilter.showDelivered
            state.soldStatusFilter.showSold = action.payload.showSold ?? state.soldStatusFilter.showSold
            state.soldStatusFilter.showUnsold = action.payload.showUnsold ?? state.soldStatusFilter.showUnsold
            state.soldStatusFilter.showRunning = action.payload.showRunning ?? state.soldStatusFilter.showRunning
            state.soldStatusFilter.showFailed = action.payload.showFailed ?? state.soldStatusFilter.showFailed
        },
        clearError :(state,action) => {
            state.error = null
        },
        clearSummary: (state, action) => {
            state.eventSummery =  {
                openAuctions: { totalOpenBidCount: 0, totalWinningBid: 0,openAuctionCount :0 },
                sales: { amount: 0, orderCount: 0, proceeds: 0, totalProceedsAmount: 0 }
            }
        },
        clearAuctionReport: (state, action) => {
            state.auctionReport = [];
            state.selectedReport = { label: 'Order Report', value: 'order' };
            state.selectedDateRange = { label: 'Last 7 Days', value: '7d' };
        },
        setOrderSearchValue: (state, action) => {
            state.orderSearchValue = action.payload.orderSearchValue
        },
        setOrderSearchBy: (state, action) => {
            state.orderSearchBy = action.payload.orderSearchBy
        }

    },
    extraReducers: (builder) => {
        //event summery builders
        builder.addCase(getEventSummery.fulfilled, (state, action) => {
            state.eventSummery.openAuctions = action.payload.openAuctions;
            state.eventSummery.sales = action.payload.sales;
            state.summeryLoading = false;
            state.error = null
        })
        builder.addCase(getEventSummery.pending, (state, action) => {
            state.summeryLoading = true;
        })
        builder.addCase(getEventSummery.rejected, (state, action) => {
            state.error = action.error.message;
            state.summeryLoading = false;

            logger('./redux/ReportReducer', 'getAuctionReport(): catch1', action.error);
        })

        //Auction report builders
        builder.addCase(getAuctionReport.fulfilled, (state, action) => {
            state.auctionReport = action.payload.rows;
            state.totalAuctions = action.payload.count;
            state.auctionLoading = false;
            state.error = null
        })
        builder.addCase(getAuctionReport.pending, (state, action) => {
            state.auctionLoading = true;
        })
        builder.addCase(getAuctionReport.rejected, (state, action) => {
            state.error = action.error.message;
            state.auctionLoading = false;

            logger('./redux/ReportReducer', 'getAuctionReport(): catch1', action.error);
        })
    },
})

export default ReportSlice.reducer;
export const { setAuctionPage, setSelectedReport, setSelectedDateRange, setCustomDateRange, setSoldStatusFilter,clearError, clearSummary, clearAuctionReport, setOrderSearchValue, setOrderSearchBy } = ReportSlice.actions



