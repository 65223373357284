import { useNavigate } from "react-router-dom";
import Container from "../../components/HOC/Container/Container";
import ImageInput from "../../components/ImageInput/ImageInput";
import { useRef, useState } from "react";
import constants from "../../../constants/en";
import checkImageBeforeUpload from "../../../utils/checkImageBeforeUpload";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import auctionServices from "../../../services/auction-services";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import "./uploadStaticImage.css";

const StaticImageUpload = () => {
  const navigate = useNavigate();
  const [imageLoading, setImageLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(null);
  const myInput = useRef();
  const [textCopied, setTextCopied] = useState(false);

  const handleImageUpload = async (e) => {
    try {
      if (!e.target.files[0]) return;
      setImageLoading(true);
      const image = e.target.files[0];
      if (image.size > 5000000) {
        alert(constants.ADMIN_COPY_EVENT_IMG_FILE_SIZE);
        setImageLoading(false);
        return;
      }
      setTextCopied(false)
      setUrl(null)
      const imageUrl = URL.createObjectURL(image);
      const { width, height } = await checkImageBeforeUpload(imageUrl);

      setImages([{ name: imageUrl, type: "img" }]);
      // const uint8 = new Uint8Array(preProcessedImage.buffer.data)
      // const newBlob = new Blob([uint8], { type: "image/jpeg" })
      setImageFile(image);
      setImageLoading(false);
    } catch (error) {
      setImageLoading(false);
    }
  };

  const copyUrl = (e) => {
    const copyText = myInput.current;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    setTextCopied(true);
    setTimeout(()=>{
      setTextCopied(false)
    },3000)
  };

  const handleSubmit = async () => {
    try {
      if (!imageFile) return;
      setIsLoading(true);
      let formData = new FormData();
      formData.append("static_image", imageFile);
      let data = await auctionServices.uploadStaticImage(formData);
      // console.log(data);
      setUrl(data?.url);
      setIsLoading(false);
      setImageFile(null);
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Container
        title="Upload Image"
      >
        <div className="back-btn-container">
          <button
            onClick={()=>{navigate('/admin')}}
            className="event-submit-btn"
          >
            Go Back
          </button>
        </div>
        <ImageInput
          loading={imageLoading}
          // required
          edit
          onDelete={() => {
            setImages([]);
            setImageFile(null);
          }}
          label="Upload image to get url"
          handleChange={handleImageUpload}
          images={images ? images : []}
          max={1}
        />
        <div className='upload-btn-container'>
          <button
            onClick={handleSubmit}
            className="event-submit-btn"
            type="submit"
          >
            UPLOAD
          </button>
        </div>
        {url && (
          <>
          <h5 className="copy_heading" >Copy your image url</h5>
          <div className="copy_url">
            <input ref={myInput} value={url} readOnly />
            <div onClick={copyUrl}>
              {textCopied ? (
                <CheckCircleOutlineOutlinedIcon sx={{ color: "green" }} />
              ) : (
                <ContentCopyOutlinedIcon />
              )}
            </div>
          </div></>
          
        )}
      </Container>
      <LoadingModal isOpen={isLoading} />
    </>
  );
};

export default StaticImageUpload;
