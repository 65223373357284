import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import orderServices from '../services/order-services'


export const fetchDonorUserReport = createAsyncThunk(
    'users/fetchDonorReport',
    async ({auctionId, page, limit, startDate, endDate, marketplace, store, orderSearchValue, orderSearchBy}) => {
        const response = await orderServices.getDonorOrder(auctionId, page, limit, startDate, endDate, marketplace, store, orderSearchValue, orderSearchBy)
        return {
            data: response?.data?.rows,
            totalDonors: response?.data?.count?.length,
        }
    }
)

const initialState = {
    donorData: [],
    totalDonors: 0,
    donorReportLoading:false,
    error:''
}

const donorReportSlice = createSlice({
    name: 'users/donors',
    initialState,
    reducers:{
        clearDonorReport: (state, action) => {
            state.donorData = []
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDonorUserReport.pending, (state, action) => {
            state.donorReportLoading=true
        })
        builder.addCase(fetchDonorUserReport.fulfilled, (state, action) => {
            state.donorData = action.payload?.data
            state.totalDonors = action.payload?.totalDonors
            state.donorReportLoading = false
            state.error = ""
        })
        builder.addCase(fetchDonorUserReport.rejected, (state, action) => {
            state.donorReportLoading = false
            state.error = action.payload?.error?.message
        })
    }
})


export default donorReportSlice.reducer
export const {clearDonorReport} = donorReportSlice.actions