import { useRef } from 'react';
import styles from './AIGeneratedData.module.css'
// import { ContentCopyOutlined, CheckCircleOutlineOutlined } from "@mui/icons-material";

const AIGeneratedData = ({ data = null , handleGeneratedData=()=>null}) => {
  const descRef = useRef();
  // const [copied, setCopied] = useState(false)
  const copyDescription = () => {
    // navigator.clipboard.writeText(descRef.current.innerText)
    // setCopied(true)
    handleGeneratedData({description:descRef.current.innerText})
  }
  // useEffect(() => {
  //   return () => {
  //     setCopied(false)
  //   }
  // },[])
  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <div className={styles.descTop}>
          <p>Description</p>
          {/* <p onClick={copyDescription} >{ copied ? 'Text Copied' : 'Copy Text'} { copied ? <CheckCircleOutlineOutlined sx={{color:'green'}}/> : <ContentCopyOutlined  />}</p> */}
          <p onClick={copyDescription} >Make this as product description.</p>
        </div>
        <p ref={descRef} className={styles.longDesc}  >{data?.description}</p>
        {/* <p ref={descRef} className={styles.longDesc} >In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.</p> */}
      </div>
      {/* <div className={styles.description}>
        <div className={styles.descTop}>
          <p>Tags</p>
        </div>
        <div className={styles.tagContainer}>
          {
            data?.tags?.map((item) => {
              return (
                <p className={styles.tags}>{item?.name}</p>
              )
            })
          }
        </div>
      </div> */}
    </div>
  )
}

export default AIGeneratedData;