import React, { useContext, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Router from "./routes";
import Header from "./pages/components/Header/Header";
import Footer from "./pages/components/Footer/Footer";
import Sidebar from "./pages/components/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { fetchEvents } from "./redux/eventReducer";
import AuthContext from "./store/auth-context";

const NO_SIDEBAR_URLS = [
  '/admin/sign-in',
  '/admin/sign-up',
  '/admin/not-found',
  '/admin/auth/verify-email',
  '/admin/reset-pwd',
  '/admin/change-email',
  '/admin/auth/verify-change-email',
  '/admin/deployment-status',
  '/admin/tos',
  '/admin/privacy',
  '/admin/faq',
  '/admin/upload-image'
]

const needEventPagination = [
   '/admin/my-events',
   '/admin/event-dashboard'
]

const hideSidebar = (pathname) => {
  return NO_SIDEBAR_URLS.indexOf(pathname) !== -1
}


function App() {
  const dispatch = useDispatch()
  const ctx = useContext(AuthContext)
  const location = useLocation()
  useEffect(() => {
    if(needEventPagination.includes(location.pathname)) return 
    // if(ctx.isLoggedIn) {
    //   dispatch(fetchEvents({user_id: ctx.user.id}))
    // }
  }, [ctx.isLoggedIn])
  return (
    <>
      <div className="APP">
        <Header />
        <div className="APP-main-container">
          {hideSidebar(location.pathname) ? null : <Sidebar />}
          <Router />
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default App;
