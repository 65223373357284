import React from 'react'
import "./Alert.css"

const Alert = ({isOpen, type='alert', message, onClose, onConfirm, style={},okBtnText='Okay'}) => {
    if(!isOpen) return null
  return (
    <div className='bg-alert' style={{...style}}>
        <div className='bg-alert-box'>
            <div className='bg-alert-content'>{message}</div>
            <div className='bg-alert-btn-container'>
                {type !== 'alert' && <button onClick={onClose} type='button' className='bg-alert-cancel'>Cancel</button>}
                <button onClick={type === 'alert' ? onClose : onConfirm} type='button' className='bg-alert-ok'>{type === 'alert' ? 'Okay' : `${okBtnText}`}</button>
            </div>
        </div>
    </div>
  )
}

export default Alert