import { useCallback, useContext, useEffect, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { setItemToLocalStorage } from '../../utils/autoSave';

function useConfirmExit(confirmExit, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }
    const push = navigator.push;

    navigator.push = (...args) => {
      const result = confirmExit();
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}

function usePrompt(message, when = true, localStorageKey='', stateData=null ) {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message);
    if(confirm) {
      /// for saving state to local storage
      if(stateData && localStorageKey !== '') {
        // localStorage.setItem(localStorageKey, JSON.stringify(stateData))
        setItemToLocalStorage(localStorageKey, stateData)
      }
    }
    return confirm;
  }, [message, stateData]);
  useConfirmExit(confirmExit, when);
}

export default usePrompt