import React, { useState } from 'react'
import Container from '../../../components/HOC/Container/Container'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment';
import "./ItemPreview.css"
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Alert from '../../../components/Alert/Alert';
import auctionServices from '../../../../services/auction-services';
import VariantsModalComponent from '../../../components/VariantsModalComponent/VariantsModalComponent';

const ItemPreview = ({item, goBack=()=>null, needGoBackButton=true, needEditButton=true, showShare=false}) => {
    const [errorMessage,setErrorMessage] = useState(null)
    const [errorAlert, setErrorAlert] = useState(false)
    const [variants, setVariants] = useState([])
    const [options, setOptions] = useState({})
    const navigate = useNavigate()
    const location = useLocation()
    const handleEdit = (item, type) => { 
        if((moment(item.end_date).format() < moment().format()) &&( type === "auction")){
            setErrorMessage("You are not allowed to edit completed auction item");
            setErrorAlert(true)
            return
          }else{
            // navigate(`/admin/items/edit/${item.auction_id}/${item.id}`, {state: {type: type}})
            if(location.pathname.startsWith('/admin/event-preview')) navigate(`/admin/items/edit/${item.auction_id}/${item.id}`, {state: {type: type}})
            if(location.pathname.startsWith('/admin/market-place-preview')) navigate(`/admin/market-place/items/edit/${item.auction_id}/${item.id}`, {state: {type: type}})
            if(location.pathname.startsWith('/admin/store-preview')) navigate(`/admin/store/items/edit/${item.auction_id}/${item.id}`, {state: {type: type}})  
        }
    }

    useEffect(() => {
        if(item?.store_item?.store_item_actual_inventories?.length > 0 ){
            fetchVariants()
        }
    },[])

    const fetchVariants = async() => {
        const response = await auctionServices.getVariantInformation(item.auction_id,item.store_item.id)
        // const newVariants = Object.values(response.data.variants).map(obj => {
        //     const newVar = {}
        //     for(let key of Object.keys(obj)) {
        //       newVar[key.trim()] = typeof(obj[key]) === 'string' ? obj[key].trim() : obj[key]
        //     }
        //     return newVar
        //   })
          setVariants(response.data.variants)
          setOptions(response.data.options)
      }


  return (
    <Container>
        {needGoBackButton && <div style={{width:'fit-content'}} className='aitem-modal-btn'>
            <div onClick={goBack}><ArrowBackIcon sx={{fontSize: "18px"}} />Go Back</div>
        </div>}
        <div className='item-p-main'>
            <div className='item-p-container'>
                <div style={{ gridTemplateColumns:"1fr", fontSize: "2rem", fontWeight: "600", marginBottom: "13px"}}>{item.name}</div>
                <div>
                    <div>Item Type:</div>
                    <div>{item.store_item ? "Store Item": "Auction Item"}</div>
                </div>
                <div>
                    <div>Price:</div>
                    <div>${item.store_item ? item.store_item.price : item.price}</div>
                </div>
                {
                    item?.auction_item_category_xrefs?.length ?
                    <div>
                        <div>Categories:</div>
                        <div>
                        {item?.auction_item_category_xrefs?.map((category, index) => (
                            <React.Fragment key={index}>
                              {category?.category ? (
                                <>
                                  {category?.category?.name}
                                  {item?.auction_item_category_xrefs?.length !== index + 1 ? ', ' : null}
                                </>
                              ) : (
                                <>
                                  {category?.custom_category?.category_name}
                                  {item?.auction_item_category_xrefs?.length !== index + 1 ? ', ' : null}
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                    </div>
                    : null
                }
                <div>
                    <div>Short Description:</div>
                    <div>{item.short_description}</div>
                </div>
                <div>
                    <div>Description:</div>
                    <div>{item.description}</div>
                </div>
                {
                    !item.store_item && item.buy_now_price ? 
                    <div>
                        <div>Buy Now Price:</div>
                        <div>{item.buy_now_price}</div>
                    </div>
                    : null
                }
                {
                    !item.store_item && item.reserve_price ? 
                    <div>
                        <div>Reserve Price:</div>
                        <div>{item.reserve_price}</div>
                    </div>
                    : null
                }
                <div>
                    <div>Is A Virtual Item:</div>
                    <div>{item.is_virtual === 1 ? "YES" : "NO"}</div>
                </div>
                {
                    item.is_virtual !== 1 ? 
                    <div>
                        <div>Item Weight:</div>
                        <div>{item.weight}lbs</div>
                    </div> : null
                }
                {
                    item.store_item ? (
                        <>
                            <div>
                                <div>List Price:</div>
                                <div>${item.store_item.list_price}</div>
                            </div>
                            <div>
                                <div>Quantity in stock:</div>
                                <div>{item.store_item.quantity_in_stock}</div>
                            </div>
                        </>
                    ) : null
                }
                {
                    item.store_item ? (
                        item.store_item && item?.store_item?.store_item_actual_inventories?.length > 0 ? 
                        <div style={{ gridTemplateColumns:"1fr"}}>
                        <div>SKU:</div>
                         <VariantsModalComponent variants={variants} options={options}/> 
                        </div>:
                         <div>
                            <div>SKU:</div>
                            <div>{item.store_item.sku}</div>
                          </div>
                    )
               :
            <div>
               <div>SKU:</div>
               <div>{item.sku}</div>
           </div> 
           }
            <div>
               <div>Donor:</div>
               <div>{item.donor?.org_name}</div>
           </div>
           { showShare && 
           (item?.donor?.revenue_splits?.length ? <>
            { item?.donor?.revenue_splits[0]?.bg_rev_share_percent ? 
            <div>
                <div>BG Share Percentage</div>
                <div>{item?.donor?.revenue_splits[0]?.bg_rev_share_percent}%</div>
            </div> 
            : null}
            { item?.donor?.revenue_splits[0]?.donor_rev_share_percent ? 
            <div>
                <div>Donor Share Percentage</div>
                <div>{item?.donor?.revenue_splits[0]?.donor_rev_share_percent}%</div>
            </div> 
            : null}
           { item?.donor?.revenue_splits[0]?.cause_rev_share_percent ? 
           <div>
                <div>Cause Share Percentage</div>
                <div>{item?.donor?.revenue_splits[0]?.cause_rev_share_percent}%</div>
            </div> 
            : null }
            </> 
            : 
            <>
            <div>
                <div>BG Share Percentage</div>
                <div>20%</div>
            </div> 
           <div>
                <div>Cause Share Percentage</div>
                <div>80%</div>
            </div> 
            </>
            )
            }

           </div>
            <div className='item-p-images'>
                {
                    item.assets.filter(img => img.context_type === 'portrait').map(image => (
                        <div className='item-p-image'>
                            <img src={image.name} alt="" />
                        </div>
                    ))
                }
            </div>
        </div>
        {needEditButton && 
        (!item?.auction_auction_item_xrefs?.length ?
        <div className='aitem-modal-btn' style={{float:'right'}}>
            <div onClick={() => {handleEdit(item, item.store_item ? "store": "auction")}}>Edit Details</div>
        </div>
        :null)
        }
        <Alert message={errorMessage} isOpen={errorAlert} onClose={() => setErrorAlert(false)} type="alert" />
    </Container>
  )
}

export default ItemPreview