import { Form } from 'react-bootstrap';

export function Input({type='text',label="",placeholder,value='',onchange=()=>{},required=false,name='',style, disabled=false}) {
    return(
        <Form.Group style={style?style:{width:'90%'}}>
            {label !== '' && <Form.Label>{label}{' '}{required&&<span style={{ color: '#f00' }}>*</span>}</Form.Label>}
            <Form.Control
                type={type!=='text'?'number':'text'}
                onWheel={type!=='text'?(e) => e.target.blur():null}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onchange}
                disabled={disabled}
                style={{borderRadius:"25px"}}
            />
        </Form.Group>
    )
}
export function Select({label="",Options,value,onchange=()=>{},placeholder='',required=false,name='',style={}, disabled=false}) {
    return(
        <div style={{width:'90%', ...style}}>
          {label !== '' && <Form.Label>{label}{' '}{required&&<span style={{ color: '#f00' }}>*</span>}</Form.Label> }
          <Form.Select
            aria-label={label}
            name={name}
            value={value}
            onChange={onchange}
            disabled={disabled}
            style={{borderRadius:"25px"}}
          >
            {/* Open this select menu */}
            {placeholder&&<option disabled={Boolean(value)} value=''>{placeholder}</option>}
            <Options />
          </Form.Select>
        </div>
    )
}

export function DateInp({label="",min,max,value,defaultValue,onChange=()=>{},required=false,name='',placeholder='',style, disabled=false}) {
    return (
        <Form.Group style={style?style:{width:'90%'}}>
         {label !== '' &&  <Form.Label>{label}{' '}{required&&<span style={{ color: 'red' }}>*</span>}</Form.Label> }
          <Form.Control
            type='date'
            onKeyDown={(e) => e.preventDefault()}
            min={min}
            max={max}
            value={value}
            name={name}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            style={{borderRadius:"25px"}}
          />
        </Form.Group>
    )
}