import "./AddEvents.css";
import Container from "../../components/HOC/Container/Container";
import Input from "../../components/Input/Input";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import auctionServices from "../../../services/auction-services";
import { useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import Alert from '../../components/Alert/Alert';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LoadingModal from "../../components/LoadingModal/LoadingModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    borderBottom: "1px solid black"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const AddEvents = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const { donor } = location.state;
  const [events, setEvents] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [alertMsg, setAlertMsg] = useState(null);
  const [flag,setFlag] = useState(false);
  const [assignedEvents,SetAssignedEvents] =useState([]);
  const [successMsg,setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const getUnassignedEvents = async () => {
   try {
    setLoading(true)
    const data = await auctionServices.getAllUnassignedEvents(donor.id);
    setLoading(false)
    setEvents(
      data?.data.map((obj, i) => {
        return { key: obj.title, value: obj.id };
      })
    );
   }
   catch(error) {
    setLoading(false)
   }
  };

  const getAssignedEvents = async () => {
    try {
      setLoading(true)
      const data = await auctionServices.getAllassignedEvents(donor.id);
      setLoading(false)
      SetAssignedEvents( data?.data );
    }
    catch(error) {
      setLoading(false)
    }
  };

  useEffect(() => {
    getUnassignedEvents();
    getAssignedEvents();
  }, [flag]);

  const handleRemove=  async(auction_id) =>{
    setLoading(true)
     await auctionServices.removeAssignedEvents(donor.id,auction_id).then((res)=>{
      setLoading(false)
      setAlertMsg(res?.data?.msg);
      setFlag(!flag)
     }).catch((err)=>{
      setLoading(false)
      throw err
     })
  }

  const handleSubmit = async () => {
    try{
      if(selectedEvents.length > 0){
        let formData = new FormData();
        formData.append("assignedEvents",JSON.stringify(selectedEvents));
        formData.append("donor_id",donor.id)
        setLoading(true)
         await auctionServices.postAssignedEvents({
          assignedEvents:JSON.stringify(selectedEvents),
          donor_id: donor.id
         }).then((res)=>{
          setLoading(false)
          setSuccessMessage(res.data.msg);
         
         }).catch((err)=>{
          console.log(err)
          setLoading(false)
         })
       }else{
        setAlertMsg("Please select event");
        setLoading(false)
       }
    }catch(error){
      throw error
      setLoading(false)
    }
     
  }

  const handleSuccessAlertClose = () =>{
    setSuccessMessage(null);
    setFlag(!flag)
    setSelectedEvents([])
    navigate("/admin/manage-donor")
  }
  return (
    <Container title="Add Events to Donor">
      <div className="add-events-form-container">
        <Input
          label="Donor Name"
          value={donor.contact_firstname + " " + donor.contact_lastname}
          disabled
        />
        <label className="input-label">Select Events</label>
        <Multiselect
          displayValue="key"
          onRemove={(e) => {
            setSelectedEvents(e)}}
          onKeyPressFn={function noRefCheck() {}}
          onSelect={(e) => {
            setSelectedEvents(e)}}
          options={events}
          showArrow
          avoidHighlightFirstOption
          style={{'optionContainer':{maxHeight:'200px'}}}
        />
{
  (events.length > 0) ? 
  <div className="donor-event-btn" style={{textAlign:"center"}}>
            <button className="event-submit-btn" onClick={handleSubmit}>Submit</button>
        </div>
   : 
   <p className="no-events">All Events are Assigned to the donor</p>
}
        
 <Alert isOpen={!!alertMsg} message={alertMsg} onClose={() => setAlertMsg(null)} />
 <Alert isOpen={!!successMsg} message={successMsg} onClose={handleSuccessAlertClose} />
      </div>
      
      <Paper sx={{width: 500, margin:"auto"}}>
                    <TableContainer >
                        <Table sx={{ minWidth: 500 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{width: "50%"}}>Assigned Event </StyledTableCell>
                                <StyledTableCell>Remove Event</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                          
                            { assignedEvents.length > 0  ? assignedEvents.map((row, i) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.title}</StyledTableCell>
                                <StyledTableCell sx={{display: "flex", alignItems: "flex-end", gap: '14px'}}>
                                <div className='table-btn' onClick={()=>handleRemove(row.id)}> <DeleteOutlineOutlinedIcon sx={{fontSize: "16px", color: "#FC3300"}} /> <span style={{color: "#FC3300", fontWeight: "600"}}>Remove</span></div>
                                </StyledTableCell>
                            </StyledTableRow>
                            )) :  <StyledTableRow>
                              <StyledTableCell>No Events Found</StyledTableCell>
                              <StyledTableCell></StyledTableCell>
                              </StyledTableRow>}
                        </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <LoadingModal isOpen={loading} />
    </Container>
  );
};

export default AddEvents;
