import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, styled, TableBody, tableCellClasses } from "@mui/material"
import { useSelector } from "react-redux";
import LoadingModal from "../LoadingModal/LoadingModal";
import { getXrefCategoryData } from "../../../utils/ordersAndReport.utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const CategorySalesReportTable = ({ pageType = "event" }) => {
    const { cateogrySalesData, categorySalesReportLoading, categorySalesHeading } = useSelector(state => state.categorySales)
    const { selectedEvent } = useSelector(state => state.orderDetails);

    return (
        <>
            {
                categorySalesReportLoading ? <LoadingModal style={{ position: 'relative', width: '100%', marginLeft: '0px', minHeight: '300px', backgroundColor: 'white' }} isOpen={true} />
                    :
                    cateogrySalesData?.length ? <Paper>
                        <TableContainer>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        {
                                            categorySalesHeading?.map((head, index) => {
                                                return (
                                                    <StyledTableCell key={index} >{head}</StyledTableCell>
                                                )
                                            })
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cateogrySalesData?.map((row, i) => {
                                        return <StyledTableRow key={row.id}>
                                            <StyledTableCell>{getXrefCategoryData(row, pageType === 'event')?.name}</StyledTableCell>
                                            <StyledTableCell>{row?.total_items_sold}</StyledTableCell>
                                            <StyledTableCell>${row?.total_sales}</StyledTableCell>
                                            {/* <StyledTableCell>{row?.auction_item?.user_order_auction_item_xrefs[0]?.total_value}</StyledTableCell>
                                            <StyledTableCell >${+row?.auction_item?.user_order_auction_item_xrefs[0]?.total_value * +itemData(row.auction_item).price}</StyledTableCell> */}
                                        </StyledTableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <TablePagination
                            rowsPerPageOptions={[10, 20, 25, 50, 100]}
                            component="div"
                            count={categorySalesTotalCount}
                            rowsPerPage={auctionLimit}
                            page={auctionPage - 1}
                            onPageChange={(e, newPage) => dispatch(setAuctionPage({ page: newPage + 1 }))}
                            onRowsPerPageChange={(e) => dispatch(setAuctionPage({ page: 1, limit: +e.target.value }))}
                        /> */}
                    </Paper> : <Paper sx={{ height: '300px', textAlign: 'center', paddingY: '140px' }}>
                        {selectedEvent.value ?
                            <p>No Items are ordered</p>
                            : <p>Please choose an event</p>}
                    </Paper>
            }
        </>
    )
}

export default CategorySalesReportTable;