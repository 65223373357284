import { CalendarMonthOutlined } from '@mui/icons-material';
import Container from '../../../components/HOC/Container/Container';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import classes from '../ItemsQRCode.module.css'
import { QRCodeCanvas } from 'qrcode.react'
import { config } from '../../../../config/config';
import LoadingModal from '../../../components/LoadingModal/LoadingModal';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
const REACT_USER_APP_BASE_URL = config.REACT_USER_APP_BASE_URL

const GenerateQRCode = () => {
    const { QRCodeArrayToPrint } = useSelector(state => state.qrCodeItems)
    const navigate = useNavigate()
    const componentRef = useRef();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!QRCodeArrayToPrint?.length) {
            navigate('/admin/item-qr-code')
        }
    }, [])

    const handleGoBack = () => {
            navigate(-1)
    }

    const handlePrintQRClicked = useReactToPrint({
        content: () => componentRef.current,
    });

    // const handlePdfQRClicked = async () => {
    //     setLoading(true)
    //     const pdf = new jsPDF('p', 'mm', 'a4');
    //     const component = componentRef.current;

    //     for (let i = 0; i < component.children.length; i++) {
    //         const page = component.children[i];

    //         const canvas = await html2canvas(page, { scale: 1 });
    //         const imgData = canvas.toDataURL('image/jpeg', 0.5);
    //         const imgProps = pdf.getImageProperties(imgData);

    //         const pdfWidth = pdf.internal.pageSize.getWidth();
    //         const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    //         if (i > 0) {
    //             pdf.addPage();
    //         }

    //         pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
    //     }

    //     // pdf.save('ProductsQRCode.pdf');
    //     pdf.save(`${selectedEvent?.label?.split(' (')[0]}-Products-QRCODE.pdf`);
    //     setLoading(false)
    // }

    const getItemLink = (item) => {
        const causeId = item?.auction_auction_item_xrefs[0]?.auction?.cause?.id || item?.auction?.cause_id;
        if(item?.auction_auction_item_xrefs?.length) {
            const xrefAuctionId = item?.auction_auction_item_xrefs[0]?.auction_id;
            return `${REACT_USER_APP_BASE_URL}/lot/${item.id}?auction_id=${item?.auction_id}&cause_id=${causeId}&xref_auction_id=${xrefAuctionId}`
        }
        else {
            return `${REACT_USER_APP_BASE_URL}/lot/${item.id}?auction_id=${item?.auction_id}&cause_id=${causeId}`
        }
    }
    return (
        <Container title='Item QR Codes' style={{ position: "relative" }} >
            <div className='event_header_container'>
                <span className='calender_icon_container'><CalendarMonthOutlined sx={{ fontSize: '20px', color: '#34b5e5' }} /><p>Generate QR Codes</p></span>
            </div>
            <hr />

            {
                QRCodeArrayToPrint?.length > 0 ?
                    <div className={classes.qrMain}>
                        <div className={classes.actionsContainer} >
                            <button onClick={handleGoBack} className="aitem-modal-btn">Go Back</button>
                            <button onClick={handlePrintQRClicked} className="aitem-modal-btn">Print QR Codes</button>
                            {/* <button onClick={handlePdfQRClicked} className="aitem-modal-btn">Generate PDF</button> */}
                        </div>
                        <div ref={componentRef} className={classes.printComponent} >
                            {
                                QRCodeArrayToPrint?.map((itemArray, index) => {
                                    return (
                                        <div key={index} className={classes.page} id="qrcode-canvas" >
                                            {
                                                itemArray.map((item) => (
                                                    <div key={item.id} className={classes.qrCard} >
                                                        <QRCodeCanvas
                                                            level="H"
                                                            imageSettings={{
                                                                // src: "",
                                                                x: undefined,
                                                                y: undefined,
                                                                height: 80,
                                                                width: 80,
                                                                excavate: true,
                                                            }}
                                                            size={300}
                                                            style={{ height: "auto", maxWidth: "100%", width: "65%" }}
                                                            value={getItemLink(item)}
                                                        />

                                                        <div className={classes.itemDetails} >
                                                            <h4>Name: {item.name}</h4>
                                                            <p>Product SKU: {item.sku}</p>
                                                        </div>

                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    : null
            }
            <LoadingModal isOpen={loading} />
        </Container>
    )
}

export default GenerateQRCode;