import axios from 'axios';
import { headerKey, headerKey_ } from './api-header';
import { config } from '../config/config.js';
import logger from '../utils/logger';
import { fabClasses } from '@mui/material';

const API_BASE_URL = config.REACT_APP_BASE_URL;

const createAuction = async (payload, forCharity) => {
  // const response = await axios.post(
  //   `${API_BASE_URL}/api/v1/auction`,
  //   {
  //     ...payload,
  //   },
  //   {
  //     headers: { ...headerKey },
  //   }
  // );
  const response = await axios({
    method: 'post',
    url: `${API_BASE_URL}/api/v1/auction?forCharity=${forCharity}`,
    data: payload,
    headers: { ...headerKey },
  });
  return response.data;
};

const createAuctionItem = async (payload, id, forCharity) => {
  const response = await axios.post(
    `${API_BASE_URL}/api/v1/auction/id/${id}/item?forCharity=${forCharity}`,
    {
      ...payload,
    },
    {
      headers: { ...headerKey_ },
    }
  );
  // const response = await axios({
  //   method: "post",
  //   url: `${API_BASE_URL}/api/v1/auction/id/${id}/item`,
  //   data: payload,
  //   headers: { ...headerKey },
  // })
  return response.data;
};

const createAuctionItemAsset = async (payload, id) => {
  // const response = await axios.post(
  //   `${API_BASE_URL}/api/v1/auction/id/${id}/item/asset`,
  //   {
  //     ...payload,
  //   },
  //   {
  //     headers: { ...headerKey_ },
  //   }
  // );
  const response = await axios({
    method: 'post',
    url: `${API_BASE_URL}/api/v1/auction/id/${id}/item/assets`,
    data: payload,
    headers: { ...headerKey },
  });
  return response.data;
};

const getAuctionCategoryList = async () => {
  try {
    
    const response = await axios.get(`${API_BASE_URL}/api/v1/auction/category`, {
      params:{
        include_NA:true //fetching NA category also (item assign to NA if it doesn't have a category)
      },
      headers: { ...headerKey },
    });
    return response.data;
  } catch (error) {
    logger('./pages/service/auction-services.jsx','getAuctionCategoryList(): catch',error);
    
  }
};

const getAuctionDetailsById = async (auctionId) => {
  const response = await axios.get(
    `${API_BASE_URL}/api/v1/auction/id/${auctionId}?showIncompleted=true`
  );
  return response.data;
};

const getAuctionItemsById = async (auctionId, page=1, limit=20) => {
  const response = await axios.get(
    `${API_BASE_URL}/api/v1/auction/id/${auctionId}/items?page=${page}&limit=${limit}&showIncompleted=true`
  );
  return response.data;
};

export const getEventItemById = async (auctionId,itemId) => {
  const res = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/item/id/${itemId}?showIncompleted=true`);
  return res.data;
}
export const getWarehouse = async () => {
  const res = await axios.get(`${API_BASE_URL}/api/v1/warehouse?limit=10&page=1`,{
    headers: {...headerKey}
  });
  return res.data;
}
export const getProducts = async (auction_id,page, limit, advatix_status='known') => {
  const res = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/advatix-tag-items?advatix_status=${advatix_status}&page=${page}&limit=${limit}`,{
    headers: {...headerKey}
  });
  return res.data;
}
export const submitAsn = async (body) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/v1/asn`, {...body}, {
      headers: {...headerKey_}
    });
    return response.data;
  }
  catch(error) {
    throw error
  }
}

const getAllEventsOfUser = async (userId, page, limit, needMarketAndStore) => {
  const response = await axios.get(
    `${API_BASE_URL}/api/v1/users/${userId}/all-events`,
    {
      params: {
        page: page,
        limit: limit,
        needMarketAndStore
      },
      headers: { ...headerKey },
    }
  );
  return response.data;
};

const getAllEventsForAdmin = async (page, limit, showComplete=true) => {
  const response = await axios.get(
    `${API_BASE_URL}/api/v1/auction/find-all-for-admin`,
    {
      params: {
        page: page,
        limit: limit,
        showComplete
      },
      headers: { ...headerKey },
    }
  );
  return response.data;
};

const updateAuction = async (auction_id, payload) => {

  const response = await axios({
    method: 'put',
    url: `${API_BASE_URL}/api/v1/auction/id/${auction_id}/update-auction`,
    data: payload,
    headers: { ...headerKey },
  });
  return response.data;
};

const updateAuctionTermsInfo = async (auction_id, payload) => {
  const response = await axios.put(
    `${API_BASE_URL}/api/v1/auction/id/${auction_id}/terms`,
    {
      ...payload,
    },
    {
      headers: { ...headerKey_ },
    }
  );
  return response.data;
};

const updateAuctionStatus = async (auctionId, payload) => {
  const response = await axios.put(
    `${API_BASE_URL}/api/v1/auction/${auctionId}/update_status`,
    {
      ...payload,
    },
    {
      headers: { ...headerKey_ },
    }
  );
  return response.data;
};

const deleteAuctionItemById = async (auctionId, itemId) => {
  const response = await axios.delete(
    `${API_BASE_URL}/api/v1/auction/id/${auctionId}/item/id/${itemId}/remove-item`,
    {
      headers: { ...headerKey },
    }
  );
  return response.data;
};

const createAuctionBuyNowItem = async (auctionId, body,forCharity) => {
  try {
    const {data} = await axios.post(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/store-item?forCharity=${forCharity}`, {...body}, {
      headers: {...headerKey_}
    })
    return data
  } catch (error) {
    throw error
  }
}

 const getdonorsList = async (page,limit,order,orderBy,searchText) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/api/v1/donor`, {
      params: {
        page: page,
        limit: limit,
        order_by: orderBy,
        search_text:searchText,
        order
      }
    });
    return data;
  } catch (err) {
    logger('./pages/service/auction-services.jsx','getdonorsList(): catch',err);
  }
}

const uploadVirItem = async (formData,auction_id,item_id) => {
  const response = await axios({
    method: 'post',
    url: `${API_BASE_URL}/api/v1/auction/id/${auction_id}/item/${item_id}/upload-virtual-doc`,
    data: formData,
    headers: { ...headerKey },
  });
  return response.data;
}
export const reqEditItem = async (auctionId,itemId,payload,req_path, forCharity) => {
  const response = await axios.put(
    `${API_BASE_URL}/api/v1/auction/id/${auctionId}/item/id/${itemId}/${req_path}?forCharity=${forCharity}`,
    {
      ...payload,
    },
    {
      headers: { ...headerKey_ },
    }
  );
  return response.data;
};
export const reqEditVirtualItem = async (auctionId,itemId,formData) => {
  const response = await axios({
    method: 'put',
    url: `${API_BASE_URL}/api/v1/auction/id/${auctionId}/item/${itemId}/update-virtual-doc`,
    data: formData,
    headers: { ...headerKey },
  });
  return response.data;
};

export const deleteItemAsset = async (itemId, imageUrl) => {
  const imageKey = imageUrl.split("/").splice(-3).join("/");
  const {data} = await axios.delete(`${API_BASE_URL}/api/v1/auction/removeItemAsset/${itemId}/${imageKey}`, {
    headers: {
      ...headerKey_
    }
  })
  return data
}

export const updateItemAsset = async (formData) => {
  const {data} = await axios.put(`${API_BASE_URL}/api/v1/auction/updateItemAsset`, formData, {
    headers: {
      ...headerKey
    }
  })
  return data
}

const getItemsForAdvatixTag = async (auction_id, status) => {
  const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/advatix-tag-items?advatix_status=${status}`, {
    headers: {
      ...headerKey_
    }
  })
  return data.result
}

const getAllItemsForAdvatixTag = async (auction_id) => {
  try {
    let newData = []
    const {data: dataS} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/advatix-tag-items?advatix_status=sent`, {
      headers: {
        ...headerKey_
      }
    })
    newData = [...dataS.result]
    const {data: dataK} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/advatix-tag-items?advatix_status=unknown`, {
      headers: {
        ...headerKey_
      }
    })
    newData = [...newData, ...dataK.result]
    const {data: dataX} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/advatix-tag-items?advatix_status=known`, {
      headers: {
        ...headerKey_
      }
    })
    newData = [...newData, ...dataX.result]
    return newData
  } catch(error) {
    throw error
  }
}

const markAdvatixStatus = async (status, items, auction_id) => {
  const {data} = await axios.post(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/advatix-tag-items?advatix_status=${status}`, {
      items: items
    },
    {
      headers: {
        ...headerKey_
      }
  })
  return data
}

const getCurrentAuctions = async (todays_date, page, limit) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/auction/active-auctions/${new Date(todays_date).toISOString()}`, {
    params: {
      page: page,
      limit: limit
    }
  },
  {
      headers: { ...headerKey_ }
  });
  return response.data;
}

const approveItemToActive = async (auctionId, itemId, donorId=null) => {
  try {
    const {data} = await axios.put(`${API_BASE_URL}/api/v1/auction/${auctionId}/approve/${itemId}`, {donorId:donorId}, {
      headers: {
        ...headerKey_
      }
    })
    return data
  }
  catch(error) {
    throw error
  }
}

const imagePreProcess = async (formData,alpha=1) => {
  const {data} = await axios.post(`${API_BASE_URL}/api/v1/auction/pre-process-image?alpha=${alpha}`, formData)
  return data
}


const addDonor=async(body)=>{
  try{
   let data= await axios.post(`${API_BASE_URL}/api/v1/donor/addDonor`,body,{
    headers:{...headerKey}
   })       
   return data;
  }catch(err){
    throw err
  }
}
const updateDonorIcon=async(donorId,body)=>{
  try{
   let data= await axios.post(`${API_BASE_URL}/api/v1/donor/update-donor-icon/${donorId}`,body,{
    headers:{...headerKey}
   })       
   return data;
  }catch(err){
    throw err
  }
}

const addDonorPromo=async(body)=>{
  try{
   let data=await axios.post(`${API_BASE_URL}/api/v1/donor/addDonorPromo`,{...body},{
    headers:{...headerKey_}
   });
   return data;
  }catch(err){
    throw err
  }
}

const addCustomCategories = async (auction_id,formData) => {
  try {
    const {data} = await axios.post(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/custom-category`,
    formData, {
      headers: { ...headerKey }
    })
    return data
  } catch (error) {
    throw error
  }
}

const fetchCustomCategories = async (auction_id) => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/custom-category`)
    return data
  } catch (error) {
    throw error
  }
}
const fetchcustomCategoryHeadline = async (auction_id) => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/custom-category-headline`)
    return data
  } catch (error) {
    throw error
  }
}

const createLocalDonor = async (body) => {
  try {
    const {data} = await axios.post(`${API_BASE_URL}/api/v1/donor/local-donor`, body, {
      headers: {...headerKey_}
    })
    return data
  } catch (error) {
    throw error
  }
}

const makeDonorFeatured = async (donor_id, featured) => {
  try {
    const {data} = await axios.put(`${API_BASE_URL}/api/v1/donor/id/${donor_id}${featured ? '?featured=true' : ''}`, {}, {
      headers: {...headerKey_}
    })
    return data
  } catch (error) {
    throw error
  }
}

const getStoreItemsByAuctionId = async (auction_id, page=null, limit=null, sortByOrder=null, eventType=null, searchValue, sortOrder, sortOrderBy) => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/store-item-by-auction-id`, {
      params: {
        page: page,
        limit: limit,
        showIncompleted: true,
        sortByOrder: sortByOrder,
        eventType:eventType,
        searchValue,
        sortOrder,
        sortOrderBy
      }
    })  
    return data 
  } catch (error) {
    throw error
  }
}

const getAuctionItemsByAuctionId = async (auction_id, page=null, limit=null, sortByOrder=null, searchValue, sortOrder, sortOrderBy) => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/auction-item-by-auction-id`, {
      params: {
        page: page,
        limit: limit,
        showIncompleted: true,
        sortByOrder: sortByOrder,
        searchValue,
        sortOrder,
        sortOrderBy
      }
    })  
    return data 
  } catch (error) {
    throw error
  }
}

const checkDonorsPermissions = async () => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/donor/verify-permissions`, {
      headers: {...headerKey_}
    })
    return data
  } catch (error) {
    throw error    
  }
}

const submitForApproval = async (auction_id) => {
  try {
    const {data} = await axios.put(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/send-for-approval`, {}, {
      headers: {...headerKey_}
    })
    return data
  } catch (error) {
    throw error
  }
}

export const deleteVirtualAsset = async (itemId, imageKey) => {
  const {data} = await axios.delete(`${API_BASE_URL}/api/v1/auction/removeVirtualItemAsset/${itemId}/${imageKey}`, {
    headers: {
      ...headerKey_
    }
  })
  return data
}

export const updateItemsDisplayOrder = async (auction_id, itemsArray) => {
  const {data} = await axios.put(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/update-item-display-order`, {
    orderedItems: itemsArray
  }, {
    headers: {
      ...headerKey_
    }
  })
  return data
}

export const checkCustomUrl = async(id, forCharity="") => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/custom-url/${id}/check-custom-url?forCharity=${forCharity}`, { 
      headers: {
        ...headerKey_
      }
    })
    return data
  } catch (error) {
    console.log(error)
  }
}


const getdonorsListbyAuctionId = async (auction_id, forMarket=false) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/api/v1/donor/get-donors-by-auction_id/${auction_id}?forMarket=${forMarket}`, {
      headers: {
        ...headerKey_
      }
    });
    return data;
  } catch (err) {
    logger('./pages/service/auction-services.jsx','getdonorsListbyAuctionId(): catch',err);
  }
}

const getAllUnassignedEvents = async (donor_id) => {
  const response = await axios.get(
    `${API_BASE_URL}/api/v1/auction/find-all-unassigned-auctions/${donor_id}`,
    {
      headers: { ...headerKey },
    }
  );
  return response.data;
};

const postAssignedEvents = async (body) => {
  try {
    const data = await axios.post(`${API_BASE_URL}/api/v1/donor/add-events-to-donor`, {...body},{
      headers: {...headerKey_}
    })
    return data
  } catch (error) {
    throw error
  }
}

const getAllassignedEvents = async (donor_id) => {
  const response = await axios.get(
    `${API_BASE_URL}/api/v1/auction/find-all-assigned-auctions/${donor_id}`,
    {
      headers: { ...headerKey },
    }
  );
  return response.data;
};

const removeAssignedEvents = async (donor_id,auction_id) => {
  const response = await axios.delete(`${API_BASE_URL}/api/v1/donor/remove-assigned-event/${donor_id}/${auction_id}`,{
    headers: { ...headerKey },
  });
  return response
}

const getCustomBandsForEvent = async (auction_id) => {
  const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/event-bid-bands`, {
    headers: {...headerKey_},
  })
  return data
}

const createCustomBidBands = async (auction_id, bands, default_case) => {
  try {
    const {data} = await axios.post(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/event-bid-bands`, {
      bands,
      default_case_increment: default_case
    }, {
      headers: {...headerKey_}
    })
    return data
  } catch (error) {
    throw error
  }
}

const updateEventBidType = async (auction_id, type, value = null) => {
  try {
    const {data} = await axios.put(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/update-bid-type`, {
      bid_increment_type: type,
      bid_increment_val: value
    }, {
      headers: {...headerKey_}
    })
    return data
  } catch (error) {
    throw error
  }
}


const getdonorById = async (donorId) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/api/v1/donor`, {
      params: {
        donor_id:donorId
      }
    });
    return data;
  } catch (err) {
    logger('./pages/service/auction-services.jsx','getdonorsList(): catch',err);
  }
}

const updateDonor = async (auctionId,payload) => {
  try {
    const data = await axios.put(`${API_BASE_URL}/api/v1/donor/update-donor/${auctionId}`,payload,{
      headers: {...headerKey_}
    })
    return data
  } catch (error) {
    throw error
  }
}
const getVariantInformation = async (auction_id,storeItemId) => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/store-item-id/${storeItemId}/fetch-variant-information`, {
      headers: {...headerKey_},
    })
    return data
  } catch (error) {
    console.log(error)

  }
}
const getAuctionReport = async (auction_id,page,limit,customDateRange,soldStatusFilter,showAll, searchText, orderSearchByText) => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/auction-report`, {
      params: {
        page: page,
        limit: limit,
        show_all:showAll,
        ...customDateRange,
        ...soldStatusFilter,
        searchText,
        orderSearchByText
      },
      headers: {...headerKey_},
    })
    return data
  } catch (error) {
    throw error
  }
}
const getEventSummery = async (auction_id, marketplace, store) => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/event-summery`, {
      params: {marketplace:marketplace, store:store},
      headers: {...headerKey_},
    })
    return data
  } catch (error) {
    throw error
  }
}

const deleteCustomCategoryById = async (customId, auction_id) => {
  try{
    const response = await axios.delete(
      `${API_BASE_URL}/api/v1/auction/id/${auction_id}/custom-category-id/${customId}/remove-custom-category`,
      {
        headers: { ...headerKey },
      }
    );
    return response.data;
  }catch(error){
    throw error
  }
};

const deleteCustomCategoryByAuctionId = async (auction_id) => {
  try{
    const response = await axios.delete(
      `${API_BASE_URL}/api/v1/auction/id/${auction_id}/remove-all-custom-category`,
      {
        headers: { ...headerKey },
      }
    );
    return response.data;
  }catch(error){
    throw error
  }
};

const deleteCustomCategoryByHeading = async (auction_id, customHeading) => {
  try{
    const response = await axios.delete(
      `${API_BASE_URL}/api/v1/auction/id/${customHeading?.id}/remove-all-custom-category-by-heading`,
      {
        headers: { ...headerKey },
      }
    );
    return response.data;
  }catch(error){
    throw error
  }
};

export const updateCustomCategoryDisplayOrder = async ( id, categoryArray, heading=false) => {
   try{
    const {data} = await axios.put(`${API_BASE_URL}/api/v1/auction/id/${id}/update-custom-category-display-order?heading=${heading}`, {
      orderedCategory: categoryArray
    }, {
      headers: {
        ...headerKey_
      }
    })
    return data
   }catch (error) {
    throw error
   }
}

const getAllMarketPlaceOrStoreOfUser = async (userId, page, limit, forCharity) => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/uid/${userId}/all-market-place-or-stores?forCharity=${forCharity}`, {
      params: {
        page: page,
        limit: limit,
      },
      headers: { ...headerKey },
    }
  )
    return data
  } catch (error) {
    throw error
  }
}

const getCustomImages = async (location) => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/get-custom-images/${location}`, {
      headers: { ...headerKey },
    }
  )
    return data
  } catch (error) {
    throw error
  }
}

const uploadBgImages = async(formData) => {
  try {
    const {data} = await axios.post(`${API_BASE_URL}/api/v1/auction/upload-bg-images`, formData, {
      headers: { ...headerKey },
    }
  )
    return data
  } catch (error) {
    throw error
  }
}

const deleteEvent = async (auction_id) => {
try{
  const response = await axios({
    method: 'put',
    url: `${API_BASE_URL}/api/v1/auction/delete-event/${auction_id}`,
    headers: { ...headerKey },
  });
  return response.data;
}catch (error) {
  throw error
}
} 

const getAllItemsByAdvatixStatus = async (page, limit, advatixStatus = "known", notByAuction=true) => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/items/advatix-status?advatix_status=${advatixStatus}&page=${page}&limit=${limit}&not_by_auction=${notByAuction}`, {
      headers: { ...headerKey },
    }
  )
    return data
  } catch (error) {
    throw error
  }
  } 
  
  const approveAllItemToActive = async (auctionId, item_type) => {
    const {data} = await axios.put(`${API_BASE_URL}/api/v1/auction/approve-all/${auctionId}`, {item_type:item_type}, {
      headers: {
        ...headerKey_
      }
    })
    return data
  }


  const uploadStaticImage = async (formData) => {
    try {
      const {data} = await axios.post(`${API_BASE_URL}/api/v1/auction/upload-static-images`,
      formData, {
        headers: { ...headerKey }
      })
      return data
    } catch (error) {
      throw error
    }
  }


  const getAvailableItemsOfDonors = async(donors=[], auction_id, page, limit) => {
    try{
      const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/items/get-donors-available-items/${auction_id}?donors=${donors}&page=${page}&limit=${limit}`, {
        headers: { ...headerKey },
      }
    )
      return data
    }catch(error) {
      throw error
    }
  }

  const createAuctionAuctionItemXref = async(itemId, auctionId) => {
    try{
      const {data} = await axios.post(`${API_BASE_URL}/api/v1/auction/${auctionId}/create-auction-auction-item-xref/item/${itemId}`, {}, {
        headers: { ...headerKey },
      }
    )
      return data
    }catch (error) {
         throw error
    }
  }

  const updateKnown = async (formData)=>{
    const {data} = await axios.put(`${API_BASE_URL}/api/v1/auction/advatix-update-known`,formData, {
      headers: {
        ...headerKey
      }
    })
    return data
  }

  const markAdvatixStatusAllEvents = async(status, items) => {
    try {
      const {data} = await axios.patch(`${API_BASE_URL}/api/v1/auction/advatix-tag-items-all-events?advatix_status=${status}`, {
        items: items
      },
      {
        headers: {
          ...headerKey_
        }
    })
    return data
    } catch (error) {

      throw error
    }
  }

  const getAchArchievedEventsOfUser = async ( page, limit, event_type) => {
    try {
      const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/get-all-archieved-events?event_type=${event_type}`, {
        params: {
          page: page,
          limit: limit,
        },
        headers: { ...headerKey },
      }
    )
      return data
    } catch (error) {
      throw error
    }
  }

  const deleteAssignedItem = async(auctionId, itemId) => {
    try{
      const response = await axios.delete(
        `${API_BASE_URL}/api/v1/auction/id/${auctionId}/remove-assigned-item/item/${itemId}`,
        {
          headers: { ...headerKey },
        }
      );
      return response.data;
    }catch(error) {
      throw error
    }
  }

  const getAsnItemsByStatus = async(page, limit, asnStatus, auction_id) => {
    try{
      const response = await axios.get(`${API_BASE_URL}/api/v1/asn/get-asn-by-status`, {
        params: {
          page: page,
          limit: limit,
          asnStatus:asnStatus,
          auctionId: auction_id
        },
        headers: {...headerKey}
      });
      return response.data;
    }catch(error) {
      throw error
    }
  }

  const updateAsnItemsQuantity = async(body, updateAllItems) => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/api/v1/asn/update-items-quantity`, {...body}, {
        params: {
          updateAllItems
        },
        headers: {...headerKey_}
      });
      return response.data;
    } catch (error) {
      throw error
    }
  }


  const getSaleIntegrationMethods = async() => {
    try {
      const { data } = await axios.get(`${API_BASE_URL}/api/v1/donor/get-sale-integration-methods`, {
        headers: {...headerKey_}
      });
      return data;
    } catch (error) {
      throw error
    }
  }

  const createRevenueSplitData = async(formData) =>{
    try {
      const { data } = await axios.post(`${API_BASE_URL}/api/v1/donor/create-dropship-data`, 
      formData, {
        headers: {...headerKey_}
      });
      
      return data;
    }catch(error) {
      throw error
    }
  }

  const getDonorRevenueSplit = async(donorId) => {
    try {
      const { data } = await axios.get(`${API_BASE_URL}/api/v1/donor/id/${donorId}/get-donor-revenue-split`, {
        headers: {...headerKey_}
      });
      return data;
    } catch (error) {
      throw error
    }
  }

  const updateRevenueSplitById = async(body, id) => {
    try {
      const { data } = await axios.put(`${API_BASE_URL}/api/v1/donor/id/${id}/update-revenue-split-data`, 
      body, {
        headers: {...headerKey_}
      });
      return data;
    }catch(error) {
      throw error
    }
  }

  const getBiddersReport = async(auctionId, page, limit, startDate, endDate, searchText, orderSearchByText) => {
    try{
       const { data } = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/get-bidders-report?page=${page}&limit=${limit}`, {
           params:{
               start_date:startDate,
               end_date:endDate,
               searchText,
               orderSearchByText
              }, 
           headers: {
                ...headerKey_
            }
        })
        return data
    }catch(error) {
       throw error
    }
}

const bulkCreateAuctionAuctionItemXref = async(items, auctionId, allSelected=false, donors=[], filterdFromAllSelected=[]) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/bulk-create-auction-auction-item-xref?allSelected=${allSelected}&donors=${donors}&filterdFromAllSelected=${filterdFromAllSelected}`, {items}, { 
      headers: {
           ...headerKey_
       }
   })
   return data
  }catch(error) {
    throw error
  }
}

const updateVariantsData = async(updatePayload) => {
  try {
    const { data } = await axios.put(`${API_BASE_URL}/api/v1/auction/items/udpate-variants`, {...updatePayload}, { 
      headers: {
           ...headerKey_
       }
   })
   return data
  }catch(error) {
    throw error
  }
}

const makeEventFeatured = async(auctionId, featuredStatus) => {
  try {
    const { data } = await axios.patch(`${API_BASE_URL}/api/v1/auction/${auctionId}/make-featured`, {featuredStatus}, { 
      headers: {
           ...headerKey_
       }
     })
     return data
  } catch (error) {
     throw error
  }
}

const updateAsnAndQuantityByFileUpload = async(formData) => {
  try{
    const {data} = await axios.patch(`${API_BASE_URL}/api/v1/asn/asn-update-by-file`,formData, {
      headers: {
        ...headerKey
      }
    })
    return data
  }catch(error) {
    throw error
  }
}

const endAuction = async(auctionItemId) => {
  try {
    const {data} = await axios.patch(`${API_BASE_URL}/api/v1/auction/item/${auctionItemId}/end-auction`,{}, {
      headers: {
        ...headerKey
      }
    })
    return data
  }catch(error) {
    throw error
  }
}

const getAvailableCategoriesOfEvent = async(auctionId, itemType) => {
  try{
    const { data } = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/get-categories-have-item-of-event`,
    {
      params: {
        itemType:itemType
      },
      headers: {
           ...headerKey_
       }
    })
   return data
  }catch(error) {
    throw error;
  }
}

const updateEventCategoriesDisplayOrder = async(auctionId, categoryArray=[], customCategoryArray=[]) => {
  try {
    const { data } = await axios.patch(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/update-order-of-categories-have-item-in-event`,{
      categoryArray: categoryArray,
      customCategoryArray: customCategoryArray
    }, {
      headers: {
           ...headerKey_
       }
   })
   return data
  } catch (error) {
    throw error;
  }
}

const deleteAuctionItemCategoryXref = async(payload, auctionId) => {
  try{
    const { data } = await axios.delete(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/delete-category-xref-data`, {
      params: {
        ...payload
      },
      headers: {
           ...headerKey_
       }
   })
   return data
  }catch(error) {
    throw error;
  }
}

const makeItemArchived = async(itemId, value, addedItem=false) => {
  try{
    const { data } = await axios.patch(`${API_BASE_URL}/api/v1/auction/item/${itemId}/update-item?addedItem=${addedItem}`,
      {
        archived:value
      },
      {
        headers: {
             ...headerKey_
        }
      }
   )
   return data
  }catch(error) {
    throw error;
  }
}


const cloneItem = async(auctionId, item, itemType) => {
  try{
    const { data } = await axios.post(`${API_BASE_URL}/api/v1/auction/${auctionId}/item/clone-item?itemType=${itemType}`,
      item,
      {
        headers: {
             ...headerKey_
        }
       }
   )
   return data
  }catch(error) {
    throw error;
  }
}


const generateImageDetails = async(formData) => {
  try{
    const { data } = await axios.post(`${API_BASE_URL}/api/v1/auction/generate-image-data`,
      formData,
      {
        headers: { ...headerKey }

      }
   )
   return data
  }catch(error) {
    throw error;
  }
}

const getBuyersOfItem = async(auctionId, itemId, page, limit) => {
  try{
    const { data } = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/item/${itemId}/get-buyers-of-item`,
    {
      params: {
        page,
        limit
      },
      headers: {
           ...headerKey_
       }
    })
   return data
  }catch(error) {
    throw error;
  }
}

const getItemSalesReport = async(auctionId, page, limit, pageType="event", searchText, orderSearchByText) => {
  try{
     const { data } = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/get-item-sales-report?page=${page}&limit=${limit}`, {
         params:{
             pageType:pageType,
             searchText,
             orderSearchByText
         }, 
         headers: {
              ...headerKey_
          }
      })
      return data
  }catch(error) {
     throw error
  }
}

const getCategorySalesReport = async(auctionId, page, limit, pageType="event", searchText, orderSearchByText) => {
  try{
     const { data } = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/get-category-sales-report?page=${page}&limit=${limit}`, {
         params:{
             pageType:pageType,
             searchText,
             orderSearchByText
         }, 
         headers: {
              ...headerKey_
          }
      })
      return data
  }catch(error) {
     throw error
  }
}

const getProductsForAsnByEvent = async (auction_id,page, limit, advatix_status='known') => {
  try {
    const res = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auction_id}/advatix-tag-items-for-asn?advatix_status=${advatix_status}&page=${page}&limit=${limit}`,{
      headers: {...headerKey}
    });
    return res.data;
  }catch(error) {
    throw error;
  }
}

const getProductsForAsnNotByEvent = async (page, limit, advatixStatus='known', notByAuction=true) => {
  try {
    const res = await axios.get(`${API_BASE_URL}/api/v1/auction/items/advatix-tag-items-for-asn-without-event?advatix_status=${advatixStatus}&page=${page}&limit=${limit}&not_by_auction=${notByAuction}`,{
      headers: {...headerKey}
    });
    return res.data;
  }catch(error) {
    throw error;
  }
}

const getAllItemsForEvent = async (eventId, limit, page) => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${eventId}/items/all-event-items`,{
      params: {
        page,
        limit
      },
      headers: {...headerKey}
    });
    return data;
  }catch(error) {
    throw error;
  }
}


const deleteVariant = async (variantId) => {
  try {
    const {data} = await axios.delete(
      `${API_BASE_URL}/api/v1/auction/delete-variant/${variantId}`,
      {
        headers: { ...headerKey },
      }
    );
    return data;
  }catch(error) {
    throw error
  }
};

const getAllProducts = async () => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/get-all-items`,{
      headers: {...headerKey}
    });
    return data;
  }catch(error) {
    throw error;
  }
}

const updateItemShopNowDisplayOrder = async (payload) => {
  try {
    const {data} = await axios.post(`${API_BASE_URL}/api/v1/auction/update-all-items-priority-order`,{payload}, {
      headers: {...headerKey_}
    });
    return data;
  }catch(error) {
    throw error;
  }
}

const cloneEvent = async (eventId) => {
  try {
    const {data} = await axios.post(`${API_BASE_URL}/api/v1/auction/id/${eventId}/clone-event`,{}, {
      headers: {...headerKey_}
    });
    return data;
  }catch(error) {
    throw error;
  }
}


const auctionServices = {
  createAuction,
  createAuctionItem,
  createAuctionItemAsset,
  getAuctionCategoryList,
  updateAuction,
  updateAuctionTermsInfo,
  getAuctionDetailsById,
  getAuctionItemsById,
  getAllEventsOfUser,
  getAllEventsForAdmin,
  updateAuctionStatus,
  deleteAuctionItemById,
  createAuctionBuyNowItem,
  getdonorsList,
  uploadVirItem,
  getEventItemById,
  deleteItemAsset,
  updateItemAsset,
  getItemsForAdvatixTag,
  markAdvatixStatus,
  getAllItemsForAdvatixTag,
  getCurrentAuctions,
  approveItemToActive,
  imagePreProcess,
  addDonor,
  addDonorPromo,
  addCustomCategories,
  fetchCustomCategories,
  createLocalDonor,
  makeDonorFeatured,
  reqEditItem,
  getStoreItemsByAuctionId,
  getAuctionItemsByAuctionId,
  checkDonorsPermissions,
  submitForApproval,
  fetchcustomCategoryHeadline,
  updateItemsDisplayOrder,
  checkCustomUrl,
  getdonorsListbyAuctionId,
  getAllUnassignedEvents,
  postAssignedEvents,
  getAllassignedEvents,
  removeAssignedEvents,
  updateDonorIcon,
  getCustomBandsForEvent,
  createCustomBidBands,
  updateEventBidType,
  getVariantInformation,
  getAuctionReport,
  getEventSummery,
  getdonorById, 
  updateDonor,
  deleteCustomCategoryById,
  deleteCustomCategoryByAuctionId,
  updateCustomCategoryDisplayOrder,
  deleteCustomCategoryByHeading,
  getAllMarketPlaceOrStoreOfUser,
  getCustomImages,
  uploadBgImages,
  deleteEvent,
  getAllItemsByAdvatixStatus,
  approveAllItemToActive,
  uploadStaticImage,
  getAvailableItemsOfDonors,
  createAuctionAuctionItemXref,
  updateKnown,
  markAdvatixStatusAllEvents,
  getAchArchievedEventsOfUser,
  deleteAssignedItem,
  getAsnItemsByStatus,
  updateAsnItemsQuantity,
  getSaleIntegrationMethods,
  createRevenueSplitData,
  getDonorRevenueSplit,
  updateRevenueSplitById,
  getBiddersReport,
  bulkCreateAuctionAuctionItemXref,
  updateVariantsData,
  makeEventFeatured,
  updateAsnAndQuantityByFileUpload,
  endAuction,
  getAvailableCategoriesOfEvent,
  updateEventCategoriesDisplayOrder,
  deleteAuctionItemCategoryXref,
  makeItemArchived,
  generateImageDetails,
  getBuyersOfItem,
  cloneItem,
  generateImageDetails,
  getItemSalesReport,
  getCategorySalesReport,
  getProductsForAsnNotByEvent,
  getProductsForAsnByEvent,
  getAllItemsForEvent,
  deleteVariant,
  getAllProducts,
  updateItemShopNowDisplayOrder,
  cloneEvent
};

export default auctionServices;
