import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import auctionServices from '../services/auction-services'

export const fetchAvailableCategoriesOfEvent = createAsyncThunk(
    'donors/fetchAvailableCategoriesOfEvent',
    async ({auction_id, itemType}) => {
        const response = await auctionServices.getAvailableCategoriesOfEvent(auction_id, itemType);
        return {
            data: response.data,
        }
    }
)

const initialState = {
    availableCategories: [],
    loading:true,
    error:''
}

const availableCategoriesOfEventSlice = createSlice({
    name: 'donors/availableCategories',
    initialState,
    reducers: {
        setCategoriesHaveItem: (state, action) => {
            state.availableCategories = action.payload.categories
        },
        toggleCategoryLoading: (state, action) => {
            state.loading = action.payload.loading
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAvailableCategoriesOfEvent.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchAvailableCategoriesOfEvent.fulfilled, (state, action) => {
            state.availableCategories = action.payload?.data
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchAvailableCategoriesOfEvent.rejected, (state, action) => {
            state.loading = false
            state.error = action.error?.message
        })
    }
})

export default availableCategoriesOfEventSlice.reducer
export const {  setCategoriesHaveItem, toggleCategoryLoading } = availableCategoriesOfEventSlice.actions