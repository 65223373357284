import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    QRSelectedItems: [],
    QRSelectedItemsSKU: [],
    QRCodeArrayToPrint: [],// this is a nested array with 4 items maximum in one array
    selectAll:{}//key page value boolean
}

const QRCodeItemSlice = createSlice({
    name: 'qrCodeSlice',
    initialState,
    reducers: {
        setQRItems: (state, action) => {
            if (state.QRSelectedItemsSKU?.includes(action.payload?.item?.sku)) {
                // then remove it from the state
                if(!action.payload?.fromSelectAll) {
                    state.QRSelectedItems = state.QRSelectedItems?.filter((item) => item.sku !== action.payload?.item?.sku)
                    state.QRSelectedItemsSKU = state.QRSelectedItemsSKU?.filter((item) => item !== action.payload?.item?.sku)
                }
            }
            else {
                state.QRSelectedItems?.push(action.payload?.item)
                state.QRSelectedItemsSKU?.push(action.payload?.item?.sku)
            }
        },
        clearSelectedItem: (state, action) => {
            state.QRSelectedItems = state.QRSelectedItems?.filter((item) => item.sku !== action.payload?.item?.sku)
            state.QRSelectedItemsSKU = state.QRSelectedItemsSKU?.filter((item) => item !== action.payload?.item?.sku)
        },
        clearQRItemState: (state, action) => {
            state.QRSelectedItems = []
            state.QRSelectedItemsSKU = []
            state.QRCodeArrayToPrint = []
        },
        SetQRCodeArrayToPrint: (state, action) => {
            state.QRCodeArrayToPrint = action.payload.itemsToPrint
        },
        setSelectAll: (state, action) => {
            const {page, value} = action.payload
            state.selectAll = {...state.selectAll, [page]:value}
        },
        clearSelectAll: (state, action) => {
            state.selectAll = {}
        }
    }
})

export default QRCodeItemSlice.reducer
export const { setSelectAll, clearSelectAll, setQRItems, clearQRItemState, SetQRCodeArrayToPrint, clearSelectedItem} = QRCodeItemSlice.actions