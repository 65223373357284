import React from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import moment from 'moment';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
const DatePickerComponent = ({date, minDateTime, maxDateTime, onChange, label, disabled,onClose=()=>null , style }) => {
    return (
        <>
            <div className='input-container'>
                <label className='input-label'> {label} <span style={{ color: "red" }}>*</span>  </label>
                {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDateTimePicker sx={{
                        ".MuiInputBase-root" : {borderRadius: "20px"},
                        ".MuiInputBase-input": {padding: "10px 15px", fontSize: "0.8rem"}
                    }}
                    value={date ? moment(date) : null}
                    minDateTime={moment(minDateTime)}
                    maxDateTime={maxDateTime != undefined ? moment(maxDateTime) : '' }
                    onChange={(value) => {
                        if(value)onChange(value._d)
                        else return
                    }}
                    minutesStep={5}
                    disabled={disabled}
                    onClose={onClose}
                    />
                </LocalizationProvider> */}
                <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDateTimePicker 
                   sx={{
                    ".MuiInputBase-root" : {borderRadius: "20px"},
                    ".MuiInputBase-input": {padding: "10px 15px", fontSize: "0.8rem", paddingRight:"0px"},
                    ".MuiIconButton-root": {paddingLeft: '0px',paddingRight: '2px'},
                    ".MuiMultiSectionDigitalClock-root": {maxHeight:"296px"}
                }}
                  value={date ? moment(date) : null}
                  minDateTime={moment(minDateTime)}
                  maxDateTime={maxDateTime != undefined ? moment(maxDateTime) : ''}
                  onChange={(value) => {
                    if(value)onChange(value._d)
                    else return
                  }}
                  minutesStep={5}
                  disabled={disabled}
                  onClose={onClose}
                />
                </LocalizationProvider>
            </div>
        </>
    )
}

export default DatePickerComponent