import Container from "../../components/HOC/Container/Container";
import "./EditDonor.css";
import { useEffect, useState } from "react";
import checkImageBeforeUpload from "../../../utils/checkImageRatio";
import auctionServices from "../../../services/auction-services";
import { onlyNumber, validEmail, validName } from "../../../utils/reg-ex";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../components/Input/Input";
import TextArea from "../../components/FormTextarea/FormTextarea";
import ImageInput from "../../components/ImageInput/ImageInput";
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import Alert from "../../components/Alert/Alert";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import usePrompt from "../../hooks/usePrompt";
import RadioGroupComponent from "../../components/RadioGroup/RadioGroup";
import constants from '../../../constants/en';
import authService from "../../../services/auth-services";
import SaleIntegration from "./SaleIntegration";

function EditDonor() {
    const { donor_id } = useParams()
    const [donorData, setDonorData] = useState({
        contact_firstname: '',
        contact_lastname: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        email: '',
        about: '',
        legal_name: '',
        org_name: '',
        tax_id: null,
        splashimage:'',
        featured_icon:'',
        featured:null,
    })
    const [error, setError] = useState({ firstNameErr: false, lastNameErr: false, emailErr: false, taxIdError: false })
    const navigate = useNavigate();
    const [alertMsg, setAlertMsg] = useState(null);
    const [featureIcon, setFeaturedIcon] = useState(null);
    const [featuredImage, setFeaturedImage] = useState([{
        name: null,
        type: "img",
        updated: false
    }]);
    const [portraitImageLoading, setPortraitImageLoading] = useState(false);
    const [featuredImageLoading, setFeaturedImageLoading] = useState(false)
    const [splashImage, setSplashImage] = useState([{
        name: null,
        type: "img",
        updated: false
    }]);
    const [splashIcon, setSplashIcon] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showPrompt, setShowPrompt] = useState(false)
  const [donorFeatured, setDonorFeatured] = useState(false)
  const [whiteborderPortraitAlert,setWhiteborderPortraitAlert] = useState(null);
  const [portraitEvent,setPortraitEvent] = useState(null);
  const [featuredLogoWhiteBorderAlert,setFeaturedLogoWhiteBorderAlert] = useState(null);
  const [featuredLogoEvent,setFeaturedLogoEvent] = useState(null);
  const [godAccess, setGodAccess] = useState(false);
  const [ediPartnerId, setEdiPartnerId] = useState({isPartner: false, value:''})
  usePrompt("Do you want to leave? You will lose your changes!", showPrompt)

  
  const checkPermission = async() => {
    try{
        setLoading(true)
        const permission = await authService.checkLimitedPermissions(15);
        setGodAccess(permission?.access);
        setLoading(false)
    }catch(error) {
        setGodAccess(false);
        setLoading(false)
    }
  }

  useEffect(() => {
    checkPermission()
  },[])

    useEffect(() => {
        fetchDonorById(donor_id)
    }, [donor_id])
    const fetchDonorById = async (donor_id) => {
        try{
        let data = await auctionServices.getdonorById(donor_id)
        const oldDonor = data.data[0]
        for(let key of Object.keys(oldDonor)) {
            if(!oldDonor[key] || oldDonor[key] === "null") {
                oldDonor[key] = ""
            }
        }
        setDonorData({ ...donorData, ...data.data[0] })
        if(data.data[0].featured === 1) setDonorFeatured(true)
        else setDonorFeatured(false)
        setFeaturedImage([ 
            {
                name: data.data[0].featured_icon,
                type: "img",
                updated: false
            },
        ]);
        setSplashImage([
            {
                name: data.data[0].splashimage,
                type: "img",
                updated: false
            },
        ])
        if(data.data[0]?.sps_edi_trading_partner_id) {
            setEdiPartnerId({isPartner:true, value:data.data[0]?.sps_edi_trading_partner_id})
        }
        setLoading(false)
    } catch(error){
        setLoading(false)
        setAlertMsg("Something went wrong");
    }
    }

    const validationLabel = (e) => {
        if (e.target.name === 'contact_firstname') {
            if (!validName.test(e.target.value)) {
                setError({ ...error, firstNameErr: true })
                return true
            }
            setError({ ...error, firstNameErr: false })
        }
        if (e.target.name === 'contact_lastname') {
            if (!validName.test(e.target.value)) {
                setError({ ...error, lastNameErr: true })
                return true
            }
            setError({ ...error, lastNameErr: false })
        }
        if (e.target.name === 'email') {
            if (!validEmail.test(e.target.value)) {
                setError({ ...error, emailErr: true })
                return true
            }
            setError({ ...error, emailErr: false })
        }
        if (e.target.name === 'tax_id') {
            if (e.target.value !== '' && isNaN(Number(e.target.value))) {
                setError({ ...error, taxIdError: true })
                return true
            }
            setError({ ...error, taxIdError: false })
        }
        return false
    }

    const handleDataChange = (e) => {
        validationLabel(e)
        setShowPrompt(true)
        setDonorData({ ...donorData, [e.target.name]: e.target.value })
    }


    const handleFeaturedImage = async (e, logo,confirm) => {
        try{
            if (!e.target.files[0]) return;
            setShowPrompt(true)
            if(logo){
                setPortraitImageLoading(true);
            }
            else{
                setFeaturedImageLoading(true)
            }
        const image = e.target.files[0];
        if (image.size > 5000000) {
            setShowPrompt(true)
            setAlertMsg("File size should be less than or equal to 5MB");
            if(logo){
                setPortraitImageLoading(false);
            }
            else{
                setFeaturedImageLoading(false)
            }
            return;
        }
        const imageUrl = URL.createObjectURL(image);
        const { width, height } = await checkImageBeforeUpload(imageUrl);
        if (width < 300 || height < 300) {
            setShowPrompt(true)
            setAlertMsg(constants.ADMIN_COPY_ITEM_IMG_POTRAIT_SIZE);
            if(logo){
                setPortraitImageLoading(false);
            }
            else{
                setFeaturedImageLoading(false)
            }
            return;
        }
        if(logo){
            
              if(!confirm){
                if(width/height < 0.98 || width/height > 1){
                  setPortraitEvent(e);
                  setPortraitImageLoading(false)
                  setWhiteborderPortraitAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','1:1'));
                  return
                }
              }else{
                setPortraitEvent(null)
              }
        }else{
            if(!confirm){
                if(width/height < 0.98 || width/height > 1){
                  setFeaturedLogoEvent(e);
                  setFeaturedImageLoading(false)
                  setFeaturedLogoWhiteBorderAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','1:1'));
                  return
                }
              }else{
                setFeaturedLogoEvent(null)
              }
        }
        const formData = new FormData();
        formData.append("image", image);
        formData.append("width", 300);
        formData.append("height", 300);
        const preProcessedImage = await auctionServices.imagePreProcess(formData);
        if (logo) {
            setSplashImage([
                {
                    name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`,
                    type: "img",
                    updated: true
                },
            ]);
            const uint8 = new Uint8Array(preProcessedImage.buffer.data);
            const newBlob = new Blob([uint8], { type: "image/jpeg" });
            setSplashIcon(newBlob);
        } else {
            setFeaturedImage([
                {
                    name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`,
                    type: "img",
                    updated: true
                },
            ]);
            const uint8 = new Uint8Array(preProcessedImage.buffer.data);
            const newBlob = new Blob([uint8], { type: "image/jpeg" });
            setFeaturedIcon(newBlob);
        }
        setFeaturedImageLoading(false)
        setPortraitImageLoading(false);
        setShowPrompt(false)
        }
        catch(error){
        setFeaturedImageLoading(false)
        setPortraitImageLoading(false);
        setShowPrompt(false)
        }
    };

    const handleFeaturedImageDelete = () => {
        setFeaturedIcon(null);
        setFeaturedImage([{}]);
        setDonorData({...donorData,featured_icon:''})
    }
    const handleSplashImageDelete = () => {
        setSplashIcon(null);
        setSplashImage([{}]);
        setDonorData({...donorData,splashimage:''})
    }

    const validateData = () => {
        if (donorData.org_name === "") {
            setAlertMsg("Organization name is required");
            setLoading(false)
            return true
        }
        // else if(ediPartnerId.isPartner && !onlyNumber.test(ediPartnerId.value)) {
        //     setAlertMsg(constants.ADMIN_COPY_DONOR_EDI_PARTNER_ALERT);
        //     setLoading(false)
        //     return true 
        // }
        else if(ediPartnerId.isPartner && !ediPartnerId?.value?.trim()) {
            setAlertMsg(constants.ADMIN_COPY_DONOR_EDI_PARTNER_REQUIRED_ALERT);
            setLoading(false)
            return true 
        }
        else if (donorData.address1 === "") {
            setAlertMsg("Address is required");
            setLoading(false)
            return true
        }
        else if (donorData.city === "") {
            setAlertMsg("City is required");
            setLoading(false)
            return true
        } else if (donorData.state === "") {
            setAlertMsg("State is required");
            setLoading(false)
            return true
        }else if (donorData.zip === "") {
            setAlertMsg("Zip is required");
            setLoading(false)
            return true
        }
        else if (donorData.contact_firstname === "") {
            setAlertMsg("First name is required");
            setLoading(false)
            return true
        }
        else if (!validName.test(donorData.contact_firstname)) {
            setAlertMsg("First name is invalid");
            setLoading(false)
            return true
        }
        else if (donorData.contact_lastname === "") {
            setAlertMsg("Last name is required");
            setLoading(false)
            return true
        }
        else if (!validName.test(donorData.contact_lastname)) {
            setAlertMsg("Last name is invalid");
            setLoading(false)
            return true
        }
        else if (donorData.email === "") {
            setAlertMsg("Email is required");
            setLoading(false)
            return true
        }
        else if (!validEmail.test(donorData.email)) {
            setAlertMsg("Email is invalid");
            setLoading(false)
            return true
        } else if (donorData.about === "") {
            setAlertMsg("Please write something about the organization");
            setLoading(false)
            return true
        }
        else if (donorData.tax_id !== '' && isNaN(Number(donorData.tax_id))) {
            setAlertMsg("Tax id must be a numeric");
            setLoading(false)
            return true;
        }
        else if (donorFeatured && (donorData.splashimage === '' && !splashIcon)){
            setAlertMsg(constants.ADMIN_COPY_DONOR_SPLASH_IMAGE_REQUIRED_ALERT);
            setLoading(false)
            return true;
        }
        else if (donorFeatured && (donorData.featured_icon === '' && !featureIcon)){
            setAlertMsg(constants.ADMIN_COPY_DONOR_FEATURED_ICON_REQUIRED_ALERT);
            setLoading(false)
            return true;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setShowPrompt(false)
        try {
            if (validateData()) {
                return
            }
            let formData = new FormData();
            formData.append("org_name", donorData.org_name);
            formData.append("about", donorData.about);
            formData.append("contact_firstname", donorData.contact_firstname);
            formData.append("contact_lastname", donorData.contact_lastname);
            formData.append("address1", donorData.address1);
            formData.append("city", donorData.city);
            formData.append("state", donorData.state);
            formData.append("zip", donorData.zip);
            formData.append("email", donorData.email);formData.append("address2", donorData.address2);formData.append("legal_name", donorData.legal_name);
            parseInt(donorData.tax_id) && formData.append("tax_id", donorData.tax_id);
            if(featureIcon)formData.append("featured_icon", featureIcon)
            else formData.append("featured_icon", donorData.featured_icon)
            if(splashIcon) splashIcon && formData.append("splashimage", splashIcon)
            else formData.append("splashimage", donorData.splashimage)   
            /// for making featured
            if (donorFeatured) formData.append('featured', 1)
            else formData.append('featured', 0)
            formData.append("sps_edi_trading_partner_id", ediPartnerId.value ? ediPartnerId.value : null);

            await auctionServices.updateDonor(donor_id, formData)
            setLoading(false)
            navigate('/admin/manage-donor')
        } catch (error) {
            setLoading(false)
            if(error?.response?.data?.data?.length) {
                setAlertMsg(error?.response?.data?.data[0].msg || constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
            }
            else {
                setAlertMsg(error.response.data.message || constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
            }
        }
    }

    const handleFeaturedRadioChange = (e) => {
        setDonorFeatured(e)
    }

    const handleEDIRadioChange = (e) => {
        setEdiPartnerId({isPartner:e, value:''})
    }

    return (
        <Container title="Edit Donor" style={{ position: "relative" }}>
            <div className="manage-donor-title"><VolunteerActivismOutlinedIcon sx={{ fontSize: 18, marginRight: "3px", color: "skyblue" }} /> Edit Donor</div>
            <form>
                <div className="addDonor-container">
                    <div className="addDonor-container-left">

                        <Input
                            label="Donor Name (Company Name or Individual That Will Be Displayed in Marketplace)"
                            required
                            name='org_name'
                            value={donorData.org_name}
                            onChange={handleDataChange}
                        />
                        <>
                        <Input
                                name='address1'
                                value={donorData.address1}
                                required
                                label="Organization Address1"
                                onChange={handleDataChange}
                            />

                            <Input
                                type="text"
                                name='address2'
                                label="Organization Address2"
                                value={donorData.address2}
                                onChange={handleDataChange}
                            />

                            <Input
                                required
                                name='city'
                                value={donorData.city}
                                label="City"
                                onChange={handleDataChange}
                            />

                            <Input
                                required
                                name='state'
                                value={donorData.state}
                                label="State"
                                onChange={handleDataChange}
                            />

                            <Input
                                label="Zip"
                                name='zip'
                                required
                                value={donorData.zip}
                                onChange={handleDataChange}
                            />
                            <Input
                                required
                                name='contact_firstname'
                                value={donorData.contact_firstname}
                                label="Contact First Name"
                                onChange={handleDataChange}
                                style={{ marginBottom: "0" }}
                            />
                            {error.firstNameErr && (
                                <label
                                    style={{
                                        textAlign: "left",
                                        color: "red",
                                        fontWeight: "400",
                                        marginBottom: "10px",
                                    }}
                                >
                                    Enter a valid first name!
                                </label>
                            )}

                            <Input
                                required
                                name='contact_lastname'
                                value={donorData.contact_lastname}
                                label="Contact Last Name"
                                onChange={handleDataChange}
                                style={{ marginBottom: "0" }}
                            />
                            {error.lastNameErr && (
                                <label
                                    style={{
                                        textAlign: "left",
                                        color: "red",
                                        fontWeight: "400",
                                        marginBottom: "10px",
                                    }}
                                >
                                    Enter a valid last name!
                                </label>
                            )}
                            <Input
                                name='email'
                                value={donorData.email}
                                label="Contact Email Address"
                                required
                                onChange={handleDataChange}
                                style={{ marginBottom: "0" }}
                            />
                            {error.emailErr && (
                                <label
                                    style={{
                                        textAlign: "left",
                                        color: "red",
                                        fontWeight: "400",
                                        marginBottom: "0",
                                    }}
                                >
                                    Enter a valid email id!
                                </label>
                            )}
                        </>

                    </div>
                    <div className="addDonor-container-right">

                    <RadioGroupComponent 
                       label={'Is the donor a trading partner of EDI/SPS?'}
                       firstLabel="Yes"
                       secondLabel= "No"
                       onChange={handleEDIRadioChange}
                       checked={ediPartnerId.isPartner}
                      />

                        {
                          ediPartnerId.isPartner && 
                          <Input
                             label="Enter ID"
                             value={ediPartnerId.value}
                             onChange={(e) => {
                               setEdiPartnerId({isPartner:true, value:e.target.value});
                             }}
                           />
                        }
                        <TextArea
                            value={donorData.about}
                            name='about'
                            onChange={handleDataChange}
                            required
                            label="Tell us about your organization (max 1000 characters)"
                            maxsize="1000"
                        />
                        <>
                            <Input
                                label="Organization Legal Name (For Tax Purposes)"
                                name='legal_name'
                                value={donorData.legal_name}
                                onChange={handleDataChange}
                            />

                            <Input
                                name='tax_id'
                                label="Organization Tax ID"
                                value={donorData.tax_id}
                                onChange={handleDataChange}
                            />
                            {error.taxIdError && (
                                <label
                                    style={{
                                        textAlign: "left",
                                        color: "red",
                                        fontWeight: "400",
                                        marginBottom: "0",
                                    }}
                                >
                                    Tax id must be a numeric
                                </label>
                            )}
                             <RadioGroupComponent 
                                label={'Make this donor a featured donor'}
                                firstLabel="Yes"
                                secondLabel= "No"
                                onChange={handleFeaturedRadioChange}
                                checked={donorFeatured}
                             />
                            <ImageInput
                                loading={portraitImageLoading}
                                required={donorFeatured}
                                label="Featured Logo"
                                sidelabel="Recommended size 300*300"
                                handleChange={(e) => handleFeaturedImage(e, "splashImage",false)}
                                images={splashImage[0].name ? splashImage : []}
                                edit
                                onDelete={handleSplashImageDelete}
                                infoBubbleText={constants.ADMIN_COPY_DONOR_SPLASH_IMAGE_INFO_TEXT}
                                iButton
                            />
                            <ImageInput
                                loading={featuredImageLoading}
                                required={donorFeatured}
                                label="Featured Icon"
                                sidelabel="Recommended size 300*300"
                                handleChange={(e) => handleFeaturedImage(e,null,false)}
                                images={featuredImage[0].name ? featuredImage : []}
                                edit
                                onDelete={handleFeaturedImageDelete}
                                infoBubbleText={constants.ADMIN_COPY_DONOR_FEATURED_ICON_INFO_TEXT}
                                iButton
                            />
                        </>
                    </div>

                </div>
                <div className="add-donor-btn" style={{ textAlign: "center" }}>
                    <button className="aitem-modal-btn" type="submit" onClick={handleSubmit}>Update</button>
                </div>
            </form>
                {
                    godAccess &&
                    <SaleIntegration godAccess={godAccess} setLoading={setLoading} paramsDonorId={donor_id} />
                }
            <div style={{fontSize: "0.9rem", marginTop: "20px", fontWeight: "600"}}><span style={{color: "red"}}>*</span> - required fields</div>
            <Alert isOpen={!!alertMsg} message={alertMsg} onClose={() => setAlertMsg(null)} />
            <LoadingModal isOpen={loading} />
            <Alert isOpen={!!whiteborderPortraitAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handleFeaturedImage(portraitEvent,'splashImage',true);
                setWhiteborderPortraitAlert(null)
                }} 
                onClose={()=>setWhiteborderPortraitAlert(null)} 
                message={whiteborderPortraitAlert}/>
           <Alert isOpen={!!featuredLogoWhiteBorderAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handleFeaturedImage(featuredLogoEvent,null,true);
                setFeaturedLogoWhiteBorderAlert(null)
                }} 
                onClose={()=>setFeaturedLogoWhiteBorderAlert(null)} 
                message={featuredLogoWhiteBorderAlert}/>
        </Container>
    )
}

export default EditDonor