import React, { useContext } from 'react'
import { useNavigate } from "react-router-dom";
import Logo from '../../../images/BGbig.png';
import AuthContext from '../../../store/auth-context';
import LogoutIcon from '@mui/icons-material/Logout';
import "./Header.css"
import authService from '../../../services/auth-services';
import { COLORS_OBJECT } from '../../../constants/en';

const Header = () => {
  let navigate = useNavigate();
  const ctx = useContext(AuthContext);

  const handleLogout = () => {
    authService.logout()
    window.location.reload()
  }
const redColor = COLORS_OBJECT.color[0];

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-logo'>
          <img src={Logo} alt="Logo" onClick={() => { navigate('/admin/cause') }} />
        </div>
        {ctx.isLoggedIn ? (<div className='navbar-menu'>
          <div className='navbar-username'>Welcome, <span style={{color: redColor, fontWeight: "700"}}>{`${ctx.user.first_name} ${ctx.user.last_name}`}</span></div>
          <div onClick={handleLogout} style={{cursor: "pointer"}}><LogoutIcon /></div>
        </div>) : (
          <div className='navbar-menu'>
            <div className='navbar-help'>
              Help
            </div>
            <div className='navbar-v-line'></div>
            <div className='navbar-buttons'>
              <button onClick={() => {
                navigate("/admin/sign-in")
              }} className='signin-btn'>Sign In</button>
              <button onClick={() => {
                navigate("/admin/sign-up")
              }} className='register-btn'>Register</button>
            </div>
        </div>
        )}
      </nav>
      <div className='navbar-place'></div>
    </>
  )
}

export default Header