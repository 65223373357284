import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Container from '../../components/HOC/Container/Container';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, TablePagination } from '@mui/material';
import { BorderColorOutlined, CancelOutlined, TaskAltOutlined } from '@mui/icons-material';
import blogServices from '../../../services/blog-service';
import Alert from '../../components/Alert/Alert';
import { setBlogLimit, setBlogPage } from '../../../redux/managePageReducer';
import authService from '../../../services/auth-services';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import AccessDenied from '../../components/AccessDenied/AccessDenied';
import { COLORS_OBJECT } from '../../../constants/en';
import TableButton from '../../components/TableButton/TableButton';

const redColor = COLORS_OBJECT.color[0]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


function BlogList() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [alert, setAlert] = useState(null);
    const [blogs, setBlogs] = useState([]);
    const [totalBlogCount, setTotalBlogCount] = useState(0)
    const [pageLoading, setPageLoading] = useState(true)
    const [blogLoading, setBlogLoading] = useState(false)
    const [access, setAccess] = useState(false)
    const { blogPage, blogLimit } = useSelector(state => state.managePage)

    useEffect(() => {
        access && fetchAllBlogs()
    }, [blogPage, blogLimit, access])
    useEffect(() => {
        verifyPermission()
    }, [])

    const fetchAllBlogs = async () => {
        try {
            setBlogLoading(true)
            let allBlogs = await blogServices.getAllBlogs(blogPage, blogLimit, setAlert);
            setBlogs(allBlogs.data)
            setTotalBlogCount(allBlogs.total_count)
            setBlogLoading(false)
        }catch(error){
            setAlert(error.message)
            setBlogLoading(false)
        }
    }
    const updateBlogStatus = async (id, status) => {
        if (!id) return
        setBlogLoading(true)
        if (status === 'active') {
            status = 'inactive'
        } else {
            status = 'active'
        }
        await blogServices.updateBlogStatus(id, status, setAlert)
        fetchAllBlogs()
    }

    const verifyPermission = async () => {
        try {
            setPageLoading(true)
            const { data } = await authService.checkBlogPermissions()
            if (data.access) {
                setAccess(true)
            }
            setPageLoading(false)
        } catch (error) {
            if (error?.response?.status === 403 || !error?.response?.data?.access) {
                setAccess(false)
            }
            setPageLoading(false)
        }
    }
    return (
        <Container title='Blog' showButton buttonText='Add Blog' onClickButton={() => navigate('/admin/blog/create-blog')} >
            {pageLoading ? <LoadingModal isOpen={true} /> : (access ? blogLoading?
                 <LoadingModal style={{ position: 'relative', width: '100%', marginLeft: '0px', minHeight: '300px', backgroundColor: 'white' }} isOpen={true} /> 
               :
               blogs.length?<Paper>
                    <TableContainer>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ width: "37%" }}>Title</StyledTableCell>
                                    <StyledTableCell sx={{ width: "37%" }}>Subtitle</StyledTableCell>
                                    <StyledTableCell sx={{ width: "16%" }}>Manage</StyledTableCell>
                                    <StyledTableCell sx={{ width: "10%" }}></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {blogs?.map((row, i) => (
                                    <StyledTableRow key={i}>
                                        <StyledTableCell>{row.title}</StyledTableCell>
                                        <StyledTableCell>{row.subtitle}</StyledTableCell>
                                        <StyledTableCell>
                                            {/* <div onClick={() => updateBlogStatus(row.id, row.status)} className='table-btn' style={{ gap: '5px' }}>
                                                {row.status === 'active' ? <CancelOutlined sx={{ fontSize: "16px", color: "#ff0000c7" }} /> : <TaskAltOutlined sx={{ fontSize: "16px", color: "#008000cf" }} />}
                                                <span style={{ color: `${row.status === 'active' ? '#ff0000c7' : '#008000cf'}`, fontWeight: "600" }}>{row.status === 'active' ? 'Deactivate' : 'Activate'}</span>
                                            </div> */}

                                                <TableButton 
                                                   onClick={() => updateBlogStatus(row.id, row.status)}
                                                   style={{gap:'5px'}}
                                                   Icon={row.status === 'active' ? CancelOutlined : TaskAltOutlined }
                                                   color={row.status === 'active' ? redColor : "#008000cf"}
                                                   text={row.status === 'active' ? 'Deactivate' : 'Activate'}
                                                />


                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TableButton onClick={() => navigate(`/admin/blog/edit-blog/${row.pretty_url}`)} Icon={BorderColorOutlined} backgroundColor={redColor} color='white'/>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20]}
                        component="div"
                        count={totalBlogCount}
                        rowsPerPage={blogLimit}
                        page={blogPage - 1}
                        onPageChange={(e, newPage) => dispatch(setBlogPage(newPage + 1))}
                        onRowsPerPageChange={(e) => dispatch(setBlogLimit(+e.target.value))}
                    />
                </Paper> :<Paper sx={{ height: '350px', textAlign: 'center', paddingY: '140px' }}>No blog to show</Paper>:
                <AccessDenied title='blog' />
            )}

            <Alert isOpen={!!alert} message={alert} onClose={() => setAlert(null)} />

        </Container>
    )
}

export default BlogList