import React, { useEffect, useRef, useState } from 'react'
import Container from '../../../components/HOC/Container/Container'
import Alert from '../../../components/Alert/Alert'
import LoadingModal from '../../../components/LoadingModal/LoadingModal'
import FormTextarea from "../../../components/FormTextarea/FormTextarea"
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { updateMarketPlaceProperties } from '../../../../redux/savedMarketPlaceReducer'
import auctionServices from '../../../../services/auction-services'
import {useDispatch} from 'react-redux'
import { setDefaultEndDate, setDefaultStartDate } from '../../../../utils/util-functions'
import { updateEvent } from '../../../../redux/dependenciesReducer'
import { updateStoreProperties } from '../../../../redux/savedStoreReducer'
import usePrompt from '../../../hooks/usePrompt'
import QuillEditor from '../../../components/QuillEditor/QuillEditor'
import constants from '../../../../constants/en'
import usePopState from '../../../hooks/usePopState'
import { setItemToLocalStorage } from '../../../../utils/autoSave'

const MarketTerms = ({reduxState, lowerName, capitalName}) => {
    const [errorMsg, setErrorMsg] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const [showPrompt, setShowPrompt] = useState(true)
    const [alertMsg, setAlertMsg] = useState(null)
    const dispatch = useDispatch()
    const location = useLocation()
    const {auction_id} = useParams()
    const localStorageDataName = `${lowerName}Data`;
    // usePrompt(`Do you want to leave? Please note that any unsaved changes to this page and the ${lowerName}'s page will be lost.`, showPrompt)
    usePrompt(`Do you want to leave?`, showPrompt, localStorageDataName, reduxState)
    // usePopState(localStorageDataName, reduxState, showPrompt)
    const modules = {
      toolbar: false
    }
    // const reduxStateRef = useRef();
    // useEffect(() => {
    //   reduxStateRef.current = reduxState
    // },[reduxState?.shipping_info, reduxState?.terms_info])
    // useEffect(()=> {
    //   return ()=> {
    //     localStorage.setItem(localStorageDataName, JSON.stringify(reduxStateRef.current))
    //     console.log('done')
    //   }
    // },[])

    useEffect(() => {
      if(showPrompt) {
        // localStorage.setItem(localStorageDataName, JSON.stringify(reduxState))
        setItemToLocalStorage(localStorageDataName, reduxState)
      }
    },[reduxState?.shipping_info, reduxState?.terms_info])

    useEffect(() => {
      if(!reduxState) {
        alert(`You need to fil initial ${lowerName} details first!`)
        if(location.pathname === '/admin/market-place/terms'){
         navigate("/admin/market-place", {replace: true})
         return
        }
        else {
         navigate("/admin/store", {replace: true})
         return
        }
      }
    }, [reduxState])

    const handleTermsInfo = (e) => {
        setShowPrompt(true)
        if(location.pathname.includes('/market-place')) dispatch(updateMarketPlaceProperties({key: "terms_info", value: e}))
        else dispatch(updateStoreProperties({key: "terms_info", value: e}))
      }
  
      const handleShippingInfo = (e) => {
        setShowPrompt(true)
        if(location.pathname.includes('/market-place')) dispatch(updateMarketPlaceProperties({key: "shipping_info", value: e.target.value}))
        else dispatch(updateStoreProperties({key: "shipping_info", value: e.target.value}))
      }

    const handleSubmit = async(e) => {
        e.preventDefault()
        setIsLoading(true)
        
        let charityData = reduxState
        const formData = new FormData()
        charityData?.title &&
          formData.append('title', charityData?.title);
        charityData?.cause_id &&
          formData.append('cause_id', charityData?.cause_id);
        charityData?.cause_category_id &&
          formData.append('cause_category_id',charityData?.cause_category_id);
        charityData?.start_date &&
          formData.append('start_date',setDefaultStartDate(charityData?.start_date));
        charityData?.end_date &&
          formData.append('end_date',setDefaultEndDate(charityData?.end_date));       
        charityData?.currency &&
          formData.append('currency', charityData?.currency);
          formData.append('shipping_info', charityData?.shipping_info);
          formData.append('terms_info', charityData?.terms_info);
        charityData?.about &&
          formData.append('about', charityData?.about);
        charityData?.image1 &&
          formData.append('image1', charityData?.image1);
        charityData?.image1 &&
          formData.append('image2', charityData?.image2);

        charityData?.auction_image_key && 
          formData.append('auction_image_key', charityData?.auction_image_key);

        charityData?.buy_now_image_key && 
          formData.append('buy_now_image_key', charityData?.buy_now_image_key);

        charityData?.video &&
          formData.append('video', charityData?.video);
        charityData?.custom_url && 
          formData.append('custom_url', charityData?.custom_url.replace(/ /g, "-"));
        charityData?.donor_id && 
          formData.append('donor_id', charityData?.donor_id);
        
        formData.append('need_box', charityData?.need_box);
        charityData?.box_color && 
          formData.append("box_color", charityData.box_color);
        charityData?.text_color && 
          formData.append("text_color", charityData.text_color);
        charityData?.featured_image &&
          formData?.append('featured_image', charityData?.featured_image)
          formData.append("show_details_over_banner", charityData?.show_details_over_banner ? 1 : 0)
        let imageLocation = 'landscape'
        if(lowerName === 'marketplace') {
            imageLocation = 'market_landscape'
        }
        formData.append('image_location', imageLocation)
          try {
            setShowPrompt(false);
            let forCharity;
            let navigatePath;
            if(location.pathname.includes('/market-place')){
               forCharity = "forMarketPlace";
               navigatePath = '/admin/market-place/items/add'
            }
            else {
              forCharity = "charity_store";
              navigatePath = '/admin/store/items/add'
            }
            const response = await auctionServices.createAuction(formData,forCharity);
            if(response.data) {   
              dispatch(updateEvent({
                type: "edit",
                auction_title: charityData?.title,
                auction_id: response.data.id,
                auction_title: charityData?.title,
                start_date: charityData?.start_date && setDefaultStartDate(charityData?.start_date),
                end_date: charityData?.end_date && setDefaultEndDate(charityData?.end_date),
                active:location.pathname.includes('/market-place') ? 'market' : 'store',
              }))
              localStorage.setItem("data", JSON.stringify({
                auction_id: response.data.id,
                auction_title: charityData?.title,
                start_date: charityData?.start_date && setDefaultStartDate(charityData?.start_date),
                end_date: charityData?.end_date && setDefaultEndDate(charityData?.end_date)
              }))
                //  if(charityData.custom_categories  && Object.keys(charityData.custom_categories).length && charityData.custom_category_images) {
                  if(charityData.custom_categories  && Object.keys(charityData.custom_categories).length) {
                  const categoryFormData = new FormData()
                  categoryFormData.append("customCategories",JSON.stringify(charityData.custom_categories))
      
                  for(let tempId in charityData.custom_category_images){
                    categoryFormData.append("customCategoryImages",charityData.custom_category_images[tempId],`${tempId}`)
                  }
                  const categoryResponse = await auctionServices.addCustomCategories(response.data.id,categoryFormData)
                }          
                setErrorMsg(null);
                setIsLoading(false);
                localStorage.removeItem(`${lowerName}Data`)
                localStorage.removeItem(`extra${capitalName}State`)
                navigate(navigatePath)
            }

        } catch (error) {
            setIsLoading(false);
            setShowPrompt(true)
            // if(error?.response?.data?.message === 'Custom URL already taken. Please choose another.'){
            //   setAlertMsg(`Custom URL already taken. Please choose another.`)
            // } 
           if((error?.response?.status === 400) && error?.response?.data?.message === 'Validation failed'){
              setAlertMsg(`${error?.response?.data?.data[0].param} - ${error?.response?.data?.data[0].msg}`)
            }
             else {
              setAlertMsg(error?.response?.data?.message ? error?.response?.data?.message : "Validation Failed")
            }
          }

    }
    return(
        <Container title={` ${auction_id ? "Edit": 'Add'} ${capitalName} Terms And Conditions`} style={{position: "relative"}}>
            <div className="event-title"> <EventNoteOutlinedIcon sx={{ fontSize: 18, marginRight: "3px", color: "skyblue" }} />{reduxState?.title}</div>
            <hr style={{margin: 0, marginBottom: "10px"}} />
                <div className='row' style={{ justifyContent: 'center' }}>
                    <form onSubmit={handleSubmit} className='col-md-8 col-lg-9'>
                        {/* <FormTextarea value={reduxState?.terms_info} onChange={handleTermsInfo} rows={4} required label={`Add your ${capitalName} Terms and Conditions`}  placeholder={`Please enter ${lowerName} specific terms and conditions`} maxsize={2000}/> */}
                        <QuillEditor
                          onChange={handleTermsInfo}
                          value={reduxState?.terms_info}
                          modules={modules}
                          placeholder={constants.ADMIN_COPY_EVENT_TERMS_INFO?.replace('event', lowerName)}
                          label={constants.ADMIN_COPY_EVENT_TERMS_INFO_LABEL?.replace('Event', capitalName)}
                        />
                        <FormTextarea value={reduxState?.shipping_info} onChange={handleShippingInfo} rows={5} label={`Additional ${capitalName} Information`}   placeholder={"Add any other details here like shipping,\nwhere to pick up items at end of event,\nreturn policies, etc."} maxsize={2000} />
                        <div>
                            <button style={{width:'100%'}} className="aitem-modal-btn" type="submit">Save {capitalName} Terms and Continue</button>
                        </div>
                    </form>
                </div>
          <div style={{fontSize: "0.9rem", marginTop: "20px", fontWeight: "600"}}><span style={{color: "red"}}>*</span> - required fields</div>
          <Alert isOpen={!!alertMsg} message={alertMsg} onClose={() => setAlertMsg(null)} />
          <LoadingModal isOpen={isLoading} />
        </Container>
    )
}

export default MarketTerms