import React, { useRef, useState, useEffect } from 'react'
import Input from "../../../components/Input/Input"
import FormTextarea from "../../../components/FormTextarea/FormTextarea"
import DropDown from '../../../components/DropDown/DropDown'
import ImageInput from '../../../components/ImageInput/ImageInput'
import auctionServices from '../../../../services/auction-services'
import checkImageBeforeUpload from "../../../../utils/checkImageBeforeUpload"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Checkbox, FormControlLabel, Radio, Tooltip,RadioGroup, Typography } from '@mui/material'
import "./AuctionItem.css"
import Modal from '../../../components/Modal/Modal'
import Alert from '../../../components/Alert/Alert'
import { setDefaultEndDate, setDefaultStartDate } from '../../../../utils/util-functions'
import { useDispatch, useSelector } from 'react-redux'
import LoadingModal from '../../../components/LoadingModal/LoadingModal'
import { useLocation, useNavigate } from 'react-router-dom'
import usePrompt from '../../../hooks/usePrompt'
import moment from "moment"
import DatePickerComponent from '../../../components/DatePicker/DatePicker'
import causeServices from '../../../../services/cause-services'
import Multiselect from 'multiselect-react-dropdown';
import constants, { COLORS_OBJECT } from '../../../../constants/en'
import RadioGroupComponent from '../../../components/RadioGroup/RadioGroup'
import { clearAttribute, setAuctionItemImages, setAuctionItemVirtualAssets, toggleBooleanState } from '../../../../redux/persistReducer'
import usePopState from '../../../hooks/usePopState'
import ClearButton from '../../../components/ClearButtonContainer/ClearButtonContainer'
import { setItemToLocalStorage } from '../../../../utils/autoSave'
import AIGeneratedData from '../../../components/AIGeneratedData/AIGeneratedData'
const redColor = COLORS_OBJECT.color[0]

const getExtention = (file_name) => file_name ? file_name?.split('.')[file_name.split('.')?.length - 1] : undefined;
const toLocalDate = (iso) => {
  const date = new Date(iso)
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  let dh = date.getHours();
  let dm = date.getMinutes();

  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }
  if (dh < 10) {
    dh = "0" + dh
  }
  if (dm < 10) {
    dm = "0" + dm
  }
  return year + '-' + month + '-' + dt + " " + dh + ":" + dm;
}

const AuctionItem = ({ categories, reload, setShowPrompt, showPrompt, setCurrentTab, setCurrentComponentToSave, godAccess }) => {
  const { auction_id, start_date, end_date, auction_status,cause_id } = useSelector(state => state.dependencies)
  const location = useLocation()

  let forCharity = false;
  let lowerName=""
  let upperName=''
  let showEndDate=false
  let showStartDate=false
  let localStoreDataName = 'eventAuctionItem';
  let localStoreExtraDataName = 'eventExtraAuctionItem'
  if(location.pathname === '/admin/items/add'){
    lowerName='event'
    upperName='Event'
    showEndDate=true
    showStartDate=true
  }
  if(location.pathname === '/admin/market-place/items/add'){
    forCharity = true;
    lowerName='marketplace'
    upperName='Marketplace'
    localStoreDataName = 'marketplaceAuctionItem';
    localStoreExtraDataName = 'marketplaceExtraAuctionItem'
  }
  if(location.pathname === '/admin/store/items/add'){
    lowerName='store'
    upperName='Store'
    localStoreDataName = 'storeAuctionItem';
    localStoreExtraDataName = 'storeExtraAuctionItem'
  }

  const localStorageData = JSON.parse(localStorage.getItem(localStoreDataName))
  const localStorageExtraAuctionItemData = JSON.parse(localStorage.getItem(localStoreExtraDataName)) || {}
  let initialState = {
    name: '',
    description: '',
    // condition: '',
    short_description: '',
    price: '',
    reserve_price: null,
    buy_now_price: null,
    auction_id: null,
    // auction_item_category_id: null,
    // custom_category_id: null,
    category_ids: [],
    custom_category_ids: [],
    weight: "",
    height: "",
    width: "",
    length: "",
    upc: "",
    donor_id: null,
    start_date: showStartDate ? moment(start_date).format("YYYY-MM-DDTHH:mm") : '',
    end_date: showEndDate ? moment(end_date).format("YYYY-MM-DDTHH:mm") : '',
    // notes: "",
    cash_on_delivery: false,
    vendor_sku_id:""
  }

  const initialStateCopy = {...initialState}

  if(localStorageData) {
    initialState = localStorageData
  }

  const persistedData = useSelector(state => state?.persistedData)
  
    /// these two are images peristed in redux for store and marketplace
    let persistAuctionItemImages = persistedData[`${lowerName}AuctionItemImages`]
    let persistAuctionItemVirtualAssets = persistedData[`${lowerName}AuctionItemVirtualAsset`]
  
    let virtualAssetReduxName = `${lowerName}AuctionItemVirtualAsset`
    let auctionItemImagesReduxName = `${lowerName}AuctionItemImages`
  

  const [auctionItem, setAuctionItem] = useState(initialState);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [reservedSelected, setReservedSelected] = useState(localStorageExtraAuctionItemData?.auctionItemReservedSelected ? localStorageExtraAuctionItemData?.auctionItemReservedSelected : null)
  const [buyNowSelected, setBuyNowSelected] = useState(localStorageExtraAuctionItemData?.auctionItemBuyNowSelected ? localStorageExtraAuctionItemData?.auctionItemBuyNowSelected : null)
  const [itemType, setItemType] = useState(localStorageExtraAuctionItemData?.auctionItemItemType ? localStorageExtraAuctionItemData?.auctionItemItemType : "physical")
  const [openShipping, setOpenShipping] = useState(false)
  const [openDonor, setOpenDonor] = useState(false)
  const [formError, setFormError] = useState(null)
  const [virtualForm, setVirtualForm] = useState(null)
  const [isFlatRate, setIsFlatRate] = useState(Object.keys(localStorageExtraAuctionItemData)?.includes('auctionItemIsFlatRate') ? localStorageExtraAuctionItemData?.auctionItemIsFlatRate : false)

  const [hasUPC, setHasUPC] = useState(Object.keys(localStorageExtraAuctionItemData)?.includes('auctionItemHasUPC') ? localStorageExtraAuctionItemData?.auctionItemHasUPC : false)
  const [isLoading, setIsLoading] = useState(false)

  // const [imageObjects1, setImageObjects1] = useState("")
  // const [imageObjects2, setImageObjects2] = useState(persistAuctionItemImages?.length ? persistAuctionItemImages : [])
  const [imageObjects2, setImageObjects2] = useState([])
  // const [portraitImage, setPortraitImage] = useState(null);
  // const [landscapeImages, setLandscapeImages] = useState((persistAuctionItemImages?.length && localStorageExtraAuctionItemData?.auctionItemLandScapeImages) ? localStorageExtraAuctionItemData?.auctionItemLandScapeImages : []);
  const [landscapeImages, setLandscapeImages] = useState([]);
  // const [portraitImageLoading, setPortraitImageLoading] = useState(false)
  const [landscapeImageLoading, setLandscapeImageLoading] = useState(false)
  // const [showPrompt, setShowPrompt] = useState(false)
  const [donorsList, setDonorsList] = useState([])
  const [updateDonors, setUpdateDonors] = useState(true)
  const [localDonor, setLocalDonor] = useState({
    "org_name": "",
    "about": ""
  })
  const [selectedDonor, setSelectedDonor] = useState(localStorageExtraAuctionItemData?.auctionItemDonor ? localStorageExtraAuctionItemData?.auctionItemDonor : null)
  const [selectedCategory, setSelectedCategory] = useState(localStorageExtraAuctionItemData?.auctionItemSelectedCategory ? localStorageExtraAuctionItemData?.auctionItemSelectedCategory : null)
  const [navigateAction, setNavigateAction] = useState('another')
  const fileInput = useRef()

  const [shipmentType, setShipmentType] = useState(Object.keys(localStorageExtraAuctionItemData)?.includes('auctionItemShipmentType') ? localStorageExtraAuctionItemData?.auctionItemShipmentType : false)
  const [ediShipment, setEdiShipment] = useState(Object.keys(localStorageExtraAuctionItemData)?.includes('auctionItemEdiShipment') ? localStorageExtraAuctionItemData?.auctionItemEdiShipment : false);
  const [pickupType, setPickupType] = useState(Object.keys(localStorageExtraAuctionItemData)?.includes('auctionItemPickupType') ? localStorageExtraAuctionItemData?.auctionItemPickupType : false)
  const [allowMultiple, setAllowMultiple] = useState(true)

  usePrompt("Do you want to leave? ", showPrompt, localStoreDataName, auctionItem)
  usePopState(localStoreDataName, auctionItem, showPrompt)
  // const [virtualFile, setVirtualFile] = useState(false);
  const [virtualText, setVirtualText] = useState(localStorageExtraAuctionItemData?.auctionItemVirtualText ? localStorageExtraAuctionItemData?.auctionItemVirtualText : "");
  // const [virtualFiles, setVirtualFiles] = useState(persistAuctionItemVirtualAssets?.length ? persistAuctionItemVirtualAssets : []);
  const [virtualFiles, setVirtualFiles] = useState([]);
  const [reset, setReset] = useState("");
  const [canChooseCause,setCanChooseCause] = useState(localStorageExtraAuctionItemData?.auctionItemSelectedCauses?.length ? true : false);
  const [causes,setCauses] = useState([]);
  const [selectedCauses,setSelectedCauses] = useState(localStorageExtraAuctionItemData?.auctionItemSelectedCauses ? localStorageExtraAuctionItemData?.auctionItemSelectedCauses : []);
  const [allowMaxBid,setAllowMaxBid] = useState(localStorageExtraAuctionItemData?.auctionItemAllowMaxBid ? localStorageExtraAuctionItemData?.auctionItemAllowMaxBid : '2');
  const [defaultCauses,setDefaultCauses] = useState(null)
  const [selectedDefaultCauseID, setSelectedDefaultCauseId] = useState(localStorageExtraAuctionItemData?.auctionITemDefaultCauseId ? localStorageExtraAuctionItemData?.auctionITemDefaultCauseId : null)
  const [causeId,setCauseId] = useState(null)
  const [showAmountDialog, setShowAmountDialog] = useState(Object.keys(localStorageExtraAuctionItemData)?.includes('auctionItemDialogeChange') ? localStorageExtraAuctionItemData?.auctionItemDialogeChange : true)
  const [whiteborderPortraitAlert,setWhiteborderPortraitAlert] = useState(null);
  const [whiteborderLandScapeAlert,setWhiteborderLandScapeAlert] = useState(null);
  const [portraitEvent,setPortraitEvent] = useState(null);
  const [landScapeEvents,setLandScapeEvents] =useState(null) 
  const [categorySelectDisable, setCategorySelectDisable] = useState(false)
  const [auctionData, setAuctionData] = useState({})
  const [aiAnalysis, setAiAnalysis] = useState({
    modalIsOpen:false,
    aiData:null,
    isUsed:false
  })

  useEffect(() => {
    if(!imageObjects2?.length) {
      setAiAnalysis({...aiAnalysis, isUsed:false})
    }
  },[ imageObjects2])

  const [defaultStateChange, setDefaultStateChange] = useState({
    causeIdChange: localStorageExtraAuctionItemData?.auctionITemDefaultCauseId ? true : false, 
    allowMaxBidChange: localStorageExtraAuctionItemData?.auctionItemAllowMaxBid ? true : false, 
    dialogeChange: localStorageExtraAuctionItemData?.auctionItemDialogeChange ? true : false, 
    itemTypeChange: localStorageExtraAuctionItemData?.auctionItemItemType ? true : false,
    pickUpChange: localStorageExtraAuctionItemData?.auctionItemPickupType ? true : false,
    shipmentChange: localStorageExtraAuctionItemData?.auctionItemShipmentType ? true : false,
    hasUPCChange: localStorageExtraAuctionItemData?.auctionItemHasUPC ? true : false,
    isFlatRateChange: localStorageExtraAuctionItemData?.auctionItemIsFlatRate ? true : false,
    auctionItemImageChangeState: localStorageExtraAuctionItemData?.auctionItemLandScapeImages ? true : false,
    auctionItemVirtualFileChange: persistAuctionItemVirtualAssets ? true : false,
    ediShipmentChange: localStorageExtraAuctionItemData?.auctionItemEdiShipment ? true : false,
  })
  const multiselectRef = useRef();
  const isUnmounting = useRef(false);
  useEffect(() => {
    setShowPrompt(false)
    setCurrentComponentToSave('auctionItemSave')/// for changing tab after saving the unsaved changes in local storage
  },[])

  const changeShippingType = () => {
    if(godAccess) {
      // if not a god user then we don't allow the user to make the product to advatix.
      setShipmentType(false)
    }
    else {
      setShipmentType(true)
    }
  }

  useEffect(() => {
    changeShippingType()
  },[godAccess])

  
  useEffect(() => {
    /// for saving and changing tab after saving the unsaved changes in local storage
    if(persistedData?.auctionItemSave === true) {
      // localStorage.setItem(localStoreDataName, JSON.stringify(auctionItem))
      setItemToLocalStorage(localStoreDataName, auctionItem)
      dispatch(toggleBooleanState({key:'auctionItemSave', value: false}))
      if(persistedData?.tabValue) {
        setCurrentTab(persistedData?.tabValue)
        dispatch(clearAttribute({key: 'tabValue'}))
      }
    }
  },[persistedData?.auctionItemSave])
  
  useEffect(() => {
    let extraAuctionItemState = {}
    /// useEffect for saving all extrastate in localstorage for showing unsaved changes
    if(selectedCategory) {
      extraAuctionItemState = {...extraAuctionItemState, auctionItemSelectedCategory:selectedCategory}
    }
    if(selectedDonor) {
      extraAuctionItemState = {...extraAuctionItemState, auctionItemDonor:selectedDonor}
    }
    if(selectedDefaultCauseID && defaultStateChange.causeIdChange) {
      extraAuctionItemState = {...extraAuctionItemState, auctionITemDefaultCauseId: selectedDefaultCauseID}
    }
    if(selectedCauses?.length) {
      extraAuctionItemState = {...extraAuctionItemState, auctionItemSelectedCauses: selectedCauses}
    }
    if(reservedSelected?.label) {
      extraAuctionItemState = {...extraAuctionItemState, auctionItemReservedSelected: reservedSelected}
    }
    if(buyNowSelected?.label) {
      extraAuctionItemState = {...extraAuctionItemState, auctionItemBuyNowSelected: buyNowSelected}
    }
    if(allowMaxBid && defaultStateChange.allowMaxBidChange) {
      extraAuctionItemState = {...extraAuctionItemState, auctionItemAllowMaxBid: allowMaxBid}
    }
    if(defaultStateChange?.dialogeChange) {
      extraAuctionItemState = {...extraAuctionItemState, auctionItemDialogeChange: showAmountDialog}
    }
    if(defaultStateChange?.itemTypeChange) {
      extraAuctionItemState = {...extraAuctionItemState, auctionItemItemType: itemType}
    }
    if(virtualText !== '') {
      extraAuctionItemState = {...extraAuctionItemState, auctionItemVirtualText: virtualText}
    }
    if(defaultStateChange.pickUpChange) {
      extraAuctionItemState = {...extraAuctionItemState, auctionItemPickupType: pickupType}
    }
    if(defaultStateChange.shipmentChange) {
      extraAuctionItemState = {...extraAuctionItemState, auctionItemShipmentType: shipmentType}
    }
    if(defaultStateChange.ediShipmentChange) {
      extraAuctionItemState = {...extraAuctionItemState, auctionItemEdiShipment: ediShipment}
    }
    if(defaultStateChange.hasUPCChange) {
      extraAuctionItemState = {...extraAuctionItemState, auctionItemHasUPC: hasUPC}
    }
    if(defaultStateChange.isFlatRateChange) {
      extraAuctionItemState = {...extraAuctionItemState, auctionItemIsFlatRate: isFlatRate}
    }
    // if(defaultStateChange.auctionItemImageChangeState) {
    //   extraAuctionItemState = {...extraAuctionItemState, auctionItemLandScapeImages: landscapeImages}
    //   dispatch(setAuctionItemImages({key: auctionItemImagesReduxName, auctionItemImages:imageObjects2}))
    // }
    // if(defaultStateChange.auctionItemVirtualFileChange) {
    //   dispatch(setAuctionItemVirtualAssets({key: virtualAssetReduxName, auctionItemVirtualAsset:virtualFiles}))
    // }
    if(Object.keys(extraAuctionItemState)?.length) {
      // localStorage.setItem(localStoreExtraDataName, JSON.stringify(extraAuctionItemState))
      setItemToLocalStorage(localStoreExtraDataName, extraAuctionItemState)
    }
    // landscapeImages, imageObjects2,, virtualFiles removed these from dependency because we are not adding 
  },[selectedCategory,selectedDonor, selectedDefaultCauseID, selectedCauses, reservedSelected, buyNowSelected, allowMaxBid, showAmountDialog, itemType, virtualText, isFlatRate, hasUPC, shipmentType, pickupType, ediShipment])

  const handleDataChange = (e) => {
    // if(e.target.name === 'start_date' || e.target.name === 'end_date') {
    //   if(!e.target.valueAsDate) {
    //     return
    //   }
    // }
    setShowPrompt(true)
    setAuctionItem({
      ...auctionItem,
      [e.target.name]: e.target.value
    })
  }

  const handleDate = (e) => {

    if (!e.target.valueAsDate) {
      return
    }
    setAuctionItem({
      ...auctionItem,
      [e.target.name]: e.target.value
    })
  }

  const handleDonorChange = (e) => {
    setShowPrompt(true)
    setAuctionItem({ ...auctionItem, donor_id: e.value })
    setSelectedDonor(e)
    if(e.sps_edi_trading_partner_id === null) {
      setEdiShipment(false)
    }
  }


  async function addDonors() {
    try {
      const data = await auctionServices.getdonorsListbyAuctionId(auction_id)
      setDonorsList(data.donors.map((i) => {
        if(i.email) {
          return {
            label: `${i.contact_firstname} ${i.contact_lastname} - ${i.org_name}`,
            value: i.id,
            sps_edi_trading_partner_id: i?.sps_edi_trading_partner_id
          }
        }
        return {
          label: i.org_name, value: i.id, sps_edi_trading_partner_id: i?.sps_edi_trading_partner_id
        }
      }));
    } catch (err) {
      console.log(err)
    }
  }
  const getCauseId = async ()=>{
    try {
      let {data} = await auctionServices.getAuctionDetailsById(auction_id)
      setAuctionData(data)
      if(Object.keys(localStorageExtraAuctionItemData)?.length && localStorageExtraAuctionItemData?.auctionITemDefaultCauseId
         && (localStorageExtraAuctionItemData?.auctionITemDefaultCauseId?.length || Object.keys(localStorageExtraAuctionItemData?.auctionITemDefaultCauseId)?.length)
        ) {
       setSelectedDefaultCauseId(localStorageExtraAuctionItemData?.auctionITemDefaultCauseId)
      }
      else {
        setSelectedDefaultCauseId([{label:data?.causeDetails?.legal_name,value:data.cause_id}])
      }
      setCauseId(data?.cause_id)
      if(data?.donor_id) setAuctionItem({...auctionItem, donor_id:data?.donor_id})
    }
    catch(error) {
      console.log(error)
    }
  }
  useEffect(() => {
    addDonors()
  }, [updateDonors])

  useEffect(()=>{
    (async () => {
      const data = await causeServices.getUserCauses();
      let newCauses
        newCauses = data?.data.map((obj,i)=>{
          return {
            key:obj.cause.legal_name
            ,value:obj.cause_id
          }
        }) 
      setCauses(newCauses)
      setDefaultCauses(data?.data.map((obj,i)=>{
        return {
          label: obj.cause.legal_name,
          value: obj.cause_id
        }
      }))
    })()
    getCauseId()
  },[])


  const handleVirtualAsset = (e) => {

    //   if(virtualItemType === "text"){
    //     setVirtualText(e.target.value);
    //     const vir_upload_form = new FormData();
    //     const ext = "text";
    //     vir_upload_form.append('asset',e?.target?.value);
    //     vir_upload_form.append('type',ext);
    //     setVirtualForm(vir_upload_form,ext);
    //   }
    //   else{
    //     if(e?.target?.files?.length > 1) return alert('Want to upload multiple items? Try Uploading Zip file');
    //     const fln = e?.target?.files[0]?.name;
    //     const ext = getExtention(fln)
    //     if(!ext) return alert('Malfunctioned file, please choose another.');
    //     const vir_upload_form = new FormData();
    //     vir_upload_form.append('asset',e?.target?.files[0]);
    //     vir_upload_form.append('type',ext);
    //     setVirtualForm(vir_upload_form,ext);
    //   }

  }
  const handlefileChange = (e) => {
    // setShowPrompt(true)
    if (e.target.files.length > 5) {
      alert(constants.ADMIN_COPY_ITEM_VIRTUAL_FILE_MAX)
      return
    }
    else {
      setVirtualFiles((prev) => {
        return [...prev, ...e.target.files]
      })
      setDefaultStateChange({...defaultStateChange, auctionItemVirtualFileChange:true})
    }
  }

  const createVirtualAsset = (files, text) => {
    setShowPrompt(true)
    const form = new FormData();
    let type = [];
    let filename = [];
    form.append("instruction", text);
    files.length > 0 && files.forEach(obj => {
      const fln = obj.name;
      const ext = getExtention(fln)
      form.append("asset", obj)
      type.push(ext);
      filename.push(obj.name)
    });
    form.append("type", JSON.stringify(type))
    form.append("file_name", JSON.stringify(filename))
    return form;
  }

//   const handlePortraitImage = async (e,confirm) => {
//    try{
//     if (!e.target.files[0]) return
//     setPortraitImageLoading(true)
//     setShowPrompt(true)
//     const image = e.target.files[0]
//     if (image.size > 5000000) {
//       setPortraitImageLoading(false)
//       setShowPrompt(false)
//       setFormError(constants.ADMIN_COPY_ITEM_IMG_FILE_SIZE);
//       return
//     }
    
//     const imageUrl = URL.createObjectURL(image)
//     const { width, height } = await checkImageBeforeUpload(imageUrl)
//     if (width < 300 || height < 300) {
//       setPortraitImageLoading(false)
//       setShowPrompt(false)
//       setFormError(constants.ADMIN_COPY_ITEM_IMG_POTRAIT_SIZE)
//       return
//     }
//     if(!confirm){
//       if(width/height < 0.98 || width/height > 1){
//         setPortraitEvent(e);
//         setPortraitImageLoading(false)
//         setWhiteborderPortraitAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','1:1'));
//         return
//       }
//     }else{
//       setPortraitEvent(null)
//     }
//     const formData = new FormData()
//     formData.append("image", image)
//     formData.append("width", 300)
//     formData.append("height", 300)
//     const preProcessedImage = await auctionServices.imagePreProcess(formData)
//     setPortraitImage([{ name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img' }])
//     const uint8 = new Uint8Array(preProcessedImage.buffer.data)
//     const newBlob = new Blob([uint8], { type: "image/jpeg" })
//     setImageObjects1(newBlob)
//     setPortraitImageLoading(false)
//     setShowPrompt(false)
//    }
//    catch(error){
//     setPortraitImageLoading(false)
//     setShowPrompt(false)
//    }
//   }

  const handleLandscapeImage = async (e,confirm) => {
    try{
      if (e.target.files.length === 0) return
    setLandscapeImageLoading(true)
    // setShowPrompt(true)
    const filesUrl = []
    const selectedFiles = []
    const targetFilesObj = [...e.target.files]
    const preProcessedFiles = []
    if (targetFilesObj.length > 4 || landscapeImages.length === 4 || (landscapeImages.length + targetFilesObj.length) > 4) {
      setLandscapeImageLoading(false)
      setShowPrompt(false)
      setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE_MAX);
      return
    }
    if (targetFilesObj.every((currentValue) => currentValue.size <= 5000000)) {
      targetFilesObj.map((file) => {
        return filesUrl.push({ name: URL.createObjectURL(file), type: 'img', local: true });
      });

      let invalidImage = false;
      let invalidAspectRatio = false;
      for (const url of filesUrl) {
        const { width, height } = await checkImageBeforeUpload(url.name)
        if (width < 600 || height < 600) {
          invalidImage = true
        }
          if(width/height < 0.98 || width/height > 1){
            invalidAspectRatio = true
          }
      }
      if (invalidImage) {
        setLandscapeImageLoading(false)
        setShowPrompt(false)
        setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE_MAX_SIZE)
        return
      }
      if(!confirm){
        if(invalidAspectRatio){
          setLandScapeEvents(e);
          setLandscapeImageLoading(false);
          setWhiteborderLandScapeAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','1:1'));
          return
        }
      }else{
        setLandScapeEvents(null);
      }
      for (const image of targetFilesObj) {
        const formData = new FormData()
        formData.append("image", image)
        formData.append("width", 1024)
        formData.append("height", 1024)
        const preProcessedImage = await auctionServices.imagePreProcess(formData)
        selectedFiles.push({ name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img', local: true });
        const uint8 = new Uint8Array(preProcessedImage.buffer.data)
        const newBlob = new Blob([uint8], { type: "image/jpeg" })
        // preProcessedFiles.push(newBlob)
        preProcessedFiles.push(uint8)
      }

      setLandscapeImages([...landscapeImages, ...selectedFiles])
      setImageObjects2([...imageObjects2, ...preProcessedFiles])
      setDefaultStateChange({...defaultStateChange, auctionItemImageChangeState:true})
      setLandscapeImageLoading(false)
      // setShowPrompt(false)
    } else {
      setLandscapeImageLoading(false)
      // setShowPrompt(false)
      setFormError(constants.ADMIN_COPY_ITEM_IMG_FILE_SIZE);
      return
    }
    }catch(error){
      setLandscapeImageLoading(false)
      // setShowPrompt(false)
    }
  }

  const prepareAssetData = (item_id) => {
    const form = new FormData()
    let type = ["img"]
    // imageObjects1 && form.append('portrait', imageObjects1);
    imageObjects2.forEach(obj => {
      const newBlob = new Blob([obj], { type: "image/jpeg" })
      form.append("landscape", newBlob)
      type.push('img')
    })
    form.append("type", JSON.stringify(type))
    form.append('auction_item_id', item_id);
    return form;
  }

  const handleSubmitError = (error) => {
    if (error.response && error.response.status === 400 && error.response.data) {
      const data = error.response.data;
      if (data.data?.length > 0) {
        setFormError(`${data.data[0].param} - ${data.data[0].msg}`)
      }
      else if(error?.response?.data?.message) {
        setFormError(error?.response?.data?.message)
      }
      return;
    }
  }

  const checkValidity = () => {
    // if (!imageObjects1) {
    //   setFormError(constants.ADMIN_COPY_ITEM_IMG_POTRAIT)
    //   return true
    // }
    if(ediShipment) {
      if(!auctionItem?.vendor_sku_id || auctionItem?.vendor_sku_id?.trim() === '') {
        setFormError(constants.ADMIN_COPY_ITEM_VENDOR_SKU_ID_ALERT)
        return true
      }
    }
    if(auctionItem?.vendor_sku_id?.length && auctionItem?.vendor_sku_id?.includes(" ")) {
      setFormError(constants.ADMIN_COPY_ITEM_VENDOR_SKU_ID_EMPTY_ALERT)
        return true
    }
    if (imageObjects2.length === 0) {
      setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE)
      return true
    }
    if(!auctionItem?.end_date) {
      setFormError("Add an end date for your auction")
      return true
    }
    if(!auctionItem?.start_date) {
      setFormError("Add a start date for your auction")
      return true
    }
    if(!auctionItem?.category_ids?.length && !auctionItem.custom_category_ids?.length) {
      setFormError(constants.ADMIN_COPY_ITEM_CATEGORY)
      return true
    }
    if (itemType === 'physical') {
      if (!auctionItem.weight && !pickupType) {
        setFormError(constants.ADMIN_COPY_ITEM_PHYSICAL_PROPERTY)
        return true
      }
      return false
    }
    return false
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setShowPrompt(false)
    if (checkValidity()) return
    setShowPrompt(false)
    let payload = {
      ...auctionItem,
      name:auctionItem.name?.replace(/\s+/g, " ").trim(),
      auction_id: +auction_id,
      start_date: setDefaultStartDate(auctionItem.start_date),
      end_date: setDefaultEndDate(auctionItem.end_date),
      created: Date.now(),
      is_virtual: itemType === 'physical' ? 0 : 1,
      height: 0,
      width: 0,
      length: 0,
      weight: itemType === 'physical' ? +auctionItem.weight : 0,
      causes: JSON.stringify(selectedCauses),
      can_choose_cause: (canChooseCause && selectedCauses.length > 0) ? 1 : 0,
      allow_max_bid: allowMaxBid,
      cause_id : causeId,
      // cause_id : selectedDefaultCauseID[0]?.value,
      auction_item_amount_given_dialog:showAmountDialog ? 1 : 0,
      // category_ids: JSON.stringify(auctionItem.auction_item_category_id),
      // custom_category_ids: JSON.stringify(auctionItem.custom_category_id),
      cash_on_delivery: auctionItem?.cash_on_delivery ? 1 : 0
    };

    if(auctionItem.donor_id === null && location.pathname !== '/admin/market-place/items/add'){
      setFormError(constants.ADMIN_COPY_ITEM_DONOR)
      return
    }
    // if(auctionItem.auction_item_category_id === null && auctionItem.custom_category_id === null){
    //   setFormError(constants.ADMIN_COPY_ITEM_CATEGORY)
    //   return 
    // }

    if(!hasUPC || payload.upc.length < 2) {
      delete payload.upc
    }
    if (itemType === 'physical') {
      payload['is_flat_rate_shipping'] = isFlatRate ? 1 : 0
      payload.fulfilment_name = shipmentType ? 'self' : ediShipment ? 'edi' : 'advatix';
      payload.pickup_type = pickupType ? 'pickup' : 'ship';
      payload.allow_multiple = allowMultiple ? 1 : 0;

    } else {
      payload['is_flat_rate_shipping'] = 0
      payload.fulfilment_name = 'virtual';
      payload.pickup_type = 'virtual';
      payload.allow_multiple = 1;
    }
    if (payload.reserve_price && +payload.reserve_price < +payload.price) {
      setFormError(constants.ADMIN_COPY_ITEM_RESERVE_PRICE_ERROR)
      return
    }
    if (auctionItem.buy_now_price &&
      (+auctionItem.buy_now_price < +auctionItem.price ||
        +auctionItem.buy_now_price < +auctionItem.reserve_price)) {
      setFormError(constants.ADMIN_COPY_ITEM_BUY_NOW_PRICE)
      return
    }
    if (itemType === 'virtual') {
      if ((virtualText === "" && virtualFiles.length === 0)) {
        setFormError(constants.ADMIN_COPY_VIRTUAL_ITEM )
        return;
      }
    }
    if(canChooseCause && selectedCauses?.length === 0){
      setFormError(constants.ADMIN_COPY_ITEM_SELECT_CAUSE);
      return
    }
    if (payload) {
      setIsLoading(true);
      // const auction_id = (addingFromEdit.state && addingFromEdit.auctionId) ? addingFromEdit.auctionId : auctionItems.auction_id
      try {

        await auctionServices
          .createAuctionItem(payload, auction_id, forCharity)
          .then(
            async (response) => {
              if (response.data) {
                setShowPrompt(false)
                const assetData = prepareAssetData(response.data.id)
                await auctionServices.createAuctionItemAsset(assetData, auction_id)
                if (itemType === 'virtual') {
                  let virtualFormData = createVirtualAsset(virtualFiles, virtualText)
                  await auctionServices.uploadVirItem(virtualFormData, auction_id, response.data.id);
                }
                setIsLoading(false);
                setFormError(null)
                if (navigateAction === 'another') {
                  setAuctionItem({
                    name: '',
                    description: '',
                    // condition: '',
                    short_description: '',
                    price: '',
                    reserve_price: null,
                    buy_now_price: null,
                    auction_id: null,
                    category_ids: [],
                    custom_category_ids: [],
                    weight: "",
                    height: "",
                    width: "",
                    length: "",
                    upc: "",
                    donor_id: null,
                    start_date: showStartDate ? moment(start_date).format("YYYY-MM-DDTHH:mm") : '',
                    end_date:showEndDate ? moment(end_date).format("YYYY-MM-DDTHH:mm") : '',
                    // notes: ""
                  })
                  setShowAmountDialog(true)
                  setReservedSelected(null)
                  setBuyNowSelected(null)
                  setFormError(null)
                  setHasUPC(false)
                  // setImageObjects1("")
                  setImageObjects2([])
                  // setPortraitImage(null)
                  setLandscapeImages([])
                  setShowPrompt(false)
                  setLocalDonor({
                    "org_name": "",
                    "about": ""
                  })
                  setUpdateDonors(true)
                  setIsFlatRate(false)
                  setVirtualForm(null)
                  setSelectedDonor(null)
                  setNavigateAction("another")
                  setSelectedCategory(null)
                  multiselectRef.current.resetSelectedValues();
                  setVirtualText("")
                  setItemType("physical")
                  changeShippingType()
                  setPickupType(false)
                  // if(fileInput.current) {
                  //   fileInput.current.value = ""
                  // }
                  setCanChooseCause(false)
                  clearUnsavedChanges()
                  setReset("reload")
                  reload()
                } else if(navigateAction === 'events-list') {
                  location.pathname === '/admin/items/add' && navigate('/admin/my-events')
                  location.pathname === '/admin/market-place/items/add' && navigate('/admin/market-place')
                  location.pathname === '/admin/store/items/add' && navigate('/admin/store')
                } else {
                  location.pathname === '/admin/items/add' && navigate(`/admin/event-preview/${auction_id}`)
                  location.pathname === '/admin/market-place/items/add' && navigate(`/admin/market-place-preview/${auction_id}`)
                  location.pathname === '/admin/store/items/add' && navigate(`/admin/store-preview/${auction_id}`)
                }
              }
            },
            (error) => {
              handleSubmitError(error)
              setIsLoading(false);
            }
          );
      } catch (error) {
        console.log(error)
        setIsLoading(false);
        setFormError(constants.ADMIN_COPY_ITEM_CREATE_FAILURE);
      }
    }
  }

  const handleCategoryChange = (e) => {
    setShowPrompt(true)
    // if (e.value.type === 'global') {
    //   setAuctionItem({
    //     ...auctionItem,
    //     auction_item_category_id: e.value.id,
    //     custom_category_id: null
    //   })
    // } else if (e.value.type === 'custom') {
    //   setAuctionItem({
    //     ...auctionItem,
    //     custom_category_id: e.value.id,
    //     auction_item_category_id: null
    //   })
    // }
    e?.map((item) => {
      if(item.value == 16 || auctionItem.category_ids?.includes(16)) {
          setAuctionItem((prevAuctionItem) => ({
            ...prevAuctionItem,
            category_ids: [16],
            custom_category_ids: []
          }))
         setSelectedCategory(e.filter((cat)=> cat.value === 16)) 
         setCategorySelectDisable(true)
      }
      else {
        setCategorySelectDisable(false)
      if (item.type === 'global' && !auctionItem.category_ids?.includes(item.value) && !auctionItem.category_ids?.includes(16) ) {
        setAuctionItem((prevAuctionItem) => ({
          ...prevAuctionItem,
          category_ids: [...prevAuctionItem.category_ids, item.value],
        }))
      } else if (item.type === 'custom' && !auctionItem.custom_category_ids?.includes(item.value) && !auctionItem.category_ids?.includes(16)) {
        setAuctionItem((prevAuctionItem) => ({
          ...prevAuctionItem,
          custom_category_ids: [...prevAuctionItem.custom_category_ids, item.value],
        }))
      }
      setSelectedCategory(e)
    }
    })
  }

  const handleCategoryRemove = (e) => {
    const globalCategory = e.filter((catItem, index) => (e.indexOf(catItem) === index) && catItem.type === 'global').map((item) => item.value);
    setAuctionItem((prevAuctionItem) => ({
      ...prevAuctionItem,
      category_ids: globalCategory
    }))
    const customCategory = e.filter((catItem,index) => (e.indexOf(catItem) === index) && catItem.type === 'custom').map((item) => item.value);
    setAuctionItem((prevAuctionItem) => ({
      ...prevAuctionItem,
      custom_category_ids: customCategory
    }))
    setSelectedCategory(e)
  }

  const handleLocalDonor = async () => {
    try {
      setIsLoading(true)
      if(localDonor.org_name?.length < 1 || localDonor.about?.length < 1){
        setIsLoading(false)
        // setFormError("Please fill all fields")
        alert(constants.ADMIN_COPY_ITEM_LOCAL_DONOR_FIELD )
        return
      }
      if(localDonor.org_name?.trim()?.length < 2) {
        setIsLoading(false)
        alert(constants.ADMIN_COPY_ITEM_LOCAL_DONOR_ORG_NAME )
        return
      }
      if(localDonor.about?.trim()?.length < 2) {
        setIsLoading(false)
        alert(constants.ADMIN_COPY_ITEM_LOCAL_DONOR_ABOUT)
        return
      }
      const data = await auctionServices.createLocalDonor({org_name: localDonor.org_name, about: localDonor.about,auction_id:auction_id})
      setUpdateDonors(!updateDonors)
      setLocalDonor({
        org_name: "",
        about: ""
      })
      setSelectedDonor({
        label: data.org_name,
        id: data.id
      })
      setAuctionItem({
        ...auctionItem,
        donor_id: data.id
      })
      setIsLoading(false)
      setOpenDonor(false)
    } catch (error) {
      if(error?.response?.data?.data?.length) {
        setFormError(error?.response?.data?.data[0].msg || constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
      }
      else {
        setFormError(error.response.data.message)
      }
      setOpenDonor(false)
      setLocalDonor({
        org_name: "",
        about: ""
      })
      setIsLoading(false)
    }
  }

  const deleteImage = async (url, imageIdx) => {
    setLandscapeImages(landscapeImages.filter((i, idx) => idx !== imageIdx))
    setImageObjects2(imageObjects2.filter((o, idx) => idx !== imageIdx))
    setShowPrompt(false)
  }

  // const deletePortrait = async (url, imageIdx) => {
  //   setPortraitImage(null)
  //   setImageObjects1("")
  //   setShowPrompt(false)
  // }

  const handleStartDateChange = (value) => {
    setShowPrompt(true)
    setShowPrompt(true)
    const date = moment(value).format("YYYY-MM-DDTHH:mm")
    setAuctionItem({
      ...auctionItem,
      start_date: date
    })
  }

  const handleEndDateChange = (value) => {
    setShowPrompt(true)
    setShowPrompt(true)
    const date = moment(value).format("YYYY-MM-DDTHH:mm")
    setAuctionItem({
      ...auctionItem,
      end_date: date
    })
  }

  const handleReserveDropdownChange = (e) => {
    setShowPrompt(true)
    setReservedSelected(e)
    if(e?.value === false) {
      setAuctionItem({
        ...auctionItem,
        reserve_price:null
      })
    }
  }

  const handleBuyNowDropDownChange = (e) => {
    setShowPrompt(true)
    setBuyNowSelected(e)
    if(e?.value === false){
        setAuctionItem({
          ...auctionItem,
          buy_now_price:null
     })
    }
   
  }

  const handleCauseChange = (e) =>{
    setShowPrompt(true)
    setDefaultStateChange({...defaultStateChange, causeIdChange:true})
    setSelectedDefaultCauseId(e)
    setCauseId(e?.value)
  }

  const onDialogChange = (e) => {
      setShowPrompt(true)
      setDefaultStateChange({...defaultStateChange, dialogeChange: true})
      setShowAmountDialog(e)
  }

  const handleAllowMaxBidChange = (value) => {
    setDefaultStateChange({...defaultStateChange, allowMaxBidChange:true})
    setShowPrompt(true)
    setAllowMaxBid(value)
  }

  const handleItemTypeChange = (value) => {
    setDefaultStateChange({...defaultStateChange, itemTypeChange:true})
    setItemType(value)
  }

  const handleVirtualTextChange = (e) => {
    setShowPrompt(true)
    setVirtualText(e.target.value)
  }

  const handleProductChange = (e) => {
    setShowPrompt(true)
    setAuctionItem({...auctionItem, weight: e.target.value})
  }

  const handleFlatRate = (mode) => {
    setShowPrompt(true)
    switch (mode) {
      case 'flatrate':
        setDefaultStateChange({...defaultStateChange, isFlatRateChange:true})
        setIsFlatRate(!isFlatRate)
        break;
      case 'hasupc':
        setDefaultStateChange({...defaultStateChange, hasUPCChange:true})
        setHasUPC(!hasUPC)
        break;
      case 'shipmenttype':
        if(shipmentType && !godAccess) {
          // if not god user then we are not allowing them to make it advatix
          setFormError(constants.ADMIN_COPY_NORMAL_USER_SHIPPING_OPTION_ALERT)
        }
        else {
          setDefaultStateChange({...defaultStateChange, shipmentChange:true})
          setShipmentType(!shipmentType)
          setEdiShipment(false);
        }
        break;
      case 'pickuptype':
        setDefaultStateChange({...defaultStateChange, pickUpChange:true})
        setPickupType(!pickupType)
        break;
      case 'ediShipment':
        if(lowerName !== 'marketplace' || !auctionData?.donor?.sps_edi_trading_partner_id) {
          if(!selectedDonor) {
            setFormError(constants.ADMIN_COPY_EDI_SHIPMENT_ALERT)
            return
          }
          if( !selectedDonor?.sps_edi_trading_partner_id || selectedDonor?.sps_edi_trading_partner_id === null || selectedDonor?.sps_edi_trading_partner_id === undefined) {
            setFormError(constants.ADMIN_COPY_EDI_SHIPMENT_ALERT)
            return
          }
        }
        setDefaultStateChange({...defaultStateChange, ediShipmentChange:true});
        setEdiShipment(!ediShipment);
        setShipmentType(false);
        break;
      default:
        break;
    }
    
  }

  const clearUnsavedChanges = () => {
    localStorage.removeItem(localStoreExtraDataName)
    localStorage.removeItem(localStoreDataName)
    setAuctionItem(initialStateCopy)
    setSelectedCategory(null)
    setSelectedDonor(null)
    setAiAnalysis({modalIsOpen:false, aiData:null, isUsed:false})
    if(lowerName !== 'marketplace') {
      setSelectedDefaultCauseId(null)
    }
    setSelectedCauses([])
    setReservedSelected(null)
    setBuyNowSelected(null)
    setAllowMaxBid('2')
    setShowAmountDialog(true)
    setItemType("physical")
    setVirtualText("")
    setVirtualFiles([])
    setPickupType(false)
    // setShipmentType(false)
    changeShippingType()
    setHasUPC(false)
    setIsFlatRate(false)
    setLandscapeImages([])
    setImageObjects2([])
    dispatch(clearAttribute({key: auctionItemImagesReduxName}))
    dispatch(clearAttribute({key: virtualAssetReduxName}))
    setDefaultStateChange({
      causeIdChange: false, 
      allowMaxBidChange: false, 
      dialogeChange: false, 
      itemTypeChange: false,
      pickUpChange: false,
      shipmentChange: false,
      hasUPCChange: false,
      isFlatRateChange: false,
      auctionItemImageChangeState:false,
      auctionItemVirtualFileChange: false
    })
  }

  const handleAiAnalysisClicked = async() => {
    if (imageObjects2.length === 0) {
      setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE)
      return true
    }
    try {
      setIsLoading(true)
      const formData = new FormData()
      const newBlob = new Blob([imageObjects2[0]], { type: "image/jpeg" })
      formData.append('image', newBlob)
      const response = await auctionServices.generateImageDetails(formData)
      if(response?.data?.description?.length) {
          setAiAnalysis({modalIsOpen:true, aiData:response?.data, isUsed:true})
      }
      // setAiAnalysis({...aiAnalysis, modalIsOpen:true, isUsed:true})
      setIsLoading(false)
    }catch(error) {
      setIsLoading(false)
      setAiAnalysis({modalIsOpen:false, aiData:null, isUsed:false})
      setFormError(error?.response?.data?.message || constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
    }
  }

  const handleGeneratedData = (data=null) => {
    if(!data) return;
    setShowPrompt(true)
    setAuctionItem({...auctionItem, description: data?.description})
    setAiAnalysis({...aiAnalysis, modalIsOpen:false, isUsed:true})
  }

  const showAiGeneratedData = () => {
    setAiAnalysis({...aiAnalysis, modalIsOpen:true})
  }

  const onCashOnDeliveryChange = (e) => {
    setShowPrompt(true)
    setAuctionItem({...auctionItem, cash_on_delivery:e})
  }

  return (
    <form onSubmit={handleSubmit} className="aitem-wrapper">
      <div className='form-top-actions'>
      {
        !aiAnalysis.isUsed ?
        <button onClick={handleAiAnalysisClicked} type='button' className='aitem-modal-btn'>AI Analysis</button>
        :
        <button onClick={showAiGeneratedData} type='button' className='aitem-modal-btn'>Show AI Generated Data</button>
      }
      {
        (localStorageData || Object.keys(localStorageExtraAuctionItemData)?.length) ?
        <ClearButton onClick={clearUnsavedChanges}/>
        :null
      }
      </div>
      <div className='aitem-container'>
        <div>
          <div className='aitem-section-head'>
            General
            <hr />
          </div>
          <Input label="Auction Item Title" value={auctionItem.name} name="name" onChange={handleDataChange} required />
            <div>
              {/* <DropDown value={selectedCategory} isSearchable onChange={handleCategoryChange} options={categories} label="Choose Category" required /> */}
              <label className='input-label' htmlFor="">Choose Category</label>
              <Multiselect 
                displayValue="key"
                onRemove={(e)=>handleCategoryRemove(e)}
                onKeyPressFn={function noRefCheck(){}}
                onSelect={(e)=>handleCategoryChange(e)}
                options={categories}
                showArrow
                avoidHighlightFirstOption
                placeholder="Choose Category"
                // disable={categorySelectDisable}
                selectionLimit={categorySelectDisable ? 1 : null}
                selectedValues={selectedCategory}
                ref={multiselectRef}
              /> 
            </div>
          <div className='aitem-two-inputs' style={{ gap: '10px' }}>
            { !location.pathname.startsWith('/admin/market-place')
             ?
             <div style={{ flex: 3, display: "flex", alignItems: "flex-end", gap: "10px" }}>
              <DropDown value={selectedDonor} isSearchable onChange={handleDonorChange} options={donorsList} label="Donor" required />
              <button onClick={() => setOpenDonor(true)} type='button' className='aitem-modal-btn'>Add Donor</button>
            </div>
              : null }
          </div>
        {location.pathname.includes('/admin/store') ? null : 
          <div style={{marginTop:"15px"}}>
          <label style={{ fontWeight:"600",fontSize:"0.85rem"}} >{constants.ADIMIN_COPY_CAN_CHOOSE_CAUSE}</label>
          <div style={{ display: "flex", gap: "20px" }}>        
            <FormControlLabel
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="Yes"
              checked={canChooseCause === true}
              onChange={()=>setCanChooseCause(true)}
              labelPlacement="end"
            />
            <FormControlLabel  
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="No"
              checked={canChooseCause === false}
              onChange={()=>setCanChooseCause(false)}
              labelPlacement="end"
            />
          </div>

         {
          (canChooseCause) ?  <Multiselect 
          displayValue="key"
          onRemove={(e)=>setSelectedCauses(e)}
          onKeyPressFn={function noRefCheck(){}}
          onSelect={(e)=>setSelectedCauses(e)}
          options={causes}
          showArrow
          avoidHighlightFirstOption
          placeholder="Select Causes"
          selectionLimit={4}
          selectedValues={selectedCauses}
        /> :  <DropDown 
                 value={selectedDefaultCauseID} 
                 isSearchable 
                 onChange={handleCauseChange} 
                 options={defaultCauses} 
                 label="Select Cause" 
                //  required={!location.pathname.includes('/market-place')? true : false} 
                 required={true}
                 isDisabled={lowerName === 'marketplace'}
              />
         }

          </div>}
          <FormTextarea iButton infoBubbleText='Add a short but clear description about your item'  label="Item Short Description" value={auctionItem.short_description} name="short_description" onChange={handleDataChange} required rows={1} maxsize={75} />
          <FormTextarea iButton infoBubbleText='Describe your item in greater detail' label="Item Long Description" value={auctionItem.description} name="description" onChange={handleDataChange} required maxsize={1000} />
          <Input label="How much do you want to list this item for?" value={auctionItem.price} name="price" onChange={handleDataChange} required type="number" step="any" />
          <div className='aitem-two-inputs'>
            <div>
              <DropDown menuPlacement='top' value={reservedSelected} onChange={(e) => handleReserveDropdownChange(e)} options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]} label="Does this item come with a Reserve?" labelStyle={{ fontSize: "0.75rem" }} required />
              {reservedSelected?.value && <Input label="Reserved Price" type="number" step="any"  value={auctionItem.reserve_price} name="reserve_price" onChange={handleDataChange} required />}
            </div>
            <div>
              <DropDown menuPlacement='top' value={buyNowSelected} onChange={(e) => handleBuyNowDropDownChange(e)} options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]} label="Do you want to add Buy Now option?" labelStyle={{ fontSize: "0.75rem" }} required />
              {buyNowSelected?.value && <Input label="Buy Now Price" type="number" step="any"  value={auctionItem.buy_now_price} name="buy_now_price" onChange={handleDataChange} required />}
            </div>
          </div>
          <hr />
          <label className='input-label' style={{fontSize: '0.92rem'}}>Choose The Bidding Option</label>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
           <span style={{display:'flex',flexDirection:'column'}}>
             <span>
              <FormControlLabel sx={{".MuiFormControlLabel-label": {fontSize: "0.95rem"}, marginRight: "5px !important"}} value="false" control={<Radio size='small' checked={allowMaxBid==='2'} sx={{"&.Mui-checked": {color: redColor,}}} />} label="eBay Style Auction" onChange={() =>handleAllowMaxBidChange('2')} />
              <Tooltip title={<Typography sx={{fontSize:'0.85rem'}}>
              This system is what is known as proxy bidding. When a buyer places a bid, they enter the maximum amount they're willing to pay for the item. The eBay system will then place bids on their behalf, only increasing the bid when another buyer places a competing bid. The system will continue to increase the bid until it reaches the buyer's maximum amount.
              </Typography>}><InfoOutlinedIcon sx={{color:'#495057c4',fontSize:'16px'}} /></Tooltip>
             </span>    
             <span>
               <FormControlLabel sx={{".MuiFormControlLabel-label": {fontSize: "0.95rem"}}} value="false" control={<Radio size='small' checked={allowMaxBid==='1'} sx={{"&.Mui-checked": {color: redColor,}}} />} label="Max Bid Only" onChange={() =>handleAllowMaxBidChange('1')} />
               {/* <Tooltip title={<Typography sx={{fontSize:'0.85rem'}}>Explanation</Typography>}><InfoOutlinedIcon sx={{color:'#495057c4',fontSize:'16px', marginLeft: "4px"}} /></Tooltip>  */}
             </span>     
             <span>
               <FormControlLabel sx={{".MuiFormControlLabel-label": {fontSize: "0.95rem"}}} value="true" control={<Radio size='small' checked={allowMaxBid==='0'} sx={{"&.Mui-checked": {color: redColor,}}} />} label="Increment Only" onChange={() =>handleAllowMaxBidChange('0')} />
               {/* <Tooltip title={<Typography sx={{fontSize:'0.85rem'}}>Explanation</Typography>}><InfoOutlinedIcon sx={{color:'#495057c4',fontSize:'16px', marginLeft: "4px"}} /></Tooltip> */}
             </span>
           </span>
          </RadioGroup>
          
        </div>
          <div className='aitem-section-head'>
            Auction period
            <hr />
          {start_date && <div className='item-dates'>
            {/* <Input type="datetime-local" min={moment(start_date).format("YYYY-MM-DDTHH:mm")} max={moment(end_date).format("YYYY-MM-DDTHH:mm")} required label="Start Date" value={auctionItem.start_date} onChange={handleDataChange} name="start_date"/>
              <Input  type="datetime-local" min={moment(auctionItem.start_date).format("YYYY-MM-DDTHH:mm")} max={moment(end_date).format("YYYY-MM-DDTHH:mm")} required label="End Date" value={auctionItem.end_date} onChange={handleDataChange} name="end_date" /> */}

            <DatePickerComponent  date={auctionItem.start_date} minDateTime={moment(start_date).format("YYYY-MM-DDTHH:mm")} maxDateTime={moment(auctionItem.end_date ? auctionItem.end_date : end_date ).format("YYYY-MM-DDTHH:mm")}  onChange={handleStartDateChange} label={'Start Date'} />
            <DatePickerComponent  date={auctionItem.end_date} minDateTime={moment(auctionItem.start_date).format("YYYY-MM-DDTHH:mm")} maxDateTime={moment(end_date).format("YYYY-MM-DDTHH:mm")}  onChange={handleEndDateChange} label={'End Date'} />

          </div>}

          <Input
            iButton infoBubbleText={constants.ADMIN_COPY_ITEM_EDI_INFO}
            label="Vendor SKU ID" value={auctionItem.vendor_sku_id} name="vendor_sku_id" onChange={handleDataChange}
          />
          
        <RadioGroupComponent 
          checked={showAmountDialog}
          onChange={onDialogChange}
          label={'Would you like to display a dialog that tells the buyer how much will go to cause?'}
         />

        <RadioGroupComponent 
          checked={auctionItem.cash_on_delivery}
          onChange={onCashOnDeliveryChange}
          label={constants.ADMIN_COPY_ITEM_CASH_ON_DELIVERY_LABEL}
         />

          <div className='aitem-section-head' style={{ marginTop: "15px" }}>
            Images
            <hr />

          {/* <ImageInput loading={portraitImageLoading} required edit onDelete={deletePortrait} handleChange={(e)=>handlePortraitImage(e,false)} images={portraitImage ? portraitImage : []} label={constants.ADMIN_COPY_ITEM_THUMPNAIL_IMG} sidelabel="Max 1, Recommended size 300x300" max={1} /> */}
          <ImageInput loading={landscapeImageLoading} required edit onDelete={deleteImage} multiple handleChange={(e)=>handleLandscapeImage(e,false)} images={landscapeImages} label={constants.ADMIN_COPY_ITEM_DETAILS_IMG} sidelabel="Max 4, Recommended size 1024x1024" max={4} reset={reset} setReset={(newState) => setReset(newState)} />

          <div className='aitem-section-head' style={{ marginTop: "15px" }}>
            Shipping
            <hr />
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <FormControlLabel
              value="physical"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="Physical Product"
              checked={itemType === "physical"}
              onChange={() => handleItemTypeChange("physical")}
              labelPlacement="end"
            />
            <FormControlLabel
              value="Virtual"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="Virtual Product"
              checked={itemType === "virtual"}
              onChange={() => handleItemTypeChange("virtual")}
              labelPlacement="end"
            />
          </div>
          <div>
          </div>
          {(itemType === 'virtual') && <>
            <FormTextarea label="Enter email body for how to receive or claim item" value={virtualText} name="description" maxsize={500} onChange={handleVirtualTextChange} iButton infoBubbleText='Please supply email instructions explaining how item winner/purchaser may receive or claim their purchase.' />
          </>}
          {(itemType === 'virtual') && <>
            <label className='input-label' style={{ marginBottom: 0, marginTop: "10px" }}>Upload attachments with additional instructions (if required)<Tooltip title={<Typography fontSize="0.85rem">Please upload a .pdf, .jpg or .png to send to the auction winner that includes the digital item or instructions for redemption</Typography>}><InfoOutlinedIcon sx={{ color: '#495057c4', fontSize: "16px", marginLeft: "3px" }} /></Tooltip></label>
            <input type='file' className='aitem-file-btn' style={{ marginTop: 0 }} multiple={true} onChange={handlefileChange} />
          </>
          }

          {itemType === 'physical' ? <button type="button" onClick={() => setOpenShipping(true)} className='aitem-modal-btn aitem-white-button' style={{ width: "100%" }}>Add Shipping/Pickup Details</button> : null}
          <hr />
          <div className='aitem-submit-container'>
            <button onClick={() => setNavigateAction("another")} type='submit'>Save & Add Another Item</button>
            {auction_status && auction_status === 'live' ? 
            <button onClick={() => setNavigateAction('events-list')} type="submit">Finished Adding? Save and Manage Events</button> : <button onClick={() => setNavigateAction("finish")} type='submit'>Finished Adding Items? Save and Preview {upperName}</button>}
          </div>
        </div>
        </div>
      </div>
      <div style={{ fontSize: "0.9rem", fontWeight: "600", padding: "0 10px" }}><span style={{ color: "red" }}>*</span> - required fields</div>
      <Modal isOpen={openShipping} onClose={() => setOpenShipping(false)} onDone={() => setOpenShipping(false)} title="Shipping Details">
        <div className='input-label' style={{ fontSize: "1.2rem", fontWeight: "600", borderBottom: "1px solid #d3d3d3" }}>Check all that applies</div>
        <FormControlLabel onChange={() => handleFlatRate('flatrate')} control={<Checkbox size="small" checked={isFlatRate} />} label="Flat Rate Shipping" />
        <br />
       { !location.pathname.includes('/market-place') &&
       <>
        <FormControlLabel onChange={() => handleFlatRate('hasupc')} control={<Checkbox size="small" checked={hasUPC} />} label="This product has a UPC" />
        {hasUPC && <Input label="Enter UPC" required value={auctionItem.upc} onChange={handleDataChange} name="upc" />}
        <br />
        </>}
        <FormControlLabel onChange={() => handleFlatRate('ediShipment')} control={<Checkbox size="small" checked={ediShipment} sx={{"&.Mui-checked": {color: redColor,}}}  />} label={constants.ADMIN_COPY_EDI_SHIPMENT_LABEL}/>
        <FormControlLabel onChange={() => handleFlatRate('shipmenttype')} control={<Checkbox size="small" checked={shipmentType} />} label={ location.pathname.includes('/market-place') ? 'I Will Provide Shipping & Handling (Drop Ship)' : `Shipping & Handling Managed By ${upperName} Creator`} />
        <br />
        {shipmentType && <FormControlLabel onChange={() => handleFlatRate('pickuptype')} control={<Checkbox size="small" checked={pickupType} />} label="No shipping. Customer must pick up from designated location."/>} 
        {!pickupType && <Input type="number" value={auctionItem.weight} onChange={handleProductChange} label="Product Weight (lb)" required={!pickupType} iButton infoBubbleText='If your item is one pound or less, enter the number 1.'/>}
      </Modal>

      <Modal isOpen={openDonor} onClose={() => setOpenDonor(false)} onDone={handleLocalDonor} title="New Donor">
        <Input value={localDonor.org_name} onChange={(e) => setLocalDonor({...localDonor, org_name: e.target.value})} label="Donor Name or Organization" required />
        <FormTextarea value={localDonor.about} onChange={(e) => setLocalDonor({...localDonor, about: e.target.value})} label="Long Description" required iButton infoBubbleText='Tell us about your item’s donor. Who are they? What do they do? Do you want to share a link to their web site?'/>
      </Modal>

      <Modal isOpen={aiAnalysis.modalIsOpen} onClose={() => setAiAnalysis({...aiAnalysis, modalIsOpen:false})} buttonRequired={false} title={constants.ADMIN_COPY_AI_MODAL_TITLE}>
        <AIGeneratedData data={aiAnalysis.aiData} handleGeneratedData={handleGeneratedData}/>
      </Modal>

      <Alert isOpen={formError} message={formError} onClose={() => setFormError(null)} />
      <LoadingModal isOpen={isLoading} />
      {/* <Alert isOpen={!!whiteborderPortraitAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handlePortraitImage(portraitEvent,true);
                setWhiteborderPortraitAlert(null)
                }} 
                onClose={()=>setWhiteborderPortraitAlert(null)} 
                message={whiteborderPortraitAlert}/> */}
      <Alert isOpen={!!whiteborderLandScapeAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handleLandscapeImage(landScapeEvents,true);
                setWhiteborderLandScapeAlert(null)
                }} 
                onClose={()=>setWhiteborderLandScapeAlert(null)} 
                message={whiteborderLandScapeAlert}/>
        
    </form>
  )
}

export default AuctionItem