import React, { useEffect, useState } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { Typography, Tooltip, CircularProgress } from '@mui/material';
import style from './UploadFile.module.css';

const UploadFile = ({ onUploadFile, multiple = false, label = '', infoText = '', buttonText = '',uploadStatus='ready_to_upload' }) => {
  const [isInput, setIsInput] = useState(false);
  const [files,setFiles] = useState(null);
  const handlefileChange=(e)=>{
    if(!e.target.files.length) return
    setFiles(e.target.files[0])
  }
  const handleButtonClick=()=>{
    onUploadFile(files);
  }
  useEffect(()=>{
    if(uploadStatus==='success'){
      setIsInput(false)
      setFiles(null)
    }
  },[uploadStatus])
  return (
    <div className={style.file_upload_contianer}>
      {isInput ?
        <div>
          <label className='input-label' style={{ marginBottom: 0, marginTop: "10px" }}>{label}{!!infoText && <Tooltip title={<Typography fontSize="0.85rem">{infoText}</Typography>}><InfoOutlined sx={{ color: '#495057c4', fontSize: "16px", marginLeft: "3px" }} /></Tooltip>}</label>
          <input type='file' className={style.aitem_file_btn} style={{ marginTop: 0 }} multiple={multiple} onChange={handlefileChange} />
       <div> 
         {uploadStatus==='loading'? 
           <CircularProgress
           size={32}
           sx={{
             color:'red',
             position: 'absolute',
             zIndex: 1,
             margin:'3px 37px'
           }}
         />:''}
         <button onClick={handleButtonClick} className={`aitem-modal-btn aitem-white-button ${style.upload_file_btn}`} style={{color:uploadStatus==='loading'?'gray':'red'}}>{uploadStatus==='loading'?'Uploading':'Upload file'}</button>
        
       </div>
        </div> :
        <button className='aitem-modal-btn' onClick={() => setIsInput(true)} >{buttonText}</button>}
    </div>
  )
}

export default UploadFile;