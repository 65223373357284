import constants from "../constants/en";

export const getAge = (_dob) => {
    let today = new Date();
    let birthDate = new Date(_dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

// Default Start Date with time in UTC (international time standard)
export const setDefaultStartDate = (date) => {
    const selDate = new Date(date);
    selDate.setSeconds(0,0);
    return selDate.toISOString();
    
}

// Default End Date with time in UTC (international time standard)
export const setDefaultEndDate = (date) => {
    const endOfDay = new Date(date);
    endOfDay.setSeconds(0,0);
    return endOfDay.toISOString();
}

// checking networkerror and reloading
export const checkNetworkError = (error={}) => {
    if(!window.navigator.onLine || ( error?.toJSON()?.message === 'Network Error' && error?.toJSON()?.code === 'ERR_NETWORK')){
        alert(constants.ADMIN_COPY_BAD_NETWORK);
        window.location.reload()
        return true;
    }
    else {
        return false
    }
} 


export const getEventType = (evt) => {
    if(!evt) return;
    if(evt?.market_place == '1') {
        return 'Marketplace'
    }
    else if(evt?.is_store == '1') {
        return 'Store'
    }
    else {
        return 'Event'
    }
}

export const checkVendorSkuOfVariants = (variants, checkMissing=true) => {
    let missingVendor = false;
    let hasExtraSpace = false;
    let duplicateCheck = false;
    const vendorSkuSet = new Set();
    for(let variant of variants) {
      if(checkMissing && (!variant?.vendor_sku_id || variant?.vendor_sku_id === "")) {
        missingVendor = true;
        break;
      }
      else if(variant?.vendor_sku_id?.length && variant?.vendor_sku_id?.includes(" ")) {
        hasExtraSpace = true;
        break;
      }
      else {
        if (variant?.vendor_sku_id !== "" && variant?.vendor_sku_id !== null && vendorSkuSet.has(variant?.vendor_sku_id)) {
            duplicateCheck = true; // Found a duplicate
            break;
        }
        vendorSkuSet.add(variant?.vendor_sku_id);
      }
    }
    if(missingVendor) {
      return {
        valid: false,
        message: constants.ADMIN_COPY_ITEM_ALL_VARIANT_VENDOR_SKU_ALERT
      }
    }
    else if (hasExtraSpace) {
      return {
        valid: false,
        message: constants.ADMIN_COPY_ITEM_VENDOR_SKU_ID_EMPTY_ALERT
      }
    }
    else if(duplicateCheck) {
        return {
            valid:false,
            message: constants.ADMIN_COPY_ITEM_VENDOR_SKU_DUPLICATE_ALERT
        }
    }
    else {
      return {
        valid:true,
        message: ''
      }
    }
  }