import React, { useState, useEffect } from 'react';
import authService from '../services/auth-services';
import logger from '../utils/logger';

const AuthContext = React.createContext({
  isLoggedIn: false,
  isLoading: false,
  user: "",
  onLogout: () => {},
  onLogin: (email, password) => {},
});

export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUserLoggedInInformation = localStorage.getItem('username');
    const storedUserToken = localStorage.getItem('token');
    const userVerification = async () => {
      try {
        await authService.verifyUser().then(
          (response) => {
                if(response.data.username === storedUserLoggedInInformation) {
                  setIsLoggedIn(true);
                  setIsLoading(false);
                  setUser({
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    id: response.data.id,
                    email: response.data.email
                  });
                } else {
                  setIsLoggedIn(false);
                  localStorage.clear();
                  setIsLoading(false);
                }
              },
              (error) => {
                logger('./store/auth-context.jsx','userVerification(): catch1',error);
                setIsLoggedIn(false);
                localStorage.clear();
                setIsLoading(false);
              }
            );
          } catch (err) {
            logger('./store/auth-context.jsx','userVerification(): catch2',err);
            setIsLoggedIn(false);
            localStorage.clear();
            setIsLoading(false);
          }
    }
    if (storedUserLoggedInInformation && storedUserToken) {
      setIsLoading(true);
      userVerification();
    } else {
      setIsLoading(false);
      setIsLoggedIn(false);
      // localStorage.clear();
    }
    // if (storedUserLoggedInInformation === '1') {
    //   setIsLoggedIn(true);
    // }
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem('isLoggedIn');
    setIsLoggedIn(false);
  };

  const loginHandler = () => {
    localStorage.setItem('isLoggedIn', '1');
    setIsLoggedIn(true);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        isLoading: isLoading,
        user: user,
        onLogout: logoutHandler,
        onLogin: loginHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;