import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    type: null,
    auction_id: null,
    auction_title: null,
    currentCauseId: null,
    currentCauseCategoryId: null,
    donorPageNumber:1,
    donorSorting:{order:'ASC',orderBy:'org_name'}
}

const userActionsSlice = createSlice({
    name: "user/actions",
    initialState,
    reducers: {
        addEvent: (state, action) => {
            state.type = "add"
        },
        resetAction: (state, action) => {
            return initialState
        },
        editEvent: (state, action) => {
            state.type = "edit"
            state.auction_id = action.payload.id
            state.auction_title = action.payload.title
        },
        updateCauseData: (state, action) => {
            state.currentCauseId = action.payload.causeId
            state.currentCauseCategoryId = action.payload.causeCategoryId
        },
        setPage: (state, action) => {
            state.donorPageNumber = action.payload
        },
        setSorting: (state, action) => {
            state.donorSorting = action.payload
        }

    }
})

export default userActionsSlice.reducer
export const {addEvent, resetAction, editEvent, updateCauseData, setPage, setSorting} = userActionsSlice.actions