import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import "./LoadingModal.css"

const LoadingModal = ({children, isOpen,style={}}) => {
    if(!isOpen) return null
  return (
    <div className='bg-modal' style={{...style}} >
        <CircularProgress sx={{color: "black"}} />
        <div style={{marginTop: "15px", fontWeight: "600"}}>Loading...</div>
    </div>
  )
}

export default LoadingModal