import React, { useEffect } from 'react';

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Container from '../../components/HOC/Container/Container';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePagination } from '@mui/material';
import { useState } from 'react';
import causeServices from '../../../services/cause-services';
import { useNavigate } from 'react-router-dom';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import TableButton from '../../components/TableButton/TableButton';
import authService from '../../../services/auth-services';
import Alert from '../../components/Alert/Alert';
import constants from '../../../constants/en';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const MyCauses = () => {
    const [causes, setCauses] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const [godMod,setGodMod] = useState(false)
    const [openConfirmAlert,setOpenConfirmAlert] = useState(false);
    const [deleteCauseId,setDeleteCauseId] = useState(null);
    const [alert,setAlert] = useState(null)
    const [refresh,setRefresh] = useState(false)
    useEffect(() => {
      (async () => {
            try {
              setIsLoading(true)
              const data = await causeServices.getUserCauses()
              const newCauses = data?.data?.map(c => c.cause)
              setCauses(newCauses)
              setIsLoading(false)
            } catch (error) {
              setIsLoading(false)              
            }
      })()
    }, [refresh])
    useEffect(()=>{
      checkPermission()
  },[])

  const checkPermission =async () =>{
    try {
      const permission = await authService.checkLimitedPermissions(15)
      if(permission.access) {
        setGodMod(true)
      }
      else {
        setGodMod(false)
      }
    }
    catch(error) {setGodMod(false)}
  }
    const handleAddCause = () => {
      navigate("/admin/cause/add", {state: {from: "add"}})
    }

    const handleEdit = (row) => {
      navigate(`/admin/cause/edit/${row.id}`)
    }

    useEffect(() => {
      localStorage.removeItem("data")
    }, [])
    const handleDeleteCauseConfirm =async () =>{
      setOpenConfirmAlert(false)
      if(!deleteCauseId) return 
      setIsLoading(true)
      await causeServices.archiveCause(deleteCauseId).then((res)=>{
          // console.log(res);
          setAlert(res?.data?.message)
          setIsLoading(false)
          setDeleteCauseId(null);
          setRefresh(!refresh)
      }).catch((err)=>{
          // console.log(err);
          setAlert(err?.response?.data?.message)
          setDeleteCauseId(null);
          setIsLoading(false)
      })
      
    
    }
    
    const handleCauseDelete = (cause) =>{
      setDeleteCauseId(cause?.id);
      setOpenConfirmAlert(true)

    }
    
    const handleDeleteAlertClose = () =>{
      setDeleteCauseId(null);
      setOpenConfirmAlert(false)
    }
 
  return (
    <Container title='My Causes' showButton onClickButton={handleAddCause} buttonText='Add new cause'>
   {  isLoading ? <LoadingModal isOpen={true} /> : 
     <>
    <div className='event_header_container'>
        <span className='calender_icon_container'><CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#34b5e5' }} /><p>Causes</p></span>
        { godMod && 
                <div><button className='event-submit-btn' style={{width:'150px'}} onClick={()=>{navigate('/admin/cause/restore-cause')}}>Restore cause</button></div>
            }
    </div>
    <hr />
    {causes?.length > 0 ? (
        <Paper>
            <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell sx={{width: "40%"}}>Legal Name</StyledTableCell>
                        <StyledTableCell>Organization Name</StyledTableCell>
                        <StyledTableCell>Email</StyledTableCell>
                        <StyledTableCell>Manage</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {causes?.map((row, i) => (
                    <StyledTableRow key={row.id}>
                        <StyledTableCell>{row.legal_name}</StyledTableCell>
                        <StyledTableCell>{row.org_name}</StyledTableCell>
                        <StyledTableCell>{row.email}</StyledTableCell>
                        <StyledTableCell sx={{display: "flex", alignItems: "center", gap: '14px'}}>
                            {/* <div onClick={() => handleEdit(row)} className='table-btn'> <BorderColorIcon sx={{fontSize: "16px", color: "#5EABD6"}} /> <span style={{color: "#5EABD6", fontWeight: "600"}}>Edit</span></div> */}
                            <TableButton onClick={() => handleEdit(row)} Icon={BorderColorIcon} />
                            {godMod && <TableButton onClick={() => handleCauseDelete(row)} Icon={DeleteOutlineOutlinedIcon} text='Archive'/>}
                        </StyledTableCell>
                    </StyledTableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
            {/* <TablePagination
                rowsPerPageOptions={[10, 20]}
                component="div"
                count={totalEvents}
                rowsPerPage={limit}
                page={page - 1}
                onPageChange={(e, newPage) => setPage(newPage + 1)}
                onRowsPerPageChange={(e) => {setLimit(+e.target.value);setPage(1)}}
            /> */}
        </Paper>
    ) : <p>No causes to show</p>}
    </>}
    <Alert message={constants.ADMIN_COPY_CAUSE_DELETE_CONFITM} isOpen={openConfirmAlert} type="confirm" onClose={handleDeleteAlertClose} onConfirm={handleDeleteCauseConfirm} />
       <Alert isOpen={! !alert} message={alert}
                onClose={() => setAlert(null)} />
</Container>
  )
}

export default MyCauses