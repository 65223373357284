import React, { useContext, useState } from 'react'
import Container from '../../../components/HOC/Container/Container'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Input from '../../../components/Input/Input';
import DropDown from '../../../components/DropDown/DropDown';
import { FormControl, FormControlLabel, FormLabel, InputAdornment, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material';
import Modal from '../../../components/Modal/Modal';
import TagInput from '../../../components/TagInput/TagInput';
import ImageInput from '../../../components/ImageInput/ImageInput';
import FormTextarea from "../../../components/FormTextarea/FormTextarea"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Alert from '../../../components/Alert/Alert';
import LoadingModal from '../../../components/LoadingModal/LoadingModal';
import DateAlert from '../../../components/Alert/dateConfirmAlert';
import auctionServices from '../../../../services/auction-services';
import checkImageBeforeUpload from '../../../../utils/checkImageBeforeUpload';
import { useEffect } from 'react';
import { saveMarketPlace } from '../../../../redux/savedMarketPlaceReducer';
import {useDispatch, useSelector} from 'react-redux'
import authService from '../../../../services/auth-services';
import { setDefaultEndDate, setDefaultStartDate } from '../../../../utils/util-functions';
import causeServices from '../../../../services/cause-services';
import { saveStore } from '../../../../redux/savedStoreReducer';
import usePrompt from '../../../hooks/usePrompt';
import constants, { COLORS_OBJECT } from '../../../../constants/en';
import AuthContext from "../../../../store/auth-context";
import CustomCategoryPriorityModalComponent, { CustomCategoryHeadingPriorityModalComponent } from '../../Event/CustomCategoryPriorityModal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { validEmail } from '../../../../utils/reg-ex';
import CustomImagesModal from '../../../components/CustomImagesModal/CustomImagesModal';
import RadioGroupComponent from '../../../components/RadioGroup/RadioGroup';
import { clearAttribute, setAttribute } from '../../../../redux/persistReducer';
import usePopState from '../../../hooks/usePopState';
import ClearButton from '../../../components/ClearButtonContainer/ClearButtonContainer';
import { setItemToLocalStorage } from '../../../../utils/autoSave';
import { handleImageChange } from '../../../../utils/image-utils';
import AccessDenied from '../../../components/AccessDenied/AccessDenied';
import VisibilityModal from '../../../components/VisibilityModal/VisibilityModal';

const redColor = COLORS_OBJECT.color[0]
///SM means Store and MarketPlace EG:(SMData)
const CreateForm = ({setStateData,stateData, stringCapitalName, stringLowerName, checkCause=false, forMarket=false, initialStateCopy={}}) => {
    
    const localStorageDataName = `${stringLowerName}Data`;
    const localStorageExtraDataName = `extra${stringCapitalName}State`;

    const localStorageData = JSON.parse(localStorage.getItem(localStorageDataName));
    const localStorageExtraSMData = JSON.parse(localStorage.getItem(localStorageExtraDataName)) || {}

    const persistedData = useSelector(state => state?.persistedData)
    const navigate = useNavigate()
    const {auction_id} = useParams();
    const [showCustom, setShowCustom] = useState( localStorageExtraSMData?.SMCustomCategory?.length ? true : false)
    const [openModal,setOpenModal]=useState(false);
    const [openModal2,setOpenModal2]=useState(false);
    const [saveCustomCategory,setSaveCustomCategory] = useState(false);
    const [customCategoryHeadline, setCustomCategoryHeadline] = useState({ id: '', heading: '', category: [], tempId:null, fixedCategory: [] }); // as per the current requirement we are not using headline we are making it as empty string, and also we are not using this state
    const [customCategories,setCustomCategories]=useState(
       localStorageExtraSMData?.SMCustomCategory?.length ? localStorageExtraSMData?.SMCustomCategory : [{id:'',heading:"", category: [], tempId: null,fixedCategory: [] }]
    );
    const [clearTags,setClearTags]=useState(false);
    const [selectedTags,setSelectedTags]=useState([])
    const [alertMsg, setAlertMsg] = useState(null)

    /// these two are images peristed in redux for store and marketplace
    let persistPortraitImage = persistedData[`${stringLowerName}PortraitImage`]
    let persistLandscapeImage = persistedData[`${stringLowerName}LandscapeImage`]

    const [portraitImage, setPortraitImage] = useState(
      (persistPortraitImage instanceof Blob || (localStorageData?.image1 !== null && typeof localStorageData?.image1 != 'object')) ? localStorageExtraSMData?.SMPortrait :  null
    );
    const [landscapeImages, setLandscapeImages] = useState(
      (persistLandscapeImage instanceof Blob || (localStorageData?.image2 !== null && typeof localStorageData?.image2 != 'object')) ? localStorageExtraSMData?.SMLandscape : []
    );
    const [customCategoryImages, setCustomCategoryImages] = useState({});
    const [customCategoryImagePreview, setCustomCategoryImagePreview] = useState({});
    const [portraitImageLoading, setPortraitImageLoading] = useState(false)
    const [landscapeImageLoading, setLandscapeImageLoading] = useState(false)
    const [browseAuctionImageLoading, setBrowseAuctionImageLoading] = useState(false)
    const [browseBuyNowImageLoading, setBrowseBuyNowImageLoading] = useState(false)
    const [customCategoryImageLoading, setCustomCategoryImageLoading] = useState({})
    const [browseAuctionImage, setBrowseAuctionImage] = useState([])
    const [browseBuyNowImage, setBrowseBuyNowImage] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [videoLoading, setVideoLoading] = useState(false)
    const [videos, setVideos] = useState([]);
    const [userCauses, setUserCauses] = useState([])
    const dispatch = useDispatch()
    const [editAccess, setEditAccess] = useState(true)
    const [navigateAction, setNavigateAction] = useState('')
    const location = useLocation()
    const savedMarketPlace = useSelector(event => event.savedMarketPlace)
    const [selectedCause, setSelectedCause] = useState(null)
    const [showPrompt, setShowPrompt] = useState(false)
    const [selectedDonor, setSelectedDonor] = useState(localStorageExtraSMData?.SMSelectedDonor ? localStorageExtraSMData?.SMSelectedDonor : null)
    const [donorsList, setDonorsList] = useState([])
    const [updateDonors, setUpdateDonors] = useState(true)
    const [localDonor, setLocalDonor] = useState({
      "org_name": "",
      "about": "",
      "email": "",
    })
    const [openDonor, setOpenDonor] = useState(false)
    const [allCustomCategoryAlert,setAllCustomCategoryAlert] = useState(null)
    const [headingPriorityOrderModal, setHeadingPriorityOrderModal] = useState(false)
    const [priorityOrderModal, setPriorityOrderModal] = useState(false)
    const [priorityCategory, setPriorityCategory] = useState({})
    const [customCategoryHeadingId, setcustomCategoryHeadingId] = useState({})
    const [customCategoryByHeadingAlert, setCustomCategoryByHeadingAlert] = useState(null)
    const [customCategoryByHeading, setCustomCategoryByHeading] = useState({})
    // const [navigateAction, setNavigateAction] = useState('')
    const [noCustomCategoryImageAlert, setnoCustomCategoryImageAlert] = useState(false)
    const [deleteCustomCatId,setDeleteCustomCatId] = useState(null)
    const [customCategoryAlert,setCustomCategoryAlert] = useState(null)
    const [customImageModal, setCustomImageModal] = useState(false)
    const [customImages, setCustomImages] = useState({})
    // const [newImagePermission, setNewImagePermission] = useState({normalUser:false, godUser: false})
    const [newImagePermission, setNewImagePermission] = useState(false)
    const [godPermission, setGodPermission] = useState(false)
    
      /// state for checking if user has permission for uploading images for customcategories
     const [customCategoryImagePermission, setCustomCategoryImagePermission] = useState(false)
     
     const [boxColorPicker, setBoxColorPicker] = useState(localStorageData?.need_box == '1' ? true : false)
    
     const [defaultStateChange, setDefaultStateChange] = useState({
      customCategoryChange: localStorageExtraSMData?.SMCustomCategory ? true : false
     })

     const [featuredIconLoading, setFeaturedIconLoading] = useState(false);
     const [featuredImage, setFeaturedImage] = useState([]);

     const [clearCategoryImageState, setClearCategoryImageState] = useState([])

     const [updateVisibilityModal, setUpdateVisibilityModal] = useState({open:false})

     const ctx = useContext(AuthContext)

    usePrompt("Do you want to leave?", showPrompt, localStorageDataName, stateData)
    usePopState(localStorageDataName, stateData, showPrompt)
    useEffect(() => {
      if (!auction_id) return
        checkEditPermission(auction_id)
        fetchMarketPlaceDetails(auction_id)
    }, [])

    useEffect(() => {
      if(auction_id) return;
      let extraSMState = {}
      if(defaultStateChange?.customCategoryChange) {
        extraSMState = {...extraSMState, SMCustomCategory:customCategories}
      }
      if(portraitImage !== null && portraitImage !== undefined && !portraitImage[0]?.name?.startsWith('data:image')) {
        extraSMState = {...extraSMState, SMPortrait:portraitImage}
      }
      if(landscapeImages?.length && !landscapeImages[0]?.name?.startsWith('data:image')) {
        extraSMState = {...extraSMState, SMLandscape:landscapeImages}
      }
      if(selectedDonor) {
        extraSMState = {...extraSMState, SMSelectedDonor: selectedDonor}
      }
      if(Object.keys(extraSMState)?.length) {
        // localStorage.setItem(localStorageExtraDataName, JSON.stringify(extraSMState))
        setItemToLocalStorage(localStorageExtraDataName, extraSMState)
      }
    },[customCategories, portraitImage, landscapeImages, selectedDonor])

    useEffect(() => {
      if (savedMarketPlace?.event && !auction_id) {
        setStateData(savedMarketPlace?.event)
        setPortraitImage(savedMarketPlace?.portraitImage || null)
        setLandscapeImages(savedMarketPlace?.landscapeImage)
        setBrowseAuctionImage(savedMarketPlace?.browseAuctionImage)
        setBrowseBuyNowImage(savedMarketPlace?.browseBuyNowImage)
        setVideos(savedMarketPlace?.vidoes)
      }
    }, [])

    const checkEditPermission = async(auction_id) => {
      try {
        const permission = await authService.checkPermissions(4, auction_id)
        setIsLoading(false);
        if (!permission.access) {
          setEditAccess(false)
          setIsLoading(false)
          return
        } else {
          setEditAccess(true)
        }
      }catch(error) {
        setEditAccess(false)
        setIsLoading(false)
      }
    }

    const fetchMarketPlaceDetails = async(auction_id) => {
       try {
            setIsLoading(true)
            const response = await auctionServices.getAuctionDetailsById(auction_id);
            setIsLoading(false);
            fetchCustomCategories()
            setStateData({
              ...response?.data,
              custom_categories: []
            })
            if (response?.data?.image2) {
              setPortraitImage([{ name: response?.data?.image1+"_small", type: 'img' }])
              setLandscapeImages([{ name: response?.data?.image2+"_small", type: 'img' }])
            } else {
              setPortraitImage([{ name: response?.data?.image1+"_small", type: 'img' }])
            }
            if(response.data?.auction_image_key){
              setBrowseAuctionImage([{name:response.data?.auction_image_key, type:'img'}])
            }
            if(response.data?.buy_now_image_key){
              setBrowseBuyNowImage([{name:response.data?.buy_now_image_key, type:'img'}])
            }
            if (response?.data?.video) setVideos([{name:response?.data?.video}]);
            if(response?.data?.donor) {
              if(response?.data?.donor?.email) setSelectedDonor({label: `${response?.data?.donor.contact_firstname} ${response?.data?.donor.contact_lastname} - ${response?.data?.donor.org_name}`,value: response?.data?.donor.id,})   
              else setSelectedDonor({label: response?.data?.donor.org_name, value: response?.data?.donor.id})
            }
            if(response?.data?.need_box === '1') {
              setBoxColorPicker(true)
            }
            else setBoxColorPicker(false)
            if(response?.data?.featured_image) {
              setFeaturedImage([{name:response.data?.featured_image, type:'img'}])
            }
            setIsLoading(false);
       } catch(error) {
        setIsLoading(false);
        navigate("/admin/market-place")
       }
    }

    const fetchCustomCategories = async()=>{
      try{
        const custom_categories = await auctionServices.fetchcustomCategoryHeadline(auction_id)
        let newCustomList = []
        let customCategotyImagePreviewVar={}
        custom_categories?.data?.map((c, i) => {
          let categories = c?.auction_custom_categories?.map((category) =>{
            let tempId = `${Math.random()}${new Date().getTime()}`
            customCategotyImagePreviewVar[tempId] = category?.image_key
           return {id:category?.id,name:category?.category_name,image_key:category?.image_key,tempId, show_everywhere:category?.show_everywhere}
          })
          let heading = c.name
          let id = c.id
          newCustomList.push({ id, heading, fixedCategory: categories, category: [] ,  })
          return null
        })
        setCustomCategoryImagePreview(customCategotyImagePreviewVar)
        setCustomCategories(newCustomList)
      }catch(error) {}
    }

    useEffect(() => {
      if (!(customCategories[0]?.id==='' || !customCategories?.length)) {
        setShowCustom(true)
      }
    }, [customCategories])

    useEffect(() => {
      userCauses.forEach((cause) => {
        if (cause.value.id === stateData.cause_id && cause.value.category_id === stateData.cause_category_id) {
          setSelectedCause(cause)
        }
      })
    }, [stateData.cause_id, stateData.cause_category_id, userCauses])

    useEffect(() => {
      (async () => {
        const data = await causeServices.getUserCauses(checkCause)
        const newCauses = data?.data?.map(c => ({ value: { id: c.cause.id, category_id: c.cause.cause_category_id, label: c.cause.legal_name }, label: c.cause.legal_name }))
        setUserCauses(newCauses)
      })()
    }, [])
  
    const handleCauseChange = (e) => {
      setShowPrompt(true)
      setStateData({
        ...stateData,
        cause_id: e.value.id,
        cause_category_id: e.value.category_id
      })
      setSelectedCause(e)
    }

      const handleDataChange = (e) => {
        if(e.target.name === 'custom_url'){
          if(e.target.value){
            e.target.value = e.target.value.replace(/ /g,'-')
          }
        }
        
        if(e.target.name.includes('/title')){
          e.target.name='title';
        }

        setShowPrompt(true)
        setStateData({
          ...stateData,
          [e.target.name]: e.target.value
        })
      }

    const handleHeadlineChange = (e) => {
        setCustomCategoryHeadline({ ...customCategoryHeadline, heading: e.target.value })
        setClearTags(false)
    
      }
    
      const handleCategoryChange = (newCategories) => {
        setCustomCategoryHeadline({ ...customCategoryHeadline, category: newCategories, tempId: `${Math.random()}${new Date().getTime()}` })
        setSelectedTags(newCategories)
        setShowPrompt(true)
      }

      const handleNewCategoryChange = (newCategories) =>{
        setShowPrompt(true)
        setDefaultStateChange({...defaultStateChange, customCategoryChange:true})
        setCustomCategories([{...customCategories[0],id:'',heading:"", category: newCategories, tempId: `${Math.random()}${new Date().getTime()}` }])
      }

      const handleCustomImageModal = () => {
        if(godPermission) {
          setOpenModal2(true)
        }
      }

    const addNewCustomCategory =async () => {
      // the below comment made as per current requirement
        // if (customCategoryHeadline.heading.trim()  !== '' && customCategoryHeadline.category.length) {
        //   setCustomCategories([customCategoryHeadline, ...customCategories])
        //   setSelectedTags([])
        //   setClearTags(true)
        //   setCustomCategoryHeadline({ id: '', heading: '', category: [], tempId: '' })
        // }

        // the below one is made for current requirement
        if(auction_id){
          const formData = new FormData()
          formData.append("customCategories",JSON.stringify(customCategories))
  
         for(let tempId in customCategoryImages){
          formData.append("customCategoryImages",customCategoryImages[tempId],`${tempId}`)
         }
  
        await auctionServices.addCustomCategories(auction_id,formData)
        setCustomCategoryImages({})
        fetchCustomCategories()
        }
      else{
        stateData.custom_categories= customCategories
        stateData.custom_category_images= customCategoryImages 
      }
  }


      const handlePortraitImage = async (e) => {
        try{
        if (!e.target.files[0]) return
        setShowPrompt(true)
        setPortraitImageLoading(true)
        const image = e.target.files[0]
        // if (image.size > 5000000) {
        //   setAlertMsg(constants.ADMIN_COPY_EVENT_IMG_FILE_SIZE)
        //   setShowPrompt(false)
        //   setPortraitImageLoading(false)
        //   return
        // }
        const imageUrl = URL.createObjectURL(image)
        const { width, height } = await checkImageBeforeUpload(imageUrl)
        // if (width < 375 || height < 450) {
        //   setAlertMsg(constants.ADMIN_COPY_EVENT_IMG_SIZE)
        //   setShowPrompt(false)
        //   setPortraitImageLoading(false)
        //   return
        // }
        const formData = new FormData()
        formData.append("image", image)
        formData.append("width", 1125)
        formData.append("height", 1350)
        const preProcessedImage = await auctionServices.imagePreProcess(formData)
        setPortraitImage([{ name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img' }])
        const uint8 = new Uint8Array(preProcessedImage.buffer.data)
        const newBlob = new Blob([uint8], { type: "image/jpeg" })
        setStateData({
          ...stateData,
          'image1': newBlob
        })
        setPortraitImageLoading(false)
        // setShowPrompt(false)
        // dispatch(setAttribute({key: `${stringLowerName}PortraitImage`, value: newBlob}))
        return `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`
        }
        catch(error){
        setPortraitImageLoading(false)
        setShowPrompt(false)
        }
      }

      const handleLandscapeImage = async (e) => {
        try{
         if (!e.target.files[0]) return
         setShowPrompt(true)
         setLandscapeImageLoading(true)
         const image = e.target.files[0]
        //  if (image.size > 5000000) {
        //    setAlertMsg(constants.ADMIN_COPY_EVENT_IMG_FILE_SIZE)
        //    setLandscapeImageLoading(false)
        //    setShowPrompt(false)
        //    return
        //  }
         const imageUrl = URL.createObjectURL(image)
         const { width, height } = await checkImageBeforeUpload(imageUrl)
        //  if (width < 636 || height < 318) {
        //    setAlertMsg(constants.ADMIN_COPY_EVENT_LANDSCAPE_IMG_SIZE)
        //    setLandscapeImageLoading(false)
        //    setShowPrompt(false)
        //    return
        //  }
         let preprocessWidth = 1908;
         let preprocessHeight = 954;
         if(stringLowerName === 'marketplace') {
          preprocessWidth = 1908;
          preprocessHeight = 532;
         }
         const formData = new FormData()
         formData.append("image", image)
         formData.append("width", preprocessWidth)
         formData.append("height", preprocessHeight)
         const preProcessedImage = await auctionServices.imagePreProcess(formData)
         setLandscapeImages([{ name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img' }])
         const uint8 = new Uint8Array(preProcessedImage.buffer.data)
         const newBlob = new Blob([uint8], { type: "image/jpeg" })
         setStateData({
           ...stateData,
           'image2': newBlob
         })
         setLandscapeImageLoading(false)
        //  setShowPrompt(false)
        // dispatch(setAttribute({key: `${stringLowerName}LandscapeImage`, value: newBlob}))
         return `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`
        }
        catch(error){
         setLandscapeImageLoading(false)
        }
       }
     
       const handleBrowseAuctionItemImage = async (e) => {
         try{
           if(!e.target.files[0]) return
         setShowPrompt(true)
         setBrowseAuctionImageLoading(true)
         const auctionBrowseImage = e.target.files[0]
         if(auctionBrowseImage.size > 5000000){
           setIsLoading(false)
           setAlertMsg(constants.ADMIN_COPY_EVENT_IMG_FILE_SIZE)
           setBrowseAuctionImageLoading(false)
           setShowPrompt(false)
           return
         }
         const auctionBrowseImageUrl = URL.createObjectURL(auctionBrowseImage)
         const {width, height} = await checkImageBeforeUpload(auctionBrowseImageUrl)
         if(width < 300 || height < 200) {
           setIsLoading(false)
           setBrowseAuctionImageLoading(false)
           setShowPrompt(false)
           setAlertMsg(constants.ADMIN_COPY_EVENT_BROWSE_IMG_SIZE)
           return
         }
         const formData = new FormData()
         formData.append("image", auctionBrowseImage)
         formData.append("width", 300)
         formData.append("height", 200)
         const preProcessedImage = await auctionServices.imagePreProcess(formData)
         setBrowseAuctionImage([{name:  `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img'}])
         const uint8 = new Uint8Array(preProcessedImage.buffer.data)
         const newBlob = new Blob([uint8], { type: "image/jpeg" })
         setStateData({
           ...stateData,
           'auction_image_key': newBlob
         })
         setBrowseAuctionImageLoading(false)
         setShowPrompt(false)
         return `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`
         }
         catch(error){
           setBrowseAuctionImageLoading(false)
           setShowPrompt(false)
         }
       }
     
       const handleBrowseBuyNowItemImage = async (e) => {
         try{
           if(!e.target.files[0]) return
         setShowPrompt(true)
         setBrowseBuyNowImageLoading(true)
         const buyNowBrowseImage = e.target.files[0]
         if(buyNowBrowseImage.size > 5000000){
           setIsLoading(false)
           setAlertMsg(constants.ADMIN_COPY_EVENT_IMG_FILE_SIZE)
           setBrowseBuyNowImageLoading(false)
           setShowPrompt(false)
           return
         }
         const buyNowBrowseImageUrl = URL.createObjectURL(buyNowBrowseImage)
         const {width, height} = await checkImageBeforeUpload(buyNowBrowseImageUrl)
         if(width < 300 || height < 200) {
           setIsLoading(false)
           setAlertMsg(constants.ADMIN_COPY_EVENT_BROWSE_IMG_SIZE)
           setBrowseBuyNowImageLoading(false)
           setShowPrompt(false)
           return
         }
         const formData = new FormData()
         formData.append("image", buyNowBrowseImage)
         formData.append("width", 300)
         formData.append("height", 200)
         const preProcessedImage = await auctionServices.imagePreProcess(formData)
         setBrowseBuyNowImage([{name:  `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img'}])
         const uint8 = new Uint8Array(preProcessedImage.buffer.data)
         const newBlob = new Blob([uint8], { type: "image/jpeg" })
         setStateData({
           ...stateData,
           'buy_now_image_key': newBlob
         })
         setBrowseBuyNowImageLoading(false)
         setShowPrompt(false)
         return `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`
         }
         catch(error){
         setBrowseBuyNowImageLoading(false)
         setShowPrompt(false)
         }
       }

       const handleMultipleVideos = (e) => {
        if (e.target.files.length === 0) return
        setShowPrompt(true)
        setVideoLoading(true)
        const filesUrl = []
        const targetFilesObj = [...e.target.files]
        if (targetFilesObj.length > 2 || landscapeImages.length === 2 || (landscapeImages.length + targetFilesObj.length) > 2) {
          setVideoLoading(false)
          setShowPrompt(false)
          alert('You can upload maximum 2 videos');
          return
        }
        if (targetFilesObj.every((currentValue) => currentValue.size <= 20000000)) {
          targetFilesObj.map((file) => {
            return filesUrl.push({ name: URL.createObjectURL(file), type: 'video', local: true });
          });
          setVideos([...videos, ...filesUrl])
          if (targetFilesObj.length > 2) {
            setStateData({
              ...stateData,
              video: targetFilesObj[0],
              video1: targetFilesObj[1]
            })
          } else {
            if (!stateData.video) {
              setStateData({
                ...stateData,
                video: targetFilesObj[0],
              })
            } else {
              setStateData({
                ...stateData,
                video1: targetFilesObj[0],
              })
            }
          }
          setVideoLoading(false)
          setShowPrompt(false)
        } else {
          setVideoLoading(false)
          setShowPrompt(false)
          alert("File size should be less than or equal to 20MB");
          return
        }
      };


       const handleCustomCategoryImage = async (e,tempId) => {
        try{
         if (!e.target.files[0]) return
         setShowPrompt(true)
         setCustomCategoryImageLoading({...customCategoryImageLoading,[tempId]:true})
         const image = e.target.files[0]
         if (image.size > 5000000) {
           setShowPrompt(false)
           setCustomCategoryImageLoading({...customCategoryImageLoading,[tempId]:false})
           alert(constants.ADMIN_COPY_EVENT_IMG_FILE_SIZE);
           return
         }
         const imageUrl = URL.createObjectURL(image)
         const { width, height } = await checkImageBeforeUpload(imageUrl)
         if (width < 375 || height < 450) {
           setShowPrompt(false)
           setCustomCategoryImageLoading({...customCategoryImageLoading,[tempId]:false})
           alert(constants.ADMIN_COPY_CUSTOM_CATEGORY_IMAGE_DIMENSIONS)
           return
         }
         const formData = new FormData()
         formData.append("image", image)
         formData.append("width", 375)
         formData.append("height", 450)
         const preProcessedImage = await auctionServices.imagePreProcess(formData)
         setCustomCategoryImagePreview({ ...customCategoryImagePreview,[tempId]: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`})
         const uint8 = new Uint8Array(preProcessedImage.buffer.data)
         const newBlob = new Blob([uint8], { type: "image/jpeg" })
         setCustomCategoryImages({...customCategoryImages,[tempId]:newBlob})
         setCustomCategoryImageLoading({...customCategoryImageLoading,[tempId]:false})
         setShowPrompt(false)
        }
        catch(error){
         setCustomCategoryImageLoading({...customCategoryImageLoading,[tempId]:false})
         setShowPrompt(false)
        }
      
     }
      const deletePortrait = () => {
        setShowPrompt(true)
        setPortraitImage(null)
        setStateData({
          ...stateData,
          image1: null
        })
      }
    
      const deleteLandscape = () => {
        setShowPrompt(true)
        setLandscapeImages([])
        setStateData({
          ...stateData,
          image2: null
        })
      }
    
      const delteAuctionBrowseImage = () => {
        setBrowseAuctionImage([])
        setStateData({
          ...stateData,
          auction_image_key: null
        })
      }
    
      const delteBuyNowBrowseImage = () => {
        setBrowseBuyNowImage([])
        setStateData({
          ...stateData,
          buy_now_image_key:null
        })
      }
  
      const deleteCustomCategoryImage = (tempId, headingId, categoryId) => {
        setCustomCategoryImagePreview({...customCategoryImagePreview,[tempId]:null})
        // to delete image from backend
        if(headingId && categoryId) {
          setClearCategoryImageState([...clearCategoryImageState, categoryId])
        }
      }

      const deleteVideo = (url, imageIdx) => {
        setVideos(videos.filter(v => v.name !== url))
        if (imageIdx === 0) {
          setStateData({
            ...stateData,
            video: null
          })
        } else {
          setStateData({
            ...stateData,
            video1: null
          })
        }
      }

      // useEffect(()=>{
      //   // this method ( calling saveCustomCategoryChange() in useEffect with saveCustomCategory dipendancy) 
      //   // help us to get a delay to save custom categoryHeadline to customCategory array.
      //   saveCustomCategoryChange();
      // },[saveCustomCategory])

      const openCustomCategoryImageUpload = async() => {
        addNewCustomCategory()
        // if(customCategoryImagePermission) {
        //   setOpenModal(false)
        //   setOpenModal2(true)
        // }
        // else {
         setSaveCustomCategory(!saveCustomCategory)
          setOpenModal(false)
        // }
      }

      const handleHeadlineEdit = (tempId, id, e) => {

        const edited = customCategories.map((c) => {
          if (c.tempId === tempId && c.tempId !== '' && c.tempId) {
    
            return { ...c, heading: e.target.value }
          } else if (c.id === id && c.id !== '' && c.id) {
    
            return { ...c, heading: e.target.value }
          }
          else {
            return c
          }
    
        })
        setCustomCategories(edited)
    
      }
      const handleCategoryEdit = (tempId, id, newCategories) => {
        const edited = customCategories.map((c) => {
          if (c.tempId === tempId && c.tempId !== '' && c.tempId ) {
    
            return { ...c, category: newCategories }
          } else if (c.id === id && c.id !== '' && c.id) {
    
            return { ...c, category: newCategories }
          }
          else {
            return c
          }
    
        })
        setCustomCategories(edited)
      }

      useEffect(() => {
        setStateData({
          ...stateData,
          custom_categories: customCategories
        })
      }, [customCategories])
    
      //validating all custom category have curresponding images
      const validateCustomCategoryImages = ()=>{
        let flag = true
        customCategories.map(heading=>{
          heading.category.map(category=>{
            if(!customCategoryImages[category.tempId]){
              flag = false
            }
            return null
          })
          return null
        })
        return flag
    
    
      }

      const saveCustomCategoryChange = async() => {
        setIsLoading(true)
       try {
         // if(validateCustomCategoryImages()){
    
         if (customCategories && Object.keys(customCategories).length ) {
          if(auction_id){
  
            const formData = new FormData()
            formData.append("customCategories",JSON.stringify(customCategories))
    
           for(let tempId in customCategoryImages){
            formData.append("customCategoryImages",customCategoryImages[tempId],`${tempId}`)
           }
    
           if(clearCategoryImageState?.length) {
            formData.append("clear_image_category_ids", JSON.stringify(clearCategoryImageState))
           }
          await auctionServices.addCustomCategories(auction_id,formData)
          setCustomCategoryImages({})
          setClearCategoryImageState([])
          fetchCustomCategories()
            // setCustomCategoryImagePreview({})
  
          }else{
          
            setStateData({
              ...stateData,
              custom_categories: customCategories,
              custom_category_images:customCategoryImages
            })
          }
        }
        setOpenModal2(false)
        setIsLoading(false)
        fetchCustomCategories()
      // }else{
      //   setIsLoading(false)
      //   alert('Image for all custom categories are required')
      // }
       } catch(error) {
        setIsLoading(false)
        setOpenModal2(false)
       }
        
      }

    
      

      const handleFormSubmit = async(e) => {
        e.preventDefault();
        setIsLoading(true)
        // setShowPrompt(false)
        // since we donot have save and continue btn for custom category Iam calling this function here as per current requirement
       await addNewCustomCategory()
       setShowPrompt(false)
           if(stateData.title){
            stateData.title = stateData.title?.replace(/\s+/g, " ")?.trim();
            if(stateData.title.length < 2 || stateData.title.length > 60 ){
             setIsLoading(false)
             return setAlertMsg(`${stringCapitalName} ${constants.ADMIN_COPY_TITLE_VALIDATION}`);
            }
          }
          if(stateData.custom_url){
            const regex = /^[a-zA-Z0-9-]+$/;
            if (stateData.custom_url.length < 3 || stateData.custom_url.length > 50) {
            setIsLoading(false)
            setAlertMsg("Custom URL must be minimum 3 and maximum 30 characters.")
            return;
        }
          if (!stateData.custom_url.match(regex)){
              setIsLoading(false)
              setAlertMsg("Please use only alphanumeric characters or hyphens (-) in the custom URL. Avoid using white spaces, other symbols or special characters.")
              return;
        }     
    }
    // if(!stateData.image1 || !stateData.image2) {
    if(!stateData.image1 || (stateData?.image1 instanceof Blob === false && typeof stateData?.image1 === 'object') || !stateData.image2 || (stateData?.image2 instanceof Blob === false && typeof stateData?.image2 === 'object')) {
      setIsLoading(false)
      setAlertMsg(constants.ADMIN_COPY_EVENT_NO_IMG.replace('Event', stringCapitalName))
      return;
   }
   if(!stateData?.cause_id || stateData?.cause_id === '') {
    setIsLoading(false)
    setAlertMsg(constants.ADMIN_COPY_CAUSE_ID_REQUIRED)
    return
   }
   if(!auction_id) {
     setShowPrompt(false)
     let id = null;
     let response;
     if(stateData?.custom_url) {
      /// for checking custom url if it exists
       id = stateData?.custom_url;
       response = await auctionServices.checkCustomUrl(id)
       if( response?.message && response.message !== 'Ready to create event'){
         setIsLoading(false)
         setAlertMsg(response.message)
         return;
        }
      }
      
      if(checkCause){
        id = stateData?.cause_id;
        let forCharity = "store"
        response = await auctionServices.checkCustomUrl(id, forCharity)
        if( response?.message && response.message !== 'Ready to create event'){
          setIsLoading(false)
          setAlertMsg(response.message)
          return;
        } 
      }
      if(forMarket) {
        if(!stateData?.donor_id) {
          setIsLoading(false)
          setAlertMsg(constants.ADMIN_COPY_ITEM_DONOR)
          return;
        }
        /// for checking if the donor already have a marketplace or not.Only for market place
         id = stateData?.donor_id;
         let forCharity = "marketplace"
         response = await auctionServices.checkCustomUrl(id, forCharity)
        if( response?.message && response.message !== 'Ready to create event'){
          setIsLoading(false)
          setAlertMsg(response.message)
          return;
        } 
      }
    setShowPrompt(false)
    if(location.pathname.includes('/market-place')){
      dispatch(saveMarketPlace({
        marketPlace: stateData,
        portraitImage: portraitImage,
        landscapeImage: landscapeImages,
        browseAuctionImage:browseAuctionImage,
        browseBuyNowImage:browseBuyNowImage,
        featuredImage:featuredImage
      }))
      setIsLoading(false)
      // localStorage.setItem(localStorageDataName, JSON.stringify(stateData))/// saving the data in localstorage for persisting on come back.
      setItemToLocalStorage(localStorageDataName, stateData)
      navigate("/admin/market-place/terms")
    }
    else {
      setShowPrompt(false)
      dispatch(saveStore({
        store: stateData,
        portraitImage: portraitImage,
        landscapeImage: landscapeImages,
        browseAuctionImage:browseAuctionImage,
        browseBuyNowImage:browseBuyNowImage,
        videos: videos,
        featuredImage:featuredImage
      }))
      setIsLoading(false)
      // localStorage.setItem(localStorageDataName, JSON.stringify(stateData))/// saving the data in localstorage for persisting on come back.
      setItemToLocalStorage(localStorageDataName, stateData)
      navigate("/admin/store/terms")
    }
      }
    else {
        try {
        setShowPrompt(false)
        let updateFormData = new FormData()
        stateData?.custom_url &&
           updateFormData.append('custom_url', stateData?.custom_url.replace(/ /g, "-"));
        stateData?.title &&
           updateFormData.append('title', stateData?.title.replace(/\s+/g, " ").trim());
        stateData?.cause_id &&
           updateFormData.append('cause_id', stateData?.cause_id);
        stateData?.cause_category_id &&
           updateFormData.append('cause_category_id',stateData?.cause_category_id);
        stateData?.start_date &&
           updateFormData.append('start_date',setDefaultStartDate(stateData?.start_date) );
        stateData?.end_date &&
           updateFormData.append('end_date',setDefaultEndDate(stateData?.end_date));
        stateData?.currency &&
           updateFormData.append('currency', stateData?.currency);
           updateFormData.append('shipping_info', stateData?.shipping_info);
           updateFormData.append('terms_info', stateData?.terms_info);
        stateData?.about &&
           updateFormData.append('about', stateData?.about);
        stateData?.image1 &&
           updateFormData.append('imageData0', stateData?.image1);
        stateData?.image2 &&
           updateFormData.append('imageData1', stateData?.image2);
        stateData?.video &&
           updateFormData.append('video', stateData?.video);
        stateData?.auction_image_key && 
           updateFormData.append('auction_image_key_updating', stateData?.auction_image_key);
        stateData?.buy_now_image_key && 
           updateFormData.append('buy_now_image_key_updating', stateData?.buy_now_image_key);
        forMarket && 
          updateFormData.append('forCharity', "forMarketPlace");
        checkCause &&
             updateFormData.append('forCharity', "charity_store");
        updateFormData.append('need_box', boxColorPicker ? '1' : '0');
        stateData.box_color && updateFormData.append("box_color", stateData.box_color );
        stateData.text_color && updateFormData.append("text_color", stateData.text_color );
        updateFormData.append("show_details_over_banner", stateData.show_details_over_banner ? 1 : 0)
        stateData?.featured_image && 
           updateFormData.append('featured_image', stateData?.featured_image)
        let imageLocation = 'landscape'
        if(stringLowerName === 'marketplace') {
          imageLocation = 'market_landscape'
        }
        updateFormData.append('image_location', imageLocation)
        await auctionServices.updateAuction(auction_id, updateFormData)
        setIsLoading(false)
        clearUnsavedChanges()
        if(navigateAction === "terms"){
          if(location.pathname.includes('/market-place'))  navigate(`/admin/market-place/terms/edit/${auction_id}`, {state:{capitalName:"Marketplace", lowerName:"marketplace"}})
          else  navigate(`/admin/store/terms/edit/${auction_id}`, {state:{capitalName:"Store", lowerName:"store"}})
        }else {
          setAlertMsg("Event data updated successfully")
        }
      } catch(error){
        setIsLoading(false)
        if(error.response.data.message === 'This Custom URL already exists. Please try a different one'){
          setAlertMsg("Custom URL already taken. Please choose another.")
        }
        setShowPrompt(true)
      }
    }

      }

      const handleDonorChange = (e) => {
        setShowPrompt(true)
        setStateData({ ...stateData, donor_id: e.value })
        setSelectedDonor(e)
      }

      async function addDonors() {
        try {
          const user_id = ctx?.user?.id;
          const data = await auctionServices.getdonorsListbyAuctionId(user_id, forMarket)
          setDonorsList(data.donors.map((i) => {
            if(i.zip) { //local donors firstName lastName and orgName are same .
              // so we are not returning label in the below format(firstname lastnaem - orgName) .
              // uding zip to distingush between loacl donor and gloabal donor (donor created by god user from manage donors).
              return {
                label: `${i.contact_firstname} ${i.contact_lastname} - ${i.org_name}`,
                value: i.id,
              }
            }
            return {
              label: i.org_name, value: i.id
            }
          }));
        } catch (err) {
          console.log(err)
        }
      }

      useEffect(() => {
        if(!forMarket) return;
        addDonors()
      }, [updateDonors])

      // useEffect(() => {
      //   if(!forMarket) return;
      //   donorsList.forEach(i => {
      //     if(i.value === stateData.donor_id)
      //     setSelectedDonor(i)
      // })
      //   }, [stateData,donorsList])

      const handleLocalDonor = async () => {
        try {
          setIsLoading(true)
          if(localDonor.org_name?.length < 1 || localDonor.about?.length < 1 || localDonor?.email?.length < 1){
            setIsLoading(false)
            // setFormError("Please fill all fields")
            alert(constants.ADMIN_COPY_ITEM_LOCAL_DONOR_FIELD )
            return
          }
          if(localDonor.org_name?.trim()?.length < 2) {
            setIsLoading(false)
            alert(constants.ADMIN_COPY_ITEM_LOCAL_DONOR_ORG_NAME )
            return
          }
          if(localDonor.about?.trim()?.length < 2) {
            setIsLoading(false)
            alert(constants.ADMIN_COPY_ITEM_LOCAL_DONOR_ABOUT)
            return
          }
          if(!validEmail.test(localDonor?.email)){
            setIsLoading(false)
            alert(constants.ADMIN_COPY_DONOR_EMAIL_INVALID )
            return
          }
          const data = await auctionServices.createLocalDonor({org_name: localDonor.org_name, about: localDonor.about,email:localDonor.email,forMarket})
          setUpdateDonors(!updateDonors)
          setLocalDonor({
            org_name: "",
            about: "",
            email: "",
          })
          setSelectedDonor({
            label: data.org_name,
            id: data.id
          })
          setStateData({
            ...stateData,
            donor_id: data.id
          })
          setIsLoading(false)
          setOpenDonor(false)
        } catch (error) {
          setIsLoading(false)
          if(error?.response?.data?.data?.length) {
            setAlertMsg(error?.response?.data?.data[0].msg || constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
          }
          else setAlertMsg(error.response?.data?.message || constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
          setOpenDonor(false)
          setLocalDonor({
            org_name: "",
            about: "",
            email: ""
          })
        }
      }

      const deleteCustomCategoryAlert = (id) => {
        // console.log(id)
        setDeleteCustomCatId(id)
        setCustomCategoryAlert(constants.ADMIN_COPY_CUSTOM_CATEGORY_DELETE_MSG)
      }
    
      const handleDeleteCustomCategory = async() => {
        setCustomCategoryAlert(null)
        setIsLoading(true)
        try{
          const response = await auctionServices.deleteCustomCategoryById(deleteCustomCatId,auction_id)
          if(response){
            setAlertMsg(response?.message)
            fetchCustomCategories()
          }
          setIsLoading(false)
        }catch(error){
          setIsLoading(false)
          setAlertMsg(error.response.data.message)
        } 
      }

      const handleDeleteAllCustomCatsModal = () => {
        if(!auction_id){
          setAllCustomCategoryAlert(constants.ADMIN_COPY_ALL_CUSTOM_CATEGORY_CLEAR_STATE_MSG)
        }
        else {
          setAllCustomCategoryAlert(constants.ADMIN_COPY_CUSTOM_CATEGORY_DELETE_MSG)
        }
      }
    
      const handleDeleteAllCustomCats = async() => {
        setAllCustomCategoryAlert(null)
        if(!auction_id){
          setCustomCategories([])
          setShowCustom(false)
        }
        else {
            try{
              setIsLoading(true)
              const response = await auctionServices.deleteCustomCategoryByAuctionId(auction_id)
              if(response){
                setAlertMsg(response?.message)
                setCustomCategories([])
                setShowCustom(false)
              }
              setIsLoading(false)
            }catch(error){
              setIsLoading(false)
              setAlertMsg(error.response.data.message)
            }
        }
      }
    

      const updatePriorityOrderModal = (customCatId, heading) => {
        const newCust = customCategories?.filter((item) => item?.id === customCatId);
        setcustomCategoryHeadingId({customCatId: customCatId, heading: heading })
        setPriorityCategory(newCust[0]?.fixedCategory)
        setHeadingPriorityOrderModal(false)
        setPriorityOrderModal(true)
      }

      const savePriorityOrder = () => {
        fetchCustomCategories()
      }

      const goToDisplayOrder = () => {
        setHeadingPriorityOrderModal(true)
      }

      const handleDeleteCustomCategoryByHeading = (customHeading) => {
        setCustomCategoryByHeading(customHeading)
        if(!customHeading?.fixedCategory?.length){
          setCustomCategoryByHeadingAlert(constants.ADMIN_COPY_CUSTOM_CATEGORY_DELETE_MSG_BY_HEADING_STATE_CLEAR)
        }
        else {
          setCustomCategoryByHeadingAlert(constants.ADMIN_COPY_CUSTOM_CATEGORY_DELETE_MSG_BY_HEADING)
        }
      }
  
    const deleteAllCustomCatsByHeading = async() => {
      setCustomCategoryByHeadingAlert(null)
          try{
            if(!customCategoryByHeading?.fixedCategory?.length){
              setCustomCategories(customCategories.filter((item) => item.heading !== customCategoryByHeading.heading))
            }
            else {
            setIsLoading(true)
            const response = await auctionServices.deleteCustomCategoryByHeading(auction_id, customCategoryByHeading)
            if(response){
              setAlertMsg(response?.message)
            }
            fetchCustomCategories()
            setIsLoading(false)
          }
          }catch(error){
            setIsLoading(false)
            setAlertMsg(error.response.data.message)
          }
    }
  
    const handleGoBack = () => {
      setOpenModal2(false)
      setOpenModal(true)
      setUpdateVisibilityModal({open:false})
    }
  
  // useEffect(() => {
  //     let count = 0
  //          customCategories?.map((item) => {      
  //          count = count+item.category.length
  //         } )
  
  //          if(count > Object.keys(customCategoryImages).length) {
  //           setnoCustomCategoryImageAlert(true)
  //          }
  //          else {
  //           setnoCustomCategoryImageAlert(false)
  //          }
  //          count = 0;
  // // setnoCustomCategoryImageAlert(flag?true : false)  
  //   },[customCategories, customCategoryImages])

    const handleCustomImageSelect = async(type) => {
      let response
      try {
        setIsLoading(true)
         if(forMarket) {
          /// call for custom market images
          response = await auctionServices.getCustomImages(`market_${type}`)
        }
        else {
          /// call for custom store images
         response = await auctionServices.getCustomImages(`store_${type}`)
        }
         setIsLoading(false)
         setCustomImageModal(true)
         setCustomImages({flag:type, images:response?.data})
        } catch (error) {
          setIsLoading(false)
          setCustomImageModal(false)
        } 
      }

      /// check if the user has the permission to upload extra images
      const verifyUserPermission = async () => {
        setIsLoading(true)
        try {
          const imageAccess = await authService.checkLimitedPermissions(19);
          if (imageAccess?.access) setNewImagePermission(true);
          const godAccess = await authService.checkLimitedPermissions(15);
          if (godAccess?.access) setGodPermission( true);
          const customCategoryPermissionCheck = await authService.checkLimitedPermissions(20);
          if(customCategoryPermissionCheck?.access) setCustomCategoryImagePermission(true);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }
      
    useEffect(() => {
      verifyUserPermission()
  }, [])

      const handleSelectedImage = (fileUrl, activeExtraState=false) => {
        setShowPrompt(true)
        let flag;
        if(activeExtraState) {
           const activeImageKey = fileUrl?.imageKey
           flag = fileUrl?.flag;
           if(flag === "portrait") {
            setPortraitImage([{ name: activeImageKey, type: 'img' }])
           }
           if(flag === "landscape") {
            setLandscapeImages([{ name: activeImageKey, type: 'img' }])
           }
           if(flag === "auction_item") {
            setBrowseAuctionImage([{ name: activeImageKey, type: 'img' }])
           }
           if(flag === "buy_now_item") {
            setBrowseBuyNowImage([{ name: activeImageKey, type: 'img' }])
           }
        }
        else {
          const imageKey = fileUrl?.imageKey?.image;
          const dbImage = fileUrl?.imageKey?.db_key;
          flag = fileUrl?.flag;

        if(flag === 'portrait' ) {
          setPortraitImage([{ name: imageKey, type: 'img' }])
          setStateData({
            ...stateData,
            'image1': dbImage
          })
        } 
        if(flag === 'landscape' ) {
          setLandscapeImages([{ name: imageKey, type: 'img' }])
          setStateData({
            ...stateData,
            'image2': dbImage
          })
        }
        if(flag === 'auction_item') {
          setBrowseAuctionImage([{name: imageKey, type:'img'}])
          setStateData({
            ...stateData,
            'auction_image_key': dbImage
          })
        }
        if(flag === 'buy_now_item' ) {
          setBrowseBuyNowImage([{name: imageKey, type:'img'}])
          setStateData({
            ...stateData,
            'buy_now_image_key': dbImage
          })
        }
      }
        setCustomImageModal(false)
      }

      const handleImageState = async(file, location) => {
        let e = {
           target: {
            files: [file]
           }
        }
        let res;
        if(location === 'portrait') {
          res = await handlePortraitImage(e)
          return res
        }
        if(location === 'landscape') {
          res = await handleLandscapeImage(e)
          return res
        }
        if(location === 'auction_item') {
          res = await handleBrowseAuctionItemImage(e)
          return res
        }
        if(location === 'buy_now_item') {
          res = await handleBrowseBuyNowItemImage(e)
          return res
        }
      }

      useEffect(() => {
        if(boxColorPicker) {
          setStateData({
            ...stateData, need_box:'1'
          })
        }
        else {
          setStateData({
            ...stateData, need_box:'0'
          })
        }
      },[boxColorPicker])
  
      const handleNeedBoxColorPickerChange = (value) => {
        setBoxColorPicker(value)
      }
    const eventImageInputLabel=(label,eventType)=>(<p>{label}: {`Showcase your ${eventType} with a background image from our library.`}<span className='asterisk_color' >*</span>{`( If you’d like to add your own custom photo, send an email notifying us at `}<a style={{color:'#060679'}} href="mailto:help@boundlessgiving.com">help@boundlessgiving.com</a>{`. Write “Custom Photo Request” in the subject line.)`}</p>)


    const handleCustomCategoryRadio = (value) => {
      setShowPrompt(true)
      setShowCustom(value)
    }

    const clearUnsavedChanges = () => {
      localStorage.removeItem(`${stringLowerName}Data`)
      localStorage.removeItem(`extra${stringCapitalName}State`)
      if(auction_id) {
        fetchMarketPlaceDetails(auction_id)
      }
      else {
        setStateData(initialStateCopy)
        setPortraitImage(null)
        setLandscapeImages([])
        setCustomCategories([])
        setSelectedDonor(null)
        setShowCustom(false)
        setSelectedCause(null)
        setBoxColorPicker(false)
        setDefaultStateChange({...defaultStateChange, customCategoryChange:false})
        dispatch(clearAttribute({key:persistLandscapeImage}))
        dispatch(clearAttribute({key:persistPortraitImage}))
      }
    }
  
    const getCauseLabel = () => {
      switch (stringLowerName) {
        case 'marketplace':
          return 'Default Cause';
        case 'store':
          return `What Cause Is This ${stringCapitalName} Supporting?`
        default:
          break;
      }
    }

    const handleFeaturedIconChange = async(event) => {
      const {preProcessedImage, newBlob, imgError} = await handleImageChange(setFeaturedIconLoading, event, 942, 280, setAlertMsg, [], [] )
      if(imgError) return;
      setFeaturedImage([
        {
          name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`,
          type: "img",
        }
      ])
      setStateData({
        ...stateData,
        featured_image: newBlob
      })
    }

    const deleteFeaturedImage = () => {
      setFeaturedImage([])
      setStateData({
        ...stateData,
        featured_image: null
      })
    }

    const handleUpdateVisibilityModal = () => {
      addNewCustomCategory()
      setUpdateVisibilityModal({open:!updateVisibilityModal.open})
    }

    const handleUpdateVisiblityModal = async() => {
      if(auction_id) {
        await saveCustomCategoryChange()
      }
      setUpdateVisibilityModal({open:false})
    }

    const onDetailsOverBannerChange = (e) => {
      setShowPrompt(true)
      setStateData({...stateData, show_details_over_banner:e})
    }


    return (
      <Container title={` ${auction_id ? "Update" : "Build"} Your ${stringCapitalName}`} style={{ position: "relative" }}>
        {
          (auction_id && !editAccess) ?
          <AccessDenied title={`${stringCapitalName}`} />:
              <>
            <div className='event_header' >
                <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                    <CalendarMonthOutlinedIcon sx={{ fontSize: "18px", color: "lightblue" }} />
                    <div style={{ fontSize: "14px" }}>{ `Create a New ${stringCapitalName}`}</div>
                </div>
                {
                 (!auction_id && (localStorageData || Object.keys(localStorageExtraSMData)?.length)) ?
                <ClearButton style={{padding:'0'}} onClick={clearUnsavedChanges}/>
                 :null
                }
             </div>
                <hr style={{ margin: "0" }} />
                <>
                    <form id="myForm" onSubmit={handleFormSubmit} className='event-container'>
                        <div>
                            <Input required label={`Give Your ${stringCapitalName} A Title`} value={stateData?.title} onChange={handleDataChange} name={`${(Math.random() + 1).toString(36).substring(7)}/title`} autoComplete='no-fill' />
                          {/* { location.pathname.includes('/store') ?
                           <> */}
                            {auction_id ? <DropDown value={{ label: stateData?.causeDetails?.legal_name, value: stateData?.causeDetails?.id }} isDisabled={true} label="Cause Name" required options={[]} /> 
                            : <DropDown value={selectedCause} onChange={handleCauseChange} label={getCauseLabel()} required options={userCauses} />}
                            {/* </>
                            : null} */}
                       { forMarket
                                ?
                                <div style={{ flex: 3, display: "flex", alignItems: "flex-end", gap: "10px" }}>
                                 <DropDown isDisabled={auction_id} value={selectedDonor} isSearchable onChange={handleDonorChange} options={donorsList} label="Donor" required />
                                 <button hidden={auction_id} onClick={() => setOpenDonor(true)} type='button' className='aitem-modal-btn'>Add Donor</button>
                                </div>
                        : null }
                            <FormTextarea required label={`Tell Us About Your ${stringCapitalName} `} value={stateData?.about} onChange={handleDataChange} placeholder={`Share as many details about your ${stringCapitalName === "Store" ? stringCapitalName : 'product or brand'} that you'd like donors to know. Who it's for, why you are raising money. Is it annual? Whatever you want to share.`}  name="about" maxsize={500} />
                            <div className='event-two-inputs'>

                            </div>

                            <div style={{ margin: "10px 0", width: '100%', display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <label className='input-label'    >Custom URL
                                  <span style={{ color: "red" }}>*</span>  {" "}
                                  <Tooltip title={`If you’d like to share a custom URL unique to your ${stringLowerName}, please enter it here. Your URL will begin with boundlessgiving.com/${stringLowerName}/ and be followed by a combination of letters and numbers (required) of your choosing.You are only required to enter the unique combination of word(s) and number(s) to your ${stringLowerName} below. We’ll take care of the rest. For example: boundlessgiving.com/event/chaparralauction2023`}>
                                        <InfoOutlinedIcon sx={{ color: '#495057c4', fontSize: "16px", marginLeft: "4px" }} />
                                  </Tooltip>
                                  <div>
                                  <span style={{ fontSize: "0.7rem", color: "gray" }}>(Custom URL must be minimum 3 and maximum 50 characters.)
                                  </span>
                                  </div>
                                </label>
                                <TextField
                                    className='MuiTextField-root'
                                    variant='outlined'
                                    onChange={handleDataChange}
                                    value={stateData?.custom_url}
                                    name="custom_url"
                                    id="outlined-start-adornment"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start" sx={{ marginRight: '0px !important' }} >
                                            <p style={{ fontSize: '0.85rem', fontFamily: 'inherit' }} >{`https://boundlessgiving.com/${stringLowerName}/`}</p>
                                        </InputAdornment>,
                                        style: {
                                            borderRadius: '20px',
                                            outline: 'none',
                                            fontSize: '0.85rem',
                                            height: '35px',
                                            width: '100%',
                                            maxWidth: '100%',
                                            fontFamily: 'inherit',
                                            color: 'black',
                                        }
                                    }}
                                />
                            </div>

                            <RadioGroupComponent 
                             checked={stateData.show_details_over_banner}
                             onChange={onDetailsOverBannerChange}
                             label={constants.ADMIN_COPY_EVENT_DETAILS_OVER_BANNER_LABEL?.replaceAll("event", stringLowerName)}
                            />

                            <div>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label"><Typography sx={{ fontSize: '0.85rem', fontWeight: '600', fontFamily: 'AvenirNext, sans-serif', color: 'black' }}>In addition to general item categories, would you like to create any custom categories?</Typography></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio checked={showCustom} sx={{"&.Mui-checked": {color: redColor,}}} />} label="Yes" onChange={() => handleCustomCategoryRadio(true)} />
                                        <FormControlLabel value="false" control={<Radio checked={!showCustom} sx={{"&.Mui-checked": {color: redColor,}}} />} label="No" onChange={() => handleCustomCategoryRadio(false)} />

                                    </RadioGroup>
                                </FormControl>
                            </div>
                            {
                              showCustom && 
                              <>
                              <TagInput
                              fixedTags={(customCategories[0]?.fixedCategory) ?? [] }
                              label="Add Custom Category"
                              name="custom_categories"
                              tags={(customCategories[0]?.category) ?? [] } 
                              handleChange={handleNewCategoryChange}
                              clearTags={clearTags}
                              deleteTags={deleteCustomCategoryAlert}
                              
                          />
                          <div className="marketStoreCategoryActions">
                             {
                               godPermission && 
                               <button type="button" onClick={handleCustomImageModal} className='aitem-modal-btn'>
                                  Upload Custom Category Images
                               </button>
                             }
                             {
                              godPermission && 
                              <button type="button" onClick={handleUpdateVisibilityModal} className='aitem-modal-btn aitem-white-button'>
                                 Update Visibility
                              </button>
                             }
                             <button type="button" onClick={() =>updatePriorityOrderModal(customCategories[0]?.id, customCategories[0]?.heading)} className='aitem-modal-btn aitem-white-button'>
                               Update Display Order
                             </button>
                          </div>  
                          </>
                            }
                            {/* {showCustom && <div style={{ marginTop: "15px" }}>
                                <button type="button" onClick={() => setOpenModal(true)} className='aitem-modal-btn' style={{ fontSize: "0.8rem" }}>
                                    {`Add${customCategories.length ? '/Edit' : ''} Custom Category`}</button>
                            </div>} */}
                            {/* {customCategories.length ? <>
                                <hr />
                                <div style={{display:'flex', justifyContent:"space-between"}}>
                                   <button type="button" onClick={handleDeleteAllCustomCatsModal} className='aitem-modal-btn aitem-white-button' style={{ fontSize: "0.8rem" }}>
                                       Delete All Custom Category</button>
                                    <button type="button" onClick={goToDisplayOrder} className='aitem-modal-btn' style={{ fontSize: "0.8rem" }}>
                                       Update Display Order</button>  
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', overflowY: 'auto', maxHeight: '190px', marginTop: '10px' }}>
                               to show an alert message for missing custom category images commented for now
                                { noCustomCategoryImageAlert && <p style={{color:'#FE7099' ,fontSize:'16px'}}>There are some categories without images. Please <span style={{color:'#1c1cec94', cursor:'pointer'}} onClick={() => {setOpenModal2(true)}} >add images</span> to them; otherwise, they will not be saved</p>}
                                    {
                                        customCategories.map((c, i) => <div style={{ display: 'flex', gap: '10px' }} key={i}>
                                            <p><strong>{c.heading}</strong>{'=> '}</p>
                                            <div style={{ display: 'flex', gap: '4px', flexWrap: "wrap" }}>
                                                {c.category?.map((fc, i) => <p key={fc.name}> {`${fc.name}${c.category.length - 2 >= i ? ',' : ''}`}</p>)}
                                            </div>
                                            <div style={{ display: 'flex', gap: '4px', flexWrap: "wrap" }}>
                                                {c.fixedCategory?.map((fc, i) => <p key={fc.name}> {`${fc.name}${c.fixedCategory.length - 2 >= i ? ',' : ''}`}</p>)}
                                            </div>
                                            <div onClick={() => {handleDeleteCustomCategoryByHeading(c)}} style={{flex:1, cursor:'pointer'}} >
                                               <Tooltip title={ <Typography sx={{fontSize:'0.85rem'}}>Delete this category and its associated custom categories.</Typography>}>
                                                  <div style={{ float:'right'}} className='table-btn'> <DeleteOutlineOutlinedIcon sx={{fontSize: "18px", color: "red",}} /> </div>
                                                </Tooltip>
                                            </div> 
                                        </div>
                                        )
                                    }
                                </div>
                            </> : null} */}

                            {/* <Modal isOpen={openModal} onClose={() => setOpenModal(false)} onDone={openCustomCategoryImageUpload} title="Custom category" buttonText={'Save custom category'}>

                                <Input value={customCategoryHeadline?.heading} onChange={handleHeadlineChange} type='text' label="Custom category heading"  name="customCategory_headline" iButton
                                    infoBubbleText='You can create any category heading you’d like. All headings begin with “Browse by…” and you fill in the rest. Some examples include: “Teachers,”
                                     “Grades,” “Featured Items,” “Sports,” “Artists,” “Locations” – anything you want.' />

                                <TagInput
                                    // fixedTags={fixedTags}
                                    label="Add Custom Category"
                                    name="custom_categories"
                                    tags={customCategoryHeadline.category}
                                    handleChange={handleCategoryChange}
                                    clearTags={clearTags}
                                />
                                <div style={{ textAlign: "right" }}>
                                    <button
                                        type="button"
                                        onClick={addNewCustomCategory}
                                        style={{ marginTop: "10px" }}
                                        className="aitem-modal-btn aitem-white-button"
                                    >
                                        + Add New category Heading
                                    </button>
                                </div>
                                <hr />
                                {customCategories && customCategories.length > 0 && <h5>Edit Custom Categories</h5>}

                                <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', overflowY: 'auto', maxHeight: '190px' }}>
                                    {
                                        customCategories.map((c, i) => <div key={i}>

                                            <Input type='text' label="Custom category heading" value={c.heading} onChange={(e) => handleHeadlineEdit(c.tempId, c.id, e)} name="customCategory_headline" />
                                            <TagInput
                                                fixedTags={c.fixedCategory}
                                                label="Add Custom Category"
                                                name="custom_categories"
                                                tags={c.category}
                                                handleChange={(newCategories) => handleCategoryEdit(c.tempId, c.id, newCategories)}
                                                clearTags={false}
                                                deleteTags={ deleteCustomCategoryAlert}
                                            />

                                            <hr />
                                        </div>
                                        )
                                    }
                                </div>
                            </Modal> */}

                            {/* <Modal isOpen={openModal2} onDone={saveCustomCategoryChange} onClose={() => setOpenModal2(false)} title='Upload image for your custom categories' buttonText='Save & Continue' > */}
                            <Modal buttonRequired={customCategories.length ? true : false} isOpen={openModal2} onDone={saveCustomCategoryChange} onClose={()=>setOpenModal2(false)} title='Upload image for your custom categories' buttonText='Save & Continue' >
                            {customCategories.length ? 
                 
                              <>
                                <p>*Recommended size 375x450px </p>
                                {
                                    customCategories.map((h, i) => <div key={h.heading} className='custom_category_container' >
                                        {/* <p><strong>{h.heading}</strong></p><hr /> */}
                                        <div className='custom_category_image_container'>
                                            {
                                                h.category?.map((c) => {
                                                    return <div key={c.tempId} style={{ width: '100px' }}>
                                                        <ImageInput
                                                            loading={customCategoryImageLoading[c.tempId]}
                                                            edit
                                                            onDelete={() => deleteCustomCategoryImage(c.tempId)}
                                                            handleChange={(e) => handleCustomCategoryImage(e, c.tempId)}
                                                            images={customCategoryImagePreview[c.tempId] ? [{ name: customCategoryImagePreview[c.tempId] }] : []}
                                                            max={1}
                                                        />
                                                        <p style={{ textAlign: 'center' }} >{c.name}</p>
                                                    </div>
                                                })
                                            }
                                            {
                                                h.fixedCategory?.map((c) => (
                                                    <div key={c.id} style={{ width: '100px' }}>
                                                        <ImageInput
                                                            loading={customCategoryImageLoading[c.tempId]}
                                                            edit
                                                            onDelete={() => deleteCustomCategoryImage(c.tempId, h?.id, c?.id)}
                                                            handleChange={(e) => handleCustomCategoryImage(e, c.tempId)}
                                                            images={customCategoryImagePreview[c.tempId] ? [{ name: customCategoryImagePreview[c.tempId] }] : []}
                                                            max={1}
                                                        />
                                                        <p style={{ textAlign: 'center' }} >{c.name}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>)
                                }
                                </>
                                  : <h6 style={{fontWeight:600, textAlign:'center', paddingBlock:'20px', color:'red'}} >Please create custom categories before uploading images.</h6>
                     
                                  }
                            </Modal>

                            {/* <Modal isOpen={headingPriorityOrderModal}  onClose={()=>setHeadingPriorityOrderModal(false)} title='Update Heading Display Order' buttonRequired={false} >
                                 <>
                               { customCategories?.length && auction_id ? 
                                 <CustomCategoryHeadingPriorityModalComponent
                                  category={customCategories}
                                  setPriorityCategory={setCustomCategories}
                                  onHeadingDone={savePriorityOrder}
                                  setIsLoading={setIsLoading}
                                  setAlertMsg={setAlertMsg}
                                  setHeadingPriorityOrderModal={setHeadingPriorityOrderModal}
                                  forCustomCategory={updatePriorityOrderModal}
                                />
                                : <h5 style={{fontWeight:600, textAlign:'center', paddingBlock:'20px'}} >No Categories are saved</h5>
                               }
                                 </>
                            </Modal> */}

                            {/* Priority order modal */}

                              <Modal isOpen={priorityOrderModal}  onClose={()=>setPriorityOrderModal(false)} title='Update Custom Category Display Order' buttonRequired={false} >
                              <>
                              { priorityCategory?.length ?  
                               <CustomCategoryPriorityModalComponent
                                 category={priorityCategory}
                                 setPriorityCategory={setPriorityCategory}
                                 onDone={savePriorityOrder}
                                 setIsLoading={setIsLoading}
                                 customCategoryHeadingId={customCategoryHeadingId}
                                 setAlertMsg={setAlertMsg}
                                setPriorityOrderModal={setPriorityOrderModal}
                               />
                                : <h5 style={{fontWeight:600, textAlign:'center', paddingBlock:'20px'}} >No Categories are saved</h5>
                              }
                                </>
                              </Modal>

                              <Modal 
                               isOpen={updateVisibilityModal.open}  
                               onClose={()=>setUpdateVisibilityModal({open:false})} 
                               title='Change Custom Category Visibility In App'
                               onDone={handleUpdateVisiblityModal}
                               buttonText='Save & Continue' 
                               extraButtonRequired 
                               extraButtonText={'Go Back'} 
                               onExtraButtonDone={handleGoBack}
                             >
                               <VisibilityModal customCategories={customCategories} setCustomCategories={setCustomCategories}/>
                             </Modal>

                        </div>

                        <div>

                            <ImageInput
                                loading={portraitImageLoading}
                                // required
                                edit
                                onDelete={deletePortrait}
                                label={eventImageInputLabel(`${stringCapitalName} Portrait`,stringLowerName)}
                                // sidelabel={constants.ADMIN_COPY_CHARITY_PORTRAIT_IMAGE_SIDELABEL}
                                handleChange={handlePortraitImage}
                                images={portraitImage ? portraitImage : []}
                                max={1}
                                
                                showCustomImageSelect={true}
                                handleCustomImageSelect={() => handleCustomImageSelect('portrait')}
                            />
                            <CustomImagesModal
                              permission={{newImagePermission:newImagePermission, godPermission:godPermission}}
                              handleImageState={handleImageState}
                              isOpen={customImageModal}
                              onClose={() => setCustomImageModal(false)}
                              onDone={handleSelectedImage}
                              images={customImages}
                              setIsLoading={setIsLoading}
                              stringLowerName={stringLowerName}
                              setAlertMsg={setAlertMsg}
                              handleCustomImageSelect={handleCustomImageSelect}
                            />
                            
                            <ImageInput
                                loading={landscapeImageLoading}
                                // required
                                edit
                                onDelete={deleteLandscape}
                                label={eventImageInputLabel(`${stringCapitalName} Landscape`,stringLowerName)}
                                // sidelabel={constants.ADMIN_COPY_CHARITY_LANDSCAPE_IMAGE_SIDELABEL}
                                handleChange={handleLandscapeImage}
                                images={landscapeImages ? landscapeImages : []}
                                max={1}
                                showCustomImageSelect={true}
                                handleCustomImageSelect={() => handleCustomImageSelect('landscape')}
                            />


                            {/* featured image input */}

                            {
                              stringLowerName === 'store' &&
                              <ImageInput
                                loading={featuredIconLoading}
                                // required
                                edit
                                onDelete={deleteFeaturedImage}
                                label={constants.ADIMIN_COPY_FEATURED_STORE_IMAGE_LABEL}
                                sidelabel={constants.ADMIN_COPY_FEATURED_IMG_SIZE}
                                handleChange={handleFeaturedIconChange}
                                images={featuredImage ? featuredImage : []}
                                max={1}
                            />
                            }


                             <Input inputStyle={{width:'100%'}} type='color' name={'text_color'} label={constants.ADMIN_COPY_EVENT_TEXT_COLOR_LABEL.replace('Event', stringCapitalName)} value={stateData?.text_color} onChange={handleDataChange} iButton infoBubbleText='Select a color that will contrast well with your background images.'/>
                            <RadioGroupComponent
                              checked={boxColorPicker}
                              onChange={handleNeedBoxColorPickerChange}
                              label={constants.ADMIN_COPY_EVENT_BOX_COLOR_RADIO.replace('event', stringLowerName)} 
                            />
                            { boxColorPicker && <Input inputStyle={{width:'100%'}} type='color' name={'box_color'} label={constants.ADMIN_COPY_EVENT_BOX_COLOR_LABEL} value={stateData?.box_color || '#f0f0f0'} onChange={handleDataChange} />}
                            {/* <div style={{ marginTop: '32px', position: 'relative' }} >
                                <label className='browse-image-input-label'>Showcase Your {stringCapitalName} With An Image For
                                    <Tooltip title={<Typography sx={{ fontSize: '0.85rem' }}>
                                        Auctions and Store Items/tickets have their own storefronts. Select a custom image for each. If your {stringLowerName} offers only one type of item (auction or store), then select only one image to upload.
                                    </Typography>}><InfoOutlinedIcon sx={{ color: '#495057c4', fontSize: '16px', marginLeft: "4px" }} /></Tooltip> 
                                    <span style={{ fontSize: "0.7rem", color: "gray", display:'flex' }}>{constants.ADMIN_COPY_CHARITY_BROWSE_AUCTION_IMAGE_SIDELABEL}
                                    </span>
                                </label>
                                <div className='event_browse_image_wrapper'>
                                    <ImageInput
                                        loading={browseAuctionImageLoading}
                                        edit
                                        onDelete={delteAuctionBrowseImage}
                                        label="Browsing Auction Items."
                                        // sidelabel="Max 1, recommended size 300x200px"
                                        handleChange={handleBrowseAuctionItemImage}
                                        images={browseAuctionImage ? browseAuctionImage : []}
                                        max={1}
                                        showCustomImageSelect={true}
                                        handleCustomImageSelect={() => handleCustomImageSelect('auction_item')}
                                    />
                                    <ImageInput
                                        loading={browseBuyNowImageLoading}
                                        edit
                                        onDelete={delteBuyNowBrowseImage}
                                        label="Browsing Buy Now Items."
                                        // sidelabel="Max 1, recommended size 300x200px"
                                        handleChange={handleBrowseBuyNowItemImage}
                                        images={browseBuyNowImage ? browseBuyNowImage : []}
                                        max={1}
                                        showCustomImageSelect={true}
                                        handleCustomImageSelect={() => handleCustomImageSelect('buy_now_item')}
                                    />
                                </div>
                            </div> */}
                         {/* { location.pathname.includes('/store') ?
                           <>
                            <ImageInput
                              loading={videoLoading}
                              label={`Showcase Your ${stringCapitalName} With A Brief Video.`}
                              sidelabel="Up to 2, Max size 20MB each"
                              accept='video/*'
                              multiple={true}
                              handleChange={handleMultipleVideos}
                              images={videos ? videos : []}
                              max={2}
                              edit
                              onDelete={deleteVideo}
                            />
                         </>
                          : null} */}
                            <div className='event-needhelp'><a target='_blank' href="mailto:help@boundlessgiving.com">Need Help?</a></div>
                            { auction_id && (<div style={{width:'100%', display:'flex', justifyContent:'center'}}><button style={{width:'60%'}}  className='event-submit-btn aitem-white-button' type='submit'>Update {stringCapitalName}</button></div>)}
                            <button onClick={() => setNavigateAction('terms')} className='event-submit-btn' type='submit'>{ ` ${auction_id ? "Update" : "Save"} ${stringCapitalName} & Continue to ${auction_id?'update':'add'}  Terms`}</button>
                        </div>
                    </form>
                    <div style={{ fontSize: "0.9rem", marginTop: "20px", fontWeight: "600", marginTop:"5px" }}><span style={{ color: "red" }}>*</span> - required fields</div>
                    <Alert isOpen={!!alertMsg} message={alertMsg} onClose={() => setAlertMsg(null)} />
                </>
               <LoadingModal isOpen={isLoading} style={{zIndex:'41'}} />
               <Modal isOpen={openDonor} onClose={() => setOpenDonor(false)} onDone={handleLocalDonor} title="New Donor">
                 <Input value={localDonor.org_name} onChange={(e) => setLocalDonor({...localDonor, org_name: e.target.value})} label="Donor Name or Organization" required />
                 <Input value={localDonor.email} onChange={(e) => setLocalDonor({...localDonor, email: e.target.value})} label="Donor Email" required />
                 <FormTextarea value={localDonor.about} onChange={(e) => setLocalDonor({...localDonor, about: e.target.value})} label="Long Description" required iButton infoBubbleText='Tell us about your item’s donor. Who are they? What do they do? Do you want to share a link to their web site?'/>
               </Modal>   
               <Alert type='confirm' isOpen={!!customCategoryAlert} message={customCategoryAlert} onClose={() => setCustomCategoryAlert(null)} onConfirm={handleDeleteCustomCategory} />
               <Alert type='confirm' isOpen={!!allCustomCategoryAlert} message={allCustomCategoryAlert} onClose={() => setAllCustomCategoryAlert(null)} onConfirm={handleDeleteAllCustomCats} />
               <Alert type='confirm' isOpen={!!customCategoryByHeadingAlert} message={customCategoryByHeadingAlert} onClose={() => setCustomCategoryByHeadingAlert(null)} onConfirm={deleteAllCustomCatsByHeading} />
            
        </>
        }
            </Container>
    )
}

export default CreateForm
