import { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from "@mui/material"
import { useDispatch, useSelector } from 'react-redux';
import { fetchItemBuyersData, changePage, changeLimit } from '../../../redux/itemBuyersReducer';
import moment from 'moment';
import CSVDownload from '../CSVDownload';
import LoadingModal from '../LoadingModal/LoadingModal';
import auctionServices from '../../../services/auction-services';
import styles from './BuyersModal.module.css'
import VariantCombination from '../VariantCombination/VariantCombination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const BuyersModal = ({ item = {}, auction_id = null }) => {
    const dispatch = useDispatch()
    const { loading, page, limit, buyers, totalBuyers, error } = useSelector(state => state.itemBuyer)
    const [csvState, setCsvState] = useState({ loading: false, data: null })
    useEffect(() => {
        getBuyersData()
    }, [page, limit])
    useEffect(() => {
        return () => {
            dispatch(changeLimit(10))
            dispatch(changePage(1))
        }
    }, [])
    const getBuyersData = () => {
        dispatch(fetchItemBuyersData({ auction_id, itemId: item?.id, page, limit }))
    }
    const downLoadCsv = async() => {
        if (!auction_id) return
        try {
            setCsvState({ ...csvState, loading: true })
            let data = await auctionServices.getBuyersOfItem(auction_id, item?.id);
            let newList = [['Buyer Name', 'Email', 'Product SKU', 'Variant Group', 'Quantity Bought', 'Order Date']]
            data?.data?.forEach(row => {
                newList.push([row?.user_order?.user?.first_name + ' ' + row?.user_order?.user?.last_name,
                 row?.user_order?.user?.email,
                 row?.product_sku_id,
                 row?.variant_combination?.fullValue ? row?.variant_combination?.fullValue : 'NA',
                 row?.quantity_bought,
                 moment(row?.user_order?.created).format('MM/DD/YYYY')])
            });
            setCsvState({ loading: false, data: newList })
        } catch (error) {
            setCsvState({ loading: false, data: null })
        }
    }

    return (
        <div>
            {loading ? <LoadingModal style={{ position: 'relative', minWidth: '400px', marginLeft: '0px', minHeight: '300px', backgroundColor: 'white' }} isOpen={true} />
                :
                !buyers?.length ?
                    <div className={styles.alertContainer}>
                        <p>{error ? error : "Item Doesn't have any buyer."}</p>
                    </div>
                    :
                    <>
                        <Paper>
                            <TableContainer>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Buyer Name</StyledTableCell>
                                            <StyledTableCell>Email</StyledTableCell>
                                            <StyledTableCell>Product SKU</StyledTableCell>
                                            <StyledTableCell>Quantity Bought</StyledTableCell>
                                            <StyledTableCell>Order Date</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {buyers?.map((row, i) => (
                                            <StyledTableRow key={row.id}>
                                                <StyledTableCell>{row?.user_order?.user?.first_name + ' ' + row?.user_order?.user?.last_name}</StyledTableCell>
                                                <StyledTableCell>{row?.user_order?.user?.email}</StyledTableCell>
                                                <StyledTableCell>
                                                    <div className={styles.variant_cell}>
                                                        <span>{row?.product_sku_id}</span>
                                                        {
                                                            row?.variant_combination &&
                                                            <VariantCombination combiantionKeys={Object.keys(row?.variant_combination?.fullObject)} combiantionValues={Object.values(row?.variant_combination?.fullObject)} />
                                                        }
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell>{row?.quantity_bought}</StyledTableCell>
                                                <StyledTableCell>{moment(row?.user_order?.created).format('MM/DD/YYYY')}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 20, 25]}
                                component="div"
                                count={totalBuyers}
                                rowsPerPage={limit}
                                page={page - 1}
                                onPageChange={(e, newPage) => dispatch(changePage(newPage + 1))}
                                onRowsPerPageChange={(e) => {
                                    console.log(e.target.value)
                                    dispatch(changeLimit(+e.target.value))
                                }}
                            />
                        </Paper>
                        <div className={styles.csvContainer} >
                            <button className='aitem-modal-btn' onClick={downLoadCsv} >{csvState?.loading ? "Loading CSV..." : "Download CSV Data"}</button>
                            {csvState.data && <CSVDownload filename={`${item?.name}-buyers.csv`}
                                data={csvState?.data} target="_blank" replace={'false'} />}
                        </div>
                    </>
            }
        </div>
    )
}

export default BuyersModal;