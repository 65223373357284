import Container from "../../components/HOC/Container/Container"
import Input from "../../components/Input/Input"
import { useState } from 'react'
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import logger from "../../../utils/logger";
import authService from "../../../services/auth-services";
import { useNavigate } from "react-router-dom";
import { validEmail } from "../../../utils/reg-ex";
import Lock from "@mui/icons-material/Lock";
import LoadingButton from '../../components/LoadingButton/LoadingButton'
import "./changeEmail.css"
const ChangeEmail = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [errorMsg, setErrorMsg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [newEmail,setNewEmail] = useState("");
    const [newEmailError, setNewEmailError] = useState("");
    const handleEmail = (e) => {
      setEmail(e.target.value);
      if (validEmail.test(e.target.value)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    };

    const handleNewEmail = (e) =>{
        setNewEmail(e.target.value)
        if (validEmail.test(e.target.value)) {
            setNewEmailError(false);
          } else {
            setNewEmailError(true);
          }
    }
    
    const handleSubmit = async (e) =>{
       e.preventDefault();
       if (!emailError && !newEmailError && password) {
        setLoading(true)
       try{
        let body={
            email: email,
            newEmail: newEmail,
            password:password,
            role:'Admin'
        }
       await authService.changeEmail(body).then((res)=>{
        setErrorMsg({ type: "success" , body: res.data.message})
        setLoading(false)
       }).catch((err)=>{
        setErrorMsg({ type: "error", body: err.response.data.message });
        setLoading(false)
       })
      
       }catch (err) {
        logger("./pages/ChangeEmail/ChangeEmail.jsx", "handleSubmit(): catch2", err);
        setErrorMsg({ type: "error", body: "Login failed!" });
        setLoading(false)
      }
    }
    }
    return (
        <>
        <Container style={{width:"70%"}}>
        <div className="change-email-conatiner">
        <h2 style={{textAlign:"center",marginBottom:"25px"}}>Change Email</h2>
        {errorMsg && <p style={{color: errorMsg.type === 'success' ? 'green' : 'red', fontSize: "12px",textAlign:"center"}}> {errorMsg.body} </p>}
        <form className='change-email-form' onSubmit={handleSubmit}>
              <Input required label="Old Email Address" type='email' value={email} onChange={handleEmail} style={{marginBottom: "2px"}}/>
              <div className='change-email-password-container'>
                <Input required label="Password" type={showPassword ? 'text': 'password'} value={password} onChange={(e) => setPassword(e.target.value)} style={{marginBottom: "2px"}}/>
                <div className='change-email-showicon' onClick={() => setShowPassword(!showPassword)}>{showPassword ? <VisibilityOffIcon sx={{fontSize: "17px"}} /> : <Visibility sx={{fontSize: "17px"}} />}</div>
              </div>
              <Input required label="New Email" type="email" value={newEmail} onChange={handleNewEmail} style={{marginBottom: "20px"}}/>
              <div className='change-email-submit-container'>
                <div className='change-email-submit-button'>
                  <LoadingButton isLoading={loading} type="submit">Change Email</LoadingButton>
                </div>
              </div>
            </form>
        </div>
        </Container>
        </>
    )
}


export default ChangeEmail