import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    marketPlace: null,
    portraitImage: null,
    landscapeImage: [],
    browseBuyNowImage:null,
    browseAuctionImage:null,
    featuredImage:null,
}

const savedMarketPlaceReducer = createSlice({
    name: "marketPlace/data",
    initialState,
    reducers: {
        saveMarketPlace: (state, action) => {
            return {
                marketPlace: action.payload.marketPlace,
                portraitImage: action.payload.portraitImage,
                landscapeImage: action.payload.landscapeImage,
                browseBuyNowImage: action.payload.browseBuyNowImage,
                browseAuctionImage: action.payload.browseAuctionImage,
                featuredImage: action.payload.featuredImage
            }
        },
        resetSavedMarketPlace: (state, action) => {
            return initialState
        },
        updateMarketPlaceProperties: (state, action) => {
            state.marketPlace[action.payload.key] = action.payload.value
        }
    }
})

export default savedMarketPlaceReducer.reducer
export const {saveMarketPlace, resetSavedMarketPlace, updateMarketPlaceProperties} = savedMarketPlaceReducer.actions