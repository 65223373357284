import React, { useEffect, useState } from 'react'
import Container from '../../../components/HOC/Container/Container'
import {useDispatch, useSelector} from "react-redux"
import "./ItemList.css"
import auctionServices from '../../../../services/auction-services';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Alert from "../../../components/Alert/Alert"
import { FormControlLabel, Radio, RadioGroup, TablePagination, Tooltip } from '@mui/material';
import CSVDownload from "../../../components/CSVDownload";
import AddTaskIcon from '@mui/icons-material/AddTask';
import { changeAuctionItemLimit, changeAuctionItemPage, changeStoreItemLimit, changeStoreItemPage } from '../../../../redux/itemsListReducer';
import Modal from '../../../components/Modal/Modal';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VariantsModalComponent from '../../../components/VariantsModalComponent/VariantsModalComponent';
import LoadingModal from '../../../components/LoadingModal/LoadingModal';
import constants, { COLORS_OBJECT } from '../../../../constants/en';
import TableButton from '../../../components/TableButton/TableButton';
import authService from '../../../../services/auth-services';
import {Visibility, VisibilityOff, ContactMail,ContentPasteGo} from '@mui/icons-material';
import BuyersModal from '../../../components/BuyersModal/BuyersModal';
import ItemListMenu from '../../../components/ItemListMenu/ItemListMenu';
import Input from '../../../components/Input/Input';
import SearchInput from '../../../components/SearchInput/SearchInput';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const redColor = COLORS_OBJECT.color[0]

const ItemList = () => {
    const {auction_id, auction_status} = useSelector(state => state.dependencies)
    const {auctionItemsPage: auctionPage, storeItemsPage: storePage, auctionItemsLimit: auctionLimit, storeItemsLimit: storeLimit} = useSelector(state => state.itemsList)
    const [currentTab, setCurrentTab] = useState("auction_item")
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [openConfirmAlert, setOpenConfirmAlert] = useState(false)
    const [itemId, setItemId] = useState(null)
    const [updated, setUpdated] = useState(false)
    const [storeItems, setStoreItems] = useState(null)
    const [auctionItems, setAuctionItems] = useState(null)
    // const [storePage, setStorePage] = useState(1)
    // const [storeLimit, setStoreLimit] = useState(5)
    // const [auctionPage, setAuctionPage]= useState(1)
    // const [auctionLimit, setAuctionLimit] = useState(5)
    const [csvLoading, setCsvLoading] = useState(false)
    const [auctionCSVData, setAuctionCSVData] = useState([])
    const [storeCSVData, setStoreCSVData] = useState([])
    const [initiateAuctionDownload, setInitiateAuctionDownload] = useState(false)
    const [initiateStoreDownload, setInitiateStoreDownload] = useState(false)
    const [openMakeActiveAlert, setOpenMakeActiveAlert] = useState(false)
    const [errorMessage,setErrorMessage] = useState(null)
    const [errorAlert, setErrorAlert] = useState(false)
    const [variantsModal, setVariantsModal] = useState(false)
    const [variants, setVariants] = useState([])
    const [options, setOptions] = useState({})
    const location = useLocation()
    const [eventType, setEventType] = useState('event');
    const [itemData, setItemData] = useState({})
    const [activeConfirmMessages, setActiveConfirmMessages] = useState('')
    const [successAlert, setSuccessAlert] = useState({message:'', isOpen:false, alertType:'alert', confirmFunction:()=>null, confirmMetaData:null});
    const [buyersModal, setBuyersModal] = useState({isOpen:false, modalData:null, title:'', onDoneFunction:()=>null})
    const [searchValue, setSearchValue] = useState('')
    const [itemSorting, setItemSorting] = useState({order:'DESC', orderBy:'created'})
    const [tableLoading, setTableLoading] = useState(false)
 const getEventType=(path=location.pathname)=>{
      switch (path) {
        case '/admin/items':
          setEventType('event')
          return 'event'
        case '/admin/market-place/items':
          setEventType('marketplace')
          return 'marketplace'
        case '/admin/store/items':
          setEventType('store')
          setCurrentTab('store_item')
          return 'store'
        default:
          setEventType('event')
          return 'event'
      }
 }
 const getPageType = (path=location.pathname) => {
  switch (path) {
    case '/admin/items':
      return 'event'
    case '/admin/market-place/items':
      return 'marketplace'
    case '/admin/store/items':
      return 'store'
    default:
      return 'event'
  }
 }
    const [path, setPath] = useState('');
    const [openAllActiveAlert,setOpenAllActiveAlert] = useState(false);
    const [unActiveItems,setUnActiveItems] = useState([]);
    const [markAllActiveSuccessMsg,setMarkAllActiveSuccessMsg] = useState(null)
    const [assignedItem, setAssignedItem] = useState(false)

    const [godAccess, setGodAccess] = useState(false);  
    const [editAccess, setEditAccess] = useState(false);  
  
    const checkPermission = async() => {
      try{
          setIsLoading(true)
          const permission = await authService.checkLimitedPermissions(15);
          setGodAccess(permission?.access);
          setIsLoading(false)
      }catch(error) {
          setGodAccess(false);
          setIsLoading(false)
      }
    }

    const checkEditItemPermission = async() => {
      try {
        const permission = await authService.checkPermissions(5, auction_id)
        setIsLoading(false);
        if (!permission.access) {
          setEditAccess(false)
          setIsLoading(false)
          return
        } else {
          setEditAccess(true)
        }
      }catch(error) {
        setEditAccess(false)
        setIsLoading(false)
      }
    }
  
    useEffect(() => {
      checkPermission()
      checkEditItemPermission()
    },[])

    useEffect(() => {
      setItemSorting({order:'DESC', orderBy:'created'})
    },[currentTab])

useEffect(() => {
  getEventType(location.pathname)
    if(!auction_id){
      if(location.pathname === '/admin/items'){
        alert("No event found")
        navigate('/admin/my-events')
        return
      }
    else if(location.pathname === '/admin/market-place/items'){
        alert("No event found")
        navigate('/admin/market-place')
        return
      }
      else {
        alert("No event found")
        navigate('/admin/store')
        return
      }
    }
    }, [location.pathname])



    useEffect(() => {
      if(initiateAuctionDownload) {
        setInitiateAuctionDownload(false)
      }
    }, [initiateAuctionDownload])

    useEffect(() => {
      if(initiateStoreDownload) {
        setInitiateStoreDownload(false)
      }
    }, [initiateStoreDownload])

    const removeItem = async (item_id) => {
      try {
        switch (assignedItem) {
          case false:
            await auctionServices.deleteAuctionItemById(auction_id, item_id);
            break;
          case true:
            await auctionServices.deleteAssignedItem(auction_id, item_id);
            break;
          default:
            break;
        }
        setOpenConfirmAlert(false);
        setUpdated(!updated)
        setItemId(null)
        setAssignedItem(false)
      } catch (err) {
        setErrorMessage(constants.ADMIN_COPY_PREVIEW_EVENT_EDIT_ITEM_PERMISSION)
        setErrorAlert(true)
      }
    };

    const handleMakeAciveConfirm=async()=>{
      setOpenMakeActiveAlert(false);
      setActiveConfirmMessages('');
      const donorId = itemData?.donor_id
      if(!itemId) return
      try {
        if(getEventType(location.pathname) === 'marketplace' || auction_id === itemData?.auction_id) {
          /// if the event is a market place then we don't need to send the donor id.
          await auctionServices.approveItemToActive(auction_id,itemId);
        }
        else {
          await auctionServices.approveItemToActive(auction_id,itemId, donorId);
        }
        setUpdated(!updated)
        setItemId(null)
      } catch (err) {
        setErrorMessage(err?.response?.data?.message ? err?.response?.data?.message :constants.ADMIN_COPY_PREVIEW_EVENT_EDIT_ITEM_PERMISSION)
        setErrorAlert(true)
      }
    }

    const handleCloseConfirmAlert = () => {
      setOpenConfirmAlert(false);
      setOpenMakeActiveAlert(false)
      setItemId(null)
    };
    
    const handleOpenDeleteConfirm = (id, assigned=false) => {
      setOpenConfirmAlert(true)
      setAssignedItem(assigned)/// if assigned is true we need to delete the item in auctionAuctionItemXref table
      setItemId(id)
    }

    const handleDeleteConfirm = () => {
      setOpenConfirmAlert(false)
      if(!itemId) return
      removeItem(itemId)
    }  

    const checkVendorSkuId = (item, allItems=false) => {
      let result = {
          valid: true,
          message: ''
      }
      let message = constants.ADMIN_COPY_ITEM_VENDOR_SKU_ID_ALERT
      if(allItems) {
        message = constants.ADMIN_COPY_ITEM_VENDOR_SKU_MISSING_MULTIPLE_ITEM
      }
      if(currentTab === 'auction_item') {
        if(!item?.vendor_sku_id || item?.vendor_sku_id === null || item?.vendor_sku_id === '') {
          result = {
            valid: false,
            message: message
          }
        }
      }
      else {
        if(item?.store_item?.store_item_actual_inventories?.length) {
          let variantVendorEmpty=false
          for(let varaint of item?.store_item?.store_item_actual_inventories) {
            if(!varaint?.vendor_sku_id || varaint?.vendor_sku_id === null || varaint?.vendor_sku_id === '') {
              variantVendorEmpty=true
              break;
            }
          }
          if (variantVendorEmpty) {
            result = {
              valid: false,
              message: message
            }
          }
        }
        else {
          if(!item?.store_item?.vendor_sku_id || item?.store_item?.vendor_sku_id === null || item?.store_item?.vendor_sku_id === '') {
            result = {
              valid: false,
              message: message
            }
          }
        }
      }
      return {
        ...result
      }
    }

   const handleMakeAcive=(id, item)=>{
      if(item?.fulfilment_id === 3 && item?.advatix_status !== 'known') {
        if(godAccess) {
          setActiveConfirmMessages(constants.ADMIN_COPY_ITEM_ADVATIX_STATUS_KNOWN_GOD_USER)
          setOpenMakeActiveAlert(true)
          setItemId(id)
          setItemData(item)
          return
        }
        else {
          setErrorMessage(constants.ADMIN_COPY_ITEM_ADVATIX_STATUS_KNOWN)
          setErrorAlert(true)
          return
        }
      }
      if(item?.fulfilment_id === 5) {
        const result = checkVendorSkuId(item)
        if(!result.valid) {
          setErrorMessage(result.message)
          setErrorAlert(true)
          return
        }
      }
      // else {
        setActiveConfirmMessages(constants.ADMIN_COPY_ITEM_ADVATIX_STATUS_KNOWN_NORMAL_USER)
        setOpenMakeActiveAlert(true)
        setItemId(id)
        setItemData(item)
      // }
   }

    const getAuctionItemsById = async (goToFirstPage=false, removeSearch=false, loadTable=false) => {
        try {
          if(loadTable) {
            setTableLoading(true)
          }
          else {
            setIsLoading(true)
          }
          let page = auctionPage
          if(goToFirstPage) {
            page = 1
          }
          const auction_items = await auctionServices.getAuctionItemsByAuctionId(auction_id, page, auctionLimit, null, removeSearch ? '' : searchValue, itemSorting.order, itemSorting.orderBy);
          setAuctionItems(auction_items)
          setIsLoading(false);
          setTableLoading(false)
        } catch (err) {
          setIsLoading(false);
          setTableLoading(false)
        }
    };

    const getStoreItemsById = async (goToFirstPage=false, removeSearch=false, loadTable=false) => {
      try {
        if(loadTable) {
          setTableLoading(true)
        }
        else {
          setIsLoading(true)
        }
        let page = storePage
        if(goToFirstPage) {
          page = 1
        }
        const store_items = await auctionServices.getStoreItemsByAuctionId(auction_id, page, storeLimit,null, getEventType(location.pathname), removeSearch ? '' : searchValue, itemSorting.order, itemSorting.orderBy)
        setStoreItems(store_items)
        setIsLoading(false);
        setTableLoading(false)
      } catch (err) {
        setTableLoading(false)
        setIsLoading(false);
      }
    }

    useEffect(() => {
        getAuctionItemsById()
    }, [updated, auctionPage, auctionLimit, auction_id])

    useEffect(() => {
      getStoreItemsById()
    }, [updated, storePage, storeLimit, auction_id])

    async function fetchItems(itemType, startFromPageOne=false) {
      if(itemType === 'auction') {
        await getAuctionItemsById(startFromPageOne, false, true)
      }
      else if(itemType === 'store') {
       await getStoreItemsById(startFromPageOne, false, true)
      }
    }

    useEffect(() => {
      // this will fetch any one item(auction or store) according to the tab
      if(itemSorting.order==='DESC' && itemSorting.orderBy === 'created') return;
      fetchItems(currentTab.replace('_item',''))
    },[itemSorting])

    const handleEdit = (item, type) => {
      if(item?.status === 'sold') {
        setErrorMessage(constants.ADMIN_COPY_PREVIEW_EVENT_EDIT_ITEM.replace('completed', 'sold'));
        setErrorAlert(true)
        return
      }
      if(!godAccess && (moment(item.end_date).format() < moment().format()) &&( type === "auction")){
        setErrorMessage(constants.ADMIN_COPY_PREVIEW_EVENT_EDIT_ITEM);
        setErrorAlert(true)
        return
      }else{
        if(location.pathname.includes('/admin/items')){ 
          navigate(`/admin/items/edit/${auction_id}/${item.id}`, {state: {type: type}})
        }
        else if(location.pathname.includes('/admin/market-place')){
          navigate(`/admin/market-place/items/edit/${auction_id}/${item.id}`, {state: {type: type}})
      }
      else {
        navigate(`/admin/store/items/edit/${auction_id}/${item.id}`, {state: {type: type}})
      }
    }
     
    }

    const handleGoToAdd = () => {
      navigate("add")
    }

    const handleAuctionCSVData = () => {
      if(!csvLoading) {
        setCsvLoading(true)
        auctionServices.getAuctionItemsByAuctionId(auction_id)
          .then(data => {
            const newList = [['Name', 'Start', 'End', 'Price', 'Virtual', 'Status']]
            data.data.forEach(row => {
              newList.push([row.name, row.start_date
                ? `${moment(row.start_date).format(
                    'MM/DD/YYYY'
                )}`
                : '---', row.end_date
                ? `${moment(row.end_date).format(
                    'MM/DD/YYYY'
                )}`
                : '---',
              row.price,
              row.is_virtual ? "YES" : "NO",
            row.status])
            })
            setAuctionCSVData(newList)
            setCsvLoading(false)
            setInitiateAuctionDownload(true)
          })
          .catch(error => {
            setCsvLoading(false)
            console.log(error)
          }) 
      }
    }
    const handleStoreCSVData = () => {
      if(!csvLoading) {
        setCsvLoading(true)
        auctionServices.getStoreItemsByAuctionId(auction_id)
          .then(data => {
            const newList = [['Name', 'Price', 'Quantity', 'SKU', 'Virtual', 'Status']]
            data.data.forEach(row => {
              newList.push([row.name, 
                row?.store_item?.price ?? "---", 
                row?.store_item?.quantity_in_stock ?? "0",
                row?.store_item.sku,
                row.is_virtual ? "YES" : "NO",
                row?.status])
            })
            setStoreCSVData(newList)
            setCsvLoading(false)
            setInitiateStoreDownload(true)
          })
          .catch(error => {
            setCsvLoading(false)
            console.log(error)
          }) 
      }
    }

    const handleAllItemsMakeActive = () =>{
      let godAccessItemsFlag = false
      let vendorSkuMissingFlag = false;
      let venderSkuResult
      if(currentTab === 'auction_item'){
        let items = [];
        for(let item of auctionItems?.data){
          if(item.status === "pending"){
            if( godAccess && item?.fulfilment_id === 3 && item?.advatix_status !== 'known') {
              godAccessItemsFlag = true;
            }
            items.push(item)
            if(item?.fulfilment_id === 5) {
              venderSkuResult = checkVendorSkuId(item, true)//true is for checking if calling from all items active function
              if(!venderSkuResult.valid) {
                vendorSkuMissingFlag = true;
                break;
              }
            }
          }
        }
        if(vendorSkuMissingFlag) {
              setErrorMessage(venderSkuResult.message)
              setErrorAlert(true)
              return
        }
       if(!items.length){
        // setErrorAlert(true);
        // setErrorMessage("No items to make active")
        return
       }
       else{
        if(godAccessItemsFlag) {
          setActiveConfirmMessages(constants.ADMIN_COPY_ITEM_ADVATIX_STATUS_KNOWN_GOD_USER_ALL_ITEMS)
        }
        else {
          setActiveConfirmMessages(constants.ADMIN_COPY_ITEM_APPROVE_ALERT_NORMAL_USER)
        }
        setOpenAllActiveAlert(true)
       }
      }else{
        let items = [];
        for(let item of storeItems?.data){
          if(item.status === "pending" || item?.auction_auction_item_xrefs[0]?.status === 'pending'){
            if( godAccess && item?.fulfilment_id === 3 && item?.advatix_status !== 'known') {
              godAccessItemsFlag = true
            }
            items.push(item)
            if(item?.fulfilment_id === 5) {
              venderSkuResult = checkVendorSkuId(item, true)//true is for checking if calling from all items active function
              if(!venderSkuResult.valid) {
                vendorSkuMissingFlag = true;
                break;
              }
            }
          }
        }
        if(vendorSkuMissingFlag) {
          setErrorMessage(venderSkuResult.message)
          setErrorAlert(true)
          return
       }
       if(!items.length){
        // setErrorAlert(true);
        // setErrorMessage("No items to make active")
        setActiveConfirmMessages('')
        return
       }
       else{
        if(godAccessItemsFlag) {
          setActiveConfirmMessages(constants.ADMIN_COPY_ITEM_ADVATIX_STATUS_KNOWN_GOD_USER_ALL_ITEMS)
        }
        else {
          setActiveConfirmMessages(constants.ADMIN_COPY_ITEM_APPROVE_ALERT_NORMAL_USER)
        }
        setOpenAllActiveAlert(true)
       }
      }
     
    }

    const fetchVariants = async(storeItemId) => {
      const response = await auctionServices.getVariantInformation(auction_id,storeItemId)
      if(response?.data?.variants?.length > 0){
        // const newVariants = Object.values(response.data.variants).map(obj => {
        //   const newVar = {}
        //   for(let key of Object.keys(obj)) {
        //     newVar[key.trim()] = typeof(obj[key]) === 'string' ? obj[key].trim() : obj[key]
        //   }
        //   return newVar
        // })
        setVariants(response.data.variants)
        setOptions(response.data.options)
        setVariantsModal(true)
      }
      else {
        setVariantsModal(false)
        setErrorMessage("No variants have been added for this item.");
        setErrorAlert(true)
      }
    }
  
    const handleAllitemActiveConfirmAlert = () =>{
   makeAllItemsActive();
   setActiveConfirmMessages('')
   setOpenAllActiveAlert(false)
  }

const makeAllItemsActive = async()=>{
      try {
        setIsLoading(true)
      let data=  await auctionServices.approveAllItemToActive(auction_id,currentTab);
        setIsLoading(false)
        setMarkAllActiveSuccessMsg(data?.message)
      } catch (err) {
        setIsLoading(false)
        setErrorMessage(constants.ADMIN_COPY_PREVIEW_EVENT_EDIT_ITEM_PERMISSION)
        setErrorAlert(true)
      }
}

const checkStoreItemStatus = (item) => {
  if(!item?.auction_auction_item_xrefs?.length || item?.status === 'sold') return item?.status
  else return item?.auction_auction_item_xrefs[0]?.status
}

const handleGoPriorityOrder = (tabValue) => {
  if(getEventType(location.pathname) === 'event') {
    navigate("/admin/items/order", {state:{from:tabValue}})
  }
  else if(getEventType(location.pathname) === 'store') {
    navigate("/admin/store/items/order", {state:{from:tabValue}})
  }
  else if(getEventType(location.pathname) === 'marketplace') {
    navigate("/admin/market-place/items/order", {state:{from:tabValue}})
  }
}

const handleCategoryOrder = () => {
  navigate(`/admin/category-priority-order/${auction_id}${getEventType(location.pathname) === 'event' ? `?item-type=${currentTab}` : ''}`)
}

const closeAlert = (clearMetaData=true) => {
  if(clearMetaData) {
      setSuccessAlert({message:'', isOpen:false, alertType:'alert', confirmFunction:()=>null, confirmMetaData:null})
  }
  else {
      setSuccessAlert({...successAlert, message:'', isOpen:false, alertType:'alert', confirmFunction:()=>null})
  }
}

const getStoreItemArchived = (item) => {
  if(!item?.auction_auction_item_xrefs?.length || item?.status === 'sold') {
    return {
      archived: item?.archived,
      id: item?.id,
      xrefItem: false
    }
  }
  else {
    return {
      archived:  item?.auction_auction_item_xrefs[0]?.archived,
      id: item?.auction_auction_item_xrefs[0]?.id,
      xrefItem: true
    }
  }
}

const handleArhiveItemAlert = (row) => {
  if(!row.id) return;
  let message = constants.ADMIN_COPY_ITEM_ARCHIVED_ALERT
  if(getStoreItemArchived(row)?.archived == '1') {
    message = constants.ADMIN_COPY_ITEM_UNARCHIVED_ALERT
  }
  setSuccessAlert({isOpen:true, message: message, alertType:'confirm', confirmFunction: makeArchived, confirmMetaData: row })
}

const makeArchived = async(data) => {
  closeAlert()
  setIsLoading(true)
  try {
    let archivedStatus = '1'
    let message = constants.ADMIN_COPY_ITEM_ARCHIVED_SUCCESS
    if(getStoreItemArchived(data)?.archived == '1') {
      archivedStatus = '0'
      message = constants.ADMIN_COPY_ITEM_ARCHIVED_SUCCESS.replace('archived', 'unarchived')
    }
    
    const response = await auctionServices.makeItemArchived(getStoreItemArchived(data)?.id, archivedStatus, getStoreItemArchived(data)?.xrefItem)
    setIsLoading(false)
    if(data?.store_item_id !== null) {
      getStoreItemsById()
    }
    else {
      getAuctionItemsById()
    }
    setSuccessAlert({isOpen:true, message: message, alertType:'alert' })
  }
  catch(error) {
    setSuccessAlert({isOpen:true, message: constants.ADMIN_COPY_SOMETHING_WENT_WRONG, alertType:'alert' })
    setIsLoading(false)
  }

}


const handleBuyerModal = async(item) => {
    setBuyersModal({isOpen:true, modalData:item, title:constants.ADMIN_COPY_ITEM_BUYER_MODAL_TITLE})
}

const handleCloneItem = async({item , itemType}) => {
  closeAlert()
  try {
    setIsLoading(true)
    const response = await auctionServices.cloneItem(auction_id, {id: item?.id}, itemType)
    await fetchItems(itemType, true)// to start from first page
    setIsLoading(false)
    setSuccessAlert({isOpen:true, message: response?.message, alertType:'alert' })
  }catch(error) {
    setSuccessAlert({isOpen:true, message: error.response.data?.message || constants.ADMIN_COPY_SOMETHING_WENT_WRONG, alertType:'alert' })
    setIsLoading(false)
  }
}

const handleCloneItemAlert = (item, itemType) => {
  setSuccessAlert({isOpen:true, message: constants.ADMIN_COPY_CLONE_ITEM_ALERT.replace('event', getPageType()), alertType:'confirm', confirmFunction: handleCloneItem, confirmMetaData: {item, itemType} })

}

const handleSearchChange = (e) => {
  setSearchValue(e.target.value)
}

const handleSearchSubmit = async(e) => {
  e.preventDefault()
  // if(!searchValue || !searchValue?.trim().length) return;
  await fetchItems(currentTab.replace('_item',''))
}

const handleClearClick = async() => {
  if(!searchValue || !searchValue?.trim().length) return;
  setSearchValue('')
  await getStoreItemsById(true, true, true)
  await getAuctionItemsById(true, true, true)
}

const handleSort = (orderBy) => {
  if(orderBy===itemSorting.orderBy){
    let order = itemSorting.order === 'ASC' ? 'DESC' : 'ASC';
    setItemSorting({order,orderBy})
  }
  else{
    setItemSorting({order:'ASC',orderBy})
  }
}

    return (
      <Container title='My Items' showButton buttonText='Add Additional Items' onClickButton={handleGoToAdd} style={{padding: 0, position: "relative"}}>
        <div className="items-nav-container">
          <div className='items-navtab-radio' style={{borderBottom:'0'}}>
          <div style={{fontWeight: "600"}}>Select the type of item you want to see - {" "}</div>
          <RadioGroup row value={currentTab} onChange={(e) => setCurrentTab(e.target.value)}>
             {(eventType !== 'store') && <FormControlLabel
                sx={{".MuiFormControlLabel-label": {fontSize: "16px", fontWeight: 600, fontFamily: "AvenirNext"}}}
                value="auction_item"
                control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,},}} />}
                label="Auction Item"
              />}
              <FormControlLabel
                sx={{".MuiFormControlLabel-label": {fontSize: "16px", fontWeight: 600, fontFamily: "AvenirNext"}}}
                value="store_item"
                control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,},}} />}
                label="Store Item"
              />
          </RadioGroup>
          </div>
          <form onSubmit={handleSearchSubmit} className="search-input">
           <SearchInput 
              placeholder="Search..." 
              name='searchValue' 
              onChange={handleSearchChange} 
              value={searchValue}
              handleClearClick={handleClearClick}
              handleSearchClick={handleSearchSubmit}
            />
          </form>
          </div>
        {currentTab === 'auction_item' ? 
        tableLoading  ? <LoadingModal style={{paddingLeft:0, position: 'relative', width: '100%', marginLeft: '0px', minHeight: '300px', backgroundColor: 'white' }} isOpen={true} />
        :
        isLoading ? <LoadingModal isOpen={true} /> :
        (auctionItems && auctionItems.data && auctionItems.data.length > 0 ? 
          <Paper>
                <TableContainer>
                  <div className='list-action-container'>
                  <button onClick={handleCategoryOrder} className='aitem-modal-btn aitem-white-button'>Update Category priority order</button>
                  <div style={{display: "flex", gap: "20px", justifyContent: "flex-end"}}>
                  {/* <button className='aitem-modal-btn aitem-white-button' onClick={handleAllItemsMakeActive}>Make all items active</button> */}
                    <button className='aitem-modal-btn aitem-white-button' onClick={() => handleGoPriorityOrder('auction')}>Update Items priority order</button>
                    <button className='aitem-modal-btn' onClick={handleAuctionCSVData}>{csvLoading ? "Loading CSV..." : "Download CSV Data"}</button>
                    {initiateAuctionDownload && <CSVDownload filename='bg-auction-items.csv' data={auctionCSVData} target="_blank" replace={false} />}
                  </div>
                  </div>
                    <Table sx={{ minWidth: 950 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                            <TableSortLabel active={itemSorting.orderBy==='name'} direction={itemSorting.order==='ASC'?'asc':'desc'} onClick={()=>handleSort('name')} >Name</TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>Start</StyledTableCell>
                            <StyledTableCell>End</StyledTableCell>
                            <StyledTableCell>
                              <TableSortLabel active={itemSorting.orderBy==='price'} direction={itemSorting.order==='ASC'?'asc':'desc'} onClick={()=>handleSort('price')} >Price&nbsp;(&#36;)</TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>Virtual</StyledTableCell>
                            <StyledTableCell>
                              <TableSortLabel active={itemSorting.orderBy==='status'} direction={itemSorting.order==='ASC'?'asc':'desc'} onClick={()=>handleSort('status')} >Status</TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>
                              <div style={{display:"flex",justifyContent:'flex-start',columnGap:"10px"}}>
                                <span>Actions</span>
                                {
                                  editAccess && 
                                  <Tooltip title="Make all items active">
                                    <div className='table-btn' onClick={handleAllItemsMakeActive}> <AddTaskIcon sx={{fontSize: "16px", color: "#96c440"}} /></div>
                                  </Tooltip>
                                }
                              </div>
                              
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {auctionItems.data.map((row, i) => (
                        <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.name}</StyledTableCell>
                                <StyledTableCell>{row.start_date
                                    ? `${moment(row.start_date).format(
                                        'MM/DD/YYYY'
                                    )}`
                                    : '---'}
                                </StyledTableCell>
                                <StyledTableCell>{row.end_date
                                    ? `${moment(row.end_date).format(
                                        'MM/DD/YYYY'
                                    )}`
                                    : '---'}
                                </StyledTableCell>
                                <StyledTableCell>{row.price}</StyledTableCell>
                                <StyledTableCell>{row.is_virtual ? "YES" : "NO"}</StyledTableCell>
                                <StyledTableCell>{row.status}</StyledTableCell>
                                <StyledTableCell>

                                   {
                                    editAccess && 
                                    <ItemListMenu 
                                     row={row} 
                                     handleOpenDeleteConfirm={handleOpenDeleteConfirm} 
                                     handleEdit={handleEdit} 
                                     checkStoreItemStatus={checkStoreItemStatus}
                                     handleMakeAcive={handleMakeAcive}
                                     getStoreItemArchived={getStoreItemArchived}
                                     handleArhiveItemAlert={handleArhiveItemAlert}
                                     handleBuyerModal={handleBuyerModal}
                                     handleCloneItemAlert={handleCloneItemAlert}
                                     getPageType={getPageType}
                                     itemType='auction'
                                   />
                                   }

                                {/* <div className='table_actions_cell'>
                                {auction_status && auction_status === 'live' ? 
                                  <>
                                  <span style={{fontWeight: "600"}}>
                                  <div className='table-btn' onClick={() => handleEdit(row, "auction")}> <BorderColorIcon sx={{fontSize: "16px", color: "#5EABD6"}} /> </div>
                                  </span>
                                  {row?.status==='pending'&&<div className='table-btn' onClick={() => handleMakeAcive(row.id, row)}> <AddTaskIcon sx={{fontSize: "16px", color: "#96c440"}} /></div>}
                                  </>
                                  :
                                  <>
                                  <TableButton onClick={() => handleOpenDeleteConfirm(row.id)} Icon={DeleteOutlineOutlinedIcon} color='white' backgroundColor={redColor} needText={false}/>
                                  <TableButton onClick={() => handleEdit(row, "auction")} Icon={BorderColorIcon} color={redColor} needText={false}/>
                                  {row?.status==='pending'&&
                                     <TableButton onClick={() => handleMakeAcive(row.id, row)} Icon={AddTaskIcon} color={"#96c440"} needText={false} borderColor='#96c440'/>
                                  }
                                  </>
                                  }

                                  {
                                    row?.status === 'active' ?
                                       <Tooltip title={
                                         <p style={{fontSize:'0.85rem'}}>
                                           {getStoreItemArchived(row)?.archived == '1' ? constants.ADMIN_COPY_ITEM_UNARCHIVED_ALERT : constants.ADMIN_COPY_ITEM_ARCHIVED_ALERT}
                                         </p>
                                       }>
                                          <div>
                                             <TableButton 
                                                onClick={() => handleArhiveItemAlert(row)} 
                                                Icon={row?.archived == '1' ? VisibilityOff : Visibility } 
                                                color={"#96c440"}
                                                needText={false} 
                                                borderColor='#96c440'
                                             />
                                          </div>
                                       </Tooltip>
                                    :null
                                  }

                                  {
                                    row?.status !== 'pending' &&
                                    <Tooltip title={
                                      <p style={{fontSize:'0.85rem'}}>
                                        {constants.ADMIN_COPY_ITEM_BUYER_ALERT}
                                      </p>
                                    }>
                                   <div>
                                     <TableButton 
                                        onClick={() => handleBuyerModal(row)} 
                                        Icon={ContactMail} 
                                        color={COLORS_OBJECT.color[1]}
                                        needText={false} 
                                        borderColor={COLORS_OBJECT.color[1]}
                                     />
                                   </div>
                                   </Tooltip>
                                   }

                                  <Tooltip title={
                                         <p style={{fontSize:'0.85rem'}}>
                                           {constants.ADMIN_COPY_CLONE_ITEM_ALERT.replace('event', getPageType())}
                                         </p>
                                       }>
                                          <div>
                                            <TableButton onClick={() => handleCloneItemAlert(row, "auction")} Icon={ContentPasteGo} color={redColor} needText={false}/>
                                          </div>
                                  </Tooltip>
                                  </div> */}
                                </StyledTableCell>
                        </StyledTableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 25]}
                    component="div"
                    count={auctionItems.total_count}
                    rowsPerPage={auctionLimit}
                    page={auctionPage - 1}
                    onPageChange={(e, newPage) => dispatch(changeAuctionItemPage(newPage + 1))}
                    onRowsPerPageChange={(e) => dispatch(changeAuctionItemLimit(+e.target.value))}
                />
            </Paper>: <div style={{padding: '20px 0', textAlign: "center"}}>No Auction items Found</div>)
            : 
                (
                  tableLoading  ? <LoadingModal style={{paddingLeft:0, position: 'relative', width: '100%', marginLeft: '0px', minHeight: '300px', backgroundColor: 'white' }} isOpen={true} />
                  :
                  isLoading ? <LoadingModal isOpen={true} /> :
                    storeItems && storeItems.data && storeItems.data.length > 0 ?
                    <Paper>
                    <TableContainer>
                    <div className='list-action-container'>
                    <button onClick={handleCategoryOrder} className='aitem-modal-btn aitem-white-button'>Update Category priority order</button>
                      <div  style={{padding: "10px 20px", display: "flex", gap: "20px", justifyContent: "flex-end"}}>
                      {/* <button className='aitem-modal-btn aitem-white-button' onClick={handleAllItemsMakeActive} >Make all items active</button> */}
                      <button className='aitem-modal-btn' onClick={() => handleGoPriorityOrder('store')}>Update Items priority order</button>
                        <button className='aitem-modal-btn' onClick={handleStoreCSVData}>{csvLoading ? "Loading CSV..." : "Download CSV Data"}</button>
                        {initiateStoreDownload && <CSVDownload filename='bg-store-items.csv' data={storeCSVData} target="_blank" replace={false} />}
                      </div>
                      </div>
                        <Table sx={{ minWidth: 1050 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                  <TableSortLabel active={itemSorting.orderBy==='name'} direction={itemSorting.order==='ASC'?'asc':'desc'} onClick={()=>handleSort('name')} >Name</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>Supplier</StyledTableCell>
                                <StyledTableCell>
                                  <TableSortLabel active={itemSorting.orderBy==='price'} direction={itemSorting.order==='ASC'?'asc':'desc'} onClick={()=>handleSort('price')} >Price&nbsp;(&#36;)</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                 <TableSortLabel active={itemSorting.orderBy==='quantity_in_stock'} direction={itemSorting.order==='ASC'?'asc':'desc'} onClick={()=>handleSort('quantity_in_stock')} >Quantity</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                 <TableSortLabel active={itemSorting.orderBy==='sku'} direction={itemSorting.order==='ASC'?'asc':'desc'} onClick={()=>handleSort('sku')} >SKU</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>Virtual</StyledTableCell>
                                <StyledTableCell>
                                  <TableSortLabel active={itemSorting.orderBy==='status'} direction={itemSorting.order==='ASC'?'asc':'desc'} onClick={()=>handleSort('status')} >Status</TableSortLabel>
                                </StyledTableCell>
                                {/* <StyledTableCell>Variants</StyledTableCell> */}
                                <StyledTableCell>
                                <div style={{display:"flex",justifyContent:'flex-start',columnGap:"10px"}}>
                                <span>Actions</span>
                                {
                                  editAccess &&
                                  <Tooltip title="Make all items active">
                                    <div className='table-btn' onClick={handleAllItemsMakeActive}> <AddTaskIcon sx={{fontSize: "16px", color: "#96c440"}} /></div>
                                  </Tooltip>
                                }
                              </div>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                    {
                        storeItems.data.map(row => 
                        <StyledTableRow key={row.id}>
                            <StyledTableCell sx={{maxWidth:'310px'}} >{row.name}</StyledTableCell>
                            <StyledTableCell sx={{maxWidth:'310px'}} >{row?.donor?.org_name}</StyledTableCell>
                            <StyledTableCell>{row?.store_item?.price ?? "---"}</StyledTableCell>
                            <StyledTableCell>{row?.store_item?.quantity_in_stock ?? "0"}</StyledTableCell>
                            <StyledTableCell>
                              {(row?.store_item?.store_item_actual_inventories?.length > 0) ?
                               <button className='table-btn' onClick={() => fetchVariants(row.store_item.id)} > <RemoveRedEyeOutlinedIcon sx={{fontSize: 16}} /> </button>
                                : row?.store_item.sku}</StyledTableCell>
                            <StyledTableCell>{row.is_virtual ? "YES" : "NO"}</StyledTableCell>
                            <StyledTableCell>{checkStoreItemStatus(row)}</StyledTableCell>
                            <StyledTableCell>

                            {
                              editAccess && 
                              <ItemListMenu 
                               row={row} 
                               handleOpenDeleteConfirm={handleOpenDeleteConfirm} 
                               handleEdit={handleEdit} 
                               checkStoreItemStatus={checkStoreItemStatus}
                               handleMakeAcive={handleMakeAcive}
                               getStoreItemArchived={getStoreItemArchived}
                               handleArhiveItemAlert={handleArhiveItemAlert}
                               handleBuyerModal={handleBuyerModal}
                               handleCloneItemAlert={handleCloneItemAlert}
                               getPageType={getPageType}
                               itemType='store'
                            />
                            }

                              {/* <div className='table_actions_cell'>
                            {auction_status && auction_status === 'live' ? 
                              <>

                              {row?.auction_auction_item_xrefs?.length ?
                              //// this is only for assigned items from other store/marketplace/events 
                                <TableButton 
                                  onClick={() => handleOpenDeleteConfirm(row?.id, row?.auction_auction_item_xrefs?.length > 0)} 
                                  Icon={DeleteOutlineOutlinedIcon} color='white' backgroundColor={redColor} needText={false}
                                />
                                : null
                              }
                              {!row?.auction_auction_item_xrefs?.length ? <TableButton onClick={() => handleEdit(row, "store")} Icon={BorderColorIcon} needText={false} /> : null}
                              {checkStoreItemStatus(row) === 'pending'&&
                                 <TableButton onClick={() => handleMakeAcive(row.id, row)} Icon={AddTaskIcon} color={"#96c440"} needText={false} borderColor='#96c440'/>
                                 }
                              </>
                              :
                                <>
                                <TableButton 
                                  onClick={() => handleOpenDeleteConfirm(row?.id, row?.auction_auction_item_xrefs?.length > 0)} 
                                  Icon={DeleteOutlineOutlinedIcon} color='white' backgroundColor={redColor} needText={false}
                                /> 
                                {!row?.auction_auction_item_xrefs?.length ? <TableButton onClick={() => handleEdit(row, "store")} Icon={BorderColorIcon} needText={false} /> : null }
                                {checkStoreItemStatus(row) === 'pending'&&
                                  <TableButton onClick={() => handleMakeAcive(row.id, row)} Icon={AddTaskIcon} color={"#96c440"} needText={false} borderColor='#96c440'/>
                                }
                                </>
                              }
                              {
                                  checkStoreItemStatus(row) === 'active' ?
                                      <Tooltip title={
                                        <p style={{fontSize:'0.85rem'}}>
                                          {getStoreItemArchived(row)?.archived == '1' ? constants.ADMIN_COPY_ITEM_UNARCHIVED_ALERT : constants.ADMIN_COPY_ITEM_ARCHIVED_ALERT}
                                        </p>
                                      }>
                                        <div>
                                           <TableButton 
                                              onClick={() => handleArhiveItemAlert(row)} 
                                              Icon={getStoreItemArchived(row)?.archived == '1' ? VisibilityOff : Visibility } 
                                              color={"#96c440"}
                                              needText={false} 
                                              borderColor='#96c440'
                                           />
                                        </div>
                                      </Tooltip>
                                  :null
                              }
                              {

                                !row?.auction_auction_item_xrefs?.length && row?.status !== 'pending' ? 
                                     <Tooltip title={
                                       <p style={{fontSize:'0.85rem'}}>
                                         {constants.ADMIN_COPY_ITEM_BUYER_ALERT}
                                       </p>
                                 }>
                                    <div>
                                      <TableButton 
                                         onClick={() => handleBuyerModal(row)} 
                                         Icon={ContactMail} 
                                         color={COLORS_OBJECT.color[1]}
                                         needText={false} 
                                         borderColor={COLORS_OBJECT.color[1]}
                                      />
                                    </div>
                                  </Tooltip>
                                :null
}
                               { !row?.auction_auction_item_xrefs?.length ? 
                                <Tooltip title={
                                  <p style={{fontSize:'0.85rem'}}>
                                    {constants.ADMIN_COPY_CLONE_ITEM_ALERT.replace('event', getPageType())}
                                  </p>
                                 }>
                                   <div>
                                     <TableButton onClick={() => handleCloneItemAlert(row, "store")} Icon={ContentPasteGo} color={redColor} needText={false}/>
                                   </div>
                                </Tooltip>
                                : null

                              }
                              </div> */}
                            </StyledTableCell>
                        </StyledTableRow>
                        )
                    }</TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 25]}
                    component="div"
                    count={storeItems.total_count}
                    rowsPerPage={storeLimit}
                    page={storePage - 1}
                    onPageChange={(e, newPage) => dispatch(changeStoreItemPage(newPage + 1))}
                    onRowsPerPageChange={(e) => dispatch(changeStoreItemLimit(+e.target.value))}
                />
            </Paper> : <div style={{padding: '20px 0', textAlign: "center"}}>No Store items Found</div>
                )
        }

       <Modal isOpen={variantsModal} onClose={() => setVariantsModal(false)} onDone={() => setVariantsModal(false)} title={'Variants Details'} buttonText='Close' >
       <VariantsModalComponent variants={variants} options={options} />
       </Modal>

       {/* Buyer details modal */}
       <Modal contentStyle={{width:'auto'}} isOpen={buyersModal.isOpen} onClose={() => setBuyersModal({isOpen:false, modalData:null})} title={buyersModal.title} buttonRequired={false} >
       <BuyersModal auction_id={auction_id} item={buyersModal.modalData}/>
       </Modal>

        <Alert message="Are you sure you want to delete this item?" isOpen={openConfirmAlert} type="confirm" onClose={handleCloseConfirmAlert} onConfirm={handleDeleteConfirm} />
        <Alert message={activeConfirmMessages} isOpen={openMakeActiveAlert} type="confirm" onClose={handleCloseConfirmAlert} onConfirm={handleMakeAciveConfirm} />
        <Alert message={errorMessage} isOpen={errorAlert} onClose={() => setErrorAlert(false)} type="alert" />
        <Alert message={activeConfirmMessages} isOpen={openAllActiveAlert} type="confirm" onClose={()=>{setOpenAllActiveAlert(false)}} onConfirm={handleAllitemActiveConfirmAlert} />
        <Alert message={markAllActiveSuccessMsg} isOpen={!!markAllActiveSuccessMsg} onClose={() => {setUpdated(!updated);setMarkAllActiveSuccessMsg(null)}} type="alert" />
        <Alert isOpen={successAlert.isOpen} message={successAlert.message} onClose={()=>closeAlert(false)} type={successAlert.alertType} onConfirm={()=>successAlert.confirmFunction(successAlert.confirmMetaData)} />
      </Container>
    )
}

export default ItemList
