import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material'
import React from 'react'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

const VariantsModalComponent = ({options,variants}) => {
  return (
    <div>
      <div className="variant_modal_container">
       <TableContainer>
       <Table sx={{minWidth:"100%"}} aria-label="customized table">
                        <TableHead>
                            <TableRow>                              
                                {
                                  Object.keys(options).map((variant, index) => {
                                    return (
                                      <StyledTableCell key={index} align='center'>{variant.replace(/^"|"(\s)*$/g, '')}</StyledTableCell>
                                    )
                                  })
                                }
                                <StyledTableCell align='center'>SKU</StyledTableCell>
                                <StyledTableCell align='center'>Vendor SKU</StyledTableCell>
                            </TableRow>
                        </TableHead>
                     <TableBody>
           {
            variants?.length > 0 && variants?.map((obj, index) => {
              return (
            <TableRow key={index}>           
                 { Object.keys(options).map((variant, idx) => (
                      obj && obj[variant] && <StyledTableCell key={idx} align='center' style={{textTransform: 'capitalize' }}>{obj && obj[variant]}</StyledTableCell>
                    ))
                 }
                      <StyledTableCell align='center'> {obj?.sku }</StyledTableCell>
                      <StyledTableCell align='center'> {obj?.vendor_sku_id ? obj?.vendor_sku_id : '' }</StyledTableCell>
            </TableRow>
               )
             })
           }
            </TableBody>
           </Table>
         </TableContainer>
        </div>
    </div>
  )
}

export default VariantsModalComponent
