import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    auctionItemsPage: 1,
    storeItemsPage: 1,
    auctionItemsLimit: 10,
    storeItemsLimit: 10
}

const itemsListReducer = createSlice({
    name: "itemlist/pagination",
    initialState: initialState,
    reducers: {
        changeAuctionItemPage: (state, action) => {
            state.auctionItemsPage = action.payload
        },
        changeStoreItemPage: (state, action) => {
            state.storeItemsPage = action.payload
        },
        changeAuctionItemLimit: (state, action) => {
            state.auctionItemsLimit = action.payload
            state.auctionItemsPage = 1
        },
        changeStoreItemLimit: (state, action) => {
            state.storeItemsLimit = action.payload
            state.storeItemsPage = 1
        },
        resetItemsList: () => {
            return initialState
        }
    }
})

export default itemsListReducer.reducer
export const {changeAuctionItemPage, changeStoreItemPage, resetItemsList, changeAuctionItemLimit, changeStoreItemLimit} = itemsListReducer.actions