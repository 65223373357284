import React, { useEffect, useRef,useState } from "react";
import "./ImageInput.css";
import { CircularProgress } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

const Image=({src,alt})=>{
  const [source,setSource] = useState(src);
  const handleImageLoadingError = ()=>{
    const lastIndex = src.lastIndexOf('_small');
    setSource(lastIndex !== -1 ? src.substring(0, lastIndex) : src)
  }
  return (
    <img src={source} alt={alt} onError={handleImageLoadingError} />
  )
}

const ImageInput = ({label,iButton=false,infoBubbleText="",infoSize='16px', multiple=false, sidelabel, accept="image/*", images=[], handleChange, required=false, edit=false, onDelete=() => {}, max=1, reset,setReset, loading=false, showCustomImageSelect=false, handleCustomImageSelect, activeStyle={}}) => {
let inputRef = useRef();
useEffect(()=>{
  if( reset === "reload" ){
    inputRef.current.value = null
    setReset("")
  }
},[reset])

  return (
    <div style={{margin: "10px 0", width:'100%'}}>
       {label && <label className='input-label' >{label}{(accept === 'image/*' && required) && <span style={{color: "red"}}>*</span>}
       {iButton&& <Tooltip title={<Typography sx={{fontSize:'0.85rem'}}>{infoBubbleText}</Typography>}><InfoOutlinedIcon sx={{color:'#495057c4',fontSize:infoSize, marginLeft: "4px"}} /></Tooltip>}
        <div style={{fontSize: "0.7rem", color: "gray"}}>{ sidelabel && ` (${sidelabel})`}
       </div>
      </label>}
        <div style={activeStyle} className="imageinput-container">
            {images.map((image, j) => (
                <div key={image.name} style={edit ? {position: "relative"} : {}} className={`${accept === 'image/*' ? "imageinput-image" : "imageinput-video"}`}>
                    {edit && <div style={{position: "absolute", right: 2, top: 5, backgroundColor: 'white', color: "red", width: "18px", height: "18px", borderRadius: "50%", display: 'flex', justifyContent: "center", alignItems: "center", fontSize: "20px", cursor: "pointer", fontWeight: "600", zIndex: 20}} onClick={() => onDelete(image?.name, j)}>&times;</div>}
                    {((accept === 'image/*') || (accept === ".png") )? <Image src={image.name} alt="" /> : 
                    <video width="100%" height="100%">
                        <source src={image.name} type="video/mp4" />
                    </video>}
                </div>
            ))}
            {
                max && max === images.length ? null : (
                    <div className="imageinput-file-wrapper">
                     { 
                     loading ? <CircularProgress/> :
                     <>
                    {!showCustomImageSelect ?
                       <>
                         <input ref={inputRef} onChange={handleChange} multiple={multiple} type="file" accept={accept} />
                         +
                       </>
                       :
                        <span style={{cursor:'pointer'}} onClick={handleCustomImageSelect}>
                        +
                        </span>
                    }
                     </>
                      }
                    </div>
                )
            }
        </div>
    </div>
  );
};

export default ImageInput;