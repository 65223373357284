import React, { useContext, useState } from 'react'
import Container from '../../../components/HOC/Container/Container'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material';
import authService from '../../../../services/auth-services';
import { useEffect } from 'react';
import AccessDenied from '../../../components/AccessDenied/AccessDenied';
import LoadingModal from '../../../components/LoadingModal/LoadingModal';
import { useDispatch, useSelector } from 'react-redux';
import AuthContext from '../../../../store/auth-context';
import { fethcMarketPlaceOrStore ,toggleLoading} from '../../../../redux/marketPlaceReducer';
import ShareIcon from '@mui/icons-material/Share';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import auctionServices from '../../../../services/auction-services';
import Alert from '../../../components/Alert/Alert';
import style from '../../EventDashboard/EventDashboard.module.css'
import Modal from '../../../components/Modal/Modal';
import { QRCodeCanvas } from 'qrcode.react';
import config from '../../../../config/config';
// import { resetSavedMarketPlace } from '../../../../redux/savedMarketPlaceReducer';
import {  resetDependencies,   typeEdit } from '../../../../redux/dependenciesReducer';
import { setDefaultEndDate, setDefaultStartDate } from '../../../../utils/util-functions';
import { resetItemsList } from '../../../../redux/itemsListReducer';
import constants, { COLORS_OBJECT } from '../../../../constants/en';
import TableButton from '../../../components/TableButton/TableButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {BookmarkRemoveOutlined, BookmarkAdded} from '@mui/icons-material';

const REACT_USER_APP_BASE_URL = config.config.REACT_USER_APP_BASE_URL;

const redColor = COLORS_OBJECT.color[0]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      borderBottom: "1px solid black"
  },
  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
      border: 0,
  },
}));
const MarketPlaceAndStoreTable = ({marketPlacesOrStores=[], totalMarketPlaceOrStore=0, loading=false, StringLowerName, stringCapitalName}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const ctx = useContext(AuthContext)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [marketPlacePermission, setMarketPlacePermission] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [shareModal, setShareModal] = useState(false)
  const [activeId, setActiveId] = useState(null)
  const [godMod,setGOdMod] = useState(false);
  const [actionDenied, setActionDenied] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const location = useLocation()
  const [openConfirmAlert,setOpenConfirmAlert] = useState(false);
  const [deleteEventId,setDeleteEventId] = useState(null);
  const [alert,setAlert] = useState(null)
  useEffect(() => {
    checkHasStoreMarketPlacePermission()
    checkGodPermission(true,15)
  },[])
  useEffect(() => {
    let forCharity;
    if(location.pathname.includes('/market-place')){
      forCharity = 'forMarketPlace'
    }
    else {
      forCharity = 'charity_store'
    }
    dispatch(fethcMarketPlaceOrStore({user_id:ctx.user.id, page: page, limit: limit, forCharity:forCharity}))
  },[page, limit, refresh])


  const checkHasStoreMarketPlacePermission = async() => {
    try {
      setIsLoading(true)
      let permissionId = 17
      // if(location.pathname.includes('/market-place')) permissionId=17;
      // else permissionId = 18;
      if(location.pathname.includes('/market-place')){
        const permission = await authService.checkLimitedPermissions(permissionId)
        setIsLoading(false)
        if(permission?.access){
          setMarketPlacePermission(true)
          return;
        }
      }
      else {
        setMarketPlacePermission(true)
      }
    } catch (error) {
      setIsLoading(false)
      setMarketPlacePermission(false)
    }
  }

  const checkGodPermission = async (checkGodMod, permissionId) => {
    try{
      setIsLoading(true)
      const permission = await authService.checkLimitedPermissions(15)
      permission.access && setGOdMod(true);
      setIsLoading(false);
    } catch(error){
      setGOdMod(true)
      setIsLoading(false)
    }
}

  const getButtonText = (status) => {
    let text = ''
    switch (status) {
        case 'building':
            text = 'Submit For Approval'
            break;
        case 'live':
            text = 'Pause'
            break;
        case 'paused':
            text = 'Resume'
            break;
        default:
            text = null;
    }
    return text
}
const getCurrentStatus = (status) => {
    let currentStatus = ''
    switch (status) {
        case 'building':
            currentStatus = 'Building'
            break;
        case 'live':
            currentStatus = 'Live'
            break;
        case 'inreview':
            currentStatus = 'Pending Approval'
            break;
        case 'ready':
            currentStatus = 'Ready To Launch'
            break;
        case 'complete':
            currentStatus = 'Complete'
            break;
        case 'paused':
            currentStatus = 'Paused'
            break;
        case 'preview':
            currentStatus = 'Preview'
            break
        default:
            currentStatus = '';
    }
    return currentStatus
}

   const handleEdit = (marketPlace) => {
      dispatch(typeEdit({
        auction_id: marketPlace?.id,
        cause_id: marketPlace?.cause_id,
        cause_category_id: marketPlace?.cause_category_id,
        auction_title: marketPlace?.title,
        auction_status: null,
        start_date: marketPlace?.start_date && setDefaultStartDate(marketPlace?.start_date),
        end_date: marketPlace?.end_date && setDefaultEndDate(marketPlace?.end_date),
        active: location.pathname.includes('/market-place')? 'market' : "store"

    }))
   
    localStorage.setItem("data", JSON.stringify({
     auction_id: marketPlace?.id,
     auction_title: marketPlace?.title,
     start_date: marketPlace?.start_date && setDefaultStartDate(marketPlace?.start_date),
     end_date: marketPlace?.end_date && setDefaultEndDate(marketPlace?.end_date),
     active: location.pathname.includes('/market-place')? 'market' : "store"
 }))
    if(location.pathname.includes('/market-place')){
    navigate(`/admin/market-place/edit/${marketPlace.id}`)
    }
    else{
      navigate(`/admin/store/edit/${marketPlace.id}`)
    }
   }

  const handleCreateMarketPlace = () => {
    // navigate('/admin/market-place/add')
    if(!marketPlacePermission) return setActionDenied(true)
    if(location.pathname.startsWith('/admin/market-place')) navigate('/admin/market-place/add')
    else navigate('/admin/store/add')
  }

  const updateStatus = async (row, review) => {
    // dispatch(toggleLoading())
    setIsLoading(true)

    let status = ''
    if (review === 'approve') {
        status = 'ready'
    } else if (review === 'reject') {
        status = 'building'
    } else {
        switch (row.status) {
            case 'building':
                status = 'inreview'
                break;
            case 'live':
                status = 'paused'
                break;
            case 'paused':
                status = 'live'
                break;
            default:
                status = '';
        }
    }
    if (status === '') {
      setIsLoading(false)
        return
    }
    try {
        await auctionServices.updateAuctionStatus(row.id, { status })
        setIsLoading(false)
      } catch (error) {
        setActionDenied(true)
        setIsLoading(false)
        return
    }
    setRefresh(!refresh)
}

const downloadQR = (activeId) => {
  const canvas = document.querySelector("#qrcode-canvas")       
  if (!canvas) throw new Error("<canvas> not found in the DOM")    
  const pngUrl = canvas
    .toDataURL("image/png",3)
    .replace("image/png", "image/octet-stream")
  const downloadLink = document.createElement("a")
  downloadLink.href = pngUrl
  downloadLink.download = `Event${activeId}QR code.png`
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)

}

const handleNavigate = () =>{
  if(StringLowerName === 'store'){
    navigate(`/admin/restore-store`,{state:{event_type:'store'}})
  }else{
    navigate(`/admin/restore-market_place`,{state:{event_type:'market_place'}})

  }
}
const handleDeleteEventConfirm =async () =>{
  setOpenConfirmAlert(false)
  if(!deleteEventId) return 
  dispatch(toggleLoading());
   
  await auctionServices.deleteEvent(deleteEventId).then((res)=>{
      // console.log(res);
      dispatch(toggleLoading());
      setDeleteEventId(null);
      setRefresh(!refresh)
  }).catch((err)=>{
      // console.log(err);
      setAlert(err?.response?.data?.message)
      setDeleteEventId(null);
      dispatch(toggleLoading());
  })
  

}

const handleDeleteEvent = (event) =>{
  setDeleteEventId(event?.id);
  setOpenConfirmAlert(true)
}

const handleDeleteAlertClose = () =>{
  setDeleteEventId(null);
  setOpenConfirmAlert(false)
}

const getFeaturedDetails = (row) => {
  switch (row?.featured) {
      case 1:
          return {
              text:'Unfeature',
              icon: BookmarkRemoveOutlined
          }
      case 0:
          return {
              text: 'Make Featured',
              icon: BookmarkAdded
          }
      default:
          return {
              text: 'Make Featured',
              icon: BookmarkAdded
          }
  }
}

const handleMakeFeatured = async(row) => {
  let featured = 1;
  let alertMessage = constants.ADMIN_COPY_FEATURED_ALERT.replace('Event',stringCapitalName);
  if(row?.featured === 1) {
      featured = 0
      alertMessage = constants.ADMIN_COPY_UNFEATURED_ALERT.replace('Event',stringCapitalName);
  }
  try {
      dispatch(toggleLoading())
      const updated = await auctionServices.makeEventFeatured(row?.id, featured)
      dispatch(toggleLoading())
      setRefresh(!refresh)
      setAlert(alertMessage)
  }
  catch(error) {
      dispatch(toggleLoading())
      setAlert(error.response?.data?.message || constants?.ADMIN_COPY_SOMETHING_WENT_WRONG)
  }
}

  return (
    
      <Container title={`My ${stringCapitalName}`} showButton onClickButton={handleCreateMarketPlace} buttonText={`Add new ${StringLowerName}`} style={{position: "relative"}}>       
      {
       isLoading || loading ? <LoadingModal isOpen={true} />
      //   : 
      //  !marketPlacePermission ? 
      //  <AccessDenied title={`${stringCapitalName}`} />
       :
        <>
        <div className='event_header_container'>
          <span className='calender_icon_container'><CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#34b5e5' }} /><p>{stringCapitalName}</p></span>
          { godMod && 
                <div><button className='event-submit-btn' style={{width:'180px'}} onClick={handleNavigate}>Restore {StringLowerName}</button></div>
            }
        </div>
        <hr />

        {/* {!marketPlaceEvent?.length ? <div className="aitem-submit-container">
          <button onClick={handleCreateMarketPlace}>Create Your Market Place Event</button>
        </div> : */}

        { marketPlacesOrStores && marketPlacesOrStores?.length > 0 ?
          
            <Paper>
              <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ width: "40%" }}>{stringCapitalName} Title</StyledTableCell>
                      <StyledTableCell sx={{ width: "20%" }}>Status</StyledTableCell>
                      <StyledTableCell>Manage</StyledTableCell>

                      <StyledTableCell>Share</StyledTableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {marketPlacesOrStores?.map((row, i) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell>{row.title}</StyledTableCell>
                       
                        <StyledTableCell sx={{ fontWeight: "600" }}>{getCurrentStatus(row.status)}</StyledTableCell>

                        <StyledTableCell >
                          <div style={{ display: 'flex', gap: '14px' }}>
                            {/* {(row.status === 'building' || row.status === 'paused' || row.status === 'live' || row.status === 'preview' || row.status === 'ready') && */}
                            {['inreview', 'complete'].indexOf(row.status) === -1 &&
                              <TableButton onClick={() => handleEdit(row)} Icon={BorderColorIcon} />
                            }
                            {(godMod && ['live','preview'].indexOf(row.status) === -1 ) ? <TableButton text='Delete' backgroundColor={redColor} color={'white'} onClick={() => handleDeleteEvent(row)} Icon={DeleteOutlineOutlinedIcon} /> : null}
                            
                            <div style={{ display: "flex", gap: '14px' }}>
                              {godMod && row.status === 'inreview' ? <>
                                <TableButton onClick={() => updateStatus(row, 'approve')} color={redColor} text='Approve' />
                                <TableButton onClick={() => updateStatus(row, 'reject')} backgroundColor={redColor} color={'white'} style={{border:'none'}} text='Reject' />
                              </> : null}
                              {getButtonText(row.status) && 
                               <TableButton onClick={() => updateStatus(row)} backgroundColor={redColor} color={'white'} style={{border:'none'}} text={getButtonText(row.status)} />
                              }
                              {(godMod && ['live'].includes(row.status) ) ? 
                                            <TableButton text={getFeaturedDetails(row).text} backgroundColor={'white'} color={redColor} onClick={() => handleMakeFeatured(row)} Icon={getFeaturedDetails(row).icon} /> : null}

                            </div>

                          </div>
                        </StyledTableCell>

                        <StyledTableCell>
                          {(row.status === 'live' || row.status === 'preview') && <div onClick={() => {
                            setActiveId(row?.custom_url ? row.custom_url : row.id)
                            setShareModal(true)
                          }}><ShareIcon sx={{ cursor: "pointer" }} /></div>}
                        </StyledTableCell>
                      </StyledTableRow>

                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20]}
                component="div"
                count={totalMarketPlaceOrStore}
                rowsPerPage={limit}
                page={page - 1}
                onPageChange={(e, newPage) => setPage(newPage + 1)}
                onRowsPerPageChange={(e) => { setLimit(+e.target.value); setPage(1) }}
              />
            </Paper>
           : <p>No {StringLowerName} to show</p>}
       </>}
       <Modal buttonRequired={false} isOpen={shareModal} onClose={() => setShareModal(false)} title={` Scan Qr Code to get the ${StringLowerName} link`} >
                <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                        <QRCodeCanvas
                          id="qrcode-canvas" 
                          level="H"
                          imageSettings={{
                            src: "",
                            x: undefined,
                            y: undefined,
                            height: 80,
                            width: 80,
                            excavate: true,
                          }}
                         size={300}
                         style={{ height: "auto", maxWidth: "100%", width: "35%" }}
                         value={`${REACT_USER_APP_BASE_URL}/${StringLowerName}/${activeId}`}  />
                </div>
                <div className="aitem-submit-container">
                <button onClick={() => {downloadQR(activeId)}} >Download QR Code</button>
                </div>
            </Modal>
       <Alert isOpen={actionDenied} message='You are not authorized to perform this action'
                onClose={() => setActionDenied(false)} />
      <Alert message={constants.ADMIN_COPY_EVENT_DELETE_CONFIRM.replace('event',StringLowerName)} isOpen={openConfirmAlert} type="confirm" onClose={handleDeleteAlertClose} onConfirm={handleDeleteEventConfirm} />
       <Alert isOpen={! !alert} message={alert}
                onClose={() => setAlert(null)} />
      </Container>
   
  )
}

export default MarketPlaceAndStoreTable
