import Container from "../../components/HOC/Container/Container"
import { useEffect, useState } from "react";
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import FormTextarea from "../../components/FormTextarea/FormTextarea"

import "./Terms.css"
import { useNavigate, useParams, useLocation } from "react-router-dom";
import auctionServices from "../../../services/auction-services";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import usePrompt from "../../hooks/usePrompt"
import Alert from "../../components/Alert/Alert";
import { useSelector } from "react-redux";
import AccessDenied, { LiveEvent } from "../../components/AccessDenied/AccessDenied";
import authService from "../../../services/auth-services";
import constants from "../../../constants/en";
import QuillEditor from "../../components/QuillEditor/QuillEditor";

const EditTerms=  () => {
    const {auction_title} = useSelector(state => state.dependencies)
    const [shipping_info,setShipping_info]=useState("");
    const [terms_info,setTerms_info]=useState("");
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const [showPrompt, setShowPrompt] = useState(false)
    const {auction_id} = useParams()
    const [alertMsg, setAlertMsg] = useState(null)
    const [editAccess, setEditAccess] = useState(true)
    const [isLive, setIsLive] = useState(false)
    const location = useLocation()
    const {lowerName, capitalName} = location.state
    usePrompt("Do you want to leave? You will lose your changes!", showPrompt)
    const modules = {
      toolbar: false
    }
    const fetchTermsDetails = async (auction_id) => {
      try {
        setIsLoading(true)
        const permission = await authService.checkPermissions(7, auction_id)
        if(!permission.access) {
          setEditAccess(false)
          setIsLoading(false)
          return
        }
        const response = await auctionServices.getAuctionDetailsById(auction_id);
        const Godpermission = await authService.checkPermissions(15, auction_id)
        if(response?.data?.status ==='complete' && !Godpermission.access) {
           setEditAccess(false)
          setIsLoading(false)
          return
        }
        setTerms_info(response?.data?.terms_info)
        setShipping_info(response?.data?.shipping_info)
        setIsLoading(false);
        setShowPrompt(false)
      } catch (error) {
        setShowPrompt(false)
        navigate("/admin/my-events")
      }
    }

    useEffect(() => {
      if(!auction_id) return
      fetchTermsDetails(auction_id)
    }, [])

    const updateEventTerms = async (e) => {
        e.preventDefault()
        setIsLoading(true);
        try {
          await auctionServices.updateAuctionTermsInfo(auction_id, {
            terms_info: terms_info,
            shipping_info: shipping_info
          })
          setShowPrompt(false)
          setIsLoading(false);
          setAlertMsg(constants.ADMIN_COPY_EDIT_TERMS_UPDATE_SUCCESS)
        } catch (error) {
          setShowPrompt(false)
          setAlertMsg(constants.ADMIN_COPY_EDIT_TERMS_UPDATE_ERROR);
          setIsLoading(false);
        }
      }

      const handleTermsInfoChange = (e) => {
        setShowPrompt(true)
        setTerms_info(e)
      }
      const handleShippingInfoChange = (e) => {
        setShowPrompt(true)
        setShipping_info(e.target.value)
      }

    return(
        <Container title={`Edit Your ${capitalName} Terms And Conditions`} style={{position: "relative"}}>
            {auction_id && !editAccess ? <AccessDenied title="Edit Terms" /> :
            // isLive ? <LiveEvent /> : (
              <>
                <div className="event-title"> <EventNoteOutlinedIcon sx={{ fontSize: 18, marginRight: "3px", color: "skyblue" }} />
                 {auction_title}
                </div>
                <hr style={{margin: 0, marginBottom: "10px"}} />
                    <div className='row' style={{ justifyContent: 'center' }}>
                        <form onSubmit={updateEventTerms} className='col-md-8 col-lg-9'>
                            {/* <FormTextarea rows={4} required label={`Add your ${capitalName} Terms and Conditions`}value={terms_info} onChange={handleTermsInfoChange} placeholder={`Please enter ${lowerName} specific terms and conditions.`} maxsize={2000}/> */}
                            <QuillEditor
                               onChange={handleTermsInfoChange}
                               value={terms_info}
                               modules={modules}
                               placeholder={`Please enter ${lowerName} specific terms and conditions.`}
                               label={`Add your ${capitalName} Terms and Conditions`}
                             />
                            <FormTextarea rows={5} label={` Add Your ${capitalName} Shipping Information`} value={shipping_info} onChange={handleShippingInfoChange} placeholder={"Add any other details here like shipping,\nwhere to pick up items at end of event,\nreturn policies, etc."} maxsize={2000} />
                            <div >
                                <button style={{width:'100%'}} className="aitem-modal-btn" type="submit">Update Terms</button>
                            </div>
                        </form>
                </div>
              <div style={{fontSize: "0.9rem", marginTop: "20px", fontWeight: "600"}}><span style={{color: "red"}}>*</span> - required fields</div>
              <Alert isOpen={!!alertMsg} message={alertMsg} onClose={() => setAlertMsg(null)} />
              </>
            // )
            }
          <LoadingModal isOpen={isLoading} />
        </Container>
    )
}

export default EditTerms