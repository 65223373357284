import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import auctionServices from '../../../services/auction-services'
import Container from '../../components/HOC/Container/Container'
import { SortableContainer, SortableElement } from "@rexxars/react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import "./ItemsOrderManagement.css"
import usePrompt from "../../hooks/usePrompt"
import moment from "moment"
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import Alert from '../../components/Alert/Alert'
import LoadingModal from '../../components/LoadingModal/LoadingModal'
import { COLORS_OBJECT } from '../../../constants/en'
const redColor = COLORS_OBJECT.color[0]
const SortableItem = SortableElement(({ item }) => {
    return (
        <div className='items-drag-card'>
            <div className='items-drag-card-img'>
                <img src={item.assets && item.assets.length > 0 && item.assets.find(i => i.context_type === 'portrait')?.name} alt="" />
            </div>
            <div className='items-drag-card-content'>
                <div className='items-drag-card-header'>{item.name}</div>
                <div style={{marginTop: "10px", fontWeight: 600, fontSize: "0.9rem", display: "flex", gap: "30px"}}>
                    <div>Price: ${item.store_item_id ? item.store_item.price : item.price}</div>
                    {
                        !item.store_item_id && (
                            <>
                                <div>Start Date: {moment(item.start_date).format('MMMM Do YYYY, h:mm a')}</div>
                                <div>End Date: {moment(item.end_date).format('MMMM Do YYYY, h:mm a')}</div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
});

const SortableList = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((item, index) => (
        <SortableItem key={`item-${item.id}`} index={index} item={item} />
      ))}
    </div>
  );
});

const SortableComponent = ({items, setItems}) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(items => arrayMoveImmutable(items, oldIndex, newIndex));
  };
  return (
    <>
      <SortableList items={items} onSortEnd={onSortEnd} lockAxis="y" />
    </>
  );
};

const ItemsOrderManagement = () => {
  const {auction_id} = useSelector(state => state.dependencies)
  const [items, setItems] = useState([])
  const [showPrompt, setShowPrompt] = useState(true)
  const { state } = useLocation();
  const [currentTab, setCurrentTab] = useState(state?.from === "auction" ? "auction_item" : "store_item")
  const [loading, setLoading] = useState(true)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [navigateHandle, setNavigateHandle] = useState('continue')
  const location = useLocation().pathname
  const navigate = useNavigate()

//   usePrompt("Do you want to leave? You will lose your changes! Please Save your changes.", showPrompt)

  useEffect(() => {
    if(!auction_id) {
        alert("No event found!")
        navigate("/admin/my-events")
        return
    }

    if(currentTab !== 'auction_item' && currentTab !== 'store_item') {
        navigate("/admin/items/order/auction_item")
        return
    }
  }, [currentTab])

  const getEventType=(path=location)=>{
    switch (path) {
      case '/admin/items/order':
        return 'event'
      case '/admin/market-place/items/order':
        return 'market-place'
      case '/admin/store/items/order':
        return 'store'
      default:
        return 'event'
    }
}

  const fetchAllAuctionItems = async () => {
    setLoading(true)
    if(currentTab === 'auction_item') {
        const data = await auctionServices.getAuctionItemsByAuctionId(auction_id, null, null, true)
        setItems(data.data)
    } else if (currentTab === 'store_item') {
        const data = await auctionServices.getStoreItemsByAuctionId(auction_id, null, null, true)
        setItems(data.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    if(!auction_id) return
    fetchAllAuctionItems()
  }, [currentTab])

  const onSaveHandler = async (navAction) => {
    setLoading(true)
    setNavigateHandle(navAction)
    const constructArray = items.map((item, index) => {
      return {
        id: item.id,
        display_order: index + 1
      }
    });
    await auctionServices.updateItemsDisplayOrder(auction_id, constructArray)
    setLoading(false)
    setOpenConfirm(true)
    setShowPrompt(false)
  };

  const handleNavigate = () => {
    setOpenConfirm(false)
    if(navigateHandle === 'back') {
      let navigateValue = ''
      switch(getEventType()) {
        case 'event':
          navigateValue = '';
          break;
        case 'store': case 'market-place':
          navigateValue = `${getEventType()}/`
          break;
          default:
            navigateValue = ''
      }
      // navigate('/admin/items')
      navigate(`/admin/${navigateValue}items`)
    }
  }

  return (
    <Container title='Items Order' style={{position: "relative", padding: "10px"}}>
        <div className='items-navtab-radio'>
        <div style={{fontWeight: "600"}}>Select the type of item you want to sort.</div>
        <RadioGroup row value={currentTab} onChange={(e) =>  setCurrentTab(e.target.value)}>
          {getEventType() !== 'store' && 
            <FormControlLabel
              sx={{".MuiFormControlLabel-label": {fontSize: "16px", fontWeight: 600, fontFamily: "AvenirNext"}}}
              value="auction_item"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,},}} />}
              label="Auction Item"
            />
           }
            <FormControlLabel
              sx={{".MuiFormControlLabel-label": {fontSize: "16px", fontWeight: 600, fontFamily: "AvenirNext"}}}
              value="store_item"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,},}} />}
              label="Store Item"
            />
        </RadioGroup>
        <button type="button" onClick={() => navigate('/admin/items')} style={{position:'absolute', right:'18px'}} className='aitem-modal-btn' >Add / Edit Items</button>
        </div>
        {
          items?.length ? 
          <>
          <SortableComponent items={items} setItems={setItems} />
          <div className='event-two-inputs'>
            <button className='aitem-modal-btn aitem-white-button' style={{width: "100%", fontSize: "1.1rem"}} onClick={() => onSaveHandler('back')}>Save and Go Back</button>
            <button className='aitem-modal-btn' style={{width: "100%", fontSize: "1.1rem"}} onClick={() => onSaveHandler('continue')}>Save and Continue Editing</button>
          </div>
          </>
          :
          <h5 className='no_items'>No products to show.</h5>
        }
        <LoadingModal isOpen={loading} />
        <Alert isOpen={openConfirm} message="Items Order updated successfully." onClose={handleNavigate}/>
    </Container>
  )
}

export default ItemsOrderManagement