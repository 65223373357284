import React, { useEffect, useState } from 'react'
import Input from "../../../components/Input/Input"
import FormTextarea from "../../../components/FormTextarea/FormTextarea"
import DropDown from '../../../components/DropDown/DropDown'
import ImageInput from '../../../components/ImageInput/ImageInput'
import auctionServices, { reqEditVirtualItem, deleteVirtualAsset } from '../../../../services/auction-services'
import checkImageBeforeUpload from "../../../../utils/checkImageBeforeUpload"
import LoadingModal from "../../../components/LoadingModal/LoadingModal"
import "./StoreItem.css"
import Modal from '../../../components/Modal/Modal'
import VaraintsModalContent from './VaraintsModalContent'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Checkbox, FormControlLabel, Radio, Tooltip, Typography } from '@mui/material'
import Alert from "../../../components/Alert/Alert"
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import usePrompt from '../../../hooks/usePrompt'
import { RadioGroup } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import causeServices from '../../../../services/cause-services'
import Multiselect from 'multiselect-react-dropdown';
import OptionsModalContent from './OptionsModalContent'
import { useRef } from 'react'
import constants, { COLORS_OBJECT } from '../../../../constants/en'
import RadioGroupComponent from '../../../components/RadioGroup/RadioGroup'
import EditVariants from '../../../components/EditVariants/EditVariants'
import AIGeneratedData from '../../../components/AIGeneratedData/AIGeneratedData'
import { checkVendorSkuOfVariants } from '../../../../utils/util-functions'
import logger from '../../../../utils/logger'

const filename = 'Items/StoreItemEdit.jsx'

const redColor = COLORS_OBJECT.color[0]

const variantCombination = ([head, ...[tailHead, ...tailTail]]) => {
  if (!tailHead) return head;

  const combined = tailHead.reduce((acc, x) => {
    return acc.concat(head.map((h) => `${h}/${x}`));
  }, []);
  return variantCombination([combined, ...tailTail]);
};

const getExtention = (file_name) => file_name ? file_name?.split('.')[file_name.split('.')?.length - 1] : undefined;

const initialData = {
    name: "",
    description: "",
    short_description: "",
    auction_id: null,
    auction_item_category_id: null,
    custom_category_id: null,
    category_ids: [],
    custom_category_ids: [],
    donor_id: null,
    upc: "",
    width: "",
    height: "",
    length: "",
    weight: "",
    is_virtual: 0,
    sell_when_out_of_stock: 0,
    store_item: {
      charge_tax: 0,
      list_price: "",
      price: "",
      quantity_in_stock: 0,
      track_inventory: 1,
      vendor_sku_id:'',
      upc: ''
    },
    options: {},
    variants: [],
    auction_item_amount_given_dialog:null,
    show_stock_availability: true,
}

const StoreItem = ({categories, reload, godAccess=false}) => {
  const {auction_id, item_id} = useParams()
  const [storeItem, setStoreItem] = useState(initialData);

  const [itemType, setItemType] = useState("physical")
  const [openShipping, setOpenShipping] = useState(false)

  const [showVariants, setShowVariants] = useState(false)
  const [showOptions, setShowOptions] = useState(false)

  const [localVariants, setLocalVariants] = useState([])

  const [imageObjects1, setImageObjects1] = useState("")
  const [imageObjects2, setImageObjects2] = useState([])
  // const [portraitImage, setPortraitImage] = useState(null);
  const [landscapeImages, setLandscapeImages] = useState([]);
  // const [portraitImageLoading, setPortraitImageLoading] = useState(false)
  const [landscapeImageLoading, setLandscapeImageLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  // const [virtualForm, setVirtualForm] = useState(null)
  const [isFlatRate, setIsFlatRate] = useState(false)
  const [isTaxable, setIsTaxable] = useState(false)
  const [hasUPC, setHasUPC] = useState(false)
  const [formError, setFormError] = useState(null)
  const navigate = useNavigate()
  const [showPrompt, setShowPrompt] = useState(false)
  const [openDonor, setOpenDonor] = useState(false)
  const [donorsList, setDonorsList] = useState([])
  const [selectedDonor, setSelectedDonor] = useState(null)
  const [updateDonors, setUpdateDonors] = useState(true)
  const [resetLocalVariants, setResetLocalVariants] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState([])
  const [localDonor, setLocalDonor] = useState({
    "org_name": "",
    "about": ""
  })
  const [selectedItemType, setSelectedItemType] = useState(null)
  const [navigateAction, setNavigateAction] = useState('another')

  const [shipmentType,setShipmentType]=useState(false)
  const [ediShipment, setEdiShipment] = useState(false);
  const [pickupType,setPickupType]=useState(false)
  const [allowMultiple,setAllowMultiple]=useState(true)
  const [preSelectedCauses,setPreSelectedCauses]= useState([])
  const [defaultCauses,setDefaultCauses] = useState(null)
  const [selectedDefaultCauseID,setSelectedDefaultCauseId] = useState(null)
  const [causeId,setCauseId] = useState(null)
  const location = useLocation()
  const [whiteborderPortraitAlert,setWhiteborderPortraitAlert] = useState(null);
  const [whiteborderLandScapeAlert,setWhiteborderLandScapeAlert] = useState(null);
  const [portraitEvent,setPortraitEvent] = useState(null);
  const [landScapeEvents,setLandScapeEvents] =useState(null) 
  const [categorySelectDisable, setCategorySelectDisable] = useState(false)

  const [variants, setVariants] = useState([])
  const [options, setOptions] = useState([])
  const [variantDetails, setVairantDetails] = useState({})// this state include variant id.
  const [editVariantsModal, setEditVariantsModal] = useState(false)
  const [continueAlert, setContinueAlert] = useState({isOpen:false, message:'', type:''})
  const [savedQuantity, setSavedQuantity] = useState(0)
  let forCharity=false
  if(location.pathname.startsWith('/admin/market-place/items')){
    forCharity=true
  }

  const [showAmountDialog, setShowAmountDialog] = useState(true)

  const [aiAnalysis, setAiAnalysis] = useState({
    modalIsOpen:false,
    aiData:null,
    isUsed:false
  })

  useEffect(() => {
    if(!landscapeImages?.length && !imageObjects2?.length) {
      setAiAnalysis({...aiAnalysis, isUsed:false})
    }
  },[landscapeImages, imageObjects2])

//   useEffect(()=>{
//     if(storeItem?.is_virtual===0 ){
//     //  storeItem?.fulfilment?.name==='advatix' ? setShipmentType(false):setShipmentType(true) 
//      storeItem?.fulfilment?.allow_multiple==0 ? setAllowMultiple(false):setAllowMultiple(true)
//     }
//  },[storeItem])

  usePrompt("Do you want to leave? You will lose your changes!", showPrompt)
  const [virtualFile,setVirtualFile]=useState(false);
  const [virtualText,setVirtualText]=useState("");
  const [virtualFiles,setVirtualFiles]=useState([]);
  const [virtualTextUpdated,setVirtualTextUpdated]=useState(false);
  const [newVirtualFiles,setNewVirtualFiles]=useState([]);
  const [virtualTextDocID,setVirtualTextDocID]=useState(null)
  const [canChooseCause,setCanChooseCause] = useState(false);
  const [assignedcauses,setAssignedCauses] = useState([]);
  let [selectedCauses,setSelectedCauses] = useState([]);
  const [unAssignedCauses,setUnassignedCauses] = useState([]);
  const [removeCauseModal,setRemoveCauseModal] = useState(false);
  const [categoryXrefData, setCategoryXrefData] = useState([]);
  const [optionsUpdated, setOptionsUpdated] = useState(false);
  const [newOptionsAdded, setNewOptionsAdded] = useState(false);

  let can_choose_cause = useRef();

  const handleDataChange = (e) => {
    setShowPrompt(true)
    setStoreItem({
      ...storeItem,
      [e.target.name]: e.target.value
    })
  }

  const handleStoreItemChange = (e) => {
    setShowPrompt(true)
    setStoreItem({
        ...storeItem,
        store_item: {
            ...storeItem.store_item,
            [e.target.name]: e.target.value
        }
    })
  }

  const fetchStoreItem = async () => {
    try {
      
    const data = await auctionServices.getEventItemById(auction_id, item_id)
    const item = data.data
    setStoreItem({
      ...storeItem,
      ...item
    })

    setSavedQuantity(item?.store_item?.quantity_in_stock)

    if(item?.auction?.cause && item?.auction?.cause?.id) {
      setSelectedDefaultCauseId([{label:item?.auction?.cause?.legal_name, value:item?.auction?.cause?.id}])
      setCauseId(item?.auction?.cause?.id)
    }

    if(item?.variants?.length) {
      setVariants(item?.variants)
    }
    if(Object.keys(item?.options)?.length) {
      setOptions(item?.options)
    }
    if(Object.keys(item.variantDetails)?.length) {
      setVairantDetails(item.variantDetails)
    }

    if(item?.auction_item_category_xrefs?.length) {
      setCategoryXrefData(item?.auction_item_category_xrefs)
    }
    if(item?.auction_item_amount_given_dialog === 1){
      setShowAmountDialog(true)
    }
    else{
      setShowAmountDialog(false)
    }
    if(item?.is_virtual == 0) {
        setItemType("physical")
        setSelectedItemType({value: "physical", label: "Physical Product"})
        if(item?.fulfilment?.name === 'advatix') {
          setShipmentType(false);
          setEdiShipment(false);
         }
         else {
          if(item?.fulfilment?.name === 'edi') {
            setEdiShipment(true);
            setShipmentType(false);
          }
          else {
            setShipmentType(true);
            setEdiShipment(false);
          }
         }
         if(item?.fulfilment?.name==='self' && item?.fulfilment?.pickup_type==='pickup'){
          setPickupType(true)
        }
        item?.fulfilment?.allow_multiple==0 ? setAllowMultiple(false):setAllowMultiple(true)
    } else {
      setItemType("virtual")
      setSelectedItemType({value: "virtual", label: "Virtual Product"});
      setVirtualFiles(item?.virtual_doc?.slice(0,item?.virtual_doc.length-1));
      let files = item?.virtual_doc?.filter((item,i)=> item?.asset_type !== "instructions");
      if(files?.length) {
        setVirtualFiles(files)
      }
      let textData = item?.virtual_doc?.filter((item,i)=> item?.asset_type === "instructions");
      if(textData?.length) {
        setVirtualText(textData[0]?.name)
        setVirtualTextDocID(textData[0]?.id)
      }
    }
    if(item?.can_choose_cause === 1){
      setCanChooseCause(true)
      can_choose_cause.current = true
    }else{
      can_choose_cause.current = false
    }
    if(item?.is_flat_rate_shipping === 1) {
        setIsFlatRate(true)
    }
    if(item?.store_item?.upc) {
        setHasUPC(true)
    }
    if(item?.store_item?.charge_tax) {
      setIsTaxable(true)
    }
    setLandscapeImages(item?.assets.filter(img=>img.context_type==='portrait'))
    // setPortraitImage(item?.assets.filter(img=>img.context_type==='portrait'))
    }catch(error) {
      logger(filename,'Error found on Editing Store Item', error)
    }
  }

  const getAssignedCauses= async () =>{
    try {
      const data= await causeServices.getCausesRelatedtoItem(item_id)
      const causes = data?.data.map((obj,i)=>{
        return {
          key:obj.cause.legal_name
          ,value:obj.cause_id}
      })
      setPreSelectedCauses(causes)
      setAssignedCauses(data?.data)
      if( !can_choose_cause.current && data?.data.length === 1){
        setSelectedDefaultCauseId([{label:data?.data[0]?.cause?.legal_name,value:data?.data[0]?.cause_id}])
        setCauseId(data?.data[0]?.cause_id)
    }
    }catch(error) {} 
   }
 
   const getUnAssignedCauses = async () =>{
    try {
      const data= await causeServices.getCausesNotRelatedtoItem(item_id)
      let newCauses = data?.data?.map((obj,i)=>{
            return {
              key:obj.cause.legal_name
              ,value:obj.cause_id}
          })
        
       setUnassignedCauses(newCauses)
    }catch(error) {}
   }


  // useEffect(() => {
  //   if(!selectedCategory) {
  //     if(storeItem.auction_item_category_id) {
  //       categories.forEach(c => {
  //         if(c.value.id === storeItem.auction_item_category_id && c.value.type === 'global') {
  //           setSelectedCategory(c)
  //         }
  //       })
  //     }
  //     else if(storeItem.custom_category_id) {
  //       categories.forEach(c => {
  //         if(c.value.id === storeItem.custom_category_id && c.value.type === 'custom') {
  //           setSelectedCategory(c)
  //         }
  //       })
  //     }
  //   }
  // }, [categories, storeItem.auction_item_category_id, storeItem.custom_category_id,virtualFiles])

  useEffect(() => {
    if(categoryXrefData?.length) {
    categoryXrefData?.map((category) => {
      if((category?.category_id && category?.category_id === 16) || storeItem.category_ids?.includes(16) ) {
        setStoreItem((prevStoreItem) => ({
          ...prevStoreItem,
          category_ids: [16],
          custom_category_ids: []
        }))
       setSelectedCategory(categories.filter((cat)=> cat.value === 16)) 
       setCategorySelectDisable(true)
      }
      else {
        setCategorySelectDisable(false)
        if(category?.category_id) {
          categories.forEach(cat => {
            if (cat?.value === category?.category_id && cat?.type === 'global' && !storeItem.category_ids?.includes(cat.value)) {
              setSelectedCategory((prevSelectedCategory) => [
                ...prevSelectedCategory,
                cat,
              ])
              setStoreItem((prevStoreItem) => ({
                ...prevStoreItem,
                category_ids: [...prevStoreItem.category_ids, cat.value],
              }))
            }
          })
        }
        if(category?.custom_category_id) {
          categories.forEach(cat => {
            if (cat.value === category?.custom_category_id && cat?.type === 'custom' && !storeItem.custom_category_ids?.includes(cat.value)) {
              setSelectedCategory((prevSelectedCategory) => [
                ...prevSelectedCategory,
                cat,
              ])
              setStoreItem((prevStoreItem) => ({
                ...prevStoreItem,
                custom_category_ids: [...prevStoreItem.custom_category_ids, cat.value],
              }))
            }
          })
        }
      }
    })
  }
  else {

    if(storeItem.auction_item_category_id) {
       const newCategories = categories?.filter((cat) => cat.value === storeItem.auction_item_category_id)
       setSelectedCategory(newCategories)
        setStoreItem({
          ...storeItem,
          category_ids: [storeItem.auction_item_category_id]
        })
        if(storeItem.auction_item_category_id === 16) setCategorySelectDisable(true)
        else setCategorySelectDisable(false)
    }
    if(storeItem.custom_category_id) {
      const newCategories = categories?.filter((cat) => cat.value === storeItem.custom_category_id)
      setSelectedCategory(newCategories)
      setStoreItem({
        ...storeItem,
        custom_category_ids: [storeItem.custom_category_id]
      })
    }
  }
  }, [categories, categoryXrefData, virtualFiles])

  useEffect(()=>{
    if(Object.keys(storeItem.options).length > 0) {
        const combination = variantCombination(Object.values(storeItem.options));
        const obj = [];
        const keys = Object.keys(storeItem.options);
        combination?.forEach((c) => {
            const values = c.split("/");
            let res = {};
            keys.forEach((element, index) => {
            res[element] = values[index];
            });
            res['index'] = ""
            res["quantity_in_stock"] = "";
            res["key"] = c;
            
            obj.push(res);
        });
        // console.log(storeItem?.variants, "for checking purpose")
        // console.log(obj, 'obj')
        if(storeItem?.variants?.length !== obj?.length) return;
        storeItem?.variants?.forEach((v,vdx)=>{
            obj[vdx]["quantity_in_stock"] = v?.quantity_in_stock;
            obj[vdx]["sku"] = v?.sku
            obj[vdx]['upc'] = v?.upc;
            obj[vdx]["vendor_sku_id"] = v?.vendor_sku_id;
            obj[vdx]['index'] = vdx
        });
        setLocalVariants(obj)
    }
    },[storeItem.options, resetLocalVariants]);

  useEffect(() => {
    fetchStoreItem()
    getAssignedCauses()
  }, [])

  useEffect(()=>{
    (async () => {
      const data = await causeServices.getUserCauses();
      setDefaultCauses(data?.data.map((obj,i)=>{
        return {
          label: obj.cause.legal_name,
          value: obj.cause_id
        }
      }))
    })()
    getUnAssignedCauses()
  },[assignedcauses])
  useEffect(() => {
    donorsList.forEach(i => {
      if(i.value === storeItem.donor_id)
      setSelectedDonor(i)
  })
    }, [storeItem,donorsList])

    const handlefileChange =(e)=>{
      if((e.target.files.length + virtualFiles.length) > 5 ){
        alert(constants.ADMIN_COPY_ITEM_VIRTUAL_FILE_MAX)
        return
      }
      else{
        setNewVirtualFiles((prev)=>{
          return [...prev,...e.target.files]
         })
      }
    }

    const createVirtualAsset = (files) =>{
      const form = new FormData();
      let type = [];
      let filename=[];
     files.length > 0 && files.forEach(obj => {
        const fln = obj.name;
        const ext = getExtention(fln)
       form.append("asset", obj)
       type.push(ext);
       filename.push(obj.name)
     });
     form.append("type",JSON.stringify(type))
     form.append("file_name",JSON.stringify(filename))
   return form;
  } 

//   const handlePortraitImage = async (e,confirm) => {
//     try{
//       if(!e.target.files[0]) return
//     setShowPrompt(true)
//     setPortraitImageLoading(true)
//     const image = e.target.files[0]
//     if(image.size > 5000000) {
//       setFormError(constants.ADMIN_COPY_ITEM_IMG_FILE_SIZE);
//       setPortraitImageLoading(false)
//       return
//     }
//     const imageUrl = URL.createObjectURL(image)
//     const {width, height} = await checkImageBeforeUpload(imageUrl)
//     if(width < 300 || height < 300) {
//       setFormError(constants.ADMIN_COPY_ITEM_IMG_POTRAIT_SIZE)
//       setPortraitImageLoading(false)
//       return
//     }
//     if(!confirm){
//       if(width/height < 0.98 || width/height > 1){
//         setPortraitEvent(e);
//         setPortraitImageLoading(false)
//         setWhiteborderPortraitAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','1:1'));
//         return
//       }
//     }else{
//       setPortraitEvent(null)
//     }
//     const formData = new FormData()
//     formData.append("image", image)
//     formData.append("width", 300)
//     formData.append("height", 300)
//     const preProcessedImage = await auctionServices.imagePreProcess(formData)
//     setPortraitImage([{name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img'}])
//     const uint8 = new Uint8Array(preProcessedImage.buffer.data)
//     const newBlob = new Blob([uint8], {type: "image/jpeg"})
//     setImageObjects1(newBlob)
//     setPortraitImageLoading(false)
//     }
//     catch(error){
//     setPortraitImageLoading(false)
//     }
//   }


  const handleLandscapeImage = async (e,confirm) => {
    try{
      if(e.target.files.length === 0) return
    setShowPrompt(true)
    setLandscapeImageLoading(true)
    const filesUrl = []
    const selectedFiles = []
    const targetFilesObj = [...e.target.files]
    const preProcessedFiles = []
    if(targetFilesObj.length > 4 || landscapeImages.length === 4 || (landscapeImages.length + targetFilesObj.length) > 4) {
      setLandscapeImageLoading(false)
      setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE_MAX);
      return
    }
    if(targetFilesObj.every((currentValue) => currentValue.size <= 5000000)) {
      targetFilesObj.map((file) => {
        return filesUrl.push({name: URL.createObjectURL(file), type: 'img', local: true});
      });

      let invalidImage = false;
      let invalidAspectRatio = false
      for(const url of filesUrl) {
        const {width, height} = await checkImageBeforeUpload(url.name)
        if(width < 600 || height < 600) {
          invalidImage = true
        }
        if(width/height < 0.98 || width/height > 1){
          invalidAspectRatio = true
        }
      }
      if(invalidImage) {
        setLandscapeImageLoading(false)
        setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE_MAX_SIZE)
        return
      }
      if(!confirm){
        if(invalidAspectRatio){
          setLandScapeEvents(e);
          setLandscapeImageLoading(false);
          setWhiteborderLandScapeAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','1:1'));
          return
        }
      }else{
        setLandScapeEvents(null);
      }

      for(const image of targetFilesObj) {
        const formData = new FormData()
        formData.append("image", image)
        formData.append("width", 1024)
        formData.append("height", 1024)
        const preProcessedImage = await auctionServices.imagePreProcess(formData)
        selectedFiles.push({name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img', local: true});
        const uint8 = new Uint8Array(preProcessedImage.buffer.data)
        const newBlob = new Blob([uint8], {type: "image/jpeg"})
        preProcessedFiles.push({blob: newBlob, url: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`})
      }

      setLandscapeImages([...landscapeImages, ...selectedFiles])
      setImageObjects2([...imageObjects2, ...preProcessedFiles])
      setLandscapeImageLoading(false)
    } else {
      setLandscapeImageLoading(false)
      setFormError(constants.ADMIN_COPY_ITEM_IMG_FILE_SIZE);
      return
    }
    }catch(error){
      setLandscapeImageLoading(false)
    }
  }

  async function addDonors() {
    try {
      const data = await auctionServices.getdonorsListbyAuctionId(auction_id)
      setDonorsList(data.donors.map((i) => {
        if(i.email) {
          return {
            label: `${i.contact_firstname} ${i.contact_lastname} - ${i.org_name}`,
            value: i.id,
            sps_edi_trading_partner_id: i?.sps_edi_trading_partner_id
          }
        }
        return {
          label: i.org_name, value: i.id, sps_edi_trading_partner_id: i?.sps_edi_trading_partner_id
        }
      }));
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    addDonors()
  }, [updateDonors])

  const updateVariants = (newVariants) => {
    setStoreItem({
      ...storeItem,
      variants: newVariants,
    });
  };

  const onSubmitVariants = () => {
    updateVariants(localVariants)
    setShowVariants(false)
  }

  const handleCloseVariants = () => {
    setShowVariants(false)
    setResetLocalVariants(!resetLocalVariants)
  }

  const handelRemoveCause = async (cause_id) =>{
    const data = await causeServices.removeCauseFromItem(item_id,cause_id);
    setAssignedCauses(assignedcauses.filter((cause,i)=> cause.cause_id !== cause_id));
    setPreSelectedCauses(preSelectedCauses.filter((obj,i)=>obj.value !== cause_id))
  }

  const prepareSubmitData = (item) => {
    let data = JSON.parse(JSON.stringify(item))
    data.name=data.name?.replace(/\s+/g, " ").trim();
    data.custom_category_ids = data.custom_category_ids;
    data.category_ids = data.category_ids;
    data.price = +data.price;
    data.list_price=+data.list_price;
    data.auction_id = +data.auction_id
    data.list_price = +data.list_price;
    data.show_stock_availability = item?.show_stock_availability ? 1 : 0
    // data.quantity_in_stock = +data?.store_item?.quantity_in_stock;
    if(itemType === 'physical' && (!shipmentType && !ediShipment)) {
      // console.log(godAccess , +data?.store_item?.quantity_in_stock , +savedQuantity, "godAccess && +data?.store_item?.quantity_in_stock !== +savedQuantity")
      ///if there is no godAccess and item is in advatix and there is change in quantity of storeitem. We donot take that change we just update it with the saved quantity
      if(godAccess && +data?.store_item?.quantity_in_stock !== +savedQuantity) {
        data.store_item.quantity_in_stock = +data?.store_item?.quantity_in_stock;
      }
      else {
        data.store_item.quantity_in_stock = +savedQuantity;
      }
    }
    else {
      data.store_item.quantity_in_stock = +data?.store_item?.quantity_in_stock;
    }
    data.store_item.vendor_sku_id = storeItem?.store_item?.vendor_sku_id && storeItem?.store_item?.vendor_sku_id !== '' ? storeItem?.store_item?.vendor_sku_id : null;
    data.auction_item_amount_given_dialog = showAmountDialog ? 1 : 0
    if(itemType === 'physical') {
      data.is_flat_rate_shipping = isFlatRate ? 1 : 0
      data.store_item.charge_tax = isTaxable ? 1 : 0
      data.fulfilment_name=shipmentType?'self': ediShipment ? 'edi' : 'advatix';
      data.pickup_type=pickupType?'pickup':'ship';
      data.allow_multiple=allowMultiple?1:0;
    } else {
      data.is_flat_rate_shipping = 0
      data.fulfilment_name='virtual';
      data.pickup_type='virtual';
      data.allow_multiple=1;
    }
    data.is_virtual = itemType !== 'physical' ? 1 : 0;
    data.height = 0;
    data.weight = (itemType === 'physical' && !pickupType) ? +data.weight : 0;
    data.length = 0;
    data.width  = 0;
    data.variants = data.variants.map((variant, index) => {
      if (variant.price === "") {
        variant.price = data.price;
      }
      if (variant.list_price === "") {
        variant.list_price = 0;
      }
      if(itemType === 'physical' && (!shipmentType && !ediShipment)) {
        if(!godAccess && (variant.quantity_in_stock !== variants[index]?.quantity_in_stock) ) {
          ///if there is no godAccess and item is in advatix and there is change in quantity of varaint. We donot take that change we just update it with the saved quantity
          variant.quantity_in_stock = variants[index]?.quantity_in_stock;
        }
      }
      return variant;
    });
    if(newOptionsAdded) {
      data.options = data?.options;
    }
    else {
      delete data.options
    }
    if(data.upc.length < 2) {
      delete data.upc
    }
    if(shipmentType) {
    data.variants = data.variants.filter(
      (variant) => variant.quantity_in_stock !== ""
    );
    }
    data.causes = JSON.stringify(selectedCauses)
    data.can_choose_cause = (canChooseCause ) ? 1 : 0
    data.cause_id = causeId
    return data
  }

  function handleQtyCheck(variants,qty) {
    //returns true === 'error' or false 'safe'
    // if(storeItem.options.length === 0) return false
    if(Object.keys(storeItem.options).length === 0) return false
    let v_total = 0;
    if(!variants.length) return true;
    variants.forEach(v=>{
      v_total = v_total + Number(v.quantity_in_stock);
    });
    console.log("qty: ", qty, "v_total: ",v_total)
    if(qty !== v_total) return true;
    return false;
  }

  const checkValidity = () => {
    // if(!portraitImage) {
    //   setFormError(constants.ADMIN_COPY_ITEM_IMG_POTRAIT)
    //   return true
    // }
    if(ediShipment) {
      if(storeItem?.variants?.length) {
        // check vendor_sku_id of all variants.
        const {valid, message} = checkVendorSkuOfVariants(storeItem.variants)
        if(!valid) {
          setFormError(message)
           return true
        }
      }
      else if(!storeItem?.store_item?.vendor_sku_id || storeItem?.store_item?.vendor_sku_id?.trim() === '') {
        setFormError(constants.ADMIN_COPY_ITEM_VENDOR_SKU_ID_ALERT)
        return true
      }
    }
    if(storeItem?.variants?.length) {
      const {valid, message} = checkVendorSkuOfVariants(storeItem.variants, false)// false: if not edishipment then we don't need to check if the vendor sku is there. Just check if it is valid or not.
        if(!valid) {
          setFormError(message)
           return true
        }
    }else if(storeItem?.store_item?.vendor_sku_id?.length && storeItem?.store_item?.vendor_sku_id?.includes(" ")) {
        setFormError(constants.ADMIN_COPY_ITEM_VENDOR_SKU_ID_EMPTY_ALERT)
        return true
    }
    if(landscapeImages.length === 0) {
      setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE)
      return true
    }
    if(!storeItem.category_ids?.length && !storeItem.custom_category_ids?.length) {
      setFormError(constants.ADMIN_COPY_ITEM_CATEGORY)
      return true
    }
    if(itemType === 'physical') {
      if((!storeItem.weight || storeItem?.weight == 0) && !pickupType) {
        setFormError(constants.ADMIN_COPY_ITEM_PHYSICAL_PROPERTY)
        return true
      }
      return false
    }
    return false
  }

  const handleSubmitError = (error) => {
    if(error.response && error.response.status === 400 && error.response.data) {
      const data = error.response.data;
      if(data.data?.length > 0) {
        setFormError(`${data.data[0].param} - ${data.data[0].msg}`)
      }
      else {
        setFormError(data?.message || constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
      }
      return;
    }
  }

  const handlePrepareAsset = () => {
    const form = new FormData()
    // form.append("imageData0", imageObjects1)
    const type=['img']

    for(let [i, l] of imageObjects2.entries()) {
        form.append(`landscapeImg`, l.blob)
        type.push('img')
    }
    form.append('type', JSON.stringify(type))
    form.append("auction_item_id", item_id)
    return form
}

  const handleSubmit = async (e, afterConfirm=false) => {///afterconfirm is for checking if the function is called after an alert
    if(e) {
      e.preventDefault()
    }
    const qty_confict = handleQtyCheck(storeItem.variants,Number(storeItem.store_item.quantity_in_stock));
    if(storeItem.variants.length > 0 && qty_confict) {
      setFormError(constants.ADMIN_COPY_STORE_ITEM_QUANTITY_ERROR);
      return;
    }

    if(storeItem.donor_id === null && !location.pathname.startsWith('/admin/market-place')){
      setFormError(constants.ADMIN_COPY_ITEM_DONOR)
      return
    }
    // if(storeItem.auction_item_category_id === null && storeItem.custom_category_id === null){
    //   setFormError(constants.ADMIN_COPY_ITEM_CATEGORY)
    //   return 
    // }
    if(checkValidity()) return
    if(!location?.pathname.startsWith('/admin/store/')) {
      if(canChooseCause && assignedcauses.length === 0 && selectedCauses.length ===0){
        setFormError(constants.ADMIN_COPY_ITEM_SELECT_CAUSE)
        return
      }
  
      if(!canChooseCause  && !causeId && !location.pathname.startsWith('/admin/market-place') ){
        setFormError(constants.ADMIN_COPY_ITEM_SELECT_CAUSE)
        return
      }
    }
    if( !can_choose_cause.current && assignedcauses.length > 0 && canChooseCause && causeId){
      selectedCauses = (selectedCauses.filter((obj,i)=> obj.value !== causeId))
   }
   if( can_choose_cause.current && assignedcauses.length > 0 && !canChooseCause){
       for(let cause of assignedcauses){
         await causeServices.removeCauseFromItem(item_id,cause?.cause_id)
       }
   }
    let data = prepareSubmitData(storeItem);

    //so the item is advatix (not edi and not selfship)
    if((!shipmentType && !ediShipment) && itemType === 'physical' && +storeItem?.store_item?.quantity_in_stock !==  +savedQuantity && !afterConfirm && godAccess) {
      setContinueAlert({isOpen:true, message:constants.ADMIN_COPY_ADVATIX_ITEM_QUANTITY_IN_STOCK_ALERT, type:'advatix_quantity'})
      return
    }
    if(itemType === "virtual"){
      if(virtualText === "" && virtualFiles.length === 0){
        if(newVirtualFiles.length === 0){
          setFormError(constants.ADMIN_COPY_VIRTUAL_ITEM)
          return
        }
      }
    }
    console.log(storeItem.variants, storeItem.options, 'options')
    console.log(data.variants, data.options, 'data options')
    setIsLoading(true)
    try {
      setShowPrompt(false)
      await auctionServices.reqEditItem(auction_id, item_id, data, "update-store-item", forCharity)
        const assetData = handlePrepareAsset()
        if(imageObjects1 || imageObjects2.length > 0) {
            await auctionServices.updateItemAsset(assetData)
        }
        if(itemType === 'virtual' ){
          if(virtualTextUpdated){
            const body= {
              name:virtualText,
              virtualDocId:virtualTextDocID
            }
           await reqEditVirtualItem(auction_id,virtualTextDocID,body);
          }
          if(newVirtualFiles.length > 0){
            const formData = createVirtualAsset(newVirtualFiles);
              await auctionServices.uploadVirItem(formData,auction_id,item_id);
          }
        }
      setIsLoading(false)
      setStoreItem(initialData)
      setShowAmountDialog(true)
      if(navigateAction === 'another') {
        // navigate("/admin/items", {state: {type: "store"}})
        if(location.pathname.startsWith('/admin/items')) navigate('/admin/items', {state: {type: "store"}})
        else if(location.pathname.startsWith('/admin/market-place')) navigate('/admin/market-place/items', {state: {type: "store"}})
        else navigate('/admin/store/items', {state: {type: "store"}})
        
      }
      else if (navigateAction === "anotherItem"){
        // navigate("/admin/items/add")
        if(location.pathname.startsWith('/admin/items')) navigate("/admin/items/add")
        else if(location.pathname.startsWith('/admin/market-place')) navigate('/admin/market-place/items/add')
        else navigate('/admin/store/items/add')
      }
      else {
        if(location.pathname.startsWith('/admin/items')) navigate(`/admin/event-preview/${auction_id}`)
        else if(location.pathname.startsWith('/admin/market-place')) navigate(`/admin/market-place-preview/${auction_id}`)
        else navigate(`/admin/store-preview/${auction_id}`)
      }
    } catch (error) {
      setIsLoading(false)
      handleSubmitError(error)
      setShowPrompt(true)
    }
  }

  const handleCategoryChange = (e) => {
    // if(e.value.type === 'global') {
    //   setStoreItem({
    //     ...storeItem,
    //     auction_item_category_id: e.value.id,
    //     custom_category_id: null
    //   })
    // } else if (e.value.type === 'custom') {
    //   setStoreItem({
    //     ...storeItem,
    //     custom_category_id: e.value.id,
    //     auction_item_category_id: null
    //   })
    // }
    e?.map((item) => {
      if(item.value == 16 || storeItem.category_ids?.includes(16)) {
        setStoreItem((prevStoreItem) => ({
          ...prevStoreItem,
          category_ids: [16],
          custom_category_ids: []
        }))
       setSelectedCategory(e.filter((cat)=> cat.value === 16)) 
       setCategorySelectDisable(true)
     }
     else {
      setCategorySelectDisable(false)
      if (item.type === 'global' && !storeItem.category_ids?.includes(item.value) ) {
        setStoreItem((prevStoreItem) => ({
          ...prevStoreItem,
          category_ids: [...prevStoreItem.category_ids, item?.value],
        }))
      } else if (item.type === 'custom' && !storeItem.custom_category_ids?.includes(item.value) ) {
        setStoreItem((prevStoreItem) => ({
          ...prevStoreItem,
          custom_category_ids: [...prevStoreItem.custom_category_ids, item.value],
        }))
      }
      setSelectedCategory(e)
     }
    })
    setShowPrompt(true)
  }

  const handleCategoryRemove = async(e, cat) => {
    let deleteCatPayload = {
      auction_item_id: item_id,
      category_id: cat?.value
    }
    if(cat?.type === 'custom') {
      deleteCatPayload = {
        auction_item_id: item_id,
        custom_category_id: cat?.value
      }
    }
    try {
      setIsLoading(true)
      await auctionServices.deleteAuctionItemCategoryXref(deleteCatPayload, auction_id)
      setIsLoading(false)
    }catch(error) {
      setIsLoading(false)
    }
    const globalCategory = e.filter((catItem, index) => (e.indexOf(catItem) === index) && catItem.type === 'global').map((item) => item.value);
    setStoreItem((prevStoreItem) => ({
      ...prevStoreItem,
      category_ids: globalCategory
    }))
    const customCategory = e.filter((catItem,index) => (e.indexOf(catItem) === index) && catItem.type === 'custom').map((item) => item.value);
    setStoreItem((prevStoreItem) => ({
      ...prevStoreItem,
      custom_category_ids: customCategory
    }))
    setSelectedCategory(e)
  }

  const handleLocalDonor = async () => {
    try {
      setIsLoading(true)
      if(localDonor.org_name?.length < 1 || localDonor.about?.length < 1){
        setIsLoading(false)
        // setFormError("Please fill all fields")
        alert(constants.ADMIN_COPY_ITEM_LOCAL_DONOR_FIELD)
        return
      }
      if(localDonor.org_name?.trim()?.length < 2) {
        setIsLoading(false)
        alert(constants.ADMIN_COPY_ITEM_LOCAL_DONOR_ORG_NAME )
        return
      }
      if(localDonor.about?.trim()?.length < 2) {
        setIsLoading(false)
        alert(constants.ADMIN_COPY_ITEM_LOCAL_DONOR_ABOUT)
        return
      }
      const data = await auctionServices.createLocalDonor({org_name: localDonor.org_name, about: localDonor.about,auction_id:auction_id })
      setUpdateDonors(!updateDonors)
      setLocalDonor({
        org_name: "",
        about: ""
      })
      setSelectedDonor({
        label: data.org_name,
        id: data.id
      })
      setStoreItem({
        ...storeItem,
        donor_id: data.id
      })
      setIsLoading(false)
      setOpenDonor(false)
    } catch (error) {
      if(error?.response?.data?.data?.length) {
        setFormError(error?.response?.data?.data[0].msg || constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
      }
      else {
        setFormError(error.response.data.message)
      }
      setOpenDonor(false)
      setLocalDonor({
        org_name: "",
        about: ""
      })
      setIsLoading(false)
    }
  }

  const handleDonorChange = (e) => {
    setStoreItem({...storeItem, donor_id: e.value})
    setSelectedDonor(e)
    if(e.sps_edi_trading_partner_id === null) {
      setEdiShipment(false)
    }
  }

  const deleteImage = async (url, imageIdx) => {
    await auctionServices.deleteItemAsset(item_id, url)
    setLandscapeImages(landscapeImages.filter((i,idx)=> idx !== imageIdx))
    setImageObjects2(imageObjects2.filter((o, idx) => o.url !== url))
  }

  // const deletePortrait = async (url, imageIdx) => {
  //   setPortraitImage(null)
  //   setImageObjects1("")
  // }

  const deleteFile =(file,index)=>{
    deleteVirtualAsset(file.auction_item_id,file.name);
    setVirtualFiles(virtualFiles.filter((i,idx)=> idx != index))
  }
  const handleEmailText = (e) =>{
    setVirtualTextUpdated(true)
   setVirtualText(e.target.value)
 }

 const handleCauseChange = (e) =>{
  setSelectedDefaultCauseId(e)
  setCauseId(e?.value)
}

const onDialogChange = (e) => {
  setShowAmountDialog(e)
}

const handleContinueAlert = async() => {
  setContinueAlert({isOpen:false, message:'', type:''})
  switch (continueAlert.type) {
    case 'advatix_quantity':
      await handleSubmit(null, true)
      break;
    default:
      break;
  }
}

const handelShipment = (shipType) => {
  switch (shipType) {
    case 'shipment':
      if(shipmentType && !godAccess) {
        // if not god user then we are not allowing them to make it advatix
        setFormError(constants.ADMIN_COPY_NORMAL_USER_SHIPPING_OPTION_ALERT)
      }
      else {
        setShipmentType(!shipmentType)
        setEdiShipment(false);
      }
      break;
    case 'ediShipment':
      if((!forCharity || !storeItem?.donor?.sps_edi_trading_partner_id) && !ediShipment) {
        if(!selectedDonor) {
          setFormError(constants.ADMIN_COPY_EDI_SHIPMENT_ALERT)
          return
        }
        if(!selectedDonor?.sps_edi_trading_partner_id || selectedDonor?.sps_edi_trading_partner_id === null || selectedDonor?.sps_edi_trading_partner_id === undefined) {
          setFormError(constants.ADMIN_COPY_EDI_SHIPMENT_ALERT)
          return
        }
      }
      setEdiShipment(!ediShipment);
      setShipmentType(false)
      break;
    default:
      break;
  }
}


const handleWeightChange = (event) => {
  setStoreItem({ ...storeItem, weight: event.target.value, fulfilment: { ...storeItem.fulfilment, pickup_type: 'ship' } })
}

const handleAiAnalysisClicked = async() => {
  if (imageObjects2.length === 0 && landscapeImages?.length === 0) {
    setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE)
    return true
  }
  try {
    setIsLoading(true)
    const formData = new FormData()
    let newBlob;
      if(landscapeImages[0]?.name?.startsWith('data:image/')) {
        // newBlob = new Blob([landscapeImages[0]], { type: "image/jpeg" })
        newBlob = imageObjects2[0].blob
      }
      else {
        newBlob = JSON.stringify(landscapeImages[0])
      }
    formData.append('image', newBlob)
    const response = await auctionServices.generateImageDetails(formData)
    // if(response?.data?.status === 'success') {
    if(response?.data?.description?.length) {
        setAiAnalysis({modalIsOpen:true, aiData:response?.data, isUsed:true})
    }
    setIsLoading(false)
  }catch(error) {
    setIsLoading(false)
    setAiAnalysis({modalIsOpen:false, aiData:null, isUsed:false})
    setFormError(error?.response?.data?.message || constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
  }
}

const handleGeneratedData = (data=null) => {
  if(!data) return;
  setShowPrompt(true)
  setStoreItem({...storeItem, description: data?.description})
  setAiAnalysis({...aiAnalysis, modalIsOpen:false, isUsed:true})
}

const showAiGeneratedData = () => {
  setAiAnalysis({...aiAnalysis, modalIsOpen:true})
}

const onStockAvailabityChange = (e) => {
  setStoreItem({...storeItem, show_stock_availability:e})
}

// Functon for adding newVariants if the variants are not added while creating an item.
const onSubmitOptions = (whileDeleting=false) => {
  if(!optionsUpdated && !whileDeleting) {
    setShowOptions(false)
    return
  }
  const combination = variantCombination(Object.values(storeItem.options));
  const obj = [];
  const keys = Object.keys(storeItem.options);
  combination?.forEach((c) => {
    const values = c.split("/");
    let res = {};
    keys.forEach((element, index) => {
      res[element] = values[index];
    });
    res["quantity_in_stock"] = "";
    res["price"] = "";
    res["list_price"] = "";
    res["vendor_sku_id"] = "";
    res["key"] = c;
    obj.push(res);
  });
  setLocalVariants(obj)
  updateVariants(obj);
  setOptionsUpdated(false)
  if(whileDeleting) return
  setShowOptions(false)
}

const addOption = (name, values) => {
  setShowPrompt(true)
  if(name === ''|| values?.length === 0) return 
  setStoreItem({
    ...storeItem,
    options: {
      ...storeItem.options,
      [name]: values,
    },
  });
  setNewOptionsAdded(true)
};

const deleteOption = (name) => {
  setShowPrompt(true)
  const newOptions = storeItem.options;
  delete newOptions[name];
  setStoreItem({
    ...storeItem,
    options: { ...newOptions },
  });
  onSubmitOptions(true)/// the true is passing because, for checking if the function is calling after deleting variants.
};


  return (
    <form onSubmit={handleSubmit} className="sitem-wrapper">
       <div className='form-top-actions'>
      {
        !aiAnalysis.isUsed ?
        <button onClick={handleAiAnalysisClicked} type='button' className='aitem-modal-btn'>AI Analysis</button>
        :
        <button onClick={showAiGeneratedData} type='button' className='aitem-modal-btn'>Show AI Generated Data</button>
      }
      {/* <button onClick={handleAiAnalysisClicked} type='button' className='aitem-modal-btn'>AI Analysis</button> */}
      </div>
      <div className='sitem-container'>
        <div>
          <div className='sitem-section-head'>
            General
            <hr />
          </div>
          <Input label="Store Item Title" value={storeItem.name} name="name" onChange={handleDataChange} required />
            <div>
            {/* <DropDown value={selectedCategory} isSearchable onChange={handleCategoryChange} options={categories} label="Choose Category" required /> */}
            <label className='input-label' htmlFor="">Choose Category</label>
              <Multiselect 
                displayValue="key"
                onRemove={(e, item)=>handleCategoryRemove(e, item)}
                onKeyPressFn={function noRefCheck(){}}
                onSelect={(e)=>handleCategoryChange(e)}
                options={categories}
                showArrow
                avoidHighlightFirstOption
                placeholder="Choose Category"
                selectionLimit={categorySelectDisable ? 1 : null}
                selectedValues={selectedCategory}
              /> 
            </div>
          <div className='aitem-two-inputs' style={{gap: '10px'}}>
            { !location.pathname.startsWith('/admin/market-place')
             ? 
            <div style={{flex: 3, display: "flex", alignItems: "flex-end", gap: "10px"}}>
              <DropDown value={selectedDonor} isSearchable onChange={handleDonorChange} options={donorsList} label="Donor" required />
              <button onClick={() => setOpenDonor(true)} type='button' className='aitem-modal-btn'>Add Donor</button>
            </div>
             : null 
            }
          </div>
          {location.pathname.includes('/admin/store') ? null : 
          <div style={{marginTop:"15px"}}>
          <label style={{ fontWeight:"600",fontSize:"0.85rem"}} >{constants.ADIMIN_COPY_CAN_CHOOSE_CAUSE}</label>
          <div style={{ display: "flex", gap: "20px" }}>        
            <FormControlLabel
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}}  />}
              label="Yes"
              checked={canChooseCause === true}
              onChange={()=>setCanChooseCause(true)}
              labelPlacement="end"
            />
            <FormControlLabel  
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}}  />}
              label="No"
              checked={canChooseCause === false}
              onChange={()=>setCanChooseCause(false)}
              labelPlacement="end"
            />
          </div>
         {
          (canChooseCause) ?  <Multiselect
          disablePreSelectedValues 
          displayValue="key"
          onRemove={(e)=>setSelectedCauses(e)}
          onKeyPressFn={function noRefCheck(){}}
          onSelect={(e)=>setSelectedCauses(e)}
          options={unAssignedCauses}
          showArrow
          avoidHighlightFirstOption
          placeholder="Select Causes"
          selectedValues={preSelectedCauses}
          selectionLimit={4}
        /> : <DropDown 
                 value={selectedDefaultCauseID} 
                 isSearchable 
                 onChange={handleCauseChange} 
                 options={defaultCauses} 
                 label="Select Cause" 
                //  required={!location.pathname.includes('/market-place')? true : false}
                 required={true}
                 isDisabled={location.pathname.includes('/market-place')} 
              />
         }
         {assignedcauses.length > 0 && canChooseCause && <button type="button" onClick={() => setRemoveCauseModal(true)} className='aitem-modal-btn' style={{ width: "100%",marginTop:"10px" }}>Manage assigned causes</button>}
          </div>}
          <FormTextarea iButton infoBubbleText='Add a short but clear description about your item' label="Item Short Description" value={storeItem.short_description} name="short_description" onChange={handleDataChange} required rows={1} maxsize={75} />
          <FormTextarea iButton infoBubbleText='Describe your item in greater detail' label="Item Long Description" value={storeItem.description} name="description" onChange={handleDataChange} required maxsize={1000} />
        <RadioGroupComponent 
          checked={showAmountDialog}
          onChange={onDialogChange}
          label={'Would you like to display a dialog that tells the buyer how much will go to cause?'}
        />
          <div className='aitem-two-inputs'>
            <Input label={ (storeItem?.auction?.market_place == 1 || location.pathname.startsWith('/admin/store')) ? "Boundless Price" : "Event Price"} value={storeItem.store_item.price} name="price" onChange={handleStoreItemChange} required type="number" step="any" />
            <Input iButton infoBubbleText='If you want to  display a retail or MSRP price for comparison purposes, enter it here.' label="List / Retail Price" value={storeItem.store_item.list_price} name="list_price" onChange={handleStoreItemChange} type="number" step="any" />
          </div>
          
          <FormControlLabel onChange={() => setIsTaxable(!isTaxable)} control={<Checkbox size="small" checked={isTaxable} sx={{"&.Mui-checked": {color: redColor,}}}  />} label={<Typography sx={{fontSize:'0.85rem',fontWeight:'600',fontFamily:'AvenirNext, sans-serif'}}>Is this item taxable?</Typography>}/>
          <Input iButton infoBubbleText='If quantity is unlimited, enter 5000' label="Quantity in stock" value={storeItem.store_item.quantity_in_stock} name="quantity_in_stock" onChange={handleStoreItemChange} required type="number" />
        </div>
        <div>
        {(localVariants && localVariants.length) || 
          (storeItem?.variants?.length) || 
          (storeItem?.status !== 'active' && storeItem?.store_item?.advatix_status !== 'known') ?
            <>
              <div className='sitem-section-head'>
                Add Sizes, Colors And Styles (Optional)
                <hr />
              </div>
              <div className='variants_buttons_container'>
                <button disabled={!localVariants || localVariants.length === 0} type="button" onClick={() => setShowVariants(true)} className='aitem-modal-btn '>Update Variants Quantity</button> 
                {
                  Object.keys(variantDetails)?.length ?
                  <button type="button" onClick={() => setEditVariantsModal(true)} className='aitem-modal-btn '>Update Variants</button>
                  :
                  (storeItem?.status === 'active' || storeItem?.store_item?.advatix_status === 'known') ? null 
                  :<button type="button" onClick={() => setShowOptions(true)} className='aitem-modal-btn '>Add Variants</button>
                }
              </div>
            </>
            : null
          }
        {!localVariants || localVariants.length === 0 ? null :
        <div>
          <label className='input-label'>Listed below are the options that you have added</label>
          <OptionsModalContent  options={storeItem.options}   outsideModal={true} />
        </div> }

        {!localVariants?.length ?
              <Input
                iButton infoBubbleText={constants.ADMIN_COPY_ITEM_EDI_INFO}
                label="Vendor SKU ID" value={storeItem.store_item.vendor_sku_id} name="vendor_sku_id" onChange={handleStoreItemChange}
              />
              :null
          }
        
        <RadioGroupComponent 
          checked={storeItem.show_stock_availability}
          onChange={onStockAvailabityChange}
          label={constants.ADMIN_COPY_ITEM_STOCK_AVAILABILITY}
        />
          <div className='sitem-section-head'>
            Images
            <hr />
          </div>

          {/* <ImageInput loading={portraitImageLoading} required edit onDelete={deletePortrait} handleChange={handlePortraitImage} images={portraitImage ? portraitImage : []} label={constants.ADMIN_COPY_ITEM_THUMPNAIL_IMG} sidelabel="Max 1, Recomended size 300x300" max={1}/> */}
          <ImageInput loading={landscapeImageLoading} required onDelete={deleteImage} edit multiple handleChange={handleLandscapeImage} images={landscapeImages} label={constants.ADMIN_COPY_ITEM_DETAILS_IMG} sidelabel="Max 4, recommended size 1024x1024" max={4} />

          <div className='sitem-section-head' style={{marginTop: "15px"}}>
            Shipping
            <hr />
          </div>

          <>
   {  storeItem?.auction?.status === 'live' ?
    <div style={{display: "flex", gap: "20px"}}>
    {  itemType === 'physical' ?   <FormControlLabel
              value="physical"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="Physical Product"
              checked={itemType === "physical"}
              onChange={() => setItemType("physical")}
              labelPlacement="end"
            />:
            <FormControlLabel
              value="Virtual"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="Virtual Product"
              checked={itemType === "virtual"}
              onChange={() => setItemType("virtual")}
              labelPlacement="end"
            />}
          </div>:
            <div style={{display: "flex", gap: "20px"}}>
            <FormControlLabel
              value="physical"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="Physical Product"
              checked={itemType === "physical"}
              onChange={() => setItemType("physical")}
              labelPlacement="end"
            />
            <FormControlLabel
              value="Virtual"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="Virtual Product"
              checked={itemType === "virtual"}
              onChange={() => setItemType("virtual")}
              labelPlacement="end"
            />
          </div>
          }
          </>
          <div>
        </div>
          {itemType === 'physical' ? <button type="button" onClick={() => setOpenShipping(true)} className='aitem-modal-btn aitem-white-button' style={{width: "100%"}}>Add Shipping/Pickup Details</button> : null}
          {(itemType === 'virtual') && <>
            <FormTextarea label="Enter email body for how to receive or claim item" value={virtualText} name="description"   maxsize={500} onChange={handleEmailText} iButton infoBubbleText='Please supply email instructions explaining how item winner/purchaser may receive or claim their purchase' />
          </>}

          {( itemType === 'virtual') && <>
            <label className='input-label' style={{marginBottom: 0, marginTop: "10px"}}>Upload attachments with additional instructions (if required)<Tooltip title={<Typography fontSize="0.85rem">Please upload a .pdf, .jpg or .png to send to the auction winner that includes the digital item or instructions for redemption</Typography>}><InfoOutlinedIcon sx={{color:'#495057c4',fontSize: "16px", marginLeft: "3px"}} /></Tooltip></label>
            <input type='file'  className='aitem-file-btn' style={{marginTop: 0, marginBottom:"15px"}} multiple={true} onChange={handlefileChange}/>   
            <div>
              {
                (virtualFiles.map((file,i)=>{
                  return (
                    <div style={{ textAlign: "left"}}>{file.original_file_name} <span onClick={()=>deleteFile(file,i)}><ClearIcon style={{ color : "red", cursor:"pointer" }}/></span></div>
                  )
                }))
              }
            </div>
          </>
          }
          <div className='aitem-submit-container'>
            <button onClick={() => setNavigateAction("another")} type='submit'>Update</button>
            <button onClick={() => setNavigateAction("anotherItem")} type='submit'>Update & Add Another Item</button>
            <button onClick={() => setNavigateAction("finish")} type='submit'>Finished Updating Items? Update and Preview</button>
          </div>
        </div>
      </div>
      <div style={{fontSize: "0.9rem", fontWeight: "600", padding: "0 10px"}}><span style={{color: "red"}}>*</span> - required fields</div>
      <Modal isOpen={openShipping} onClose={() => setOpenShipping(false)} onDone={() => setOpenShipping(false)} title="Shipping Details">
        <div className='input-label' style={{fontSize: "1.2rem", fontWeight: "600", borderBottom: "1px solid #d3d3d3"}}>Check all that applies</div>
        <FormControlLabel onChange={() => setIsFlatRate(!isFlatRate)} control={<Checkbox size="small" checked={isFlatRate} />} label="Flat Rate Shipping"/>
        <br />
        <FormControlLabel onChange={() => handelShipment('ediShipment')} control={<Checkbox size="small" checked={ediShipment} sx={{"&.Mui-checked": {color: redColor,}}}  />} label={constants.ADMIN_COPY_EDI_SHIPMENT_LABEL}/>
        <FormControlLabel onChange={() => handelShipment('shipment')} control={<Checkbox size="small" checked={shipmentType} />} label={ location.pathname.includes('/market-place') ? 'I Will Provide Shipping & Handling (Drop Ship)' : `Shipping & Handling Managed By Event Creator`}/>
        <br />
        { !location.pathname.includes('/market-place') &&
       <>
        <FormControlLabel onChange={() => setHasUPC(!hasUPC)} control={<Checkbox size="small" checked={hasUPC} />} label="This product has UPC"/>
        {hasUPC && <Input label="Enter UPC" required value={storeItem.store_item?.upc} onChange={handleStoreItemChange} name="upc" />}
        </>}
        {shipmentType && <FormControlLabel onChange={() => setPickupType(!pickupType)} control={<Checkbox size="small" checked={pickupType} />} label="No shipping. Customer must pick up from designated location."/>} 
        {!pickupType && <Input type="number" value={storeItem.weight} onChange={handleWeightChange} label="Product Weight (lb)" required={!pickupType} iButton infoBubbleText='If your item is one pound or less, enter the number 1.'/>}
      </Modal>
      <Modal onDone={onSubmitVariants} isOpen={showVariants} onClose={handleCloseVariants} title="Update Varaints Quantity">
        <VaraintsModalContent localVariants={localVariants} setLocalVariants={setLocalVariants} setShowPrompt={setShowPrompt} />
      </Modal>
      <Modal isOpen={openDonor} onClose={() => setOpenDonor(false)} onDone={handleLocalDonor} title="New Donor">
        <Input value={localDonor.org_name} onChange={(e) => setLocalDonor({...localDonor, org_name: e.target.value})} label="Donor Name or Organization" required />
        <FormTextarea value={localDonor.about} onChange={(e) => setLocalDonor({...localDonor, about: e.target.value})} label="Long Description" required iButton infoBubbleText='Tell us about your item’s donor. Who are they? What do they do? Do you want to share a link to their web site?'/>
      </Modal>
      <Modal buttonRequired={false}  isOpen={showOptions} onClose={() => setShowOptions(false)} title="Add Options">
          <OptionsModalContent onSubmitOptions={onSubmitOptions}  options={storeItem.options} setOptionsUpdated={setOptionsUpdated} addOption={addOption} deleteOption={deleteOption}/>
      </Modal>
      <Modal isOpen={removeCauseModal} onClose={()=>setRemoveCauseModal(false)} buttonText='Continue' onDone={()=>setRemoveCauseModal(false)}>
    {
  (assignedcauses.length > 0 ) ? assignedcauses.map((cause,i)=>{
    return (
    <div key={cause?.id} className='remove-cause-conatiner'>
      <span>{cause?.cause?.legal_name}</span>
      <button onClick={(e)=>{
        e.preventDefault()
        handelRemoveCause(cause?.cause_id)}}
        >Remove</button>
    </div>
    )
  }) : <div>No causes found</div>
   }
   </Modal>

   <Modal title={'Update or Add variants'} isOpen={editVariantsModal} onClose={() => setEditVariantsModal(false)} buttonRequired={false}>
    <EditVariants 
       options={options} 
       variants={variants} 
       setIsLoading={setIsLoading} 
       fetchData={fetchStoreItem} 
       setEditVariantsModal={setEditVariantsModal}
       setFormError={setFormError}
       variantDetails={variantDetails}
    />
   </Modal>

      <Modal isOpen={aiAnalysis.modalIsOpen} onClose={() => setAiAnalysis({...aiAnalysis, modalIsOpen:false})} buttonRequired={false} title={constants.ADMIN_COPY_AI_MODAL_TITLE}>
        <AIGeneratedData data={aiAnalysis.aiData} handleGeneratedData={handleGeneratedData}/>
      </Modal>

      <LoadingModal isOpen={isLoading} />
      <Alert isOpen={formError} message={formError} onClose={() => setFormError(null)} />
      {/* <Alert isOpen={!!whiteborderPortraitAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handlePortraitImage(portraitEvent,true);
                setWhiteborderPortraitAlert(null)
                }} 
                onClose={()=>setWhiteborderPortraitAlert(null)} 
                message={whiteborderPortraitAlert}/> */}
      <Alert isOpen={!!whiteborderLandScapeAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handleLandscapeImage(landScapeEvents,true);
                setWhiteborderLandScapeAlert(null)
                }} 
                onClose={()=>setWhiteborderLandScapeAlert(null)} 
                message={whiteborderLandScapeAlert}/>
      <Alert 
          isOpen={continueAlert.isOpen} 
          message={continueAlert.message} 
          onClose={() => setContinueAlert({isOpen:false, message:'', type:''})}
          type='confirm'
          onConfirm={handleContinueAlert}
          okBtnText='I Understand, Proceed'
      />
    </form>
  )
}

export default StoreItem