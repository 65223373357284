import React, { useEffect, useState } from 'react'
import { Accordion } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import Input from '../../../components/Input/Input';
import "./OptionsModalContent.css"
import DropDown from '../../../components/DropDown/DropDown';

const OptionsInput = ({handleAddOption, currentOptions, editOptionsData={}, setEditOptionsData}) => {
    const customOptionsArray = currentOptions?.filter((val, i) => {
        if(val !== "Size" && val !== "Color" && val !== "Style"){
            return val
        }
    })
    const [name, setName] = useState("")
    const [selectedOption, setSelectedOption] = useState(null)
    const [options, setOptions] = useState([{key: 1, value: ""}])
    const [customInput, setCustomInput] = useState(false)
    const [customName, setCustomName] = useState("")

    const getOptionsInitialValue = () => {
        let value = [{key: 1, value: ""}]
        if(Object.keys(editOptionsData)?.length) {
            value = Object.values(editOptionsData)[0].map((item, index) => {
                     return {
                        key: index + 1,
                        value: item
                     }
            })
        }
        else {
            value = [{key: 1, value: ""}]
        }
        return value
    }

    useEffect(()=> {
        if(!Object.keys(editOptionsData)?.length ) {
            setOptions([{ key: 1, value: "" }])
        }
        else {
        setSelectedOption(
            Object.keys(editOptionsData)?.length ? {value: Object.keys(editOptionsData)[0], label: Object.keys(editOptionsData)[0]} : null
        )
        setName(
            Object.keys(editOptionsData)?.length ? Object.keys(editOptionsData)[0] : ""
        )
        setOptions(getOptionsInitialValue())
    }
    },[editOptionsData])

    const handleDone = () => {
        // if (name === "") return;
        if (options.length === 0) return;
        // const empty = options.find((e) => e.value === "");
        // if (empty) return;
        setName("");
        setOptions([{ key: 1, value: "" }]);
        handleAddOption(name, options);
    };

    const addOptionField = () => {
        setOptions([...options, { key: options.length + 1, value: "" }]);
    };

    const handleOptionRemove = (key) => {
        const newOptions = options.filter((opt) => opt.key !== key);
        setOptions(newOptions);
    };

    const handleChange = (e, key) => {
        const newOptions = options.map((opt) => {
            if (opt.key === key) {
            return {
                ...opt,
                value: e.target.value,
            };
            }
            return opt;
        });
        setOptions(newOptions);
    };
    const generateLabels = () => {
        return [
            {value: "Size", label: "Size", isDisabled: currentOptions.indexOf("Size") !== -1},
            {value: "Color", label: "Color", isDisabled: currentOptions.indexOf("Color") !== -1},
            {value: "Style", label: "Style", isDisabled: currentOptions.indexOf("Style") !== -1},
            {value: "Custom", label: "Custom", isDisabled: customOptionsArray?.length === 4 },
        ]
    }

    const handleOptionChange = (e) => {
        if(e?.value === "Custom"){
            setCustomInput(true)
            setSelectedOption(e)
            setEditOptionsData({})
        }
        else {
            setCustomInput(false)
            setSelectedOption(e)
            setName(e.value)
            setEditOptionsData({})
        }
    }

    const handleCustomChange = (e) => {
            setCustomName(e?.target?.value.trim())
            setName(e?.target?.value.trim())
    }

    return <div style={{paddingBottom: "20px"}}>
    <DropDown placeholder="Choose Option" label="Option Name" value={selectedOption} onChange={handleOptionChange} options={generateLabels()} style={{marginBottom: "15px"}}/>
    <div>
        {customInput && <Input  label={`Enter custom option name`} value={customName} onChange={handleCustomChange} />}
    { 
       options?.map((opt) => (
        <div key={opt.key} style={{display: "flex", alignItems: "flex-end"}}>
            <Input key={opt.key} label={`Add ${ selectedOption?.value === "Custom" ? customName : name}`} value={opt.value} onChange={(e) => handleChange(e, opt.key)} />
        </div>
        ))
        }
        <button
        type="button"
        onClick={addOptionField}
        className="variants-option-add-btn"
        >
        + Add Another {selectedOption?.value === "Custom" ? customName : name}
        </button>
    </div>
    <div style={{textAlign: "right"}}>
      <button type="button" onClick={handleDone} className='aitem-modal-btn aitem-white-button'>Finished Adding</button>
    </div>
  </div>
}

const OptionsModalContent = ({options, addOption, deleteOption, setOptionsUpdated=()=>null, outsideModal=false, onSubmitOptions=()=>null}) => {
    const [showForm, setShowForm] = useState(Object.keys(options).length === 0 ? true : false);
    const [editOptionsData, setEditOptionsData] = useState({})
    const handleAddOption = (name, valuesObj) => {
      let valueArr = valuesObj.map((val) => val.value);
      valueArr = valueArr.filter((val) => val !== "");
      addOption(name, valueArr);
      setOptionsUpdated(true)
      setShowForm(false);
    };

    const handleDeleteOption = (key) => {
        deleteOption(key)
        setOptionsUpdated(true)
    }

    const handleEditOption = (key, values) => {
        setEditOptionsData({[key]:values})
        setShowForm(true)
    }

    const handleAddNewOption = () => {
        setEditOptionsData({})
        setShowForm(true)
    }

    const handleSave = () =>{
        onSubmitOptions()
    }

    return (
        <div className='variants-container'>
            <div className='variants-options-container'>
            <Accordion>
                {Object.keys(options).map((key, idx) => (
                <Accordion.Item key={key} eventKey={idx + ""}>
                    <Accordion.Header>{key}</Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        {options[key].map((val, idx) => (
                        <li className="input-label" style={{ margin: "5px 0" }} key={idx}>
                            {val}
                        </li>
                        ))}
                    </ul>
                    { !outsideModal && <button
                        className='accordion-delete-btn'
                        type="button"
                        onClick={() => handleDeleteOption(key)}
                    >
                        Delete
                    </button>}
                    { !outsideModal && <button
                        className='accordion-edit-btn'
                        type="button"
                        onClick={() => handleEditOption(key, options[key])}
                    >
                        Edit
                    </button>}
                    </Accordion.Body>
                </Accordion.Item>
                ))}
            </Accordion>
            { !outsideModal &&
            <>
            { showForm ? <OptionsInput setEditOptionsData={setEditOptionsData} editOptionsData={editOptionsData} currentOptions={Object.keys(options)} handleAddOption={handleAddOption} /> : (
            <div className='options-final-buttons'>
           { Object.keys(options)?.length ? 
             <button
                type="button"
                onClick={() => handleSave()}
                className="aitem-modal-btn"
             >
                Save and Continue
             </button>
            : <div></div>
            }
            <button
                type="button"
                onClick={() => handleAddNewOption()}
                className="aitem-modal-btn aitem-white-button"
            >
                + Add New Option
            </button>
            </div>
            )}
            </>}
            </div>
        </div>
    )
}

export default OptionsModalContent