import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import auctionServices from '../services/auction-services'

export const fetchItemSalesReportRedux = createAsyncThunk(
    'auction/fetchItemSalesReportRedux',
    async ({ auctionId, page, limit, pageType = "event", orderSearchValue, orderSearchBy  }) => {
        const response = await auctionServices.getItemSalesReport(auctionId, page, limit, pageType, orderSearchValue, orderSearchBy )
        return {
            data: response?.data,
            itemSalesTotalCount: response?.count,
            itemSalesTotalPage: response?.total_page
        }
    }
)

const initialState = {
    itemSalesData: [],
    itemSalesTotalCount: 0,
    itemSalesReportLoading: false,
    itemSalesTotalPage: 0,
    itemSalesHeading: ['Product Name', 'Product SKU', 'Quantity Available', 'Quantity Sold', 'Total Sales Amount (excl. tax and charges)'],
    error: ''
}

const itemSalesReportSlice = createSlice({
    name: 'auction/item-sales',
    initialState,
    reducers: {
        clearItemSalesReport: (state, action) => {
                state.itemSalesData =  []
                state.itemSalesTotalCount =  0
                state.itemSalesReportLoading =  false
                state.itemSalesTotalPage =  0
                state.itemSalesHeading =  ['Product Name', 'Product SKU', 'Quantity Available', 'Quantity Sold', 'Total Sales Amount (excl. tax and charges)']
                state.error =  ''
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchItemSalesReportRedux.pending, (state, action) => {
            state.itemSalesReportLoading = true
        })
        builder.addCase(fetchItemSalesReportRedux.fulfilled, (state, action) => {
            state.itemSalesData = action.payload?.data
            state.itemSalesTotalCount = action.payload?.itemSalesTotalCount
            state.itemSalesReportLoading = false
            state.itemSalesTotalPage = action.payload.itemSalesTotalPage
            state.error = ""
        })
        builder.addCase(fetchItemSalesReportRedux.rejected, (state, action) => {
            state.itemSalesReportLoading = false
            state.error = action.payload?.error?.message
        })
    }
})

export default itemSalesReportSlice.reducer
export const { clearItemSalesReport } = itemSalesReportSlice.actions