import { Alert, AlertTitle } from '@mui/material'
import React from 'react'

const AccessDenied = ({title, margin=false}) => {
  return (
    <Alert severity="error" style={margin ? {margin: "20px"}:{}}>
        <AlertTitle><strong>You don't have the permission to access this page.</strong></AlertTitle>
        You don't have permission to access this page.<br /> Contact BG administrator if you need help.
    </Alert>
  )
}

export const LiveEvent = ({margin= false}) => {
  return (
    <Alert severity="warning" style={margin ? {margin: "20px"}:{}}>
        <AlertTitle><strong>This event is Live! You cannot perform this action!</strong></AlertTitle>
        Contact BG administrator if you need any help.
    </Alert>
  )
}

export default AccessDenied