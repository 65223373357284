import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import orderService from '../services/order-services'


export const getAuctionWinnerReport = createAsyncThunk(
    'users/getAuctionWinnerReport',
    async ({auctionId, page, limit, startDate, endDate, orderSearchValue, orderSearchBy}) => {
        const response = await orderService.getAuctionWinners(auctionId, page, limit, startDate, endDate, orderSearchValue, orderSearchBy)
        return {
            data: response?.data,
            totalWinners: response?.count,
            totalPage: response?.total_page
        }
    }
)

const initialState = {
    auctionWinners: [],
    totalWinners: 0,
    auctionWinnersReportLoading:false,
    totalWinnerPage:0,
    error:'',
    winnerTableHeading: ['Product Name', 'First Name', 'Last Name', 'Email', 'Order Date', 'Order Amount']
}

const auctionWinnersReportSlice = createSlice({
    name: 'auction/winners',
    initialState,
    reducers: {
        clearAuctionWinnerReport: (state, action) => {
            state.auctionWinners = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAuctionWinnerReport.pending, (state, action) => {
            state.auctionWinnersReportLoading = true
        })
        builder.addCase(getAuctionWinnerReport.fulfilled, (state, action) => {
            state.auctionWinners = action.payload.data
            state.auctionWinnersReportLoading = false
            state.totalWinners = action.payload.totalWinners
            state.totalWinnerPage = action.payload.totalPage
            state.error = ''
        })
        builder.addCase(getAuctionWinnerReport.rejected, (state, action) => {
            state.auctionWinnersReportLoading = false
            state.error = action.payload?.error?.message
        })
    }
})

export default auctionWinnersReportSlice.reducer
export const {clearAuctionWinnerReport} = auctionWinnersReportSlice.actions