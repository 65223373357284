import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { AuthContextProvider } from './store/auth-context';
import { CauseContextProvider } from './store/cause-context';
import { AuctionContextProvider } from './store/auction-context';

import { store } from './redux/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <CauseContextProvider>
        <AuctionContextProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </AuctionContextProvider>
      </CauseContextProvider>
    </AuthContextProvider>
  </BrowserRouter>
);

