import axios from 'axios';
import { headerKey, headerKey_ } from './api-header';
import { config } from '../config/config.js';
import logger from '../utils/logger';

const API_BASE_URL = config.REACT_APP_BASE_URL;


const getAllOrder = async (auctionId,page,limit,showDelivered,startDate,endDate,showFailed,showAll, marketplace=false, store=false, searchText, orderSearchByText) => {
    try{
    const { data } = await axios.get(`${API_BASE_URL}/api/v1/order/${auctionId}/get_all_bg_order?auctionId=${auctionId}&page=${page}&limit=${limit}`, {
       params:{
        show_delivered:showDelivered,
        start_date:startDate,
        end_date:endDate,
        show_failed:showFailed,
        show_all:showAll,
        marketplace:marketplace,
        store: store,
        searchText,
        orderSearchByText
       },
        headers: {
            ...headerKey_
        }
    })
    return data
    }catch(error){
        throw error
    }
}
const getOrder = async (xrefId) => {
    const { data } = await axios.get(`${API_BASE_URL}/api/v1/order/view_bg_order/${xrefId}`, {
        headers: {
            ...headerKey_
        }
    })
    return data
}
const updateOrderStatus = async (formData) => {
    const { data } = await axios.post(`${API_BASE_URL}/api/v1/order/order_web_hook?admin=true`,formData, {
        headers: {
            ...headerKey_
        }
    })
    return data
}

const getDonorOrder = async(auctionId, page, limit, startDate, endDate, marketplace=false, store=false, searchText, orderSearchByText) => {
     try{
        const { data } = await axios.get(`${API_BASE_URL}/api/v1/order/${auctionId}/get_donor_report?page=${page}&limit=${limit}`, {
            params:{
                start_date:startDate,
                end_date:endDate,
                marketplace:marketplace,
                store: store,
                searchText,
                orderSearchByText
               }, 
            headers: {
                 ...headerKey_
             }
         })
         return data
     }catch(error) {
        throw error
     }
}


const retryPayment = async (order_id,user_id,item_id,bid_amount) =>{
    try{
        const response = await axios.post(`${API_BASE_URL}/api/v1/order/retry-payment-admin`,{order_id:order_id,user_id:user_id,item_id:item_id,bid_amount:bid_amount},
        {headers:{
            ...headerKey_
        }})
        return response
    }catch(error){
        return error
    }
}

const getDonorOrderDetails = async(eventId, userId, page=1, limit=10, marketplace=false, store=false) => {
    try{
        const { data } = await axios.get(`${API_BASE_URL}/api/v1/order/auction/${eventId}/user/${userId}/get_donor_order_details?page=${page}&limit=${limit}`, 
        { 
            params: {
                marketplace:marketplace,
                store:store
            },
            headers: {
                 ...headerKey_
             }
         })
         return data
     }catch(error) {
        throw error
     }

}

const getAuctionWinners = async(auctionId, page, limit, startDate, endDate, searchText, orderSearchByText) => {
    try{
        const { data } = await axios.get(`${API_BASE_URL}/api/v1/order/${auctionId}/get_auction_winners?page=${page}&limit=${limit}`, {
            params:{
                start_date:startDate,
                end_date:endDate,
                searchText,
                orderSearchByText
               }, 
            headers: {
                 ...headerKey_
             }
         })
         return data
     }catch(error) {
        throw error
     }
}

const orderService = {
    getAllOrder,
    getOrder,
    updateOrderStatus,
    getDonorOrder,
    retryPayment,
    getDonorOrderDetails,
    getAuctionWinners

}
export default orderService