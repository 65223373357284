import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    store: null,
    portraitImage: null,
    landscapeImage: [],
    browseBuyNowImage:null,
    browseAuctionImage:null,
    videos: [],
    featuredImage:null,
}

const savedStoreReducer = createSlice({
    name: "store/data",
    initialState,
    reducers: {
        saveStore: (state, action) => {
            return {
                store: action.payload.store,
                portraitImage: action.payload.portraitImage,
                landscapeImage: action.payload.landscapeImage,
                browseBuyNowImage: action.payload.browseBuyNowImage,
                browseAuctionImage: action.payload.browseAuctionImage,
                videos: action.payload.videos,
                featuredImage:action.payload.featuredImage
            }
        },
        resetSavedStore: (state, action) => {
            return initialState
        },
        updateStoreProperties: (state, action) => {
            state.store[action.payload.key] = action.payload.value
        }
    }
})

export default savedStoreReducer.reducer
export const {saveStore, resetSavedStore, updateStoreProperties} = savedStoreReducer.actions