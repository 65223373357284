export const itemData = (item) => {
    let itemObj = {
        quantity: 0,
        price: item?.price
    }

    if (item?.store_item?.store_item_actual_inventories?.length) {
        // let quantity = item?.store_item?.store_item_actual_inventories?.find((variant) => variant?.sku === item?.user_order_auction_item_xrefs[0].product_sku_id).quantity_in_stock;

        itemObj = {
            quantity: item?.store_item?.quantity_in_stock,
            price: item?.store_item?.price
        }
    }
    else {
        if (item.buy_now_price && +item.buy_now_price > 0) {
            itemObj = {
                quantity: 0,
                price: item?.buy_now_price
            }
        }
        else if(item?.store_item) {
            itemObj = {
                quantity: item?.store_item?.quantity_in_stock,
                price: item?.store_item?.price
            }
        }
    }
    return itemObj
}

export const getXrefCategoryData = (xrefData, event=false) => {
    let catObj = {
        name: xrefData?.category?.name
    }
    if (xrefData?.category) {
        return catObj
    }
    else if (xrefData?.custom_category) {
        catObj = {
            name: event ? xrefData?.custom_category?.custom_category_heading?.name : xrefData?.custom_category?.category_name
        }
    }

    return catObj;
}

export const createVariantValue = (sku, variantsArray = [], options = {}) => {
    const variant = variantsArray?.find((item) => item.sku === sku)
    const optionsArray = Object.keys(options)
    // ['size', 'color']
    let combination = {
        fullValue:'',
        fullObject:{}
      }
    for (let option of optionsArray) {
        // combination += `/${variant[option]} `
        combination.fullValue += `/${variant[option]}`
        combination.fullObject[option] = variant[option]
    }
    // return combination.slice(1)
    combination = {...combination, fullValue: combination.fullValue.slice(1)}
    return combination

}

export const appendSkus = (xrefs=[], variants=[], options={}) => {
    let sku = []
    let roughSku = []
    for (let obj of xrefs) {
        if(!roughSku.includes(obj.product_sku_id)) {
            roughSku.push(obj.product_sku_id)
            if(variants?.length) {
                const valueObj = createVariantValue(obj.product_sku_id, variants, options)
                sku.push(`${obj.product_sku_id }( ${valueObj.fullValue} )`)
            }
            else {
                sku.push(obj.product_sku_id)
            }
        }
    }
    return JSON.stringify(sku).replace('[', '').replace(']','').replaceAll('"', '')
}