import { Paper, Table, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, TableBody, tableCellClasses } from "@mui/material"
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import LoadingModal from "../LoadingModal/LoadingModal";
import { setAuctionPage } from "../../../redux/reportReducer";
import { createVariantValue, itemData } from "../../../utils/ordersAndReport.utils";
import { RemoveRedEyeOutlined } from '@mui/icons-material';
import Modal from "../Modal/Modal";
import VariantCombination from "../VariantCombination/VariantCombination";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ItemSalesReportTable = ({ pageType = "event" }) => {
    const { itemSalesData, itemSalesHeading, itemSalesReportLoading, itemSalesTotalCount } = useSelector(state => state.itemSales)
    const { selectedEvent } = useSelector(state => state.orderDetails);
    const { auctionPage, auctionLimit } = useSelector(state => state.report)
    const [showModal, setShowModal] = useState({ isOpen: false, tableHeading: [], tableBody: [], modalTitle: '' })
    const dispatch = useDispatch()

    function getAvailableQuantity(sku, auctionItem) {
        const skuSplit = sku?.split('-')
        let quantity
        if (skuSplit.length === 5) {
            // take inventory quantity
            quantity = auctionItem?.store_item?.store_item_actual_inventories?.find((variant) => variant?.sku === sku).quantity_in_stock;
        }
        else if (skuSplit.length === 4) {
            // take store item quantity
            quantity = auctionItem?.store_item?.quantity_in_stock
        }
        else {
            quantity = 0
        }
        return quantity
    }

    function removeDuplicatesAndUpdateQuantity(inputArray, auctionItem) {
        const skuQuantities = {};
        inputArray.forEach(item => {
            if (skuQuantities.hasOwnProperty(item.product_sku_id)) {
                skuQuantities[item.product_sku_id].quantity_bought += item.quantity_bought;
            } else {
                skuQuantities[item.product_sku_id] = { ...item };
            }
            const availableQty = getAvailableQuantity(item.product_sku_id, auctionItem)
            skuQuantities[item.product_sku_id].quantity_in_stock = availableQty

            // create variantCombo
            if (auctionItem?.variants?.length) {
                const variantCombo = createVariantValue(item.product_sku_id, auctionItem?.variants, auctionItem?.options)
                skuQuantities[item.product_sku_id].variant_combination = variantCombo
            }
        });

        const outputArray = Object.values(skuQuantities).sort((a, b) => b.quantity_bought - a.quantity_bought);
        return outputArray;
    }

    const handleShowSku = (orderXrefArray = [], auctionItem = {}) => {
        const outputArray = removeDuplicatesAndUpdateQuantity(orderXrefArray, auctionItem);
        setShowModal({ isOpen: true, tableHeading: ['Product SKU', 'Variant', 'Quantity Sold', 'Quantity Available'], tableBody: outputArray, modalTitle: 'Product SKU' })
    }

    return (
        <>
            {
                itemSalesReportLoading ? <LoadingModal style={{ position: 'relative', width: '100%', marginLeft: '0px', minHeight: '300px', backgroundColor: 'white' }} isOpen={true} />
                    :
                    itemSalesData?.length ? <Paper>
                        <TableContainer>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        {
                                            itemSalesHeading?.map((head, index) => {
                                                return (
                                                    <StyledTableCell key={index} >{head}</StyledTableCell>
                                                )
                                            })
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {itemSalesData?.map((row, i) => {
                                        return <StyledTableRow key={row.id}>
                                            <StyledTableCell>{row?.name}</StyledTableCell>
                                            {/* <StyledTableCell>{row?.user_order_auction_item_xrefs[0]?.product_sku_id}</StyledTableCell> */}
                                            <StyledTableCell>
                                                {
                                                    row?.variants?.length ?
                                                        <span onClick={() => handleShowSku(row?.user_order_auction_item_xrefs, row)}><RemoveRedEyeOutlined style={{ marginLeft: '8px', cursor: 'pointer' }} /></span>
                                                        :
                                                        row?.user_order_auction_item_xrefs[0]?.product_sku_id
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell>{itemData(row).quantity}</StyledTableCell>
                                            <StyledTableCell>{+row?.user_order_auction_item_xrefs[0]?.total_value}</StyledTableCell>
                                            <StyledTableCell >${+row?.user_order_auction_item_xrefs[0]?.total_value * +itemData(row).price}</StyledTableCell>
                                        </StyledTableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 25, 50, 100]}
                            component="div"
                            count={itemSalesTotalCount}
                            rowsPerPage={auctionLimit}
                            page={auctionPage - 1}
                            onPageChange={(e, newPage) => dispatch(setAuctionPage({ page: newPage + 1 }))}
                            onRowsPerPageChange={(e) => dispatch(setAuctionPage({ page: 1, limit: +e.target.value }))}
                        />
                    </Paper> : <Paper sx={{ height: '300px', textAlign: 'center', paddingY: '140px' }}>
                        {selectedEvent.value ?
                            <p>No Items are ordered</p>
                            : <p>Please choose {['a', 'e', 'i', 'o', 'u'].includes(pageType[0]) ? 'an' : 'a'} {pageType}</p>}
                    </Paper>
            }

            <Modal title={showModal.modalTitle} isOpen={showModal?.isOpen} onClose={() => setShowModal({ isOpen: false })} onDone={() => setShowModal({ isOpen: false })} buttonText='Close' contentStyle={{ width: '90%', overflowY: 'auto' }} >
                <TableContainer>
                    {
                        showModal?.tableBody?.length ? <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    {
                                        showModal?.tableHeading?.map((item) => {
                                            return (
                                                <StyledTableCell key={item} >{item}</StyledTableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* table row for bid details */}
                                {showModal?.tableBody?.map((row, i) => {
                                    return <StyledTableRow key={row.product_sku_id}>
                                        <StyledTableCell>{row?.product_sku_id}</StyledTableCell>
                                        <StyledTableCell>
                                            {
                                                row?.variant_combination &&
                                                <VariantCombination combiantionKeys={Object.keys(row?.variant_combination?.fullObject)} combiantionValues={Object.values(row?.variant_combination?.fullObject)} />
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell>{row?.quantity_bought}</StyledTableCell>
                                        <StyledTableCell>{row?.quantity_in_stock}</StyledTableCell>
                                    </StyledTableRow>
                                })}
                            </TableBody>
                        </Table> : <Paper sx={{ height: '100px', textAlign: 'center', paddingY: '140px' }}>
                            <p>No Items to show</p></Paper>}
                </TableContainer>
            </Modal>
        </>
    )
}

export default ItemSalesReportTable;