import React, { useEffect } from 'react'
import Container from "../../components/HOC/Container/Container"
import Input from "../../components/Input/Input"
import SidebarImage from "../../../images/sidebar_image.png"
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import logger from "../../../utils/logger";
import authService from "../../../services/auth-services";
import { useNavigate } from "react-router-dom";
import { validEmail } from "../../../utils/reg-ex";
import Lock from "@mui/icons-material/Lock";
import "./SignIn.css"
import LoadingButton from '../../components/LoadingButton/LoadingButton'
import logo_alone from "../../../images/BGbig.png"

import parseQuery from "query-string";


const SignIn = () => {
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false)
  const url = useLocation();
  const handleEmail = (e) => {
    setEmail(e.target.value);
    if (validEmail.test(e.target.value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  ///for permissions assigning
  useEffect(() => {
    const { token, auction_id  } = parseQuery.parse(url.search);
    console.log(token, "form permission", auction_id)
    if (token) {
      authService.assignPermissions(auction_id, token ).then((response) => {
        console.log(response, "response")
      })
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!emailError && password) {
      setLoading(true)
      try {
        await authService.login(email, password).then(
          (response) => {
            //==code with checking the role        
            // if (response?.data?.role === "Admin") {
            //   localStorage.setItem("token", response.token);
            //   localStorage.setItem("username", email);
            //   setErrorMsg(null);
            //   setLoading(false)
            //   window.location.reload();
            // } else {
            //   setErrorMsg({
            //     type: "error",
            //     body: "Invalid credentials!",
            //   });
            //   setLoading(false)
            // }


            //==code without checking the role
            if (response?.data) {
              localStorage.setItem("token", response.token);
              localStorage.setItem("username", email);
              setErrorMsg(null);
              setLoading(false)
              window.location.reload();
            } else {
              setErrorMsg({
                type: "error",
                body: "Invalid credentials!",
              });
              setLoading(false)
            }


          },
          (error) => {
            logger(
              "./pages/SignIn/SignIn.jsx",
              "handleSubmit(): catch1",
              error
            );
            setErrorMsg({
              type: "error",
              body: error.response.data.message || "Login failed!",
            });
            setLoading(false)
          }
        );
      } catch (err) {
        logger("./pages/SignIn/SignIn.jsx", "handleSubmit(): catch2", err);
        setErrorMsg({ type: "error", body: "Login failed!" });
        setLoading(false)
      }
    }
  };
  return (
    <Container style={{ padding: "0px", width: "70%" }} >
      <div className='signin-container'>
        <div className='signin-image'>
          <img src={SidebarImage} alt="" />
        </div>
        <div className='signin-right'>
          <div className='signin-form-container'>
            <div className='signin-heading'>
              <div className='signin-heading-image'>
                <img src={logo_alone} alt="" />
              </div>
              <div style={{ fontSize: "15px", fontWeight: "600" }}>Welcome to the Boundless Giving Event And Marketplace Creation Tool. Here you can either create a new event or continue building an event you already started.</div>
            </div>
            {errorMsg && <p style={{ color: errorMsg.type === 'success' ? 'green' : 'red', fontSize: "12px", textAlign: "center" }}> {errorMsg.body} </p>}
            <form className='signin-form' onSubmit={handleSubmit}>
              <Input required label="Email Address" type='email' value={email} onChange={handleEmail} style={{ marginBottom: "2px" }} />
              <div className='signin-change-email'> <span onClick={() => navigate("/admin/change-email")}>Change Email</span></div>
              <div className='signin-password-container'>
                <Input required label="Password" type={showPassword ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} style={{ marginBottom: "2px" }} />
                <div className='signin-showicon' onClick={() => setShowPassword(!showPassword)}>{showPassword ? <VisibilityOffIcon sx={{ fontSize: "17px" }} /> : <Visibility sx={{ fontSize: "17px" }} />}</div>
              </div>

              <div className='signin-forgot'><Link to={"/admin/reset-pwd"}><span><Lock sx={{ fontSize: "13px" }} /> Forgot Password</span></Link> </div>
              <div className='signin-submit-container'>
                <div className='signin-submit-button'>
                  <LoadingButton isLoading={loading} type="submit">Sign In</LoadingButton>
                  <div style={{ fontSize: "18px", marginTop: "5px" }}>Don't have an account? <Link to={"/admin/sign-up"} style={{ color: "#FC3300", cursor: "pointer" }}>Register Now</Link></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default SignIn